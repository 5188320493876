import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoldGuardService implements CanActivate {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('Admin service: ' + this.auth.isUserLoggedIn());
    if (this.auth.isUserLoggedIn() && this.auth.getTypeUser() === 'gold') {
        // console.log('ok...');
        return true;
    } else {
        // console.log('Type user: ', this.auth.getTypeUser() );
        this.router.navigate(['dashboard/postalizzazioni']);
    }
  }
}
