import { RouterModule, Routes } from '@angular/router';

import { AdminDelegateGuardService } from './services/admin-delegate-guard.service';
import { AdminGuardService } from './services/admin-guard.service';
import { CambiaPasswordComponent } from './cambia-password/cambia-password.component';
import { ConfermaRegistrazioneComponent } from './conferma-registrazione/conferma-registrazione.component';
import { FirstLoginService } from './services/first-login.service';
import { GoldAdminGuardService } from './services/gold-admin-guard.service';
import { GoldGuardService } from './services/gold-guard.service';
import { LoginComponent } from './login/login.component';
import { LoginGuardService } from './services/login-guard.service';
import { MaintenanceService } from './services/maintenance.service';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecuperaUsernameComponent } from './recupera-username/recupera-username.component';
import { WrapperDashComponent } from './dashboard/wrapper-dash/wrapper-dash.component';

const routes: Routes = [
  {
    path: '',
    component: WrapperDashComponent,
    canActivate: [GoldGuardService, FirstLoginService],
    pathMatch: 'full',
  },
  {
    path: 'cambia-password',
    component:  WrapperDashComponent,
    pathMatch: 'full',
  },
  {
    path: 'dashboard/completa-registrazione',
    component:  WrapperDashComponent,
    canActivate: [AdminDelegateGuardService],
    pathMatch: 'full',
  },
  {
    path: 'dashboard/home',
    component: WrapperDashComponent,
    canActivate: [GoldGuardService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'dashboard/utenti',
    component: WrapperDashComponent,
    canActivate: [GoldAdminGuardService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'dashboard/configurazioni',
    component: WrapperDashComponent,
    canActivate: [GoldGuardService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'dashboard/prodotti',
    component: WrapperDashComponent,
    canActivate: [GoldGuardService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'dashboard/gruppi',
    component: WrapperDashComponent,
    canActivate: [GoldGuardService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'dashboard/uffici',
    component: WrapperDashComponent,
    canActivate: [GoldAdminGuardService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'dashboard/postalizzazioni',
    component: WrapperDashComponent,
    canActivate: [LoginGuardService, MaintenanceService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'dashboard/test',
    component: WrapperDashComponent,
    canActivate: [LoginGuardService, MaintenanceService, FirstLoginService],
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'confermaRegistrazione',
    component: ConfermaRegistrazioneComponent,
    pathMatch: 'full',
  },
  {
    path: 'cambiaPassword',
    component: CambiaPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'recuperaUsername',
    component: RecuperaUsernameComponent,
    pathMatch: 'full',
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
