import { Component, OnInit } from '@angular/core';
import {  faFileAlt,
          faChevronUp,
          faChevronDown,
          faFile } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DistinteService } from 'src/app/services/distinte/distinte.service';

@Component({
  selector: 'app-recap-shipping',
  templateUrl: './recap-shipping.component.html',
  styleUrls: ['./recap-shipping.component.css']
})
export class RecapShippingComponent implements OnInit {
  faChevronUp         = faChevronUp;
  faChevronDown       = faChevronDown;
  faFileAlt           = faFileAlt;
  faFile              = faFile;

  close: boolean;
  nShipping: number;
  nOpen: number;
  nClosed: number;

  constructor(private distinteService: DistinteService,
              private loader: NgxUiLoaderService) {
          this.close = false;
          this.nShipping = 0;
          this.nOpen = 0;
          this.nClosed = 0;
          this.loader.startLoader('nShippingLoader');
          this.distinteService.getNShipping()
          .subscribe(resp => {
            if (resp.status.toUpperCase() === 'OK'){
              // tslint:disable-next-line: no-string-literal
              this.nShipping = resp.items['nShipping'];
              // tslint:disable-next-line: no-string-literal
              this.nOpen = resp.items['nOpen'];
              // tslint:disable-next-line: no-string-literal
              this.nClosed = resp.items['nClosed'];
            }
            this.loader.stopLoader('nShippingLoader');
          });
  }

  ngOnInit(): void {
  }

  toggleWidget(){
    this.close = !this.close;
    if (this.close){
      $('#recapShippingWrapper').css('min-height', '0px');
    } else {
      $('#recapShippingWrapper').css('min-height', '200px');
    }
  }

}
