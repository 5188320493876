import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var __env: any;

@Injectable({
  providedIn: 'root'
})
export class UfficiService extends BaseService {
  private apiUrlGetUffici     = '/getUffici';
  private apiUrlGetUfficiDT   = '/getUfficiDT';
  private apiUrlEditUfficio   = '/modUfficio';
  private apiUrlDeleteUfficio = '/delUfficio';
  private apiUrlAddUfficio    = '/addUfficio';
  private apiUrlReset         = '/reset';
  private apiUrlGetNOffice    = '/getNOffice';
  private apiUrlEditUfficioWithFile = '/editUfficioWithFile';

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
      super(http, auth, route, message);
  }

  getUffici(param = {}) {
    return this.get(this.apiUrlGetUffici, param, true);
  }

  getUfficiDT(param = {}) {
    return this.post(this.apiUrlGetUfficiDT, param, true);
  }

  editUfficio(param) {
    return this.post(this.apiUrlEditUfficio, param, true);
  }

  editUfficioWithFile(param) {
    const token = this.auth.getToken();
    // tslint:disable-next-line: object-literal-shorthand
    const headers = new HttpHeaders({token: token });
    const options = { headers };
    return this.http.post<any>(__env.apiUrl + this.apiUrlEditUfficioWithFile, param, options);
  }

  deleteUfficio(param = {}) {
    return this.post(this.apiUrlDeleteUfficio, param, true);
  }

  addUfficio(param = {}) {
    return this.post(this.apiUrlAddUfficio, param, true);
  }

  reset(param = {}){
    return this.post(this.apiUrlReset, param, true);
  }

  getNOffice(param = {}){
    return this.get(this.apiUrlGetNOffice, param, true);
  }
}
