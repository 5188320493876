<div class="container-fluid">
  <div class="row ">
    <div class="col">
      <h4 class="mt-3"><fa-icon [icon]="faTachometerAlt"></fa-icon> Dashboard</h4>
    </div>
    <div class="col pt-2">

    </div>
  </div>
  <div class="row">
    <div class="col">
      <a routerLink="/dashboard/home" class="hover-dark" ngbTooltip="Vai alla Home">Dashboard</a> / Home
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
      <app-recap-office></app-recap-office>
    </div>
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
      <app-recap-users></app-recap-users>
    </div>
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
      <app-recap-shipping></app-recap-shipping>
    </div>
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
      <app-recap-mails></app-recap-mails>
    </div>
  </div>  
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
      <app-pie-chart></app-pie-chart>
    </div>
    <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
      <app-line-chart></app-line-chart>
    </div>
  </div>
<!-- GRAFICI -->
  <!-- <div class="row mt-3 p-3">
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 shadow">
      <app-line-chart></app-line-chart>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 shadow">
      <app-bar-chart></app-bar-chart>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 shadow">
      <app-doughnut-chart></app-doughnut-chart>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-md-4 col-sm-4 col-xs-12 shadow">
      <app-radar-chart></app-radar-chart>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 shadow">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 shadow">
      <app-bubble-chart></app-bubble-chart>
    </div>
  </div>
</div>
 -->
