import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Config } from '../../../class/Config';
import { ConfigService } from 'src/app/services/config/config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-config-dash',
  templateUrl: './config-dash.component.html',
  styleUrls: ['./config-dash.component.css']
})
export class ConfigDashComponent implements OnInit {
  // icone
  faCogs = faCogs;

  active = 1;
  config: Config = new Config();
  configForm: FormGroup;

  constructor(private configService: ConfigService,
              private fb: FormBuilder,
              private message: ToastrService,
              private loader: NgxUiLoaderService) {
    this.initFormConfig();
    this.setFormConfig();
  }

  ngOnInit(): void {
    this.initFormConfig();
  }

  setFormConfig(){
    this.configService.getConfig()
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK'){
        this.config = resp.items[0];
        console.log(this.config);
        this.configForm.controls.modalitaManutenzione.setValue(this.config.modalitaManutenzione);
      }
    });
  }

  initFormConfig() {
    this.configForm = this.fb.group({
      modalitaManutenzione:     new FormControl(false),
    });
  }

  salvaModifiche(){
    this.loader.start();
    this.configService.saveConfig(this.configForm.value)
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK') {
        this.message.success('Modifiche salvate', 'Fatto');
      }
      this.loader.stop();
    });
  }

}
