<div class="row">
  <div class="col ">
    <h4 class="mt-3"><fa-icon [icon]="faUser"></fa-icon> Utenti</h4>
  </div>
</div>
<div class="row">
  <div class="col">
    <a routerLink="/dashboard/home" class="hover-dark" ngbTooltip="Vai alla Home">Dashboard</a> / <a routerLink="/dashboard/utenti" class="hover-dark" ngbTooltip="Vai agli utenti">Utenti</a>
  </div>
</div>
<hr />

<table datatable #dtUsers id="dtUsers"  [dtOptions]="dtOptions"  class="row-border hover w-100">
  <thead>
    <tr>
      <th ><fa-icon [icon]="faTools"></fa-icon></th>
      <th>Id</th>
      <th>Username</th>
      <th>Gruppo</th>
      <th>E-mail</th>
      <th>Nome</th>
      <th>Cognome</th>
      <th>Tipo utente</th>
      <th>Cellulare</th>
      <th>Data registrazione</th>
      <th>Ufficio</th>
      <th>Abilitato</th>
      <th *ngIf="gold">Upload</th>
      <th *ngIf="gold">Ultimo Reset</th>
    </tr>
  </thead>
</table>


<!-- Nuova/Modifica Utente -->
<ng-template #newUserModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faUser"></fa-icon> Utente
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <form action="" [formGroup]="userForm" class="justify-content-center">
        <div class="row">
          <div class="col-12 col-sm-12">

            <ng-container  >
              <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="form-row"  >
                      <div class="form-group w-100" >
                        <label for="email">Email *</label>
                        <input
                          type="email"
                          name="email"
                          required
                          class="form-control "
                          formControlName="email"
                          #email
                          placeholder="email..."
                        />
                      </div>
                      <div *ngIf="utils.showError(userForm.get('email'))" class="alert alert-danger">
                        Email utente non valida.
                      </div>
                    </div>
                  </div>
                  <div *ngIf="userForm.get('tipoUtente').value != 'gold'" class="col-12 col-sm-6">
                    <div class="form-row mb-2"  >
                      <label for="enabledUpload">Abilita il caricamento file</label>
                      <!-- Rounded switch -->
                      <div class="form-control" style="border: 0;">
                        <label class="switch">
                          <input type="checkbox" [disabled]="(!gold)" readonly="(!gold)" formControlName="enabledUpload" name="enabledUpload" >
                          <span  [className]="(!gold)?'slider round isDisabled':'slider round'"></span>
                        </label>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row">

              <div class="col-12 col-sm-6 my-2">
                <div class="form-row"  >
                  <div class="form-group w-100">
                    <label for="tipoUtente">Tipo utente *</label>
                    <select
                      name="tipoUtente"
                      required
                      class="form-control "
                      formControlName="tipoUtente"
                      #tipoUtente
                    >
                    <ng-container *ngFor="let privilege of privileges">
                      <option value="{{ privilege }}">{{ getLabelTypeUser(privilege.toString()) }}</option>
                    </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 my-2"  >
                <div class="form-row"  >
                  <div class="form-group w-100">
                    <label for="gruppo">Gruppo di spedizione*</label>
                    <select
                      name="gruppo"
                      required
                      class="form-control "
                      formControlName="gruppo"
                      #tipoUtente
                    >
                    <ng-container *ngFor="let gruppo of gruppi">
                      <option value="{{ gruppo.idGruppo }}">{{ gruppo.nome}}</option>
                    </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>
              
            </ng-container>

            <ng-container *ngIf="(userForm.get('tipoUtente').value === 'gold' && gold) || admin" >
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-row">
                    <div class="form-group w-100" >
                      <label for="nome">Nome *</label>
                      <input
                        type="text"
                        name="nome"
                        class="form-control"
                        formControlName="nome"
                        #nome
                        placeholder="nome..."
                      />
                    </div>
                    <div *ngIf="utils.showError(userForm.get('nome'))" class="alert alert-danger">
                      Nome utente non valido.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-row">
                    <div class="form-group w-100" >
                      <label for="cognome">Cognome </label>
                      <input
                        type="text"
                        name="cognome"
                        class="form-control"
                        formControlName="cognome"
                        #nome
                        placeholder="cognome..."
                      />
                    </div>
                    <div *ngIf="utils.showError(userForm.get('cognome'))" class="alert alert-danger">
                      Cognome utente non valido.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-row">
                    <div class="form-group w-100" >
                      <label for="cellulare">Cellulare</label>
                      <input
                        type="text"
                        name="cellulare"
                        class="form-control"
                        formControlName="cellulare"
                        #nome
                        placeholder="333 44 55 678..."
                      />
                    </div>
                    <div *ngIf="utils.showError(userForm.get('cellulare'))" class="alert alert-danger">
                      Cellulare  non valido.
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <p class="mt-2">
          <b>* Campi obbligatori</b>
        </p>
        <div class="modal-footer">
          <button
            *ngIf="!edit"
            type="submit"
            [className]="(!userForm.valid)?'btn  btn-blu isDisabled':'btn  btn-blu'"
            [disabled]="!userForm.valid"
            [ngbTooltip]="
              userForm.valid == false
                ? 'Inserisci i campi obbligatori'
                : 'Aggiungi l\'utente'
            "
            (click)="add()"
          >
            Salva
          </button>
          <button
            *ngIf="edit"
            type="submit"
            [className]="(!userForm.valid)?'btn  btn-blu isDisabled':'btn  btn-blu'"
            [disabled]="!userForm.valid"
            [ngbTooltip]="
              userForm.valid == false
                ? 'Inserisci i campi obbligatori'
                : 'Modifica l\'utente'
            "
            (click)="confirmEdit()"
          >
            Modifica
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- Elimina utente -->
<ng-template #deleteUserModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faUser"></fa-icon> Elimina utente
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close "
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <p>
        Sei sicoro di voler eliminare l'utente <b>{{ userToDelete }}</b> ?
      </p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-5  ">
          <button
            class="btn btn-secondary"
            (click)="modal.dismiss('Cross click')"
          >
            Annulla
          </button>
        </div>
        <div class="col-7">
          <button class="btn btn-blu" (click)="deleteUserConfirm()">
            Conferma
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Nuovo delegato -->
<ng-template #newDelegateModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faUser"></fa-icon> Aggiungi delegato
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <p>
        Sei sicoro di voler aggiungere un delegato? Le credenziali saranno inviate via e-mail.
      </p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-5  ">
          <button
            class="btn btn-secondary"
            (click)="modal.dismiss('Cross click')"
          >
            Annulla
          </button>
        </div>
        <div class="col-7">
          <button class="btn btn-blu" (click)="addDelegate()">
            Conferma
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

