import { ChartOptions, ChartType } from 'chart.js';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
         faChartPie,
         faChevronDown,
         faChevronUp
} from '@fortawesome/free-solid-svg-icons';

import { BaseChartDirective } from 'ng2-charts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PostalizzazioniService } from 'src/app/services/postalizzazioni/postalizzazioni.service';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart? : BaseChartDirective;

  // icone
  faChevronUp         = faChevronUp;
  faChevronDown       = faChevronDown;
  faChartPie          = faChartPie;
  close: boolean;
  chartHeight: string;
  chartWidth: string;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public pieChartLabels: String[] = ['', '', '', '', '', '', '', '', '', ''];
  public pieChartData = [{data: []}];

  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public loadData: boolean;

  constructor(private loader: NgxUiLoaderService,
              private postalizzazioniService: PostalizzazioniService) {
    this.loadData = false;
    this.close = false;
    this.loader.startLoader('nChartPieLoader');
    this.chartHeight  = "300%";
    // this.chartWidth   = "300%";
    this.postalizzazioniService.getStatsPostalizzazioni()
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK'){
        // console.log("Response => ", resp);
        this.pieChartLabels = [];
        resp.items.forEach(element => {
          this.pieChartData[0].data.push(element.nPostalizzazioni);
          this.pieChartLabels.push(element.nome.replace(/â‚¬/g, '€'));
        });

      }
    });
    this.loader.stopLoader('nChartPieLoader');
  }


  ngOnInit(): void {

  }

  toggleWidget(){
    this.close = !this.close;
    if (this.close){
      $('#pieChartsWrapper').css('height', '0px');
    } else {
      $('#pieChartsWrapper').css('height', '300%');
    }
  }

}
