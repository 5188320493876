import { Component, OnInit, Output } from '@angular/core';

import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-wrapper-dash',
  templateUrl: './wrapper-dash.component.html',
  styleUrls: ['./wrapper-dash.component.css']
})
export class WrapperDashComponent implements OnInit {
  url: string;
  // @Output() apiUrl: string;

  constructor(private router: Router,
              private utils: UtilsService) {
          this.url = this.router.url;
          // this.apiUrl = this.utils.getBaseUrl() + 'api-sendtrack';
  }

  ngOnInit(): void {
    // console.log(this.url);
  }

}
