import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { IResponse } from 'src/app/interfaces/response';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends BaseService {
  private apiUrlGetConfig = '/getConfig';
  private apiUrlGetInsight = '/getInsight';
  private apiUrlEditConfig = '/editConfig';
  private apiUrlSaveConfig = '/editConfig';

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
    super(http, auth, route, message);
  }

  getConfig(param = {}) {
    return this.get(this.apiUrlGetConfig, param, true);
  }

  getConfigSync(param = {}): Observable<IResponse> {
    return this.getObservable(this.apiUrlGetConfig, param, true);
  }

  getInsight(param = {}) {
    return this.get(this.apiUrlGetInsight, param, true);
  }

  editConfig(param = {}) {
    return this.post(this.apiUrlEditConfig, param, true);
  }

  saveConfig(param = {}){
    return this.post(this.apiUrlSaveConfig, param, true);
  }
}
