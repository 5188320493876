import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-conferma-registrazione',
  templateUrl: './conferma-registrazione.component.html',
  styleUrls: ['./conferma-registrazione.component.css']
})
export class ConfermaRegistrazioneComponent implements OnInit {
  registerMSG: string;
  registerStatusOk: boolean;
  tokenReg: string;
  // baseUrl: string;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private utils: UtilsService) {
                // this.baseUrl = this.utils.getBaseUrl();
                // tslint:disable-next-line: deprecation
                this.route.queryParams.subscribe(params => {
                  this.tokenReg = params.tokenReg;
                  this.auth.confermaRegistrazione(this.tokenReg)
                    // tslint:disable-next-line: deprecation
                    .subscribe(res => {
                      console.log(res);
                      if (res.status === 'OK') {
                        this.registerStatusOk = true;
                      } else {
                        this.registerStatusOk = false;
                      }
                      this.registerMSG = res.msg;
                    });
                });
  }

  ngOnInit(): void {
  }

}
