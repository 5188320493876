import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  faBell,
  faEye,
  faEyeSlash,
  faSearch,
  faUpload,
  faUser
} from '@fortawesome/free-solid-svg-icons';

import { AuthService } from '../../services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtentiService } from 'src/app/services/utenti/utenti.service';
import { UtilsService } from 'src/app/services/utils.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-top-dash',
  templateUrl: './top-dash.component.html',
  styleUrls: ['./top-dash.component.css']
})
export class TopDashComponent implements OnInit {
  @ViewChild('newProfilo') public newProfilo: TemplateRef<any>;

  // icone
  faBell = faBell;
  faUser = faUser;
  faSearch = faSearch;
  faUpload = faUpload;
  faEye       = faEye;
  faEyeSlash  = faEyeSlash;

  username: string;
  msg: string;
  isUserLoggedIn: boolean;
  modalOptions: NgbModalConfig;

  // form
  profiloForm: FormGroup;

  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;

  linkBanner: string;
  baseUrl: string;

  constructor(private auth: AuthService,
              private router: Router,
              private loader: NgxUiLoaderService,
              private fb: FormBuilder,
              private userServ: UtentiService,
              private message: ToastrService,
              public utils: UtilsService
              ) {
    this.showConfirmPassword = false;
    this.showCurrentPassword = false;
    this.showConfirmPassword = false;
    this.modalOptions = new NgbModalConfig(new NgbConfig());
    this.modalOptions.ariaLabelledBy =  'modal-basic-title';
    this.modalOptions.size = 'lg';
    this.username = this.auth.getUserName();
    this.isUserLoggedIn = this.auth.isUserLoggedIn();
    this.linkBanner = this.auth.getLinkBanner();
    this.baseUrl = this.utils.getBaseUrl();
    this.initFormProfilo();
  }

  ngOnInit(): void {
    this.username = this.auth.getUserName();
    this.isUserLoggedIn = this.auth.isUserLoggedIn();
  }

  initFormProfilo() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    // console.log('loginData: ', loginData.items);
    this.profiloForm = this.fb.group({
      // tslint:disable-next-line: no-string-literal
      email:            new FormControl({ value: loginData['items']['email'], disabled : true}, Validators.email),
      // tslint:disable-next-line: no-string-literal
      username:         new FormControl({ value: loginData['items']['username'], disabled : true}),
      // tslint:disable-next-line: no-string-literal
      nome:  new FormControl({ value: loginData['items']['nome'], disabled : true}, Validators.required),
      // tslint:disable-next-line: no-string-literal
      cognome:  new FormControl({ value: loginData['items']['cognome'], disabled : true}, Validators.required),
      oldPassword:      new FormControl(null, [ Validators.required,
                                                Validators.minLength(8),
                                                Validators.maxLength(20)]),
      newPassword:      new FormControl(null, [ Validators.required,
                                                Validators.minLength(8),
                                                Validators.maxLength(20)]),
      confermPassword:  new FormControl(null, [ Validators.required,
                                                Validators.minLength(8),
                                                Validators.maxLength(20)]),
                                              });
  }

  logout() {
    // e.preventDefault();
    const that = this;
    this.auth.logout()
      .pipe(first())
      // tslint:disable-next-line: deprecation
      .subscribe(
        data => {
          this.loader.start();
          if (data.status.toLocaleUpperCase().trim() === 'KO'){
            that.message.warning('Errore nel tentativo di logout', 'Attenzione');
          }
          this.router.navigate(['/login']);
          this.isUserLoggedIn = false;
          this.username = null;
          this.loader.stop();
        });
  }

  confirmEdit() {
    if (this.validPassword(this.profiloForm.get('newPassword').value)) {
      this.profiloForm.removeControl('confermPassword');
      this.profiloForm.controls.oldPassword.setValue(btoa(this.profiloForm.get('oldPassword').value));
      this.profiloForm.controls.newPassword.setValue(btoa(this.profiloForm.get('newPassword').value));
      this.profiloForm.controls.username.enable();
      this.userServ.modificaPassword(this.profiloForm.value)
      // tslint:disable-next-line: deprecation
      .subscribe(res => {
        console.log(res);
        $('#errorMSG').html(res.msg);
        if (res.status === 'OK') {
          this.profiloForm.reset();
          $('#errorMSG').removeClass('alert-danger d-none');
          $('#errorMSG').addClass('alert-success');
          $('#btn-mod').attr('disabled', 'true');
          $('#btn-mod').attr('readonly', 'true');
        } else {
          console.log('Non è possibile modificare la password',  this.msg);
          $('#errorMSG').removeClass('d-none');
        }
      });
    }

  }

  validPassword(password: string) {
    const regExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20}$)');
    // RegEx	Description
    // ^	The password string will start this way
    // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
    // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
    // (?=.*[0-9])	The string must contain at least 1 numeric character
    // (?=.*[!@#$%^&*])	The string must contain at least one special character,
    // but we are escaping reserved RegEx characters to avoid conflict
    // (?=.{8,20}$)	The string must be eight characters and longer max 20
    // console.log(regExp.test(password));
    return regExp.test(password);
  }

  openProfilo(){
    this.initFormProfilo();
    this.utils.open(this.newProfilo, this.modalOptions);
  }

  toggleShowCurrentPassword(){
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  toggleShowNewPassword(){
    this.showNewPassword = !this.showNewPassword;
  }

  toggleShowConfirmPassword(){
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
