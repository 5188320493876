<ngx-ui-loader [loaderId]="'nUsersLoader'"></ngx-ui-loader>
<div class="card shadow card-widget-recap border rounded m-1 " id="recapUsersWrapper">
    <div class="card-header h5">
        <fa-icon class="" [icon]="faUsersCog"></fa-icon>
        Utenti
        <div class="float-end">
            <fa-icon class="cur" (click)="toggleWidget()" [icon]="(!close)?faChevronUp:faChevronDown"></fa-icon>
        </div>
    </div>
    <div *ngIf="!close" class="card-body text-secondary">
      <h5 class="card-title float-start">
        <fa-icon class="fa-2x" [icon]="faUser"></fa-icon>
        <span class="badge bg-blu rounded-pill text-light">{{ nUsers }}</span>

      </h5>
      <p class="card-text p-1 text-right float-rigth">
        <span class="badge rounded-pill bg-warning">{{ nGold }} gold</span>&nbsp;
        <span class="badge rounded-pill bg-dark">{{ nAdminCC}} admin_cc</span>&nbsp;  
        <span class="badge rounded-pill bg-primary">{{ nAdmin }} admin</span>&nbsp;  
        <span class="badge rounded-pill bg-info">{{ nCustomer }} customer</span>&nbsp; 
        <span class="badge rounded-pill bg-secondary">{{ nDelegate }} delegate</span>  
      </p>
      
    </div>
    <div class="card-footer bg-transparent m-0 p-0" style="max-width: 18rem;">
      <div class="row m-0 p-0">
        <div class="col text-left m-0 p-0">
        </div>
        <div class="col text-right pt-3 m-0 p-0">
          <a href="" class="text-secondary">
            <!-- <fa-icon class="" [icon]="faEllipsisV"></fa-icon> -->
          </a>
        </div>
      </div>
    </div>
  </div>