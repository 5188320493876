import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Config } from '../class/Config';
import { ConfigService } from './config/config.service';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService implements CanActivate {
  config: Config = new Config();
  modalitaManutenzione: boolean;

  constructor(private router: Router,
              private auth: AuthService,
              private configService: ConfigService) {

            }

   async canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const modMaintenance = await this.getMaintenanceMode();
    // console.log('canActivate', modMaintenance[0].modalitaManutenzione);
    if  (modMaintenance[0].modalitaManutenzione === 0) {
      // console.log('Modalità manutenzione disabilitata');
      return true;
    } else if (!this.auth.isUserLoggedIn()){
            // console.log('Utente non loggato');
            this.logout();
         } else if (this.auth.getTypeUser() !== 'gold'){
            // console.log('Utente non gold');
            this.logout();
          } else { return true; }
  }

  async getMaintenanceMode() {
    const resp = await this.configService.getConfigSync().toPromise();
    if (resp.status === 'OK') {
      return resp.items;
    }
  }

  logout() {
    // e.preventDefault();
    this.auth.logout()
      .pipe(first())
      // tslint:disable-next-line: deprecation
      .subscribe(
        data => {
          localStorage.setItem('loginData', null);
          console.log(data);
          this.router.navigate(['/']);
        });
  }
}
