// import { ChartDataset, ChartType, RadialChartOptions } from 'chart.js';
import { ChartDataset, ChartOptions, ChartType, LabelItem } from 'chart.js';
import { Component, OnInit } from '@angular/core';

// import {  } from 'ng2-charts';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css']
})
export class RadarChartComponent implements OnInit {
  public radarChartOptions: ChartOptions<'radar'> = {
    responsive: true,
  };
  public radarChartLabels: String[] = ['CycleStart', 'CycleStop', 'Device',
    'MachineStop', 'MachineStart', 'Healt-Check', 'Temp'];

  public radarChartData: ChartDataset[] = [
    { data: [0, 1, 2, 3, 4, 5, 6], label: 'Analisi Agenti' }
  ];
  public radarChartType: ChartType = 'radar';

  constructor() { }

  ngOnInit(): void {
  }

}
