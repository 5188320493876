import { Component, OnInit } from '@angular/core';
import { faBuilding,
         faChevronUp,
         faChevronDown,
         faIndustry } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UfficiService } from 'src/app/services/uffici/uffici.service';

@Component({
  selector: 'app-recap-office',
  templateUrl: './recap-office.component.html',
  styleUrls: ['./recap-office.component.css']
})
export class RecapOfficeComponent implements OnInit {
  // icone
  faBuilding    =  faBuilding;
  faChevronUp   = faChevronUp;
  faChevronDown = faChevronDown;
  faIndustry    = faIndustry;

  close: boolean;
  nOffice: number;

  constructor(private ufficiService: UfficiService,
              private loader: NgxUiLoaderService) {
    this.close = false;
    this.nOffice = 0;
    this.loader.startLoader('nOfficeLoader');
    this.ufficiService.getNOffice()
    .subscribe(resp => {
      if (resp.status.toUpperCase() === 'OK'){
        this.nOffice = resp.items;
      }
      this.loader.stopLoader('nOfficeLoader');
    });
  }

  ngOnInit(): void {
  }

  toggleWidget(){
    this.close = !this.close;
    if (this.close){
      $('#recapOfficeWrapper').css('min-height', '0px');
    } else {
      $('#recapOfficeWrapper').css('min-height', '200px');
    }
  }

}
