import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GruppiService extends BaseService {
  private apiUrlGetGruppi = '/getGruppi';
  private apiUrlGetGruppiDT = '/getGruppiDT';
  private apiUrlDeleteGroup = '/deleteGroup';
  private apiUrlEditGruppo = '/editGruppo';
  private apiUrlAddGroup = '/addGruppo';

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
          super(http, auth, route, message);
  }

  getGruppi(param = {}) {
    return this.get(this.apiUrlGetGruppi, param, true);
  }

  getGruppiDT(param = {}) {
    return this.post(this.apiUrlGetGruppiDT, param, true);
  }

  deleteGroup(idGruppo) {
    const param = {id : idGruppo};
    return this.post(this.apiUrlDeleteGroup, param, true);
  }

  editGruppo(param) {
    return this.post(this.apiUrlEditGruppo, param, true);
  }

  addGroup(param = {}){
    return this.post(this.apiUrlAddGroup, param, true);
  }
}
