<div class="row">
  <div class="col ">
    <h4 class="mt-3"><fa-icon [icon]="faBuilding"></fa-icon> Uffici</h4>
  </div>
  <div *ngIf="adminCC" class="col text-right me-2 pt-2">
    <input type="hidden" (click)="openEditUfficio()" #editUfficioM id="editUfficioM">
    <button (click)="openReset()" class="btn btn-blu" #apri id="apri">
      <fa-icon [icon]="faUserSlash"></fa-icon> Reset
    </button>
  </div>
</div>
<div class="row">
  <div class="col">
    <a routerLink="/dashboard/home" class="hover-dark" ngbTooltip="Vai alla Home">Dashboard</a> / <a routerLink="/dashboard/uffici" class="hover-dark" ngbTooltip="Vai alle postalizzazioni">Uffici</a>
  </div>
</div>
<hr />
<table
  datatable
  [dtOptions]="dtOptions"
  #dtOffice
  class="row-border hover w-100"
>
  <thead>
    <tr>
      <th><fa-icon [icon]="faTools"></fa-icon></th>
      <th>Id</th>
      <th>Nome</th>
      <th>Email</th>
      <th>Telefono</th>
      <th>Indirizzo</th>
      <th>Comune</th>
      <th>Cap</th>
      <th>Banner</th>
    </tr>
  </thead>
</table>


<!-- Modifica Ufficio -->
<ng-template #editUfficioModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faBuilding"></fa-icon> Ufficio
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
        ngbTooltip="Chiudi il modal"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <form action="" [formGroup]="ufficioForm" class="justify-content-center">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-row">
              <div class="form-group w-100">
                <label for="nome">Nome *</label>
                <input
                  type="text"
                  name="nome"
                  required
                  class="form-control"
                  formControlName="nome"
                  #ragione
                  placeholder="Nome ufficio mittente..."
                />
              </div>
              <div *ngIf="utils.showError(this.ufficioForm.get('nome'))" class="alert alert-danger">
                Nome non valido.
              </div>
            </div>
            
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-row">
              <div class="form-group w-100">
                <label for="telefono">Telefono</label>
                <input
                  type="tel"
                  name="telefono"
                  required
                  class="form-control "
                  formControlName="telefono"
                  #telefono
                  placeholder="06100100"
                />
              </div>
              <div *ngIf="utils.showError(this.ufficioForm.get('telefono'))" class="alert alert-danger">
                Telefono non valido.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="form-row">
              <div class="form-group w-100">
                <label for="provincia">Provincia </label>
                <select (change)="getComuni()" name="provincia" class="form-control "formControlName="provincia">
                  <option value="-1">Seleziona...</option>
                  <ng-container *ngFor="let provincia of province">
                    <option value="{{ provincia }}">{{ provincia }}</option>
                  </ng-container>
                </select>
              </div> 
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-row">
              <div class="form-group w-100">
                <label for="cap">Comune </label>
                <select (change)="getCap()" name="citta" class="form-control "formControlName="citta">
                  <option value="-1">Seleziona...</option>
                  <ng-container *ngFor="let comune of comuni">
                    <option value="{{ comune.Comune }}">{{ comune.Comune }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-row">
              <div class="form-group w-100">
                <label for="cap">Cap *</label>
                <select name="cap" class="form-control "formControlName="cap">
                  <option value="-1">Seleziona...</option>
                  <ng-container *ngFor="let cap of caps">
                    <option value="{{ cap.CAP }}">{{ cap.CAP }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="form-row">
              <div class="form-group w-100">
                <label for="indirizzo">Indirizzo *</label>
                <input
                  type="text"
                  name="indirizzo"
                  required
                  class="form-control"
                  formControlName="indirizzo"
                  #indirizzo
                  placeholder="Indirizzo..."
                />
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="gold"> 
          <div class="row" >
            <div class="col">
              <label for="fileBanner">File Banner </label>
              <ngx-dropzone (change)="onSelect($event); " [maxFileSize]="1048576" [multiple]='false' [showPreviews]="true" [accept]="'image/png,image/jpeg,image/jpg'">
                <ngx-dropzone-label>Clicca trascina il file</ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"  (removed)="onRemove(f)" >
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>

              <ngx-dropzone-image-preview  ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                <!-- <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
              </ngx-dropzone-image-preview>
            </div>
          </div>
        </ng-container>
        <p class="mt-2">
          <b>* Campi obbligatori</b>
        </p>
        <div class="modal-footer">
          <button
            *ngIf="!edit"
            type="submit"
            class="btn btn-orange"
            [disabled]="!ufficioForm.valid"
            [ngbTooltip]="
            ufficioForm.valid == false
                ? 'Inserisci i campi obbligatori'
                : 'Aggiungi l\'utente'
            "
            (click)="add()"
          >
            Salva
          </button>
          <button
            *ngIf="edit"
            type="submit"
            [className]="(!ufficioForm.valid)?'btn btn-blu isDisabled':'btn btn-blu'"
            [disabled]="!ufficioForm.valid"
            ngbTooltip="Modifica l'uffico"
            (click)="confirmEdit()"
          >
            Modifica
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- Reset Ufficio -->
<ng-template #resetModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faUserSlash"></fa-icon> Reset Ufficio
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body ">
      <p class="">
        Sei sicoro di voler resettare l'account <b>{{ ufficioToDelete }}</b>  ?<br>
        L'azione è irreversibile
      </p>
      <form action="" [formGroup]="resetForm" class="justify-content-center">
        <div class="form-row">
          <div class="form-group">
            <label for="email">Email del nuovo utente amministratore. (sostituirà l'attuale) </label>
            <input
              type="email"
              name="email"
              required
              class="form-control "
              formControlName="email"
              #email
              placeholder="email@email.it"
            />
            <div *ngIf="utils.showError(this.resetForm.get('email'))" class="alert alert-danger">
              Email non valida.
            </div>
          </div>
          
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-5 float-start">
          <button
            class="btn btn-secondary"
            (click)="modal.dismiss('Cross click')"
          >
            Annulla
          </button>
        </div>
        <div class="col-7">
          <button (click)="resetUfficioConfirm()" 
          [disabled]="!resetForm.valid" [className]="(!resetForm.valid)?'btn btn-blu isDisabled':'btn btn-blu'" >
            Conferma
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
