import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PostalizzazioniService extends BaseService {
  private apiUrlGetPostalizzazioni = '/getPostalizzazioni';
  private apiUrlGetMails  = '/getNPostalizzazioni';
  private apiUrlGetStatsPostalizzazioni = '/getStatsPostalizzazioni';
  private apiUrlGetStatsMonthPostalizzazioni = '/getStatsMonthPostalizzazioni';
  private apiUrlPostAnnullaPostalizzazione = '/annullaPostalizzazione';

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
        super(http, auth, route, message);
  }

  getPostalizzazioni(param = {}) {
      return this.get(this.apiUrlGetPostalizzazioni, param, true);
  }

  getNMails(param = {}){
    return this.get(this.apiUrlGetMails, param, true);
  }

  getStatsPostalizzazioni(param = {}){
    return this.get(this.apiUrlGetStatsPostalizzazioni, param, true);
  }

  getStatsMonthPostalizzazioni(param = {}){
    return this.get(this.apiUrlGetStatsMonthPostalizzazioni, param, true);
  }

  annullaPostalizzazione(param = {}){
    return this.post(this.apiUrlPostAnnullaPostalizzazione, param, true);
  }

}
