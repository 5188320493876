<footer class="footer  text-light pt-3 mt-n3">
  <div class="container-fluid bg-dark  ">
    <div class="row bg-secondary p-4">
      <div class="col">
        <img src="../../../assets/img/logo-mono.png"  class="mono" alt=" Partec SpA" width="160">
      </div>
    </div>
    <div class="row bg-dark text-left px-5 py-4">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pt-3">
        <b>Sede Legale </b><br>
        Vico Monteleone, 12 <br>
        80134 Napoli <br>
        Tel: +39 081.0104082 <br>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pt-3">
        <b>Sede Operativa </b><br>
        Via Diomede Carafa, 58 <br>
        80124 Napoli <br>
        Tel: +39 081.0104082 <br>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pt-3">
        <b>SendTrack System V2.0.3<br> 
          Copyright © 2022 - tutti i diritti riservati </b><br>
          P.IVA 07461981214<br>
        <a href="" target="_blank" ngbTooltip="La nostra Privacy Policy">Privacy Policy</a> |
        <a href="" target="_blank" ngbTooltip="La nostra Cookie Policy">Cookie Policy</a>
      </div>
    </div>
  </div>
</footer>
