<div class="row">
    <div class="col ">
      <h4 class="mt-3"><fa-icon [icon]="faCogs"></fa-icon> Configurazioni</h4>
    </div>
    <div class="col text-right me-2 pt-2">
  
    </div>
</div>
<div class="row">
  <div class="col">
    <a routerLink="/dashboard/home" class="hover-dark" ngbTooltip="Vai alla Home">Dashboard</a> / <a routerLink="/dashboard/configurazioni" class="hover-dark" ngbTooltip="Vai alle configurazioni">Configurazioni</a>
  </div>
</div>
<hr />
<form [formGroup]="configForm">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Generali</a>
        <ng-template ngbNavContent>
            <div class="form-group row">
              <label for="modalitaManutenzione" class="col-sm-2  form-check-label">Modalità Manutenzione</label>
              <div class="col-sm-10 ">
                <input type="checkbox"  class="ms-1 form-check-input" formControlName="modalitaManutenzione" #modalitaManutenzione >
              </div>
            </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Change Log</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12 p-3">
              <article>
                <h5>V.2.0.3 Fix Validazione User form e Sidebar</h5>
                <p>
                  Fix sulla validazione dei campi obbligatori per il form di aggiunta di un utente. <br>
                  Fix grafica sulla sidebar.
                </p>
                <hr>
              </article>
              <article>
                <h5>V.2.0.2 Fix Dettaglio in export Excel</h5>
                <p>
                  Aggiunta la colonna dettaglio nell'export generico in formato excel dalla tabella postalizzazioni.
                </p>
                <hr>
              </article>
              <article>
                <h5>UPGRADE - Aggiornamento del core alle seguenti versioni + Migliorie -  03/11/2023</h5>
                <pre>
                  Angular CLI: 16.2.5 
                  Node: 18.17.0
                  Package Manager: npm 7.5.6
                  OS: win32 x64

                  Angular: 16.2.8
                  ... animations, common, compiler, compiler-cli, core, forms
                  ... localize, platform-browser, platform-browser-dynamic, router

                  Package                         Version
                  ---------------------------------------------------------
                  @angular-devkit/architect       0.1602.5
                  @angular-devkit/build-angular   16.2.5
                  @angular-devkit/core            16.2.5
                  @angular-devkit/schematics      16.2.5
                  @angular/cli                    16.2.5
                  @schematics/angular             16.2.5
                  rxjs                            7.8.1
                  typescript                      4.9.3
                  zone.js                         0.14.
                  </pre>
                <p>
                  Aggiunta del comune 07029 Tempio Pausania
                </p>
                <hr>
              </article>
              <article>
                <h5>POSTALIZZAZIONI - Fix Comuni -  03/11/2022</h5>
                <p>
                  Risolto il problema che impediva la selezione dei comuni in una distinta con più spedizioni.
                </p>
                <hr>
              </article>
              <article>
                <h5>POSTALIZZAZIONI - Fix Escape -  26/05/2022</h5>
                <p>
                  Escape per il campo mittente nelle funzioni di dettaglio e download excel e pdf.
                </p>
                <hr>
              </article>
              <article>
                <h5>POSTALIZZAZIONI / UFFICI - Fix Download Excel 24/05/2022</h5>
                <p>
                  Effettuato l'unescape dei caratteri speciali.
                </p>
                <hr>
              </article>
              <article>
                <h5>POSTALIZZAZIONI - Riapertura distinta 11/05/2022</h5>
                <p>
                  Cliccando sul lucchetto è possibile riaprire la distinta cancellando i file associati.
                </p>
                <hr>
              </article>
              <article>
                <h5>POSTALIZZAZIONI - Aggiunta colonna Dettaglio in export 10/05/2022</h5>
                <p>
                  Aggiunta la colonna dettaglio nell'export generico in formato excel dalla tabella postalizzazioni.
                </p>
                <hr>
              </article>
              <article>
                <h5>Fix Charts e  introduzione ChangeLog - 06/05/2022</h5>
                <p>
                  Fix nella dashboard home per la restituzione delle statistiche, introduzione della console dei rilasci.
                </p>
                <hr>
              </article>
              
            </div>
          </div>
        </ng-template>
      </li>
    <button [disabled]="!configForm.valid"  [ngbTooltip]="configForm.valid == false ? 'Inserisci i campi obbligatori' : 'Salva le modifiche'" class="btn btn-primary" (click)="salvaModifiche()" ><i class="fas fa-save"></i> Salva</button>
    </ul>
</form>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
    
    