import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-dash',
  templateUrl: './footer-dash.component.html',
  styleUrls: ['./footer-dash.component.css']
})
export class FooterDashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
