<div class="container-fluid gradient m-0 p-5 min-vh-100">
    <div class="container text-center ">
      <div class="bg-white p-4 shadow">
        <img src="../../assets/img/logo.png" width="200"/>
        <div class="row">
          <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4 m-0">
            <h3 class="m-3">Recupera username</h3>
  
            <div #message  [className]="!(success)? 'alert alert-danger' : 'alert alert-success'" role="alert">
              <p class="p-3"> <b>{{ msg }}</b>
               <br> <br>
               <a routerLink="/" routerLinkActive="active" placement="bottom" ngbTooltip="Torna alla Home" class="verde-fix"> Torna alla Home </a></p>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  