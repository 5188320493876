import 'popper.js';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderService, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BarChartComponent } from './dashboard/chart/bar-chart/bar-chart.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BubbleChartComponent } from './dashboard/chart/bubble-chart/bubble-chart.component';
import { CambiaPasswordComponent } from './cambia-password/cambia-password.component';
// import { ChartsModule } from 'ng2-charts';
import { CompletaRegistrazioneDashComponent } from './dashboard/wrapper-dash/completa-registrazione-dash/completa-registrazione-dash.component';
import { ConfermaRegistrazioneComponent } from './conferma-registrazione/conferma-registrazione.component';
import { ConfigDashComponent } from './dashboard/wrapper-dash/config-dash/config-dash.component';
import { DataTablesModule } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { DoughnutChartComponent } from './dashboard/chart/doughnut-chart/doughnut-chart.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterDashComponent } from './dashboard/footer-dash/footer-dash.component';
import { HomeDashComponent } from './dashboard/wrapper-dash/home-dash/home-dash.component';
import { HttpClientModule } from '@angular/common/http';
import { LightboxModule } from 'ngx-lightbox';
import { LineChartComponent } from './dashboard/chart/line-chart/line-chart.component';
import { LoginComponent } from './login/login.component';
import { NgChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxPrintModule } from 'ngx-print';
import { NgxSimpleCountdownModule } from 'ngx-simple-countdown';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PieChartComponent } from './dashboard/chart/pie-chart/pie-chart.component';
import { PostalizzazioniDashComponent } from './dashboard/wrapper-dash/postalizzazioni-dash/postalizzazioni-dash.component';
import { RadarChartComponent } from './dashboard/chart/radar-chart/radar-chart.component';
import { RecapMailsComponent } from './dashboard/wrapper-dash/widgets/recap-mails/recap-mails.component';
import { RecapOfficeComponent } from './dashboard/wrapper-dash/widgets/recap-office/recap-office.component';
import { RecapShippingComponent } from './dashboard/wrapper-dash/widgets/recap-shipping/recap-shipping.component';
import { RecapUsersComponent } from './dashboard/wrapper-dash/widgets/recap-users/recap-users.component';
import { RecuperaUsernameComponent } from './recupera-username/recupera-username.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { TopDashComponent } from './dashboard/top-dash/top-dash.component';
import { UfficiDashComponent } from './dashboard/wrapper-dash/uffici-dash/uffici-dash.component';
import { UsersDashComponent } from './dashboard/wrapper-dash/users-dash/users-dash.component';
import { WrapperDashComponent } from './dashboard/wrapper-dash/wrapper-dash.component';
import { GruppiDashComponent } from './dashboard/wrapper-dash/gruppi-dash/gruppi-dash.component';
import { ProdottiDashComponent } from './dashboard/wrapper-dash/prodotti-dash/prodotti-dash.component';

const ngxUiLoaderConfiguration = {
  bgsColor: 'transparent',
  bgsOpacity: 0.1,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 50,
  bgsType: SPINNER.threeStrings,
  blur: 3,
  delay: 0,
  fgsColor: '#287eac',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 100,
  fgsType: SPINNER.threeStrings,
  gap: 24,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  // "overlayColor": "#71717178",
  // "pbColor": "#FF9900",
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 3,
  hasProgressBar: true,
  maxTime: -1,
  minTime: 500,
  logoUrl: '/assets/img/logo.png',
  logoSize: 0,
  logoPosition: POSITION.centerCenter
};


declare var $: any;
console.log(`jQuery version: ${$.fn.jquery}`);
@NgModule({
  declarations: [
    AppComponent,
    WrapperDashComponent,
    SidebarComponent,
    FooterDashComponent,
    TopDashComponent,
    HomeDashComponent,
    UsersDashComponent,
    LoginComponent,
    BarChartComponent,
    BubbleChartComponent,
    LineChartComponent,
    PieChartComponent,
    RadarChartComponent,
    DoughnutChartComponent,
    UfficiDashComponent,
    PostalizzazioniDashComponent,
    ConfermaRegistrazioneComponent,
    PageNotFoundComponent,
    CambiaPasswordComponent,
    ConfigDashComponent,
    CompletaRegistrazioneDashComponent,
    RecuperaUsernameComponent,
    RecapOfficeComponent,
    RecapUsersComponent,
    RecapShippingComponent,
    RecapMailsComponent,
    GruppiDashComponent,
    ProdottiDashComponent,
  ],
  imports: [
    // AngularMultiSelectModule,
    BrowserModule,
    DataTablesModule,
    // ChartsModule,
    NgChartsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSimpleCountdownModule,
    NgbModule,
    NgxPrintModule,
    NgxDropzoneModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfiguration),
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    FontAwesomeModule,
    LightboxModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added

  ],
  providers: [DatePipe, {provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
