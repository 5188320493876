import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-cambia-password',
  templateUrl: './cambia-password.component.html',
  styleUrls: ['./cambia-password.component.css']
})
export class CambiaPasswordComponent implements OnInit {
  changePasswordMSG: string;
  changePasswordStatusOk: boolean;
  token: string;
  cambiaPasswordForm: FormGroup;
  submitActive: boolean;
  success: boolean;

  constructor( private fb: FormBuilder,
               private auth: AuthService,
               private router: Router,
               private route: ActivatedRoute,
               private loader: NgxUiLoaderService) {
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe(params => {
      this.token = params.tokenReg;
      this.auth.checkTokenNewPassword(this.token)
                  // tslint:disable-next-line: deprecation
                  .subscribe(res => {
                    this.loader.start();
                    // console.log(res);
                    if (res.status === 'OK') {
                      this.changePasswordStatusOk = true;
                      this.success = true;
                    } else {
                      this.changePasswordStatusOk = false;
                      this.success = false;
                    }
                    this.changePasswordMSG = res.msg;
                    this.loader.stop();
                  });
    });
    this.changePasswordStatusOk = true;
    this.submitActive = false;
    this.cambiaPasswordForm = this.fb.group({
      newPassword:      new FormControl(null, [Validators.minLength(8), Validators.required]),
      confirmPassword:  new FormControl(null, [Validators.minLength(8), Validators.required]),
    });
  }

  ngOnInit(): void {
    this.changePasswordStatusOk = true;
    this.success = false;
    this.submitActive = false;
    this.cambiaPasswordForm = this.fb.group({
      newPassword:      new FormControl(null, [Validators.minLength(8), Validators.required]),
      confirmPassword:  new FormControl(null, [Validators.minLength(8), Validators.required]),
    });
  }


  cambiaPassword() {
    this.submitActive = true;
    // console.log('Nuova Password: ', this.cambiaPasswordForm.get('newPassword').value);
    if (this.validPassword(this.cambiaPasswordForm.get('newPassword').value) &&
       this.cambiaPasswordForm.get('newPassword').value === this.cambiaPasswordForm.get('confirmPassword').value) {
        this.auth.getNewPassword(this.token, btoa(this.cambiaPasswordForm.get('newPassword').value))
        // tslint:disable-next-line: deprecation
        .subscribe(res => {
          // console.log(res);
          this.loader.start();
          if (res.status === 'OK') {
            this.changePasswordStatusOk = false;
            this.success = true;
            this.changePasswordMSG = res.msg;
            // $('#message').removeClass('alert-danger');
            // $('#message').addClass('alert-success');
          } else {
            this.changePasswordStatusOk = false;
            this.success = false;
          }
          this.changePasswordMSG = res.msg;
          this.loader.stop();
        });
    } else{
      return false;
    }
  }

  validPassword(password: string) {
    const regExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20}$)');
    // RegEx	Description
    // ^	The password string will start this way
    // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
    // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
    // (?=.*[0-9])	The string must contain at least 1 numeric character
    // (?=.*[!@#$%^&*])	The string must contain at least one special character,
    // but we are escaping reserved RegEx characters to avoid conflict
    // (?=.{8,20}$)	The string must be eight characters and longer max 20
    // console.log(regExp.test(password));
    return regExp.test(password);
  }

  checkPwdEquals() {
   return (this.cambiaPasswordForm.get('newPassword').value !== this.cambiaPasswordForm.get('confirmPassword').value) ? false : true;
  }

}
