import { Injectable, TemplateRef } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { AbstractControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  baseUrl: string;

  constructor(private modalService: NgbModal) {
    // this.baseUrl = 'https://enti.postenazionali.it/api-sendtrack/'; // PROD
    this.baseUrl = 'https://enti.postenazionali.it/api-sendtrack/v2/'; // èREPROD
    // this.baseUrl = 'http://localhost/api-sendtrack/'; // svil
  }

  arrotondaNumero(numero, nDecimali): number {
      // arrotondo e ritorno il numero con le cifre decimali indicate
      return  Math.round(numero * Math.pow(10, nDecimali)) / Math.pow(10, nDecimali);
  }

  convertDateForIos(date){
    // console.log('Converto ' + date + ' di tipo ' + typeof(date));
    const arr = date.split(/[- :]/);
    date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return date;
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  sortResults(obj: any, prop, asc: boolean = true) {
    // tslint:disable-next-line: only-arrow-functions
    return obj.sort(function(a, b) {
        if (asc) {
            return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
        } else {
            return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
        }
    });
  }

  dateItToEn(data: string){
    const DATA_TIME_ARRAY = data.split(' ');
    const DATA_ARRAY = DATA_TIME_ARRAY[0].split('/');
    const ANNO    = DATA_ARRAY[2];
    const MESE    = DATA_ARRAY[1];
    const GIORNO  = DATA_ARRAY[0];
    console.log(ANNO + '-' + MESE + '-' + GIORNO + ' ' + DATA_TIME_ARRAY[1]);
    return ANNO + '-' + MESE + '-' + GIORNO + ' ' + DATA_TIME_ARRAY[1];
  }

  dateEnToIt(data: string){
    const DATA_ARRAY = data.split('-');
    const ANNO    = DATA_ARRAY[0];
    const MESE    = DATA_ARRAY[1];
    const GIORNO  = DATA_ARRAY[2];
    console.log(GIORNO + '-' + MESE + '-' + ANNO );
    return GIORNO + '-' + MESE + '-' + ANNO;
  }

  calculateVAT( price, vat ) {
    const value = ( price * vat ) / 100;
    return value;
  }

  parseDate(date){
    if (date){
      // tslint:disable-next-line: max-line-length
      const dataNotFormatted = date;
      // console.log('dataNotFromatted', dataNotFromatted);
      // tslint:disable-next-line: no-string-literal
      const dateFormatted = dataNotFormatted['year'] + '-' + dataNotFormatted['month'] + '-' + dataNotFormatted['day'];
      return dateFormatted;
    } else {
      return null;
    }
  }

  parseDateTime(date){
    if (date){
      // tslint:disable-next-line: max-line-length
      const dataNotFormatted = date;
      // console.log('dataNotFromatted', dataNotFromatted);
      // tslint:disable-next-line: no-string-literal
      const dateFormatted = dataNotFormatted['year'] + '-' + dataNotFormatted['month'] + '-' + dataNotFormatted['day'];
      // tslint:disable-next-line: max-line-length
      // this.configForm.controls.termineFormazioni.setValue(this.configForm.get('termineFormazioniDate').value.year + '-' + this.configForm.get('termineFormazioniDate').value.month + '-' + this.configForm.get('termineFormazioniDate').value.day + ' ' + this.configForm.get('termineFormazioniTime').value.hour + ':' + this.configForm.get('termineFormazioniTime').value.minute );
      return dateFormatted;
    } else {
      return null;
    }
  }

  showError(control: AbstractControl): boolean {
    if (!control.valid && control.dirty && control.touched) {
      return true;
    } else {
      return false;
    }
  }

  open(content: TemplateRef<any>, modalOptions?: NgbModalConfig) {
    // console.log('content:', content);
    this.modalService.open(content, modalOptions).result.then((result) => {
      // console.log(`Closed with: ${result}`);
    }, (reason) => {
      // console.log( `Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  public jsonParse(s: string){
    return JSON.parse(s);
  }

  openFile(url: string){
    if (url !== null){
      window.open(this.getBaseUrl() + url, '_blank').focus();
    }
  }

  onPrint(divName) {
      let  html = '<div style="dispaly: block;">';
      html += $('#' + divName).html();
      html += '</div>';
      // console.log($('#' + divName).html());
      const w = window.open();
      w.document.write($('#' + divName).html());
      w.print();
      w.close();
  }

  softRerender(dtElement: DataTableDirective, dtTrigger: Subject<any>): void{
    dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      dtTrigger.next(true);
    });
  }

  rerenderServerSide(dtElement: DataTableDirective): void {
    try {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
         dtInstance.ajax.reload();
      });
    } catch (err) {
      console.log(err);
    }
  }

  

}
