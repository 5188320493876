<div class="row">
    <div class="col ">
      <h4 class="mt-3"><fa-icon [icon]="faEnvelopeOpenText"></fa-icon> Postalizzazioni</h4>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a routerLink="/dashboard/home" class="hover-dark" ngbTooltip="Vai alla Home">Dashboard</a> / <a routerLink="/dashboard/postalizzazioni" class="hover-dark" ngbTooltip="Vai alle postalizzazioni">Postalizzazioni</a>
    </div>
  </div>
  <hr />
  <table
  datatable
  [dtOptions]="dtOptions"
  #dtShipping
  class="row-border hover w-100"
>
  <thead>
    <tr>
      <th><fa-icon [icon]="faTools"></fa-icon></th>
      <th>Id</th>
      <th>Codice Distinta</th>
      <th>Id Mittente</th>
      <th>Mittente</th>
      <th>Gruppo</th>
      <th>Username</th>
      <th>Stato</th>
      <th>Data inserimento</th>
      <th>Dettaglio</th>
      <th>Download</th>
    </tr>
  </thead>
</table>

<!-- Nuova Distinta -->
<ng-template #newShippingModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faEnvelopeOpenText"></fa-icon> Nuova distinta
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <form action="" [formGroup]="shippingForm" class="justify-content-center">
        <ng-container >
          <fieldset class="border p-3">
            <legend  class="w-auto">Dati spedizione</legend>
            <div *ngIf="enabledUpload" class="col-12 my-2">
              <div class="form-row ">
                <label for="fileStampa">File di Stampa</label>
                <ngx-dropzone (change)="onSelect($event); " [maxFileSize]="maxFileSize" class="w-100" [multiple]='false' [showPreviews]="true" [accept]="'application/pdf'">
                  <ngx-dropzone-label class="border-0">Clicca o trascina il file</ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"  (removed)="onRemove(f)" >
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
            </div>
            <div formArrayName="newPostalizzazioni"
                 *ngFor="let item of shippingForm.get('newPostalizzazioni')['controls']; let i = index;">
              <div class="row p-2 border m-2" [formGroupName]="i">
                
                <div class="col-12">
                  <h5>Spedizione {{ i + 1 }}</h5>
                </div>
                <div class="col-12 col-sm-3 my-2">
                  <div class="form-row">
                    <div class="form-group w-100" >
                      <label for="tipoSpedizione">Tipo spedizione *</label>
                      <select (change)="clearInput(item)" autofocus tabindex="1"  name="tipoSpedizione" class="form-control "formControlName="tipoSpedizione">
                          <option value="-1">Seleziona...</option>
                          <ng-container *ngFor="let categoria of categorieSpedizioni">
                            <optgroup label="{{ categoria.nome }}">
                              <ng-container *ngFor="let tSpedizione of categoria.spedizioni">
                                  <option value="{{ tSpedizione.idSpedizione }}">{{ tSpedizione.nome }}</option>
                              </ng-container>
                            </optgroup>
                          </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 my-2">
                  <div class="form-row"  >
                    <div class="form-group w-100" >
                      <label for="denominazione">Destinatario *</label>
                      <input
                        type="text"
                        name="denominazione"
                        class="form-control"
                        formControlName="denominazione"
                        #nome
                        placeholder="nome o ragione sociale..."
                      />
                    </div>
                    <div *ngIf="utils.showError(item.get('denominazione'))" class="alert alert-danger">
                      Deominazione destinatario non valida.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 my-2">
                  <div class="form-row"  >
                    <div *ngIf="!isInternational(item.get('tipoSpedizione').value)" class="form-group w-100" >
                      <label for="provincia">Provincia *</label>
                      <select (change)="getComuni(i)" name="provincia" class="form-control "formControlName="provincia">
                          <option value="-1">Seleziona...</option>
                          <ng-container *ngFor="let provincia of province">
                            <option value="{{ provincia }}">{{ provincia }}</option>
                          </ng-container>
                      </select>
                    </div>
                    <div *ngIf="isInternational(item.get('tipoSpedizione').value)" class="form-group w-100" >
                      <label for="provincia">Stato *</label>
                      <input  name="provincia" class="form-control "formControlName="provincia"  placeholder="es. Francia...">
                      <div *ngIf="utils.showError(item.get('provincia'))" class="alert alert-danger">
                        Provincia non valida.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 my-2">
                  <div class="form-row"  >
                    <div *ngIf="!isInternational(item.get('tipoSpedizione').value)" class="form-group w-100" >
                      <label for="citta">Comune *</label>
                      <select (change)="getCap(i)" name="citta" class="form-control "formControlName="citta">
                        <option value="-1">Seleziona...</option>
                        <ng-container *ngFor="let comune of comuni[i]">
                          <option value="{{ comune.Comune }}">{{ comune.Comune }}</option>
                        </ng-container>
                      </select>
                    </div>
                    <div *ngIf="isInternational(item.get('tipoSpedizione').value)" class="form-group w-100" >
                      <label for="citta">Comune *</label>
                      <input name="citta" class="form-control "formControlName="citta" placeholder="es. Bordeaux...">
                      <div *ngIf="utils.showError(item.get('citta'))" class="alert alert-danger">
                        Comune non valido.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 my-2">
                  <div class="form-row"  >
                    <div *ngIf="!isInternational(item.get('tipoSpedizione').value)" class="form-group w-100" >
                      <label for="cap">Cap *</label>
                      <select name="cap" class="form-control "formControlName="cap">
                        <option value="-1">Seleziona...</option>
                        <ng-container *ngFor="let cap of caps[i]">
                          <option value="{{ cap.CAP }}">{{ cap.CAP }}</option>
                        </ng-container>
                      </select>
                    </div>
                    <div *ngIf="isInternational(item.get('tipoSpedizione').value)" class="form-group w-100" >
                      <label for="cap">Zipcode *</label>
                      <input name="cap" class="form-control "formControlName="cap" placeholder="es. AF06...">
                    </div>
                    <div *ngIf="utils.showError(item.get('provincia'))" class="alert alert-danger" >
                      Cap non valido.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 my-2">
                  <div class="form-row"  >
                    <div class="form-group w-100" >
                      <label for="indirizzo">Indirizzo *</label>
                      <input
                        type="text"
                        name="indirizzo"
                        required
                        class="form-control "
                        formControlName="indirizzo"
                        placeholder="Via Roma 1"
                      />
                    </div>
                    <div *ngIf="utils.showError(item.get('indirizzo'))" class="alert alert-danger">
                      Indirizzo non valido.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 my-2">
                  <div class="form-row"  >
                    <div class="form-group w-100" >
                      <label for="note">Note (verranno aggiunte sulla ricevuta)</label>
                      <textarea
                        type="text"
                        name="note"
                        class="form-control "
                        formControlName="note"
                        placeholder="Note..."
                        maxlength="12"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 my-2">
                  <div class="form-row"  >
                    <div class="form-group w-100" >
                      <label for="infoAggiuntive">Info aggiuntive (per uso interno)</label>
                      <textarea
                        type="text"
                        name="infoAggiuntive"
                        class="form-control "
                        formControlName="infoAggiuntive"
                        maxlength="100"
                        placeholder="Info aggiuntive"
                      ></textarea>
                    </div>
                  </div>
                </div>
               
                <div class="col-12  pt-5">
                  <div class="form-group float-end pt-3">
                    <button type="button" ngbTooltip="Rimuovi la spedizione" class="btn btn-secondary"  (click)="removeItem(i)"> <fa-icon [icon]="faTrash"></fa-icon> Rimuovi</button>
                  </div>
                </div> 
                
              </div>
            </div> 
            
            <div class="form-row pt-3">
              <div class="form-group col-md-4">
                <button type="button" ngbTooltip="Aggiungi un'altra spedizione" class="btn btn-blu"  (click)="addItem()">
                  <fa-icon [icon]="faPlus"></fa-icon> Aggiungi destinatario
                </button>
              </div>
            </div>
          </fieldset>
        </ng-container>
        <p class="mt-2">
          <b>* Campi obbligatori</b>
        </p>
        <div class="modal-footer">
          <button
            type="submit"
            [className]="(!shippingForm.valid)?'btn btn-blu isDisabled':'btn btn-blu'"
            [disabled]="!shippingForm.valid"
            ngbTooltip="Aggiungi la richiesta di postalizzazione"
            (click)="add()"
          ><fa-icon [icon]="faSave"></fa-icon>
            Inserisci distinta
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- Dettaglio distinta -->
<ng-template #dettaglioDistintaModal let-modal class="">
  <div class="modal-content " >
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faEnvelopeOpenText"></fa-icon> Distinta {{ nDistinta }}
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <ngx-ui-loader [loaderId]="'loaderPostalizzazioni'"></ngx-ui-loader>

        <div class="row">
          <div class="col-12 col-sm-12">

            <ng-container  >
              <div class="row">
                <div class="table-responsive " id="dettaglioDistinta">
                  <table class="table table-hover table-striped page" >
                    <caption class="p-2">Id Distinta: {{ idDistinta }}<br>Dettaglio postalizzazioni {{ idMittente }} - {{ mittente }} - {{ username }} del {{ dataInserimento }}</caption>
                    <thead>
                      <tr>
                        <th *ngIf="gold"><fa-icon [icon]="faTools"></fa-icon></th>
                        <th scope="col">#</th>
                        <th scope="col">Tipo spedizione</th>
                        <th scope="col">Destinatario</th>
                        <th scope="col">Provincia</th>
                        <th scope="col">Citta</th>
                        <th scope="col">Cap</th>
                        <th scope="col">Indirizzo</th>
                        <th scope="col">Note</th>
                        <th scope="col">Info aggiuntive</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let post of postalizzazioni; let i = index" >
                        <tr id="row-dettaglio-{{ post.idPostalizzazione }}" [className]="(!post.enabled)?'alert alert-warning':''" >
                          <td [className]="(!post.enabled)?'alert alert-warning':''" *ngIf="gold && (post['statoDistinta'] == 'APERTA'|| post['statoDistinta']  === 'LAVORAZIONE') ">           
                            <fa-icon *ngIf="!post.enabled" popoverTitle="Spedizione annullata" class="cur" popoverClass="alert alert-info" ngbPopover="{{ post.noteAnnullamento }}" [icon]="faInfoCircle" placement="end" ></fa-icon>
                            <a (click)="annullaSpedizione(post)" 
                               *ngIf="post.enabled"
                               class="cur"
                               ngbTooltip="Annulla la spedizione"
                               id="plus-{{ post.idPostalizzazione }}" 
                               >
                              <fa-icon [icon]="faTrash"    ></fa-icon>
                            </a>
                            <a (click)="annullaSpedizione(post)" 
                               class="cur"
                               style="display: none;" 
                               ngbTooltip="Torna indietro"
                               id="minus-{{ post.idPostalizzazione }}"
                               >
                              <fa-icon [icon]="faAngleLeft"  ></fa-icon>
                            </a>
                          </td>
                          <th [className]="(!post.enabled)?'alert alert-warning':''" scope="row">{{ i+1 }}</th>
                          <td [className]="(!post.enabled)?'alert alert-warning':''" >{{ post['tipoSpedizione'] }}</td>
                          <td [className]="(!post.enabled)?'alert alert-warning':''">{{ post.denominazione }}</td>
                          <td [className]="(!post.enabled)?'alert alert-warning':''">{{ post.provincia }}</td>
                          <td [className]="(!post.enabled)?'alert alert-warning':''">{{ post['citta'] }}</td>
                          <td [className]="(!post.enabled)?'alert alert-warning':''">{{ post.cap }}</td>
                          <td [className]="(!post.enabled)?'alert alert-warning':''">{{ post.indirizzo }}</td>
                          <td [className]="(!post.enabled)?'alert alert-warning':''">{{ post.note }}</td>
                          <td [className]="(!post.enabled)?'alert alert-warning':''">{{ post.infoAggiuntive }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>       
            
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="modal.dismiss('Cross click')"
          >
            Chiudi
          </button>
          
          <button styleSheetFile="assets/css/print.css" 
                  printTitle="Stampa Distinta" 
                  class="btn btn-danger"
                  id="printButton" 
                  printSectionId="dettaglioDistinta" 
                  (click)="printDivContent()">
            <fa-icon [icon]="faPrint"></fa-icon>
            Stampa
          </button>


        </div>
    </div>
  </div>
</ng-template>

<!-- Chiudi distinta -->
<ng-template #chiudiDistintaModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faLockOpen"></fa-icon> Chiudi distinta 
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-12">
            <form action="" [formGroup]="chiudiDistintaForm" class="justify-content-center">
                <div class="form-row"  >
                  <div class="form-group w-100" >
                    <label for="codiceDistinta">Codice distinta *</label>
                    <input
                      type="number"
                      name="codiceDistinta"
                      class="form-control"
                      formControlName="codiceDistinta"
                      #nome
                      placeholder="Es. 1"
                    />
                  </div>
                  <div *ngIf="utils.showError(chiudiDistintaForm.get('codiceDistinta'))" class="alert alert-danger">
                    Codice distinta non valido.
                  </div>
                </div>
                <div class="form-row ">
                  <label for="fileDistinta">File distinta *</label>

                  <ngx-dropzone (change)="onSelect($event); " [maxFileSize]="maxFileSize" class="w-100" [multiple]='false' [showPreviews]="true" [accept]="'application/pdf'">
                    <ngx-dropzone-label>Clicca o trascina il file</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"  (removed)="onRemove(f)" >
                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  </ngx-dropzone>
    
                  <!-- <ngx-dropzone-image-preview  ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"> -->
                    <!-- <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
                  <!-- </ngx-dropzone-image-preview> -->
                </div>
            </form>
          </div>
        </div>
        <p class="mt-2">
          <b>* Campi obbligatori</b>
        </p>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-blu"
            (click)="chiudiDistintaConfirm()"
            ngbTooltip="Chiudi la distinta"
            [disabled]="!chiudiDistintaForm.valid || files.length == 0 "
          >
            Chiudi
          </button>
        </div>
    </div>
  </div>
</ng-template>

<table id="excelTable" style="visibility: hidden;">
  <tr>
    <th>Barcode</th>
    <th>Peso</th>
    <th>Tipo spedizione</th>
    <th>Denominazione</th>
    <th>Indirizzo</th>
    <th>Cap</th>
    <th>Comune</th>
    <th>Provincia</th>
    <th>Info Aggiuntive</th>
    <th>Note</th>
  </tr>
  <ng-container *ngFor="let post of postalizzazioni;">
    <tr  *ngIf="post.enabled">
        <td></td>
        <td></td>
        <td>{{ post['tipoSpedizione'] }}</td>
        <td>{{ post.denominazione }}</td>
        <td>{{ post.indirizzo }}</td>
        <td>{{ post.cap }}</td>
        <td>{{ post['citta'] }}</td>
        <td>{{ post.provincia }}</td>
        <td>{{ post.infoAggiuntive }}</td>
        <td>{{ post.note }}</td>
    </tr> 
  </ng-container>

</table>



<!-- Elimina distinta -->
<ng-template #deleteDistintaModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faEnvelopeOpenText"></fa-icon> Elimina distinta
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close "
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <p>
        Sei sicoro di voler eliminare la distinta <b>{{ distintaToDelete }}</b> ?
      </p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-5  ">
          <button
            class="btn btn-secondary"
            (click)="modal.dismiss('Cross click')"
          >
            Annulla
          </button>
        </div>
        <div class="col-7">
          <button class="btn btn-blu" (click)="deleteDistintaConfirm()">
            Conferma
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>