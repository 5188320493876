import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtentiService extends BaseService {
  private apiUrlGetUsers              = '/getUsers';
  private apiUrlGetUsersDT            = '/getUsersDT';
  private apiUrlEditUsers             = '/modUser';
  private apiUrlEditPassword          = '/modificaPassword';
  private apiUrlDeleteAccount         = '/delUser';
  private apiUrlAddUser               = '/addUser';
  private apiUrlAddDelegate           = '/addDelegate';
  private apiUrlCompletaRegistrazione = '/completaRegistrazione';
  private apiUrlGetNUsers             = '/getNUsers';
  private apiResendLink               = '/resendLink';

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
                super(http, auth, route, message);
  }

  getUsers(param = {}) {
    return this.get(this.apiUrlGetUsers, param, true);
  }

  getUsersDT(param = {}) {
    return this.post(this.apiUrlGetUsersDT, param, true);
  }

  editUtente(param) {
    return this.post(this.apiUrlEditUsers, param, true);
  }

  modificaPassword(param) {
    return this.post(this.apiUrlEditPassword, param, true);
  }

  deleteAccount(idUtente) {
    const param = {id : idUtente};
    return this.post(this.apiUrlDeleteAccount, param, true);
  }

  resendLink(idUtente) {
    const param = {id : idUtente};
    return this.post(this.apiResendLink, param, true);
  }

  addUser(param = {}) {
    return this.post(this.apiUrlAddUser, param, true);
  }

  addDelegate(param = {}) {
    return this.post(this.apiUrlAddDelegate, param, true);
  }

  completaRegistrazione(param = {}) {
    return this.post(this.apiUrlCompletaRegistrazione, param, true);
  }

  getNUsers(param = {}){
    return this.get(this.apiUrlGetNUsers, param, true);
  }
}
