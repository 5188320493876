import { Component, OnInit } from '@angular/core';
import {
         faBuilding,
         faChevronCircleLeft,
         faChevronCircleRight,
         faCogs,
         faDatabase,
         faDolly,
         faEnvelopeOpenText,
         faPeopleGroup,
         faTachometerAlt,
         faUser
} from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  // icone
  faUser                = faUser;
  faTachometerAlt       = faTachometerAlt;
  faCogs                = faCogs;
  faEnvelopeOpenText    = faEnvelopeOpenText;
  faChevronCircleLeft   = faChevronCircleLeft;
  faChevronCircleRight  = faChevronCircleRight;
  faDatabase            = faDatabase;
  faBuilding            = faBuilding;
  faPeopleGroup         = faPeopleGroup;
  faDolly               = faDolly;
  sideBarClose: boolean;

  tipoUtente: string;

  constructor(private auth: AuthService) {
    this.sideBarClose = false;
    this.tipoUtente = auth.getTypeUser().toLowerCase().trim() ;
    // console.log(this.tipoUtente);
  }

  ngOnInit(): void {
  }

  toggleSideBar(e) {
    e.preventDefault();
    this.sideBarClose = !this.sideBarClose;
  }
}
