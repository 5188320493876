<nav class="navbar navbar-expand-lg navbar-dark bg-dark border-bottom py-3 ">
  <!-- <button class="btn btn-primary" id="menu-toggle">Toggle Menu</button> -->

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div *ngIf="linkBanner !== null" class="" style="height: 40px;">
    <img [src]="baseUrl + linkBanner" class="img-fluid" style="max-height: 40px;"/>
  </div>
  <!-- <div class="ms-3 me-2 text-right float-rigth"><fa-icon [icon]="faBell" class="text-light"></fa-icon></div> -->

  <div class="collapse navbar-collapse " id="navbarSupportedContent">

    <ul class="navbar-nav ms-auto mt-2 mt-lg-0 ">
      <li class="nav-item active">
        <!-- <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a> -->
      </li>
      <li class="nav-item">
        <!-- <a class="nav-link" href="#">Link</a> -->
      </li>
      <li class="nav-item dropdown">
        
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown" id="dropdownProfile">
          {{ username }}
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfile">
          <a class="dropdown-item cur" (click)="openProfilo()" >Profilo</a>
          <!-- <a class="dropdown-item" href="#">Another action</a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" class="dropdown-item bg-light text-secondary"  routerLink=""  (click)="logout()" routerLinkActive="active">Esci</a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<!-- Modifica profilo -->
<ng-template #newProfilo let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title" > <fa-icon [icon]="faUser" ></fa-icon> Profilo</h5>
      <a  class="close cur"  (click)="modal.dismiss('Cross click')" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <form action="" [formGroup]="profiloForm" class="justify-content-center" >
        <fieldset>
          <div class="form-row ">
            <div class="row">

            <div class="col-6 my-3">
              <div class="form-group w-100">
                <label for="email ">Email  </label>
                <input type="email" name="email"   class="form-control my-1" formControlName="email" #email placeholder="email..." />
              </div>
            </div>
            <div class="col-6 my-3">
              <div class="form-group w-100">
                <label for="username">Username  </label>
                <input type="text" name="username"   class="form-control my-1" formControlName="username" #username />
              </div>
            </div>
            <div class="col-6 my-3" >
              <div class="form-group w-100">
                <label for="newPassword">Nome </label>
                <input type="text" name="nome"   class="form-control my-1" formControlName="nome"  placeholder="nome..." />
              </div>
            </div>
            <div class="col-6 my-3">
              <div class="form-group w-100">
                <label for="confermPassword">Cognome </label>
                <input type="text" name="cognome"   class="form-control my-1" formControlName="cognome"  placeholder="cognome..." />
              </div>
            </div>
            </div>

          </div>
          <div class="form-row ">
            
            
          </div>
          <div class="form-row ">
            <div class="row">

            <div class="col-4">
              <div class="form-group w-100 py-2">
                <label for="oldPassword">Password attuale</label>
                <!-- <input type="password" name="oldPassword"  required class="form-control" formControlName="oldPassword" #oldPassword autocomplete="true" placeholder="password attuale" /> -->
                <div class="input-group my-1">
                  <input  [type]="(showCurrentPassword) ? 'text' : 'password'" class="form-control " name="oldPassword" autocomplete="on" required formControlName="oldPassword" #oldPassword placeholder="password">
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <fa-icon class="cur" [ngbTooltip]="(!showCurrentPassword) ? 'Mostra la password' : 'Nascondi la password'" [icon]="(showCurrentPassword) ? faEye : faEyeSlash" (click)="toggleShowCurrentPassword()"></fa-icon>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group w-100 py-2">
                <label for="newPassword ">Modifica Password *</label>
                <div class="input-group my-1">
                  <input  [type]="(showNewPassword) ? 'text' : 'password'" class="form-control" name="newPassword" autocomplete="on" required formControlName="newPassword" #newPassword placeholder="nuova password">
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <fa-icon class="cur" [ngbTooltip]="(!showNewPassword) ? 'Mostra la password' : 'Nascondi la password'" [icon]="(showNewPassword) ? faEye : faEyeSlash" (click)="toggleShowNewPassword()"></fa-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="!validPassword(this.profiloForm.get('newPassword').value) && utils.showError(this.profiloForm.get('newPassword'))" class="alert alert-danger">
                Password non valida.
              </div>
            </div>
            <div class="col-4">
              <div class="form-group w-100 py-2">
                <label for="confermPassword">Conferma Password  </label>
                <div class="input-group my-1">
                  <input  [type]="(showConfirmPassword) ? 'text' : 'password'" class="form-control" name="confermPassword" autocomplete="on" required formControlName="confermPassword" #confermPassword placeholder="conferma password">
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <fa-icon class="cur" [ngbTooltip]="(!showConfirmPassword) ? 'Mostra la password' : 'Nascondi la password'" [icon]="(showConfirmPassword) ? faEye : faEyeSlash" (click)="toggleShowConfirmPassword()"></fa-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="this.profiloForm.get('newPassword').value !== this.profiloForm.get('confermPassword').value && this.profiloForm.get('confermPassword').value !== null" class="alert alert-danger">
                Le Password non corrispondono.
              </div>
            </div>
            </div>

          </div>
          <div class=" alert alert-danger d-none" id="errorMSG">
            {{ msg }}
          </div>
        </fieldset>
        <p class="mt-2">
          <b>* La password deve essere compresa tra gli 8 e i 20 caratteri, contenere almeno una lettera minuscola, una maiuscola un numero ed un carattere speciale compreso tra !@#$%^&</b>
        </p>
        <div class="modal-footer">
          <button  type="button" class="btn btn-primary btn-blu" id="btn-mod" [disabled]="!profiloForm.valid"  ngbTooltip="Modifica la password" (click)="confirmEdit(); ">Modifica</button>
        </div>

      </form>
    </div>
  </div>
</ng-template>
