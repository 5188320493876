import { ChartConfiguration, ChartDataset, ChartOptions, ChartType } from 'chart.js';
// import { Color } from 'ng2-charts';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
         faChartLine,
         faChevronDown,
         faChevronUp
} from '@fortawesome/free-solid-svg-icons';

import { BaseChartDirective } from 'ng2-charts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PostalizzazioniService } from 'src/app/services/postalizzazioni/postalizzazioni.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart? : BaseChartDirective;

  lineChartType: ChartType = 'line';

  // lineChartData: ChartDataset[] = [
  //   { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Postalizzazioni / Mese' },
  // ];

  

  lineChartLabels: String[] = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre' , 'Ottobre', 'Novembre', 'Dicembre'];
  
  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: this.lineChartLabels,
    datasets: []
  };

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: any[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];

  faChevronUp         = faChevronUp;
  faChevronDown       = faChevronDown;
  faChartLine         = faChartLine;
  close: boolean;

  constructor(private loader: NgxUiLoaderService,
              private postalizzazioniService: PostalizzazioniService) {
    this.close = false;
    this.loader.startLoader('nChartLineLoader');
    const nodo = {
      data: [0,2,3,5,6,7,8],
      label: 'Postalizzazioni / Mese',
      fill: true,
      tension: 0.5,
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)'
    }
    this.lineChartData.datasets.push(nodo)
    this.postalizzazioniService.getStatsMonthPostalizzazioni()
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK'){

        resp.items.forEach(element => {
          
          this.lineChartData.datasets[0].data.push(element.nPostalizzazioni)
          // this.lineChartData.labels[0].data.push(element.nPostalizzazioni)
          
        });

        // aggiorno il dataset del grafico
        setTimeout(() => {
          if (this.chart && this.chart.chart && this.chart.chart.config) {
            // console.log("Cambio il dataset");
            this.chart.chart.update();
          }
        });
      }
    });


    this.loader.stopLoader('nChartLineLoader');
  }

  ngOnInit(): void {
  }

  toggleWidget(){
    this.close = !this.close;
  }

}
