<ngx-ui-loader [loaderId]="'nChartPieLoader'"></ngx-ui-loader>
<div class="card shadow card-widget border rounded m-1 " id="pieChartsWrapper"  style="height: 98%" >
  <div class="card-header h5"> 
    <fa-icon class="" [icon]="faChartPie"></fa-icon>
    Tipi Spedizione
      <div class="float-end">
          <fa-icon class="cur" (click)="toggleWidget()" [icon]="(!close)?faChevronUp:faChevronDown"></fa-icon>
      </div>
  </div>
  <div *ngIf="!close" class="card-body text-secondary" style="display: block;  height: 100%">
    <div class="chart-pie-wrapper" >
      <canvas baseChart
      [datasets]="pieChartData"
      [labels]="pieChartLabels"
      [type]="'pie'"
      [options]="pieChartOptions"
      [plugins]="pieChartPlugins"
      [legend]="pieChartLegend"
      [height]="chartHeight">

      >
    </canvas>
    </div>
  </div>
</div>