<ngx-ui-loader [loaderId]="'nMailsLoader'"></ngx-ui-loader>
<div class="card shadow card-widget-recap border rounded m-1 " id="recapMailsWrapper" >
    <div class="card-header h5"> 
        <fa-icon class="" [icon]="faTruckMoving"></fa-icon>
        Spedizioni
        <div class="float-end">
            <fa-icon class="cur" (click)="toggleWidget()" [icon]="(!close)?faChevronUp:faChevronDown"></fa-icon>
        </div>
    </div>
    <div *ngIf="!close" class="card-body text-secondary">
      <h5 class="card-title ">
        <fa-icon class="fa-2x" [icon]="faEnvelopeOpenText"></fa-icon>
        <span class="badge bg-blu rounded-pill text-light">{{ nMails}}</span>
      </h5>
      <p class="card-text ">

      </p>
      
    </div>
    <div class="card-footer bg-transparent m-0 p-0" style="max-width: 18rem;">
      <div class="row m-0 p-0">
        <div class="col text-left m-0 p-0">
        </div>
        <div class="col text-right pt-3 m-0 p-0">
          <a href="" class="text-secondary">
          </a>
        </div>
      </div>
    </div>
  </div>