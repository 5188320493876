import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { IResponse } from '../interfaces/response';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var __env: any;
@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {

  private token: string;

  // label
  noResponse: string;
  error: any;
  redirect: any;

  // dict
  errorDecode = [
    {
      servicePath : '/domain/get',  // Servizio da gestire in caso di errore
      redirect: { '-1': 'login', '-2': false, '-3': false, '-4': false}
      // Per ogni codice di errore immettere il link
      // se è presente un redirect, false altrimenti
    }
];

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
                this.redirect = false;
              }

  protected post(url: string, params, json: boolean = false) {
    this.token = this.auth.getToken();
    // const contentType = (json) ? 'application/json' : 'application/x-www-form-urlencoded';
    const contentType = 'application/x-www-form-urlencoded';

    const headers = new HttpHeaders({ 'Content-Type': contentType, token: this.token });
    const options = { headers };
    const parametri = new URLSearchParams();

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
      //   const element = object[key];
          // console.log(key + ': ' + params[key] + ' - ' + typeof(params[key]));
          if (typeof(params[key]) === 'object'){
            params[key] = JSON.stringify(params[key]);
          }
          parametri.set(key, params[key]);
                   //  console.log(typeof parametri.get(key));
      }
    }

    const body = parametri.toString();

    return this.http.post(__env.apiUrl + url, body, options).pipe(map(
        (payload: IResponse) => {
          const messages = this.message.findDuplicate(payload.msg, payload.msg, true, false);

          // console.log('paylod.status', payload.status);
          // console.log('paylod.errorCode', Number(payload.errorCode));

          // console.log(typeof(this.errorDecode.find(item => item.servicePath === url)));

          this.redirect = this.getRedirect(payload.status, url);
          if (payload.status === 'KO') {

            switch (Number(payload.errorCode)) {
              case -1: // token scaduto o non valido, torna alla login
                  this.error = 'Errore';
                  this.message.error(payload.msg, this.error);
                  localStorage.clear();
                  this.route.navigate(['/']);
                  break;
              case -2: // errore logici
                  if (!messages) {
                    this.error = 'Attenzione';
                    this.message.warning(payload.msg, this.error);
                    if ( this.redirect !== false ) {
                        this.route.navigate([this.redirect]);
                    }
                  }
                  break;
              case -3: // eccezioni / errori generici backend
                  if (!messages) {
                    this.error = 'Errore';
                    this.message.error(payload.msg, this.error);
                    if ( this.redirect !== false ) {
                      this.route.navigate([this.redirect]);
                    }
                  }
                  break;
              case -4: // errore sui privilegi/permessi
                  if (!messages) {
                    this.error = 'Warning';
                    this.message.warning(payload.msg, this.error);
                    if ( this.redirect !== false ) {
                      this.route.navigate([this.redirect]);
                    }
                  }
                  break;
              default:
                this.error = 'Errore';
                this.message.error('Errore imprevisto dal server. Contattare il supporto tecnico', this.error);
                break;
            }
          }
          return payload;
        }
      ), catchError(error => {
          if (error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }

          // ...optionally return a default fallback value so app can continue (pick one)
          // which could be a default value
          // return Observable.of<any>({my: "default value..."});
          // or simply an empty observable
          // return Observable.empty<T>();
          return  of<any>({status: 'KO', msg: this.noResponse, items: {}, errorCode: -2});
      }));
    }

    protected postObservable(url: string, params, json: boolean = false): Observable<IResponse> {
      this.token = this.auth.getToken();
      const contentType = 'application/json';
      // if (1 == 1)
      //   contentType = this.contentTypeJSON
      // else
      //   contentType = this.contentTypeURL

      const headers = new HttpHeaders({ 'Content-Type': contentType,  token: this.token });

      const options = { headers };

      return this.http.post<IResponse>(__env.apiUrl + url, params, options).pipe(map(
          (payload: IResponse) => {
          const messages = this.message.findDuplicate(payload.msg, payload.msg, true, false);
          this.redirect = this.getRedirect(payload.status, url);

          switch (payload.status) {
              case '-1': // token scaduto o non valido, torna alla login
                  this.message.error(payload.msg, this.error);
                  if ( this.redirect !== false ) {
                    this.route.navigate([this.redirect]);
                  }
                  break;
              case '-2': // errore logici
                    if (!messages) {
                      this.message.warning(payload.msg, this.error);
                      if ( this.redirect !== false ) {
                          this.route.navigate([this.redirect]);
                      }
                    }
                    break;
              case '-3': // eccezioni / errori generici backend
                    if (!messages) {
                      this.message.error(payload.msg, this.error);
                      if ( this.redirect !== false ) {
                        this.route.navigate([this.redirect]);
                      }
                    }
                    break;
              case '-4': // errore sui privilegi/permessi
                    if (!messages) {
                      this.message.warning(payload.msg, this.error);
                      if ( this.redirect !== false ) {
                        this.route.navigate([this.redirect]);
                      }
                    }
                    break;
            }
          return payload;
          }
        ), catchError(error => {
            if (error instanceof Error) {
              // A client-side or network error occurred. Handle it accordingly.
              console.error('An error occurred:', error.message);
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
              this.message.error('Il backend non risponde', 'Errore');

            }

            // ...optionally return a default fallback value so app can continue (pick one)
            // which could be a default value
            // return Observable.of<any>({my: "default value..."});
            // or simply an empty observable
            // return Observable.empty<T>();
            return  of<any>({status: '-3', msg: this.noResponse, items: {}});
        }));
      }

    getRedirect(status, url) {
      let ret = false;
      if (status !== 'OK') {
        const checkService = this.errorDecode.find(item => item.servicePath === url);
        if ( checkService ) {
          console.log(checkService.redirect[status]);
          if (this.redirect === 'login') {
            localStorage.removeItem('loginData');
          }
          ret = checkService.redirect[status];
        }
      }
      return ret;
    }

    protected get(url: string, param, json: boolean = false) {
      // console.log('Recupero il token...');
      this.token = this.auth.getToken() || '';
      // console.log('base service... getToken', this.token);
      const contentType = 'application/x-www-form-urlencoded';
      let headers;
      if (this.token !== '' && this.token !== null) {
         headers = new HttpHeaders({ 'Content-Type': contentType, token: this.token });
      } else {
        headers = new HttpHeaders({ 'Content-Type': contentType });
      }
      // console.log(param);
      const p = { params: param };
      // console.log(p);
      // tslint:disable-next-line: one-variable-per-declaration
      const options = { headers, ...p};
      // console.log(options);
      return this.http.get(__env.apiUrl + url, options).pipe(map(
          (payload: IResponse) => {
            // console.log('status: ', payload.status);
            // console.log('errorCode: ', payload.errorCode);
            const messages = this.message.findDuplicate(payload.msg, payload.msg, true, false);

            // console.log('paylod.status', payload.status);

            // console.log(typeof(this.errorDecode.find(item => item.servicePath === url)));

            // this.redirect = this.getRedirect(payload.status, url);
            if (payload.status === 'KO') {
              switch (Number(payload.errorCode)) {
                case -1: // token scaduto o non valido, torna alla login
                     this.error = 'Errore';
                     this.message.error(payload.msg, this.error);
                     localStorage.clear();
                     this.route.navigate(['/']);
                     break;
                case -2: // errore logici
                    if (!messages) {
                      this.error = 'Warning';
                      this.message.warning(payload.msg, this.error);
                      if ( this.redirect !== false ) {
                          this.route.navigate([this.redirect]);
                      }
                    }
                    break;
                case -3: // eccezioni / errori generici backend
                    if (!messages) {
                      this.error = 'Errore';
                      this.message.error(payload.msg, this.error);
                      if ( this.redirect !== false ) {
                        this.route.navigate([this.redirect]);
                      }
                    }
                    break;
                case -4: // errore sui privilegi/permessi
                    if (!messages) {
                      this.error = 'Warning';
                      this.message.warning(payload.msg, this.error);
                      if ( this.redirect !== false ) {
                        this.route.navigate([this.redirect]);
                      }
                    }
                    break;
                default:
                  this.error = 'Errore';
                  this.message.error('Errore imprevisto dal server. Contattare il supporto tecnico', this.error);
                  break;
              }
            }

            return payload;
          }
        ), catchError(error => {
            if (error instanceof Error) {
              // A client-side or network error occurred. Handle it accordingly.
              console.error('An error occurred:', error.message);
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
              this.message.error('Il server non risponde', 'Errore');
            }

            // ...optionally return a default fallback value so app can continue (pick one)
            // which could be a default value
            // return Observable.of<any>({my: "default value..."});
            // or simply an empty observable
            // return Observable.empty<T>();
            return  of<any>({status: '-3', msg: this.noResponse, items: {}});
        }));
      }

      protected patch(url: string, param, json: boolean = false) {
        // console.log('Recupero il token...');
        this.token = this.auth.getToken() || '';
        // console.log('base service... getToken', this.token);
        const contentType = 'application/x-www-form-urlencoded';
        let headers;
        if (this.token !== '' && this.token !== null) {
           headers = new HttpHeaders({ 'Content-Type': contentType, token: this.token });
        } else {
          headers = new HttpHeaders({ 'Content-Type': contentType });
        }
        // console.log(param);
        const p = { params: param };
        // console.log(p);
        // tslint:disable-next-line: one-variable-per-declaration
        const options = { headers, ...p};
        // console.log(options);
        return this.http.patch(__env.apiUrl + url, options).pipe(map(
            (payload: IResponse) => {
              // console.log('status: ', payload.status);
              // console.log('errorCode: ', payload.errorCode);
              const messages = this.message.findDuplicate(payload.msg, payload.msg, true, false);

              // console.log('paylod.status', payload.status);

              // console.log(typeof(this.errorDecode.find(item => item.servicePath === url)));

              // this.redirect = this.getRedirect(payload.status, url);
              if (payload.status === 'KO') {
                switch (Number(payload.errorCode)) {
                  case -1: // token scaduto o non valido, torna alla login
                       this.error = 'Errore';
                       this.message.error(payload.msg, this.error);
                       localStorage.clear();
                       this.route.navigate(['/']);
                       break;
                  case -2: // errore logici
                      if (!messages) {
                        this.error = 'Warning';
                        this.message.warning(payload.msg, this.error);
                        if ( this.redirect !== false ) {
                            this.route.navigate([this.redirect]);
                        }
                      }
                      break;
                  case -3: // eccezioni / errori generici backend
                      if (!messages) {
                        this.error = 'Errore';
                        this.message.error(payload.msg, this.error);
                        if ( this.redirect !== false ) {
                          this.route.navigate([this.redirect]);
                        }
                      }
                      break;
                  case -4: // errore sui privilegi/permessi
                      if (!messages) {
                        this.error = 'Warning';
                        this.message.warning(payload.msg, this.error);
                        if ( this.redirect !== false ) {
                          this.route.navigate([this.redirect]);
                        }
                      }
                      break;
                  default:
                    this.error = 'Errore';
                    this.message.error('Errore imprevisto dal server. Contattare il supporto tecnico', this.error);
                    break;
                }
              }

              return payload;
            }
          ), catchError(error => {
              if (error instanceof Error) {
                // A client-side or network error occurred. Handle it accordingly.
                console.error('An error occurred:', error.message);
              } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                this.message.error('Il server non risponde', 'Errore');
              }

              // ...optionally return a default fallback value so app can continue (pick one)
              // which could be a default value
              // return Observable.of<any>({my: "default value..."});
              // or simply an empty observable
              // return Observable.empty<T>();
              return  of<any>({status: '-3', msg: this.noResponse, items: {}});
          }));
        }

        protected put(url: string, param, json: boolean = false) {
          // console.log('Recupero il token...');
          this.token = this.auth.getToken() || '';
          // console.log('base service... getToken', this.token);
          const contentType = 'application/x-www-form-urlencoded';
          let headers;
          if (this.token !== '' && this.token !== null) {
             headers = new HttpHeaders({ 'Content-Type': contentType, token: this.token });
          } else {
            headers = new HttpHeaders({ 'Content-Type': contentType });
          }
          // console.log(param);
          const p = { params: param };
          // console.log(p);
          // tslint:disable-next-line: one-variable-per-declaration
          const options = { headers, ...p};
          // console.log(options);
          return this.http.get(__env.apiUrl + url, options).pipe(map(
              (payload: IResponse) => {
                // console.log('status: ', payload.status);
                // console.log('errorCode: ', payload.errorCode);
                const messages = this.message.findDuplicate(payload.msg, payload.msg, true, false);

                // console.log('paylod.status', payload.status);

                // console.log(typeof(this.errorDecode.find(item => item.servicePath === url)));

                // this.redirect = this.getRedirect(payload.status, url);
                if (payload.status === 'KO') {
                  switch (Number(payload.errorCode)) {
                    case -1: // token scaduto o non valido, torna alla login
                         this.error = 'Errore';
                         this.message.error(payload.msg, this.error);
                         localStorage.clear();
                         this.route.navigate(['/']);
                         break;
                    case -2: // errore logici
                        if (!messages) {
                          this.error = 'Warning';
                          this.message.warning(payload.msg, this.error);
                          if ( this.redirect !== false ) {
                              this.route.navigate([this.redirect]);
                          }
                        }
                        break;
                    case -3: // eccezioni / errori generici backend
                        if (!messages) {
                          this.error = 'Errore';
                          this.message.error(payload.msg, this.error);
                          if ( this.redirect !== false ) {
                            this.route.navigate([this.redirect]);
                          }
                        }
                        break;
                    case -4: // errore sui privilegi/permessi
                        if (!messages) {
                          this.error = 'Warning';
                          this.message.warning(payload.msg, this.error);
                          if ( this.redirect !== false ) {
                            this.route.navigate([this.redirect]);
                          }
                        }
                        break;
                    default:
                      this.error = 'Errore';
                      this.message.error('Errore imprevisto dal server. Contattare il supporto tecnico', this.error);
                      break;
                  }
                }

                return payload;
              }
            ), catchError(error => {
                if (error instanceof Error) {
                  // A client-side or network error occurred. Handle it accordingly.
                  console.error('An error occurred:', error.message);
                } else {
                  // The backend returned an unsuccessful response code.
                  // The response body may contain clues as to what went wrong,
                  console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                  this.message.error('Il server non risponde', 'Errore');
                }

                // ...optionally return a default fallback value so app can continue (pick one)
                // which could be a default value
                // return Observable.of<any>({my: "default value..."});
                // or simply an empty observable
                // return Observable.empty<T>();
                return  of<any>({status: '-3', msg: this.noResponse, items: {}});
            }));
          }

          protected delete(url: string, param, json: boolean = false) {
            // console.log('Recupero il token...');
            this.token = this.auth.getToken() || '';
            // console.log('base service... getToken', this.token);
            const contentType = 'application/x-www-form-urlencoded';
            let headers;
            if (this.token !== '' && this.token !== null) {
               headers = new HttpHeaders({ 'Content-Type': contentType, token: this.token });
            } else {
              headers = new HttpHeaders({ 'Content-Type': contentType });
            }
            // console.log(param);
            const p = { params: param };
            // console.log(p);
            // tslint:disable-next-line: one-variable-per-declaration
            const options = { headers, ...p};
            // console.log(options);
            return this.http.get(__env.apiUrl + url, options).pipe(map(
                (payload: IResponse) => {
                  // console.log('status: ', payload.status);
                  // console.log('errorCode: ', payload.errorCode);
                  const messages = this.message.findDuplicate(payload.msg, payload.msg, true, false);

                  // console.log('paylod.status', payload.status);

                  // console.log(typeof(this.errorDecode.find(item => item.servicePath === url)));

                  // this.redirect = this.getRedirect(payload.status, url);
                  if (payload.status === 'KO') {
                    switch (Number(payload.errorCode)) {
                      case -1: // token scaduto o non valido, torna alla login
                           this.error = 'Errore';
                           this.message.error(payload.msg, this.error);
                           localStorage.clear();
                           this.route.navigate(['/']);
                           break;
                      case -2: // errore logici
                          if (!messages) {
                            this.error = 'Warning';
                            this.message.warning(payload.msg, this.error);
                            if ( this.redirect !== false ) {
                                this.route.navigate([this.redirect]);
                            }
                          }
                          break;
                      case -3: // eccezioni / errori generici backend
                          if (!messages) {
                            this.error = 'Errore';
                            this.message.error(payload.msg, this.error);
                            if ( this.redirect !== false ) {
                              this.route.navigate([this.redirect]);
                            }
                          }
                          break;
                      case -4: // errore sui privilegi/permessi
                          if (!messages) {
                            this.error = 'Warning';
                            this.message.warning(payload.msg, this.error);
                            if ( this.redirect !== false ) {
                              this.route.navigate([this.redirect]);
                            }
                          }
                          break;
                      default:
                        this.error = 'Errore';
                        this.message.error('Errore imprevisto dal server. Contattare il supporto tecnico', this.error);
                        break;
                    }
                  }

                  return payload;
                }
              ), catchError(error => {
                  if (error instanceof Error) {
                    // A client-side or network error occurred. Handle it accordingly.
                    console.error('An error occurred:', error.message);
                  } else {
                    // The backend returned an unsuccessful response code.
                    // The response body may contain clues as to what went wrong,
                    console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                    this.message.error('Il server non risponde', 'Errore');
                  }

                  // ...optionally return a default fallback value so app can continue (pick one)
                  // which could be a default value
                  // return Observable.of<any>({my: "default value..."});
                  // or simply an empty observable
                  // return Observable.empty<T>();
                  return  of<any>({status: '-3', msg: this.noResponse, items: {}});
              }));
            }

            protected getObservable(url: string, param, json: boolean = false): Observable<IResponse> {
              this.token = this.auth.getToken() || '';
              // console.log('base service... getToken', this.token);
              const contentType = 'application/x-www-form-urlencoded';
              let headers;
              if (this.token !== '' && this.token !== null) {
                 headers = new HttpHeaders({ 'Content-Type': contentType, token: this.token });
              } else {
                headers = new HttpHeaders({ 'Content-Type': contentType });
              }
              // console.log(param);
              const p = { params: param };
              // console.log(p);
              // tslint:disable-next-line: one-variable-per-declaration
              const options = { headers, ...p};
              // console.log(options);
              return this.http.get(__env.apiUrl + url, options).pipe(map(
                  (payload: IResponse) => {
                    // console.log('status: ', payload.status);
                    // console.log('errorCode: ', payload.errorCode);
                    const messages = this.message.findDuplicate(payload.msg, payload.msg, true, false);
                    // console.log('paylod.status', payload.status);
                    // console.log(typeof(this.errorDecode.find(item => item.servicePath === url)));
                    // this.redirect = this.getRedirect(payload.status, url);
                    if (payload.status === 'KO') {
                      switch (payload.errorCode) {
                        case -1: // token scaduto o non valido, torna alla login
                             this.error = 'Errore';
                             this.message.error(payload.msg, this.error);
                             window.localStorage.clear();
                             window.localStorage.removeItem('items');
                             this.route.navigate(['/']);
                             break;
                        case -2: // errore logici
                            if (!messages) {
                              this.error = 'Warning';
                              this.message.warning(payload.msg, this.error);
                              if ( this.redirect !== false ) {
                                  this.route.navigate([this.redirect]);
                              }
                            }
                            break;
                        case -3: // eccezioni / errori generici backend
                            if (!messages) {
                              this.error = 'Errore';
                              this.message.error(payload.msg, this.error);
                              if ( this.redirect !== false ) {
                                this.route.navigate([this.redirect]);
                              }
                            }
                            break;
                        case -4: // errore sui privilegi/permessi
                            if (!messages) {
                              this.error = 'Warning';
                              this.message.warning(payload.msg, this.error);
                              if ( this.redirect !== false ) {
                                this.route.navigate([this.redirect]);
                              }
                            }
                            break;
                        default:
                          this.error = 'Errore';
                          this.message.error('Errore imprevisto dal server. Contattare il supporto tecnico', this.error);
                          break;
                      }
                    }
                    return payload;
                  }
                ), catchError(error => {
                    if (error instanceof Error) {
                      // A client-side or network error occurred. Handle it accordingly.
                      console.error('An error occurred:', error.message);
                    } else {
                      // The backend returned an unsuccessful response code.
                      // The response body may contain clues as to what went wrong,
                      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
                    }

                    // ...optionally return a default fallback value so app can continue (pick one)
                    // which could be a default value
                    // return Observable.of<any>({my: "default value..."});
                    // or simply an empty observable
                    // return Observable.empty<T>();
                    return  of<any>({status: '-3', msg: this.noResponse, items: {}});
                }));
            }
}
