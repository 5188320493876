<ngx-ui-loader [loaderId]="'nShippingLoader'"></ngx-ui-loader>
<div class="card shadow card-widget-recap border rounded m-1 " id="recapShippingWrapper" >
    <div class="card-header h5"> 
        <fa-icon class="" [icon]="faFile"></fa-icon>
        Distinte
        <div class="float-end">
            <fa-icon class="cur" (click)="toggleWidget()" [icon]="(!close)?faChevronUp:faChevronDown"></fa-icon>
        </div>
    </div>
    <div *ngIf="!close" class="card-body text-secondary">
      <h5 class="card-title float-start">
        <fa-icon class="fa-2x" [icon]="faFileAlt"></fa-icon>
        <span class="badge bg-blu rounded-pill text-light">{{ nShipping }}</span>

      </h5>
      <p class="card-text p-1 text-right float-end">
        <span class="badge rounded-pill bg-warning">{{ nOpen }} aperte</span>&nbsp;
        <span class="badge rounded-pill bg-success">{{ nClosed}} chiuse</span>&nbsp;  
      </p>
      
    </div>
    <div class="card-footer bg-transparent m-0 p-0" style="max-width: 18rem;">
      <div class="row m-0 p-0">
        <div class="col text-left m-0 p-0">
        </div>
        <div class="col text-right pt-3 m-0 p-0">
          <a href="" class="text-secondary">
          </a>
        </div>
      </div>
    </div>
  </div>