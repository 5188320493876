<div class="container-fluid gradient m-0 p-5 min-vh-100">
    <div class="container text-center ">
      <div class="bg-white p-4 shadow">
        <img src="../../assets/img/logo.png" width="200"/>
        <div class="row">
          <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4 m-0">
            <h3 class="m-3">Cambia la tua password</h3>
  
            <div *ngIf="!changePasswordStatusOk" #message  [className]="!(success)? 'alert alert-danger' : 'alert alert-success'" role="alert">
              <p class="p-3"> <b>{{ changePasswordMSG }}</b>
               <br> <br>
               <a routerLink="/" routerLinkActive="active" placement="bottom" ngbTooltip="Torna alla Home" class="verde-fix"> Torna alla Home </a></p>
           </div>
           <form *ngIf="changePasswordStatusOk" [formGroup]="cambiaPasswordForm" class="justify-content-center" >
             <div class="form-group text-left">
               <label for="email">Nuova password</label>
               <input type="password" class="form-control" autocomplete="on" required formControlName="newPassword" name="newPassword" #email placeholder="la tua password">
               <small><i>La password deve essere compresa tra gli 8 e i 20 caratteri, contenere almeno una lettera minuscola, una maiuscola un numero ed un carattere speciale compreso tra !@#$%^&* </i></small>
               <div  *ngIf="submitActive && !validPassword(cambiaPasswordForm.get('newPassword').value)" class="alert alert-danger" role="alert">
                  La password inserita non è valida.
               </div>
             </div>
             <div class="form-group text-left">
               <label for="email">Conferma password</label>
               <input type="password" class="form-control" autocomplete="on" required formControlName="confirmPassword" name="confirmPassword" #email placeholder="ripeti la password">
               <div  *ngIf="submitActive && !checkPwdEquals()" class="alert alert-danger" role="alert">
                 Le password inserite non sono uguali.
              </div>
             </div>
             <button type="button" (click)="cambiaPassword()" class="btn btn-primary btn-verde btn-block btn-azzurro" [disabled]="!cambiaPasswordForm.valid" >CAMBIA PASSWORD</button>
  
             <div class="row mt-3">
               <div class="col">
                 <a routerLink="/" placement="bottom" ngbTooltip="Torna alla Login" routerLinkActive="active" class="cur"> Torna al Login</a>
               </div>
             </div>
           </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  