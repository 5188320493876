import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirstLoginService  implements CanActivate {
  firstLogin: boolean;

  constructor(private router: Router,
              private auth: AuthService) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('Login service: ' + this.auth.isUserLoggedIn());
    if (!this.auth.getFirstLogin() ) {
        return true;
    } else if (this.auth.getTypeUser() !== 'gold' && this.auth.getTypeUser() !== 'customer') {
        this.router.navigate(['/dashboard/completa-registrazione']);
    }
  }
}
