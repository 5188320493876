<div class="chart-wrapper">
  <canvas baseChart
  [datasets]="barChartData"
  [labels]="barChartLabels"
  [options]="barChartOptions"
  [plugins]="barChartPlugins"
  [legend]="barChartLegend"
  [type]="'bar'">
</canvas>
</div>
