<div class="d-flex bg-light " id="wrapper">
  <app-sidebar></app-sidebar>
  <!-- Page Content -->
  <div id="page-content-wrapper" >
    <app-top-dash></app-top-dash>
    <div class="container-fluid" >
      <app-home-dash *ngIf="(url === '/dashboard/home') || (url === '/dashboard') || (url === '/') || (url === '')"></app-home-dash>
      <app-users-dash *ngIf="(url === '/dashboard/utenti')"></app-users-dash>
      <app-uffici-dash *ngIf="(url === '/dashboard/uffici')"></app-uffici-dash>
      <app-config-dash *ngIf="(url === '/dashboard/configurazioni')"></app-config-dash>
      <app-completa-registrazione-dash *ngIf="(url === '/dashboard/completa-registrazione')"></app-completa-registrazione-dash>
      <app-postalizzazioni-dash *ngIf="(url === '/dashboard/postalizzazioni')"></app-postalizzazioni-dash>
      <app-prodotti-dash *ngIf="(url === '/dashboard/prodotti')"></app-prodotti-dash>
      <app-gruppi-dash *ngIf="(url === '/dashboard/gruppi')"></app-gruppi-dash>
      <!-- <app-server-side-angular-way-component *ngIf="(url === '/dashboard/test')"></app-server-side-angular-way-component> -->
    </div>
  </div>
</div>
<app-footer-dash ></app-footer-dash>
