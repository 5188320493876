<div class="container-fluid bg-dark  gradient m-0 p-5 min-vh-100">
    <div class="container text-center ">
      <div class="bg-white p-4 shadow">
        <img src="../../assets/img/logo.png" width="200"/>
        <div [className]="(registerStatusOk) ? 'alert alert-info' : 'alert alert-danger'" role="alert">
           <p class="p-3"> <b>{{ registerMSG }}</b>
            <br> <br>
            <a routerLink="/login" routerLinkActive="active" lacement="bottom" ngbTooltip="Torna alla Home" class="verde-fix"> Torna al login </a></p>
  
        </div>
      </div>
    </div>
  </div>
  