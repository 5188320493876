<!-- Completa registrazione -->
<ng-template #completaRegistrazioneModal let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">
          <fa-icon [icon]="faBuilding"></fa-icon> Completa la registrazione
        </h5>
   
      </div>
      <div class="modal-body">
        <form *ngIf="tipoUtente === 'admin' || tipoUtente === 'admin_cc' || tipoUtente === 'delegate'" action="" [formGroup]="officeForm" class="justify-content-center">
          <div class="row">
            <div class="col-12 col-sm-12">
                <div class="row" *ngIf="tipoUtente=='admin'">
                    <div class="col-12 col-sm-4">
                      <div class="form-row"  >
                        <div class="form-group w-100" >
                          <label for="nome">Nome *</label>
                          <input
                            type="text"
                            name="nome"
                            required
                            class="form-control "
                            formControlName="nome"
                            #nome
                            placeholder="nome..."
                            autocomplete="off"
                          />
                        </div>
                        <div *ngIf="utils.showError(officeForm.get('nome'))" class="alert alert-danger">
                          Nome utente non valido.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                      <div class="form-row "  >
                        <div class="form-group w-100" >
                            <label for="cognome">Cognome *</label>
                            <input
                              type="text"
                              name="cognome"
                              required
                              class="form-control "
                              formControlName="cognome"
                              #cognome
                              placeholder="cognome..."
                              autocomplete="off"
                            />
                          </div>
                          <div *ngIf="utils.showError(officeForm.get('cognome'))" class="alert alert-danger">
                            Cognome utente non valido.
                          </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-row "  >
                          <div class="form-group w-100" >
                              <label for="cellulare">Cellulare *</label>
                              <input
                                type="text"
                                name="cellulare"
                                required
                                class="form-control "
                                formControlName="cellulare"
                                #cognome
                                placeholder="123 567 89"
                                autocomplete="off"
                              />
                            </div>
                            <div *ngIf="utils.showError(officeForm.get('cellulare'))" class="alert alert-danger">
                              Cellulare utente non valido.
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="row" *ngIf="tipoUtente=='admin' || tipoUtente=='admin_cc'">
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group w-100" >
                            <label for="ufficio">Ufficio mittente *</label>
                            <input
                              type="text"
                              name="ufficio"
                              required
                              class="form-control "
                              formControlName="ufficio"
                              placeholder="ufficio..."
                              autocomplete="off"
                            />
                          </div>
                          <div *ngIf="utils.showError(officeForm.get('ufficio'))" class="alert alert-danger">
                            Ufficio mittente non valido.
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group w-100" >
                            <label for="telefono">Telefono *</label>
                            <input
                              type="text"
                              name="telefono"
                              required
                              class="form-control "
                              formControlName="telefono"
                              placeholder="555 55 789..."
                              autocomplete="off"
                            />
                          </div>
                          <div *ngIf="utils.showError(officeForm.get('telefono'))" class="alert alert-danger">
                            Telefono non valido.
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group w-100" >
                            <label for="indirizzo">Indirizzo *</label>
                            <input
                              type="text"
                              name="indirizzo"
                              required
                              class="form-control "
                              formControlName="indirizzo"
                              placeholder="Via Roma 1"
                              autocomplete="off"
                            />
                          </div>
                          <div *ngIf="utils.showError(officeForm.get('indirizzo'))" class="alert alert-danger">
                            Indirizzo non valido.
                          </div>
                        </div>
                    </div>
                </div>      
                <div class="row" *ngIf="tipoUtente=='admin' || tipoUtente=='admin_cc'">
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group w-100" >
                            <label for="provincia">Provincia *</label>
                            <select (change)="getComuni()" name="provincia" class="form-control "formControlName="provincia">
                                <option value="-1">Seleziona...</option>
                                <ng-container *ngFor="let provincia of province">
                                  <option value="{{ provincia }}">{{ provincia }}</option>
                                </ng-container>
                            </select>
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group w-100" >
                            <label for="citta">Comune *</label>
                            <select (change)="getCap()" name="citta" class="form-control "formControlName="citta">
                              <option value="-1">Seleziona...</option>
                              <ng-container *ngFor="let comune of comuni">
                                <option value="{{ comune.Comune }}">{{ comune.Comune }}</option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group w-100" >
                            <label for="cap">Cap *</label>
                            <select name="cap" class="form-control "formControlName="cap">
                              <option value="-1">Seleziona...</option>
                              <ng-container *ngFor="let cap of caps">
                                <option value="{{ cap.CAP }}">{{ cap.CAP }}</option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                    </div>
                </div>   
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group text-left">
                            <label for="password">Password attuale</label>
                            <div class="input-group">
                              <input  [type]="(showCurrentPassword) ? 'text' : 'password'" class="form-control" name="currentPassword" autocomplete="on" required formControlName="currentPassword" #currentPassword placeholder="password">
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <fa-icon class="cur" [ngbTooltip]="(!showCurrentPassword) ? 'Mostra la password' : 'Nascondi la password'" [icon]="(showCurrentPassword) ? faEye : faEyeSlash" (click)="toggleShowCurrentPassword()"></fa-icon>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group text-left">
                            <label for="password">Nuova password**</label>
                            <div class="input-group">
                              <input  [type]="(showNewPassword) ? 'text' : 'password'" class="form-control" name="newPassword" autocomplete="on" required formControlName="newPassword" #newPassword placeholder="password">
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <fa-icon class="cur" [ngbTooltip]="(!showNewPassword) ? 'Mostra la password' : 'Nascondi la password'" [icon]="(showNewPassword) ? faEye : faEyeSlash" (click)="toggleShowNewPassword()"></fa-icon>
                                </span>
                              </div>
                            </div>
                            <div *ngIf="utils.showError(officeForm.get('newPassword')) || ( !validPassword(officeForm.get('newPassword').value) && officeForm.get('newPassword').dirty && officeForm.get('newPassword').touched ) " class="alert alert-danger">
                              Password non valida.
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-row"  >
                          <div class="form-group text-left">
                            <label for="password">Conferma nuova password</label>
                            <div class="input-group">
                              <input  [type]="(showConfirmPassword) ? 'text' : 'password'" class="form-control" name="confirmPassword" autocomplete="on" required formControlName="confirmPassword" #confirmPassword placeholder="password">
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <fa-icon class="cur" [ngbTooltip]="(!showConfirmPassword) ? 'Mostra la password' : 'Nascondi la password'" [icon]="(showConfirmPassword) ? faEye : faEyeSlash" (click)="toggleShowConfirmPassword()"></fa-icon>
                                </span>
                              </div>
                            </div>
                            <div *ngIf="utils.showError(officeForm.get('confirmPassword')) || (officeForm.get('confirmPassword').value !== officeForm.get('newPassword').value) " class="alert alert-danger">
                              Password non valida.
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <p class="mt-2 font-weight-bold">
            * Campi obbligatori
            <br>
            ** La password deve essere compresa tra gli 8 e i 20 caratteri, contenere almeno una lettera minuscola, una maiuscola un numero ed un carattere speciale compreso tra !@#$%^&
          </p>
          <div class="modal-footer">
            <button
              type="button"
              [className]="(officeForm.valid == false) ? 'btn btn-blu isDisabled' : 'btn btn-blu'"
              [disabled]="!officeForm.valid"
              [ngbTooltip]="
                (officeForm.valid == false)
                  ? 'Inserisci i campi obbligatori'
                  : 'Salva i dati'
              "
              (click)="save()"
            >
              Salva
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  