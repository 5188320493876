import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var __env: any;

@Injectable({
  providedIn: 'root'
})
export class DistinteService extends BaseService {
  private apiUrlGetDistinte     = '/getDistinte';
  private apiUrlGetDistinteDT   = '/getDistinteDT';
  private apiUrlAddDistinta     = '/addDistinta';
  private apiUrlGetNShipping    = '/getNShipping';
  private apiUrlChiudiDistinta  = '/chiudiDistinta';
  private apiUrlRiapriDistinta  = '/riapriDistinta';
  private apiUrlUpdateStato     = '/updateStato';
  private apiUrlDeleteDistinta  = '/deleteDistinta';

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
                super(http, auth, route, message);
  }

  getDistinte(param = {}) {
    return this.get(this.apiUrlGetDistinte, param, true);
  }

  getDistinteDT(param = {}) {
    return this.post(this.apiUrlGetDistinteDT, param, true);
  }

  addDistinta(param = {}) {
    const token = this.auth.getToken();
    // tslint:disable-next-line: object-literal-shorthand
    const headers = new HttpHeaders({token: token });
    const options = { headers };
    return this.http.post<any>(__env.apiUrl + this.apiUrlAddDistinta, param, options);
  }

  getNShipping(param = {}){
    return this.get(this.apiUrlGetNShipping, param, true);
  }

  chiudiDistinta(param) {
    const token = this.auth.getToken();
    // tslint:disable-next-line: object-literal-shorthand
    const headers = new HttpHeaders({token: token });
    const options = { headers };
    return this.http.post<any>(__env.apiUrl + this.apiUrlChiudiDistinta, param, options);
  }

  riapriDistinta(param) {
    return this.post(this.apiUrlRiapriDistinta, param, true);
  }

  updateStato(param) {
    return this.post(this.apiUrlUpdateStato, param, true);
  }

  deleteDistinta(idDistinta) {
    const param = {id : idDistinta};
    return this.post(this.apiUrlDeleteDistinta, param, true);
  }
}
