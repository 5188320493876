import { Component, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  NgbConfig,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { faDolly, faTools } from "@fortawesome/free-solid-svg-icons";

import { CategorieSpedizioni } from "src/app/class/CategorieSpedizioni";
import { DataTableDirective } from "angular-datatables";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SpedizioniService } from "src/app/services/spedizioni/spedizioni.service";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "app-prodotti-dash",
  templateUrl: "./prodotti-dash.component.html",
  styleUrls: ["./prodotti-dash.component.css"],
})
export class ProdottiDashComponent {
  @ViewChild("addProductModal") public addProductModal: TemplateRef<any>;
  @ViewChild("deleteProductModal") public deleteProductModal: TemplateRef<any>;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  products: any[] = [];
  row: any;

  // icone
  faDolly = faDolly;
  faTools = faTools;

  // form
  productForm: FormGroup;

  // modal
  closeResult: string;
  modalOptions: NgbModalConfig;

  edit: boolean;
  msg: string;
  idProductToDelete: number;
  idProductToUpdate: number;
  productToDelete: string;

  categorieSpedizioni: CategorieSpedizioni[] = [];

  constructor(
    private loader: NgxUiLoaderService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private message: ToastrService,
    private shippingService: SpedizioniService,
    public utils: UtilsService
  ) {
    this.modalOptions = new NgbModalConfig(new NgbConfig());
    this.modalOptions.ariaLabelledBy = "modal-basic-title";
    this.modalOptions.size = "lg";
    this.edit = false;
    this.msg = "";

    this.shippingService.getSpedizioni().subscribe((resp) => {
      if (resp.status === "OK") {
        this.categorieSpedizioni = resp.items;
        this.categorieSpedizioni.forEach((ele: any) => {
          ele.spedizioni.forEach((sped: any) => {
            sped.nome = sped.nome.replace(/â‚¬/g, "€");
          });
        });
      }
    });
  }

  ngOnInit(): void {
    this.loader.start();
    this.edit = false;
    this.msg = "";
    // binds component methods to javascript calls
    window.functions = window.functions || {};
    window.functions.editProduct = this.editProduct.bind(this);
    window.functions.deleteProduct = this.deleteProduct.bind(this);
    this.initTable();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  initTable() {
    const that = this;
    this.dtOptions = {
      order: [[1, "desc"]],
      paging: true,
      pagingType: "full_numbers",
      pageLength: 25,
      lengthMenu: [
        [10, 25, 50, 100, 200, -1],
        [10, 25, 50, 100, 200, "Tutti"],
      ],
      autoWidth: true,
      deferRender: true,
      serverSide: true,
      processing: true,
      scrollX: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.shippingService
          .getProductDT(dataTablesParameters)
          .subscribe((resp) => {
            that.products = resp.data;
            const table = $("#dtProducts").DataTable();
            const info = table.page.info();
            table.columns.adjust();
            that.loader.stop();
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data,
            });
          });
      },
      stateSave: false,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json",
      },
      // Declare the use of the extension in the dom parameter
      dom: "lBfrtip",
      // Configure the buttons
      buttons: [
        {
          extend: "colvis",
          text: "Nascondi colonne",
          className: "ms-2 btn",
        },
        {
          extend: "copy",
          text: "Copia",
          className: "btn ",
        },
        {
          extend: "print",
          text: "Stampa",
          className: "btn ",
        },
        {
          extend: "csv",
          text: "CSV",
          className: "btn ",
        },
        {
          extend: "excel",
          text: "Excel",
          className: "btn ",
        },
        {
          text: '<i class="fas fa-sync-alt"></i> Aggiorna',
          key: "1",
          className: "btn",
          // tslint:disable-next-line: space-before-function-paren
          // tslint:disable-next-line: object-literal-shorthand
          // tslint:disable-next-line: only-arrow-functions
          action(e, dt, node, config) {
            // alert('Button activated');
            that.loader.start();
            that.utils.rerenderServerSide(that.dtElement);
            that.loader.stop();
          },
        },
        {
          text: " Aggiungi",
          key: "2",
          className: "btn",
          // tslint:disable-next-line: space-before-function-paren
          // tslint:disable-next-line: object-literal-shorthand
          // tslint:disable-next-line: only-arrow-functions
          action(e, dt, node, config) {
            // alert('Button activated');
            that.openModal();
          },
        },
      ],
      // tslint:disable-next-line: ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // tslint:disable-next-line: deprecation
        $("td", row).unbind("click");
        // tslint:disable-next-line: deprecation
        $("td", row).bind("click", () => {
          self.someClickHandler(data);
        });
        self.row = row;
        return row;
      },
      // Use this attribute to enable colreorder
      colReorder: true,
      columns: [
        {
          data: null,
          render: (data, type, full) => {
            // console.log(data);
            // console.log(type);
            // console.log(full);

            let product = JSON.stringify(data);
            data.nome = decodeURIComponent(data.nome);

            // tslint:disable-next-line: quotemark
            product = product
              .replace(/\\n/g, "\\n")
              // tslint:disable-next-line: quotemark
              .replace(/\\'/g, "\\'")
              .replace(/\\"/g, '\\"')
              // tslint:disable-next-line: quotemark
              .replace(/\\&/g, "\\&")
              // tslint:disable-next-line: quotemark
              .replace(/\\r/g, "\\r")
              // tslint:disable-next-line: quotemark
              .replace(/\\t/g, "\\t")
              // tslint:disable-next-line: quotemark
              .replace(/\\b/g, "\\b")
              // tslint:disable-next-line: quotemark
              .replace(/\\f/g, "\\f");
            return (
              ' <a class="me-2 hover-dark" title="Modifica" onclick=\'functions.editProduct(' +
              product +
              ')\' href="javascript:void(0);"><i class="fa fa-edit" aria-hidden="true"></i></a>' +
              ' <a class=" hover-dark" title="Elimina" onclick=\'functions.deleteProduct(' +
              product +
              ')\' href="javascript:void(0);"><i class="fa fa-minus" aria-hidden="true"></i></a>'
            );
          },
        },
        {
          data: "idSpedizione",
        },
        {
          data: "nome",
          name: "nome",
          title: "Nome",
          target: "nome",
          render: (data, type, full, meta) => {
            return decodeURIComponent(data);
          },
        },
        {
          data: "nomeCategoria",
        },
        {
          data: "enabled",
        },
      ],
      columnDefs: [
        {
          targets: 0,
          searchable: false,
          orderable: false,
          className: "dt-body-center dt-head-center dt-nowrap",
        },
        {
          targets: 1,
          className: "dt-body-right dt-head-center",
          searchable: true,
          orderable: true,
          visible: false,
        },
        {
          targets: 2,
          className: "dt-body-left dt-head-center",
          searchable: true,
          orderable: true,
        },
        {
          targets: 3,
          className: "dt-body-lefts dt-head-center",
          searchable: false,
          orderable: true,
        },
        {
          targets: 4,
          className: "dt-body-center dt-head-center",
          searchable: false,
          orderable: true,
        },
      ],
    };
  }

  initFormProduct() {
    this.productForm = this.fb.group({
      idSpedizione: new FormControl(null),
      nome: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      idCategoria: new FormControl(1, Validators.required),
    });
  }

  someClickHandler(info: any): void {
    // console.log(info);
  }

  deleteProduct(product: any) {
    this.productToDelete =
      "(" +
      product.idSpedizione +
      ") " +
      decodeURIComponent(product.nome?.toUpperCase());
    this.idProductToDelete = product.idSpedizione;
    this.utils.open(this.deleteProductModal, this.modalOptions);
  }

  deleteProductConfirm() {
    this.loader.start();
    const selector = "#row-" + this.idProductToDelete;
    // console.log(selector);
    this.shippingService
      .deleteProduct(this.idProductToDelete)
      // tslint:disable-next-line: deprecation
      .subscribe((resp) => {
        this.msg = resp.msg;
        if (resp.status === "OK") {
          this.message.success(resp.msg, "Fatto", {
            timeOut: 3000,
          });
          this.utils.rerenderServerSide(this.dtElement);
          this.modalService.dismissAll();
        } else {
        }
        this.loader.stop();
      });
  }

  openModal() {
    this.loader.start();
    this.edit = false;
    this.initFormProduct();
    this.utils.open(this.addProductModal, this.modalOptions);
    this.loader.stop();
  }

  add() {
    this.loader.start();
    this.shippingService.addProduct(this.productForm.value).subscribe((res) => {
      this.msg = res.msg;
      if (res.status === "OK") {
        this.message.success(res.msg, "Fatto", {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.productForm.reset();
        this.initFormProduct();
        this.modalService.dismissAll();
      }
      this.loader.stop();
    });
  }

  confirmEdit() {
    this.loader.start();
    this.shippingService
      .editProduct(this.productForm.value)
      // tslint:disable-next-line: deprecation
      .subscribe((res) => {
        this.msg = res.msg;
        if (res.status === "OK") {
          this.message.success(res.msg, "Fatto", {
            timeOut: 3000,
          });
          this.utils.rerenderServerSide(this.dtElement);
          this.productForm.reset();
          this.initFormProduct();
          this.modalService.dismissAll();
        } else {
        }
        this.modalService.dismissAll();
        this.loader.stop();
      });
  }

  editProduct(product: any) {
    this.initFormProduct();
    this.idProductToUpdate = product.idSpedizione;
    this.edit = true;
    this.productForm.controls.idSpedizione.setValue(product.idSpedizione);
    this.productForm.controls.idCategoria.setValue(product.idCategoria);
    this.productForm.controls.nome.setValue(product.nome);
    this.utils.open(this.addProductModal, this.modalOptions);
  }
}
