import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { faBuilding, faCalendarAlt, faEdit, faTools, faTrash, faUserSlash, faUsers } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'src/app/services/auth.service';
import { ComuniService } from 'src/app/services/comuni/comuni.service';
import { DataTableDirective } from 'angular-datatables';
import { Lightbox } from 'ngx-lightbox';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UfficiService } from 'src/app/services/uffici/uffici.service';
import { Ufficio } from 'src/app/class/Ufficio';
import { UtilsService } from 'src/app/services/utils.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-uffici-dash',
  templateUrl: './uffici-dash.component.html',
  styleUrls: ['./uffici-dash.component.css']
})
export class UfficiDashComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('resetModal') public resetModal: TemplateRef<any>;
  @ViewChild('editUfficioModal') public editUfficioModal: TemplateRef<any>;
  @ViewChild('deleteUfficioModal') public deleteUfficioModal: TemplateRef<any>;

  @ViewChild(DataTableDirective)

  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  uffici: Ufficio[] = [];
  row: any;

  // icone
  faUsers = faUsers;
  faTools = faTools;
  faEdit = faEdit;
  faCalendarAlt = faCalendarAlt;
  faTrash = faTrash;
  faBuilding = faBuilding;
  faUserSlash = faUserSlash;

  // form
  ufficioForm: FormGroup;
  resetForm: FormGroup;

  // modal
  modalOptions: NgbModalConfig;

  success: boolean;
  edit: boolean;

  msg: string;
  idUfficioToDelete: number;
  idUfficioToUpdate: number;
  ufficioToDelete: string;

  // dropzone
  files: File[] = [];
  formData = new FormData();

  // gestione indirizzi
  province: any;
  comuni: any;
  caps: any;

  gold: boolean;
  admin: boolean;
  adminCC: boolean;

  // gallery
  // tslint:disable-next-line: variable-name
  _albums: Array<any> = [];

  constructor(private loader: NgxUiLoaderService,
              private ufficiService: UfficiService,
              private fb: FormBuilder,
              private modalService: NgbModal,
              private message: ToastrService,
              private comuniService: ComuniService,
              public utils: UtilsService,
              private router: Router,
              // tslint:disable-next-line: variable-name
              private _lightbox: Lightbox,
              private auth: AuthService) {
    this.modalOptions = new NgbModalConfig(new NgbConfig());
    this.modalOptions.ariaLabelledBy =  'modal-basic-title';
    this.success = false;
    this.edit = false;
    this.msg = '';
    this.province = this.comuniService.getProvince();
    if (this.auth.getTypeUser() === 'gold'){
      this.gold = true;
    } else if (this.auth.getTypeUser() === 'admin'){
              this.admin = true;
           } else if (this.auth.getTypeUser() === 'admin_cc'){
                    this.adminCC = true;
                  }
    this.initFormReset();
  }

  ngOnInit(): void {
    this.loader.start();
    // binds component methods to javascript calls
    window.functions = window.functions || {};
    window.functions.editUfficio = this.editUfficio.bind(this);
    window.functions.openLightbox = this.openLightbox.bind(this);
    this.edit = false;
    this.success = false;
    this.msg = '';
    this.initTable();
  }

  initTable() {
    const that = this;
    this.dtOptions = {
      order: [[ 1, 'desc' ]],
      paging: true,
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [[10, 25, 50, 100, 200, -1], [10, 25, 50, 100, 200, 'Tutti']],
      autoWidth: true,
      deferRender: true,
      serverSide: true,
      processing: true,
      scrollX: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.ufficiService.getUfficiDT(dataTablesParameters)
        .subscribe( resp => {
          that.uffici = resp.data;
          for (const ufficio of that.uffici) {
              const src = that.utils.getBaseUrl() + ufficio.linkBanner;
              const caption = 'Image ' + ufficio.nome;
              const thumb = that.utils.getBaseUrl()  + ufficio.linkBanner;
              const album = {
                  // tslint:disable-next-line: object-literal-shorthand
                  src: src,
                  // tslint:disable-next-line: object-literal-shorthand
                  caption: caption,
                  // tslint:disable-next-line: object-literal-shorthand
                  thumb: thumb
              };
              // console.log('album', album);
              this._albums.push(album);
          }
          // console.log(that.users);
          const table = $('#dtOffice').DataTable();
          const info = table.page.info();
          // console.log(info);
          table.columns.adjust();
          // table.draw();
          that.loader.stop();
          callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.data,
           });
        });
      },
      stateSave: false,
      // search: { regex: true },
      language: {
        url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
      },
      // Declare the use of the extension in the dom parameter
      dom: 'lBfrtip',
      // Configure the buttons
      buttons: [
        {
          extend: 'colvis',
          text: 'Nascondi colonne',
          className: 'ms-2 btn'
        },
        {
          extend: 'copy',
          text: 'Copia',
          className: 'btn '
        },
        {
          extend: 'print',
          text: 'Stampa',
          className: 'btn '
        },
        {
          extend: 'csv',
          text: 'CSV',
          className: 'btn '
        },
        {
          extend: 'excel',
          text: 'Excel',
          className: 'btn '
        },
        {
          text: '<i class="fas fa-sync-alt"></i> Aggiorna',
          key: '1',
          className: 'btn',
          // tslint:disable-next-line: space-before-function-paren
          // tslint:disable-next-line: object-literal-shorthand
          // tslint:disable-next-line: only-arrow-functions
          action(e, dt, node, config) {
            // alert('Button activated');
            that.loader.start();
            that.utils.rerenderServerSide(that.dtElement);
            that.loader.stop();
          }
        }
     ],
     // tslint:disable-next-line: ban-types
     rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // tslint:disable-next-line: deprecation
        $('td', row).unbind('click');
        // tslint:disable-next-line: deprecation
        $('td', row).bind('click', () => {
          self.someClickHandler(data);
        });
        self.row = row;
        return row;
      },
      // Use this attribute to enable colreorder
     colReorder: true,
     columns: [
        {
          data: null,
          // tslint:disable-next-line: max-line-length
          // defaultContent: '<a class="edit-user"><i class="fa fa-edit" aria-hidden="true"></i></a><a></a>',
          // defaultContent: '<app-table-actions></app-table-actions>'
          render: (data, type, full) => {
            data.nome = escape(data.nome);
            data.indirizzo = escape(data.indirizzo);
            data.citta = escape(data.citta);

            // console.log(data.nome);
            // console.log(data);

            // console.log(type);
            // console.log(full);
            // console.log(data.enabled, this.idUserLoggedin, data.idUtente);
            const classType = (!this.gold) ? 'isDisabled' : '';
            let office = JSON.stringify(data);
            data.nome = unescape(data.nome);
            data.indirizzo = unescape(data.indirizzo);
            data.citta = unescape(data.citta);
            // tslint:disable-next-line: quotemark
            office = office.replace(/\\n/g, "\\n")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\'/g, "\\'")
                        .replace(/\\"/g, '\\"')
                        // tslint:disable-next-line: quotemark
                        .replace(/\\&/g, "\\&")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\r/g, "\\r")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\t/g, "\\t")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\b/g, "\\b")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\f/g, "\\f");
            return '<a class="me-2 hover-dark ' + classType + '" title="Modifica" onclick=\'functions.editUfficio(' + office + ')\' href="javascript:void(0);"><i class="fa fa-edit" aria-hidden="true"></i></a>';
          }
        },
        {
          data: 'idUfficio'
        },
        {
          data: 'nome',
          name: 'nome',
          title: 'nome',
          target: 'nome',
          render: (data, type, full, meta) => {
            return unescape(data);
          }
        },
        {
          data: 'email'
        },
        {
          data: 'telefono'
        },
        {
          data: 'indirizzo',
          render: (data, type, full, meta) => {
            return unescape(data);
          }
        },
        {
          data: 'citta',
          render: (data, type, full, meta) => {
            return unescape(data);
          }
        },
        {
          data: 'cap'
        },
        {
          data: 'linkBanner',
          render: (data, type, full, meta) => {
            // console.log('data:', data);
            // console.log('type', type);
            // console.log('full', full);
            // console.log('meta', meta);
            // tslint:disable-next-line: max-line-length
            return '<img height="80" class="cur" onclick=\'functions.openLightbox(' + meta.row + ')\' src="' + this.utils.getBaseUrl() + data + '" alt="' + full.nome + '" />';
          }
        }
     ],
     columnDefs: [
        {
          targets: 0,
          className: 'dt-body-center dt-head-center dt-nowrap'
        },
        {
          targets: 1,
          className: 'dt-body-right dt-head-center'
        },
        {
          targets: 2,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 3,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 4,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 5,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 6,
          className: 'dt-body-right dt-head-center'
        },
        {
          targets: 7,
          className: 'dt-body-center dt-head-center'
        },
        {
          targets: 8,
          className: 'dt-body-center dt-head-center'
        }
      ],
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    // this.customFilterColumns();
  }

  customFilterColumns(){
    setTimeout(() => {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function() {
          // console.log('Filtri  su colonna attivi...');
          const that = this;
          $('input', this.footer()).on('keyup change', function() {
            // console.log('on keyup change', that.search());
            // console.log('this', this['value']);

            // tslint:disable-next-line: no-string-literal
            if (that.search() !== this['value']) {
              that
                // tslint:disable-next-line: no-string-literal
                .search(this['value'])
                .draw();
            }
          });
        });
      });
    }, 1200);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.loader.start();
      dtInstance.clear();
      dtInstance.destroy();
      this.initTable();
    });
  }

  someClickHandler(info: any): void {
    // console.log(info);
  }


  editUfficio(ufficio: Ufficio) {
    if (this.gold){
      this.comuni = this.comuniService.getComuni(ufficio.provincia);
      this.caps = this.comuniService.getCap(unescape(ufficio.citta));
      this.idUfficioToUpdate = ufficio.idUfficio;
      this.edit = true;
      this.initFormUfficio();
      this.files = [];
      this.formData = new FormData();
      this.ufficioForm.controls.idUfficio.setValue(ufficio.idUfficio);
      this.ufficioForm.controls.nome.setValue(unescape(ufficio.nome));
      this.ufficioForm.controls.cap.setValue(ufficio.cap);
      this.ufficioForm.controls.citta.setValue(unescape(ufficio.citta));
      this.ufficioForm.controls.indirizzo.setValue(unescape(ufficio.indirizzo));
      this.ufficioForm.controls.provincia.setValue(ufficio.provincia);
      this.ufficioForm.controls.telefono.setValue(ufficio.telefono);
      this.ufficioForm.controls.linkBanner.setValue(ufficio.linkBanner);
      this.utils.open(this.editUfficioModal);
    }
  }

  openReset(){
    this.initFormUfficio();
    this.modalOptions.size = 'lg';
    this.utils.open(this.resetModal, this.modalOptions);
  }

  openEditUfficio(){
    this.edit = true;
    this.initFormUfficio();
    this.modalOptions.size = 'lg';
    this.utils.open(this.editUfficioModal, this.modalOptions);
  }

  resetUfficioConfirm(){
    this.loader.start();
    this.ufficiService.reset(this.resetForm.value)
      .subscribe( resp => {
        if (resp.status.toUpperCase() === 'OK'){
          this.loader.stop();
          this.modalService.dismissAll();
          this.logout();
        }
        this.loader.stop();
      });
  }

  logout() {
    // e.preventDefault();
    const that = this;
    this.auth.logout()
      .pipe(first())
      // tslint:disable-next-line: deprecation
      .subscribe(
        data => {
          this.loader.start();
          if (data.status.toLocaleUpperCase().trim() === 'KO'){
            that.message.warning('Errore nel tentativo di logout', 'Attenzione');
          }
          this.router.navigate(['/login']);
          this.loader.stop();
        });
  }


  initFormReset(){
    this.resetForm = this.fb.group({
      email:  new FormControl(null, [Validators.required, Validators.email])
    });
  }

  initFormUfficio() {
    this.ufficioForm = this.fb.group({
      idUfficio:    new FormControl(null),

      nome:         new FormControl(null, [ Validators.required,
                                            Validators.minLength(3),
                                            Validators.maxLength(50)]),
      cap:          new FormControl(-1),
      citta:        new FormControl(-1),
      telefono:     new FormControl(null, [ Validators.minLength(5),
                                            Validators.maxLength(15)]),
      indirizzo:    new FormControl(null),
      provincia:    new FormControl(-1),
      linkBanner:   new FormControl(null),
      modFoto:      new FormControl(0)
    });
  }

  add(){
    console.log(this.ufficioForm.value);
    this.ufficiService.addUfficio(this.ufficioForm.value)
    // tslint:disable-next-line: deprecation
    .subscribe(res => {
      this.msg = res.msg;
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.success = true;
        // this.rerender();
        this.utils.rerenderServerSide(this.dtElement);
        this.ufficioForm.reset();
        this.initFormUfficio();
        this.modalService.dismissAll();

      } else {
        this.success = false;
      }
    });
  }

  getComuni(){
    if ( this.ufficioForm.get('provincia').value.toString() !== '-1'){
      this.loader.start();

      this.ufficioForm.controls.citta.enable();
      this.ufficioForm.controls.citta.setValue('-1');
      this.ufficioForm.controls.cap.enable();
      this.ufficioForm.controls.cap.setValue('-1');
      this.ufficioForm.controls.cap.disable();

      this.comuni = this.comuniService.getComuni(this.ufficioForm.get('provincia').value);
      // console.log(this.comuni);
      this.loader.stop();
    }else{
      this.ufficioForm.controls.citta.enable();
      this.ufficioForm.controls.citta.setValue('-1');
      this.ufficioForm.controls.citta.disable();
      this.ufficioForm.controls.cap.enable();
      this.ufficioForm.controls.cap.setValue('-1');
      this.ufficioForm.controls.cap.disable();
    }
  }

  getCap(){
    if ( this.ufficioForm.get('citta').value.toString() !== '-1'){
      this.loader.start();
      this.ufficioForm.controls.cap.enable();
      this.ufficioForm.controls.cap.setValue('-1');

      this.caps = this.comuniService.getCap(this.ufficioForm.get('citta').value);
      // console.log(this.caps);
      this.loader.stop();
    }else{
      this.ufficioForm.controls.cap.enable();
      this.ufficioForm.controls.cap.setValue('-1');
      this.ufficioForm.controls.cap.disable();
    }
  }

  deleteUfficioConfirm() {
    const selector = '#row-' + this.idUfficioToDelete;
    console.log(selector);
    const param = {id  : this.idUfficioToDelete };
    this.ufficiService.deleteUfficio(param)
    // tslint:disable-next-line: deprecation
    .subscribe( resp => {
      this.loader.start();
      this.msg = resp.msg;
      if (resp.status === 'OK') {
        this.message.success(resp.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.success = true;
        // this.rerender();
        this.utils.rerenderServerSide(this.dtElement);
        this.modalService.dismissAll();
      } else {
        this.success = false;
      }
      this.loader.stop();
    });
  }

  confirmEdit() {
    this.loader.start();
    this.formData.delete('idUfficio');
    this.formData.delete('nome');
    this.formData.delete('cap');
    this.formData.delete('citta');
    this.formData.delete('telefono');
    this.formData.delete('indirizzo');
    this.formData.delete('provincia');
    this.formData.append('idUfficio', this.ufficioForm.get('idUfficio').value);
    this.formData.append('nome', this.ufficioForm.get('nome').value);
    this.formData.append('cap', this.ufficioForm.get('cap').value);
    this.formData.append('citta', this.ufficioForm.get('citta').value);
    this.formData.append('telefono', this.ufficioForm.get('telefono').value);
    this.formData.append('indirizzo', this.ufficioForm.get('indirizzo').value);
    this.formData.append('provincia', this.ufficioForm.get('provincia').value);

    this.ufficiService.editUfficioWithFile(this.formData)
    // tslint:disable-next-line: deprecation
    .subscribe(res => {
      this.msg = res.msg;
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.success = true;
        this.utils.rerenderServerSide(this.dtElement);
        this.ufficioForm.reset();
        this.initFormUfficio();
        this.modalService.dismissAll();
      } else {
        this.success = false;
        this.message.error(res.msg, 'Errore', {
          timeOut: 3000,
        });
      }
      this.loader.stop();
    });
  }

  // gestione upload
  onSelect(event) {
    console.log(event);
    this.ufficioForm.controls.modFoto.setValue(1);
    this.files.push(...event.addedFiles);
    if (this.files.length > 1) { // checking if files array has more than one content
      this.replaceFile(); // replace file
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.files.length; i++) {
      this.formData.append('fileBanner', this.files[i]);
    }
  }

  // method for replacing file
  replaceFile(){
    this.files.splice(0, 1); // index =0 , remove_count = 1
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  // lightbox
  openLightbox(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  closeLightbox(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
