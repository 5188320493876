import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../services/auth.service';
import { IResponse } from '../interfaces/response';

@Component({
  selector: 'app-recupera-username',
  templateUrl: './recupera-username.component.html',
  styleUrls: ['./recupera-username.component.css']
})
export class RecuperaUsernameComponent implements OnInit {
  token: string;
  success: boolean;
  msg: string;

  constructor(private loader: NgxUiLoaderService,
              private route: ActivatedRoute,
              private auth: AuthService,
              ) {
            // tslint:disable-next-line: deprecation
            this.route.queryParams.subscribe(params => {
              this.token = params.tokenReg;
              this.auth.checkTokenRecuperaUsername(this.token)
                          // tslint:disable-next-line: deprecation
                          .subscribe( res => {
                            this.loader.start();
                            // console.log(res);
                            if (res.status.toUpperCase() === 'OK') {
                              this.success = true;
                            } else {
                              this.success = false;
                            }
                            this.msg = res.msg;
                            this.loader.stop();
                          });
            });
  }

  ngOnInit(): void {
  }

}
