    <!-- Sidebar -->
      <div class="bg-dark border-right fill ps-2" id="sidebar-wrapper"  >

        <div class=" sidebar-heading ps-2 py-3 pt-3 me-3 ms-2" style="height: 90px;">
          <img *ngIf="!sideBarClose" src="../../../assets/img/logo-mono.png" alt="Logo PNL" width="140" class="mt-1" >
          <img *ngIf="sideBarClose" src="../../../assets/img/logo-mini.png"  alt="Logo PNL" width="30" class="mt-1" >
        </div>
        <div  id="navbarNav" class="navbar-nav list-group list-group-flush bg-blu">

          <a *ngIf="tipoUtente === 'gold'"  routerLink="/dashboard/home" placement="right" ngbTooltip="Vai ai report" class="btn btn-blu p-2 text-left">
            <fa-icon [icon]="faTachometerAlt " ></fa-icon>
            <span *ngIf="!sideBarClose" class="font-weight-bold ps-3"> Dashboard</span>
          </a>

          <a  routerLink="/dashboard/postalizzazioni" placement="right" ngbTooltip="Gestisci le postalizzazioni" class="btn btn-blu p-2 text-left">
            <fa-icon [icon]="faEnvelopeOpenText"></fa-icon>
            <span *ngIf="!sideBarClose" class="font-weight-bold ps-3"> Postalizzazioni</span>
          </a>

          <a  *ngIf="tipoUtente === 'gold'" routerLink="/dashboard/configurazioni" placement="right" ngbTooltip="Gestisci le configurazioni" class="btn btn-blu p-2 text-left">
              <fa-icon [icon]="faCogs"></fa-icon>
              <span *ngIf="!sideBarClose" class="font-weight-bold ps-3"> Config</span>
          </a>

          <!-- GRUPPO ANAGRAFICHE -->
          <div *ngIf="tipoUtente === 'gold' || tipoUtente === 'admin' || tipoUtente === 'admin_cc'" class="btn-group  bg-blu dropdown">
            <button type="button" class="btn btn-blu-disable p-2 text-left">
              <fa-icon [icon]="faDatabase" class=""></fa-icon>
              <span *ngIf="!sideBarClose" class="ps-3" > Anagrafiche</span>
            </button>
            <button type="button" class="text-light dropdown-blu dropdown-toggle dropdown-toggle-split " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropDownMenuAnagrafiche" data-bs-toggle="dropdown" >
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="bg-blu dropdown-menu "  aria-labelledby="dropDownMenuAnagrafiche">
              <a routerLink="/dashboard/uffici" placement="right" ngbTooltip="Gestisci gli Uffici" class="list-group-item list-group-item-action btn btn-blu text-light text-left ps-3 dropwodn-item">
                <div class="w20-fix float-start me-2">
                  <fa-icon [icon]="faBuilding"></fa-icon>
                </div>
                <span class="font-weight-bold"> Uffici</span>
              </a>
              <a routerLink="/dashboard/utenti"  placement="right" ngbTooltip="Gestisci gli utenti" class="list-group-item list-group-item-action btn btn-blu text-light text-left ps-3 dropwodn-item">
                <div class="w20-fix float-start me-2">
                  <fa-icon [icon]="faUser"></fa-icon>
                </div>
                <span class="font-weight-bold"> Utenti</span>
              </a>
              <a *ngIf="tipoUtente === 'gold'"  routerLink="/dashboard/gruppi"  placement="right" ngbTooltip="Gestisci i gruppi" class="list-group-item list-group-item-action btn btn-blu text-light text-left ps-3 dropwodn-item">
                <div class="w20-fix float-start me-2">
                  <fa-icon [icon]="faPeopleGroup"></fa-icon>
                </div>
                <span class="font-weight-bold"> Gruppi</span>
              </a>
              <a *ngIf="tipoUtente === 'gold'"  routerLink="/dashboard/prodotti"  placement="right" ngbTooltip="Gestisci i prodotti" class="list-group-item list-group-item-action btn btn-blu text-light text-left ps-3 dropwodn-item">
                <div class="w20-fix float-start me-2">
                  <fa-icon [icon]="faDolly"></fa-icon>
                </div>
                <span class="font-weight-bold"> Prodotti</span>
              </a>
            </div>
          </div>
        </div>

        <!-- RIDUCI / AUMENTA SIDEBAR -->
        <a *ngIf="!sideBarClose" (click)="toggleSideBar($event)"  ngbTooltip="Riduci" class="list-group-item list-group-item-action bg-light cur py-3 ps-2">
          <fa-icon [icon]="faChevronCircleLeft" class="fa-xl"></fa-icon>
        </a>
        <a *ngIf="sideBarClose" (click)="toggleSideBar($event)" ngbTooltip="Espandi" class="list-group-item list-group-item-action bg-light cur py-3 ps-2">
          <fa-icon [icon]="faChevronCircleRight" class="fa-xl"></fa-icon>
        </a>
      </div>
    <!-- /#sidebar-wrapper -->
