import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Component, OnInit } from '@angular/core';

// import { Label } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit {
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: String[] = ['CPS47001', 'CPS47002', 'CPS47003', 'CPS47004', 'CPS47005', 'CPS47006'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataset[] = [
    { data: [45, 37, 60, 70, 46, 33], label: 'Cps in Simulazione' }
  ];

  constructor() { }

  ngOnInit(): void {
  }


}
