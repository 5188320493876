import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from '../services/auth.service';
import { Config } from '../class/Config';
import { ConfigService } from '../services/config/config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error: string;
  firstLogin: any;
  loginForm: FormGroup;
  recuperaPasswordForm: FormGroup;
  recuperaUsernameForm: FormGroup;
  showError: boolean;
  showMaintenanceMsg: boolean;
  recuperaPassword: boolean;
  recuperaUsername: boolean;
  recuperaPasswordOk: boolean;
  recuperaUsernameOk: boolean;

  // icone
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  config: Config = new Config();

  showPassword: boolean;

  constructor(private fb: FormBuilder,
              private auth: AuthService,
              private router: Router,
              private loader: NgxUiLoaderService,
              private configService: ConfigService
              ) {
                this.loginForm = this.fb.group({
                  username:     new FormControl(null, Validators.required),
                  password:     new FormControl(null, [Validators.minLength(8), Validators.required]),
                  keepConn:     new FormControl(null)
                });
                this.recuperaPasswordForm = this.fb.group({
                  email:        new FormControl(null, Validators.email),
                  username:     new FormControl(null, Validators.required)
                });

                this.recuperaUsernameForm = this.fb.group({
                  email:        new FormControl(null, Validators.email),
                });

                this.recuperaPassword = false;
                this.recuperaUsername = false;
                this.recuperaUsernameOk = false;
                this.recuperaPasswordOk = false;
                this.showPassword = false;
  }

  ngOnInit(): void {
    this.recuperaPassword = false;
    this.recuperaPasswordOk = false;
    this.recuperaUsername = false;
    this.recuperaUsernameOk = false;
    this.configService.getConfig()
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK'){
        this.config = resp.items[0];
        // console.log(this.config);
        this.showMaintenanceMsg = this.config.modalitaManutenzione;
      }
    });
  }

  signIn() {
    if (this.loginForm.status) {
      // this.loader.start();
      this.auth.logIn(
          this.loginForm.get('username').value,
          btoa(this.loginForm.get('password').value),
          this.loginForm.get('keepConn').value || false)
          .pipe(first())
          // tslint:disable-next-line: deprecation
          .subscribe(
                data => {
                  this.loader.start();
                  // console.log(data);
                  this.firstLogin = false;
                  if (data.status === 'OK') {
                    this.router.navigate(['/dashboard/home']);
                  } else {
                    // console.log('Errore...');
                    this.error = data.msg;
                    this.showError = true;
                  }
                  this.loader.stop();
                });
    } else {
      return false;
    }
  }

  toggleRecuperaPassword(){
    this.recuperaPassword = !this.recuperaPassword;
  }

  toggleRecuperaUsername(){
    this.recuperaUsername = !this.recuperaUsername;
  }

  goToLogin(){
    this.recuperaPassword = false;
    this.recuperaPasswordOk = false;
    this.recuperaUsername = false;
    this.recuperaUsernameOk = false;
  }

  getLinkNewPassword(){
    this.loader.start();
    this.auth.getLikNewPassword(
      this.recuperaPasswordForm.get('email').value,
      this.recuperaPasswordForm.get('username').value
      )
      .pipe(first())
      // tslint:disable-next-line: deprecation
      .subscribe(
            data => {
              this.loader.start();
              // console.log(data);
              if (data.status === 'OK') {
                this.recuperaPassword = false;
                this.recuperaPasswordOk = true;
              } else {
                // console.log('Errore...');
                this.error = data.msg;
                this.showError = true;
              }
              this.loader.stop();
            });
  }

  getLinkUsername(){
    this.loader.start();
    this.auth.getLinkUsername(
      this.recuperaUsernameForm.get('email').value)
      .pipe(first())
      // tslint:disable-next-line: deprecation
      .subscribe(
            data => {
              this.loader.start();
              // console.log(data);
              if (data.status === 'OK') {
                this.recuperaUsername = false;
                this.recuperaUsernameOk = true;
              } else {
                // console.log('Errore...');
                this.error = data.msg;
                this.showError = true;
              }
              this.loader.stop();
            });
  }

  toggleShowPassword(){
    this.showPassword = !this.showPassword;
  }

}
