import * as XLSX from 'xlsx';
import * as fs from 'file-saver';

import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  faAngleLeft,
  faEdit,
  faEnvelopeOpenText,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faInfoCircle,
  faLock,
  faLockOpen,
  faMailBulk,
  faMinus,
  faPlus,
  faPrint,
  faSave,
  faTools,
  faTrash,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'src/app/services/auth.service';
import { CategorieSpedizioni } from 'src/app/class/CategorieSpedizioni';
import { ComuniService } from 'src/app/services/comuni/comuni.service';
import { DataTableDirective } from 'angular-datatables';
import { Distinta } from 'src/app/class/Distinta';
import { DistinteService } from 'src/app/services/distinte/distinte.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Postalizzazioni } from 'src/app/class/Postalizzazione';
import { PostalizzazioniService } from 'src/app/services/postalizzazioni/postalizzazioni.service';
import { SpedizioniService } from 'src/app/services/spedizioni/spedizioni.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/services/utils.service';
import { Workbook } from 'exceljs';

@Component({
  selector: 'app-postalizzazioni-dash',
  templateUrl: './postalizzazioni-dash.component.html',
  styleUrls: ['./postalizzazioni-dash.component.css']
})
export class PostalizzazioniDashComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('newShippingModal') public newShippingModal: TemplateRef<any>;
  @ViewChild('dettaglioDistintaModal') public dettaglioDistintaModal: TemplateRef<any>;
  @ViewChild('chiudiDistintaModal') public chiudiDistintaModal: TemplateRef<any>;
  @ViewChild('deleteDistintaModal') public deleteDistintaModal: TemplateRef<any>;

  @ViewChild(DataTableDirective)

  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  distinte: Distinta[] = [];
  row: any;

  // icone
  faEnvelopeOpenText  = faEnvelopeOpenText;
  faPlus              = faPlus;
  faTools             = faTools;
  faEdit              = faEdit;
  faMinus             = faMinus;
  faFileExcel         = faFileExcel;
  faFilePdf           = faFilePdf;
  faFileAlt           = faFileAlt;
  faMailBulk          = faMailBulk;
  faWindowClose       = faWindowClose;
  faTrash             = faTrash;
  faSave              = faSave;
  faLockOpen          = faLockOpen;
  faLock              = faLock;
  faAngleLeft         = faAngleLeft;
  faInfoCircle        = faInfoCircle;
  faPrint             = faPrint;

  // modal
  closeResult: string;

  edit: boolean;

  distintaToClose: number;

  // form
  shippingForm: FormGroup;
  chiudiDistintaForm: FormGroup;
  annullaSpedizioneForm: FormGroup;
  newPostalizzazioni: FormArray;

  gold: boolean;
  enabledUpload: boolean;
  nDistinta: number;
  postalizzazioni: Postalizzazioni[] = [];
  categorieSpedizioni: CategorieSpedizioni[] = [];
  province: any;
  comuni: any[] = [];
  caps: any[] = [];

  // modal
  modalOptions: NgbModalConfig;

  // dropzone
  files: File[] = [];
  formData = new FormData();
  maxFileSize: number;
  baseUrl: string;

  // excel
  fileExcelName: string;
  idMittente: number;
  idDistinta: number;
  mittente: string;
  username: string;
  dataInserimento: Date;

  idDistintaToDelete: number;
  distintaToDelete: string;

  constructor(private modalService: NgbModal,
              private loader: NgxUiLoaderService,
              private fb: FormBuilder,
              private auth: AuthService,
              private distinteService: DistinteService,
              private message: ToastrService,
              public utils: UtilsService,
              private spedizioniService: SpedizioniService,
              private postalizzazioniService: PostalizzazioniService,
              private comuniService: ComuniService,
             ) {
    this.maxFileSize = 15799999; // 15799999 Byte = 15.0681 Megabyte
    this.baseUrl = this.utils.getBaseUrl();
    this.modalOptions = new NgbModalConfig(new NgbConfig());
    this.modalOptions.ariaLabelledBy =  'modal-basic-title';
    this.edit = false;
    this.initFormShipping();
    this.initFormChiudiDistinta();

    if (this.auth.getTypeUser() === 'gold') {
      this.gold = true;
    }
    else {
        this.gold = false;
    }
    if  (this.auth.getEnabledUpload()) {
      this.enabledUpload = true;
    }
    else {
        this.enabledUpload = false;
    }

    this.spedizioniService.getSpedizioni()
    .subscribe(resp => {
      if (resp.status === 'OK') {
        this.categorieSpedizioni = resp.items;
        // console.log(this.categorieSpedizioni);
      }
    });
    this.province = this.comuniService.getProvince();
  }

  initTable() {
    const that = this;
    this.dtOptions = {
      order: [[ 1, 'desc' ]],
      paging: true,
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [[10, 25, 50, 100, 200, -1], [10, 25, 50, 100, 200, 'Tutti']],
      autoWidth: true,
      deferRender: true,
      serverSide: true,
      processing: true,
      scrollX: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.distinteService.getDistinteDT(dataTablesParameters)
        .subscribe( resp => {
          that.distinte = resp.data;
          // console.log(that.users);
          const table = $('#dtShipping').DataTable();
          const info = table.page.info();
          // console.log(info);
          table.columns.adjust();
          // table.draw();
          that.loader.stop();
          callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.data,
           });
        });
      },
      stateSave: false,
      // search: { regex: true },
      language: {
        url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
      },
      // Declare the use of the extension in the dom parameter
       dom: 'lBfrtip',

      // Configure the buttons
      buttons: [
        {
          extend: 'colvis',
          text: 'Nascondi colonne',
          className: 'ms-2 btn'
        },
        {
          extend: 'copy',
          text: 'Copia',
          className: 'btn '
        },
        {
          extend: 'print',
          text: 'Stampa',
          className: 'btn '
        },
        {
          extend: 'csv',
          text: 'CSV',
          className: 'btn '
        },
        {
          extend: 'excel',
          text: 'Excel',
          className: 'btn ',
          exportOptions : {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          // format: {
          //     body(data, row, column, node) {
          //           console.log(data, row, column, node);
          //           // tslint:disable-next-line: quotemark
          //           return column >= 1 && column <= 9 ? data.replace( /[$,.]/g, '' ) : data.replace(/(&nbsp;|<([^>]+)>)/ig, "");

          //     }
          // }
          },
        },
        {
          text: '<i class="fas fa-sync-alt"></i> Aggiorna',
          key: '1',
          className: 'btn',
          // tslint:disable-next-line: space-before-function-paren
          // tslint:disable-next-line: object-literal-shorthand
          // tslint:disable-next-line: only-arrow-functions
          action(e, dt, node, config) {
            // alert('Button activated');
            that.loader.start();
            that.utils.rerenderServerSide(that.dtElement);
            that.loader.stop();
          }
        },
        {
          text: ' Aggiungi',
          key: '2',
          className: 'btn',
          // tslint:disable-next-line: space-before-function-paren
          // tslint:disable-next-line: object-literal-shorthand
          // tslint:disable-next-line: only-arrow-functions
          action(e, dt, node, config) {
            // alert('Button activated');
            that.addShipping();
          }
        }
     ],
     // tslint:disable-next-line: ban-types
     rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // tslint:disable-next-line: deprecation
        $('td', row).unbind('click');
        // tslint:disable-next-line: deprecation
        $('td', row).bind('click', () => {
          self.someClickHandler(data);
        });
        self.row = row;
        return row;
      },
      // Use this attribute to enable colreorder
     colReorder: true,
     columns: [
        {
          data: null,
          // tslint:disable-next-line: max-line-length
          // defaultContent: '<a class="edit-user"><i class="fa fa-edit" aria-hidden="true"></i></a><a></a>',
          // defaultContent: '<app-table-actions></app-table-actions>'
          render: (data, type, full) => {
            // console.log(data);
            // console.log(type);
            // console.log(full);
            // console.log(data.enabled, this.idUserLoggedin, data.idUtente);
            const classType = ((full.stato === 'APERTA' || full.stato === 'LAVORAZIONE') && this.gold) ? '' : 'isDisabled';
            const classTypeChiudiDistinta = (this.gold) ? '' : 'isDisabled';
            // tslint:disable-next-line: quotemark
            data.mittente = escape(data.mittente);
            let distinta = JSON.stringify(data);
            data.mittente = unescape(data.mittente);
            // console.log(data.mittente);
            // tslint:disable-next-line: quotemark
            distinta = distinta.replace(/\\n/g, "\\n")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\'/g, "\\'")
                        .replace(/\\"/g, '\\"')
                        // tslint:disable-next-line: quotemark
                        .replace(/\\&/g, "\\&")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\r/g, "\\r")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\t/g, "\\t")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\b/g, "\\b")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\f/g, "\\f");
            // tslint:disable-next-line: quotemark
            const ico = (full.stato === 'APERTA' || full.stato === 'LAVORAZIONE') ? 'fa-unlock' : 'fa-lock' ;
            // tslint:disable-next-line: max-line-length
            return '<a class="me-2 hover-dark ' + classTypeChiudiDistinta + '" title="Chiudi la distinta" onclick=\'functions.chiudiDistinta(' + distinta + ')\' href="javascript:void(0);"><i class="fa ' + ico + '" aria-hidden="true"></i></a>'
                + ' <a class=" hover-dark ' + classType + '" title="Elimina" onclick=\'functions.deleteDistinta(' + data.idDistinta + ')\' href="javascript:void(0);"><i class="fa fa-minus" aria-hidden="true"></i></a>';

          }
        },
        {
          data: 'idDistinta'
        },
        {
          data: 'nDistinta'
        },
        {
          data: 'idMittente'
        },
        {
          data: 'mittente',
          render: (data, type, full, meta) => {
            data = unescape(data);
            return data;
          }
        },
        {
          data: 'gruppo'
        },
        {
          data: 'username'
        },
        {
          data: 'stato',
          render: (data, type, full, meta) => {
            // console.log(data);
            const classType = (data === 'APERTA') ? 'bg-warning' : (data === 'LAVORAZIONE') ? 'bg-info' : 'bg-success';
            // tslint:disable-next-line: max-line-length
            return '<div class=\"progress\" style=\"height: 20px;\"><div class=\"progress-bar progress-bar-striped progress-bar-animated ' + classType + '" role=\"progressbar\" aria-valuenow=\"100\" aria-valuemin=\"0\" aria-valuemax=\"100\" style=\"width: 100%; \"><b>' + data + '</b></div></div>';
          }
        },
        {
          data: 'dataOraInserimento'
        },
        {
          data: null,
          render: (data, type, full, meta) => {
            // console.log(data);
            data.mittente = escape(data.mittente);
            let distinta = JSON.stringify(data);
            data.mittente = unescape(data.mittente);
            // tslint:disable-next-line: quotemark
            distinta = distinta.replace(/\\n/g, "\\n")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\'/g, "\\'")
                        .replace(/\\"/g, '\\"')
                        // tslint:disable-next-line: quotemark
                        .replace(/\\&/g, "\\&")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\r/g, "\\r")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\t/g, "\\t")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\b/g, "\\b")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\f/g, "\\f");
            let annullate = '';
            if (data.nAnnullate > 0 ){
              // tslint:disable-next-line: max-line-length
              annullate = '<i title="Sono presenti ' + data.nAnnullate  + ' spedizioni annullate" class=\"fa fa-info ms-1 text-danger\"</i>';
            }
            // tslint:disable-next-line: max-line-length
            return '<a class="me-2 hover-dark" title="Chiudi la distinta" onclick=\'functions.mostraDettaglio(' + distinta + ')\' href="javascript:void(0);"><i class="fa fa-envelope fa-2x"></i> <span class="badge bg-info">' + data.nPostalizzazioni + '</span></a>' + annullate;
          }
        },
        {
          data: null,
          render: (data, type, full, meta) => {
          // console.log(data);
          data.mittente = escape(data.mittente);
          let distinta = JSON.stringify(data);
          data.mittente = unescape(data.mittente);

          // tslint:disable-next-line: quotemark
          distinta = distinta.replace(/\\n/g, "\\n")
                      // tslint:disable-next-line: quotemark
                      .replace(/\\'/g, "\\'")
                      .replace(/\\"/g, '\\"')
                      // tslint:disable-next-line: quotemark
                      .replace(/\\&/g, "\\&")
                      // tslint:disable-next-line: quotemark
                      .replace(/\\r/g, "\\r")
                      // tslint:disable-next-line: quotemark
                      .replace(/\\t/g, "\\t")
                      // tslint:disable-next-line: quotemark
                      .replace(/\\b/g, "\\b")
                      // tslint:disable-next-line: quotemark
                      .replace(/\\f/g, "\\f");
          const classTypeBtnDownloadFile = (data.file === null || data.file === '') ? 'isDisabled' : '';
          let btnDownloadFileStampa = '';
          let btnExcel = '';
          if (this.enabledUpload || this.gold){
            const title = (data.file !== null && data.file !== '') ? 'Scarica il file di Stampa' : 'File di stampa non disponibile';
            // tslint:disable-next-line: max-line-length
            btnDownloadFileStampa = '<button class=\"m-1 btn btn-sm btn-danger text-light ' + classTypeBtnDownloadFile + '\" title=\" ' + title + '\" onclick="functions.openFile(\'' + data.file + '\') \" > File di Stampa</button>';
          }
          if (this.gold){
            btnExcel = ' <button title=\"Scarica l\'Excel delle postalizzazioni\" class=\"btn btn-success btn-sm m-1\"  onclick=\'functions.exportExcel(' + distinta + ')\'> Excel</button>';
          }
          const classBtnDownloadType = (data.distinta === null) ? 'isDisabled' : '';
          const titleBtnDownload = (data.distinta === null) ? 'Distinta non disponibile' : 'Scarica la distinta';
          // tslint:disable-next-line: max-line-length
          const btnDownload = '<button class=\" m-1 btn btn-primary text-light btn-sm ' + classBtnDownloadType + '\" title=\" ' + titleBtnDownload + ' \" target=\"_blank\" onclick=\"functions.openFile(\'' + data.distinta + '\') \" >Distinta</button>';
          return  btnDownloadFileStampa + btnExcel + btnDownload;
          }
        },
      ],
      columnDefs: [
      {
        targets: 0,
        className: 'dt-body-center dt-head-center dt-nowrap',
        visible: this.gold,
      },
      {
        targets: 1,
        className: 'dt-body-right dt-head-center'
      },
      {
        targets: 2,
        className: 'dt-body-right dt-head-center',
        searchable: true
      },
      {
        targets: 3,
        className: 'dt-body-right dt-head-center',
        searchable: true
      },
      {
        targets: 4,
        className: 'dt-body-left dt-head-center',
        searchable: true
      },
      {
        targets: 5,
        className: 'dt-body-center dt-head-center'
      },
      {
        targets: 6,
        className: 'dt-body-center dt-head-center',
      },
      {
        targets: 7,
        className: 'dt-body-center dt-head-center',
      },
      {
        targets: 8,
        className: 'dt-body-center dt-head-center',
      }
      ,
      {
        targets: 9,
        className: 'dt-body-center dt-head-center',
      }
    ],
    };

  }

  customFilterColumns(){
    // setTimeout(() => {
        this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
          dtInstance.columns().every(function() {
            // console.log('Filtri  su colonna attivi...');
            const that = this;
            $('input', this.footer()).on('keyup change', function() {
              // console.log('on keyup change', that.search());
              // console.log('this', this['value']);
              // tslint:disable-next-line: no-string-literal
              // console.log(that.search() + '!==' +  this['value']);
              // tslint:disable-next-line: no-string-literal
              if (that.search() !== this['value']) {
                that
                  // tslint:disable-next-line: no-string-literal
                  .search(this['value'])
                  .draw();
              }
            });
          });
        });
    // }, 1200);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    // this.customFilterColumns();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.loader.start();
      dtInstance.clear();
      dtInstance.destroy();
      this.initTable();
    });
  }

  someClickHandler(info: any): void {
    // console.log(info);
  }

  ngOnInit(): void {
    this.loader.start();
    // binds component methods to javascript calls
    window.functions = window.functions || {};
    window.functions.chiudiDistinta = this.chiudiDistinta.bind(this);
    window.functions.mostraDettaglio = this.mostraDettaglio.bind(this);
    window.functions.deleteDistinta = this.deleteDistinta.bind(this);
    window.functions.openFile = this.openFile.bind(this);
    window.functions.exportExcel = this.exportExcel.bind(this);
    this.initTable();
    this.edit = false;
    this.loader.stop();
  }

  openFile(url: string){
    if (url !== null && url !== 'null'){
      window.open(this.utils.getBaseUrl() + url, '_blank').focus();
    }
  }

  initFormShipping(){
    this.shippingForm = this.fb.group({
      nDistinta:            new FormControl(null),
      newPostalizzazioni:      this.fb.array([ this.createItem() ])
    });
  }

  initFormAnnullaSpedizione(){
    this.annullaSpedizioneForm = this.fb.group({
      noteAnnullamento:       new FormControl(null, Validators.required),
      idPostalizzazione:      new FormControl(null, Validators.required)
    });
  }

  initFormChiudiDistinta(){
    this.chiudiDistintaForm = this.fb.group({
      codiceDistinta:           new FormControl(null, [Validators.required,
                                                       Validators.minLength(1)])
    });
  }

  addItem() {
    this.t.push(this.createItem());
  }

  createItem(): FormGroup {
    return this.fb.group({
      denominazione:  new FormControl(null, Validators.required),
      cap:            new FormControl({value: -1, disabled: true}, Validators.required),
      provincia:      new FormControl(-1, Validators.required),
      citta:          new FormControl({value: -1, disabled: true}, Validators.required),
      indirizzo:      new FormControl(null),
      tipoSpedizione: new FormControl(-1),
      note:           new FormControl(null, Validators.maxLength(12)),
      infoAggiuntive: new FormControl(null, Validators.maxLength(100)),
    });
  }

  removeItem(index) {
    this.t.removeAt(index);
  }

  // convenience getters for easy access to form fields
  get f() { return this.shippingForm.controls; }
  get t() { return this.f.newPostalizzazioni as FormArray; }


  add(){
    this.loader.start();
    this.formData.delete('nDistinta');
    this.formData.delete('newPostalizzazioni');
    this.formData.append('newPostalizzazioni', JSON.stringify(this.shippingForm.get('newPostalizzazioni').value));

    // tslint:disable-next-line: max-line-length
    this.distinteService.addDistinta(this.formData)
    // tslint:disable-next-line: deprecation
    .subscribe(res => {
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.shippingForm.reset();
        this.initFormShipping();
        this.modalService.dismissAll();
      } else{
        this.message.error(res.msg, 'Errore', {
          timeOut: 3000,
        });
      }
      this.loader.stop();
    });
  }

  mostraDettaglio(distinta: Distinta){
    // ***************************************
    // TODO
    // Capire perchè non funzionano i loader
    // ***************************************
    // this.loader.startLoader('loaderPostalizzazioni');
    // this.loader.stopLoader('loaderPostalizzazioni');

    // this.loader.start();
    // this.loader.stop();
    // tslint:disable-next-line: no-string-literal
    this.idMittente = distinta['idMittente'];
    this.idDistinta = distinta.idDistinta;
    this.mittente   = unescape(distinta.mittente);
    this.username   = distinta.username;
    this.dataInserimento = distinta.dataOraInserimento;
    this.postalizzazioniService.getPostalizzazioni({idDistinta: distinta.idDistinta})
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK'){
        this.postalizzazioni = resp.items;
      }
      // this.loader.stopLoader('loaderPostalizzazioni');
    });
    this.nDistinta = distinta.nDistinta;
    this.modalOptions.size = 'xl';
    this.utils.open(this.dettaglioDistintaModal, this.modalOptions);
  }

  getComuni(i: number){
    console.log('Check control ', i , this.t.controls[i]['controls']['città']);
    if ( this.t.controls[i].get('provincia').value.toString() !== '-1'){
      console.log('Abilito ',  this.t.controls[i].get('citta').value);
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls']['citta'].enable();
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls']['citta'].setValue('-1');
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.enable();
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.setValue('-1');
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.disable();

      this.comuni[i] = this.comuniService.getComuni(this.t.controls[i].get('provincia').value);
      // console.log(this.comuni);
    }else{
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].citta.enable();
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].citta.setValue('-1');
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].citta.disable();
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.enable();
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.setValue('-1');
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.disable();
    }
  }

  getCap(i: number){
    if ( this.t.controls[i].get('citta').value.toString() !== '-1'){
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.enable();
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.setValue('-1');

      this.caps[i] = this.comuniService.getCap(this.t.controls[i].get('citta').value);
      // console.log(this.caps);
    }else{
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.enable();
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.setValue('-1');
      // tslint:disable-next-line: no-string-literal
      this.t.controls[i]['controls'].cap.disable();
    }
  }

  chiudiDistinta(distinta: Distinta){
    // console.log(distinta);
    if (!this.gold) {
      return false;
    } else if (distinta.stato === 'CHIUSA'){
        if (confirm('Sei sicuro di voler riaprire la distinta? Tutte le informazioni precedentemente associate verranno perse!')){
          // richiamo il servizio per eliminare il file e il codice distinta
          this.distinteService.riapriDistinta({idDistinta: distinta.idDistinta, distinta: distinta.distinta})
          .subscribe( resp => {
            if (resp.status.toUpperCase() === 'OK'){
              this.message.success(resp.msg, 'Fatto');
              this.utils.rerenderServerSide(this.dtElement);
            }
          });
        } else {
          return false;
        }
    } else{
      this.distintaToClose = distinta.idDistinta;
      this.initFormChiudiDistinta();
      this.files = [];
      this.formData = new FormData();
      this.modalOptions.size = 'lg';
      this.utils.open(this.chiudiDistintaModal, this.modalOptions);
    }
  }

  chiudiDistintaConfirm(){
    this.formData.delete('nDistinta');
    this.formData.delete('idDistinta');
    this.formData.append('idDistinta', this.distintaToClose.toString());
    this.formData.append('nDistinta', this.chiudiDistintaForm.get('codiceDistinta').value);
    this.distinteService.chiudiDistinta(this.formData)
    // tslint:disable-next-line: deprecation
    .subscribe(res => {
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        // this.rerender();
        this.utils.rerenderServerSide(this.dtElement);
        this.chiudiDistintaForm.reset();
        this.initFormShipping();
        this.modalService.dismissAll();
      } else{
        this.message.error(res.msg, 'Errore', {
          timeOut: 3000,
        });
      }
    });
  }

  addShipping(){
    if (!this.gold){
      this.initFormShipping();
      this.modalOptions.size = 'xl';
      this.files = [];
      this.formData = new FormData();
      this.utils.open(this.newShippingModal, this.modalOptions);
    } else {
      alert('Il tuo utente non può postalizzare');
      return false;
    }
  }

  onSelect(event) {
    // console.log(event);
    // this.eventsForm.controls.modFoto.setValue(1);
    this.files.push(...event.addedFiles);
    if (this.files.length > 1) { // checking if files array has more than one content
      this.replaceFile(); // replace file
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.files.length; i++) {
      if (this.gold){
        this.formData.append('fileDistinta', this.files[i]);
      } else {
        this.formData.append('fileStampa', this.files[i]);
      }
    }
  }

  // metodo per replacing file
  replaceFile(){
    this.files.splice(0, 1); // index =0 , remove_count = 1
  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  // funzione per l'esportazione del file excel
  async exportExcel(distinta: Distinta) {
    try {
      this.loader.start();
      this.loader.stop();
      this.postalizzazioni = [];
      const resp = await this.postalizzazioniService.getPostalizzazioni({idDistinta: distinta.idDistinta})
      .toPromise();
      // console.log(resp);
      if (resp.status.toUpperCase() === 'OK') {
        this.postalizzazioni = resp.items;
        console.log(this.postalizzazioni);
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Foglio1');
        worksheet.columns = [
          { header: 'Barcode', key: 'barcoe', width: 10 },
          { header: 'Peso', key: 'peso', width: 32 },
          { header: 'Tipo spedizione', key: 'tipoSpedizione', width: 10 },
          { header: 'Denominazione', key: 'denominazione', width: 10 },
          { header: 'Indirizzo', key: 'indirizzo', width: 10 },
          { header: 'Cap', key: 'cap', width: 10 },
          { header: 'Comune', key: 'comune', width: 32 },
          { header: 'Provincia', key: 'provincia', width: 10 },
          { header: 'Info Aggiuntive', key: 'infoAggiuntive', width: 10 },
          { header: 'Note', key: 'note', width: 10 },
        ];
        this.postalizzazioni.forEach(element => {
          if (element.enabled) {
            worksheet.addRow(
              [ '',
                '',
                // tslint:disable-next-line: no-string-literal
                element['tipoSpedizione'],
                element.denominazione,
                element.indirizzo,
                element.cap,
                // tslint:disable-next-line: no-string-literal
                element['citta'],
                element.provincia,
                element.infoAggiuntive,
                element.note], 'n');
          }
        });

        // tslint:disable-next-line: no-string-literal
        this.fileExcelName = distinta.idDistinta + '_' + distinta.mittente + '_' + distinta['username'] + '.xlsx';

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, this.fileExcelName);
        });

        if (distinta.stato === 'APERTA'){
          this.distinteService.updateStato({stato: 'LAVORAZIONE', idDistinta: distinta.idDistinta})
          // tslint:disable-next-line: no-shadowed-variable
          .subscribe( resp => {
              if (resp.status.toUpperCase() === 'OK'){
                this.message.success(resp.msg, 'Fatto');
                this.utils.rerenderServerSide(this.dtElement);
              }
            });
          }
        }
    } catch (error) {
        console.error(error);
        return {status: 'KO'};
    }
  }

  annullaSpedizione(p: Postalizzazioni){
    // tslint:disable-next-line: no-string-literal
    if (p['statoDistinta'] !== 'APERTA' && p['statoDistinta'] !== 'LAVORAZIONE'){
      return false;
    } else
    if (document.getElementById('row-annulla-' + p.idPostalizzazione) === null) {
      this.initFormShipping();
      const tr = document.createElement('tr');
      tr.setAttribute('id', 'row-annulla-' + p.idPostalizzazione);
      $('#row-dettaglio-' + p.idPostalizzazione).after(tr);
      // console.log(tr);
      const td = document.createElement('td');
      $(tr).append(td);
      $(td).attr('colspan', 10);
      const divOne = document.createElement('div');
      $(td).append(divOne);
      const divTwo = document.createElement('div');
      $(divOne).append(divTwo);
      $(divTwo).attr('class', 'form-group');
      const label = document.createElement('label');
      $(divTwo).append(label);
      $(label).html('Note annullamento:');
      $(label).attr('for', 'noteAnnullamento');
      const textArea = document.createElement('textarea');
      $(textArea).attr('class', 'form-control');
      $(divTwo).append(textArea);

      const button = document.createElement('button');
      $(divOne).append(button);
      $(button).attr('class', 'btn btn-warning text-light float-end font-weight-bold');
      $(button).html('Annulla spedizione');
      const that = this;
      // tslint:disable-next-line: only-arrow-functions
      $(button).on('click', function() {
        // console.log( $( this ).text() );
        if ($(textArea).val() === ''){
          alert('Devi aggiungere una nota!');
          return false;
        }
        that.confirmAnnulla(p.idPostalizzazione, $(textArea).val());
      });
      // this.elRef.nativeElement.querySelector('button').addEventListener('click', this.onClick.bind(this));
      $('#plus-' + p.idPostalizzazione).hide();
      $('#minus-' + p.idPostalizzazione).show();
    }
    else{
      $('#row-annulla-' + p.idPostalizzazione).remove();
      $('#plus-' + p.idPostalizzazione).show();
      $('#minus-' + p.idPostalizzazione).hide();
    }
  }

  confirmAnnulla(idPostalizzazione: number, note){
    // console.log('idPostalizzazione ', idPostalizzazione);
    // console.log('Note ', note);
    this.loader.start();
    // tslint:disable-next-line: object-literal-shorthand
    this.postalizzazioniService.annullaPostalizzazione({idPostalizzazione: idPostalizzazione, note: note})
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK'){
        this.message.success(resp.msg, 'Fatto');
        $('#row-annulla-' + idPostalizzazione).remove();
        $('#minus-' + idPostalizzazione).hide();
        // this.modalService.dismissAll();
        // this.rerender();
      }
      this.loader.stop();
    });
    // return false;
  }

  isInternational(idSpedizione: number): boolean {
    idSpedizione = Number(idSpedizione);
    // console.log('idSpedizione selezionata:', idSpedizione);
    let trovato = false;
    let i = 0;
    let j;
    let internazionale: boolean;
    while (!trovato && (i < this.categorieSpedizioni.length)) {
        // console.log(thias.categorieSpedizioni[i]);
        j = 0;
        while ( (j < this.categorieSpedizioni[i].spedizioni.length ) && !trovato) {
          const idToCompare = this.categorieSpedizioni[i].spedizioni[j].idSpedizione;
          // console.log(idToCompare + '===' + idSpedizione);
          if (idToCompare === idSpedizione) {
            trovato = true;
            // console.log('trovato');
            internazionale = this.categorieSpedizioni[i].internazionale;
          }
          j++;
        }
        i++;
    }
    // console.log('INTERNAZIONALE:', internazionale);
    return internazionale;
  }

  clearInput(item: any): void{
    const idSpedizione = Number(item.get('tipoSpedizione').value);
    if (this.isInternational(idSpedizione)){
      // console.log('INTERNAZIONALE');
      item.controls.provincia.enable();
      item.controls.provincia.setValue(null);
      item.controls.citta.enable();
      item.controls.citta.setValue(null);
      item.controls.cap.enable();
      item.controls.cap.setValue(null);
    } else {
      item.controls.provincia.enable();
      item.controls.provincia.setValue(-1);
      item.controls.citta.enable();
      item.controls.citta.setValue(-1);
      item.controls.citta.disable();
      item.controls.cap.enable();
      item.controls.cap.setValue(-1);
      item.controls.cap.disable();
    }
  }

  deleteDistintaConfirm() {
    this.loader.start();
    const selector = '#row-' + this.idDistintaToDelete;
    // console.log(selector);
    this.distinteService.deleteDistinta(this.idDistintaToDelete)
    // tslint:disable-next-line: deprecation
    .subscribe( resp => {
      if (resp.status === 'OK') {
        this.message.success(resp.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.modalService.dismissAll();
      } else {
      }
      this.loader.stop();
    });
  }

  deleteDistinta(idDistinta: number) {
    // console.log(idUtente + ' ' + nome + ' ' + cognome );
    // tslint:disable-next-line: max-line-length
    this.distintaToDelete =  '(' + idDistinta + ')';
    this.idDistintaToDelete = idDistinta;
    this.utils.open(this.deleteDistintaModal, this.modalOptions);
  }

  printDivContent() {
    const printContent = document.getElementById('dettaglioDistinta');
    const popupWindow = window.open('', '_blank', 'width=600,height=600');
    popupWindow.document.open();
    popupWindow.document.write(`
      <html>
        <head>
          <title>Stampa Distinta</title>
        </head>
        <body onload="window.print(); window.onafterprint = function() { window.close(); }">
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    popupWindow.document.close();
  }
}
