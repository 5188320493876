import { Component, OnInit } from '@angular/core';
import {  faEnvelopeOpenText,
          faChevronUp,
          faChevronDown,
          faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PostalizzazioniService } from 'src/app/services/postalizzazioni/postalizzazioni.service';

@Component({
  selector: 'app-recap-mails',
  templateUrl: './recap-mails.component.html',
  styleUrls: ['./recap-mails.component.css']
})
export class RecapMailsComponent implements OnInit {
  faChevronUp         = faChevronUp;
  faChevronDown       = faChevronDown;
  faEnvelopeOpenText  = faEnvelopeOpenText;
  faTruckMoving       = faTruckMoving;

  close: boolean;
  nMails: number;

  constructor(private loader: NgxUiLoaderService,
              private postalizzazioniService: PostalizzazioniService) {
                this.close = false;
                this.nMails = 0;
                this.loader.startLoader('nMailsLoader');
                this.postalizzazioniService.getNMails()
                .subscribe(resp => {
                  if (resp.status.toUpperCase() === 'OK'){
                    // tslint:disable-next-line: no-string-literal
                    this.nMails = resp.items['nMails'];
                  }
                  this.loader.stopLoader('nMailsLoader');
                });
  }

  ngOnInit(): void {
  }

  toggleWidget(){
    this.close = !this.close;
    if (this.close){
      $('#recapMailsWrapper').css('min-height', '0px');
    } else {
      $('#recapMailsWrapper').css('min-height', '200px');
    }
  }

}
