import { Comune } from "src/app/class/Comune";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ComuniService {
  comuni: Comune[] = [];

  constructor() {
    this.comuni = [
      {
        Istat: "68001",
        Comune: "Abbateggio",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "A008",
        Abitanti: "443",
      },
      {
        Istat: "66001",
        Comune: "Acciano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67020",
        CodFisco: "A018",
        Abitanti: "368",
      },
      {
        Istat: "66002",
        Comune: "Aielli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67041",
        CodFisco: "A100",
        Abitanti: "1473",
      },
      {
        Istat: "68002",
        Comune: "Alanno",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "A120",
        Abitanti: "3679",
      },
      {
        Istat: "67001",
        Comune: "Alba Adriatica",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64011",
        CodFisco: "A125",
        Abitanti: "12522",
      },
      {
        Istat: "66003",
        Comune: "Alfedena",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "A187",
        Abitanti: "822",
      },
      {
        Istat: "69001",
        Comune: "Altino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "A235",
        Abitanti: "2879",
      },
      {
        Istat: "67002",
        Comune: "Ancarano",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "A270",
        Abitanti: "1921",
      },
      {
        Istat: "66004",
        Comune: "Anversa degli Abruzzi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "A318",
        Abitanti: "389",
      },
      {
        Istat: "69002",
        Comune: "Archi",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66044",
        CodFisco: "A367",
        Abitanti: "2294",
      },
      {
        Istat: "69003",
        Comune: "Ari",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "A398",
        Abitanti: "1208",
      },
      {
        Istat: "69004",
        Comune: "Arielli",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66030",
        CodFisco: "A402",
        Abitanti: "1143",
      },
      {
        Istat: "67003",
        Comune: "Arsita",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64031",
        CodFisco: "A445",
        Abitanti: "889",
      },
      {
        Istat: "66005",
        Comune: "Ateleta",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "A481",
        Abitanti: "1174",
      },
      {
        Istat: "69005",
        Comune: "Atessa",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66041",
        CodFisco: "A485",
        Abitanti: "10775",
      },
      {
        Istat: "67004",
        Comune: "Atri",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64032",
        CodFisco: "A488",
        Abitanti: "11239",
      },
      {
        Istat: "66006",
        Comune: "Avezzano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67051",
        CodFisco: "A515",
        Abitanti: "42029",
      },
      {
        Istat: "66007",
        Comune: "Balsorano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67052",
        CodFisco: "A603",
        Abitanti: "3722",
      },
      {
        Istat: "66008",
        Comune: "Barete",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67010",
        CodFisco: "A656",
        Abitanti: "701",
      },
      {
        Istat: "66009",
        Comune: "Barisciano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67021",
        CodFisco: "A667",
        Abitanti: "1854",
      },
      {
        Istat: "66010",
        Comune: "Barrea",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "A678",
        Abitanti: "750",
      },
      {
        Istat: "67005",
        Comune: "Basciano",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64030",
        CodFisco: "A692",
        Abitanti: "2449",
      },
      {
        Istat: "67006",
        Comune: "Bellante",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64020",
        CodFisco: "A746",
        Abitanti: "7176",
      },
      {
        Istat: "66011",
        Comune: "Bisegna",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "A884",
        Abitanti: "286",
      },
      {
        Istat: "67007",
        Comune: "Bisenti",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64033",
        CodFisco: "A885",
        Abitanti: "1957",
      },
      {
        Istat: "68003",
        Comune: "Bolognano",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "A945",
        Abitanti: "1195",
      },
      {
        Istat: "69006",
        Comune: "Bomba",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66042",
        CodFisco: "A956",
        Abitanti: "906",
      },
      {
        Istat: "69007",
        Comune: "Borrello",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "B057",
        Abitanti: "381",
      },
      {
        Istat: "68004",
        Comune: "Brittoli",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "B193",
        Abitanti: "343",
      },
      {
        Istat: "69008",
        Comune: "Bucchianico",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66011",
        CodFisco: "B238",
        Abitanti: "5223",
      },
      {
        Istat: "66012",
        Comune: "Bugnara",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "B256",
        Abitanti: "1088",
      },
      {
        Istat: "68005",
        Comune: "Bussi sul Tirino",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65022",
        CodFisco: "B294",
        Abitanti: "2718",
      },
      {
        Istat: "66013",
        Comune: "Cagnano Amiterno",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67012",
        CodFisco: "B358",
        Abitanti: "1472",
      },
      {
        Istat: "66014",
        Comune: "Calascio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "B382",
        Abitanti: "148",
      },
      {
        Istat: "67008",
        Comune: "Campli",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64012",
        CodFisco: "B515",
        Abitanti: "7522",
      },
      {
        Istat: "66015",
        Comune: "Campo di Giove",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "B526",
        Abitanti: "860",
      },
      {
        Istat: "66016",
        Comune: "Campotosto",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67013",
        CodFisco: "B569",
        Abitanti: "660",
      },
      {
        Istat: "66017",
        Comune: "Canistro",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "B606",
        Abitanti: "1060",
      },
      {
        Istat: "69010",
        Comune: "Canosa Sannita",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "B620",
        Abitanti: "1471",
      },
      {
        Istat: "66018",
        Comune: "Cansano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "B624",
        Abitanti: "280",
      },
      {
        Istat: "67009",
        Comune: "Canzano",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64020",
        CodFisco: "B640",
        Abitanti: "1973",
      },
      {
        Istat: "66019",
        Comune: "Capestrano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67022",
        CodFisco: "B651",
        Abitanti: "957",
      },
      {
        Istat: "66020",
        Comune: "Capistrello",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67053",
        CodFisco: "B656",
        Abitanti: "5419",
      },
      {
        Istat: "66021",
        Comune: "Capitignano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67014",
        CodFisco: "B658",
        Abitanti: "685",
      },
      {
        Istat: "66022",
        Comune: "Caporciano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "B672",
        Abitanti: "236",
      },
      {
        Istat: "66023",
        Comune: "Cappadocia",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67060",
        CodFisco: "B677",
        Abitanti: "535",
      },
      {
        Istat: "68006",
        Comune: "Cappelle sul Tavo",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "B681",
        Abitanti: "3974",
      },
      {
        Istat: "68007",
        Comune: "Caramanico Terme",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65023",
        CodFisco: "B722",
        Abitanti: "2032",
      },
      {
        Istat: "66024",
        Comune: "Carapelle Calvisio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "B725",
        Abitanti: "94",
      },
      {
        Istat: "68008",
        Comune: "Carpineto della Nora",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "B827",
        Abitanti: "698",
      },
      {
        Istat: "69011",
        Comune: "Carpineto Sinello",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66030",
        CodFisco: "B826",
        Abitanti: "706",
      },
      {
        Istat: "66025",
        Comune: "Carsoli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67061",
        CodFisco: "B842",
        Abitanti: "5607",
      },
      {
        Istat: "69012",
        Comune: "Carunchio",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "B853",
        Abitanti: "668",
      },
      {
        Istat: "69013",
        Comune: "Casacanditella",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "B859",
        Abitanti: "1372",
      },
      {
        Istat: "69014",
        Comune: "Casalanguida",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66031",
        CodFisco: "B861",
        Abitanti: "1026",
      },
      {
        Istat: "69015",
        Comune: "Casalbordino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66021",
        CodFisco: "B865",
        Abitanti: "6382",
      },
      {
        Istat: "69016",
        Comune: "Casalincontrada",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66012",
        CodFisco: "B896",
        Abitanti: "3143",
      },
      {
        Istat: "69017",
        Comune: "Casoli",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66043",
        CodFisco: "B985",
        Abitanti: "5922",
      },
      {
        Istat: "67010",
        Comune: "Castel Castagna",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64030",
        CodFisco: "C040",
        Abitanti: "502",
      },
      {
        Istat: "66026",
        Comune: "Castel del Monte",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67023",
        CodFisco: "C083",
        Abitanti: "508",
      },
      {
        Istat: "66027",
        Comune: "Castel di Ieri",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67020",
        CodFisco: "C090",
        Abitanti: "343",
      },
      {
        Istat: "66028",
        Comune: "Castel di Sangro",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67031",
        CodFisco: "C096",
        Abitanti: "6125",
      },
      {
        Istat: "69018",
        Comune: "Castel Frentano",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66032",
        CodFisco: "C114",
        Abitanti: "4305",
      },
      {
        Istat: "69019",
        Comune: "Castelguidone",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66040",
        CodFisco: "C123",
        Abitanti: "427",
      },
      {
        Istat: "66029",
        Comune: "Castellafiume",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "C126",
        Abitanti: "1124",
      },
      {
        Istat: "67011",
        Comune: "Castellalto",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64020",
        CodFisco: "C128",
        Abitanti: "7458",
      },
      {
        Istat: "67012",
        Comune: "Castelli",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64041",
        CodFisco: "C169",
        Abitanti: "1256",
      },
      {
        Istat: "66030",
        Comune: "Castelvecchio Calvisio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "C278",
        Abitanti: "184",
      },
      {
        Istat: "66031",
        Comune: "Castelvecchio Subequo",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67024",
        CodFisco: "C279",
        Abitanti: "1084",
      },
      {
        Istat: "68009",
        Comune: "Castiglione a Casauria",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "C308",
        Abitanti: "875",
      },
      {
        Istat: "69020",
        Comune: "Castiglione Messer Marino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66033",
        CodFisco: "C298",
        Abitanti: "1942",
      },
      {
        Istat: "67013",
        Comune: "Castiglione Messer Raimondo",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64034",
        CodFisco: "C316",
        Abitanti: "2397",
      },
      {
        Istat: "67014",
        Comune: "Castilenti",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64035",
        CodFisco: "C322",
        Abitanti: "1585",
      },
      {
        Istat: "68010",
        Comune: "Catignano",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65011",
        CodFisco: "C354",
        Abitanti: "1480",
      },
      {
        Istat: "66032",
        Comune: "Celano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67043",
        CodFisco: "C426",
        Abitanti: "11184",
      },
      {
        Istat: "69021",
        Comune: "Celenza sul Trigno",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "C428",
        Abitanti: "985",
      },
      {
        Istat: "67015",
        Comune: "Cellino Attanasio",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64036",
        CodFisco: "C449",
        Abitanti: "2659",
      },
      {
        Istat: "68011",
        Comune: "Cepagatti",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65012",
        CodFisco: "C474",
        Abitanti: "10536",
      },
      {
        Istat: "66033",
        Comune: "Cerchio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67044",
        CodFisco: "C492",
        Abitanti: "1708",
      },
      {
        Istat: "67016",
        Comune: "Cermignano",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64037",
        CodFisco: "C517",
        Abitanti: "1794",
      },
      {
        Istat: "69022",
        Comune: "Chieti",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66100",
        CodFisco: "C632",
        Abitanti: "53937",
      },
      {
        Istat: "68012",
        Comune: "Città Sant'Angelo",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65013",
        CodFisco: "C750",
        Abitanti: "14553",
      },
      {
        Istat: "66034",
        Comune: "Civita d'Antino",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "C766",
        Abitanti: "1016",
      },
      {
        Istat: "69023",
        Comune: "Civitaluparella",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "C768",
        Abitanti: "375",
      },
      {
        Istat: "68013",
        Comune: "Civitaquana",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "C771",
        Abitanti: "1376",
      },
      {
        Istat: "66035",
        Comune: "Civitella Alfedena",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "C778",
        Abitanti: "315",
      },
      {
        Istat: "68014",
        Comune: "Civitella Casanova",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "C779",
        Abitanti: "1947",
      },
      {
        Istat: "67017",
        Comune: "Civitella del Tronto",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "C781",
        Abitanti: "5442",
      },
      {
        Istat: "69024",
        Comune: "Civitella Messer Raimondo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66010",
        CodFisco: "C776",
        Abitanti: "899",
      },
      {
        Istat: "66036",
        Comune: "Civitella Roveto",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67054",
        CodFisco: "C783",
        Abitanti: "3395",
      },
      {
        Istat: "66037",
        Comune: "Cocullo",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "C811",
        Abitanti: "259",
      },
      {
        Istat: "66038",
        Comune: "Collarmele",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67040",
        CodFisco: "C844",
        Abitanti: "971",
      },
      {
        Istat: "68015",
        Comune: "Collecorvino",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "C853",
        Abitanti: "5989",
      },
      {
        Istat: "67018",
        Comune: "Colledara",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64042",
        CodFisco: "C311",
        Abitanti: "2260",
      },
      {
        Istat: "69025",
        Comune: "Colledimacine",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66010",
        CodFisco: "C855",
        Abitanti: "245",
      },
      {
        Istat: "69026",
        Comune: "Colledimezzo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "C856",
        Abitanti: "557",
      },
      {
        Istat: "66039",
        Comune: "Collelongo",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "C862",
        Abitanti: "1355",
      },
      {
        Istat: "66040",
        Comune: "Collepietro",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "C866",
        Abitanti: "249",
      },
      {
        Istat: "67019",
        Comune: "Colonnella",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "C901",
        Abitanti: "3747",
      },
      {
        Istat: "67020",
        Comune: "Controguerra",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "C972",
        Abitanti: "2491",
      },
      {
        Istat: "66041",
        Comune: "Corfinio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "C999",
        Abitanti: "1067",
      },
      {
        Istat: "67021",
        Comune: "Corropoli",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64013",
        CodFisco: "D043",
        Abitanti: "4753",
      },
      {
        Istat: "67022",
        Comune: "Cortino",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64040",
        CodFisco: "D076",
        Abitanti: "709",
      },
      {
        Istat: "68016",
        Comune: "Corvara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "D078",
        Abitanti: "288",
      },
      {
        Istat: "69027",
        Comune: "Crecchio",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66014",
        CodFisco: "D137",
        Abitanti: "3033",
      },
      {
        Istat: "67023",
        Comune: "Crognaleto",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64043",
        CodFisco: "D179",
        Abitanti: "1451",
      },
      {
        Istat: "68017",
        Comune: "Cugnoli",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "D201",
        Abitanti: "1603",
      },
      {
        Istat: "69028",
        Comune: "Cupello",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66051",
        CodFisco: "D209",
        Abitanti: "4904",
      },
      {
        Istat: "69029",
        Comune: "Dogliola",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "D315",
        Abitanti: "399",
      },
      {
        Istat: "68018",
        Comune: "Elice",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "D394",
        Abitanti: "1724",
      },
      {
        Istat: "66042",
        Comune: "Fagnano Alto",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "D465",
        Abitanti: "446",
      },
      {
        Istat: "69104",
        Comune: "Fallo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "D480",
        Abitanti: "155",
      },
      {
        Istat: "67024",
        Comune: "Fano Adriano",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64044",
        CodFisco: "D489",
        Abitanti: "382",
      },
      {
        Istat: "69030",
        Comune: "Fara Filiorum Petri",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "D494",
        Abitanti: "1940",
      },
      {
        Istat: "69031",
        Comune: "Fara San Martino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66015",
        CodFisco: "D495",
        Abitanti: "1528",
      },
      {
        Istat: "68019",
        Comune: "Farindola",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "D501",
        Abitanti: "1642",
      },
      {
        Istat: "69032",
        Comune: "Filetto",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66030",
        CodFisco: "D592",
        Abitanti: "1008",
      },
      {
        Istat: "66043",
        Comune: "Fontecchio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "D681",
        Abitanti: "412",
      },
      {
        Istat: "66044",
        Comune: "Fossa",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "D736",
        Abitanti: "704",
      },
      {
        Istat: "69033",
        Comune: "Fossacesia",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66022",
        CodFisco: "D738",
        Abitanti: "6283",
      },
      {
        Istat: "69034",
        Comune: "Fraine",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "D757",
        Abitanti: "403",
      },
      {
        Istat: "69035",
        Comune: "Francavilla al Mare",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "66023",
        CodFisco: "D763",
        Abitanti: "24649",
      },
      {
        Istat: "69036",
        Comune: "Fresagrandinaria",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "D796",
        Abitanti: "1085",
      },
      {
        Istat: "69037",
        Comune: "Frisa",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66030",
        CodFisco: "D803",
        Abitanti: "1910",
      },
      {
        Istat: "69038",
        Comune: "Furci",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "D823",
        Abitanti: "1115",
      },
      {
        Istat: "66045",
        Comune: "Gagliano Aterno",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67020",
        CodFisco: "D850",
        Abitanti: "287",
      },
      {
        Istat: "69039",
        Comune: "Gamberale",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "D898",
        Abitanti: "331",
      },
      {
        Istat: "69040",
        Comune: "Gessopalena",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66010",
        CodFisco: "D996",
        Abitanti: "1614",
      },
      {
        Istat: "66046",
        Comune: "Gioia dei Marsi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67055",
        CodFisco: "E040",
        Abitanti: "2231",
      },
      {
        Istat: "69041",
        Comune: "Gissi",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66052",
        CodFisco: "E052",
        Abitanti: "3006",
      },
      {
        Istat: "69042",
        Comune: "Giuliano Teatino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "E056",
        Abitanti: "1316",
      },
      {
        Istat: "67025",
        Comune: "Giulianova",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64021",
        CodFisco: "E058",
        Abitanti: "23606",
      },
      {
        Istat: "66047",
        Comune: "Goriano Sicoli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "E096",
        Abitanti: "617",
      },
      {
        Istat: "69043",
        Comune: "Guardiagrele",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66016",
        CodFisco: "E243",
        Abitanti: "9497",
      },
      {
        Istat: "69044",
        Comune: "Guilmi",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66050",
        CodFisco: "E266",
        Abitanti: "440",
      },
      {
        Istat: "66048",
        Comune: "Introdacqua",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "E307",
        Abitanti: "2184",
      },
      {
        Istat: "67026",
        Comune: "Isola del Gran Sasso d'Italia",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64045",
        CodFisco: "E343",
        Abitanti: "4970",
      },
      {
        Istat: "69045",
        Comune: "Lama dei Peligni",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66010",
        CodFisco: "E424",
        Abitanti: "1407",
      },
      {
        Istat: "69046",
        Comune: "Lanciano",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66034",
        CodFisco: "E435",
        Abitanti: "36304",
      },
      {
        Istat: "66049",
        Comune: "L'Aquila",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67100",
        CodFisco: "A345",
        Abitanti: "72511",
      },
      {
        Istat: "66050",
        Comune: "Lecce nei Marsi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "E505",
        Abitanti: "1762",
      },
      {
        Istat: "69047",
        Comune: "Lentella",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "E531",
        Abitanti: "729",
      },
      {
        Istat: "68020",
        Comune: "Lettomanoppello",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "E558",
        Abitanti: "3024",
      },
      {
        Istat: "69048",
        Comune: "Lettopalena",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66010",
        CodFisco: "E559",
        Abitanti: "378",
      },
      {
        Istat: "69049",
        Comune: "Liscia",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "E611",
        Abitanti: "730",
      },
      {
        Istat: "68021",
        Comune: "Loreto Aprutino",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65014",
        CodFisco: "E691",
        Abitanti: "7741",
      },
      {
        Istat: "66051",
        Comune: "Luco dei Marsi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67056",
        CodFisco: "E723",
        Abitanti: "5950",
      },
      {
        Istat: "66052",
        Comune: "Lucoli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67045",
        CodFisco: "E724",
        Abitanti: "1029",
      },
      {
        Istat: "66053",
        Comune: "Magliano de' Marsi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67062",
        CodFisco: "E811",
        Abitanti: "3884",
      },
      {
        Istat: "68022",
        Comune: "Manoppello",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65024",
        CodFisco: "E892",
        Abitanti: "6952",
      },
      {
        Istat: "67047",
        Comune: "Martinsicuro",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64014",
        CodFisco: "E989",
        Abitanti: "17078",
      },
      {
        Istat: "66054",
        Comune: "Massa d'Albe",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "F022",
        Abitanti: "1571",
      },
      {
        Istat: "69050",
        Comune: "Miglianico",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "F196",
        Abitanti: "4846",
      },
      {
        Istat: "66055",
        Comune: "Molina Aterno",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67020",
        CodFisco: "M255",
        Abitanti: "414",
      },
      {
        Istat: "69051",
        Comune: "Montazzoli",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66030",
        CodFisco: "F433",
        Abitanti: "1040",
      },
      {
        Istat: "68023",
        Comune: "Montebello di Bertona",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "F441",
        Abitanti: "1061",
      },
      {
        Istat: "69009",
        Comune: "Montebello sul Sangro",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "B268",
        Abitanti: "102",
      },
      {
        Istat: "69052",
        Comune: "Monteferrante",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "F498",
        Abitanti: "140",
      },
      {
        Istat: "67027",
        Comune: "Montefino",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64030",
        CodFisco: "F500",
        Abitanti: "1112",
      },
      {
        Istat: "69053",
        Comune: "Montelapiano",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "F535",
        Abitanti: "77",
      },
      {
        Istat: "69054",
        Comune: "Montenerodomo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66010",
        CodFisco: "F578",
        Abitanti: "775",
      },
      {
        Istat: "69055",
        Comune: "Monteodorisio",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "F582",
        Abitanti: "2598",
      },
      {
        Istat: "66056",
        Comune: "Montereale",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67015",
        CodFisco: "F595",
        Abitanti: "2867",
      },
      {
        Istat: "68024",
        Comune: "Montesilvano",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65015",
        CodFisco: "F646",
        Abitanti: "51565",
      },
      {
        Istat: "67028",
        Comune: "Montorio al Vomano",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64046",
        CodFisco: "F690",
        Abitanti: "8283",
      },
      {
        Istat: "66057",
        Comune: "Morino",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "F732",
        Abitanti: "1531",
      },
      {
        Istat: "67029",
        Comune: "Morro d'Oro",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64020",
        CodFisco: "F747",
        Abitanti: "3698",
      },
      {
        Istat: "67030",
        Comune: "Mosciano Sant'Angelo",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64023",
        CodFisco: "F764",
        Abitanti: "9230",
      },
      {
        Istat: "68025",
        Comune: "Moscufo",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "F765",
        Abitanti: "3274",
      },
      {
        Istat: "69056",
        Comune: "Mozzagrogna",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66030",
        CodFisco: "F785",
        Abitanti: "2308",
      },
      {
        Istat: "66058",
        Comune: "Navelli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "F852",
        Abitanti: "594",
      },
      {
        Istat: "67031",
        Comune: "Nereto",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64015",
        CodFisco: "F870",
        Abitanti: "5141",
      },
      {
        Istat: "68026",
        Comune: "Nocciano",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "F908",
        Abitanti: "1851",
      },
      {
        Istat: "67032",
        Comune: "Notaresco",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64024",
        CodFisco: "F942",
        Abitanti: "6979",
      },
      {
        Istat: "66059",
        Comune: "Ocre",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67040",
        CodFisco: "F996",
        Abitanti: "1116",
      },
      {
        Istat: "66060",
        Comune: "Ofena",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67025",
        CodFisco: "G002",
        Abitanti: "562",
      },
      {
        Istat: "66061",
        Comune: "Opi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67030",
        CodFisco: "G079",
        Abitanti: "441",
      },
      {
        Istat: "66062",
        Comune: "Oricola",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67063",
        CodFisco: "G102",
        Abitanti: "1162",
      },
      {
        Istat: "69057",
        Comune: "Orsogna",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66036",
        CodFisco: "G128",
        Abitanti: "4160",
      },
      {
        Istat: "69058",
        Comune: "Ortona",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "66026",
        CodFisco: "G141",
        Abitanti: "23911",
      },
      {
        Istat: "66063",
        Comune: "Ortona dei Marsi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "G142",
        Abitanti: "654",
      },
      {
        Istat: "66064",
        Comune: "Ortucchio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "G145",
        Abitanti: "1901",
      },
      {
        Istat: "66065",
        Comune: "Ovindoli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67046",
        CodFisco: "G200",
        Abitanti: "1254",
      },
      {
        Istat: "66066",
        Comune: "Pacentro",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "G210",
        Abitanti: "1250",
      },
      {
        Istat: "69059",
        Comune: "Paglieta",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66020",
        CodFisco: "G237",
        Abitanti: "4531",
      },
      {
        Istat: "69060",
        Comune: "Palena",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66017",
        CodFisco: "G271",
        Abitanti: "1424",
      },
      {
        Istat: "69061",
        Comune: "Palmoli",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "G290",
        Abitanti: "1002",
      },
      {
        Istat: "69062",
        Comune: "Palombaro",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "G294",
        Abitanti: "1131",
      },
      {
        Istat: "67033",
        Comune: "Penna Sant'Andrea",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64039",
        CodFisco: "G437",
        Abitanti: "1828",
      },
      {
        Istat: "69063",
        Comune: "Pennadomo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "G434",
        Abitanti: "326",
      },
      {
        Istat: "69064",
        Comune: "Pennapiedimonte",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "G435",
        Abitanti: "515",
      },
      {
        Istat: "68027",
        Comune: "Penne",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65017",
        CodFisco: "G438",
        Abitanti: "12873",
      },
      {
        Istat: "69065",
        Comune: "Perano",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "G441",
        Abitanti: "1717",
      },
      {
        Istat: "66067",
        Comune: "Pereto",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67064",
        CodFisco: "G449",
        Abitanti: "760",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65121",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65122",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65123",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65124",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65125",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65126",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65127",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65128",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "68028",
        Comune: "Pescara",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65129",
        CodFisco: "G482",
        Abitanti: "123077",
      },
      {
        Istat: "66068",
        Comune: "Pescasseroli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67032",
        CodFisco: "G484",
        Abitanti: "2271",
      },
      {
        Istat: "66069",
        Comune: "Pescina",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67057",
        CodFisco: "G492",
        Abitanti: "4319",
      },
      {
        Istat: "66070",
        Comune: "Pescocostanzo",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67033",
        CodFisco: "G493",
        Abitanti: "1179",
      },
      {
        Istat: "68029",
        Comune: "Pescosansonesco",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "G499",
        Abitanti: "527",
      },
      {
        Istat: "66071",
        Comune: "Pettorano sul Gizio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67034",
        CodFisco: "G524",
        Abitanti: "1373",
      },
      {
        Istat: "68030",
        Comune: "Pianella",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65019",
        CodFisco: "G555",
        Abitanti: "8467",
      },
      {
        Istat: "68031",
        Comune: "Picciano",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "G589",
        Abitanti: "1347",
      },
      {
        Istat: "67034",
        Comune: "Pietracamela",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64047",
        CodFisco: "G608",
        Abitanti: "298",
      },
      {
        Istat: "69103",
        Comune: "Pietraferrazzana",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "G613",
        Abitanti: "130",
      },
      {
        Istat: "68032",
        Comune: "Pietranico",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "G621",
        Abitanti: "523",
      },
      {
        Istat: "67035",
        Comune: "Pineto",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64025",
        CodFisco: "F831",
        Abitanti: "14707",
      },
      {
        Istat: "69066",
        Comune: "Pizzoferrato",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "G724",
        Abitanti: "1160",
      },
      {
        Istat: "66072",
        Comune: "Pizzoli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67017",
        CodFisco: "G726",
        Abitanti: "3879",
      },
      {
        Istat: "66073",
        Comune: "Poggio Picenze",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67026",
        CodFisco: "G766",
        Abitanti: "1067",
      },
      {
        Istat: "69067",
        Comune: "Poggiofiorito",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66030",
        CodFisco: "G760",
        Abitanti: "963",
      },
      {
        Istat: "69068",
        Comune: "Pollutri",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66020",
        CodFisco: "G799",
        Abitanti: "2339",
      },
      {
        Istat: "68033",
        Comune: "Popoli",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65026",
        CodFisco: "G878",
        Abitanti: "5525",
      },
      {
        Istat: "66074",
        Comune: "Prata d'Ansidonia",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "G992",
        Abitanti: "533",
      },
      {
        Istat: "66075",
        Comune: "Pratola Peligna",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67035",
        CodFisco: "H007",
        Abitanti: "7890",
      },
      {
        Istat: "69069",
        Comune: "Pretoro",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "H052",
        Abitanti: "1022",
      },
      {
        Istat: "66076",
        Comune: "Prezza",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "H056",
        Abitanti: "1053",
      },
      {
        Istat: "69070",
        Comune: "Quadri",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "H098",
        Abitanti: "881",
      },
      {
        Istat: "66077",
        Comune: "Raiano",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67027",
        CodFisco: "H166",
        Abitanti: "2908",
      },
      {
        Istat: "69071",
        Comune: "Rapino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "H184",
        Abitanti: "1403",
      },
      {
        Istat: "69072",
        Comune: "Ripa Teatina",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "H320",
        Abitanti: "4279",
      },
      {
        Istat: "66078",
        Comune: "Rivisondoli",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67036",
        CodFisco: "H353",
        Abitanti: "688",
      },
      {
        Istat: "66080",
        Comune: "Rocca di Botte",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67066",
        CodFisco: "H399",
        Abitanti: "910",
      },
      {
        Istat: "66081",
        Comune: "Rocca di Cambio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67047",
        CodFisco: "H400",
        Abitanti: "538",
      },
      {
        Istat: "66082",
        Comune: "Rocca di Mezzo",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67048",
        CodFisco: "H402",
        Abitanti: "1556",
      },
      {
        Istat: "66083",
        Comune: "Rocca Pia",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "H429",
        Abitanti: "171",
      },
      {
        Istat: "69074",
        Comune: "Rocca San Giovanni",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66020",
        CodFisco: "H439",
        Abitanti: "2379",
      },
      {
        Istat: "67036",
        Comune: "Rocca Santa Maria",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "H440",
        Abitanti: "590",
      },
      {
        Istat: "66079",
        Comune: "Roccacasale",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "H389",
        Abitanti: "726",
      },
      {
        Istat: "69073",
        Comune: "Roccamontepiano",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "H424",
        Abitanti: "1822",
      },
      {
        Istat: "68034",
        Comune: "Roccamorice",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "H425",
        Abitanti: "989",
      },
      {
        Istat: "66084",
        Comune: "Roccaraso",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67037",
        CodFisco: "H434",
        Abitanti: "1677",
      },
      {
        Istat: "69075",
        Comune: "Roccascalegna",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "H442",
        Abitanti: "1362",
      },
      {
        Istat: "69076",
        Comune: "Roccaspinalveti",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "H448",
        Abitanti: "1465",
      },
      {
        Istat: "69077",
        Comune: "Roio del Sangro",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "H495",
        Abitanti: "111",
      },
      {
        Istat: "68035",
        Comune: "Rosciano",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "H562",
        Abitanti: "3597",
      },
      {
        Istat: "69078",
        Comune: "Rosello",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66040",
        CodFisco: "H566",
        Abitanti: "269",
      },
      {
        Istat: "67037",
        Comune: "Roseto degli Abruzzi",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64026",
        CodFisco: "F585",
        Abitanti: "25072",
      },
      {
        Istat: "68036",
        Comune: "Salle",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "H715",
        Abitanti: "323",
      },
      {
        Istat: "66085",
        Comune: "San Benedetto dei Marsi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67058",
        CodFisco: "H772",
        Abitanti: "3977",
      },
      {
        Istat: "66086",
        Comune: "San Benedetto in Perillis",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "H773",
        Abitanti: "128",
      },
      {
        Istat: "69079",
        Comune: "San Buono",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "H784",
        Abitanti: "1042",
      },
      {
        Istat: "66087",
        Comune: "San Demetrio ne' Vestini",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67028",
        CodFisco: "H819",
        Abitanti: "1839",
      },
      {
        Istat: "69080",
        Comune: "San Giovanni Lipioni",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "H923",
        Abitanti: "218",
      },
      {
        Istat: "69081",
        Comune: "San Giovanni Teatino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "66020",
        CodFisco: "D690",
        Abitanti: "12489",
      },
      {
        Istat: "69082",
        Comune: "San Martino sulla Marrucina",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "H991",
        Abitanti: "1025",
      },
      {
        Istat: "66088",
        Comune: "San Pio delle Camere",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "I121",
        Abitanti: "634",
      },
      {
        Istat: "69083",
        Comune: "San Salvo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "I148",
        Abitanti: "19401",
      },
      {
        Istat: "68038",
        Comune: "San Valentino in Abruzzo Citeriore",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "I376",
        Abitanti: "1949",
      },
      {
        Istat: "66092",
        Comune: "San Vincenzo Valle Roveto",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "I389",
        Abitanti: "2460",
      },
      {
        Istat: "69086",
        Comune: "San Vito Chietino",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66038",
        CodFisco: "I394",
        Abitanti: "5321",
      },
      {
        Istat: "69084",
        Comune: "Santa Maria Imbaro",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66030",
        CodFisco: "I244",
        Abitanti: "1862",
      },
      {
        Istat: "66089",
        Comune: "Sante Marie",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67067",
        CodFisco: "I326",
        Abitanti: "1251",
      },
      {
        Istat: "67038",
        Comune: "Sant'Egidio alla Vibrata",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64016",
        CodFisco: "I318",
        Abitanti: "9844",
      },
      {
        Istat: "68037",
        Comune: "Sant'Eufemia a Maiella",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "I332",
        Abitanti: "305",
      },
      {
        Istat: "69085",
        Comune: "Sant'Eusanio del Sangro",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66037",
        CodFisco: "I335",
        Abitanti: "2522",
      },
      {
        Istat: "66090",
        Comune: "Sant'Eusanio Forconese",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "I336",
        Abitanti: "413",
      },
      {
        Istat: "66091",
        Comune: "Santo Stefano di Sessanio",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "I360",
        Abitanti: "117",
      },
      {
        Istat: "67039",
        Comune: "Sant'Omero",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64027",
        CodFisco: "I348",
        Abitanti: "5423",
      },
      {
        Istat: "68039",
        Comune: "Scafa",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65027",
        CodFisco: "I482",
        Abitanti: "3900",
      },
      {
        Istat: "66093",
        Comune: "Scanno",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67038",
        CodFisco: "I501",
        Abitanti: "1966",
      },
      {
        Istat: "69087",
        Comune: "Scerni",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66020",
        CodFisco: "I520",
        Abitanti: "3458",
      },
      {
        Istat: "69088",
        Comune: "Schiavi di Abruzzo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66045",
        CodFisco: "I526",
        Abitanti: "990",
      },
      {
        Istat: "66094",
        Comune: "Scontrone",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "I543",
        Abitanti: "596",
      },
      {
        Istat: "66095",
        Comune: "Scoppito",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67019",
        CodFisco: "I546",
        Abitanti: "3286",
      },
      {
        Istat: "66096",
        Comune: "Scurcola Marsicana",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67068",
        CodFisco: "I553",
        Abitanti: "2813",
      },
      {
        Istat: "66097",
        Comune: "Secinaro",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67029",
        CodFisco: "I558",
        Abitanti: "415",
      },
      {
        Istat: "68040",
        Comune: "Serramonacesca",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65025",
        CodFisco: "I649",
        Abitanti: "625",
      },
      {
        Istat: "67040",
        Comune: "Silvi",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "64028",
        CodFisco: "I741",
        Abitanti: "15766",
      },
      {
        Istat: "68041",
        Comune: "Spoltore",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "I922",
        Abitanti: "18533",
      },
      {
        Istat: "66098",
        Comune: "Sulmona",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67039",
        CodFisco: "I804",
        Abitanti: "25159",
      },
      {
        Istat: "66099",
        Comune: "Tagliacozzo",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67069",
        CodFisco: "L025",
        Abitanti: "7036",
      },
      {
        Istat: "69089",
        Comune: "Taranta Peligna",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66018",
        CodFisco: "L047",
        Abitanti: "425",
      },
      {
        Istat: "67041",
        Comune: "Teramo",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64100",
        CodFisco: "L103",
        Abitanti: "54957",
      },
      {
        Istat: "66100",
        Comune: "Tione degli Abruzzi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "L173",
        Abitanti: "334",
      },
      {
        Istat: "68042",
        Comune: "Tocco da Casauria",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65028",
        CodFisco: "L186",
        Abitanti: "2782",
      },
      {
        Istat: "69090",
        Comune: "Tollo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "L194",
        Abitanti: "4183",
      },
      {
        Istat: "67042",
        Comune: "Torano Nuovo",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "L207",
        Abitanti: "1692",
      },
      {
        Istat: "69091",
        Comune: "Torino di Sangro",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66020",
        CodFisco: "L218",
        Abitanti: "3084",
      },
      {
        Istat: "69092",
        Comune: "Tornareccio",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66046",
        CodFisco: "L224",
        Abitanti: "1937",
      },
      {
        Istat: "66101",
        Comune: "Tornimparte",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67049",
        CodFisco: "L227",
        Abitanti: "3042",
      },
      {
        Istat: "68043",
        Comune: "Torre De' Passeri",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65029",
        CodFisco: "L263",
        Abitanti: "3229",
      },
      {
        Istat: "69093",
        Comune: "Torrebruna",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "L253",
        Abitanti: "956",
      },
      {
        Istat: "69094",
        Comune: "Torrevecchia Teatina",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "L284",
        Abitanti: "4153",
      },
      {
        Istat: "69095",
        Comune: "Torricella Peligna",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66019",
        CodFisco: "L291",
        Abitanti: "1426",
      },
      {
        Istat: "67043",
        Comune: "Torricella Sicura",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "L295",
        Abitanti: "2727",
      },
      {
        Istat: "67044",
        Comune: "Tortoreto",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64018",
        CodFisco: "L307",
        Abitanti: "10202",
      },
      {
        Istat: "67045",
        Comune: "Tossicia",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64049",
        CodFisco: "L314",
        Abitanti: "1457",
      },
      {
        Istat: "66102",
        Comune: "Trasacco",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67059",
        CodFisco: "L334",
        Abitanti: "6252",
      },
      {
        Istat: "69096",
        Comune: "Treglio",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66030",
        CodFisco: "L363",
        Abitanti: "1610",
      },
      {
        Istat: "69097",
        Comune: "Tufillo",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66050",
        CodFisco: "L459",
        Abitanti: "482",
      },
      {
        Istat: "68044",
        Comune: "Turrivalignani",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65020",
        CodFisco: "L475",
        Abitanti: "886",
      },
      {
        Istat: "69098",
        Comune: "Vacri",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "L526",
        Abitanti: "1739",
      },
      {
        Istat: "67046",
        Comune: "Valle Castellana",
        Provincia: "TE",
        Regione: "ABR",
        Prefisso: "861",
        CAP: "64010",
        CodFisco: "L597",
        Abitanti: "1045",
      },
      {
        Istat: "69099",
        Comune: "Vasto",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66054",
        CodFisco: "E372",
        Abitanti: "40381",
      },
      {
        Istat: "68045",
        Comune: "Vicoli",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "L846",
        Abitanti: "399",
      },
      {
        Istat: "68046",
        Comune: "Villa Celiera",
        Provincia: "PE",
        Regione: "ABR",
        Prefisso: "85",
        CAP: "65010",
        CodFisco: "L922",
        Abitanti: "765",
      },
      {
        Istat: "66104",
        Comune: "Villa Santa Lucia degli Abruzzi",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "M021",
        Abitanti: "148",
      },
      {
        Istat: "69102",
        Comune: "Villa Santa Maria",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "872",
        CAP: "66047",
        CodFisco: "M022",
        Abitanti: "1439",
      },
      {
        Istat: "66105",
        Comune: "Villa Sant'Angelo",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "862",
        CAP: "67020",
        CodFisco: "M023",
        Abitanti: "429",
      },
      {
        Istat: "66103",
        Comune: "Villalago",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "L958",
        Abitanti: "613",
      },
      {
        Istat: "69100",
        Comune: "Villalfonsina",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "873",
        CAP: "66020",
        CodFisco: "L961",
        Abitanti: "1008",
      },
      {
        Istat: "69101",
        Comune: "Villamagna",
        Provincia: "CH",
        Regione: "ABR",
        Prefisso: "871",
        CAP: "66010",
        CodFisco: "L964",
        Abitanti: "2449",
      },
      {
        Istat: "66106",
        Comune: "Villavallelonga",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "863",
        CAP: "67050",
        CodFisco: "M031",
        Abitanti: "931",
      },
      {
        Istat: "66107",
        Comune: "Villetta Barrea",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "M041",
        Abitanti: "677",
      },
      {
        Istat: "66108",
        Comune: "Vittorito",
        Provincia: "AQ",
        Regione: "ABR",
        Prefisso: "864",
        CAP: "67030",
        CodFisco: "M090",
        Abitanti: "916",
      },
      {
        Istat: "76001",
        Comune: "Abriola",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "A013",
        Abitanti: "1599",
      },
      {
        Istat: "77001",
        Comune: "Accettura",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75011",
        CodFisco: "A017",
        Abitanti: "2019",
      },
      {
        Istat: "76002",
        Comune: "Acerenza",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85011",
        CodFisco: "A020",
        Abitanti: "2595",
      },
      {
        Istat: "76003",
        Comune: "Albano di Lucania",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "A131",
        Abitanti: "1485",
      },
      {
        Istat: "77002",
        Comune: "Aliano",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "A196",
        Abitanti: "1110",
      },
      {
        Istat: "76004",
        Comune: "Anzi",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "A321",
        Abitanti: "1811",
      },
      {
        Istat: "76005",
        Comune: "Armento",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "A415",
        Abitanti: "696",
      },
      {
        Istat: "76006",
        Comune: "Atella",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85020",
        CodFisco: "A482",
        Abitanti: "3883",
      },
      {
        Istat: "76007",
        Comune: "Avigliano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85021",
        CodFisco: "A519",
        Abitanti: "11948",
      },
      {
        Istat: "76008",
        Comune: "Balvano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85050",
        CodFisco: "A604",
        Abitanti: "1883",
      },
      {
        Istat: "76009",
        Comune: "Banzi",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "A612",
        Abitanti: "1437",
      },
      {
        Istat: "76010",
        Comune: "Baragiano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85050",
        CodFisco: "A615",
        Abitanti: "2693",
      },
      {
        Istat: "76011",
        Comune: "Barile",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85022",
        CodFisco: "A666",
        Abitanti: "3012",
      },
      {
        Istat: "76012",
        Comune: "Bella",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "976",
        CAP: "85051",
        CodFisco: "A743",
        Abitanti: "5324",
      },
      {
        Istat: "77003",
        Comune: "Bernalda",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75012",
        CodFisco: "A801",
        Abitanti: "12258",
      },
      {
        Istat: "76013",
        Comune: "Brienza",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "B173",
        Abitanti: "4187",
      },
      {
        Istat: "76014",
        Comune: "Brindisi Montagna",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "B181",
        Abitanti: "931",
      },
      {
        Istat: "77004",
        Comune: "Calciano",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "B391",
        Abitanti: "807",
      },
      {
        Istat: "76015",
        Comune: "Calvello",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "B440",
        Abitanti: "2000",
      },
      {
        Istat: "76016",
        Comune: "Calvera",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "B443",
        Abitanti: "442",
      },
      {
        Istat: "76017",
        Comune: "Campomaggiore",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "B549",
        Abitanti: "866",
      },
      {
        Istat: "76018",
        Comune: "Cancellara",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "B580",
        Abitanti: "1438",
      },
      {
        Istat: "76019",
        Comune: "Carbone",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "B743",
        Abitanti: "715",
      },
      {
        Istat: "76021",
        Comune: "Castelgrande",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "976",
        CAP: "85050",
        CodFisco: "C120",
        Abitanti: "1032",
      },
      {
        Istat: "76022",
        Comune: "Castelluccio Inferiore",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85040",
        CodFisco: "C199",
        Abitanti: "2175",
      },
      {
        Istat: "76023",
        Comune: "Castelluccio Superiore",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85040",
        CodFisco: "C201",
        Abitanti: "874",
      },
      {
        Istat: "76024",
        Comune: "Castelmezzano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "C209",
        Abitanti: "861",
      },
      {
        Istat: "76025",
        Comune: "Castelsaraceno",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85031",
        CodFisco: "C271",
        Abitanti: "1497",
      },
      {
        Istat: "76026",
        Comune: "Castronuovo di Sant'Andrea",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "C345",
        Abitanti: "1189",
      },
      {
        Istat: "76027",
        Comune: "Cersosimo",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "C539",
        Abitanti: "734",
      },
      {
        Istat: "76028",
        Comune: "Chiaromonte",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85032",
        CodFisco: "C619",
        Abitanti: "2015",
      },
      {
        Istat: "77005",
        Comune: "Cirigliano",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "C723",
        Abitanti: "397",
      },
      {
        Istat: "77006",
        Comune: "Colobraro",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75021",
        CodFisco: "C888",
        Abitanti: "1378",
      },
      {
        Istat: "76029",
        Comune: "Corleto Perticara",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85012",
        CodFisco: "D010",
        Abitanti: "2628",
      },
      {
        Istat: "77007",
        Comune: "Craco",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "D128",
        Abitanti: "775",
      },
      {
        Istat: "76030",
        Comune: "Episcopia",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85033",
        CodFisco: "D414",
        Abitanti: "1488",
      },
      {
        Istat: "76031",
        Comune: "Fardella",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85034",
        CodFisco: "D497",
        Abitanti: "653",
      },
      {
        Istat: "77008",
        Comune: "Ferrandina",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75013",
        CodFisco: "D547",
        Abitanti: "9072",
      },
      {
        Istat: "76032",
        Comune: "Filiano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85020",
        CodFisco: "D593",
        Abitanti: "3116",
      },
      {
        Istat: "76033",
        Comune: "Forenza",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85023",
        CodFisco: "D696",
        Abitanti: "2254",
      },
      {
        Istat: "76034",
        Comune: "Francavilla in Sinni",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85034",
        CodFisco: "D766",
        Abitanti: "4303",
      },
      {
        Istat: "76035",
        Comune: "Gallicchio",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "D876",
        Abitanti: "907",
      },
      {
        Istat: "77009",
        Comune: "Garaguso",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "D909",
        Abitanti: "1155",
      },
      {
        Istat: "76036",
        Comune: "Genzano di Lucania",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85013",
        CodFisco: "D971",
        Abitanti: "6072",
      },
      {
        Istat: "76099",
        Comune: "Ginestra",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85020",
        CodFisco: "E033",
        Abitanti: "748",
      },
      {
        Istat: "77010",
        Comune: "Gorgoglione",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "E093",
        Abitanti: "1064",
      },
      {
        Istat: "77011",
        Comune: "Grassano",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75014",
        CodFisco: "E147",
        Abitanti: "5494",
      },
      {
        Istat: "77012",
        Comune: "Grottole",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "E213",
        Abitanti: "2420",
      },
      {
        Istat: "76037",
        Comune: "Grumento Nova",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "E221",
        Abitanti: "1727",
      },
      {
        Istat: "76038",
        Comune: "Guardia Perticara",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "E246",
        Abitanti: "629",
      },
      {
        Istat: "77013",
        Comune: "Irsina",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75022",
        CodFisco: "E326",
        Abitanti: "5189",
      },
      {
        Istat: "76039",
        Comune: "Lagonegro",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85042",
        CodFisco: "E409",
        Abitanti: "5802",
      },
      {
        Istat: "76040",
        Comune: "Latronico",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85043",
        CodFisco: "E474",
        Abitanti: "4811",
      },
      {
        Istat: "76041",
        Comune: "Laurenzana",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85014",
        CodFisco: "E482",
        Abitanti: "1975",
      },
      {
        Istat: "76042",
        Comune: "Lauria",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85044",
        CodFisco: "E483",
        Abitanti: "13392",
      },
      {
        Istat: "76043",
        Comune: "Lavello",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85024",
        CodFisco: "E493",
        Abitanti: "13945",
      },
      {
        Istat: "76044",
        Comune: "Maratea",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85046",
        CodFisco: "E919",
        Abitanti: "5210",
      },
      {
        Istat: "76045",
        Comune: "Marsico Nuovo",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85052",
        CodFisco: "E976",
        Abitanti: "4585",
      },
      {
        Istat: "76046",
        Comune: "Marsicovetere",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "E977",
        Abitanti: "5411",
      },
      {
        Istat: "76047",
        Comune: "Maschito",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85020",
        CodFisco: "F006",
        Abitanti: "1735",
      },
      {
        Istat: "77014",
        Comune: "Matera",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75100",
        CodFisco: "F052",
        Abitanti: "60818",
      },
      {
        Istat: "76048",
        Comune: "Melfi",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85025",
        CodFisco: "F104",
        Abitanti: "17554",
      },
      {
        Istat: "77015",
        Comune: "Miglionico",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "F201",
        Abitanti: "2573",
      },
      {
        Istat: "76049",
        Comune: "Missanello",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "F249",
        Abitanti: "555",
      },
      {
        Istat: "76050",
        Comune: "Moliterno",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85047",
        CodFisco: "F295",
        Abitanti: "4289",
      },
      {
        Istat: "77016",
        Comune: "Montalbano Jonico",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75023",
        CodFisco: "F399",
        Abitanti: "7552",
      },
      {
        Istat: "76051",
        Comune: "Montemilone",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85020",
        CodFisco: "F568",
        Abitanti: "1763",
      },
      {
        Istat: "76052",
        Comune: "Montemurro",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85053",
        CodFisco: "F573",
        Abitanti: "1347",
      },
      {
        Istat: "77017",
        Comune: "Montescaglioso",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75024",
        CodFisco: "F637",
        Abitanti: "10107",
      },
      {
        Istat: "76053",
        Comune: "Muro Lucano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "976",
        CAP: "85054",
        CodFisco: "F817",
        Abitanti: "5711",
      },
      {
        Istat: "76054",
        Comune: "Nemoli",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85040",
        CodFisco: "F866",
        Abitanti: "1519",
      },
      {
        Istat: "76055",
        Comune: "Noepoli",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85035",
        CodFisco: "F917",
        Abitanti: "988",
      },
      {
        Istat: "77018",
        Comune: "Nova Siri",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75020",
        CodFisco: "A942",
        Abitanti: "6807",
      },
      {
        Istat: "77019",
        Comune: "Oliveto Lucano",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "G037",
        Abitanti: "506",
      },
      {
        Istat: "76056",
        Comune: "Oppido Lucano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85015",
        CodFisco: "G081",
        Abitanti: "3891",
      },
      {
        Istat: "76057",
        Comune: "Palazzo San Gervasio",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85026",
        CodFisco: "G261",
        Abitanti: "5048",
      },
      {
        Istat: "76100",
        Comune: "Paterno",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "M269",
        Abitanti: "3452",
      },
      {
        Istat: "76058",
        Comune: "Pescopagano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "976",
        CAP: "85020",
        CodFisco: "G496",
        Abitanti: "2029",
      },
      {
        Istat: "76059",
        Comune: "Picerno",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85055",
        CodFisco: "G590",
        Abitanti: "6090",
      },
      {
        Istat: "76060",
        Comune: "Pietragalla",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85016",
        CodFisco: "G616",
        Abitanti: "4332",
      },
      {
        Istat: "76061",
        Comune: "Pietrapertosa",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "G623",
        Abitanti: "1129",
      },
      {
        Istat: "76062",
        Comune: "Pignola",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "G663",
        Abitanti: "6671",
      },
      {
        Istat: "77020",
        Comune: "Pisticci",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75015",
        CodFisco: "G712",
        Abitanti: "17927",
      },
      {
        Istat: "77021",
        Comune: "Policoro",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75025",
        CodFisco: "G786",
        Abitanti: "16407",
      },
      {
        Istat: "77022",
        Comune: "Pomarico",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75016",
        CodFisco: "G806",
        Abitanti: "4268",
      },
      {
        Istat: "76063",
        Comune: "Potenza",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85100",
        CodFisco: "G942",
        Abitanti: "68297",
      },
      {
        Istat: "76064",
        Comune: "Rapolla",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85027",
        CodFisco: "H186",
        Abitanti: "4506",
      },
      {
        Istat: "76065",
        Comune: "Rapone",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "976",
        CAP: "85020",
        CodFisco: "H187",
        Abitanti: "1029",
      },
      {
        Istat: "76066",
        Comune: "Rionero in Vulture",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85028",
        CodFisco: "H307",
        Abitanti: "13533",
      },
      {
        Istat: "76067",
        Comune: "Ripacandida",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85020",
        CodFisco: "H312",
        Abitanti: "1639",
      },
      {
        Istat: "76068",
        Comune: "Rivello",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85040",
        CodFisco: "H348",
        Abitanti: "2865",
      },
      {
        Istat: "76069",
        Comune: "Roccanova",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85036",
        CodFisco: "H426",
        Abitanti: "1640",
      },
      {
        Istat: "76070",
        Comune: "Rotonda",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85048",
        CodFisco: "H590",
        Abitanti: "3584",
      },
      {
        Istat: "77023",
        Comune: "Rotondella",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75026",
        CodFisco: "H591",
        Abitanti: "2846",
      },
      {
        Istat: "76071",
        Comune: "Ruoti",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85056",
        CodFisco: "H641",
        Abitanti: "3551",
      },
      {
        Istat: "76072",
        Comune: "Ruvo del Monte",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "976",
        CAP: "85020",
        CodFisco: "H646",
        Abitanti: "1113",
      },
      {
        Istat: "77024",
        Comune: "Salandra",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75017",
        CodFisco: "H687",
        Abitanti: "2974",
      },
      {
        Istat: "76073",
        Comune: "San Chirico Nuovo",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "H795",
        Abitanti: "1495",
      },
      {
        Istat: "76074",
        Comune: "San Chirico Raparo",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "H796",
        Abitanti: "1177",
      },
      {
        Istat: "76075",
        Comune: "San Costantino Albanese",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "H808",
        Abitanti: "805",
      },
      {
        Istat: "76076",
        Comune: "San Fele",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "976",
        CAP: "85020",
        CodFisco: "H831",
        Abitanti: "3273",
      },
      {
        Istat: "77025",
        Comune: "San Giorgio Lucano",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75027",
        CodFisco: "H888",
        Abitanti: "1334",
      },
      {
        Istat: "76077",
        Comune: "San Martino d'Agri",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "H994",
        Abitanti: "850",
      },
      {
        Istat: "77026",
        Comune: "San Mauro Forte",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75010",
        CodFisco: "I029",
        Abitanti: "1718",
      },
      {
        Istat: "76020",
        Comune: "San Paolo Albanese",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "B906",
        Abitanti: "327",
      },
      {
        Istat: "76078",
        Comune: "San Severino Lucano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "I157",
        Abitanti: "1711",
      },
      {
        Istat: "76079",
        Comune: "Sant'Angelo Le Fratte",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "I288",
        Abitanti: "1486",
      },
      {
        Istat: "76080",
        Comune: "Sant'Arcangelo",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85037",
        CodFisco: "I305",
        Abitanti: "6524",
      },
      {
        Istat: "76081",
        Comune: "Sarconi",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "I426",
        Abitanti: "1392",
      },
      {
        Istat: "76082",
        Comune: "Sasso di Castalda",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "I457",
        Abitanti: "852",
      },
      {
        Istat: "76083",
        Comune: "Satriano di Lucania",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85050",
        CodFisco: "G614",
        Abitanti: "2408",
      },
      {
        Istat: "76084",
        Comune: "Savoia di Lucania",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85050",
        CodFisco: "H730",
        Abitanti: "1166",
      },
      {
        Istat: "77031",
        Comune: "Scanzano Jonico",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75020",
        CodFisco: "M256",
        Abitanti: "7255",
      },
      {
        Istat: "76085",
        Comune: "Senise",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85038",
        CodFisco: "I610",
        Abitanti: "7304",
      },
      {
        Istat: "76086",
        Comune: "Spinoso",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85039",
        CodFisco: "I917",
        Abitanti: "1602",
      },
      {
        Istat: "77027",
        Comune: "Stigliano",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75018",
        CodFisco: "I954",
        Abitanti: "4794",
      },
      {
        Istat: "76087",
        Comune: "Teana",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85032",
        CodFisco: "L082",
        Abitanti: "669",
      },
      {
        Istat: "76088",
        Comune: "Terranova di Pollino",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85030",
        CodFisco: "L126",
        Abitanti: "1362",
      },
      {
        Istat: "76089",
        Comune: "Tito",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85050",
        CodFisco: "L181",
        Abitanti: "7197",
      },
      {
        Istat: "76090",
        Comune: "Tolve",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85017",
        CodFisco: "L197",
        Abitanti: "3396",
      },
      {
        Istat: "76091",
        Comune: "Tramutola",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85057",
        CodFisco: "L326",
        Abitanti: "3195",
      },
      {
        Istat: "76092",
        Comune: "Trecchina",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85049",
        CodFisco: "L357",
        Abitanti: "2369",
      },
      {
        Istat: "77028",
        Comune: "Tricarico",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75019",
        CodFisco: "L418",
        Abitanti: "5808",
      },
      {
        Istat: "76093",
        Comune: "Trivigno",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85018",
        CodFisco: "L439",
        Abitanti: "722",
      },
      {
        Istat: "77029",
        Comune: "Tursi",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75028",
        CodFisco: "L477",
        Abitanti: "5217",
      },
      {
        Istat: "76094",
        Comune: "Vaglio Basilicata",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85010",
        CodFisco: "L532",
        Abitanti: "2128",
      },
      {
        Istat: "77030",
        Comune: "Valsinni",
        Provincia: "MT",
        Regione: "BAS",
        Prefisso: "835",
        CAP: "75029",
        CodFisco: "D513",
        Abitanti: "1677",
      },
      {
        Istat: "76095",
        Comune: "Venosa",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "972",
        CAP: "85029",
        CodFisco: "L738",
        Abitanti: "12231",
      },
      {
        Istat: "76096",
        Comune: "Vietri di Potenza",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "971",
        CAP: "85058",
        CodFisco: "L859",
        Abitanti: "2933",
      },
      {
        Istat: "76097",
        Comune: "Viggianello",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "973",
        CAP: "85040",
        CodFisco: "L873",
        Abitanti: "3209",
      },
      {
        Istat: "76098",
        Comune: "Viggiano",
        Provincia: "PZ",
        Regione: "BAS",
        Prefisso: "975",
        CAP: "85059",
        CodFisco: "L874",
        Abitanti: "3170",
      },
      {
        Istat: "78001",
        Comune: "Acquaformosa",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "A033",
        Abitanti: "1184",
      },
      {
        Istat: "78002",
        Comune: "Acquappesa",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87020",
        CodFisco: "A041",
        Abitanti: "1936",
      },
      {
        Istat: "102001",
        Comune: "Acquaro",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89832",
        CodFisco: "A043",
        Abitanti: "2632",
      },
      {
        Istat: "78003",
        Comune: "Acri",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87041",
        CodFisco: "A053",
        Abitanti: "21228",
      },
      {
        Istat: "80001",
        Comune: "Africo",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "A065",
        Abitanti: "3251",
      },
      {
        Istat: "80002",
        Comune: "Agnana Calabra",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "A077",
        Abitanti: "616",
      },
      {
        Istat: "78004",
        Comune: "Aiello Calabro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87031",
        CodFisco: "A102",
        Abitanti: "2017",
      },
      {
        Istat: "78005",
        Comune: "Aieta",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "A105",
        Abitanti: "845",
      },
      {
        Istat: "79002",
        Comune: "Albi",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88055",
        CodFisco: "A155",
        Abitanti: "1027",
      },
      {
        Istat: "78006",
        Comune: "Albidona",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "A160",
        Abitanti: "1481",
      },
      {
        Istat: "78007",
        Comune: "Alessandria del Carretto",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "A183",
        Abitanti: "538",
      },
      {
        Istat: "78008",
        Comune: "Altilia",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "A234",
        Abitanti: "749",
      },
      {
        Istat: "78009",
        Comune: "Altomonte",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87042",
        CodFisco: "A240",
        Abitanti: "4679",
      },
      {
        Istat: "78010",
        Comune: "Amantea",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87032",
        CodFisco: "A253",
        Abitanti: "13925",
      },
      {
        Istat: "79003",
        Comune: "Amaroni",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "A255",
        Abitanti: "1918",
      },
      {
        Istat: "79004",
        Comune: "Amato",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88040",
        CodFisco: "A257",
        Abitanti: "861",
      },
      {
        Istat: "78011",
        Comune: "Amendolara",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87071",
        CodFisco: "A263",
        Abitanti: "3124",
      },
      {
        Istat: "79005",
        Comune: "Andali",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "A272",
        Abitanti: "811",
      },
      {
        Istat: "80003",
        Comune: "Anoia",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "A303",
        Abitanti: "2289",
      },
      {
        Istat: "80004",
        Comune: "Antonimina",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "A314",
        Abitanti: "1384",
      },
      {
        Istat: "78012",
        Comune: "Aprigliano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87051",
        CodFisco: "A340",
        Abitanti: "2714",
      },
      {
        Istat: "80005",
        Comune: "Ardore",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89031",
        CodFisco: "A385",
        Abitanti: "4977",
      },
      {
        Istat: "102002",
        Comune: "Arena",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89832",
        CodFisco: "A386",
        Abitanti: "1572",
      },
      {
        Istat: "79007",
        Comune: "Argusto",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "A397",
        Abitanti: "538",
      },
      {
        Istat: "79008",
        Comune: "Badolato",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "A542",
        Abitanti: "3268",
      },
      {
        Istat: "80006",
        Comune: "Bagaladi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89060",
        CodFisco: "A544",
        Abitanti: "1132",
      },
      {
        Istat: "80007",
        Comune: "Bagnara Calabra",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89011",
        CodFisco: "A552",
        Abitanti: "10660",
      },
      {
        Istat: "79009",
        Comune: "Belcastro",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "A736",
        Abitanti: "1365",
      },
      {
        Istat: "78013",
        Comune: "Belmonte Calabro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87033",
        CodFisco: "A762",
        Abitanti: "2251",
      },
      {
        Istat: "78014",
        Comune: "Belsito",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87030",
        CodFisco: "A768",
        Abitanti: "973",
      },
      {
        Istat: "101001",
        Comune: "Belvedere di Spinello",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88824",
        CodFisco: "A772",
        Abitanti: "2306",
      },
      {
        Istat: "78015",
        Comune: "Belvedere Marittimo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87021",
        CodFisco: "A773",
        Abitanti: "9388",
      },
      {
        Istat: "80008",
        Comune: "Benestare",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "A780",
        Abitanti: "2494",
      },
      {
        Istat: "78016",
        Comune: "Bianchi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "A842",
        Abitanti: "1411",
      },
      {
        Istat: "80009",
        Comune: "Bianco",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89032",
        CodFisco: "A843",
        Abitanti: "4337",
      },
      {
        Istat: "78017",
        Comune: "Bisignano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87043",
        CodFisco: "A887",
        Abitanti: "10487",
      },
      {
        Istat: "80010",
        Comune: "Bivongi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "A897",
        Abitanti: "1429",
      },
      {
        Istat: "78018",
        Comune: "Bocchigliero",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "A912",
        Abitanti: "1548",
      },
      {
        Istat: "78019",
        Comune: "Bonifati",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87020",
        CodFisco: "A973",
        Abitanti: "3116",
      },
      {
        Istat: "79011",
        Comune: "Borgia",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88021",
        CodFisco: "B002",
        Abitanti: "7677",
      },
      {
        Istat: "79012",
        Comune: "Botricello",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88070",
        CodFisco: "B085",
        Abitanti: "4985",
      },
      {
        Istat: "80011",
        Comune: "Bova",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89033",
        CodFisco: "B097",
        Abitanti: "465",
      },
      {
        Istat: "80013",
        Comune: "Bova Marina",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89035",
        CodFisco: "B099",
        Abitanti: "3873",
      },
      {
        Istat: "80012",
        Comune: "Bovalino",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89034",
        CodFisco: "B098",
        Abitanti: "9021",
      },
      {
        Istat: "80014",
        Comune: "Brancaleone",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89036",
        CodFisco: "B118",
        Abitanti: "3769",
      },
      {
        Istat: "102003",
        Comune: "Briatico",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89817",
        CodFisco: "B169",
        Abitanti: "4111",
      },
      {
        Istat: "102004",
        Comune: "Brognaturo",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89822",
        CodFisco: "B197",
        Abitanti: "665",
      },
      {
        Istat: "80015",
        Comune: "Bruzzano Zeffirio",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "B234",
        Abitanti: "1209",
      },
      {
        Istat: "78020",
        Comune: "Buonvicino",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "B270",
        Abitanti: "2349",
      },
      {
        Istat: "101002",
        Comune: "Caccuri",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "88833",
        CodFisco: "B319",
        Abitanti: "1730",
      },
      {
        Istat: "80016",
        Comune: "Calanna",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89050",
        CodFisco: "B379",
        Abitanti: "1006",
      },
      {
        Istat: "78021",
        Comune: "Calopezzati",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "B424",
        Abitanti: "1314",
      },
      {
        Istat: "78022",
        Comune: "Caloveto",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "B426",
        Abitanti: "1297",
      },
      {
        Istat: "80017",
        Comune: "Camini",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "B481",
        Abitanti: "737",
      },
      {
        Istat: "78023",
        Comune: "Campana",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87061",
        CodFisco: "B500",
        Abitanti: "1980",
      },
      {
        Istat: "80018",
        Comune: "Campo Calabro",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89052",
        CodFisco: "B516",
        Abitanti: "4407",
      },
      {
        Istat: "80019",
        Comune: "Candidoni",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "B591",
        Abitanti: "392",
      },
      {
        Istat: "78024",
        Comune: "Canna",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "B607",
        Abitanti: "797",
      },
      {
        Istat: "80020",
        Comune: "Canolo",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "B617",
        Abitanti: "814",
      },
      {
        Istat: "102005",
        Comune: "Capistrano",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89818",
        CodFisco: "B655",
        Abitanti: "1076",
      },
      {
        Istat: "80021",
        Comune: "Caraffa del Bianco",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "B718",
        Abitanti: "554",
      },
      {
        Istat: "79017",
        Comune: "Caraffa di Catanzaro",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "B717",
        Abitanti: "1990",
      },
      {
        Istat: "80022",
        Comune: "Cardeto",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89060",
        CodFisco: "B756",
        Abitanti: "1877",
      },
      {
        Istat: "79018",
        Comune: "Cardinale",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88062",
        CodFisco: "B758",
        Abitanti: "2396",
      },
      {
        Istat: "80023",
        Comune: "Careri",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "B766",
        Abitanti: "2370",
      },
      {
        Istat: "101003",
        Comune: "Carfizzi",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88817",
        CodFisco: "B771",
        Abitanti: "788",
      },
      {
        Istat: "78025",
        Comune: "Cariati",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87062",
        CodFisco: "B774",
        Abitanti: "8618",
      },
      {
        Istat: "79020",
        Comune: "Carlopoli",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "B790",
        Abitanti: "1653",
      },
      {
        Istat: "78026",
        Comune: "Carolei",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87030",
        CodFisco: "B802",
        Abitanti: "3517",
      },
      {
        Istat: "78027",
        Comune: "Carpanzano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "B813",
        Abitanti: "311",
      },
      {
        Istat: "101004",
        Comune: "Casabona",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88822",
        CodFisco: "B857",
        Abitanti: "2911",
      },
      {
        Istat: "80024",
        Comune: "Casignana",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "B966",
        Abitanti: "827",
      },
      {
        Istat: "78028",
        Comune: "Casole Bruzio",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "B983",
        Abitanti: "2606",
      },
      {
        Istat: "78029",
        Comune: "Cassano all'Ionio",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87011",
        CodFisco: "C002",
        Abitanti: "17587",
      },
      {
        Istat: "101005",
        Comune: "Castelsilano",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "88834",
        CodFisco: "B968",
        Abitanti: "1092",
      },
      {
        Istat: "78030",
        Comune: "Castiglione Cosentino",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "C301",
        Abitanti: "2983",
      },
      {
        Istat: "78031",
        Comune: "Castrolibero",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "C108",
        Abitanti: "10323",
      },
      {
        Istat: "78032",
        Comune: "Castroregio",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "C348",
        Abitanti: "360",
      },
      {
        Istat: "78033",
        Comune: "Castrovillari",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87012",
        CodFisco: "C349",
        Abitanti: "22561",
      },
      {
        Istat: "79023",
        Comune: "Catanzaro",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88100",
        CodFisco: "C352",
        Abitanti: "93124",
      },
      {
        Istat: "80025",
        Comune: "Caulonia",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89041",
        CodFisco: "C285",
        Abitanti: "7407",
      },
      {
        Istat: "78034",
        Comune: "Celico",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87053",
        CodFisco: "C430",
        Abitanti: "3046",
      },
      {
        Istat: "78035",
        Comune: "Cellara",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "C437",
        Abitanti: "508",
      },
      {
        Istat: "79024",
        Comune: "Cenadi",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88067",
        CodFisco: "C453",
        Abitanti: "593",
      },
      {
        Istat: "79025",
        Comune: "Centrache",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88067",
        CodFisco: "C472",
        Abitanti: "411",
      },
      {
        Istat: "78036",
        Comune: "Cerchiara di Calabria",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "C489",
        Abitanti: "2524",
      },
      {
        Istat: "101006",
        Comune: "Cerenzia",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "88833",
        CodFisco: "C501",
        Abitanti: "1255",
      },
      {
        Istat: "78037",
        Comune: "Cerisano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87044",
        CodFisco: "C515",
        Abitanti: "3306",
      },
      {
        Istat: "79027",
        Comune: "Cerva",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "C542",
        Abitanti: "1281",
      },
      {
        Istat: "78038",
        Comune: "Cervicati",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87010",
        CodFisco: "C554",
        Abitanti: "924",
      },
      {
        Istat: "78039",
        Comune: "Cerzeto",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "C560",
        Abitanti: "1257",
      },
      {
        Istat: "102006",
        Comune: "Cessaniti",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89816",
        CodFisco: "C581",
        Abitanti: "3440",
      },
      {
        Istat: "78040",
        Comune: "Cetraro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87022",
        CodFisco: "C588",
        Abitanti: "10125",
      },
      {
        Istat: "79029",
        Comune: "Chiaravalle Centrale",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88064",
        CodFisco: "C616",
        Abitanti: "6645",
      },
      {
        Istat: "79030",
        Comune: "Cicala",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "C674",
        Abitanti: "991",
      },
      {
        Istat: "80026",
        Comune: "Ciminà",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "C695",
        Abitanti: "598",
      },
      {
        Istat: "80027",
        Comune: "Cinquefrondi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89021",
        CodFisco: "C710",
        Abitanti: "6643",
      },
      {
        Istat: "101007",
        Comune: "Cirò",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88813",
        CodFisco: "C725",
        Abitanti: "3228",
      },
      {
        Istat: "101008",
        Comune: "Cirò Marina",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88811",
        CodFisco: "C726",
        Abitanti: "14957",
      },
      {
        Istat: "80028",
        Comune: "Cittanova",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89022",
        CodFisco: "C747",
        Abitanti: "10512",
      },
      {
        Istat: "78041",
        Comune: "Civita",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "C763",
        Abitanti: "969",
      },
      {
        Istat: "78042",
        Comune: "Cleto",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87030",
        CodFisco: "C795",
        Abitanti: "1348",
      },
      {
        Istat: "78043",
        Comune: "Colosimi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "C905",
        Abitanti: "1333",
      },
      {
        Istat: "80029",
        Comune: "Condofuri",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89030",
        CodFisco: "C954",
        Abitanti: "4977",
      },
      {
        Istat: "79033",
        Comune: "Conflenti",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "C960",
        Abitanti: "1470",
      },
      {
        Istat: "78044",
        Comune: "Corigliano Calabro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87064",
        CodFisco: "D005",
        Abitanti: "40548",
      },
      {
        Istat: "79034",
        Comune: "Cortale",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88020",
        CodFisco: "D049",
        Abitanti: "2309",
      },
      {
        Istat: "78045",
        Comune: "Cosenza",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87100",
        CodFisco: "D086",
        Abitanti: "70068",
      },
      {
        Istat: "80030",
        Comune: "Cosoleto",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89050",
        CodFisco: "D089",
        Abitanti: "942",
      },
      {
        Istat: "101009",
        Comune: "Cotronei",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88836",
        CodFisco: "D123",
        Abitanti: "5490",
      },
      {
        Istat: "78046",
        Comune: "Cropalati",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "D180",
        Abitanti: "1117",
      },
      {
        Istat: "79036",
        Comune: "Cropani",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88051",
        CodFisco: "D181",
        Abitanti: "4362",
      },
      {
        Istat: "78047",
        Comune: "Crosia",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "D184",
        Abitanti: "9532",
      },
      {
        Istat: "101010",
        Comune: "Crotone",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88900",
        CodFisco: "D122",
        Abitanti: "61798",
      },
      {
        Istat: "101011",
        Comune: "Crucoli",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88812",
        CodFisco: "D189",
        Abitanti: "3326",
      },
      {
        Istat: "79039",
        Comune: "Curinga",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88022",
        CodFisco: "D218",
        Abitanti: "6802",
      },
      {
        Istat: "101012",
        Comune: "Cutro",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88842",
        CodFisco: "D236",
        Abitanti: "10426",
      },
      {
        Istat: "102007",
        Comune: "Dasà",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89832",
        CodFisco: "D253",
        Abitanti: "1216",
      },
      {
        Istat: "79042",
        Comune: "Davoli",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "D257",
        Abitanti: "5493",
      },
      {
        Istat: "79043",
        Comune: "Decollatura",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88041",
        CodFisco: "D261",
        Abitanti: "3301",
      },
      {
        Istat: "80031",
        Comune: "Delianuova",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89012",
        CodFisco: "D268",
        Abitanti: "3507",
      },
      {
        Istat: "78048",
        Comune: "Diamante",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87023",
        CodFisco: "D289",
        Abitanti: "5447",
      },
      {
        Istat: "102008",
        Comune: "Dinami",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89833",
        CodFisco: "D303",
        Abitanti: "2526",
      },
      {
        Istat: "78049",
        Comune: "Dipignano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87045",
        CodFisco: "D304",
        Abitanti: "4506",
      },
      {
        Istat: "78050",
        Comune: "Domanico",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87030",
        CodFisco: "D328",
        Abitanti: "970",
      },
      {
        Istat: "102009",
        Comune: "Drapia",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89862",
        CodFisco: "D364",
        Abitanti: "2192",
      },
      {
        Istat: "102010",
        Comune: "Fabrizia",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89823",
        CodFisco: "D453",
        Abitanti: "2437",
      },
      {
        Istat: "78051",
        Comune: "Fagnano Castello",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87013",
        CodFisco: "D464",
        Abitanti: "3948",
      },
      {
        Istat: "78052",
        Comune: "Falconara Albanese",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87030",
        CodFisco: "D473",
        Abitanti: "1409",
      },
      {
        Istat: "79047",
        Comune: "Falerna",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88042",
        CodFisco: "D476",
        Abitanti: "4043",
      },
      {
        Istat: "79048",
        Comune: "Feroleto Antico",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "D544",
        Abitanti: "2109",
      },
      {
        Istat: "80032",
        Comune: "Feroleto della Chiesa",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89050",
        CodFisco: "D545",
        Abitanti: "1791",
      },
      {
        Istat: "80033",
        Comune: "Ferruzzano",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "D557",
        Abitanti: "795",
      },
      {
        Istat: "78053",
        Comune: "Figline Vegliaturo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "D582",
        Abitanti: "1117",
      },
      {
        Istat: "102011",
        Comune: "Filadelfia",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "89814",
        CodFisco: "D587",
        Abitanti: "5684",
      },
      {
        Istat: "102012",
        Comune: "Filandari",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89841",
        CodFisco: "D589",
        Abitanti: "1884",
      },
      {
        Istat: "102013",
        Comune: "Filogaso",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89843",
        CodFisco: "D596",
        Abitanti: "1454",
      },
      {
        Istat: "78054",
        Comune: "Firmo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "D614",
        Abitanti: "2257",
      },
      {
        Istat: "80034",
        Comune: "Fiumara",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89050",
        CodFisco: "D619",
        Abitanti: "1068",
      },
      {
        Istat: "78055",
        Comune: "Fiumefreddo Bruzio",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87030",
        CodFisco: "D624",
        Abitanti: "3156",
      },
      {
        Istat: "79052",
        Comune: "Fossato Serralta",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "D744",
        Abitanti: "607",
      },
      {
        Istat: "102014",
        Comune: "Francavilla Angitola",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "89815",
        CodFisco: "D762",
        Abitanti: "2051",
      },
      {
        Istat: "78056",
        Comune: "Francavilla Marittima",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87072",
        CodFisco: "D764",
        Abitanti: "2994",
      },
      {
        Istat: "102015",
        Comune: "Francica",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89851",
        CodFisco: "D767",
        Abitanti: "1711",
      },
      {
        Istat: "78057",
        Comune: "Frascineto",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "D774",
        Abitanti: "2289",
      },
      {
        Istat: "78058",
        Comune: "Fuscaldo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87024",
        CodFisco: "D828",
        Abitanti: "8334",
      },
      {
        Istat: "79055",
        Comune: "Gagliato",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "D852",
        Abitanti: "539",
      },
      {
        Istat: "80035",
        Comune: "Galatro",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89054",
        CodFisco: "D864",
        Abitanti: "1837",
      },
      {
        Istat: "79056",
        Comune: "Gasperina",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "D932",
        Abitanti: "2206",
      },
      {
        Istat: "80036",
        Comune: "Gerace",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "D975",
        Abitanti: "2836",
      },
      {
        Istat: "102016",
        Comune: "Gerocarne",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89831",
        CodFisco: "D988",
        Abitanti: "2383",
      },
      {
        Istat: "80037",
        Comune: "Giffone",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "E025",
        Abitanti: "1961",
      },
      {
        Istat: "79058",
        Comune: "Gimigliano",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88045",
        CodFisco: "E031",
        Abitanti: "3444",
      },
      {
        Istat: "80038",
        Comune: "Gioia Tauro",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89013",
        CodFisco: "E041",
        Abitanti: "18683",
      },
      {
        Istat: "80039",
        Comune: "Gioiosa Ionica",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89042",
        CodFisco: "E044",
        Abitanti: "7254",
      },
      {
        Istat: "79059",
        Comune: "Girifalco",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88024",
        CodFisco: "E050",
        Abitanti: "6164",
      },
      {
        Istat: "79060",
        Comune: "Gizzeria",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "E068",
        Abitanti: "4445",
      },
      {
        Istat: "78059",
        Comune: "Grimaldi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87034",
        CodFisco: "E180",
        Abitanti: "1776",
      },
      {
        Istat: "78060",
        Comune: "Grisolia",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "E185",
        Abitanti: "2407",
      },
      {
        Istat: "80040",
        Comune: "Grotteria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89043",
        CodFisco: "E212",
        Abitanti: "3273",
      },
      {
        Istat: "79061",
        Comune: "Guardavalle",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88065",
        CodFisco: "E239",
        Abitanti: "4929",
      },
      {
        Istat: "78061",
        Comune: "Guardia Piemontese",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87020",
        CodFisco: "E242",
        Abitanti: "1546",
      },
      {
        Istat: "102017",
        Comune: "Ionadi",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89851",
        CodFisco: "E321",
        Abitanti: "3796",
      },
      {
        Istat: "79063",
        Comune: "Isca sullo Ionio",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "E328",
        Abitanti: "1666",
      },
      {
        Istat: "101013",
        Comune: "Isola di Capo Rizzuto",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88841",
        CodFisco: "E339",
        Abitanti: "15789",
      },
      {
        Istat: "79065",
        Comune: "Jacurso",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88020",
        CodFisco: "E274",
        Abitanti: "639",
      },
      {
        Istat: "102018",
        Comune: "Joppolo",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89863",
        CodFisco: "E389",
        Abitanti: "2120",
      },
      {
        Istat: "80041",
        Comune: "Laganadi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89050",
        CodFisco: "E402",
        Abitanti: "422",
      },
      {
        Istat: "78062",
        Comune: "Lago",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87035",
        CodFisco: "E407",
        Abitanti: "2765",
      },
      {
        Istat: "78063",
        Comune: "Laino Borgo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87014",
        CodFisco: "E417",
        Abitanti: "2077",
      },
      {
        Istat: "78064",
        Comune: "Laino Castello",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87015",
        CodFisco: "E419",
        Abitanti: "897",
      },
      {
        Istat: "79160",
        Comune: "Lamezia Terme",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88046",
        CodFisco: "M208",
        Abitanti: "71286",
      },
      {
        Istat: "78065",
        Comune: "Lappano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "E450",
        Abitanti: "983",
      },
      {
        Istat: "78066",
        Comune: "Lattarico",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87010",
        CodFisco: "E475",
        Abitanti: "4246",
      },
      {
        Istat: "80042",
        Comune: "Laureana di Borrello",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89023",
        CodFisco: "E479",
        Abitanti: "5397",
      },
      {
        Istat: "102019",
        Comune: "Limbadi",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89844",
        CodFisco: "E590",
        Abitanti: "3741",
      },
      {
        Istat: "80043",
        Comune: "Locri",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89044",
        CodFisco: "D976",
        Abitanti: "12877",
      },
      {
        Istat: "78067",
        Comune: "Longobardi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87030",
        CodFisco: "E677",
        Abitanti: "2358",
      },
      {
        Istat: "78068",
        Comune: "Longobucco",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87066",
        CodFisco: "E678",
        Abitanti: "3612",
      },
      {
        Istat: "78069",
        Comune: "Lungro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "E745",
        Abitanti: "2812",
      },
      {
        Istat: "78070",
        Comune: "Luzzi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "E773",
        Abitanti: "9997",
      },
      {
        Istat: "79068",
        Comune: "Magisano",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "E806",
        Abitanti: "1285",
      },
      {
        Istat: "79069",
        Comune: "Maida",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88025",
        CodFisco: "E834",
        Abitanti: "4453",
      },
      {
        Istat: "78071",
        Comune: "Maierà",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "E835",
        Abitanti: "1254",
      },
      {
        Istat: "102020",
        Comune: "Maierato",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89843",
        CodFisco: "E836",
        Abitanti: "2279",
      },
      {
        Istat: "78072",
        Comune: "Malito",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87030",
        CodFisco: "E859",
        Abitanti: "843",
      },
      {
        Istat: "78073",
        Comune: "Malvito",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87010",
        CodFisco: "E872",
        Abitanti: "1888",
      },
      {
        Istat: "80044",
        Comune: "Mammola",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89045",
        CodFisco: "E873",
        Abitanti: "3049",
      },
      {
        Istat: "78074",
        Comune: "Mandatoriccio",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "E878",
        Abitanti: "2854",
      },
      {
        Istat: "78075",
        Comune: "Mangone",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "E888",
        Abitanti: "1885",
      },
      {
        Istat: "78076",
        Comune: "Marano Marchesato",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "E914",
        Abitanti: "3540",
      },
      {
        Istat: "78077",
        Comune: "Marano Principato",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "E915",
        Abitanti: "3120",
      },
      {
        Istat: "79071",
        Comune: "Marcedusa",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "E923",
        Abitanti: "453",
      },
      {
        Istat: "79072",
        Comune: "Marcellinara",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88044",
        CodFisco: "E925",
        Abitanti: "2275",
      },
      {
        Istat: "80045",
        Comune: "Marina di Gioiosa Ionica",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89046",
        CodFisco: "E956",
        Abitanti: "6610",
      },
      {
        Istat: "80046",
        Comune: "Maropati",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "E968",
        Abitanti: "1611",
      },
      {
        Istat: "79073",
        Comune: "Martirano",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "E990",
        Abitanti: "953",
      },
      {
        Istat: "79074",
        Comune: "Martirano Lombardo",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "E991",
        Abitanti: "1214",
      },
      {
        Istat: "80047",
        Comune: "Martone",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "E993",
        Abitanti: "569",
      },
      {
        Istat: "78078",
        Comune: "Marzi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "F001",
        Abitanti: "993",
      },
      {
        Istat: "80048",
        Comune: "Melicuccà",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "F105",
        Abitanti: "1004",
      },
      {
        Istat: "80049",
        Comune: "Melicucco",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "F106",
        Abitanti: "5206",
      },
      {
        Istat: "101014",
        Comune: "Melissa",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88814",
        CodFisco: "F108",
        Abitanti: "3609",
      },
      {
        Istat: "80050",
        Comune: "Melito di Porto Salvo",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89063",
        CodFisco: "F112",
        Abitanti: "11594",
      },
      {
        Istat: "78079",
        Comune: "Mendicino",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "F125",
        Abitanti: "9462",
      },
      {
        Istat: "101015",
        Comune: "Mesoraca",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88838",
        CodFisco: "F157",
        Abitanti: "6787",
      },
      {
        Istat: "79077",
        Comune: "Miglierina",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88040",
        CodFisco: "F200",
        Abitanti: "811",
      },
      {
        Istat: "102021",
        Comune: "Mileto",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89852",
        CodFisco: "F207",
        Abitanti: "7017",
      },
      {
        Istat: "80051",
        Comune: "Molochio",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89010",
        CodFisco: "F301",
        Abitanti: "2628",
      },
      {
        Istat: "80052",
        Comune: "Monasterace",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "F324",
        Abitanti: "3538",
      },
      {
        Istat: "102022",
        Comune: "Mongiana",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89823",
        CodFisco: "F364",
        Abitanti: "822",
      },
      {
        Istat: "78080",
        Comune: "Mongrassano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "F370",
        Abitanti: "1650",
      },
      {
        Istat: "78081",
        Comune: "Montalto Uffugo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87046",
        CodFisco: "F416",
        Abitanti: "20844",
      },
      {
        Istat: "79080",
        Comune: "Montauro",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "F432",
        Abitanti: "1514",
      },
      {
        Istat: "80053",
        Comune: "Montebello Ionico",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89064",
        CodFisco: "D746",
        Abitanti: "6463",
      },
      {
        Istat: "78082",
        Comune: "Montegiordano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "F519",
        Abitanti: "2020",
      },
      {
        Istat: "79081",
        Comune: "Montepaone",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "F586",
        Abitanti: "4900",
      },
      {
        Istat: "102023",
        Comune: "Monterosso Calabro",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89819",
        CodFisco: "F607",
        Abitanti: "1851",
      },
      {
        Istat: "78083",
        Comune: "Morano Calabro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87016",
        CodFisco: "F708",
        Abitanti: "4795",
      },
      {
        Istat: "78084",
        Comune: "Mormanno",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87026",
        CodFisco: "F735",
        Abitanti: "3326",
      },
      {
        Istat: "80054",
        Comune: "Motta San Giovanni",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89065",
        CodFisco: "F779",
        Abitanti: "6317",
      },
      {
        Istat: "79083",
        Comune: "Motta Santa Lucia",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "F780",
        Abitanti: "878",
      },
      {
        Istat: "78085",
        Comune: "Mottafollone",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "F775",
        Abitanti: "1317",
      },
      {
        Istat: "102024",
        Comune: "Nardodipace",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89824",
        CodFisco: "F843",
        Abitanti: "1414",
      },
      {
        Istat: "102025",
        Comune: "Nicotera",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89844",
        CodFisco: "F893",
        Abitanti: "6346",
      },
      {
        Istat: "78086",
        Comune: "Nocara",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "F907",
        Abitanti: "443",
      },
      {
        Istat: "79087",
        Comune: "Nocera Terinese",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88047",
        CodFisco: "F910",
        Abitanti: "4843",
      },
      {
        Istat: "79088",
        Comune: "Olivadi",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88067",
        CodFisco: "G034",
        Abitanti: "609",
      },
      {
        Istat: "80055",
        Comune: "Oppido Mamertina",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89014",
        CodFisco: "G082",
        Abitanti: "5442",
      },
      {
        Istat: "78087",
        Comune: "Oriolo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87073",
        CodFisco: "G110",
        Abitanti: "2556",
      },
      {
        Istat: "78088",
        Comune: "Orsomarso",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "G129",
        Abitanti: "1356",
      },
      {
        Istat: "79089",
        Comune: "Palermiti",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "G272",
        Abitanti: "1293",
      },
      {
        Istat: "80056",
        Comune: "Palizzi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89038",
        CodFisco: "G277",
        Abitanti: "2387",
      },
      {
        Istat: "101016",
        Comune: "Pallagorio",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88818",
        CodFisco: "G278",
        Abitanti: "1383",
      },
      {
        Istat: "80057",
        Comune: "Palmi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89015",
        CodFisco: "G288",
        Abitanti: "19320",
      },
      {
        Istat: "78089",
        Comune: "Paludi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "G298",
        Abitanti: "1168",
      },
      {
        Istat: "78090",
        Comune: "Panettieri",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "87050",
        CodFisco: "G307",
        Abitanti: "344",
      },
      {
        Istat: "78091",
        Comune: "Paola",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87027",
        CodFisco: "G317",
        Abitanti: "16866",
      },
      {
        Istat: "78092",
        Comune: "Papasidero",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87020",
        CodFisco: "G320",
        Abitanti: "855",
      },
      {
        Istat: "78093",
        Comune: "Parenti",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "G331",
        Abitanti: "2287",
      },
      {
        Istat: "102026",
        Comune: "Parghelia",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89861",
        CodFisco: "G335",
        Abitanti: "1348",
      },
      {
        Istat: "78094",
        Comune: "Paterno Calabro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "G372",
        Abitanti: "1352",
      },
      {
        Istat: "80058",
        Comune: "Pazzano",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "G394",
        Abitanti: "677",
      },
      {
        Istat: "78095",
        Comune: "Pedace",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "G400",
        Abitanti: "2031",
      },
      {
        Istat: "78096",
        Comune: "Pedivigliano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "G411",
        Abitanti: "893",
      },
      {
        Istat: "79092",
        Comune: "Pentone",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "G439",
        Abitanti: "2227",
      },
      {
        Istat: "101017",
        Comune: "Petilia Policastro",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88837",
        CodFisco: "G508",
        Abitanti: "9311",
      },
      {
        Istat: "79094",
        Comune: "Petrizzi",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "G517",
        Abitanti: "1167",
      },
      {
        Istat: "79095",
        Comune: "Petronà",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "G518",
        Abitanti: "2697",
      },
      {
        Istat: "78097",
        Comune: "Piane Crati",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "G553",
        Abitanti: "1454",
      },
      {
        Istat: "79096",
        Comune: "Pianopoli",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "D546",
        Abitanti: "2553",
      },
      {
        Istat: "78098",
        Comune: "Pietrafitta",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "G615",
        Abitanti: "1397",
      },
      {
        Istat: "78099",
        Comune: "Pietrapaola",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "G622",
        Abitanti: "1197",
      },
      {
        Istat: "102027",
        Comune: "Pizzo",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89812",
        CodFisco: "G722",
        Abitanti: "9258",
      },
      {
        Istat: "102028",
        Comune: "Pizzoni",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89834",
        CodFisco: "G728",
        Abitanti: "1247",
      },
      {
        Istat: "80059",
        Comune: "Placanica",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "G729",
        Abitanti: "1246",
      },
      {
        Istat: "78100",
        Comune: "Plataci",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "G733",
        Abitanti: "830",
      },
      {
        Istat: "79099",
        Comune: "Platania",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "G734",
        Abitanti: "2267",
      },
      {
        Istat: "80060",
        Comune: "Platì",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89039",
        CodFisco: "G735",
        Abitanti: "3780",
      },
      {
        Istat: "102029",
        Comune: "Polia",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89813",
        CodFisco: "G785",
        Abitanti: "1040",
      },
      {
        Istat: "80061",
        Comune: "Polistena",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89024",
        CodFisco: "G791",
        Abitanti: "11541",
      },
      {
        Istat: "80062",
        Comune: "Portigliola",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "G905",
        Abitanti: "1261",
      },
      {
        Istat: "78101",
        Comune: "Praia a Mare",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87028",
        CodFisco: "G975",
        Abitanti: "6802",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89121",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89122",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89123",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89124",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89125",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89126",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89127",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89128",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89129",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89130",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89131",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89132",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89133",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89134",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "80063",
        Comune: "Reggio Calabria",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89135",
        CodFisco: "H224",
        Abitanti: "186547",
      },
      {
        Istat: "78102",
        Comune: "Rende",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87036",
        CodFisco: "H235",
        Abitanti: "35488",
      },
      {
        Istat: "80064",
        Comune: "Riace",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "H265",
        Abitanti: "1977",
      },
      {
        Istat: "102030",
        Comune: "Ricadi",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89866",
        CodFisco: "H271",
        Abitanti: "4954",
      },
      {
        Istat: "80065",
        Comune: "Rizziconi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89016",
        CodFisco: "H359",
        Abitanti: "8046",
      },
      {
        Istat: "101019",
        Comune: "Rocca di Neto",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88821",
        CodFisco: "H403",
        Abitanti: "5657",
      },
      {
        Istat: "78103",
        Comune: "Rocca Imperiale",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87074",
        CodFisco: "H416",
        Abitanti: "3361",
      },
      {
        Istat: "101018",
        Comune: "Roccabernarda",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88835",
        CodFisco: "H383",
        Abitanti: "3374",
      },
      {
        Istat: "80066",
        Comune: "Roccaforte del Greco",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89060",
        CodFisco: "H408",
        Abitanti: "594",
      },
      {
        Istat: "80067",
        Comune: "Roccella Ionica",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89047",
        CodFisco: "H456",
        Abitanti: "6750",
      },
      {
        Istat: "78104",
        Comune: "Roggiano Gravina",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87017",
        CodFisco: "H488",
        Abitanti: "7371",
      },
      {
        Istat: "80068",
        Comune: "Roghudi",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89060",
        CodFisco: "H489",
        Abitanti: "1203",
      },
      {
        Istat: "78105",
        Comune: "Rogliano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87054",
        CodFisco: "H490",
        Abitanti: "5828",
      },
      {
        Istat: "102031",
        Comune: "Rombiolo",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89841",
        CodFisco: "H516",
        Abitanti: "4742",
      },
      {
        Istat: "80069",
        Comune: "Rosarno",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89025",
        CodFisco: "H558",
        Abitanti: "14836",
      },
      {
        Istat: "78106",
        Comune: "Rose",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "H565",
        Abitanti: "4396",
      },
      {
        Istat: "78107",
        Comune: "Roseto Capo Spulico",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "H572",
        Abitanti: "1910",
      },
      {
        Istat: "78108",
        Comune: "Rossano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87067",
        CodFisco: "H579",
        Abitanti: "38422",
      },
      {
        Istat: "78109",
        Comune: "Rota Greca",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87010",
        CodFisco: "H585",
        Abitanti: "1197",
      },
      {
        Istat: "78110",
        Comune: "Rovito",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "H621",
        Abitanti: "3213",
      },
      {
        Istat: "80070",
        Comune: "Samo",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "H013",
        Abitanti: "918",
      },
      {
        Istat: "78111",
        Comune: "San Basile",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "H765",
        Abitanti: "1072",
      },
      {
        Istat: "78112",
        Comune: "San Benedetto Ullano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "H774",
        Abitanti: "1625",
      },
      {
        Istat: "102032",
        Comune: "San Calogero",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89842",
        CodFisco: "H785",
        Abitanti: "4565",
      },
      {
        Istat: "78113",
        Comune: "San Cosmo Albanese",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "H806",
        Abitanti: "641",
      },
      {
        Istat: "102033",
        Comune: "San Costantino Calabro",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89851",
        CodFisco: "H807",
        Abitanti: "2303",
      },
      {
        Istat: "78114",
        Comune: "San Demetrio Corone",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87069",
        CodFisco: "H818",
        Abitanti: "3693",
      },
      {
        Istat: "78115",
        Comune: "San Donato di Ninea",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "H825",
        Abitanti: "1514",
      },
      {
        Istat: "80097",
        Comune: "San Ferdinando",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89026",
        CodFisco: "M277",
        Abitanti: "4435",
      },
      {
        Istat: "78116",
        Comune: "San Fili",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87037",
        CodFisco: "H841",
        Abitanti: "2800",
      },
      {
        Istat: "79108",
        Comune: "San Floro",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88021",
        CodFisco: "H846",
        Abitanti: "713",
      },
      {
        Istat: "78118",
        Comune: "San Giorgio Albanese",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "H881",
        Abitanti: "1579",
      },
      {
        Istat: "80071",
        Comune: "San Giorgio Morgeto",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89017",
        CodFisco: "H889",
        Abitanti: "3271",
      },
      {
        Istat: "80072",
        Comune: "San Giovanni di Gerace",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "H903",
        Abitanti: "552",
      },
      {
        Istat: "78119",
        Comune: "San Giovanni in Fiore",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87055",
        CodFisco: "H919",
        Abitanti: "18049",
      },
      {
        Istat: "102034",
        Comune: "San Gregorio d'Ippona",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89853",
        CodFisco: "H941",
        Abitanti: "2272",
      },
      {
        Istat: "80073",
        Comune: "San Lorenzo",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89069",
        CodFisco: "H959",
        Abitanti: "2857",
      },
      {
        Istat: "78120",
        Comune: "San Lorenzo Bellizzi",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87070",
        CodFisco: "H961",
        Abitanti: "765",
      },
      {
        Istat: "78121",
        Comune: "San Lorenzo del Vallo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87040",
        CodFisco: "H962",
        Abitanti: "3515",
      },
      {
        Istat: "80074",
        Comune: "San Luca",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "H970",
        Abitanti: "4088",
      },
      {
        Istat: "78122",
        Comune: "San Lucido",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87038",
        CodFisco: "H971",
        Abitanti: "6008",
      },
      {
        Istat: "79110",
        Comune: "San Mango d'Aquino",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "H976",
        Abitanti: "1746",
      },
      {
        Istat: "78123",
        Comune: "San Marco Argentano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87018",
        CodFisco: "H981",
        Abitanti: "7635",
      },
      {
        Istat: "78124",
        Comune: "San Martino di Finita",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87010",
        CodFisco: "H992",
        Abitanti: "1216",
      },
      {
        Istat: "101020",
        Comune: "San Mauro Marchesato",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88831",
        CodFisco: "I026",
        Abitanti: "2246",
      },
      {
        Istat: "78125",
        Comune: "San Nicola Arcella",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "I060",
        Abitanti: "1804",
      },
      {
        Istat: "102035",
        Comune: "San Nicola da Crissa",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89821",
        CodFisco: "I058",
        Abitanti: "1437",
      },
      {
        Istat: "101021",
        Comune: "San Nicola dell'Alto",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88817",
        CodFisco: "I057",
        Abitanti: "916",
      },
      {
        Istat: "79114",
        Comune: "San Pietro a Maida",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88025",
        CodFisco: "I093",
        Abitanti: "4296",
      },
      {
        Istat: "79115",
        Comune: "San Pietro Apostolo",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88040",
        CodFisco: "I095",
        Abitanti: "1769",
      },
      {
        Istat: "80075",
        Comune: "San Pietro di Caridà",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "I102",
        Abitanti: "1326",
      },
      {
        Istat: "78126",
        Comune: "San Pietro in Amantea",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87030",
        CodFisco: "I108",
        Abitanti: "540",
      },
      {
        Istat: "78127",
        Comune: "San Pietro in Guarano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87047",
        CodFisco: "I114",
        Abitanti: "3684",
      },
      {
        Istat: "80076",
        Comune: "San Procopio",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "I132",
        Abitanti: "563",
      },
      {
        Istat: "80077",
        Comune: "San Roberto",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89050",
        CodFisco: "I139",
        Abitanti: "1912",
      },
      {
        Istat: "79116",
        Comune: "San Sostene",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "I164",
        Abitanti: "1347",
      },
      {
        Istat: "78128",
        Comune: "San Sosti",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "I165",
        Abitanti: "2156",
      },
      {
        Istat: "78135",
        Comune: "San Vincenzo la Costa",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87030",
        CodFisco: "I388",
        Abitanti: "2199",
      },
      {
        Istat: "79122",
        Comune: "San Vito sullo Ionio",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88067",
        CodFisco: "I393",
        Abitanti: "1858",
      },
      {
        Istat: "78117",
        Comune: "Sangineto",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87020",
        CodFisco: "H877",
        Abitanti: "1404",
      },
      {
        Istat: "78129",
        Comune: "Santa Caterina Albanese",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87010",
        CodFisco: "I171",
        Abitanti: "1292",
      },
      {
        Istat: "79117",
        Comune: "Santa Caterina dello Ionio",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "I170",
        Abitanti: "2100",
      },
      {
        Istat: "80078",
        Comune: "Santa Cristina d'Aspromonte",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89056",
        CodFisco: "I176",
        Abitanti: "1054",
      },
      {
        Istat: "78130",
        Comune: "Santa Domenica Talao",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "I183",
        Abitanti: "1290",
      },
      {
        Istat: "78132",
        Comune: "Santa Maria del Cedro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "C717",
        Abitanti: "4957",
      },
      {
        Istat: "101022",
        Comune: "Santa Severina",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88832",
        CodFisco: "I308",
        Abitanti: "2234",
      },
      {
        Istat: "78133",
        Comune: "Santa Sofia D'Epiro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87048",
        CodFisco: "I309",
        Abitanti: "2934",
      },
      {
        Istat: "80079",
        Comune: "Sant'Agata del Bianco",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "I198",
        Abitanti: "683",
      },
      {
        Istat: "78131",
        Comune: "Sant'Agata di Esaro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "I192",
        Abitanti: "2007",
      },
      {
        Istat: "80080",
        Comune: "Sant'Alessio in Aspromonte",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89050",
        CodFisco: "I214",
        Abitanti: "357",
      },
      {
        Istat: "79118",
        Comune: "Sant'Andrea Apostolo dello Ionio",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "I266",
        Abitanti: "2131",
      },
      {
        Istat: "80081",
        Comune: "Sant'Eufemia d'Aspromonte",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89027",
        CodFisco: "I333",
        Abitanti: "4164",
      },
      {
        Istat: "80082",
        Comune: "Sant'Ilario dello Ionio",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "I341",
        Abitanti: "1374",
      },
      {
        Istat: "78134",
        Comune: "Santo Stefano di Rogliano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87056",
        CodFisco: "I359",
        Abitanti: "1648",
      },
      {
        Istat: "80083",
        Comune: "Santo Stefano in Aspromonte",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89057",
        CodFisco: "I371",
        Abitanti: "1330",
      },
      {
        Istat: "102036",
        Comune: "Sant'Onofrio",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89843",
        CodFisco: "I350",
        Abitanti: "3028",
      },
      {
        Istat: "78136",
        Comune: "Saracena",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "I423",
        Abitanti: "4061",
      },
      {
        Istat: "79123",
        Comune: "Satriano",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "I463",
        Abitanti: "3364",
      },
      {
        Istat: "101023",
        Comune: "Savelli",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "88825",
        CodFisco: "I468",
        Abitanti: "1334",
      },
      {
        Istat: "78137",
        Comune: "Scala Coeli",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "I485",
        Abitanti: "1181",
      },
      {
        Istat: "78138",
        Comune: "Scalea",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87029",
        CodFisco: "I489",
        Abitanti: "10948",
      },
      {
        Istat: "101024",
        Comune: "Scandale",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88831",
        CodFisco: "I494",
        Abitanti: "3300",
      },
      {
        Istat: "80084",
        Comune: "Scido",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89010",
        CodFisco: "I536",
        Abitanti: "998",
      },
      {
        Istat: "78139",
        Comune: "Scigliano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87057",
        CodFisco: "D290",
        Abitanti: "1371",
      },
      {
        Istat: "80085",
        Comune: "Scilla",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89058",
        CodFisco: "I537",
        Abitanti: "5139",
      },
      {
        Istat: "79126",
        Comune: "Sellia",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "I589",
        Abitanti: "537",
      },
      {
        Istat: "79127",
        Comune: "Sellia Marina",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "I590",
        Abitanti: "6619",
      },
      {
        Istat: "80086",
        Comune: "Seminara",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89028",
        CodFisco: "I600",
        Abitanti: "2993",
      },
      {
        Istat: "78140",
        Comune: "Serra d'Aiello",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "982",
        CAP: "87030",
        CodFisco: "I642",
        Abitanti: "659",
      },
      {
        Istat: "78141",
        Comune: "Serra Pedace",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "I650",
        Abitanti: "1006",
      },
      {
        Istat: "102037",
        Comune: "Serra San Bruno",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89822",
        CodFisco: "I639",
        Abitanti: "6971",
      },
      {
        Istat: "79129",
        Comune: "Serrastretta",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88040",
        CodFisco: "I655",
        Abitanti: "3299",
      },
      {
        Istat: "80087",
        Comune: "Serrata",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "I656",
        Abitanti: "922",
      },
      {
        Istat: "79130",
        Comune: "Sersale",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88054",
        CodFisco: "I671",
        Abitanti: "4827",
      },
      {
        Istat: "79131",
        Comune: "Settingiano",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88040",
        CodFisco: "I704",
        Abitanti: "2902",
      },
      {
        Istat: "80088",
        Comune: "Siderno",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89048",
        CodFisco: "I725",
        Abitanti: "18176",
      },
      {
        Istat: "102038",
        Comune: "Simbario",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89822",
        CodFisco: "I744",
        Abitanti: "989",
      },
      {
        Istat: "79133",
        Comune: "Simeri Crichi",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "I745",
        Abitanti: "4550",
      },
      {
        Istat: "80089",
        Comune: "Sinopoli",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89020",
        CodFisco: "I753",
        Abitanti: "2181",
      },
      {
        Istat: "79134",
        Comune: "Sorbo San Basile",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "I844",
        Abitanti: "886",
      },
      {
        Istat: "102039",
        Comune: "Sorianello",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89831",
        CodFisco: "I853",
        Abitanti: "1214",
      },
      {
        Istat: "102040",
        Comune: "Soriano Calabro",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89831",
        CodFisco: "I854",
        Abitanti: "2775",
      },
      {
        Istat: "79137",
        Comune: "Soverato",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88068",
        CodFisco: "I872",
        Abitanti: "9590",
      },
      {
        Istat: "79138",
        Comune: "Soveria Mannelli",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "968",
        CAP: "88049",
        CodFisco: "I874",
        Abitanti: "3206",
      },
      {
        Istat: "79139",
        Comune: "Soveria Simeri",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "I875",
        Abitanti: "1678",
      },
      {
        Istat: "102041",
        Comune: "Spadola",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89822",
        CodFisco: "I884",
        Abitanti: "871",
      },
      {
        Istat: "78142",
        Comune: "Spezzano Albanese",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87019",
        CodFisco: "I895",
        Abitanti: "7270",
      },
      {
        Istat: "78143",
        Comune: "Spezzano della Sila",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87058",
        CodFisco: "I896",
        Abitanti: "4688",
      },
      {
        Istat: "78144",
        Comune: "Spezzano Piccolo",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "I898",
        Abitanti: "2160",
      },
      {
        Istat: "102042",
        Comune: "Spilinga",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89864",
        CodFisco: "I905",
        Abitanti: "1503",
      },
      {
        Istat: "79142",
        Comune: "Squillace",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88069",
        CodFisco: "I929",
        Abitanti: "3450",
      },
      {
        Istat: "80090",
        Comune: "Staiti",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89030",
        CodFisco: "I936",
        Abitanti: "291",
      },
      {
        Istat: "79143",
        Comune: "Stalettì",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88069",
        CodFisco: "I937",
        Abitanti: "2494",
      },
      {
        Istat: "102043",
        Comune: "Stefanaconi",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89843",
        CodFisco: "I945",
        Abitanti: "2523",
      },
      {
        Istat: "80091",
        Comune: "Stignano",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89040",
        CodFisco: "I955",
        Abitanti: "1386",
      },
      {
        Istat: "80092",
        Comune: "Stilo",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "964",
        CAP: "89049",
        CodFisco: "I956",
        Abitanti: "2746",
      },
      {
        Istat: "101025",
        Comune: "Strongoli",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88816",
        CodFisco: "I982",
        Abitanti: "6383",
      },
      {
        Istat: "78145",
        Comune: "Tarsia",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87040",
        CodFisco: "L055",
        Abitanti: "2164",
      },
      {
        Istat: "80093",
        Comune: "Taurianova",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89029",
        CodFisco: "L063",
        Abitanti: "15824",
      },
      {
        Istat: "79146",
        Comune: "Taverna",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88055",
        CodFisco: "L070",
        Abitanti: "2712",
      },
      {
        Istat: "78146",
        Comune: "Terranova da Sibari",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87010",
        CodFisco: "L124",
        Abitanti: "5261",
      },
      {
        Istat: "80094",
        Comune: "Terranova Sappo Minulio",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89010",
        CodFisco: "L127",
        Abitanti: "534",
      },
      {
        Istat: "78147",
        Comune: "Terravecchia",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "L134",
        Abitanti: "856",
      },
      {
        Istat: "79147",
        Comune: "Tiriolo",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88056",
        CodFisco: "L177",
        Abitanti: "3975",
      },
      {
        Istat: "78148",
        Comune: "Torano Castello",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87010",
        CodFisco: "L206",
        Abitanti: "4789",
      },
      {
        Istat: "79148",
        Comune: "Torre di Ruggiero",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "967",
        CAP: "88060",
        CodFisco: "L240",
        Abitanti: "1136",
      },
      {
        Istat: "78149",
        Comune: "Tortora",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "L305",
        Abitanti: "6186",
      },
      {
        Istat: "78150",
        Comune: "Trebisacce",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87075",
        CodFisco: "L353",
        Abitanti: "9405",
      },
      {
        Istat: "78151",
        Comune: "Trenta",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87050",
        CodFisco: "L375",
        Abitanti: "2764",
      },
      {
        Istat: "102044",
        Comune: "Tropea",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89861",
        CodFisco: "L452",
        Abitanti: "6725",
      },
      {
        Istat: "101026",
        Comune: "Umbriatico",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88823",
        CodFisco: "L492",
        Abitanti: "953",
      },
      {
        Istat: "78152",
        Comune: "Vaccarizzo Albanese",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "983",
        CAP: "87060",
        CodFisco: "L524",
        Abitanti: "1183",
      },
      {
        Istat: "79151",
        Comune: "Vallefiorita",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "I322",
        Abitanti: "1912",
      },
      {
        Istat: "102045",
        Comune: "Vallelonga",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89821",
        CodFisco: "L607",
        Abitanti: "712",
      },
      {
        Istat: "80095",
        Comune: "Varapodio",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "966",
        CAP: "89010",
        CodFisco: "L673",
        Abitanti: "2245",
      },
      {
        Istat: "102046",
        Comune: "Vazzano",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89834",
        CodFisco: "L699",
        Abitanti: "1116",
      },
      {
        Istat: "78153",
        Comune: "Verbicaro",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "985",
        CAP: "87020",
        CodFisco: "L747",
        Abitanti: "3238",
      },
      {
        Istat: "101027",
        Comune: "Verzino",
        Provincia: "KR",
        Regione: "CAL",
        Prefisso: "962",
        CAP: "88819",
        CodFisco: "L802",
        Abitanti: "2022",
      },
      {
        Istat: "102047",
        Comune: "Vibo Valentia",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89900",
        CodFisco: "F537",
        Abitanti: "33853",
      },
      {
        Istat: "80096",
        Comune: "Villa San Giovanni",
        Provincia: "RC",
        Regione: "CAL",
        Prefisso: "965",
        CAP: "89018",
        CodFisco: "M018",
        Abitanti: "13792",
      },
      {
        Istat: "78154",
        Comune: "Villapiana",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "981",
        CAP: "87076",
        CodFisco: "B903",
        Abitanti: "5513",
      },
      {
        Istat: "102048",
        Comune: "Zaccanopoli",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89867",
        CodFisco: "M138",
        Abitanti: "810",
      },
      {
        Istat: "79157",
        Comune: "Zagarise",
        Provincia: "CZ",
        Regione: "CAL",
        Prefisso: "961",
        CAP: "88050",
        CodFisco: "M140",
        Abitanti: "1760",
      },
      {
        Istat: "102049",
        Comune: "Zambrone",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89868",
        CodFisco: "M143",
        Abitanti: "1829",
      },
      {
        Istat: "78155",
        Comune: "Zumpano",
        Provincia: "CS",
        Regione: "CAL",
        Prefisso: "984",
        CAP: "87040",
        CodFisco: "M202",
        Abitanti: "2413",
      },
      {
        Istat: "102050",
        Comune: "Zungri",
        Provincia: "VV",
        Regione: "CAL",
        Prefisso: "963",
        CAP: "89867",
        CodFisco: "M204",
        Abitanti: "2055",
      },
      {
        Istat: "65001",
        Comune: "Acerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84042",
        CodFisco: "A023",
        Abitanti: "2877",
      },
      {
        Istat: "63001",
        Comune: "Acerra",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80011",
        CodFisco: "A024",
        Abitanti: "56177",
      },
      {
        Istat: "63002",
        Comune: "Afragola",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80021",
        CodFisco: "A064",
        Abitanti: "63981",
      },
      {
        Istat: "63003",
        Comune: "Agerola",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80051",
        CodFisco: "A068",
        Abitanti: "7456",
      },
      {
        Istat: "65002",
        Comune: "Agropoli",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84043",
        CodFisco: "A091",
        Abitanti: "21305",
      },
      {
        Istat: "64001",
        Comune: "Aiello del Sabato",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83020",
        CodFisco: "A101",
        Abitanti: "4003",
      },
      {
        Istat: "61001",
        Comune: "Ailano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "A106",
        Abitanti: "1406",
      },
      {
        Istat: "62001",
        Comune: "Airola",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82011",
        CodFisco: "A110",
        Abitanti: "8147",
      },
      {
        Istat: "65003",
        Comune: "Albanella",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84044",
        CodFisco: "A128",
        Abitanti: "6519",
      },
      {
        Istat: "65004",
        Comune: "Alfano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84040",
        CodFisco: "A186",
        Abitanti: "1110",
      },
      {
        Istat: "61002",
        Comune: "Alife",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81011",
        CodFisco: "A200",
        Abitanti: "7571",
      },
      {
        Istat: "64002",
        Comune: "Altavilla Irpina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83011",
        CodFisco: "A228",
        Abitanti: "4185",
      },
      {
        Istat: "65005",
        Comune: "Altavilla Silentina",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84045",
        CodFisco: "A230",
        Abitanti: "7010",
      },
      {
        Istat: "61003",
        Comune: "Alvignano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81012",
        CodFisco: "A243",
        Abitanti: "5011",
      },
      {
        Istat: "65006",
        Comune: "Amalfi",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84011",
        CodFisco: "A251",
        Abitanti: "5317",
      },
      {
        Istat: "62002",
        Comune: "Amorosi",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82031",
        CodFisco: "A265",
        Abitanti: "2879",
      },
      {
        Istat: "63004",
        Comune: "Anacapri",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80071",
        CodFisco: "A268",
        Abitanti: "6768",
      },
      {
        Istat: "64003",
        Comune: "Andretta",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "A284",
        Abitanti: "2089",
      },
      {
        Istat: "65007",
        Comune: "Angri",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84012",
        CodFisco: "A294",
        Abitanti: "32226",
      },
      {
        Istat: "62003",
        Comune: "Apice",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82021",
        CodFisco: "A328",
        Abitanti: "5819",
      },
      {
        Istat: "62004",
        Comune: "Apollosa",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "A330",
        Abitanti: "2725",
      },
      {
        Istat: "65008",
        Comune: "Aquara",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "A343",
        Abitanti: "1606",
      },
      {
        Istat: "64004",
        Comune: "Aquilonia",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83041",
        CodFisco: "A347",
        Abitanti: "1848",
      },
      {
        Istat: "64005",
        Comune: "Ariano Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83031",
        CodFisco: "A399",
        Abitanti: "23134",
      },
      {
        Istat: "61004",
        Comune: "Arienzo",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81021",
        CodFisco: "A403",
        Abitanti: "5364",
      },
      {
        Istat: "62005",
        Comune: "Arpaia",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82011",
        CodFisco: "A431",
        Abitanti: "2001",
      },
      {
        Istat: "62006",
        Comune: "Arpaise",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82010",
        CodFisco: "A432",
        Abitanti: "858",
      },
      {
        Istat: "63005",
        Comune: "Arzano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80022",
        CodFisco: "A455",
        Abitanti: "35926",
      },
      {
        Istat: "65009",
        Comune: "Ascea",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84046",
        CodFisco: "A460",
        Abitanti: "5830",
      },
      {
        Istat: "65010",
        Comune: "Atena Lucana",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "A484",
        Abitanti: "2344",
      },
      {
        Istat: "65011",
        Comune: "Atrani",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "A487",
        Abitanti: "913",
      },
      {
        Istat: "64006",
        Comune: "Atripalda",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83042",
        CodFisco: "A489",
        Abitanti: "11174",
      },
      {
        Istat: "65012",
        Comune: "Auletta",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84031",
        CodFisco: "A495",
        Abitanti: "2440",
      },
      {
        Istat: "64007",
        Comune: "Avella",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83021",
        CodFisco: "A508",
        Abitanti: "7958",
      },
      {
        Istat: "64008",
        Comune: "Avellino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83100",
        CodFisco: "A509",
        Abitanti: "56339",
      },
      {
        Istat: "61005",
        Comune: "Aversa",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81031",
        CodFisco: "A512",
        Abitanti: "51631",
      },
      {
        Istat: "63006",
        Comune: "Bacoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80070",
        CodFisco: "A535",
        Abitanti: "27267",
      },
      {
        Istat: "64009",
        Comune: "Bagnoli Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83043",
        CodFisco: "A566",
        Abitanti: "3286",
      },
      {
        Istat: "61006",
        Comune: "Baia e Latina",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "A579",
        Abitanti: "2349",
      },
      {
        Istat: "64010",
        Comune: "Baiano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83022",
        CodFisco: "A580",
        Abitanti: "4763",
      },
      {
        Istat: "63007",
        Comune: "Barano d'Ischia",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80070",
        CodFisco: "A617",
        Abitanti: "10083",
      },
      {
        Istat: "65013",
        Comune: "Baronissi",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84081",
        CodFisco: "A674",
        Abitanti: "16850",
      },
      {
        Istat: "62007",
        Comune: "Baselice",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "A696",
        Abitanti: "2593",
      },
      {
        Istat: "65014",
        Comune: "Battipaglia",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84091",
        CodFisco: "A717",
        Abitanti: "51133",
      },
      {
        Istat: "65158",
        Comune: "Bellizzi",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84092",
        CodFisco: "M294",
        Abitanti: "13172",
      },
      {
        Istat: "61007",
        Comune: "Bellona",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81041",
        CodFisco: "A755",
        Abitanti: "5846",
      },
      {
        Istat: "65015",
        Comune: "Bellosguardo",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "A756",
        Abitanti: "865",
      },
      {
        Istat: "62008",
        Comune: "Benevento",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82100",
        CodFisco: "A783",
        Abitanti: "62035",
      },
      {
        Istat: "64011",
        Comune: "Bisaccia",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83044",
        CodFisco: "A881",
        Abitanti: "4044",
      },
      {
        Istat: "62009",
        Comune: "Bonea",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82013",
        CodFisco: "A970",
        Abitanti: "1498",
      },
      {
        Istat: "64012",
        Comune: "Bonito",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83032",
        CodFisco: "A975",
        Abitanti: "2553",
      },
      {
        Istat: "63008",
        Comune: "Boscoreale",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80041",
        CodFisco: "B076",
        Abitanti: "26984",
      },
      {
        Istat: "63009",
        Comune: "Boscotrecase",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80042",
        CodFisco: "B077",
        Abitanti: "10645",
      },
      {
        Istat: "65016",
        Comune: "Bracigliano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84082",
        CodFisco: "B115",
        Abitanti: "5621",
      },
      {
        Istat: "63010",
        Comune: "Brusciano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80031",
        CodFisco: "B227",
        Abitanti: "16017",
      },
      {
        Istat: "62010",
        Comune: "Bucciano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82010",
        CodFisco: "B239",
        Abitanti: "2064",
      },
      {
        Istat: "65017",
        Comune: "Buccino",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84021",
        CodFisco: "B242",
        Abitanti: "5394",
      },
      {
        Istat: "65018",
        Comune: "Buonabitacolo",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84032",
        CodFisco: "B266",
        Abitanti: "2632",
      },
      {
        Istat: "62011",
        Comune: "Buonalbergo",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "B267",
        Abitanti: "1852",
      },
      {
        Istat: "65019",
        Comune: "Caggiano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "B351",
        Abitanti: "2858",
      },
      {
        Istat: "61008",
        Comune: "Caianello",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81059",
        CodFisco: "B361",
        Abitanti: "1775",
      },
      {
        Istat: "61009",
        Comune: "Caiazzo",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81013",
        CodFisco: "B362",
        Abitanti: "5822",
      },
      {
        Istat: "64013",
        Comune: "Cairano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "B367",
        Abitanti: "371",
      },
      {
        Istat: "63011",
        Comune: "Caivano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80023",
        CodFisco: "B371",
        Abitanti: "36871",
      },
      {
        Istat: "64014",
        Comune: "Calabritto",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "B374",
        Abitanti: "2560",
      },
      {
        Istat: "64015",
        Comune: "Calitri",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83045",
        CodFisco: "B415",
        Abitanti: "5042",
      },
      {
        Istat: "65020",
        Comune: "Calvanico",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84080",
        CodFisco: "B437",
        Abitanti: "1570",
      },
      {
        Istat: "62012",
        Comune: "Calvi",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82018",
        CodFisco: "B444",
        Abitanti: "2634",
      },
      {
        Istat: "61010",
        Comune: "Calvi Risorta",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81042",
        CodFisco: "B445",
        Abitanti: "5855",
      },
      {
        Istat: "63012",
        Comune: "Calvizzano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80012",
        CodFisco: "B452",
        Abitanti: "12737",
      },
      {
        Istat: "65021",
        Comune: "Camerota",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84040",
        CodFisco: "B476",
        Abitanti: "7289",
      },
      {
        Istat: "61011",
        Comune: "Camigliano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81050",
        CodFisco: "B477",
        Abitanti: "1884",
      },
      {
        Istat: "65022",
        Comune: "Campagna",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84022",
        CodFisco: "B492",
        Abitanti: "16183",
      },
      {
        Istat: "62013",
        Comune: "Campolattaro",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "B541",
        Abitanti: "1090",
      },
      {
        Istat: "62014",
        Comune: "Campoli del Monte Taburno",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "B542",
        Abitanti: "1538",
      },
      {
        Istat: "65023",
        Comune: "Campora",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84040",
        CodFisco: "B555",
        Abitanti: "479",
      },
      {
        Istat: "63013",
        Comune: "Camposano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "B565",
        Abitanti: "5450",
      },
      {
        Istat: "61012",
        Comune: "Cancello ed Arnone",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81030",
        CodFisco: "B581",
        Abitanti: "5371",
      },
      {
        Istat: "64016",
        Comune: "Candida",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83040",
        CodFisco: "B590",
        Abitanti: "1163",
      },
      {
        Istat: "65024",
        Comune: "Cannalonga",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84040",
        CodFisco: "B608",
        Abitanti: "1098",
      },
      {
        Istat: "65025",
        Comune: "Capaccio",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84047",
        CodFisco: "B644",
        Abitanti: "22300",
      },
      {
        Istat: "61013",
        Comune: "Capodrise",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81020",
        CodFisco: "B667",
        Abitanti: "9747",
      },
      {
        Istat: "64017",
        Comune: "Caposele",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "B674",
        Abitanti: "3605",
      },
      {
        Istat: "63014",
        Comune: "Capri",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80073",
        CodFisco: "B696",
        Abitanti: "7349",
      },
      {
        Istat: "61014",
        Comune: "Capriati a Volturno",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81014",
        CodFisco: "B704",
        Abitanti: "1661",
      },
      {
        Istat: "64018",
        Comune: "Capriglia Irpina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "B706",
        Abitanti: "2416",
      },
      {
        Istat: "61015",
        Comune: "Capua",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81043",
        CodFisco: "B715",
        Abitanti: "18839",
      },
      {
        Istat: "63015",
        Comune: "Carbonara di Nola",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "B740",
        Abitanti: "2293",
      },
      {
        Istat: "63016",
        Comune: "Cardito",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80024",
        CodFisco: "B759",
        Abitanti: "21302",
      },
      {
        Istat: "64019",
        Comune: "Carife",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "B776",
        Abitanti: "1530",
      },
      {
        Istat: "61016",
        Comune: "Carinaro",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81032",
        CodFisco: "B779",
        Abitanti: "7034",
      },
      {
        Istat: "61017",
        Comune: "Carinola",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81030",
        CodFisco: "B781",
        Abitanti: "8287",
      },
      {
        Istat: "61018",
        Comune: "Casagiove",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81022",
        CodFisco: "B860",
        Abitanti: "14159",
      },
      {
        Istat: "61019",
        Comune: "Casal di Principe",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81033",
        CodFisco: "B872",
        Abitanti: "21404",
      },
      {
        Istat: "65028",
        Comune: "Casal Velino",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84040",
        CodFisco: "B895",
        Abitanti: "4995",
      },
      {
        Istat: "64020",
        Comune: "Casalbore",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83034",
        CodFisco: "B866",
        Abitanti: "1933",
      },
      {
        Istat: "65026",
        Comune: "Casalbuono",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "B868",
        Abitanti: "1236",
      },
      {
        Istat: "62015",
        Comune: "Casalduni",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82027",
        CodFisco: "B873",
        Abitanti: "1493",
      },
      {
        Istat: "65027",
        Comune: "Casaletto Spartano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "973",
        CAP: "84030",
        CodFisco: "B888",
        Abitanti: "1466",
      },
      {
        Istat: "63017",
        Comune: "Casalnuovo di Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80013",
        CodFisco: "B905",
        Abitanti: "50724",
      },
      {
        Istat: "61020",
        Comune: "Casaluce",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "B916",
        Abitanti: "10283",
      },
      {
        Istat: "63018",
        Comune: "Casamarciano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80032",
        CodFisco: "B922",
        Abitanti: "3359",
      },
      {
        Istat: "63019",
        Comune: "Casamicciola Terme",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80074",
        CodFisco: "B924",
        Abitanti: "8361",
      },
      {
        Istat: "63020",
        Comune: "Casandrino",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80025",
        CodFisco: "B925",
        Abitanti: "13179",
      },
      {
        Istat: "61103",
        Comune: "Casapesenna",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "M260",
        Abitanti: "6874",
      },
      {
        Istat: "61021",
        Comune: "Casapulla",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81020",
        CodFisco: "B935",
        Abitanti: "8622",
      },
      {
        Istat: "63021",
        Comune: "Casavatore",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80020",
        CodFisco: "B946",
        Abitanti: "18499",
      },
      {
        Istat: "65029",
        Comune: "Caselle in Pittari",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84030",
        CodFisco: "B959",
        Abitanti: "2001",
      },
      {
        Istat: "61022",
        Comune: "Caserta",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81100",
        CodFisco: "B963",
        Abitanti: "78693",
      },
      {
        Istat: "63022",
        Comune: "Casola di Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80050",
        CodFisco: "B980",
        Abitanti: "3864",
      },
      {
        Istat: "63023",
        Comune: "Casoria",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80026",
        CodFisco: "B990",
        Abitanti: "79562",
      },
      {
        Istat: "64021",
        Comune: "Cassano Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "B997",
        Abitanti: "996",
      },
      {
        Istat: "64022",
        Comune: "Castel Baronia",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "C058",
        Abitanti: "1178",
      },
      {
        Istat: "61023",
        Comune: "Castel Campagnano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "B494",
        Abitanti: "1635",
      },
      {
        Istat: "61024",
        Comune: "Castel di Sasso",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81040",
        CodFisco: "C097",
        Abitanti: "1201",
      },
      {
        Istat: "61026",
        Comune: "Castel Morrone",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81020",
        CodFisco: "C211",
        Abitanti: "3982",
      },
      {
        Istat: "65034",
        Comune: "Castel San Giorgio",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84083",
        CodFisco: "C259",
        Abitanti: "13728",
      },
      {
        Istat: "65035",
        Comune: "Castel San Lorenzo",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84049",
        CodFisco: "C262",
        Abitanti: "2708",
      },
      {
        Istat: "61027",
        Comune: "Castel Volturno",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81030",
        CodFisco: "C291",
        Abitanti: "24149",
      },
      {
        Istat: "65030",
        Comune: "Castelcivita",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "C069",
        Abitanti: "1902",
      },
      {
        Istat: "64023",
        Comune: "Castelfranci",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "C105",
        Abitanti: "2165",
      },
      {
        Istat: "62016",
        Comune: "Castelfranco in Miscano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82022",
        CodFisco: "C106",
        Abitanti: "964",
      },
      {
        Istat: "65031",
        Comune: "Castellabate",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84048",
        CodFisco: "C125",
        Abitanti: "8213",
      },
      {
        Istat: "63024",
        Comune: "Castellammare di Stabia",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80053",
        CodFisco: "C129",
        Abitanti: "64506",
      },
      {
        Istat: "61025",
        Comune: "Castello del Matese",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81016",
        CodFisco: "C178",
        Abitanti: "1539",
      },
      {
        Istat: "63025",
        Comune: "Castello di Cisterna",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "C188",
        Abitanti: "7435",
      },
      {
        Istat: "65032",
        Comune: "Castelnuovo Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84040",
        CodFisco: "C231",
        Abitanti: "2614",
      },
      {
        Istat: "65033",
        Comune: "Castelnuovo di Conza",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "C235",
        Abitanti: "662",
      },
      {
        Istat: "62017",
        Comune: "Castelpagano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82024",
        CodFisco: "C245",
        Abitanti: "1572",
      },
      {
        Istat: "62018",
        Comune: "Castelpoto",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "C250",
        Abitanti: "1370",
      },
      {
        Istat: "62019",
        Comune: "Castelvenere",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82037",
        CodFisco: "C280",
        Abitanti: "2562",
      },
      {
        Istat: "62020",
        Comune: "Castelvetere in Val Fortore",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82023",
        CodFisco: "C284",
        Abitanti: "1442",
      },
      {
        Istat: "64024",
        Comune: "Castelvetere sul Calore",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "C283",
        Abitanti: "1707",
      },
      {
        Istat: "65036",
        Comune: "Castiglione del Genovesi",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84090",
        CodFisco: "C306",
        Abitanti: "1364",
      },
      {
        Istat: "62021",
        Comune: "Cautano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "C359",
        Abitanti: "2116",
      },
      {
        Istat: "65037",
        Comune: "Cava de' Tirreni",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84013",
        CodFisco: "C361",
        Abitanti: "53520",
      },
      {
        Istat: "65038",
        Comune: "Celle di Bulgheria",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84040",
        CodFisco: "C444",
        Abitanti: "1934",
      },
      {
        Istat: "61102",
        Comune: "Cellole",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81030",
        CodFisco: "M262",
        Abitanti: "7873",
      },
      {
        Istat: "65039",
        Comune: "Centola",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84051",
        CodFisco: "C470",
        Abitanti: "5004",
      },
      {
        Istat: "62022",
        Comune: "Ceppaloni",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82010",
        CodFisco: "C476",
        Abitanti: "3375",
      },
      {
        Istat: "65040",
        Comune: "Ceraso",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84052",
        CodFisco: "C485",
        Abitanti: "2532",
      },
      {
        Istat: "63026",
        Comune: "Cercola",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "C495",
        Abitanti: "19336",
      },
      {
        Istat: "62023",
        Comune: "Cerreto Sannita",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82032",
        CodFisco: "C525",
        Abitanti: "4159",
      },
      {
        Istat: "64025",
        Comune: "Cervinara",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "83012",
        CodFisco: "C557",
        Abitanti: "9926",
      },
      {
        Istat: "61028",
        Comune: "Cervino",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81023",
        CodFisco: "C558",
        Abitanti: "5062",
      },
      {
        Istat: "61029",
        Comune: "Cesa",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "C561",
        Abitanti: "8241",
      },
      {
        Istat: "64026",
        Comune: "Cesinali",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83020",
        CodFisco: "C576",
        Abitanti: "2570",
      },
      {
        Istat: "65041",
        Comune: "Cetara",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "C584",
        Abitanti: "2317",
      },
      {
        Istat: "64027",
        Comune: "Chianche",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "C606",
        Abitanti: "571",
      },
      {
        Istat: "64028",
        Comune: "Chiusano di San Domenico",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83040",
        CodFisco: "C659",
        Abitanti: "2388",
      },
      {
        Istat: "63027",
        Comune: "Cicciano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80033",
        CodFisco: "C675",
        Abitanti: "12290",
      },
      {
        Istat: "65042",
        Comune: "Cicerale",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84053",
        CodFisco: "C676",
        Abitanti: "1272",
      },
      {
        Istat: "63028",
        Comune: "Cimitile",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "C697",
        Abitanti: "7356",
      },
      {
        Istat: "61030",
        Comune: "Ciorlano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "C716",
        Abitanti: "443",
      },
      {
        Istat: "62024",
        Comune: "Circello",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "C719",
        Abitanti: "2501",
      },
      {
        Istat: "62025",
        Comune: "Colle Sannita",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82024",
        CodFisco: "C846",
        Abitanti: "2680",
      },
      {
        Istat: "65043",
        Comune: "Colliano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "C879",
        Abitanti: "3800",
      },
      {
        Istat: "63029",
        Comune: "Comiziano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "C929",
        Abitanti: "1821",
      },
      {
        Istat: "65044",
        Comune: "Conca dei Marini",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "C940",
        Abitanti: "739",
      },
      {
        Istat: "61031",
        Comune: "Conca della Campania",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81044",
        CodFisco: "C939",
        Abitanti: "1292",
      },
      {
        Istat: "64029",
        Comune: "Contrada",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83020",
        CodFisco: "C971",
        Abitanti: "3020",
      },
      {
        Istat: "65045",
        Comune: "Controne",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "C973",
        Abitanti: "909",
      },
      {
        Istat: "65046",
        Comune: "Contursi Terme",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84024",
        CodFisco: "C974",
        Abitanti: "3367",
      },
      {
        Istat: "64030",
        Comune: "Conza della Campania",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "C976",
        Abitanti: "1447",
      },
      {
        Istat: "65047",
        Comune: "Corbara",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84010",
        CodFisco: "C984",
        Abitanti: "2594",
      },
      {
        Istat: "65048",
        Comune: "Corleto Monforte",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "D011",
        Abitanti: "672",
      },
      {
        Istat: "63030",
        Comune: "Crispano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80020",
        CodFisco: "D170",
        Abitanti: "12585",
      },
      {
        Istat: "65049",
        Comune: "Cuccaro Vetere",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84050",
        CodFisco: "D195",
        Abitanti: "584",
      },
      {
        Istat: "61032",
        Comune: "Curti",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81040",
        CodFisco: "D228",
        Abitanti: "7234",
      },
      {
        Istat: "62026",
        Comune: "Cusano Mutri",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82033",
        CodFisco: "D230",
        Abitanti: "4226",
      },
      {
        Istat: "64031",
        Comune: "Domicella",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "D331",
        Abitanti: "1915",
      },
      {
        Istat: "61033",
        Comune: "Dragoni",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "D361",
        Abitanti: "2154",
      },
      {
        Istat: "62027",
        Comune: "Dugenta",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "D380",
        Abitanti: "2733",
      },
      {
        Istat: "62028",
        Comune: "Durazzano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82015",
        CodFisco: "D386",
        Abitanti: "2261",
      },
      {
        Istat: "65050",
        Comune: "Eboli",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84025",
        CodFisco: "D390",
        Abitanti: "38470",
      },
      {
        Istat: "63064",
        Comune: "Ercolano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80056",
        CodFisco: "H243",
        Abitanti: "54779",
      },
      {
        Istat: "62029",
        Comune: "Faicchio",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "D469",
        Abitanti: "3746",
      },
      {
        Istat: "61101",
        Comune: "Falciano del Massico",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81030",
        CodFisco: "D471",
        Abitanti: "3762",
      },
      {
        Istat: "65051",
        Comune: "Felitto",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84055",
        CodFisco: "D527",
        Abitanti: "1332",
      },
      {
        Istat: "65052",
        Comune: "Fisciano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84084",
        CodFisco: "D615",
        Abitanti: "13652",
      },
      {
        Istat: "64032",
        Comune: "Flumeri",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83040",
        CodFisco: "D638",
        Abitanti: "3112",
      },
      {
        Istat: "62030",
        Comune: "Foglianise",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "D644",
        Abitanti: "3520",
      },
      {
        Istat: "62031",
        Comune: "Foiano di Val Fortore",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "D650",
        Abitanti: "1484",
      },
      {
        Istat: "64033",
        Comune: "Fontanarosa",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83040",
        CodFisco: "D671",
        Abitanti: "3329",
      },
      {
        Istat: "61034",
        Comune: "Fontegreca",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81014",
        CodFisco: "D683",
        Abitanti: "857",
      },
      {
        Istat: "62032",
        Comune: "Forchia",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82011",
        CodFisco: "D693",
        Abitanti: "1226",
      },
      {
        Istat: "64034",
        Comune: "Forino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83020",
        CodFisco: "D701",
        Abitanti: "5396",
      },
      {
        Istat: "63031",
        Comune: "Forio",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80075",
        CodFisco: "D702",
        Abitanti: "17600",
      },
      {
        Istat: "61035",
        Comune: "Formicola",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81040",
        CodFisco: "D709",
        Abitanti: "1580",
      },
      {
        Istat: "62033",
        Comune: "Fragneto L'Abate",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "D755",
        Abitanti: "1094",
      },
      {
        Istat: "62034",
        Comune: "Fragneto Monforte",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "D756",
        Abitanti: "1880",
      },
      {
        Istat: "61036",
        Comune: "Francolise",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81050",
        CodFisco: "D769",
        Abitanti: "5025",
      },
      {
        Istat: "62035",
        Comune: "Frasso Telesino",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "D784",
        Abitanti: "2448",
      },
      {
        Istat: "63032",
        Comune: "Frattamaggiore",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80027",
        CodFisco: "D789",
        Abitanti: "30108",
      },
      {
        Istat: "63033",
        Comune: "Frattaminore",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80020",
        CodFisco: "D790",
        Abitanti: "16067",
      },
      {
        Istat: "64035",
        Comune: "Frigento",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83040",
        CodFisco: "D798",
        Abitanti: "4017",
      },
      {
        Istat: "61037",
        Comune: "Frignano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "D799",
        Abitanti: "8659",
      },
      {
        Istat: "65053",
        Comune: "Furore",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "D826",
        Abitanti: "850",
      },
      {
        Istat: "65054",
        Comune: "Futani",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84050",
        CodFisco: "D832",
        Abitanti: "1296",
      },
      {
        Istat: "61038",
        Comune: "Gallo Matese",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "D884",
        Abitanti: "678",
      },
      {
        Istat: "61039",
        Comune: "Galluccio",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81044",
        CodFisco: "D886",
        Abitanti: "2275",
      },
      {
        Istat: "64036",
        Comune: "Gesualdo",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83040",
        CodFisco: "D998",
        Abitanti: "3692",
      },
      {
        Istat: "61040",
        Comune: "Giano Vetusto",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81042",
        CodFisco: "E011",
        Abitanti: "670",
      },
      {
        Istat: "65055",
        Comune: "Giffoni Sei Casali",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84090",
        CodFisco: "E026",
        Abitanti: "5322",
      },
      {
        Istat: "65056",
        Comune: "Giffoni Valle Piana",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84095",
        CodFisco: "E027",
        Abitanti: "12079",
      },
      {
        Istat: "62036",
        Comune: "Ginestra degli Schiavoni",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "E034",
        Abitanti: "526",
      },
      {
        Istat: "65057",
        Comune: "Gioi",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84056",
        CodFisco: "E037",
        Abitanti: "1366",
      },
      {
        Istat: "61041",
        Comune: "Gioia Sannitica",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "E039",
        Abitanti: "3575",
      },
      {
        Istat: "63034",
        Comune: "Giugliano in Campania",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80014",
        CodFisco: "E054",
        Abitanti: "117963",
      },
      {
        Istat: "65058",
        Comune: "Giungano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84050",
        CodFisco: "E060",
        Abitanti: "1257",
      },
      {
        Istat: "63035",
        Comune: "Gragnano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80054",
        CodFisco: "E131",
        Abitanti: "29719",
      },
      {
        Istat: "61042",
        Comune: "Grazzanise",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81046",
        CodFisco: "E158",
        Abitanti: "6812",
      },
      {
        Istat: "64037",
        Comune: "Greci",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "E161",
        Abitanti: "772",
      },
      {
        Istat: "61043",
        Comune: "Gricignano di Aversa",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "E173",
        Abitanti: "10421",
      },
      {
        Istat: "64038",
        Comune: "Grottaminarda",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83035",
        CodFisco: "E206",
        Abitanti: "8359",
      },
      {
        Istat: "64039",
        Comune: "Grottolella",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "E214",
        Abitanti: "2020",
      },
      {
        Istat: "63036",
        Comune: "Grumo Nevano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80028",
        CodFisco: "E224",
        Abitanti: "18269",
      },
      {
        Istat: "64040",
        Comune: "Guardia Lombardi",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "E245",
        Abitanti: "1830",
      },
      {
        Istat: "62037",
        Comune: "Guardia Sanframondi",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82034",
        CodFisco: "E249",
        Abitanti: "5283",
      },
      {
        Istat: "63037",
        Comune: "Ischia",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80077",
        CodFisco: "E329",
        Abitanti: "18828",
      },
      {
        Istat: "65059",
        Comune: "Ispani",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "973",
        CAP: "84050",
        CodFisco: "E365",
        Abitanti: "1017",
      },
      {
        Istat: "63038",
        Comune: "Lacco Ameno",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80076",
        CodFisco: "E396",
        Abitanti: "4783",
      },
      {
        Istat: "64041",
        Comune: "Lacedonia",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83046",
        CodFisco: "E397",
        Abitanti: "2825",
      },
      {
        Istat: "64042",
        Comune: "Lapio",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "E448",
        Abitanti: "1648",
      },
      {
        Istat: "65060",
        Comune: "Laureana Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84050",
        CodFisco: "E480",
        Abitanti: "1185",
      },
      {
        Istat: "65061",
        Comune: "Laurino",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84057",
        CodFisco: "E485",
        Abitanti: "1741",
      },
      {
        Istat: "65062",
        Comune: "Laurito",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84050",
        CodFisco: "E486",
        Abitanti: "870",
      },
      {
        Istat: "64043",
        Comune: "Lauro",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83023",
        CodFisco: "E487",
        Abitanti: "3638",
      },
      {
        Istat: "65063",
        Comune: "Laviano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "E498",
        Abitanti: "1496",
      },
      {
        Istat: "61044",
        Comune: "Letino",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "E554",
        Abitanti: "780",
      },
      {
        Istat: "63039",
        Comune: "Lettere",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80050",
        CodFisco: "E557",
        Abitanti: "6228",
      },
      {
        Istat: "61045",
        Comune: "Liberi",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81040",
        CodFisco: "E570",
        Abitanti: "1176",
      },
      {
        Istat: "62038",
        Comune: "Limatola",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82030",
        CodFisco: "E589",
        Abitanti: "3916",
      },
      {
        Istat: "64044",
        Comune: "Lioni",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83047",
        CodFisco: "E605",
        Abitanti: "6420",
      },
      {
        Istat: "63040",
        Comune: "Liveri",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "E620",
        Abitanti: "1669",
      },
      {
        Istat: "64045",
        Comune: "Luogosano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "E746",
        Abitanti: "1239",
      },
      {
        Istat: "61046",
        Comune: "Lusciano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "E754",
        Abitanti: "14553",
      },
      {
        Istat: "65064",
        Comune: "Lustra",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84050",
        CodFisco: "E767",
        Abitanti: "1110",
      },
      {
        Istat: "61047",
        Comune: "Macerata Campania",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81047",
        CodFisco: "E784",
        Abitanti: "10845",
      },
      {
        Istat: "61048",
        Comune: "Maddaloni",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81024",
        CodFisco: "E791",
        Abitanti: "38690",
      },
      {
        Istat: "65065",
        Comune: "Magliano Vetere",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84050",
        CodFisco: "E814",
        Abitanti: "778",
      },
      {
        Istat: "65066",
        Comune: "Maiori",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "E839",
        Abitanti: "5626",
      },
      {
        Istat: "64046",
        Comune: "Manocalzati",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "E891",
        Abitanti: "3280",
      },
      {
        Istat: "63041",
        Comune: "Marano di Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80016",
        CodFisco: "E906",
        Abitanti: "59472",
      },
      {
        Istat: "61049",
        Comune: "Marcianise",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81025",
        CodFisco: "E932",
        Abitanti: "40439",
      },
      {
        Istat: "63042",
        Comune: "Mariglianella",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "E954",
        Abitanti: "7468",
      },
      {
        Istat: "63043",
        Comune: "Marigliano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80034",
        CodFisco: "E955",
        Abitanti: "30370",
      },
      {
        Istat: "61050",
        Comune: "Marzano Appio",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81035",
        CodFisco: "E998",
        Abitanti: "2373",
      },
      {
        Istat: "64047",
        Comune: "Marzano di Nola",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "E997",
        Abitanti: "1722",
      },
      {
        Istat: "63092",
        Comune: "Massa di Somma",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "M289",
        Abitanti: "5751",
      },
      {
        Istat: "63044",
        Comune: "Massa Lubrense",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80061",
        CodFisco: "F030",
        Abitanti: "13985",
      },
      {
        Istat: "63045",
        Comune: "Melito di Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80017",
        CodFisco: "F111",
        Abitanti: "38163",
      },
      {
        Istat: "64048",
        Comune: "Melito Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "F110",
        Abitanti: "1968",
      },
      {
        Istat: "62039",
        Comune: "Melizzano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "F113",
        Abitanti: "1908",
      },
      {
        Istat: "65067",
        Comune: "Mercato San Severino",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84085",
        CodFisco: "F138",
        Abitanti: "21814",
      },
      {
        Istat: "64049",
        Comune: "Mercogliano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83013",
        CodFisco: "F141",
        Abitanti: "12471",
      },
      {
        Istat: "63046",
        Comune: "Meta",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80062",
        CodFisco: "F162",
        Abitanti: "8041",
      },
      {
        Istat: "61051",
        Comune: "Mignano Monte Lungo",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81049",
        CodFisco: "F203",
        Abitanti: "3290",
      },
      {
        Istat: "65068",
        Comune: "Minori",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "F223",
        Abitanti: "2836",
      },
      {
        Istat: "64050",
        Comune: "Mirabella Eclano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83036",
        CodFisco: "F230",
        Abitanti: "8042",
      },
      {
        Istat: "62040",
        Comune: "Moiano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82010",
        CodFisco: "F274",
        Abitanti: "4142",
      },
      {
        Istat: "65069",
        Comune: "Moio della Civitella",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "F278",
        Abitanti: "1927",
      },
      {
        Istat: "62041",
        Comune: "Molinara",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "F287",
        Abitanti: "1681",
      },
      {
        Istat: "61052",
        Comune: "Mondragone",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81034",
        CodFisco: "F352",
        Abitanti: "27405",
      },
      {
        Istat: "64051",
        Comune: "Montaguto",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "F397",
        Abitanti: "478",
      },
      {
        Istat: "65070",
        Comune: "Montano Antilia",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "F426",
        Abitanti: "2002",
      },
      {
        Istat: "63047",
        Comune: "Monte di Procida",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80070",
        CodFisco: "F488",
        Abitanti: "13341",
      },
      {
        Istat: "65075",
        Comune: "Monte San Giacomo",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "F618",
        Abitanti: "1662",
      },
      {
        Istat: "64052",
        Comune: "Montecalvo Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83037",
        CodFisco: "F448",
        Abitanti: "3940",
      },
      {
        Istat: "65071",
        Comune: "Montecorice",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "F479",
        Abitanti: "2589",
      },
      {
        Istat: "65072",
        Comune: "Montecorvino Pugliano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84090",
        CodFisco: "F480",
        Abitanti: "10190",
      },
      {
        Istat: "65073",
        Comune: "Montecorvino Rovella",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84096",
        CodFisco: "F481",
        Abitanti: "12633",
      },
      {
        Istat: "64053",
        Comune: "Montefalcione",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "F491",
        Abitanti: "3461",
      },
      {
        Istat: "62042",
        Comune: "Montefalcone di Val Fortore",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82025",
        CodFisco: "F494",
        Abitanti: "1661",
      },
      {
        Istat: "65074",
        Comune: "Monteforte Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "F507",
        Abitanti: "576",
      },
      {
        Istat: "64054",
        Comune: "Monteforte Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83024",
        CodFisco: "F506",
        Abitanti: "12646",
      },
      {
        Istat: "64055",
        Comune: "Montefredane",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "F511",
        Abitanti: "2310",
      },
      {
        Istat: "64056",
        Comune: "Montefusco",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "F512",
        Abitanti: "1432",
      },
      {
        Istat: "64057",
        Comune: "Montella",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83048",
        CodFisco: "F546",
        Abitanti: "8013",
      },
      {
        Istat: "64058",
        Comune: "Montemarano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "F559",
        Abitanti: "3090",
      },
      {
        Istat: "64059",
        Comune: "Montemiletto",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83038",
        CodFisco: "F566",
        Abitanti: "5464",
      },
      {
        Istat: "65076",
        Comune: "Montesano sulla Marcellana",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84033",
        CodFisco: "F625",
        Abitanti: "6683",
      },
      {
        Istat: "62043",
        Comune: "Montesarchio",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82016",
        CodFisco: "F636",
        Abitanti: "13707",
      },
      {
        Istat: "64060",
        Comune: "Monteverde",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83049",
        CodFisco: "F660",
        Abitanti: "842",
      },
      {
        Istat: "64061",
        Comune: "Montoro Inferiore",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83025",
        CodFisco: "F693",
        Abitanti: "10529",
      },
      {
        Istat: "64062",
        Comune: "Montoro Superiore",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83026",
        CodFisco: "F694",
        Abitanti: "8844",
      },
      {
        Istat: "62044",
        Comune: "Morcone",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82026",
        CodFisco: "F717",
        Abitanti: "5150",
      },
      {
        Istat: "65077",
        Comune: "Morigerati",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84030",
        CodFisco: "F731",
        Abitanti: "736",
      },
      {
        Istat: "64063",
        Comune: "Morra De Sanctis",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83040",
        CodFisco: "F744",
        Abitanti: "1328",
      },
      {
        Istat: "64064",
        Comune: "Moschiano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "F762",
        Abitanti: "1701",
      },
      {
        Istat: "64065",
        Comune: "Mugnano del Cardinale",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83027",
        CodFisco: "F798",
        Abitanti: "5376",
      },
      {
        Istat: "63048",
        Comune: "Mugnano di Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80018",
        CodFisco: "F799",
        Abitanti: "34582",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80121",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80122",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80123",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80124",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80125",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80126",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80127",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80128",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80129",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80130",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80131",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80132",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80133",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80134",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80135",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80136",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80137",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80138",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80139",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80140",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80141",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80142",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80143",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80144",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80145",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80146",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "63049",
        Comune: "Napoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80147",
        CodFisco: "F839",
        Abitanti: "959574",
      },
      {
        Istat: "65078",
        Comune: "Nocera Inferiore",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84014",
        CodFisco: "F912",
        Abitanti: "45707",
      },
      {
        Istat: "65079",
        Comune: "Nocera Superiore",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84015",
        CodFisco: "F913",
        Abitanti: "24255",
      },
      {
        Istat: "63050",
        Comune: "Nola",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80035",
        CodFisco: "F924",
        Abitanti: "32761",
      },
      {
        Istat: "65080",
        Comune: "Novi Velia",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "F967",
        Abitanti: "2263",
      },
      {
        Istat: "64066",
        Comune: "Nusco",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83051",
        CodFisco: "F988",
        Abitanti: "4295",
      },
      {
        Istat: "65081",
        Comune: "Ogliastro Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84061",
        CodFisco: "G011",
        Abitanti: "2274",
      },
      {
        Istat: "65082",
        Comune: "Olevano sul Tusciano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84062",
        CodFisco: "G023",
        Abitanti: "6993",
      },
      {
        Istat: "65083",
        Comune: "Oliveto Citra",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "G039",
        Abitanti: "3972",
      },
      {
        Istat: "65084",
        Comune: "Omignano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "G063",
        Abitanti: "1570",
      },
      {
        Istat: "65085",
        Comune: "Orria",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "G121",
        Abitanti: "1195",
      },
      {
        Istat: "61053",
        Comune: "Orta di Atella",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "G130",
        Abitanti: "24547",
      },
      {
        Istat: "64067",
        Comune: "Ospedaletto d'Alpinolo",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83014",
        CodFisco: "G165",
        Abitanti: "1934",
      },
      {
        Istat: "65086",
        Comune: "Ottati",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "G192",
        Abitanti: "761",
      },
      {
        Istat: "63051",
        Comune: "Ottaviano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80044",
        CodFisco: "G190",
        Abitanti: "24072",
      },
      {
        Istat: "65087",
        Comune: "Padula",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84034",
        CodFisco: "G226",
        Abitanti: "5523",
      },
      {
        Istat: "62045",
        Comune: "Paduli",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "G227",
        Abitanti: "4127",
      },
      {
        Istat: "65088",
        Comune: "Pagani",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84016",
        CodFisco: "G230",
        Abitanti: "35962",
      },
      {
        Istat: "64068",
        Comune: "Pago del Vallo di Lauro",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "G242",
        Abitanti: "1875",
      },
      {
        Istat: "62046",
        Comune: "Pago Veiano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "G243",
        Abitanti: "2567",
      },
      {
        Istat: "63052",
        Comune: "Palma Campania",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80036",
        CodFisco: "G283",
        Abitanti: "15187",
      },
      {
        Istat: "65089",
        Comune: "Palomonte",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "G292",
        Abitanti: "4151",
      },
      {
        Istat: "62047",
        Comune: "Pannarano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82017",
        CodFisco: "G311",
        Abitanti: "2045",
      },
      {
        Istat: "62048",
        Comune: "Paolisi",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82011",
        CodFisco: "G318",
        Abitanti: "2018",
      },
      {
        Istat: "61054",
        Comune: "Parete",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "G333",
        Abitanti: "11001",
      },
      {
        Istat: "64069",
        Comune: "Parolise",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83050",
        CodFisco: "G340",
        Abitanti: "706",
      },
      {
        Istat: "61055",
        Comune: "Pastorano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81050",
        CodFisco: "G364",
        Abitanti: "2941",
      },
      {
        Istat: "64070",
        Comune: "Paternopoli",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83052",
        CodFisco: "G370",
        Abitanti: "2603",
      },
      {
        Istat: "62049",
        Comune: "Paupisi",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "G386",
        Abitanti: "1515",
      },
      {
        Istat: "65090",
        Comune: "Pellezzano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84080",
        CodFisco: "G426",
        Abitanti: "10714",
      },
      {
        Istat: "65091",
        Comune: "Perdifumo",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "G447",
        Abitanti: "1784",
      },
      {
        Istat: "65092",
        Comune: "Perito",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "G455",
        Abitanti: "1022",
      },
      {
        Istat: "65093",
        Comune: "Pertosa",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "G476",
        Abitanti: "714",
      },
      {
        Istat: "62050",
        Comune: "Pesco Sannita",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "G494",
        Abitanti: "2081",
      },
      {
        Istat: "65094",
        Comune: "Petina",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "G509",
        Abitanti: "1213",
      },
      {
        Istat: "64071",
        Comune: "Petruro Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "G519",
        Abitanti: "359",
      },
      {
        Istat: "65095",
        Comune: "Piaggine",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84065",
        CodFisco: "G538",
        Abitanti: "1478",
      },
      {
        Istat: "61056",
        Comune: "Piana di Monte Verna",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81013",
        CodFisco: "G541",
        Abitanti: "2402",
      },
      {
        Istat: "63053",
        Comune: "Piano di Sorrento",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80063",
        CodFisco: "G568",
        Abitanti: "13136",
      },
      {
        Istat: "61057",
        Comune: "Piedimonte Matese",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81016",
        CodFisco: "G596",
        Abitanti: "11428",
      },
      {
        Istat: "64072",
        Comune: "Pietradefusi",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "G611",
        Abitanti: "2472",
      },
      {
        Istat: "61058",
        Comune: "Pietramelara",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81051",
        CodFisco: "G620",
        Abitanti: "4810",
      },
      {
        Istat: "62051",
        Comune: "Pietraroja",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "G626",
        Abitanti: "600",
      },
      {
        Istat: "64073",
        Comune: "Pietrastornina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83015",
        CodFisco: "G629",
        Abitanti: "1567",
      },
      {
        Istat: "61059",
        Comune: "Pietravairano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81040",
        CodFisco: "G630",
        Abitanti: "3090",
      },
      {
        Istat: "62052",
        Comune: "Pietrelcina",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "G631",
        Abitanti: "3083",
      },
      {
        Istat: "61060",
        Comune: "Pignataro Maggiore",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81052",
        CodFisco: "G661",
        Abitanti: "6281",
      },
      {
        Istat: "63054",
        Comune: "Pimonte",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80050",
        CodFisco: "G670",
        Abitanti: "6033",
      },
      {
        Istat: "65096",
        Comune: "Pisciotta",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84066",
        CodFisco: "G707",
        Abitanti: "2880",
      },
      {
        Istat: "63055",
        Comune: "Poggiomarino",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "G762",
        Abitanti: "21353",
      },
      {
        Istat: "65097",
        Comune: "Polla",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84035",
        CodFisco: "G793",
        Abitanti: "5316",
      },
      {
        Istat: "63056",
        Comune: "Pollena Trocchia",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "G795",
        Abitanti: "13646",
      },
      {
        Istat: "65098",
        Comune: "Pollica",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84068",
        CodFisco: "G796",
        Abitanti: "2460",
      },
      {
        Istat: "63057",
        Comune: "Pomigliano d'Arco",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80038",
        CodFisco: "G812",
        Abitanti: "39215",
      },
      {
        Istat: "63058",
        Comune: "Pompei",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80045",
        CodFisco: "G813",
        Abitanti: "25620",
      },
      {
        Istat: "62053",
        Comune: "Ponte",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "G827",
        Abitanti: "2695",
      },
      {
        Istat: "65099",
        Comune: "Pontecagnano Faiano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84098",
        CodFisco: "G834",
        Abitanti: "25307",
      },
      {
        Istat: "62054",
        Comune: "Pontelandolfo",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82027",
        CodFisco: "G848",
        Abitanti: "2352",
      },
      {
        Istat: "61061",
        Comune: "Pontelatone",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81040",
        CodFisco: "G849",
        Abitanti: "1819",
      },
      {
        Istat: "63059",
        Comune: "Portici",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80055",
        CodFisco: "G902",
        Abitanti: "53981",
      },
      {
        Istat: "61062",
        Comune: "Portico di Caserta",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81050",
        CodFisco: "G903",
        Abitanti: "7737",
      },
      {
        Istat: "65100",
        Comune: "Positano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84017",
        CodFisco: "G932",
        Abitanti: "3983",
      },
      {
        Istat: "65101",
        Comune: "Postiglione",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84026",
        CodFisco: "G939",
        Abitanti: "2289",
      },
      {
        Istat: "63060",
        Comune: "Pozzuoli",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80078",
        CodFisco: "G964",
        Abitanti: "83459",
      },
      {
        Istat: "65102",
        Comune: "Praiano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "G976",
        Abitanti: "2081",
      },
      {
        Istat: "64074",
        Comune: "Prata di Principato Ultra",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "G990",
        Abitanti: "2975",
      },
      {
        Istat: "61063",
        Comune: "Prata Sannita",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "G991",
        Abitanti: "1605",
      },
      {
        Istat: "61064",
        Comune: "Pratella",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "G995",
        Abitanti: "1635",
      },
      {
        Istat: "64075",
        Comune: "Pratola Serra",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83039",
        CodFisco: "H006",
        Abitanti: "3700",
      },
      {
        Istat: "61065",
        Comune: "Presenzano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81050",
        CodFisco: "H045",
        Abitanti: "1773",
      },
      {
        Istat: "65103",
        Comune: "Prignano Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "H062",
        Abitanti: "975",
      },
      {
        Istat: "63061",
        Comune: "Procida",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80079",
        CodFisco: "H072",
        Abitanti: "10596",
      },
      {
        Istat: "62055",
        Comune: "Puglianello",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "H087",
        Abitanti: "1400",
      },
      {
        Istat: "64076",
        Comune: "Quadrelle",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "H097",
        Abitanti: "1957",
      },
      {
        Istat: "63062",
        Comune: "Qualiano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80019",
        CodFisco: "H101",
        Abitanti: "25313",
      },
      {
        Istat: "63063",
        Comune: "Quarto",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80010",
        CodFisco: "H114",
        Abitanti: "40154",
      },
      {
        Istat: "64077",
        Comune: "Quindici",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "H128",
        Abitanti: "2447",
      },
      {
        Istat: "65104",
        Comune: "Ravello",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "H198",
        Abitanti: "2508",
      },
      {
        Istat: "61066",
        Comune: "Raviscanina",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81017",
        CodFisco: "H202",
        Abitanti: "1383",
      },
      {
        Istat: "61067",
        Comune: "Recale",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81020",
        CodFisco: "H210",
        Abitanti: "7557",
      },
      {
        Istat: "62056",
        Comune: "Reino",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "H227",
        Abitanti: "1281",
      },
      {
        Istat: "61068",
        Comune: "Riardo",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81053",
        CodFisco: "H268",
        Abitanti: "2442",
      },
      {
        Istat: "65105",
        Comune: "Ricigliano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "H277",
        Abitanti: "1241",
      },
      {
        Istat: "61069",
        Comune: "Rocca D'Evandro",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81040",
        CodFisco: "H398",
        Abitanti: "3437",
      },
      {
        Istat: "64079",
        Comune: "Rocca San Felice",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "H438",
        Abitanti: "889",
      },
      {
        Istat: "64078",
        Comune: "Roccabascerana",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83016",
        CodFisco: "H382",
        Abitanti: "2358",
      },
      {
        Istat: "65106",
        Comune: "Roccadaspide",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84069",
        CodFisco: "H394",
        Abitanti: "7462",
      },
      {
        Istat: "65107",
        Comune: "Roccagloriosa",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84060",
        CodFisco: "H412",
        Abitanti: "1650",
      },
      {
        Istat: "61070",
        Comune: "Roccamonfina",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81035",
        CodFisco: "H423",
        Abitanti: "3688",
      },
      {
        Istat: "65108",
        Comune: "Roccapiemonte",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84086",
        CodFisco: "H431",
        Abitanti: "9068",
      },
      {
        Istat: "63065",
        Comune: "Roccarainola",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "H433",
        Abitanti: "7294",
      },
      {
        Istat: "61071",
        Comune: "Roccaromana",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81051",
        CodFisco: "H436",
        Abitanti: "997",
      },
      {
        Istat: "61072",
        Comune: "Rocchetta e Croce",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81042",
        CodFisco: "H459",
        Abitanti: "486",
      },
      {
        Istat: "65109",
        Comune: "Rofrano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "H485",
        Abitanti: "1710",
      },
      {
        Istat: "65110",
        Comune: "Romagnano al Monte",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "H503",
        Abitanti: "399",
      },
      {
        Istat: "65111",
        Comune: "Roscigno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "H564",
        Abitanti: "860",
      },
      {
        Istat: "64080",
        Comune: "Rotondi",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "83017",
        CodFisco: "H592",
        Abitanti: "3647",
      },
      {
        Istat: "65112",
        Comune: "Rutino",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "H644",
        Abitanti: "895",
      },
      {
        Istat: "61073",
        Comune: "Ruviano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "H165",
        Abitanti: "1839",
      },
      {
        Istat: "65113",
        Comune: "Sacco",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "H654",
        Abitanti: "582",
      },
      {
        Istat: "65114",
        Comune: "Sala Consilina",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84036",
        CodFisco: "H683",
        Abitanti: "12716",
      },
      {
        Istat: "65115",
        Comune: "Salento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "H686",
        Abitanti: "2046",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84121",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84122",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84123",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84124",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84125",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84126",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84127",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84128",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84129",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84130",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84131",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84132",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84133",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84134",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65116",
        Comune: "Salerno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84135",
        CodFisco: "H703",
        Abitanti: "139019",
      },
      {
        Istat: "65117",
        Comune: "Salvitelle",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84020",
        CodFisco: "H732",
        Abitanti: "600",
      },
      {
        Istat: "64081",
        Comune: "Salza Irpina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83050",
        CodFisco: "H733",
        Abitanti: "788",
      },
      {
        Istat: "62057",
        Comune: "San Bartolomeo in Galdo",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82028",
        CodFisco: "H764",
        Abitanti: "5204",
      },
      {
        Istat: "61074",
        Comune: "San Cipriano d'Aversa",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81036",
        CodFisco: "H798",
        Abitanti: "13085",
      },
      {
        Istat: "65118",
        Comune: "San Cipriano Picentino",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84099",
        CodFisco: "H800",
        Abitanti: "6721",
      },
      {
        Istat: "61075",
        Comune: "San Felice a Cancello",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81027",
        CodFisco: "H834",
        Abitanti: "17675",
      },
      {
        Istat: "63066",
        Comune: "San Gennaro Vesuviano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "H860",
        Abitanti: "11235",
      },
      {
        Istat: "63067",
        Comune: "San Giorgio a Cremano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80046",
        CodFisco: "H892",
        Abitanti: "47244",
      },
      {
        Istat: "62058",
        Comune: "San Giorgio del Sannio",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82018",
        CodFisco: "H894",
        Abitanti: "9902",
      },
      {
        Istat: "62059",
        Comune: "San Giorgio La Molara",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "H898",
        Abitanti: "3069",
      },
      {
        Istat: "65119",
        Comune: "San Giovanni a Piro",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "H907",
        Abitanti: "3868",
      },
      {
        Istat: "63068",
        Comune: "San Giuseppe Vesuviano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80047",
        CodFisco: "H931",
        Abitanti: "28084",
      },
      {
        Istat: "65120",
        Comune: "San Gregorio Magno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "H943",
        Abitanti: "4457",
      },
      {
        Istat: "61076",
        Comune: "San Gregorio Matese",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "H939",
        Abitanti: "986",
      },
      {
        Istat: "62060",
        Comune: "San Leucio del Sannio",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82010",
        CodFisco: "H953",
        Abitanti: "3207",
      },
      {
        Istat: "62061",
        Comune: "San Lorenzello",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "H955",
        Abitanti: "2364",
      },
      {
        Istat: "62062",
        Comune: "San Lorenzo Maggiore",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82034",
        CodFisco: "H967",
        Abitanti: "2213",
      },
      {
        Istat: "62063",
        Comune: "San Lupo",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82034",
        CodFisco: "H973",
        Abitanti: "886",
      },
      {
        Istat: "65121",
        Comune: "San Mango Piemonte",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84090",
        CodFisco: "H977",
        Abitanti: "2644",
      },
      {
        Istat: "64082",
        Comune: "San Mango sul Calore",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "H975",
        Abitanti: "1210",
      },
      {
        Istat: "61077",
        Comune: "San Marcellino",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "H978",
        Abitanti: "13308",
      },
      {
        Istat: "62064",
        Comune: "San Marco dei Cavoti",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82029",
        CodFisco: "H984",
        Abitanti: "3596",
      },
      {
        Istat: "61104",
        Comune: "San Marco Evangelista",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81020",
        CodFisco: "F043",
        Abitanti: "6483",
      },
      {
        Istat: "62065",
        Comune: "San Martino Sannita",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82010",
        CodFisco: "I002",
        Abitanti: "1287",
      },
      {
        Istat: "64083",
        Comune: "San Martino Valle Caudina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "83018",
        CodFisco: "I016",
        Abitanti: "4730",
      },
      {
        Istat: "65122",
        Comune: "San Marzano sul Sarno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84010",
        CodFisco: "I019",
        Abitanti: "10205",
      },
      {
        Istat: "65123",
        Comune: "San Mauro Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "I031",
        Abitanti: "982",
      },
      {
        Istat: "65124",
        Comune: "San Mauro La Bruca",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "I032",
        Abitanti: "676",
      },
      {
        Istat: "64084",
        Comune: "San Michele di Serino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83020",
        CodFisco: "I034",
        Abitanti: "2602",
      },
      {
        Istat: "62066",
        Comune: "San Nazzaro",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82018",
        CodFisco: "I049",
        Abitanti: "934",
      },
      {
        Istat: "64085",
        Comune: "San Nicola Baronia",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "I061",
        Abitanti: "793",
      },
      {
        Istat: "61078",
        Comune: "San Nicola la Strada",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81020",
        CodFisco: "I056",
        Abitanti: "21746",
      },
      {
        Istat: "62067",
        Comune: "San Nicola Manfredi",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82010",
        CodFisco: "I062",
        Abitanti: "3642",
      },
      {
        Istat: "63069",
        Comune: "San Paolo Bel Sito",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "I073",
        Abitanti: "3546",
      },
      {
        Istat: "65125",
        Comune: "San Pietro al Tanagro",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "I089",
        Abitanti: "1733",
      },
      {
        Istat: "61079",
        Comune: "San Pietro Infine",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81049",
        CodFisco: "I113",
        Abitanti: "988",
      },
      {
        Istat: "61080",
        Comune: "San Potito Sannitico",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81016",
        CodFisco: "I130",
        Abitanti: "2011",
      },
      {
        Istat: "64086",
        Comune: "San Potito Ultra",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83050",
        CodFisco: "I129",
        Abitanti: "1603",
      },
      {
        Istat: "61081",
        Comune: "San Prisco",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81054",
        CodFisco: "I131",
        Abitanti: "12196",
      },
      {
        Istat: "65126",
        Comune: "San Rufo",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "I143",
        Abitanti: "1760",
      },
      {
        Istat: "62068",
        Comune: "San Salvatore Telesino",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "I145",
        Abitanti: "4080",
      },
      {
        Istat: "63070",
        Comune: "San Sebastiano al Vesuvio",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "I151",
        Abitanti: "9561",
      },
      {
        Istat: "64087",
        Comune: "San Sossio Baronia",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "I163",
        Abitanti: "1738",
      },
      {
        Istat: "61085",
        Comune: "San Tammaro",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81050",
        CodFisco: "I261",
        Abitanti: "5115",
      },
      {
        Istat: "65132",
        Comune: "San Valentino Torio",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84010",
        CodFisco: "I377",
        Abitanti: "10313",
      },
      {
        Istat: "63075",
        Comune: "San Vitaliano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "I391",
        Abitanti: "6189",
      },
      {
        Istat: "62069",
        Comune: "Santa Croce del Sannio",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82020",
        CodFisco: "I179",
        Abitanti: "991",
      },
      {
        Istat: "64088",
        Comune: "Santa Lucia di Serino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83020",
        CodFisco: "I219",
        Abitanti: "1460",
      },
      {
        Istat: "61082",
        Comune: "Santa Maria a Vico",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81028",
        CodFisco: "I233",
        Abitanti: "14210",
      },
      {
        Istat: "61083",
        Comune: "Santa Maria Capua Vetere",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81055",
        CodFisco: "I234",
        Abitanti: "33742",
      },
      {
        Istat: "63090",
        Comune: "Santa Maria la Carità",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80050",
        CodFisco: "M273",
        Abitanti: "11718",
      },
      {
        Istat: "61084",
        Comune: "Santa Maria La Fossa",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81050",
        CodFisco: "I247",
        Abitanti: "2745",
      },
      {
        Istat: "65127",
        Comune: "Santa Marina",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "I253",
        Abitanti: "3222",
      },
      {
        Istat: "64093",
        Comune: "Santa Paolina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "I301",
        Abitanti: "1440",
      },
      {
        Istat: "62070",
        Comune: "Sant'Agata De' Goti",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "82019",
        CodFisco: "I197",
        Abitanti: "11473",
      },
      {
        Istat: "63071",
        Comune: "Sant'Agnello",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80065",
        CodFisco: "I208",
        Abitanti: "9079",
      },
      {
        Istat: "63072",
        Comune: "Sant'Anastasia",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80048",
        CodFisco: "I262",
        Abitanti: "28827",
      },
      {
        Istat: "64089",
        Comune: "Sant'Andrea di Conza",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83053",
        CodFisco: "I264",
        Abitanti: "1696",
      },
      {
        Istat: "62071",
        Comune: "Sant'Angelo a Cupolo",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82010",
        CodFisco: "I277",
        Abitanti: "4314",
      },
      {
        Istat: "65128",
        Comune: "Sant'Angelo a Fasanella",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84027",
        CodFisco: "I278",
        Abitanti: "730",
      },
      {
        Istat: "64091",
        Comune: "Sant'Angelo a Scala",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "I280",
        Abitanti: "736",
      },
      {
        Istat: "64090",
        Comune: "Sant'Angelo all'Esca",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "I279",
        Abitanti: "852",
      },
      {
        Istat: "61086",
        Comune: "Sant'Angelo d'Alife",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81017",
        CodFisco: "I273",
        Abitanti: "2320",
      },
      {
        Istat: "64092",
        Comune: "Sant'Angelo dei Lombardi",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83054",
        CodFisco: "I281",
        Abitanti: "4431",
      },
      {
        Istat: "63073",
        Comune: "Sant'Antimo",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80029",
        CodFisco: "I293",
        Abitanti: "31081",
      },
      {
        Istat: "63074",
        Comune: "Sant'Antonio Abate",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80057",
        CodFisco: "I300",
        Abitanti: "19693",
      },
      {
        Istat: "62078",
        Comune: "Sant'Arcangelo Trimonte",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82021",
        CodFisco: "F557",
        Abitanti: "643",
      },
      {
        Istat: "61087",
        Comune: "Sant'Arpino",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "I306",
        Abitanti: "14267",
      },
      {
        Istat: "65129",
        Comune: "Sant'Arsenio",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84037",
        CodFisco: "I307",
        Abitanti: "2807",
      },
      {
        Istat: "65130",
        Comune: "Sant'Egidio del Monte Albino",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84010",
        CodFisco: "I317",
        Abitanti: "8942",
      },
      {
        Istat: "64095",
        Comune: "Santo Stefano del Sole",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83050",
        CodFisco: "I357",
        Abitanti: "2218",
      },
      {
        Istat: "65131",
        Comune: "Santomenna",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "I260",
        Abitanti: "482",
      },
      {
        Istat: "65133",
        Comune: "Sanza",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84030",
        CodFisco: "I410",
        Abitanti: "2754",
      },
      {
        Istat: "65134",
        Comune: "Sapri",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "973",
        CAP: "84073",
        CodFisco: "I422",
        Abitanti: "7038",
      },
      {
        Istat: "65135",
        Comune: "Sarno",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84087",
        CodFisco: "I438",
        Abitanti: "31423",
      },
      {
        Istat: "65136",
        Comune: "Sassano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84038",
        CodFisco: "I451",
        Abitanti: "5119",
      },
      {
        Istat: "62072",
        Comune: "Sassinoro",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82026",
        CodFisco: "I455",
        Abitanti: "595",
      },
      {
        Istat: "63076",
        Comune: "Saviano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80039",
        CodFisco: "I469",
        Abitanti: "15410",
      },
      {
        Istat: "64096",
        Comune: "Savignano Irpino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "I471",
        Abitanti: "1204",
      },
      {
        Istat: "65137",
        Comune: "Scafati",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84018",
        CodFisco: "I483",
        Abitanti: "50794",
      },
      {
        Istat: "65138",
        Comune: "Scala",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "I486",
        Abitanti: "1541",
      },
      {
        Istat: "64097",
        Comune: "Scampitella",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "I493",
        Abitanti: "1288",
      },
      {
        Istat: "63077",
        Comune: "Scisciano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "I540",
        Abitanti: "5769",
      },
      {
        Istat: "64098",
        Comune: "Senerchia",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "I606",
        Abitanti: "845",
      },
      {
        Istat: "64099",
        Comune: "Serino",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83028",
        CodFisco: "I630",
        Abitanti: "7254",
      },
      {
        Istat: "65139",
        Comune: "Serramezzana",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "I648",
        Abitanti: "355",
      },
      {
        Istat: "63078",
        Comune: "Serrara Fontana",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80070",
        CodFisco: "I652",
        Abitanti: "3205",
      },
      {
        Istat: "65140",
        Comune: "Serre",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84028",
        CodFisco: "I666",
        Abitanti: "4045",
      },
      {
        Istat: "61088",
        Comune: "Sessa Aurunca",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81037",
        CodFisco: "I676",
        Abitanti: "22603",
      },
      {
        Istat: "65141",
        Comune: "Sessa Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84074",
        CodFisco: "I677",
        Abitanti: "1381",
      },
      {
        Istat: "65142",
        Comune: "Siano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "84088",
        CodFisco: "I720",
        Abitanti: "10324",
      },
      {
        Istat: "65143",
        Comune: "Sicignano degli Alburni",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84029",
        CodFisco: "M253",
        Abitanti: "3311",
      },
      {
        Istat: "64100",
        Comune: "Sirignano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "I756",
        Abitanti: "3028",
      },
      {
        Istat: "64101",
        Comune: "Solofra",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83029",
        CodFisco: "I805",
        Abitanti: "12313",
      },
      {
        Istat: "62073",
        Comune: "Solopaca",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82036",
        CodFisco: "I809",
        Abitanti: "4051",
      },
      {
        Istat: "63079",
        Comune: "Somma Vesuviana",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80049",
        CodFisco: "I820",
        Abitanti: "35260",
      },
      {
        Istat: "64102",
        Comune: "Sorbo Serpico",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83050",
        CodFisco: "I843",
        Abitanti: "595",
      },
      {
        Istat: "63080",
        Comune: "Sorrento",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80067",
        CodFisco: "I862",
        Abitanti: "16589",
      },
      {
        Istat: "61089",
        Comune: "Sparanise",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81056",
        CodFisco: "I885",
        Abitanti: "7447",
      },
      {
        Istat: "64103",
        Comune: "Sperone",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "I893",
        Abitanti: "3726",
      },
      {
        Istat: "65144",
        Comune: "Stella Cilento",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "G887",
        Abitanti: "785",
      },
      {
        Istat: "65145",
        Comune: "Stio",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84075",
        CodFisco: "I960",
        Abitanti: "972",
      },
      {
        Istat: "63081",
        Comune: "Striano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "I978",
        Abitanti: "8323",
      },
      {
        Istat: "64104",
        Comune: "Sturno",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83055",
        CodFisco: "I990",
        Abitanti: "3171",
      },
      {
        Istat: "61090",
        Comune: "Succivo",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "I993",
        Abitanti: "8040",
      },
      {
        Istat: "64105",
        Comune: "Summonte",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "L004",
        Abitanti: "1662",
      },
      {
        Istat: "64106",
        Comune: "Taurano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "83020",
        CodFisco: "L061",
        Abitanti: "1615",
      },
      {
        Istat: "64107",
        Comune: "Taurasi",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83030",
        CodFisco: "L062",
        Abitanti: "2519",
      },
      {
        Istat: "61091",
        Comune: "Teano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81057",
        CodFisco: "L083",
        Abitanti: "12536",
      },
      {
        Istat: "65146",
        Comune: "Teggiano",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "975",
        CAP: "84039",
        CodFisco: "D292",
        Abitanti: "8232",
      },
      {
        Istat: "62074",
        Comune: "Telese Terme",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82037",
        CodFisco: "L086",
        Abitanti: "7028",
      },
      {
        Istat: "64108",
        Comune: "Teora",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83056",
        CodFisco: "L102",
        Abitanti: "1579",
      },
      {
        Istat: "63082",
        Comune: "Terzigno",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "L142",
        Abitanti: "17866",
      },
      {
        Istat: "61092",
        Comune: "Teverola",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "L155",
        Abitanti: "13707",
      },
      {
        Istat: "62075",
        Comune: "Tocco Caudio",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "L185",
        Abitanti: "1570",
      },
      {
        Istat: "61093",
        Comune: "Tora e Piccilli",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81044",
        CodFisco: "L205",
        Abitanti: "970",
      },
      {
        Istat: "65147",
        Comune: "Torchiara",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84076",
        CodFisco: "L212",
        Abitanti: "1808",
      },
      {
        Istat: "64109",
        Comune: "Torella dei Lombardi",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83057",
        CodFisco: "L214",
        Abitanti: "2235",
      },
      {
        Istat: "65148",
        Comune: "Torraca",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "973",
        CAP: "84030",
        CodFisco: "L233",
        Abitanti: "1304",
      },
      {
        Istat: "63083",
        Comune: "Torre Annunziata",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80058",
        CodFisco: "L245",
        Abitanti: "43699",
      },
      {
        Istat: "63084",
        Comune: "Torre del Greco",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80059",
        CodFisco: "L259",
        Abitanti: "87197",
      },
      {
        Istat: "64110",
        Comune: "Torre Le Nocelle",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "L272",
        Abitanti: "1376",
      },
      {
        Istat: "65149",
        Comune: "Torre Orsaia",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84077",
        CodFisco: "L274",
        Abitanti: "2259",
      },
      {
        Istat: "62076",
        Comune: "Torrecuso",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82030",
        CodFisco: "L254",
        Abitanti: "3496",
      },
      {
        Istat: "64111",
        Comune: "Torrioni",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "L301",
        Abitanti: "586",
      },
      {
        Istat: "65150",
        Comune: "Tortorella",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "973",
        CAP: "84030",
        CodFisco: "L306",
        Abitanti: "572",
      },
      {
        Istat: "65151",
        Comune: "Tramonti",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84010",
        CodFisco: "L323",
        Abitanti: "4145",
      },
      {
        Istat: "63091",
        Comune: "Trecase",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "M280",
        Abitanti: "9311",
      },
      {
        Istat: "65152",
        Comune: "Trentinara",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84070",
        CodFisco: "L377",
        Abitanti: "1724",
      },
      {
        Istat: "61094",
        Comune: "Trentola-Ducenta",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81038",
        CodFisco: "L379",
        Abitanti: "17794",
      },
      {
        Istat: "64112",
        Comune: "Trevico",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83058",
        CodFisco: "L399",
        Abitanti: "1085",
      },
      {
        Istat: "63085",
        Comune: "Tufino",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "L460",
        Abitanti: "3745",
      },
      {
        Istat: "64113",
        Comune: "Tufo",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83010",
        CodFisco: "L461",
        Abitanti: "941",
      },
      {
        Istat: "61095",
        Comune: "Vairano Patenora",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81058",
        CodFisco: "L540",
        Abitanti: "6470",
      },
      {
        Istat: "64114",
        Comune: "Vallata",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83059",
        CodFisco: "L589",
        Abitanti: "2874",
      },
      {
        Istat: "61096",
        Comune: "Valle Agricola",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81010",
        CodFisco: "L594",
        Abitanti: "989",
      },
      {
        Istat: "65153",
        Comune: "Valle dell'Angelo",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84070",
        CodFisco: "G540",
        Abitanti: "314",
      },
      {
        Istat: "61097",
        Comune: "Valle di Maddaloni",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81020",
        CodFisco: "L591",
        Abitanti: "2796",
      },
      {
        Istat: "64115",
        Comune: "Vallesaccarda",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "827",
        CAP: "83050",
        CodFisco: "L616",
        Abitanti: "1357",
      },
      {
        Istat: "65154",
        Comune: "Vallo della Lucania",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "974",
        CAP: "84078",
        CodFisco: "L628",
        Abitanti: "8865",
      },
      {
        Istat: "65155",
        Comune: "Valva",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "828",
        CAP: "84020",
        CodFisco: "L656",
        Abitanti: "1760",
      },
      {
        Istat: "64116",
        Comune: "Venticano",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "L739",
        Abitanti: "2599",
      },
      {
        Istat: "65156",
        Comune: "Vibonati",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "973",
        CAP: "84079",
        CodFisco: "L835",
        Abitanti: "3278",
      },
      {
        Istat: "63086",
        Comune: "Vico Equense",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80069",
        CodFisco: "L845",
        Abitanti: "20980",
      },
      {
        Istat: "65157",
        Comune: "Vietri sul Mare",
        Provincia: "SA",
        Regione: "CAM",
        Prefisso: "89",
        CAP: "84019",
        CodFisco: "L860",
        Abitanti: "8293",
      },
      {
        Istat: "61098",
        Comune: "Villa di Briano",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81030",
        CodFisco: "D801",
        Abitanti: "6522",
      },
      {
        Istat: "61099",
        Comune: "Villa Literno",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "81039",
        CodFisco: "L844",
        Abitanti: "11676",
      },
      {
        Istat: "64117",
        Comune: "Villamaina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83050",
        CodFisco: "L965",
        Abitanti: "970",
      },
      {
        Istat: "64118",
        Comune: "Villanova del Battista",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "L973",
        Abitanti: "1786",
      },
      {
        Istat: "63087",
        Comune: "Villaricca",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80010",
        CodFisco: "G309",
        Abitanti: "30628",
      },
      {
        Istat: "63088",
        Comune: "Visciano",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80030",
        CodFisco: "M072",
        Abitanti: "4559",
      },
      {
        Istat: "62077",
        Comune: "Vitulano",
        Provincia: "BN",
        Regione: "CAM",
        Prefisso: "824",
        CAP: "82038",
        CodFisco: "M093",
        Abitanti: "3006",
      },
      {
        Istat: "61100",
        Comune: "Vitulazio",
        Provincia: "CE",
        Regione: "CAM",
        Prefisso: "823",
        CAP: "81041",
        CodFisco: "M092",
        Abitanti: "6984",
      },
      {
        Istat: "63089",
        Comune: "Volla",
        Provincia: "NA",
        Regione: "CAM",
        Prefisso: "81",
        CAP: "80040",
        CodFisco: "M115",
        Abitanti: "23312",
      },
      {
        Istat: "64119",
        Comune: "Volturara Irpina",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83050",
        CodFisco: "M130",
        Abitanti: "4103",
      },
      {
        Istat: "64120",
        Comune: "Zungoli",
        Provincia: "AV",
        Regione: "CAM",
        Prefisso: "825",
        CAP: "83030",
        CodFisco: "M203",
        Abitanti: "1232",
      },
      {
        Istat: "33001",
        Comune: "Agazzano",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "A067",
        Abitanti: "2107",
      },
      {
        Istat: "34001",
        Comune: "Albareto",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43051",
        CodFisco: "A138",
        Abitanti: "2232",
      },
      {
        Istat: "35001",
        Comune: "Albinea",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42020",
        CodFisco: "A162",
        Abitanti: "8766",
      },
      {
        Istat: "39001",
        Comune: "Alfonsine",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48011",
        CodFisco: "A191",
        Abitanti: "12411",
      },
      {
        Istat: "33002",
        Comune: "Alseno",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "A223",
        Abitanti: "4897",
      },
      {
        Istat: "37001",
        Comune: "Anzola dell'Emilia",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40011",
        CodFisco: "A324",
        Abitanti: "11961",
      },
      {
        Istat: "37002",
        Comune: "Argelato",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40050",
        CodFisco: "A392",
        Abitanti: "9744",
      },
      {
        Istat: "38001",
        Comune: "Argenta",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44011",
        CodFisco: "A393",
        Abitanti: "22575",
      },
      {
        Istat: "39002",
        Comune: "Bagnacavallo",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48012",
        CodFisco: "A547",
        Abitanti: "16665",
      },
      {
        Istat: "39003",
        Comune: "Bagnara di Romagna",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48010",
        CodFisco: "A551",
        Abitanti: "2330",
      },
      {
        Istat: "40001",
        Comune: "Bagno di Romagna",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47021",
        CodFisco: "A565",
        Abitanti: "6212",
      },
      {
        Istat: "35002",
        Comune: "Bagnolo in Piano",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42011",
        CodFisco: "A573",
        Abitanti: "9536",
      },
      {
        Istat: "35003",
        Comune: "Baiso",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42031",
        CodFisco: "A586",
        Abitanti: "3440",
      },
      {
        Istat: "34002",
        Comune: "Bardi",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43032",
        CodFisco: "A646",
        Abitanti: "2382",
      },
      {
        Istat: "37003",
        Comune: "Baricella",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40052",
        CodFisco: "A665",
        Abitanti: "6739",
      },
      {
        Istat: "36001",
        Comune: "Bastiglia",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41030",
        CodFisco: "A713",
        Abitanti: "4137",
      },
      {
        Istat: "37004",
        Comune: "Bazzano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40053",
        CodFisco: "A726",
        Abitanti: "6896",
      },
      {
        Istat: "34003",
        Comune: "Bedonia",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43041",
        CodFisco: "A731",
        Abitanti: "3701",
      },
      {
        Istat: "99001",
        Comune: "Bellaria-Igea Marina",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47814",
        CodFisco: "A747",
        Abitanti: "19358",
      },
      {
        Istat: "37005",
        Comune: "Bentivoglio",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40010",
        CodFisco: "A785",
        Abitanti: "5282",
      },
      {
        Istat: "34004",
        Comune: "Berceto",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43042",
        CodFisco: "A788",
        Abitanti: "2189",
      },
      {
        Istat: "38002",
        Comune: "Berra",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44033",
        CodFisco: "A806",
        Abitanti: "5308",
      },
      {
        Istat: "40003",
        Comune: "Bertinoro",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47032",
        CodFisco: "A809",
        Abitanti: "11029",
      },
      {
        Istat: "33003",
        Comune: "Besenzone",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "A823",
        Abitanti: "989",
      },
      {
        Istat: "33004",
        Comune: "Bettola",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29021",
        CodFisco: "A831",
        Abitanti: "3024",
      },
      {
        Istat: "35004",
        Comune: "Bibbiano",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42021",
        CodFisco: "A850",
        Abitanti: "10021",
      },
      {
        Istat: "33005",
        Comune: "Bobbio",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29022",
        CodFisco: "A909",
        Abitanti: "3737",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40121",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40122",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40123",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40124",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40125",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40126",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40127",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40128",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40129",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40130",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40131",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40132",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40133",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40134",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40135",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40136",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40137",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40138",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40139",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40140",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "37006",
        Comune: "Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40141",
        CodFisco: "A944",
        Abitanti: "380181",
      },
      {
        Istat: "36002",
        Comune: "Bomporto",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41030",
        CodFisco: "A959",
        Abitanti: "9748",
      },
      {
        Istat: "38003",
        Comune: "Bondeno",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44012",
        CodFisco: "A965",
        Abitanti: "15401",
      },
      {
        Istat: "34005",
        Comune: "Bore",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43030",
        CodFisco: "A987",
        Abitanti: "800",
      },
      {
        Istat: "35005",
        Comune: "Boretto",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42022",
        CodFisco: "A988",
        Abitanti: "5335",
      },
      {
        Istat: "40004",
        Comune: "Borghi",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47030",
        CodFisco: "B001",
        Abitanti: "2763",
      },
      {
        Istat: "37007",
        Comune: "Borgo Tossignano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40021",
        CodFisco: "B044",
        Abitanti: "3323",
      },
      {
        Istat: "34006",
        Comune: "Borgo Val di Taro",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43043",
        CodFisco: "B042",
        Abitanti: "7319",
      },
      {
        Istat: "33006",
        Comune: "Borgonovo Val Tidone",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29011",
        CodFisco: "B025",
        Abitanti: "7713",
      },
      {
        Istat: "35006",
        Comune: "Brescello",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42041",
        CodFisco: "B156",
        Abitanti: "5604",
      },
      {
        Istat: "39004",
        Comune: "Brisighella",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "48013",
        CodFisco: "B188",
        Abitanti: "7847",
      },
      {
        Istat: "37008",
        Comune: "Budrio",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40054",
        CodFisco: "B249",
        Abitanti: "17994",
      },
      {
        Istat: "35007",
        Comune: "Busana",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42032",
        CodFisco: "B283",
        Abitanti: "1319",
      },
      {
        Istat: "34007",
        Comune: "Busseto",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "524",
        CAP: "43011",
        CodFisco: "B293",
        Abitanti: "7052",
      },
      {
        Istat: "35008",
        Comune: "Cadelbosco di Sopra",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42023",
        CodFisco: "B328",
        Abitanti: "10542",
      },
      {
        Istat: "33007",
        Comune: "Cadeo",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "B332",
        Abitanti: "6187",
      },
      {
        Istat: "37009",
        Comune: "Calderara di Reno",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40012",
        CodFisco: "B399",
        Abitanti: "13163",
      },
      {
        Istat: "33008",
        Comune: "Calendasco",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "B405",
        Abitanti: "2509",
      },
      {
        Istat: "34008",
        Comune: "Calestano",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43030",
        CodFisco: "B408",
        Abitanti: "2126",
      },
      {
        Istat: "33009",
        Comune: "Caminata",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "B479",
        Abitanti: "283",
      },
      {
        Istat: "35009",
        Comune: "Campagnola Emilia",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42012",
        CodFisco: "B499",
        Abitanti: "5594",
      },
      {
        Istat: "35010",
        Comune: "Campegine",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42040",
        CodFisco: "B502",
        Abitanti: "5187",
      },
      {
        Istat: "36003",
        Comune: "Campogalliano",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41011",
        CodFisco: "B539",
        Abitanti: "8650",
      },
      {
        Istat: "36004",
        Comune: "Camposanto",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41031",
        CodFisco: "B566",
        Abitanti: "3218",
      },
      {
        Istat: "37010",
        Comune: "Camugnano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40032",
        CodFisco: "B572",
        Abitanti: "2038",
      },
      {
        Istat: "35018",
        Comune: "Canossa",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42026",
        CodFisco: "C669",
        Abitanti: "3843",
      },
      {
        Istat: "33010",
        Comune: "Caorso",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29012",
        CodFisco: "B643",
        Abitanti: "4896",
      },
      {
        Istat: "33011",
        Comune: "Carpaneto Piacentino",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29013",
        CodFisco: "B812",
        Abitanti: "7681",
      },
      {
        Istat: "36005",
        Comune: "Carpi",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41012",
        CodFisco: "B819",
        Abitanti: "69021",
      },
      {
        Istat: "35011",
        Comune: "Carpineti",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42033",
        CodFisco: "B825",
        Abitanti: "4223",
      },
      {
        Istat: "37011",
        Comune: "Casalecchio di Reno",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40033",
        CodFisco: "B880",
        Abitanti: "35761",
      },
      {
        Istat: "37012",
        Comune: "Casalfiumanese",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40020",
        CodFisco: "B892",
        Abitanti: "3478",
      },
      {
        Istat: "35012",
        Comune: "Casalgrande",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42013",
        CodFisco: "B893",
        Abitanti: "18785",
      },
      {
        Istat: "35013",
        Comune: "Casina",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42034",
        CodFisco: "B967",
        Abitanti: "4538",
      },
      {
        Istat: "39005",
        Comune: "Casola Valsenio",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "48010",
        CodFisco: "B982",
        Abitanti: "2761",
      },
      {
        Istat: "39006",
        Comune: "Castel Bolognese",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "48014",
        CodFisco: "C065",
        Abitanti: "9626",
      },
      {
        Istat: "37013",
        Comune: "Castel d'Aiano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40034",
        CodFisco: "C075",
        Abitanti: "1982",
      },
      {
        Istat: "37014",
        Comune: "Castel del Rio",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40022",
        CodFisco: "C086",
        Abitanti: "1260",
      },
      {
        Istat: "37015",
        Comune: "Castel di Casio",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40030",
        CodFisco: "B969",
        Abitanti: "3526",
      },
      {
        Istat: "37016",
        Comune: "Castel Guelfo di Bologna",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40023",
        CodFisco: "C121",
        Abitanti: "4281",
      },
      {
        Istat: "37019",
        Comune: "Castel Maggiore",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40013",
        CodFisco: "C204",
        Abitanti: "17466",
      },
      {
        Istat: "33013",
        Comune: "Castel San Giovanni",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29015",
        CodFisco: "C261",
        Abitanti: "13943",
      },
      {
        Istat: "37020",
        Comune: "Castel San Pietro Terme",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40024",
        CodFisco: "C265",
        Abitanti: "20689",
      },
      {
        Istat: "99021",
        Comune: "Casteldelci",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47861",
        CodFisco: "C080",
        Abitanti: "454",
      },
      {
        Istat: "36006",
        Comune: "Castelfranco Emilia",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41013",
        CodFisco: "C107",
        Abitanti: "32102",
      },
      {
        Istat: "35014",
        Comune: "Castellarano",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "42014",
        CodFisco: "C141",
        Abitanti: "15095",
      },
      {
        Istat: "33012",
        Comune: "Castell'Arquato",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29014",
        CodFisco: "C145",
        Abitanti: "4773",
      },
      {
        Istat: "37017",
        Comune: "Castello d'Argile",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40050",
        CodFisco: "C185",
        Abitanti: "6419",
      },
      {
        Istat: "37018",
        Comune: "Castello di Serravalle",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40050",
        CodFisco: "C191",
        Abitanti: "4917",
      },
      {
        Istat: "35015",
        Comune: "Castelnovo di Sotto",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42024",
        CodFisco: "C218",
        Abitanti: "8673",
      },
      {
        Istat: "35016",
        Comune: "Castelnovo ne' Monti",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42035",
        CodFisco: "C219",
        Abitanti: "10761",
      },
      {
        Istat: "36007",
        Comune: "Castelnuovo Rangone",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41051",
        CodFisco: "C242",
        Abitanti: "14324",
      },
      {
        Istat: "36008",
        Comune: "Castelvetro di Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41014",
        CodFisco: "C287",
        Abitanti: "11165",
      },
      {
        Istat: "33014",
        Comune: "Castelvetro Piacentino",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "C288",
        Abitanti: "5581",
      },
      {
        Istat: "37021",
        Comune: "Castenaso",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40055",
        CodFisco: "C292",
        Abitanti: "14317",
      },
      {
        Istat: "37022",
        Comune: "Castiglione dei Pepoli",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40035",
        CodFisco: "C296",
        Abitanti: "5964",
      },
      {
        Istat: "40005",
        Comune: "Castrocaro Terme e Terra del Sole",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47011",
        CodFisco: "C339",
        Abitanti: "6600",
      },
      {
        Istat: "99002",
        Comune: "Cattolica",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47841",
        CodFisco: "C357",
        Abitanti: "16899",
      },
      {
        Istat: "36009",
        Comune: "Cavezzo",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41032",
        CodFisco: "C398",
        Abitanti: "7390",
      },
      {
        Istat: "35017",
        Comune: "Cavriago",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42025",
        CodFisco: "C405",
        Abitanti: "9750",
      },
      {
        Istat: "38004",
        Comune: "Cento",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "44042",
        CodFisco: "C469",
        Abitanti: "35582",
      },
      {
        Istat: "33015",
        Comune: "Cerignale",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "C513",
        Abitanti: "170",
      },
      {
        Istat: "39007",
        Comune: "Cervia",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48015",
        CodFisco: "C553",
        Abitanti: "29180",
      },
      {
        Istat: "40007",
        Comune: "Cesena",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47521",
        CodFisco: "C573",
        Abitanti: "97056",
      },
      {
        Istat: "40007",
        Comune: "Cesena",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47522",
        CodFisco: "C573",
        Abitanti: "97056",
      },
      {
        Istat: "40008",
        Comune: "Cesenatico",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47042",
        CodFisco: "C574",
        Abitanti: "25633",
      },
      {
        Istat: "40009",
        Comune: "Civitella di Romagna",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47012",
        CodFisco: "C777",
        Abitanti: "3870",
      },
      {
        Istat: "38005",
        Comune: "Codigoro",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44021",
        CodFisco: "C814",
        Abitanti: "12653",
      },
      {
        Istat: "33016",
        Comune: "Coli",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "C838",
        Abitanti: "1001",
      },
      {
        Istat: "35019",
        Comune: "Collagna",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42037",
        CodFisco: "C840",
        Abitanti: "984",
      },
      {
        Istat: "34009",
        Comune: "Collecchio",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43044",
        CodFisco: "C852",
        Abitanti: "14120",
      },
      {
        Istat: "34010",
        Comune: "Colorno",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43052",
        CodFisco: "C904",
        Abitanti: "9096",
      },
      {
        Istat: "38006",
        Comune: "Comacchio",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44022",
        CodFisco: "C912",
        Abitanti: "23122",
      },
      {
        Istat: "34011",
        Comune: "Compiano",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43053",
        CodFisco: "C934",
        Abitanti: "1131",
      },
      {
        Istat: "36010",
        Comune: "Concordia sulla Secchia",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41033",
        CodFisco: "C951",
        Abitanti: "9059",
      },
      {
        Istat: "39008",
        Comune: "Conselice",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48017",
        CodFisco: "C963",
        Abitanti: "10028",
      },
      {
        Istat: "38007",
        Comune: "Copparo",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44034",
        CodFisco: "C980",
        Abitanti: "17245",
      },
      {
        Istat: "99003",
        Comune: "Coriano",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47853",
        CodFisco: "D004",
        Abitanti: "10200",
      },
      {
        Istat: "34012",
        Comune: "Corniglio",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43021",
        CodFisco: "D026",
        Abitanti: "2071",
      },
      {
        Istat: "35020",
        Comune: "Correggio",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42015",
        CodFisco: "D037",
        Abitanti: "25395",
      },
      {
        Istat: "33017",
        Comune: "Corte Brugnatella",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "D054",
        Abitanti: "688",
      },
      {
        Istat: "33018",
        Comune: "Cortemaggiore",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29016",
        CodFisco: "D061",
        Abitanti: "4552",
      },
      {
        Istat: "39009",
        Comune: "Cotignola",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48010",
        CodFisco: "D121",
        Abitanti: "7414",
      },
      {
        Istat: "37023",
        Comune: "Crespellano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40056",
        CodFisco: "D158",
        Abitanti: "9982",
      },
      {
        Istat: "37024",
        Comune: "Crevalcore",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40014",
        CodFisco: "D166",
        Abitanti: "13686",
      },
      {
        Istat: "40011",
        Comune: "Dovadola",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47013",
        CodFisco: "D357",
        Abitanti: "1708",
      },
      {
        Istat: "37025",
        Comune: "Dozza",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40060",
        CodFisco: "D360",
        Abitanti: "6516",
      },
      {
        Istat: "35021",
        Comune: "Fabbrico",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42042",
        CodFisco: "D450",
        Abitanti: "6750",
      },
      {
        Istat: "39010",
        Comune: "Faenza",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "48018",
        CodFisco: "D458",
        Abitanti: "58150",
      },
      {
        Istat: "36011",
        Comune: "Fanano",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41021",
        CodFisco: "D486",
        Abitanti: "3121",
      },
      {
        Istat: "33019",
        Comune: "Farini",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29023",
        CodFisco: "D502",
        Abitanti: "1489",
      },
      {
        Istat: "34013",
        Comune: "Felino",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43035",
        CodFisco: "D526",
        Abitanti: "8546",
      },
      {
        Istat: "38008",
        Comune: "Ferrara",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44121",
        CodFisco: "D548",
        Abitanti: "135369",
      },
      {
        Istat: "38008",
        Comune: "Ferrara",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44122",
        CodFisco: "D548",
        Abitanti: "135369",
      },
      {
        Istat: "38008",
        Comune: "Ferrara",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44123",
        CodFisco: "D548",
        Abitanti: "135369",
      },
      {
        Istat: "38008",
        Comune: "Ferrara",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44124",
        CodFisco: "D548",
        Abitanti: "135369",
      },
      {
        Istat: "33020",
        Comune: "Ferriere",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29024",
        CodFisco: "D555",
        Abitanti: "1551",
      },
      {
        Istat: "34014",
        Comune: "Fidenza",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "524",
        CAP: "43036",
        CodFisco: "B034",
        Abitanti: "26196",
      },
      {
        Istat: "36012",
        Comune: "Finale Emilia",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41034",
        CodFisco: "D599",
        Abitanti: "16072",
      },
      {
        Istat: "36013",
        Comune: "Fiorano Modenese",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41042",
        CodFisco: "D607",
        Abitanti: "17041",
      },
      {
        Istat: "33021",
        Comune: "Fiorenzuola d'Arda",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29017",
        CodFisco: "D611",
        Abitanti: "15204",
      },
      {
        Istat: "36014",
        Comune: "Fiumalbo",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41022",
        CodFisco: "D617",
        Abitanti: "1313",
      },
      {
        Istat: "37026",
        Comune: "Fontanelice",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40025",
        CodFisco: "D668",
        Abitanti: "1921",
      },
      {
        Istat: "34015",
        Comune: "Fontanellato",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43012",
        CodFisco: "D673",
        Abitanti: "7080",
      },
      {
        Istat: "34016",
        Comune: "Fontevivo",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43010",
        CodFisco: "D685",
        Abitanti: "5572",
      },
      {
        Istat: "40012",
        Comune: "Forlì",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47121",
        CodFisco: "D704",
        Abitanti: "118167",
      },
      {
        Istat: "40012",
        Comune: "Forlì",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47122",
        CodFisco: "D704",
        Abitanti: "118167",
      },
      {
        Istat: "40013",
        Comune: "Forlimpopoli",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47034",
        CodFisco: "D705",
        Abitanti: "13063",
      },
      {
        Istat: "36015",
        Comune: "Formigine",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41043",
        CodFisco: "D711",
        Abitanti: "33832",
      },
      {
        Istat: "38009",
        Comune: "Formignana",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44035",
        CodFisco: "D713",
        Abitanti: "2810",
      },
      {
        Istat: "34017",
        Comune: "Fornovo di Taro",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43045",
        CodFisco: "D728",
        Abitanti: "6294",
      },
      {
        Istat: "36016",
        Comune: "Frassinoro",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41044",
        CodFisco: "D783",
        Abitanti: "2046",
      },
      {
        Istat: "39011",
        Comune: "Fusignano",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48010",
        CodFisco: "D829",
        Abitanti: "8444",
      },
      {
        Istat: "37027",
        Comune: "Gaggio Montano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40041",
        CodFisco: "D847",
        Abitanti: "5131",
      },
      {
        Istat: "40014",
        Comune: "Galeata",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47010",
        CodFisco: "D867",
        Abitanti: "2532",
      },
      {
        Istat: "37028",
        Comune: "Galliera",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40015",
        CodFisco: "D878",
        Abitanti: "5555",
      },
      {
        Istat: "40015",
        Comune: "Gambettola",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47035",
        CodFisco: "D899",
        Abitanti: "10431",
      },
      {
        Istat: "35022",
        Comune: "Gattatico",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42043",
        CodFisco: "D934",
        Abitanti: "5935",
      },
      {
        Istat: "40016",
        Comune: "Gatteo",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47043",
        CodFisco: "D935",
        Abitanti: "8863",
      },
      {
        Istat: "33022",
        Comune: "Gazzola",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "D958",
        Abitanti: "2024",
      },
      {
        Istat: "99004",
        Comune: "Gemmano",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47855",
        CodFisco: "D961",
        Abitanti: "1161",
      },
      {
        Istat: "38025",
        Comune: "Goro",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44020",
        CodFisco: "E107",
        Abitanti: "3945",
      },
      {
        Istat: "33023",
        Comune: "Gossolengo",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "E114",
        Abitanti: "5328",
      },
      {
        Istat: "33024",
        Comune: "Gragnano Trebbiense",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "E132",
        Abitanti: "4397",
      },
      {
        Istat: "37029",
        Comune: "Granaglione",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40045",
        CodFisco: "E135",
        Abitanti: "2266",
      },
      {
        Istat: "37030",
        Comune: "Granarolo dell'Emilia",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40057",
        CodFisco: "E136",
        Abitanti: "10653",
      },
      {
        Istat: "37031",
        Comune: "Grizzana Morandi",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40030",
        CodFisco: "E187",
        Abitanti: "4060",
      },
      {
        Istat: "33025",
        Comune: "Gropparello",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29025",
        CodFisco: "E196",
        Abitanti: "2468",
      },
      {
        Istat: "35023",
        Comune: "Gualtieri",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42044",
        CodFisco: "E232",
        Abitanti: "6696",
      },
      {
        Istat: "35024",
        Comune: "Guastalla",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42016",
        CodFisco: "E253",
        Abitanti: "15191",
      },
      {
        Istat: "36017",
        Comune: "Guiglia",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41052",
        CodFisco: "E264",
        Abitanti: "4095",
      },
      {
        Istat: "37032",
        Comune: "Imola",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40026",
        CodFisco: "E289",
        Abitanti: "69116",
      },
      {
        Istat: "38010",
        Comune: "Jolanda di Savoia",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44037",
        CodFisco: "E320",
        Abitanti: "3089",
      },
      {
        Istat: "38011",
        Comune: "Lagosanto",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44023",
        CodFisco: "E410",
        Abitanti: "4921",
      },
      {
        Istat: "36018",
        Comune: "Lama Mocogno",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41023",
        CodFisco: "E426",
        Abitanti: "2912",
      },
      {
        Istat: "34018",
        Comune: "Langhirano",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43013",
        CodFisco: "E438",
        Abitanti: "9842",
      },
      {
        Istat: "34019",
        Comune: "Lesignano de' Bagni",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43037",
        CodFisco: "E547",
        Abitanti: "4795",
      },
      {
        Istat: "35025",
        Comune: "Ligonchio",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42039",
        CodFisco: "E585",
        Abitanti: "875",
      },
      {
        Istat: "37033",
        Comune: "Lizzano in Belvedere",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40042",
        CodFisco: "A771",
        Abitanti: "2380",
      },
      {
        Istat: "37034",
        Comune: "Loiano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40050",
        CodFisco: "E655",
        Abitanti: "4511",
      },
      {
        Istat: "40018",
        Comune: "Longiano",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47020",
        CodFisco: "E675",
        Abitanti: "6966",
      },
      {
        Istat: "33026",
        Comune: "Lugagnano Val D'Arda",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29018",
        CodFisco: "E726",
        Abitanti: "4293",
      },
      {
        Istat: "39012",
        Comune: "Lugo",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48022",
        CodFisco: "E730",
        Abitanti: "32777",
      },
      {
        Istat: "35026",
        Comune: "Luzzara",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42045",
        CodFisco: "E772",
        Abitanti: "9134",
      },
      {
        Istat: "99022",
        Comune: "Maiolo",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47862",
        CodFisco: "E838",
        Abitanti: "854",
      },
      {
        Istat: "37035",
        Comune: "Malalbergo",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40051",
        CodFisco: "E844",
        Abitanti: "8810",
      },
      {
        Istat: "36019",
        Comune: "Maranello",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41053",
        CodFisco: "E904",
        Abitanti: "16969",
      },
      {
        Istat: "36020",
        Comune: "Marano sul Panaro",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41054",
        CodFisco: "E905",
        Abitanti: "4716",
      },
      {
        Istat: "37036",
        Comune: "Marzabotto",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40043",
        CodFisco: "B689",
        Abitanti: "6833",
      },
      {
        Istat: "38012",
        Comune: "Masi Torello",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44020",
        CodFisco: "F016",
        Abitanti: "2386",
      },
      {
        Istat: "38013",
        Comune: "Massa Fiscaglia",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44025",
        CodFisco: "F026",
        Abitanti: "3621",
      },
      {
        Istat: "39013",
        Comune: "Massa Lombarda",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48024",
        CodFisco: "F029",
        Abitanti: "10700",
      },
      {
        Istat: "34020",
        Comune: "Medesano",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43014",
        CodFisco: "F082",
        Abitanti: "10749",
      },
      {
        Istat: "37037",
        Comune: "Medicina",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40059",
        CodFisco: "F083",
        Abitanti: "16675",
      },
      {
        Istat: "36021",
        Comune: "Medolla",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41036",
        CodFisco: "F087",
        Abitanti: "6331",
      },
      {
        Istat: "40019",
        Comune: "Meldola",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47014",
        CodFisco: "F097",
        Abitanti: "10193",
      },
      {
        Istat: "40020",
        Comune: "Mercato Saraceno",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47025",
        CodFisco: "F139",
        Abitanti: "7087",
      },
      {
        Istat: "38014",
        Comune: "Mesola",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44026",
        CodFisco: "F156",
        Abitanti: "7190",
      },
      {
        Istat: "34021",
        Comune: "Mezzani",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43055",
        CodFisco: "F174",
        Abitanti: "3449",
      },
      {
        Istat: "38015",
        Comune: "Migliarino",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44027",
        CodFisco: "F198",
        Abitanti: "3739",
      },
      {
        Istat: "38026",
        Comune: "Migliaro",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44020",
        CodFisco: "F199",
        Abitanti: "2239",
      },
      {
        Istat: "37038",
        Comune: "Minerbio",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40061",
        CodFisco: "F219",
        Abitanti: "8700",
      },
      {
        Istat: "38016",
        Comune: "Mirabello",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44043",
        CodFisco: "F235",
        Abitanti: "3541",
      },
      {
        Istat: "36022",
        Comune: "Mirandola",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41037",
        CodFisco: "F240",
        Abitanti: "24602",
      },
      {
        Istat: "99005",
        Comune: "Misano Adriatico",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47843",
        CodFisco: "F244",
        Abitanti: "12359",
      },
      {
        Istat: "36023",
        Comune: "Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41121",
        CodFisco: "F257",
        Abitanti: "184663",
      },
      {
        Istat: "36023",
        Comune: "Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41122",
        CodFisco: "F257",
        Abitanti: "184663",
      },
      {
        Istat: "36023",
        Comune: "Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41123",
        CodFisco: "F257",
        Abitanti: "184663",
      },
      {
        Istat: "36023",
        Comune: "Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41124",
        CodFisco: "F257",
        Abitanti: "184663",
      },
      {
        Istat: "36023",
        Comune: "Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41125",
        CodFisco: "F257",
        Abitanti: "184663",
      },
      {
        Istat: "36023",
        Comune: "Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41126",
        CodFisco: "F257",
        Abitanti: "184663",
      },
      {
        Istat: "40022",
        Comune: "Modigliana",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "47015",
        CodFisco: "F259",
        Abitanti: "4815",
      },
      {
        Istat: "37039",
        Comune: "Molinella",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40062",
        CodFisco: "F288",
        Abitanti: "15821",
      },
      {
        Istat: "34022",
        Comune: "Monchio delle Corti",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43010",
        CodFisco: "F340",
        Abitanti: "1024",
      },
      {
        Istat: "99006",
        Comune: "Mondaino",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47836",
        CodFisco: "F346",
        Abitanti: "1478",
      },
      {
        Istat: "37040",
        Comune: "Monghidoro",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40063",
        CodFisco: "F363",
        Abitanti: "3871",
      },
      {
        Istat: "99007",
        Comune: "Monte Colombo",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47854",
        CodFisco: "F476",
        Abitanti: "3302",
      },
      {
        Istat: "37042",
        Comune: "Monte San Pietro",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40050",
        CodFisco: "F627",
        Abitanti: "10956",
      },
      {
        Istat: "35027",
        Comune: "Montecchio Emilia",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42027",
        CodFisco: "F463",
        Abitanti: "10416",
      },
      {
        Istat: "34023",
        Comune: "Montechiarugolo",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43022",
        CodFisco: "F473",
        Abitanti: "10626",
      },
      {
        Istat: "36024",
        Comune: "Montecreto",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41025",
        CodFisco: "F484",
        Abitanti: "995",
      },
      {
        Istat: "99008",
        Comune: "Montefiore Conca",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47834",
        CodFisco: "F502",
        Abitanti: "2231",
      },
      {
        Istat: "36025",
        Comune: "Montefiorino",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41045",
        CodFisco: "F503",
        Abitanti: "2258",
      },
      {
        Istat: "99009",
        Comune: "Montegridolfo",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47837",
        CodFisco: "F523",
        Abitanti: "1044",
      },
      {
        Istat: "37041",
        Comune: "Monterenzio",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40050",
        CodFisco: "F597",
        Abitanti: "6090",
      },
      {
        Istat: "99010",
        Comune: "Montescudo",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47854",
        CodFisco: "F641",
        Abitanti: "3303",
      },
      {
        Istat: "36026",
        Comune: "Montese",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41055",
        CodFisco: "F642",
        Abitanti: "3407",
      },
      {
        Istat: "37043",
        Comune: "Monteveglio",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40050",
        CodFisco: "F659",
        Abitanti: "5286",
      },
      {
        Istat: "40028",
        Comune: "Montiano",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47020",
        CodFisco: "F668",
        Abitanti: "1710",
      },
      {
        Istat: "33027",
        Comune: "Monticelli d'Ongina",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "F671",
        Abitanti: "5471",
      },
      {
        Istat: "37044",
        Comune: "Monzuno",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40036",
        CodFisco: "F706",
        Abitanti: "6500",
      },
      {
        Istat: "99011",
        Comune: "Morciano di Romagna",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47833",
        CodFisco: "F715",
        Abitanti: "6988",
      },
      {
        Istat: "37045",
        Comune: "Mordano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "542",
        CAP: "40027",
        CodFisco: "F718",
        Abitanti: "4725",
      },
      {
        Istat: "33028",
        Comune: "Morfasso",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "F724",
        Abitanti: "1131",
      },
      {
        Istat: "34024",
        Comune: "Neviano degli Arduini",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43024",
        CodFisco: "F882",
        Abitanti: "3750",
      },
      {
        Istat: "33029",
        Comune: "Nibbiano",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "F885",
        Abitanti: "2294",
      },
      {
        Istat: "34025",
        Comune: "Noceto",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43015",
        CodFisco: "F914",
        Abitanti: "12724",
      },
      {
        Istat: "36027",
        Comune: "Nonantola",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41015",
        CodFisco: "F930",
        Abitanti: "15489",
      },
      {
        Istat: "99023",
        Comune: "Novafeltria",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47863",
        CodFisco: "F137",
        Abitanti: "7380",
      },
      {
        Istat: "35028",
        Comune: "Novellara",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42017",
        CodFisco: "F960",
        Abitanti: "13858",
      },
      {
        Istat: "36028",
        Comune: "Novi di Modena",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41016",
        CodFisco: "F966",
        Abitanti: "11476",
      },
      {
        Istat: "38017",
        Comune: "Ostellato",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44020",
        CodFisco: "G184",
        Abitanti: "6558",
      },
      {
        Istat: "33030",
        Comune: "Ottone",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29026",
        CodFisco: "G195",
        Abitanti: "601",
      },
      {
        Istat: "37046",
        Comune: "Ozzano dell'Emilia",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40064",
        CodFisco: "G205",
        Abitanti: "12850",
      },
      {
        Istat: "36029",
        Comune: "Palagano",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41046",
        CodFisco: "G250",
        Abitanti: "2417",
      },
      {
        Istat: "34026",
        Comune: "Palanzano",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43025",
        CodFisco: "G255",
        Abitanti: "1221",
      },
      {
        Istat: "34027",
        Comune: "Parma",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43121",
        CodFisco: "G337",
        Abitanti: "186690",
      },
      {
        Istat: "34027",
        Comune: "Parma",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43122",
        CodFisco: "G337",
        Abitanti: "186690",
      },
      {
        Istat: "34027",
        Comune: "Parma",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43123",
        CodFisco: "G337",
        Abitanti: "186690",
      },
      {
        Istat: "34027",
        Comune: "Parma",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43124",
        CodFisco: "G337",
        Abitanti: "186690",
      },
      {
        Istat: "34027",
        Comune: "Parma",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43125",
        CodFisco: "G337",
        Abitanti: "186690",
      },
      {
        Istat: "34027",
        Comune: "Parma",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43126",
        CodFisco: "G337",
        Abitanti: "186690",
      },
      {
        Istat: "36030",
        Comune: "Pavullo nel Frignano",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41026",
        CodFisco: "G393",
        Abitanti: "17350",
      },
      {
        Istat: "33031",
        Comune: "Pecorara",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "G399",
        Abitanti: "813",
      },
      {
        Istat: "34028",
        Comune: "Pellegrino Parmense",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "524",
        CAP: "43047",
        CodFisco: "G424",
        Abitanti: "1097",
      },
      {
        Istat: "99024",
        Comune: "Pennabilli",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47864",
        CodFisco: "G433",
        Abitanti: "3002",
      },
      {
        Istat: "33032",
        Comune: "Piacenza",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29121",
        CodFisco: "G535",
        Abitanti: "103206",
      },
      {
        Istat: "33032",
        Comune: "Piacenza",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29122",
        CodFisco: "G535",
        Abitanti: "103206",
      },
      {
        Istat: "33033",
        Comune: "Pianello Val Tidone",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "G557",
        Abitanti: "2296",
      },
      {
        Istat: "37047",
        Comune: "Pianoro",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40065",
        CodFisco: "G570",
        Abitanti: "17268",
      },
      {
        Istat: "37048",
        Comune: "Pieve di Cento",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40066",
        CodFisco: "G643",
        Abitanti: "6959",
      },
      {
        Istat: "36031",
        Comune: "Pievepelago",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41027",
        CodFisco: "G649",
        Abitanti: "2304",
      },
      {
        Istat: "33034",
        Comune: "Piozzano",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "G696",
        Abitanti: "646",
      },
      {
        Istat: "33035",
        Comune: "Podenzano",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29027",
        CodFisco: "G747",
        Abitanti: "9081",
      },
      {
        Istat: "99012",
        Comune: "Poggio Berni",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47824",
        CodFisco: "G755",
        Abitanti: "3412",
      },
      {
        Istat: "38018",
        Comune: "Poggio Renatico",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44028",
        CodFisco: "G768",
        Abitanti: "9634",
      },
      {
        Istat: "34029",
        Comune: "Polesine Parmense",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "524",
        CAP: "43010",
        CodFisco: "G783",
        Abitanti: "1522",
      },
      {
        Istat: "36032",
        Comune: "Polinago",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41040",
        CodFisco: "G789",
        Abitanti: "1775",
      },
      {
        Istat: "33036",
        Comune: "Ponte dell'Olio",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29028",
        CodFisco: "G842",
        Abitanti: "5055",
      },
      {
        Istat: "33037",
        Comune: "Pontenure",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "G852",
        Abitanti: "6365",
      },
      {
        Istat: "37049",
        Comune: "Porretta Terme",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40046",
        CodFisco: "A558",
        Abitanti: "4778",
      },
      {
        Istat: "40031",
        Comune: "Portico e San Benedetto",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47010",
        CodFisco: "G904",
        Abitanti: "801",
      },
      {
        Istat: "38019",
        Comune: "Portomaggiore",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44015",
        CodFisco: "G916",
        Abitanti: "12445",
      },
      {
        Istat: "35029",
        Comune: "Poviglio",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42028",
        CodFisco: "G947",
        Abitanti: "7320",
      },
      {
        Istat: "40032",
        Comune: "Predappio",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47016",
        CodFisco: "H017",
        Abitanti: "6545",
      },
      {
        Istat: "40033",
        Comune: "Premilcuore",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47010",
        CodFisco: "H034",
        Abitanti: "824",
      },
      {
        Istat: "36033",
        Comune: "Prignano sulla Secchia",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41048",
        CodFisco: "H061",
        Abitanti: "3813",
      },
      {
        Istat: "35030",
        Comune: "Quattro Castella",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42020",
        CodFisco: "H122",
        Abitanti: "13139",
      },
      {
        Istat: "35031",
        Comune: "Ramiseto",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42030",
        CodFisco: "G654",
        Abitanti: "1307",
      },
      {
        Istat: "36034",
        Comune: "Ravarino",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41017",
        CodFisco: "H195",
        Abitanti: "6318",
      },
      {
        Istat: "39014",
        Comune: "Ravenna",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48121",
        CodFisco: "H199",
        Abitanti: "158739",
      },
      {
        Istat: "39014",
        Comune: "Ravenna",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48122",
        CodFisco: "H199",
        Abitanti: "158739",
      },
      {
        Istat: "39014",
        Comune: "Ravenna",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48123",
        CodFisco: "H199",
        Abitanti: "158739",
      },
      {
        Istat: "39014",
        Comune: "Ravenna",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48124",
        CodFisco: "H199",
        Abitanti: "158739",
      },
      {
        Istat: "39014",
        Comune: "Ravenna",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48125",
        CodFisco: "H199",
        Abitanti: "158739",
      },
      {
        Istat: "35033",
        Comune: "Reggio Emilia",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42121",
        CodFisco: "H223",
        Abitanti: "170086",
      },
      {
        Istat: "35033",
        Comune: "Reggio Emilia",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42122",
        CodFisco: "H223",
        Abitanti: "170086",
      },
      {
        Istat: "35033",
        Comune: "Reggio Emilia",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42123",
        CodFisco: "H223",
        Abitanti: "170086",
      },
      {
        Istat: "35033",
        Comune: "Reggio Emilia",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42124",
        CodFisco: "H223",
        Abitanti: "170086",
      },
      {
        Istat: "35032",
        Comune: "Reggiolo",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42046",
        CodFisco: "H225",
        Abitanti: "9362",
      },
      {
        Istat: "99013",
        Comune: "Riccione",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47838",
        CodFisco: "H274",
        Abitanti: "35815",
      },
      {
        Istat: "99014",
        Comune: "Rimini",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47921",
        CodFisco: "H294",
        Abitanti: "143321",
      },
      {
        Istat: "99014",
        Comune: "Rimini",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47922",
        CodFisco: "H294",
        Abitanti: "143321",
      },
      {
        Istat: "99014",
        Comune: "Rimini",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47923",
        CodFisco: "H294",
        Abitanti: "143321",
      },
      {
        Istat: "99014",
        Comune: "Rimini",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47924",
        CodFisco: "H294",
        Abitanti: "143321",
      },
      {
        Istat: "35034",
        Comune: "Rio Saliceto",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42010",
        CodFisco: "H298",
        Abitanti: "6048",
      },
      {
        Istat: "39015",
        Comune: "Riolo Terme",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "48025",
        CodFisco: "H302",
        Abitanti: "5813",
      },
      {
        Istat: "36035",
        Comune: "Riolunato",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41020",
        CodFisco: "H303",
        Abitanti: "759",
      },
      {
        Istat: "33038",
        Comune: "Rivergaro",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29029",
        CodFisco: "H350",
        Abitanti: "6878",
      },
      {
        Istat: "38020",
        Comune: "Ro",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44030",
        CodFisco: "H360",
        Abitanti: "3460",
      },
      {
        Istat: "40036",
        Comune: "Rocca San Casciano",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47017",
        CodFisco: "H437",
        Abitanti: "2031",
      },
      {
        Istat: "34030",
        Comune: "Roccabianca",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43010",
        CodFisco: "H384",
        Abitanti: "3110",
      },
      {
        Istat: "35035",
        Comune: "Rolo",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42047",
        CodFisco: "H500",
        Abitanti: "4090",
      },
      {
        Istat: "40037",
        Comune: "Roncofreddo",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47020",
        CodFisco: "H542",
        Abitanti: "3371",
      },
      {
        Istat: "33039",
        Comune: "Rottofreno",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "H593",
        Abitanti: "11524",
      },
      {
        Istat: "35036",
        Comune: "Rubiera",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42048",
        CodFisco: "H628",
        Abitanti: "14559",
      },
      {
        Istat: "39016",
        Comune: "Russi",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "544",
        CAP: "48026",
        CodFisco: "H642",
        Abitanti: "12286",
      },
      {
        Istat: "34031",
        Comune: "Sala Baganza",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43038",
        CodFisco: "H682",
        Abitanti: "5394",
      },
      {
        Istat: "37050",
        Comune: "Sala Bolognese",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40010",
        CodFisco: "H678",
        Abitanti: "8286",
      },
      {
        Istat: "34032",
        Comune: "Salsomaggiore Terme",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "524",
        CAP: "43039",
        CodFisco: "H720",
        Abitanti: "20051",
      },
      {
        Istat: "99015",
        Comune: "Saludecio",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47835",
        CodFisco: "H724",
        Abitanti: "2998",
      },
      {
        Istat: "37051",
        Comune: "San Benedetto Val di Sambro",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "534",
        CAP: "40048",
        CodFisco: "G566",
        Abitanti: "4495",
      },
      {
        Istat: "36036",
        Comune: "San Cesario sul Panaro",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41018",
        CodFisco: "H794",
        Abitanti: "6057",
      },
      {
        Istat: "99016",
        Comune: "San Clemente",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47832",
        CodFisco: "H801",
        Abitanti: "5153",
      },
      {
        Istat: "36037",
        Comune: "San Felice sul Panaro",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41038",
        CodFisco: "H835",
        Abitanti: "11135",
      },
      {
        Istat: "37052",
        Comune: "San Giorgio di Piano",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40016",
        CodFisco: "H896",
        Abitanti: "8289",
      },
      {
        Istat: "33040",
        Comune: "San Giorgio Piacentino",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29019",
        CodFisco: "H887",
        Abitanti: "5859",
      },
      {
        Istat: "99017",
        Comune: "San Giovanni in Marignano",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47842",
        CodFisco: "H921",
        Abitanti: "9090",
      },
      {
        Istat: "37053",
        Comune: "San Giovanni in Persiceto",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40017",
        CodFisco: "G467",
        Abitanti: "27227",
      },
      {
        Istat: "37054",
        Comune: "San Lazzaro di Savena",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40068",
        CodFisco: "H945",
        Abitanti: "31457",
      },
      {
        Istat: "99025",
        Comune: "San Leo",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47865",
        CodFisco: "H949",
        Abitanti: "3074",
      },
      {
        Istat: "35037",
        Comune: "San Martino in Rio",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42018",
        CodFisco: "I011",
        Abitanti: "8010",
      },
      {
        Istat: "40041",
        Comune: "San Mauro Pascoli",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47030",
        CodFisco: "I027",
        Abitanti: "11106",
      },
      {
        Istat: "37055",
        Comune: "San Pietro in Casale",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40018",
        CodFisco: "I110",
        Abitanti: "11815",
      },
      {
        Istat: "33041",
        Comune: "San Pietro in Cerro",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "G788",
        Abitanti: "932",
      },
      {
        Istat: "35038",
        Comune: "San Polo d'Enza",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42020",
        CodFisco: "I123",
        Abitanti: "5858",
      },
      {
        Istat: "36038",
        Comune: "San Possidonio",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "535",
        CAP: "41039",
        CodFisco: "I128",
        Abitanti: "3828",
      },
      {
        Istat: "36039",
        Comune: "San Prospero",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41030",
        CodFisco: "I133",
        Abitanti: "5888",
      },
      {
        Istat: "34033",
        Comune: "San Secondo Parmense",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43017",
        CodFisco: "I153",
        Abitanti: "5648",
      },
      {
        Istat: "40043",
        Comune: "Santa Sofia",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47018",
        CodFisco: "I310",
        Abitanti: "4240",
      },
      {
        Istat: "37056",
        Comune: "Sant'Agata Bolognese",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40019",
        CodFisco: "I191",
        Abitanti: "7392",
      },
      {
        Istat: "99026",
        Comune: "Sant'Agata Feltria",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47866",
        CodFisco: "I201",
        Abitanti: "2281",
      },
      {
        Istat: "39017",
        Comune: "Sant'Agata sul Santerno",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "545",
        CAP: "48020",
        CodFisco: "I196",
        Abitanti: "2849",
      },
      {
        Istat: "38021",
        Comune: "Sant'Agostino",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44047",
        CodFisco: "I209",
        Abitanti: "7106",
      },
      {
        Istat: "99018",
        Comune: "Santarcangelo di Romagna",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47822",
        CodFisco: "I304",
        Abitanti: "21409",
      },
      {
        Istat: "35039",
        Comune: "Sant'Ilario d'Enza",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42049",
        CodFisco: "I342",
        Abitanti: "11021",
      },
      {
        Istat: "33042",
        Comune: "Sarmato",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "I434",
        Abitanti: "2868",
      },
      {
        Istat: "40044",
        Comune: "Sarsina",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "547",
        CAP: "47027",
        CodFisco: "I444",
        Abitanti: "3652",
      },
      {
        Istat: "37057",
        Comune: "Sasso Marconi",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40037",
        CodFisco: "G972",
        Abitanti: "14727",
      },
      {
        Istat: "36040",
        Comune: "Sassuolo",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41049",
        CodFisco: "I462",
        Abitanti: "41290",
      },
      {
        Istat: "36041",
        Comune: "Savignano sul Panaro",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41056",
        CodFisco: "I473",
        Abitanti: "9452",
      },
      {
        Istat: "40045",
        Comune: "Savignano sul Rubicone",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47039",
        CodFisco: "I472",
        Abitanti: "17653",
      },
      {
        Istat: "37058",
        Comune: "Savigno",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40060",
        CodFisco: "I474",
        Abitanti: "2788",
      },
      {
        Istat: "35040",
        Comune: "Scandiano",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42019",
        CodFisco: "I496",
        Abitanti: "25074",
      },
      {
        Istat: "36042",
        Comune: "Serramazzoni",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41028",
        CodFisco: "F357",
        Abitanti: "8300",
      },
      {
        Istat: "36043",
        Comune: "Sestola",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "536",
        CAP: "41029",
        CodFisco: "I689",
        Abitanti: "2642",
      },
      {
        Istat: "34034",
        Comune: "Sissa",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43018",
        CodFisco: "I763",
        Abitanti: "4311",
      },
      {
        Istat: "40046",
        Comune: "Sogliano al Rubicone",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47030",
        CodFisco: "I779",
        Abitanti: "3293",
      },
      {
        Istat: "39018",
        Comune: "Solarolo",
        Provincia: "RA",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "48027",
        CodFisco: "I787",
        Abitanti: "4438",
      },
      {
        Istat: "36044",
        Comune: "Soliera",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41019",
        CodFisco: "I802",
        Abitanti: "15289",
      },
      {
        Istat: "34035",
        Comune: "Solignano",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43040",
        CodFisco: "I803",
        Abitanti: "1858",
      },
      {
        Istat: "34036",
        Comune: "Soragna",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "524",
        CAP: "43019",
        CodFisco: "I840",
        Abitanti: "4883",
      },
      {
        Istat: "34037",
        Comune: "Sorbolo",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43058",
        CodFisco: "I845",
        Abitanti: "9648",
      },
      {
        Istat: "36045",
        Comune: "Spilamberto",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41057",
        CodFisco: "I903",
        Abitanti: "12318",
      },
      {
        Istat: "99027",
        Comune: "Talamello",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47867",
        CodFisco: "L034",
        Abitanti: "1080",
      },
      {
        Istat: "34038",
        Comune: "Terenzo",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43040",
        CodFisco: "E548",
        Abitanti: "1239",
      },
      {
        Istat: "34039",
        Comune: "Tizzano Val Parma",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43028",
        CodFisco: "L183",
        Abitanti: "2163",
      },
      {
        Istat: "35041",
        Comune: "Toano",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42010",
        CodFisco: "L184",
        Abitanti: "4541",
      },
      {
        Istat: "34040",
        Comune: "Tornolo",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43059",
        CodFisco: "L229",
        Abitanti: "1145",
      },
      {
        Istat: "99019",
        Comune: "Torriana",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47825",
        CodFisco: "I550",
        Abitanti: "1577",
      },
      {
        Istat: "34041",
        Comune: "Torrile",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43056",
        CodFisco: "L299",
        Abitanti: "7804",
      },
      {
        Istat: "34042",
        Comune: "Traversetolo",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43029",
        CodFisco: "L346",
        Abitanti: "9339",
      },
      {
        Istat: "33043",
        Comune: "Travo",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "L348",
        Abitanti: "2009",
      },
      {
        Istat: "34043",
        Comune: "Trecasali",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "521",
        CAP: "43010",
        CodFisco: "L354",
        Abitanti: "3679",
      },
      {
        Istat: "40049",
        Comune: "Tredozio",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "546",
        CAP: "47019",
        CodFisco: "L361",
        Abitanti: "1283",
      },
      {
        Istat: "38024",
        Comune: "Tresigallo",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "533",
        CAP: "44039",
        CodFisco: "L390",
        Abitanti: "4617",
      },
      {
        Istat: "34044",
        Comune: "Valmozzola",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43050",
        CodFisco: "L641",
        Abitanti: "585",
      },
      {
        Istat: "34045",
        Comune: "Varano de' Melegari",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43040",
        CodFisco: "L672",
        Abitanti: "2704",
      },
      {
        Istat: "34046",
        Comune: "Varsi",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "525",
        CAP: "43049",
        CodFisco: "L689",
        Abitanti: "1300",
      },
      {
        Istat: "37059",
        Comune: "Vergato",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40038",
        CodFisco: "L762",
        Abitanti: "7854",
      },
      {
        Istat: "40050",
        Comune: "Verghereto",
        Provincia: "FC",
        Regione: "EMR",
        Prefisso: "543",
        CAP: "47028",
        CodFisco: "L764",
        Abitanti: "1992",
      },
      {
        Istat: "33044",
        Comune: "Vernasca",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "L772",
        Abitanti: "2313",
      },
      {
        Istat: "99020",
        Comune: "Verucchio",
        Provincia: "RN",
        Regione: "EMR",
        Prefisso: "541",
        CAP: "47826",
        CodFisco: "L797",
        Abitanti: "10079",
      },
      {
        Istat: "35042",
        Comune: "Vetto",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42020",
        CodFisco: "L815",
        Abitanti: "2001",
      },
      {
        Istat: "35043",
        Comune: "Vezzano sul Crostolo",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42030",
        CodFisco: "L820",
        Abitanti: "4270",
      },
      {
        Istat: "35044",
        Comune: "Viano",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42030",
        CodFisco: "L831",
        Abitanti: "3423",
      },
      {
        Istat: "38022",
        Comune: "Vigarano Mainarda",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44049",
        CodFisco: "L868",
        Abitanti: "7520",
      },
      {
        Istat: "36046",
        Comune: "Vignola",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41058",
        CodFisco: "L885",
        Abitanti: "24802",
      },
      {
        Istat: "33045",
        Comune: "Vigolzone",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "L897",
        Abitanti: "4314",
      },
      {
        Istat: "35045",
        Comune: "Villa Minozzo",
        Provincia: "RE",
        Regione: "EMR",
        Prefisso: "522",
        CAP: "42030",
        CodFisco: "L969",
        Abitanti: "3988",
      },
      {
        Istat: "33046",
        Comune: "Villanova sull'Arda",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "L980",
        Abitanti: "1969",
      },
      {
        Istat: "38023",
        Comune: "Voghiera",
        Provincia: "FE",
        Regione: "EMR",
        Prefisso: "532",
        CAP: "44019",
        CodFisco: "M110",
        Abitanti: "3918",
      },
      {
        Istat: "33047",
        Comune: "Zerba",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29020",
        CodFisco: "M165",
        Abitanti: "94",
      },
      {
        Istat: "33048",
        Comune: "Ziano Piacentino",
        Provincia: "PC",
        Regione: "EMR",
        Prefisso: "523",
        CAP: "29010",
        CodFisco: "L848",
        Abitanti: "2671",
      },
      {
        Istat: "34048",
        Comune: "Zibello",
        Provincia: "PR",
        Regione: "EMR",
        Prefisso: "524",
        CAP: "43010",
        CodFisco: "M174",
        Abitanti: "1863",
      },
      {
        Istat: "36047",
        Comune: "Zocca",
        Provincia: "MO",
        Regione: "EMR",
        Prefisso: "59",
        CAP: "41059",
        CodFisco: "M183",
        Abitanti: "5024",
      },
      {
        Istat: "37060",
        Comune: "Zola Predosa",
        Provincia: "BO",
        Regione: "EMR",
        Prefisso: "51",
        CAP: "40069",
        CodFisco: "M185",
        Abitanti: "18314",
      },
      {
        Istat: "30001",
        Comune: "Aiello del Friuli",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33041",
        CodFisco: "A103",
        Abitanti: "2260",
      },
      {
        Istat: "30002",
        Comune: "Amaro",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "A254",
        Abitanti: "820",
      },
      {
        Istat: "30003",
        Comune: "Ampezzo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33021",
        CodFisco: "A267",
        Abitanti: "1058",
      },
      {
        Istat: "93001",
        Comune: "Andreis",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33080",
        CodFisco: "A283",
        Abitanti: "289",
      },
      {
        Istat: "30004",
        Comune: "Aquileia",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33051",
        CodFisco: "A346",
        Abitanti: "3493",
      },
      {
        Istat: "93002",
        Comune: "Arba",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "A354",
        Abitanti: "1336",
      },
      {
        Istat: "30005",
        Comune: "Arta Terme",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33022",
        CodFisco: "A447",
        Abitanti: "2273",
      },
      {
        Istat: "30006",
        Comune: "Artegna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33011",
        CodFisco: "A448",
        Abitanti: "2912",
      },
      {
        Istat: "93003",
        Comune: "Arzene",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33098",
        CodFisco: "A456",
        Abitanti: "1808",
      },
      {
        Istat: "30007",
        Comune: "Attimis",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "A491",
        Abitanti: "1893",
      },
      {
        Istat: "93004",
        Comune: "Aviano",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33081",
        CodFisco: "A516",
        Abitanti: "9270",
      },
      {
        Istat: "93005",
        Comune: "Azzano Decimo",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33082",
        CodFisco: "A530",
        Abitanti: "15601",
      },
      {
        Istat: "30008",
        Comune: "Bagnaria Arsa",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "A553",
        Abitanti: "3595",
      },
      {
        Istat: "93006",
        Comune: "Barcis",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33080",
        CodFisco: "A640",
        Abitanti: "256",
      },
      {
        Istat: "30009",
        Comune: "Basiliano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33031",
        CodFisco: "A700",
        Abitanti: "5410",
      },
      {
        Istat: "30010",
        Comune: "Bertiolo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33032",
        CodFisco: "A810",
        Abitanti: "2578",
      },
      {
        Istat: "30011",
        Comune: "Bicinicco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "A855",
        Abitanti: "1930",
      },
      {
        Istat: "30012",
        Comune: "Bordano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "A983",
        Abitanti: "810",
      },
      {
        Istat: "93007",
        Comune: "Brugnera",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33070",
        CodFisco: "B215",
        Abitanti: "9300",
      },
      {
        Istat: "93008",
        Comune: "Budoia",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33070",
        CodFisco: "B247",
        Abitanti: "2573",
      },
      {
        Istat: "30013",
        Comune: "Buja",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "B259",
        Abitanti: "6759",
      },
      {
        Istat: "30014",
        Comune: "Buttrio",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33042",
        CodFisco: "B309",
        Abitanti: "4140",
      },
      {
        Istat: "30015",
        Comune: "Camino al Tagliamento",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "B483",
        Abitanti: "1676",
      },
      {
        Istat: "30016",
        Comune: "Campoformido",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "B536",
        Abitanti: "7771",
      },
      {
        Istat: "30138",
        Comune: "Campolongo Tapogliano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33040",
        CodFisco: "M311",
        Abitanti: "1215",
      },
      {
        Istat: "93009",
        Comune: "Caneva",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33070",
        CodFisco: "B598",
        Abitanti: "6541",
      },
      {
        Istat: "31001",
        Comune: "Capriva del Friuli",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "B712",
        Abitanti: "1747",
      },
      {
        Istat: "30018",
        Comune: "Carlino",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "B788",
        Abitanti: "2806",
      },
      {
        Istat: "93010",
        Comune: "Casarsa della Delizia",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33072",
        CodFisco: "B940",
        Abitanti: "8585",
      },
      {
        Istat: "30019",
        Comune: "Cassacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "B994",
        Abitanti: "2926",
      },
      {
        Istat: "93011",
        Comune: "Castelnovo del Friuli",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "C217",
        Abitanti: "942",
      },
      {
        Istat: "30020",
        Comune: "Castions di Strada",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "C327",
        Abitanti: "3904",
      },
      {
        Istat: "93012",
        Comune: "Cavasso Nuovo",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33092",
        CodFisco: "C385",
        Abitanti: "1637",
      },
      {
        Istat: "30021",
        Comune: "Cavazzo Carnico",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "C389",
        Abitanti: "1102",
      },
      {
        Istat: "30022",
        Comune: "Cercivento",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "C494",
        Abitanti: "705",
      },
      {
        Istat: "30023",
        Comune: "Cervignano del Friuli",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33052",
        CodFisco: "C556",
        Abitanti: "13590",
      },
      {
        Istat: "93013",
        Comune: "Chions",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33083",
        CodFisco: "C640",
        Abitanti: "5260",
      },
      {
        Istat: "30024",
        Comune: "Chiopris-Viscone",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33048",
        CodFisco: "C641",
        Abitanti: "649",
      },
      {
        Istat: "30025",
        Comune: "Chiusaforte",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33010",
        CodFisco: "C656",
        Abitanti: "705",
      },
      {
        Istat: "93014",
        Comune: "Cimolais",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33080",
        CodFisco: "C699",
        Abitanti: "431",
      },
      {
        Istat: "30026",
        Comune: "Cividale del Friuli",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33043",
        CodFisco: "C758",
        Abitanti: "11615",
      },
      {
        Istat: "93015",
        Comune: "Claut",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33080",
        CodFisco: "C790",
        Abitanti: "1027",
      },
      {
        Istat: "93016",
        Comune: "Clauzetto",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "C791",
        Abitanti: "402",
      },
      {
        Istat: "30027",
        Comune: "Codroipo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33033",
        CodFisco: "C817",
        Abitanti: "15887",
      },
      {
        Istat: "30028",
        Comune: "Colloredo di Monte Albano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "C885",
        Abitanti: "2245",
      },
      {
        Istat: "30029",
        Comune: "Comeglians",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33023",
        CodFisco: "C918",
        Abitanti: "540",
      },
      {
        Istat: "93017",
        Comune: "Cordenons",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33084",
        CodFisco: "C991",
        Abitanti: "18470",
      },
      {
        Istat: "93018",
        Comune: "Cordovado",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33075",
        CodFisco: "C993",
        Abitanti: "2759",
      },
      {
        Istat: "31002",
        Comune: "Cormons",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34071",
        CodFisco: "D014",
        Abitanti: "7698",
      },
      {
        Istat: "30030",
        Comune: "Corno di Rosazzo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "D027",
        Abitanti: "3284",
      },
      {
        Istat: "30031",
        Comune: "Coseano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "D085",
        Abitanti: "2262",
      },
      {
        Istat: "30032",
        Comune: "Dignano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "D300",
        Abitanti: "2430",
      },
      {
        Istat: "31003",
        Comune: "Doberdò del Lago",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "D312",
        Abitanti: "1462",
      },
      {
        Istat: "30033",
        Comune: "Dogna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "428",
        CAP: "33010",
        CodFisco: "D316",
        Abitanti: "200",
      },
      {
        Istat: "31004",
        Comune: "Dolegna del Collio",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "D321",
        Abitanti: "387",
      },
      {
        Istat: "30034",
        Comune: "Drenchia",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "D366",
        Abitanti: "141",
      },
      {
        Istat: "32001",
        Comune: "Duino-Aurisina",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34011",
        CodFisco: "D383",
        Abitanti: "8717",
      },
      {
        Istat: "30035",
        Comune: "Enemonzo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "D408",
        Abitanti: "1355",
      },
      {
        Istat: "93019",
        Comune: "Erto e Casso",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33080",
        CodFisco: "D426",
        Abitanti: "389",
      },
      {
        Istat: "30036",
        Comune: "Faedis",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "D455",
        Abitanti: "3039",
      },
      {
        Istat: "30037",
        Comune: "Fagagna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33034",
        CodFisco: "D461",
        Abitanti: "6363",
      },
      {
        Istat: "93020",
        Comune: "Fanna",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33092",
        CodFisco: "D487",
        Abitanti: "1572",
      },
      {
        Istat: "31005",
        Comune: "Farra d'Isonzo",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34072",
        CodFisco: "D504",
        Abitanti: "1754",
      },
      {
        Istat: "93021",
        Comune: "Fiume Veneto",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33080",
        CodFisco: "D621",
        Abitanti: "11494",
      },
      {
        Istat: "30038",
        Comune: "Fiumicello",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "D627",
        Abitanti: "5063",
      },
      {
        Istat: "30039",
        Comune: "Flaibano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "D630",
        Abitanti: "1209",
      },
      {
        Istat: "31006",
        Comune: "Fogliano Redipuglia",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "D645",
        Abitanti: "3071",
      },
      {
        Istat: "93022",
        Comune: "Fontanafredda",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33074",
        CodFisco: "D670",
        Abitanti: "11686",
      },
      {
        Istat: "30137",
        Comune: "Forgaria nel Friuli",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33030",
        CodFisco: "D700",
        Abitanti: "1854",
      },
      {
        Istat: "30040",
        Comune: "Forni Avoltri",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "D718",
        Abitanti: "653",
      },
      {
        Istat: "30041",
        Comune: "Forni di Sopra",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33024",
        CodFisco: "D719",
        Abitanti: "1071",
      },
      {
        Istat: "30042",
        Comune: "Forni di Sotto",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "D720",
        Abitanti: "660",
      },
      {
        Istat: "93024",
        Comune: "Frisanco",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33080",
        CodFisco: "D804",
        Abitanti: "683",
      },
      {
        Istat: "30043",
        Comune: "Gemona del Friuli",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33013",
        CodFisco: "D962",
        Abitanti: "11241",
      },
      {
        Istat: "30044",
        Comune: "Gonars",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "E083",
        Abitanti: "4810",
      },
      {
        Istat: "31007",
        Comune: "Gorizia",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34170",
        CodFisco: "E098",
        Abitanti: "35798",
      },
      {
        Istat: "31008",
        Comune: "Gradisca d'Isonzo",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34072",
        CodFisco: "E124",
        Abitanti: "6617",
      },
      {
        Istat: "31009",
        Comune: "Grado",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "34073",
        CodFisco: "E125",
        Abitanti: "8611",
      },
      {
        Istat: "30045",
        Comune: "Grimacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "E179",
        Abitanti: "395",
      },
      {
        Istat: "30046",
        Comune: "Latisana",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33053",
        CodFisco: "E473",
        Abitanti: "13953",
      },
      {
        Istat: "30047",
        Comune: "Lauco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33029",
        CodFisco: "E476",
        Abitanti: "805",
      },
      {
        Istat: "30048",
        Comune: "Lestizza",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "E553",
        Abitanti: "3937",
      },
      {
        Istat: "30049",
        Comune: "Lignano Sabbiadoro",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33054",
        CodFisco: "E584",
        Abitanti: "6813",
      },
      {
        Istat: "30050",
        Comune: "Ligosullo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "E586",
        Abitanti: "180",
      },
      {
        Istat: "30051",
        Comune: "Lusevera",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "E760",
        Abitanti: "711",
      },
      {
        Istat: "30052",
        Comune: "Magnano in Riviera",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "E820",
        Abitanti: "2393",
      },
      {
        Istat: "30053",
        Comune: "Majano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "E833",
        Abitanti: "6080",
      },
      {
        Istat: "30054",
        Comune: "Malborghetto Valbruna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "428",
        CAP: "33010",
        CodFisco: "E847",
        Abitanti: "965",
      },
      {
        Istat: "93025",
        Comune: "Maniago",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33085",
        CodFisco: "E889",
        Abitanti: "11968",
      },
      {
        Istat: "30055",
        Comune: "Manzano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33044",
        CodFisco: "E899",
        Abitanti: "6730",
      },
      {
        Istat: "30056",
        Comune: "Marano Lagunare",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "E910",
        Abitanti: "1965",
      },
      {
        Istat: "31010",
        Comune: "Mariano del Friuli",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "E952",
        Abitanti: "1592",
      },
      {
        Istat: "30057",
        Comune: "Martignacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33035",
        CodFisco: "E982",
        Abitanti: "6752",
      },
      {
        Istat: "31011",
        Comune: "Medea",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34076",
        CodFisco: "F081",
        Abitanti: "979",
      },
      {
        Istat: "93026",
        Comune: "Meduno",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33092",
        CodFisco: "F089",
        Abitanti: "1701",
      },
      {
        Istat: "30058",
        Comune: "Mereto di Tomba",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33036",
        CodFisco: "F144",
        Abitanti: "2736",
      },
      {
        Istat: "30059",
        Comune: "Moggio Udinese",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33015",
        CodFisco: "F266",
        Abitanti: "1842",
      },
      {
        Istat: "30060",
        Comune: "Moimacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "F275",
        Abitanti: "1636",
      },
      {
        Istat: "31012",
        Comune: "Monfalcone",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34074",
        CodFisco: "F356",
        Abitanti: "27877",
      },
      {
        Istat: "32002",
        Comune: "Monrupino",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34016",
        CodFisco: "F378",
        Abitanti: "890",
      },
      {
        Istat: "30061",
        Comune: "Montenars",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "F574",
        Abitanti: "558",
      },
      {
        Istat: "93027",
        Comune: "Montereale Valcellina",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33086",
        CodFisco: "F596",
        Abitanti: "4570",
      },
      {
        Istat: "31013",
        Comune: "Moraro",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "F710",
        Abitanti: "761",
      },
      {
        Istat: "93028",
        Comune: "Morsano al Tagliamento",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33075",
        CodFisco: "F750",
        Abitanti: "2887",
      },
      {
        Istat: "30062",
        Comune: "Mortegliano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "F756",
        Abitanti: "5093",
      },
      {
        Istat: "30063",
        Comune: "Moruzzo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "F760",
        Abitanti: "2366",
      },
      {
        Istat: "31014",
        Comune: "Mossa",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "F767",
        Abitanti: "1674",
      },
      {
        Istat: "32003",
        Comune: "Muggia",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34015",
        CodFisco: "F795",
        Abitanti: "13410",
      },
      {
        Istat: "30064",
        Comune: "Muzzana del Turgnano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33055",
        CodFisco: "F832",
        Abitanti: "2673",
      },
      {
        Istat: "30065",
        Comune: "Nimis",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33045",
        CodFisco: "F898",
        Abitanti: "2789",
      },
      {
        Istat: "30066",
        Comune: "Osoppo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "G163",
        Abitanti: "3033",
      },
      {
        Istat: "30067",
        Comune: "Ovaro",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33025",
        CodFisco: "G198",
        Abitanti: "2064",
      },
      {
        Istat: "30068",
        Comune: "Pagnacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "G238",
        Abitanti: "5037",
      },
      {
        Istat: "30069",
        Comune: "Palazzolo dello Stella",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33056",
        CodFisco: "G268",
        Abitanti: "3042",
      },
      {
        Istat: "30070",
        Comune: "Palmanova",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33057",
        CodFisco: "G284",
        Abitanti: "5453",
      },
      {
        Istat: "30071",
        Comune: "Paluzza",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33026",
        CodFisco: "G300",
        Abitanti: "2403",
      },
      {
        Istat: "30072",
        Comune: "Pasian di Prato",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33037",
        CodFisco: "G352",
        Abitanti: "9317",
      },
      {
        Istat: "93029",
        Comune: "Pasiano di Pordenone",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33087",
        CodFisco: "G353",
        Abitanti: "7901",
      },
      {
        Istat: "30073",
        Comune: "Paularo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33027",
        CodFisco: "G381",
        Abitanti: "2782",
      },
      {
        Istat: "30074",
        Comune: "Pavia di Udine",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "G389",
        Abitanti: "5736",
      },
      {
        Istat: "93030",
        Comune: "Pinzano al Tagliamento",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33094",
        CodFisco: "G680",
        Abitanti: "1613",
      },
      {
        Istat: "30075",
        Comune: "Pocenia",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "G743",
        Abitanti: "2619",
      },
      {
        Istat: "93031",
        Comune: "Polcenigo",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33070",
        CodFisco: "G780",
        Abitanti: "3237",
      },
      {
        Istat: "30076",
        Comune: "Pontebba",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "428",
        CAP: "33016",
        CodFisco: "G831",
        Abitanti: "1535",
      },
      {
        Istat: "93032",
        Comune: "Porcia",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33080",
        CodFisco: "G886",
        Abitanti: "15443",
      },
      {
        Istat: "93033",
        Comune: "Pordenone",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33170",
        CodFisco: "G888",
        Abitanti: "51723",
      },
      {
        Istat: "30077",
        Comune: "Porpetto",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "G891",
        Abitanti: "2673",
      },
      {
        Istat: "30078",
        Comune: "Povoletto",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "G949",
        Abitanti: "5588",
      },
      {
        Istat: "30079",
        Comune: "Pozzuolo del Friuli",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "G966",
        Abitanti: "6909",
      },
      {
        Istat: "30080",
        Comune: "Pradamano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "G969",
        Abitanti: "3566",
      },
      {
        Istat: "93034",
        Comune: "Prata di Pordenone",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33080",
        CodFisco: "G994",
        Abitanti: "8569",
      },
      {
        Istat: "30081",
        Comune: "Prato Carnico",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "H002",
        Abitanti: "958",
      },
      {
        Istat: "93035",
        Comune: "Pravisdomini",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33076",
        CodFisco: "H010",
        Abitanti: "3532",
      },
      {
        Istat: "30082",
        Comune: "Precenicco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "H014",
        Abitanti: "1498",
      },
      {
        Istat: "30083",
        Comune: "Premariacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "H029",
        Abitanti: "4222",
      },
      {
        Istat: "30084",
        Comune: "Preone",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "H038",
        Abitanti: "279",
      },
      {
        Istat: "30085",
        Comune: "Prepotto",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "H040",
        Abitanti: "829",
      },
      {
        Istat: "30086",
        Comune: "Pulfero",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33046",
        CodFisco: "H089",
        Abitanti: "1052",
      },
      {
        Istat: "30087",
        Comune: "Ragogna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "H161",
        Abitanti: "2985",
      },
      {
        Istat: "30088",
        Comune: "Ravascletto",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "H196",
        Abitanti: "569",
      },
      {
        Istat: "30089",
        Comune: "Raveo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33029",
        CodFisco: "H200",
        Abitanti: "506",
      },
      {
        Istat: "30090",
        Comune: "Reana del Rojale",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "H206",
        Abitanti: "5044",
      },
      {
        Istat: "30091",
        Comune: "Remanzacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33047",
        CodFisco: "H229",
        Abitanti: "6075",
      },
      {
        Istat: "30092",
        Comune: "Resia",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33010",
        CodFisco: "H242",
        Abitanti: "1101",
      },
      {
        Istat: "30093",
        Comune: "Resiutta",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33010",
        CodFisco: "H244",
        Abitanti: "320",
      },
      {
        Istat: "30094",
        Comune: "Rigolato",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "H289",
        Abitanti: "513",
      },
      {
        Istat: "30095",
        Comune: "Rive D'Arcano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "H347",
        Abitanti: "2492",
      },
      {
        Istat: "30096",
        Comune: "Rivignano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "H352",
        Abitanti: "4453",
      },
      {
        Istat: "31015",
        Comune: "Romans d'Isonzo",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34076",
        CodFisco: "H514",
        Abitanti: "3732",
      },
      {
        Istat: "31016",
        Comune: "Ronchi dei Legionari",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34077",
        CodFisco: "H531",
        Abitanti: "12130",
      },
      {
        Istat: "30097",
        Comune: "Ronchis",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "H533",
        Abitanti: "2077",
      },
      {
        Istat: "93036",
        Comune: "Roveredo in Piano",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33080",
        CodFisco: "H609",
        Abitanti: "5746",
      },
      {
        Istat: "30098",
        Comune: "Ruda",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "H629",
        Abitanti: "3003",
      },
      {
        Istat: "93037",
        Comune: "Sacile",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33077",
        CodFisco: "H657",
        Abitanti: "20227",
      },
      {
        Istat: "31017",
        Comune: "Sagrado",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34078",
        CodFisco: "H665",
        Abitanti: "2267",
      },
      {
        Istat: "31018",
        Comune: "San Canzian d'Isonzo",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34075",
        CodFisco: "H787",
        Abitanti: "6383",
      },
      {
        Istat: "30099",
        Comune: "San Daniele del Friuli",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33038",
        CodFisco: "H816",
        Abitanti: "8210",
      },
      {
        Istat: "32004",
        Comune: "San Dorligo della Valle - Dolina",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34018",
        CodFisco: "D324",
        Abitanti: "5913",
      },
      {
        Istat: "31019",
        Comune: "San Floriano del Collio",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "H845",
        Abitanti: "811",
      },
      {
        Istat: "93038",
        Comune: "San Giorgio della Richinvelda",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33095",
        CodFisco: "H891",
        Abitanti: "4758",
      },
      {
        Istat: "30100",
        Comune: "San Giorgio di Nogaro",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33058",
        CodFisco: "H895",
        Abitanti: "7755",
      },
      {
        Istat: "30101",
        Comune: "San Giovanni al Natisone",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33048",
        CodFisco: "H906",
        Abitanti: "6192",
      },
      {
        Istat: "30102",
        Comune: "San Leonardo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "H951",
        Abitanti: "1210",
      },
      {
        Istat: "31020",
        Comune: "San Lorenzo Isontino",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "H964",
        Abitanti: "1558",
      },
      {
        Istat: "93039",
        Comune: "San Martino al Tagliamento",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33098",
        CodFisco: "H999",
        Abitanti: "1556",
      },
      {
        Istat: "31021",
        Comune: "San Pier d'Isonzo",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "I082",
        Abitanti: "2017",
      },
      {
        Istat: "30103",
        Comune: "San Pietro al Natisone",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33049",
        CodFisco: "I092",
        Abitanti: "2207",
      },
      {
        Istat: "93040",
        Comune: "San Quirino",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33080",
        CodFisco: "I136",
        Abitanti: "4310",
      },
      {
        Istat: "93041",
        Comune: "San Vito al Tagliamento",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33078",
        CodFisco: "I403",
        Abitanti: "15015",
      },
      {
        Istat: "30105",
        Comune: "San Vito al Torre",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "I404",
        Abitanti: "1358",
      },
      {
        Istat: "30106",
        Comune: "San Vito di Fagagna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "I405",
        Abitanti: "1692",
      },
      {
        Istat: "30104",
        Comune: "Santa Maria La Longa",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "I248",
        Abitanti: "2445",
      },
      {
        Istat: "30107",
        Comune: "Sauris",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "I464",
        Abitanti: "429",
      },
      {
        Istat: "30108",
        Comune: "Savogna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "I478",
        Abitanti: "506",
      },
      {
        Istat: "31022",
        Comune: "Savogna d'Isonzo",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "I479",
        Abitanti: "1739",
      },
      {
        Istat: "30109",
        Comune: "Sedegliano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33039",
        CodFisco: "I562",
        Abitanti: "3926",
      },
      {
        Istat: "93042",
        Comune: "Sequals",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "I621",
        Abitanti: "2258",
      },
      {
        Istat: "93043",
        Comune: "Sesto al Reghena",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33079",
        CodFisco: "I686",
        Abitanti: "6296",
      },
      {
        Istat: "32005",
        Comune: "Sgonico",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34010",
        CodFisco: "I715",
        Abitanti: "2091",
      },
      {
        Istat: "30110",
        Comune: "Socchieve",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "I777",
        Abitanti: "938",
      },
      {
        Istat: "93044",
        Comune: "Spilimbergo",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33097",
        CodFisco: "I904",
        Abitanti: "12220",
      },
      {
        Istat: "31023",
        Comune: "Staranzano",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34079",
        CodFisco: "I939",
        Abitanti: "7257",
      },
      {
        Istat: "30111",
        Comune: "Stregna",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "I974",
        Abitanti: "413",
      },
      {
        Istat: "30112",
        Comune: "Sutrio",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "L018",
        Abitanti: "1376",
      },
      {
        Istat: "30113",
        Comune: "Taipana",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "G736",
        Abitanti: "699",
      },
      {
        Istat: "30114",
        Comune: "Talmassons",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "L039",
        Abitanti: "4167",
      },
      {
        Istat: "30116",
        Comune: "Tarcento",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33017",
        CodFisco: "L050",
        Abitanti: "9148",
      },
      {
        Istat: "30117",
        Comune: "Tarvisio",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "428",
        CAP: "33018",
        CodFisco: "L057",
        Abitanti: "4683",
      },
      {
        Istat: "30118",
        Comune: "Tavagnacco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "L065",
        Abitanti: "14441",
      },
      {
        Istat: "30119",
        Comune: "Teor",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "L101",
        Abitanti: "1997",
      },
      {
        Istat: "30120",
        Comune: "Terzo d'Aquileia",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "L144",
        Abitanti: "2900",
      },
      {
        Istat: "30121",
        Comune: "Tolmezzo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33028",
        CodFisco: "L195",
        Abitanti: "10659",
      },
      {
        Istat: "30122",
        Comune: "Torreano",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "L246",
        Abitanti: "2266",
      },
      {
        Istat: "30123",
        Comune: "Torviscosa",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33050",
        CodFisco: "L309",
        Abitanti: "3024",
      },
      {
        Istat: "93045",
        Comune: "Tramonti di Sopra",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "L324",
        Abitanti: "385",
      },
      {
        Istat: "93046",
        Comune: "Tramonti di Sotto",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "L325",
        Abitanti: "423",
      },
      {
        Istat: "30124",
        Comune: "Trasaghis",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "L335",
        Abitanti: "2337",
      },
      {
        Istat: "93047",
        Comune: "Travesio",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "L347",
        Abitanti: "1861",
      },
      {
        Istat: "30125",
        Comune: "Treppo Carnico",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "L381",
        Abitanti: "659",
      },
      {
        Istat: "30126",
        Comune: "Treppo Grande",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "L382",
        Abitanti: "1765",
      },
      {
        Istat: "30127",
        Comune: "Tricesimo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33019",
        CodFisco: "L421",
        Abitanti: "7721",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34121",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34122",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34123",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34124",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34125",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34126",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34127",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34128",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34129",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34130",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34131",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34132",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34133",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34134",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34135",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34136",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34137",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34138",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34139",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34140",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34141",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34142",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34143",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34144",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34145",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34146",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34147",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34148",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34149",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34150",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "32006",
        Comune: "Trieste",
        Provincia: "TS",
        Regione: "FVG",
        Prefisso: "40",
        CAP: "34151",
        CodFisco: "L424",
        Abitanti: "205535",
      },
      {
        Istat: "30128",
        Comune: "Trivignano Udinese",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33050",
        CodFisco: "L438",
        Abitanti: "1700",
      },
      {
        Istat: "31024",
        Comune: "Turriaco",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "L474",
        Abitanti: "2756",
      },
      {
        Istat: "30129",
        Comune: "Udine",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33100",
        CodFisco: "L483",
        Abitanti: "99627",
      },
      {
        Istat: "93052",
        Comune: "Vajont",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33080",
        CodFisco: "M265",
        Abitanti: "1785",
      },
      {
        Istat: "93048",
        Comune: "Valvasone",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33098",
        CodFisco: "L657",
        Abitanti: "2232",
      },
      {
        Istat: "30130",
        Comune: "Varmo",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33030",
        CodFisco: "L686",
        Abitanti: "2892",
      },
      {
        Istat: "30131",
        Comune: "Venzone",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33010",
        CodFisco: "L743",
        Abitanti: "2223",
      },
      {
        Istat: "30132",
        Comune: "Verzegnis",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "L801",
        Abitanti: "929",
      },
      {
        Istat: "30133",
        Comune: "Villa Santina",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33029",
        CodFisco: "L909",
        Abitanti: "2223",
      },
      {
        Istat: "30134",
        Comune: "Villa Vicentina",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "431",
        CAP: "33059",
        CodFisco: "M034",
        Abitanti: "1405",
      },
      {
        Istat: "31025",
        Comune: "Villesse",
        Provincia: "GO",
        Regione: "FVG",
        Prefisso: "481",
        CAP: "34070",
        CodFisco: "M043",
        Abitanti: "1729",
      },
      {
        Istat: "30135",
        Comune: "Visco",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "432",
        CAP: "33040",
        CodFisco: "M073",
        Abitanti: "786",
      },
      {
        Istat: "93049",
        Comune: "Vito d'Asio",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33090",
        CodFisco: "M085",
        Abitanti: "843",
      },
      {
        Istat: "93050",
        Comune: "Vivaro",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "427",
        CAP: "33099",
        CodFisco: "M096",
        Abitanti: "1388",
      },
      {
        Istat: "93051",
        Comune: "Zoppola",
        Provincia: "PN",
        Regione: "FVG",
        Prefisso: "434",
        CAP: "33080",
        CodFisco: "M190",
        Abitanti: "8565",
      },
      {
        Istat: "30136",
        Comune: "Zuglio",
        Provincia: "UD",
        Regione: "FVG",
        Prefisso: "433",
        CAP: "33020",
        CodFisco: "M200",
        Abitanti: "607",
      },
      {
        Istat: "57001",
        Comune: "Accumoli",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02011",
        CodFisco: "A019",
        Abitanti: "708",
      },
      {
        Istat: "60001",
        Comune: "Acquafondata",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "A032",
        Abitanti: "296",
      },
      {
        Istat: "56001",
        Comune: "Acquapendente",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "763",
        CAP: "01021",
        CodFisco: "A040",
        Abitanti: "5677",
      },
      {
        Istat: "60002",
        Comune: "Acuto",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "A054",
        Abitanti: "1914",
      },
      {
        Istat: "58001",
        Comune: "Affile",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00021",
        CodFisco: "A062",
        Abitanti: "1562",
      },
      {
        Istat: "58002",
        Comune: "Agosta",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "A084",
        Abitanti: "1768",
      },
      {
        Istat: "60003",
        Comune: "Alatri",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03011",
        CodFisco: "A123",
        Abitanti: "29496",
      },
      {
        Istat: "58003",
        Comune: "Albano Laziale",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00041",
        CodFisco: "A132",
        Abitanti: "40516",
      },
      {
        Istat: "58004",
        Comune: "Allumiere",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "766",
        CAP: "00051",
        CodFisco: "A210",
        Abitanti: "4268",
      },
      {
        Istat: "60004",
        Comune: "Alvito",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03041",
        CodFisco: "A244",
        Abitanti: "2885",
      },
      {
        Istat: "60005",
        Comune: "Amaseno",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03021",
        CodFisco: "A256",
        Abitanti: "4415",
      },
      {
        Istat: "57002",
        Comune: "Amatrice",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02012",
        CodFisco: "A258",
        Abitanti: "2717",
      },
      {
        Istat: "60006",
        Comune: "Anagni",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03012",
        CodFisco: "A269",
        Abitanti: "21676",
      },
      {
        Istat: "58005",
        Comune: "Anguillara Sabazia",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00061",
        CodFisco: "A297",
        Abitanti: "18882",
      },
      {
        Istat: "58006",
        Comune: "Anticoli Corrado",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00022",
        CodFisco: "A309",
        Abitanti: "969",
      },
      {
        Istat: "57003",
        Comune: "Antrodoco",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02013",
        CodFisco: "A315",
        Abitanti: "2762",
      },
      {
        Istat: "58007",
        Comune: "Anzio",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00042",
        CodFisco: "A323",
        Abitanti: "55413",
      },
      {
        Istat: "59001",
        Comune: "Aprilia",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "04011",
        CodFisco: "A341",
        Abitanti: "70349",
      },
      {
        Istat: "60007",
        Comune: "Aquino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03031",
        CodFisco: "A348",
        Abitanti: "5247",
      },
      {
        Istat: "60008",
        Comune: "Arce",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03032",
        CodFisco: "A363",
        Abitanti: "5925",
      },
      {
        Istat: "58008",
        Comune: "Arcinazzo Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "A370",
        Abitanti: "1466",
      },
      {
        Istat: "58117",
        Comune: "Ardea",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "M213",
        Abitanti: "42879",
      },
      {
        Istat: "58009",
        Comune: "Ariccia",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "A401",
        Abitanti: "18555",
      },
      {
        Istat: "56002",
        Comune: "Arlena di Castro",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "A412",
        Abitanti: "903",
      },
      {
        Istat: "60009",
        Comune: "Arnara",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "A421",
        Abitanti: "2395",
      },
      {
        Istat: "60010",
        Comune: "Arpino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03033",
        CodFisco: "A433",
        Abitanti: "7552",
      },
      {
        Istat: "58010",
        Comune: "Arsoli",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00023",
        CodFisco: "A446",
        Abitanti: "1670",
      },
      {
        Istat: "58011",
        Comune: "Artena",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00031",
        CodFisco: "A449",
        Abitanti: "13959",
      },
      {
        Istat: "57004",
        Comune: "Ascrea",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "A464",
        Abitanti: "279",
      },
      {
        Istat: "60011",
        Comune: "Atina",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03042",
        CodFisco: "A486",
        Abitanti: "4519",
      },
      {
        Istat: "60012",
        Comune: "Ausonia",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "A502",
        Abitanti: "2636",
      },
      {
        Istat: "56003",
        Comune: "Bagnoregio",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01022",
        CodFisco: "A577",
        Abitanti: "3678",
      },
      {
        Istat: "56004",
        Comune: "Barbarano Romano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "A628",
        Abitanti: "1110",
      },
      {
        Istat: "56006",
        Comune: "Bassano in Teverina",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "A706",
        Abitanti: "1319",
      },
      {
        Istat: "56005",
        Comune: "Bassano Romano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "A704",
        Abitanti: "5049",
      },
      {
        Istat: "59002",
        Comune: "Bassiano",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04010",
        CodFisco: "A707",
        Abitanti: "1651",
      },
      {
        Istat: "58012",
        Comune: "Bellegra",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "A749",
        Abitanti: "3008",
      },
      {
        Istat: "60013",
        Comune: "Belmonte Castello",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "A763",
        Abitanti: "777",
      },
      {
        Istat: "57005",
        Comune: "Belmonte in Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "A765",
        Abitanti: "677",
      },
      {
        Istat: "56007",
        Comune: "Blera",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "A857",
        Abitanti: "3402",
      },
      {
        Istat: "56008",
        Comune: "Bolsena",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01023",
        CodFisco: "A949",
        Abitanti: "4187",
      },
      {
        Istat: "56009",
        Comune: "Bomarzo",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01020",
        CodFisco: "A955",
        Abitanti: "1862",
      },
      {
        Istat: "57006",
        Comune: "Borbona",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "A981",
        Abitanti: "660",
      },
      {
        Istat: "57008",
        Comune: "Borgo Velino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "A996",
        Abitanti: "1004",
      },
      {
        Istat: "57007",
        Comune: "Borgorose",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02021",
        CodFisco: "B008",
        Abitanti: "4644",
      },
      {
        Istat: "60014",
        Comune: "Boville Ernica",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03022",
        CodFisco: "A720",
        Abitanti: "8898",
      },
      {
        Istat: "58013",
        Comune: "Bracciano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00062",
        CodFisco: "B114",
        Abitanti: "18889",
      },
      {
        Istat: "60015",
        Comune: "Broccostella",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "B195",
        Abitanti: "2823",
      },
      {
        Istat: "56010",
        Comune: "Calcata",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "B388",
        Abitanti: "924",
      },
      {
        Istat: "58014",
        Comune: "Camerata Nuova",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "B472",
        Abitanti: "485",
      },
      {
        Istat: "58015",
        Comune: "Campagnano di Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00063",
        CodFisco: "B496",
        Abitanti: "11166",
      },
      {
        Istat: "59003",
        Comune: "Campodimele",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04020",
        CodFisco: "B527",
        Abitanti: "660",
      },
      {
        Istat: "60016",
        Comune: "Campoli Appennino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "B543",
        Abitanti: "1756",
      },
      {
        Istat: "58016",
        Comune: "Canale Monterano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "B576",
        Abitanti: "3966",
      },
      {
        Istat: "56011",
        Comune: "Canepina",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "B597",
        Abitanti: "3212",
      },
      {
        Istat: "56012",
        Comune: "Canino",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01011",
        CodFisco: "B604",
        Abitanti: "5310",
      },
      {
        Istat: "57009",
        Comune: "Cantalice",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02014",
        CodFisco: "B627",
        Abitanti: "2796",
      },
      {
        Istat: "57010",
        Comune: "Cantalupo in Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "B631",
        Abitanti: "1742",
      },
      {
        Istat: "58017",
        Comune: "Canterano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "B635",
        Abitanti: "369",
      },
      {
        Istat: "58018",
        Comune: "Capena",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "B649",
        Abitanti: "9709",
      },
      {
        Istat: "56013",
        Comune: "Capodimonte",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "B663",
        Abitanti: "1797",
      },
      {
        Istat: "56014",
        Comune: "Capranica",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01012",
        CodFisco: "B688",
        Abitanti: "6673",
      },
      {
        Istat: "58019",
        Comune: "Capranica Prenestina",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "B687",
        Abitanti: "375",
      },
      {
        Istat: "56015",
        Comune: "Caprarola",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01032",
        CodFisco: "B691",
        Abitanti: "5715",
      },
      {
        Istat: "56016",
        Comune: "Carbognano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "B735",
        Abitanti: "2066",
      },
      {
        Istat: "58020",
        Comune: "Carpineto Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00032",
        CodFisco: "B828",
        Abitanti: "4714",
      },
      {
        Istat: "60017",
        Comune: "Casalattico",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "B862",
        Abitanti: "656",
      },
      {
        Istat: "60018",
        Comune: "Casalvieri",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03034",
        CodFisco: "B919",
        Abitanti: "3098",
      },
      {
        Istat: "58021",
        Comune: "Casape",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "B932",
        Abitanti: "748",
      },
      {
        Istat: "57011",
        Comune: "Casaprota",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02030",
        CodFisco: "B934",
        Abitanti: "774",
      },
      {
        Istat: "57012",
        Comune: "Casperia",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02041",
        CodFisco: "A472",
        Abitanti: "1241",
      },
      {
        Istat: "60019",
        Comune: "Cassino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03043",
        CodFisco: "C034",
        Abitanti: "33153",
      },
      {
        Istat: "57013",
        Comune: "Castel di Tora",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "C098",
        Abitanti: "310",
      },
      {
        Istat: "58022",
        Comune: "Castel Gandolfo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "C116",
        Abitanti: "9037",
      },
      {
        Istat: "58023",
        Comune: "Castel Madama",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00024",
        CodFisco: "C203",
        Abitanti: "7568",
      },
      {
        Istat: "58025",
        Comune: "Castel San Pietro Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "C266",
        Abitanti: "859",
      },
      {
        Istat: "57015",
        Comune: "Castel Sant'Angelo",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "C268",
        Abitanti: "1289",
      },
      {
        Istat: "56017",
        Comune: "Castel Sant'Elia",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "C269",
        Abitanti: "2642",
      },
      {
        Istat: "59004",
        Comune: "Castelforte",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04021",
        CodFisco: "C104",
        Abitanti: "4505",
      },
      {
        Istat: "60020",
        Comune: "Castelliri",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "C177",
        Abitanti: "3539",
      },
      {
        Istat: "57014",
        Comune: "Castelnuovo di Farfa",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02031",
        CodFisco: "C224",
        Abitanti: "1072",
      },
      {
        Istat: "58024",
        Comune: "Castelnuovo di Porto",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "C237",
        Abitanti: "8886",
      },
      {
        Istat: "60021",
        Comune: "Castelnuovo Parano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "C223",
        Abitanti: "894",
      },
      {
        Istat: "56018",
        Comune: "Castiglione in Teverina",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01024",
        CodFisco: "C315",
        Abitanti: "2421",
      },
      {
        Istat: "60023",
        Comune: "Castro dei Volsci",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "C338",
        Abitanti: "4977",
      },
      {
        Istat: "60022",
        Comune: "Castrocielo",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "C340",
        Abitanti: "4014",
      },
      {
        Istat: "58026",
        Comune: "Cave",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00033",
        CodFisco: "C390",
        Abitanti: "10924",
      },
      {
        Istat: "60024",
        Comune: "Ceccano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03023",
        CodFisco: "C413",
        Abitanti: "23003",
      },
      {
        Istat: "56019",
        Comune: "Celleno",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01020",
        CodFisco: "C446",
        Abitanti: "1362",
      },
      {
        Istat: "56020",
        Comune: "Cellere",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "C447",
        Abitanti: "1280",
      },
      {
        Istat: "60025",
        Comune: "Ceprano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03024",
        CodFisco: "C479",
        Abitanti: "8610",
      },
      {
        Istat: "58027",
        Comune: "Cerreto Laziale",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "C518",
        Abitanti: "1206",
      },
      {
        Istat: "58028",
        Comune: "Cervara di Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "C543",
        Abitanti: "502",
      },
      {
        Istat: "60026",
        Comune: "Cervaro",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03044",
        CodFisco: "C545",
        Abitanti: "7209",
      },
      {
        Istat: "58029",
        Comune: "Cerveteri",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00052",
        CodFisco: "C552",
        Abitanti: "36229",
      },
      {
        Istat: "58118",
        Comune: "Ciampino",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00043",
        CodFisco: "M272",
        Abitanti: "38529",
      },
      {
        Istat: "58030",
        Comune: "Ciciliano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "C677",
        Abitanti: "1459",
      },
      {
        Istat: "58031",
        Comune: "Cineto Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "C702",
        Abitanti: "668",
      },
      {
        Istat: "59005",
        Comune: "Cisterna di Latina",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "04012",
        CodFisco: "C740",
        Abitanti: "35480",
      },
      {
        Istat: "57016",
        Comune: "Cittaducale",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02015",
        CodFisco: "C746",
        Abitanti: "7018",
      },
      {
        Istat: "57017",
        Comune: "Cittareale",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "C749",
        Abitanti: "485",
      },
      {
        Istat: "56021",
        Comune: "Civita Castellana",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01033",
        CodFisco: "C765",
        Abitanti: "16777",
      },
      {
        Istat: "58032",
        Comune: "Civitavecchia",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "766",
        CAP: "00053",
        CodFisco: "C773",
        Abitanti: "52294",
      },
      {
        Istat: "56022",
        Comune: "Civitella d'Agliano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01020",
        CodFisco: "C780",
        Abitanti: "1696",
      },
      {
        Istat: "58033",
        Comune: "Civitella San Paolo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "00060",
        CodFisco: "C784",
        Abitanti: "2009",
      },
      {
        Istat: "60027",
        Comune: "Colfelice",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "C836",
        Abitanti: "1893",
      },
      {
        Istat: "57018",
        Comune: "Collalto Sabino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02022",
        CodFisco: "C841",
        Abitanti: "458",
      },
      {
        Istat: "57019",
        Comune: "Colle di Tora",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "C857",
        Abitanti: "380",
      },
      {
        Istat: "60029",
        Comune: "Colle San Magno",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "C870",
        Abitanti: "755",
      },
      {
        Istat: "58034",
        Comune: "Colleferro",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00034",
        CodFisco: "C858",
        Abitanti: "22142",
      },
      {
        Istat: "57020",
        Comune: "Collegiove",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "C859",
        Abitanti: "190",
      },
      {
        Istat: "60028",
        Comune: "Collepardo",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "C864",
        Abitanti: "985",
      },
      {
        Istat: "57021",
        Comune: "Collevecchio",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02042",
        CodFisco: "C876",
        Abitanti: "1650",
      },
      {
        Istat: "57022",
        Comune: "Colli sul Velino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "C880",
        Abitanti: "513",
      },
      {
        Istat: "58035",
        Comune: "Colonna",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "C900",
        Abitanti: "4016",
      },
      {
        Istat: "57023",
        Comune: "Concerviano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "C946",
        Abitanti: "319",
      },
      {
        Istat: "57024",
        Comune: "Configni",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02040",
        CodFisco: "C959",
        Abitanti: "692",
      },
      {
        Istat: "57025",
        Comune: "Contigliano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02043",
        CodFisco: "C969",
        Abitanti: "3676",
      },
      {
        Istat: "56023",
        Comune: "Corchiano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "C988",
        Abitanti: "3838",
      },
      {
        Istat: "60030",
        Comune: "Coreno Ausonio",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "C998",
        Abitanti: "1691",
      },
      {
        Istat: "59006",
        Comune: "Cori",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "04010",
        CodFisco: "D003",
        Abitanti: "11284",
      },
      {
        Istat: "57026",
        Comune: "Cottanello",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02040",
        CodFisco: "D124",
        Abitanti: "572",
      },
      {
        Istat: "60031",
        Comune: "Esperia",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03045",
        CodFisco: "D440",
        Abitanti: "3978",
      },
      {
        Istat: "56024",
        Comune: "Fabrica di Roma",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01034",
        CodFisco: "D452",
        Abitanti: "8537",
      },
      {
        Istat: "56025",
        Comune: "Faleria",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "D475",
        Abitanti: "2303",
      },
      {
        Istat: "60032",
        Comune: "Falvaterra",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "D483",
        Abitanti: "594",
      },
      {
        Istat: "57027",
        Comune: "Fara in Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02032",
        CodFisco: "D493",
        Abitanti: "13350",
      },
      {
        Istat: "56026",
        Comune: "Farnese",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "D503",
        Abitanti: "1667",
      },
      {
        Istat: "60033",
        Comune: "Ferentino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03013",
        CodFisco: "D539",
        Abitanti: "21258",
      },
      {
        Istat: "57028",
        Comune: "Fiamignano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02023",
        CodFisco: "D560",
        Abitanti: "1525",
      },
      {
        Istat: "58036",
        Comune: "Fiano Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "00065",
        CodFisco: "D561",
        Abitanti: "13978",
      },
      {
        Istat: "58037",
        Comune: "Filacciano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "00060",
        CodFisco: "D586",
        Abitanti: "526",
      },
      {
        Istat: "60034",
        Comune: "Filettino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "D591",
        Abitanti: "550",
      },
      {
        Istat: "60035",
        Comune: "Fiuggi",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03014",
        CodFisco: "A310",
        Abitanti: "9755",
      },
      {
        Istat: "58120",
        Comune: "Fiumicino",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00054",
        CodFisco: "M297",
        Abitanti: "70985",
      },
      {
        Istat: "59007",
        Comune: "Fondi",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04022",
        CodFisco: "D662",
        Abitanti: "37770",
      },
      {
        Istat: "60036",
        Comune: "Fontana Liri",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03035",
        CodFisco: "D667",
        Abitanti: "3063",
      },
      {
        Istat: "58122",
        Comune: "Fonte Nuova",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00013",
        CodFisco: "M309",
        Abitanti: "28620",
      },
      {
        Istat: "60037",
        Comune: "Fontechiari",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "D682",
        Abitanti: "1315",
      },
      {
        Istat: "57029",
        Comune: "Forano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02044",
        CodFisco: "D689",
        Abitanti: "3102",
      },
      {
        Istat: "58038",
        Comune: "Formello",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "D707",
        Abitanti: "12802",
      },
      {
        Istat: "59008",
        Comune: "Formia",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04023",
        CodFisco: "D708",
        Abitanti: "37571",
      },
      {
        Istat: "58039",
        Comune: "Frascati",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00044",
        CodFisco: "D773",
        Abitanti: "21285",
      },
      {
        Istat: "57030",
        Comune: "Frasso Sabino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02030",
        CodFisco: "D785",
        Abitanti: "709",
      },
      {
        Istat: "60038",
        Comune: "Frosinone",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03100",
        CodFisco: "D810",
        Abitanti: "48122",
      },
      {
        Istat: "60039",
        Comune: "Fumone",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "D819",
        Abitanti: "2210",
      },
      {
        Istat: "59009",
        Comune: "Gaeta",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04024",
        CodFisco: "D843",
        Abitanti: "21546",
      },
      {
        Istat: "56027",
        Comune: "Gallese",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01035",
        CodFisco: "D870",
        Abitanti: "2982",
      },
      {
        Istat: "58040",
        Comune: "Gallicano nel Lazio",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00010",
        CodFisco: "D875",
        Abitanti: "6058",
      },
      {
        Istat: "60040",
        Comune: "Gallinaro",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "D881",
        Abitanti: "1279",
      },
      {
        Istat: "58041",
        Comune: "Gavignano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "D945",
        Abitanti: "1987",
      },
      {
        Istat: "58042",
        Comune: "Genazzano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "D964",
        Abitanti: "6036",
      },
      {
        Istat: "58043",
        Comune: "Genzano di Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00045",
        CodFisco: "D972",
        Abitanti: "24364",
      },
      {
        Istat: "58044",
        Comune: "Gerano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00025",
        CodFisco: "D978",
        Abitanti: "1253",
      },
      {
        Istat: "60041",
        Comune: "Giuliano di Roma",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "E057",
        Abitanti: "2373",
      },
      {
        Istat: "58045",
        Comune: "Gorga",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "E091",
        Abitanti: "778",
      },
      {
        Istat: "56028",
        Comune: "Gradoli",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "E126",
        Abitanti: "1483",
      },
      {
        Istat: "56029",
        Comune: "Graffignano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01020",
        CodFisco: "E128",
        Abitanti: "2355",
      },
      {
        Istat: "57031",
        Comune: "Greccio",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02045",
        CodFisco: "E160",
        Abitanti: "1558",
      },
      {
        Istat: "58046",
        Comune: "Grottaferrata",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00046",
        CodFisco: "E204",
        Abitanti: "21039",
      },
      {
        Istat: "56030",
        Comune: "Grotte di Castro",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "763",
        CAP: "01025",
        CodFisco: "E210",
        Abitanti: "2833",
      },
      {
        Istat: "60042",
        Comune: "Guarcino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03016",
        CodFisco: "E236",
        Abitanti: "1700",
      },
      {
        Istat: "58047",
        Comune: "Guidonia Montecelio",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00012",
        CodFisco: "E263",
        Abitanti: "83736",
      },
      {
        Istat: "56031",
        Comune: "Ischia di Castro",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "E330",
        Abitanti: "2412",
      },
      {
        Istat: "60043",
        Comune: "Isola del Liri",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03036",
        CodFisco: "E340",
        Abitanti: "11991",
      },
      {
        Istat: "59010",
        Comune: "Itri",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04020",
        CodFisco: "E375",
        Abitanti: "10369",
      },
      {
        Istat: "58048",
        Comune: "Jenne",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "E382",
        Abitanti: "407",
      },
      {
        Istat: "58049",
        Comune: "Labico",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "E392",
        Abitanti: "5982",
      },
      {
        Istat: "57032",
        Comune: "Labro",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "E393",
        Abitanti: "370",
      },
      {
        Istat: "58116",
        Comune: "Ladispoli",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00055",
        CodFisco: "M212",
        Abitanti: "41035",
      },
      {
        Istat: "58050",
        Comune: "Lanuvio",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "C767",
        Abitanti: "13147",
      },
      {
        Istat: "58115",
        Comune: "Lariano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "M207",
        Abitanti: "13011",
      },
      {
        Istat: "56032",
        Comune: "Latera",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "E467",
        Abitanti: "951",
      },
      {
        Istat: "59011",
        Comune: "Latina",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04100",
        CodFisco: "E472",
        Abitanti: "119804",
      },
      {
        Istat: "59012",
        Comune: "Lenola",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04025",
        CodFisco: "E527",
        Abitanti: "4180",
      },
      {
        Istat: "57033",
        Comune: "Leonessa",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02016",
        CodFisco: "E535",
        Abitanti: "2604",
      },
      {
        Istat: "58051",
        Comune: "Licenza",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00026",
        CodFisco: "E576",
        Abitanti: "1021",
      },
      {
        Istat: "57034",
        Comune: "Longone Sabino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "E681",
        Abitanti: "604",
      },
      {
        Istat: "56033",
        Comune: "Lubriano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01020",
        CodFisco: "E713",
        Abitanti: "941",
      },
      {
        Istat: "59013",
        Comune: "Maenza",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04010",
        CodFisco: "E798",
        Abitanti: "3169",
      },
      {
        Istat: "58052",
        Comune: "Magliano Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "E813",
        Abitanti: "1519",
      },
      {
        Istat: "57035",
        Comune: "Magliano Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "744",
        CAP: "02046",
        CodFisco: "E812",
        Abitanti: "3892",
      },
      {
        Istat: "58053",
        Comune: "Mandela",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "B632",
        Abitanti: "947",
      },
      {
        Istat: "58054",
        Comune: "Manziana",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00066",
        CodFisco: "E900",
        Abitanti: "6951",
      },
      {
        Istat: "58055",
        Comune: "Marano Equo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "E908",
        Abitanti: "825",
      },
      {
        Istat: "58056",
        Comune: "Marcellina",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "E924",
        Abitanti: "7101",
      },
      {
        Istat: "57036",
        Comune: "Marcetelli",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "E927",
        Abitanti: "111",
      },
      {
        Istat: "58057",
        Comune: "Marino",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00047",
        CodFisco: "E958",
        Abitanti: "39976",
      },
      {
        Istat: "56034",
        Comune: "Marta",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "E978",
        Abitanti: "3553",
      },
      {
        Istat: "58058",
        Comune: "Mazzano Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "F064",
        Abitanti: "3064",
      },
      {
        Istat: "58059",
        Comune: "Mentana",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00013",
        CodFisco: "F127",
        Abitanti: "21602",
      },
      {
        Istat: "57037",
        Comune: "Micigliano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "F193",
        Abitanti: "142",
      },
      {
        Istat: "59014",
        Comune: "Minturno",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04026",
        CodFisco: "F224",
        Abitanti: "19059",
      },
      {
        Istat: "57038",
        Comune: "Mompeo",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "F319",
        Abitanti: "546",
      },
      {
        Istat: "56035",
        Comune: "Montalto di Castro",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "766",
        CAP: "01014",
        CodFisco: "F419",
        Abitanti: "8976",
      },
      {
        Istat: "57039",
        Comune: "Montasola",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02040",
        CodFisco: "F430",
        Abitanti: "420",
      },
      {
        Istat: "58060",
        Comune: "Monte Compatri",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "F477",
        Abitanti: "10716",
      },
      {
        Istat: "58064",
        Comune: "Monte Porzio Catone",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "F590",
        Abitanti: "8989",
      },
      {
        Istat: "56037",
        Comune: "Monte Romano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "766",
        CAP: "01010",
        CodFisco: "F603",
        Abitanti: "1994",
      },
      {
        Istat: "59015",
        Comune: "Monte San Biagio",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04020",
        CodFisco: "F616",
        Abitanti: "6188",
      },
      {
        Istat: "60044",
        Comune: "Monte San Giovanni Campano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03025",
        CodFisco: "F620",
        Abitanti: "12796",
      },
      {
        Istat: "57043",
        Comune: "Monte San Giovanni in Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "F619",
        Abitanti: "772",
      },
      {
        Istat: "57040",
        Comune: "Montebuono",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "F446",
        Abitanti: "938",
      },
      {
        Istat: "56036",
        Comune: "Montefiascone",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01027",
        CodFisco: "F499",
        Abitanti: "13712",
      },
      {
        Istat: "58061",
        Comune: "Monteflavio",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "F504",
        Abitanti: "1420",
      },
      {
        Istat: "58062",
        Comune: "Montelanico",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "F534",
        Abitanti: "2156",
      },
      {
        Istat: "57041",
        Comune: "Monteleone Sabino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02033",
        CodFisco: "F541",
        Abitanti: "1278",
      },
      {
        Istat: "58063",
        Comune: "Montelibretti",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "F545",
        Abitanti: "5130",
      },
      {
        Istat: "57042",
        Comune: "Montenero Sabino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "F579",
        Abitanti: "310",
      },
      {
        Istat: "56038",
        Comune: "Monterosi",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "F606",
        Abitanti: "4082",
      },
      {
        Istat: "58065",
        Comune: "Monterotondo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00015",
        CodFisco: "F611",
        Abitanti: "39588",
      },
      {
        Istat: "57044",
        Comune: "Montopoli di Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02034",
        CodFisco: "F687",
        Abitanti: "4274",
      },
      {
        Istat: "58066",
        Comune: "Montorio Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "F692",
        Abitanti: "2035",
      },
      {
        Istat: "58067",
        Comune: "Moricone",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "F730",
        Abitanti: "2748",
      },
      {
        Istat: "58068",
        Comune: "Morlupo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00067",
        CodFisco: "F734",
        Abitanti: "8486",
      },
      {
        Istat: "60045",
        Comune: "Morolo",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03017",
        CodFisco: "F740",
        Abitanti: "3299",
      },
      {
        Istat: "57045",
        Comune: "Morro Reatino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "F746",
        Abitanti: "371",
      },
      {
        Istat: "58069",
        Comune: "Nazzano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "00060",
        CodFisco: "F857",
        Abitanti: "1368",
      },
      {
        Istat: "58070",
        Comune: "Nemi",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "F865",
        Abitanti: "2026",
      },
      {
        Istat: "56039",
        Comune: "Nepi",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01036",
        CodFisco: "F868",
        Abitanti: "9684",
      },
      {
        Istat: "58071",
        Comune: "Nerola",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00017",
        CodFisco: "F871",
        Abitanti: "1811",
      },
      {
        Istat: "57046",
        Comune: "Nespolo",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "F876",
        Abitanti: "281",
      },
      {
        Istat: "58072",
        Comune: "Nettuno",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00048",
        CodFisco: "F880",
        Abitanti: "47332",
      },
      {
        Istat: "59016",
        Comune: "Norma",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04010",
        CodFisco: "F937",
        Abitanti: "4103",
      },
      {
        Istat: "58073",
        Comune: "Olevano Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00035",
        CodFisco: "G022",
        Abitanti: "6914",
      },
      {
        Istat: "56040",
        Comune: "Onano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "763",
        CAP: "01010",
        CodFisco: "G065",
        Abitanti: "1040",
      },
      {
        Istat: "56041",
        Comune: "Oriolo Romano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "01010",
        CodFisco: "G111",
        Abitanti: "3759",
      },
      {
        Istat: "56042",
        Comune: "Orte",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01028",
        CodFisco: "G135",
        Abitanti: "9069",
      },
      {
        Istat: "57047",
        Comune: "Orvinio",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02035",
        CodFisco: "B595",
        Abitanti: "472",
      },
      {
        Istat: "57048",
        Comune: "Paganico Sabino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "G232",
        Abitanti: "180",
      },
      {
        Istat: "58074",
        Comune: "Palestrina",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00036",
        CodFisco: "G274",
        Abitanti: "21602",
      },
      {
        Istat: "60046",
        Comune: "Paliano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03018",
        CodFisco: "G276",
        Abitanti: "8330",
      },
      {
        Istat: "58075",
        Comune: "Palombara Sabina",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00018",
        CodFisco: "G293",
        Abitanti: "12991",
      },
      {
        Istat: "60047",
        Comune: "Pastena",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03020",
        CodFisco: "G362",
        Abitanti: "1534",
      },
      {
        Istat: "60048",
        Comune: "Patrica",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "G374",
        Abitanti: "3147",
      },
      {
        Istat: "58076",
        Comune: "Percile",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "G444",
        Abitanti: "238",
      },
      {
        Istat: "57049",
        Comune: "Pescorocchiano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02024",
        CodFisco: "G498",
        Abitanti: "2290",
      },
      {
        Istat: "60049",
        Comune: "Pescosolido",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "G500",
        Abitanti: "1582",
      },
      {
        Istat: "57050",
        Comune: "Petrella Salto",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02025",
        CodFisco: "G513",
        Abitanti: "1271",
      },
      {
        Istat: "56043",
        Comune: "Piansano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "G571",
        Abitanti: "2189",
      },
      {
        Istat: "60050",
        Comune: "Picinisco",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "G591",
        Abitanti: "1256",
      },
      {
        Istat: "60051",
        Comune: "Pico",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03020",
        CodFisco: "G592",
        Abitanti: "3065",
      },
      {
        Istat: "60052",
        Comune: "Piedimonte San Germano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "G598",
        Abitanti: "6267",
      },
      {
        Istat: "60053",
        Comune: "Piglio",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "G659",
        Abitanti: "4775",
      },
      {
        Istat: "60054",
        Comune: "Pignataro Interamna",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "G662",
        Abitanti: "2595",
      },
      {
        Istat: "58077",
        Comune: "Pisoniano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00020",
        CodFisco: "G704",
        Abitanti: "829",
      },
      {
        Istat: "60055",
        Comune: "Pofi",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03026",
        CodFisco: "G749",
        Abitanti: "4423",
      },
      {
        Istat: "57051",
        Comune: "Poggio Bustone",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02018",
        CodFisco: "G756",
        Abitanti: "2163",
      },
      {
        Istat: "57052",
        Comune: "Poggio Catino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "G757",
        Abitanti: "1377",
      },
      {
        Istat: "57053",
        Comune: "Poggio Mirteto",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02047",
        CodFisco: "G763",
        Abitanti: "6157",
      },
      {
        Istat: "57054",
        Comune: "Poggio Moiano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02037",
        CodFisco: "G764",
        Abitanti: "2959",
      },
      {
        Istat: "57055",
        Comune: "Poggio Nativo",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02030",
        CodFisco: "G765",
        Abitanti: "2527",
      },
      {
        Istat: "57056",
        Comune: "Poggio San Lorenzo",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02030",
        CodFisco: "G770",
        Abitanti: "589",
      },
      {
        Istat: "58078",
        Comune: "Poli",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00010",
        CodFisco: "G784",
        Abitanti: "2479",
      },
      {
        Istat: "58079",
        Comune: "Pomezia",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "G811",
        Abitanti: "61106",
      },
      {
        Istat: "60056",
        Comune: "Pontecorvo",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03037",
        CodFisco: "G838",
        Abitanti: "13388",
      },
      {
        Istat: "59017",
        Comune: "Pontinia",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04014",
        CodFisco: "G865",
        Abitanti: "14209",
      },
      {
        Istat: "59018",
        Comune: "Ponza",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04027",
        CodFisco: "G871",
        Abitanti: "3360",
      },
      {
        Istat: "58080",
        Comune: "Ponzano Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "00060",
        CodFisco: "G874",
        Abitanti: "1183",
      },
      {
        Istat: "57057",
        Comune: "Posta",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02019",
        CodFisco: "G934",
        Abitanti: "724",
      },
      {
        Istat: "60057",
        Comune: "Posta Fibreno",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "G935",
        Abitanti: "1216",
      },
      {
        Istat: "57058",
        Comune: "Pozzaglia Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02030",
        CodFisco: "G951",
        Abitanti: "368",
      },
      {
        Istat: "59019",
        Comune: "Priverno",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04015",
        CodFisco: "G698",
        Abitanti: "14369",
      },
      {
        Istat: "56044",
        Comune: "Proceno",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "763",
        CAP: "01020",
        CodFisco: "H071",
        Abitanti: "623",
      },
      {
        Istat: "59020",
        Comune: "Prossedi",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04010",
        CodFisco: "H076",
        Abitanti: "1238",
      },
      {
        Istat: "58081",
        Comune: "Riano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "H267",
        Abitanti: "9902",
      },
      {
        Istat: "57059",
        Comune: "Rieti",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02100",
        CodFisco: "H282",
        Abitanti: "47774",
      },
      {
        Istat: "58082",
        Comune: "Rignano Flaminio",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "00068",
        CodFisco: "H288",
        Abitanti: "9740",
      },
      {
        Istat: "58083",
        Comune: "Riofreddo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "H300",
        Abitanti: "790",
      },
      {
        Istat: "60058",
        Comune: "Ripi",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03027",
        CodFisco: "H324",
        Abitanti: "5494",
      },
      {
        Istat: "57060",
        Comune: "Rivodutri",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02010",
        CodFisco: "H354",
        Abitanti: "1310",
      },
      {
        Istat: "58084",
        Comune: "Rocca Canterano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "H387",
        Abitanti: "202",
      },
      {
        Istat: "60059",
        Comune: "Rocca d'Arce",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "H393",
        Abitanti: "990",
      },
      {
        Istat: "58085",
        Comune: "Rocca di Cave",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "H401",
        Abitanti: "385",
      },
      {
        Istat: "58086",
        Comune: "Rocca di Papa",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "H404",
        Abitanti: "16149",
      },
      {
        Istat: "59022",
        Comune: "Rocca Massima",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "04010",
        CodFisco: "H421",
        Abitanti: "1088",
      },
      {
        Istat: "58088",
        Comune: "Rocca Priora",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00040",
        CodFisco: "H432",
        Abitanti: "11987",
      },
      {
        Istat: "58089",
        Comune: "Rocca Santo Stefano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "H441",
        Abitanti: "1040",
      },
      {
        Istat: "57062",
        Comune: "Rocca Sinibalda",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02026",
        CodFisco: "H446",
        Abitanti: "844",
      },
      {
        Istat: "58087",
        Comune: "Roccagiovine",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "H411",
        Abitanti: "290",
      },
      {
        Istat: "59021",
        Comune: "Roccagorga",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04010",
        CodFisco: "H413",
        Abitanti: "4766",
      },
      {
        Istat: "57061",
        Comune: "Roccantica",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "H427",
        Abitanti: "627",
      },
      {
        Istat: "60060",
        Comune: "Roccasecca",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03038",
        CodFisco: "H443",
        Abitanti: "7583",
      },
      {
        Istat: "59023",
        Comune: "Roccasecca dei Volsci",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04010",
        CodFisco: "H444",
        Abitanti: "1144",
      },
      {
        Istat: "58090",
        Comune: "Roiate",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "H494",
        Abitanti: "770",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00118",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00119",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00120",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00121",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00122",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00123",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00124",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00125",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00126",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00127",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00128",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00129",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00130",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00131",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00132",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00133",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00134",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00135",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00136",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00137",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00138",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00139",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00140",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00141",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00142",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00143",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00144",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00145",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00146",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00147",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00148",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00149",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00150",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00151",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00152",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00153",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00154",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00155",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00156",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00157",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00158",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00159",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00160",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00161",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00162",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00163",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00164",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00165",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00166",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00167",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00168",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00169",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00170",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00171",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00172",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00173",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00174",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00175",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00176",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00177",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00178",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00179",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00180",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00181",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00182",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00183",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00184",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00185",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00186",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00187",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00188",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00189",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00190",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00191",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00192",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00193",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00194",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00195",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00196",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00197",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00198",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "58091",
        Comune: "Roma",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00199",
        CodFisco: "H501",
        Abitanti: "2761477",
      },
      {
        Istat: "56045",
        Comune: "Ronciglione",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01037",
        CodFisco: "H534",
        Abitanti: "8908",
      },
      {
        Istat: "58092",
        Comune: "Roviano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00027",
        CodFisco: "H618",
        Abitanti: "1429",
      },
      {
        Istat: "59024",
        Comune: "Sabaudia",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04016",
        CodFisco: "H647",
        Abitanti: "19664",
      },
      {
        Istat: "58093",
        Comune: "Sacrofano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00060",
        CodFisco: "H658",
        Abitanti: "7508",
      },
      {
        Istat: "57063",
        Comune: "Salisano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "H713",
        Abitanti: "565",
      },
      {
        Istat: "58094",
        Comune: "Sambuci",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "H745",
        Abitanti: "966",
      },
      {
        Istat: "60061",
        Comune: "San Biagio Saracinisco",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "H779",
        Abitanti: "364",
      },
      {
        Istat: "58119",
        Comune: "San Cesareo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "M295",
        Abitanti: "14175",
      },
      {
        Istat: "60062",
        Comune: "San Donato Val di Comino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03046",
        CodFisco: "H824",
        Abitanti: "2133",
      },
      {
        Istat: "59025",
        Comune: "San Felice Circeo",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04017",
        CodFisco: "H836",
        Abitanti: "8603",
      },
      {
        Istat: "60063",
        Comune: "San Giorgio a Liri",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03047",
        CodFisco: "H880",
        Abitanti: "3168",
      },
      {
        Istat: "60064",
        Comune: "San Giovanni Incarico",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03028",
        CodFisco: "H917",
        Abitanti: "3395",
      },
      {
        Istat: "58095",
        Comune: "San Gregorio da Sassola",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "H942",
        Abitanti: "1578",
      },
      {
        Istat: "56047",
        Comune: "San Lorenzo Nuovo",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "763",
        CAP: "01020",
        CodFisco: "H969",
        Abitanti: "2193",
      },
      {
        Istat: "58096",
        Comune: "San Polo dei Cavalieri",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "I125",
        Abitanti: "2973",
      },
      {
        Istat: "58100",
        Comune: "San Vito Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00030",
        CodFisco: "I400",
        Abitanti: "3429",
      },
      {
        Istat: "60070",
        Comune: "San Vittore del Lazio",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "I408",
        Abitanti: "2735",
      },
      {
        Istat: "58097",
        Comune: "Santa Marinella",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "766",
        CAP: "00058",
        CodFisco: "I255",
        Abitanti: "18397",
      },
      {
        Istat: "60065",
        Comune: "Sant'Ambrogio sul Garigliano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "I256",
        Abitanti: "976",
      },
      {
        Istat: "60066",
        Comune: "Sant'Andrea del Garigliano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "I265",
        Abitanti: "1601",
      },
      {
        Istat: "58098",
        Comune: "Sant'Angelo Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00010",
        CodFisco: "I284",
        Abitanti: "4778",
      },
      {
        Istat: "60067",
        Comune: "Sant'Apollinare",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03048",
        CodFisco: "I302",
        Abitanti: "2015",
      },
      {
        Istat: "60068",
        Comune: "Sant'Elia Fiumerapido",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03049",
        CodFisco: "I321",
        Abitanti: "6283",
      },
      {
        Istat: "59026",
        Comune: "Santi Cosma e Damiano",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04020",
        CodFisco: "I339",
        Abitanti: "6886",
      },
      {
        Istat: "60069",
        Comune: "Santopadre",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "I351",
        Abitanti: "1450",
      },
      {
        Istat: "58099",
        Comune: "Sant'Oreste",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "00060",
        CodFisco: "I352",
        Abitanti: "3870",
      },
      {
        Istat: "58101",
        Comune: "Saracinesco",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "I424",
        Abitanti: "169",
      },
      {
        Istat: "57064",
        Comune: "Scandriglia",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02038",
        CodFisco: "I499",
        Abitanti: "3140",
      },
      {
        Istat: "58102",
        Comune: "Segni",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00037",
        CodFisco: "I573",
        Abitanti: "9439",
      },
      {
        Istat: "57065",
        Comune: "Selci",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "I581",
        Abitanti: "1112",
      },
      {
        Istat: "59027",
        Comune: "Sermoneta",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04013",
        CodFisco: "I634",
        Abitanti: "9156",
      },
      {
        Istat: "60071",
        Comune: "Serrone",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "I669",
        Abitanti: "3138",
      },
      {
        Istat: "60072",
        Comune: "Settefrati",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "I697",
        Abitanti: "809",
      },
      {
        Istat: "59028",
        Comune: "Sezze",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04018",
        CodFisco: "I712",
        Abitanti: "24790",
      },
      {
        Istat: "60073",
        Comune: "Sgurgola",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "I716",
        Abitanti: "2709",
      },
      {
        Istat: "59029",
        Comune: "Sonnino",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04010",
        CodFisco: "I832",
        Abitanti: "7267",
      },
      {
        Istat: "60074",
        Comune: "Sora",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03039",
        CodFisco: "I838",
        Abitanti: "26589",
      },
      {
        Istat: "56048",
        Comune: "Soriano nel Cimino",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01038",
        CodFisco: "I855",
        Abitanti: "8722",
      },
      {
        Istat: "59030",
        Comune: "Sperlonga",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04029",
        CodFisco: "I892",
        Abitanti: "3277",
      },
      {
        Istat: "59031",
        Comune: "Spigno Saturnia",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04020",
        CodFisco: "I902",
        Abitanti: "2962",
      },
      {
        Istat: "57066",
        Comune: "Stimigliano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02048",
        CodFisco: "I959",
        Abitanti: "2206",
      },
      {
        Istat: "60075",
        Comune: "Strangolagalli",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "I973",
        Abitanti: "2544",
      },
      {
        Istat: "58103",
        Comune: "Subiaco",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00028",
        CodFisco: "I992",
        Abitanti: "9406",
      },
      {
        Istat: "60076",
        Comune: "Supino",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03019",
        CodFisco: "L009",
        Abitanti: "4958",
      },
      {
        Istat: "56049",
        Comune: "Sutri",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01015",
        CodFisco: "L017",
        Abitanti: "6671",
      },
      {
        Istat: "57067",
        Comune: "Tarano",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02040",
        CodFisco: "L046",
        Abitanti: "1495",
      },
      {
        Istat: "56050",
        Comune: "Tarquinia",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "766",
        CAP: "01016",
        CodFisco: "D024",
        Abitanti: "16724",
      },
      {
        Istat: "60077",
        Comune: "Terelle",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "L105",
        Abitanti: "487",
      },
      {
        Istat: "59032",
        Comune: "Terracina",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "773",
        CAP: "04019",
        CodFisco: "L120",
        Abitanti: "44480",
      },
      {
        Istat: "56051",
        Comune: "Tessennano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "L150",
        Abitanti: "367",
      },
      {
        Istat: "58104",
        Comune: "Tivoli",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00019",
        CodFisco: "L182",
        Abitanti: "56531",
      },
      {
        Istat: "57068",
        Comune: "Toffia",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02039",
        CodFisco: "L189",
        Abitanti: "1085",
      },
      {
        Istat: "58105",
        Comune: "Tolfa",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "766",
        CAP: "00059",
        CodFisco: "L192",
        Abitanti: "5252",
      },
      {
        Istat: "60078",
        Comune: "Torre Cajetani",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "L243",
        Abitanti: "1464",
      },
      {
        Istat: "57070",
        Comune: "Torri in Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02049",
        CodFisco: "L286",
        Abitanti: "1310",
      },
      {
        Istat: "60079",
        Comune: "Torrice",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "L290",
        Abitanti: "4647",
      },
      {
        Istat: "57069",
        Comune: "Torricella in Sabina",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02030",
        CodFisco: "L293",
        Abitanti: "1404",
      },
      {
        Istat: "58106",
        Comune: "Torrita Tiberina",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "00060",
        CodFisco: "L302",
        Abitanti: "1076",
      },
      {
        Istat: "60080",
        Comune: "Trevi nel Lazio",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "L398",
        Abitanti: "1754",
      },
      {
        Istat: "58107",
        Comune: "Trevignano Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00069",
        CodFisco: "L401",
        Abitanti: "5949",
      },
      {
        Istat: "60081",
        Comune: "Trivigliano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "L437",
        Abitanti: "1743",
      },
      {
        Istat: "57071",
        Comune: "Turania",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "G507",
        Abitanti: "247",
      },
      {
        Istat: "56052",
        Comune: "Tuscania",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01017",
        CodFisco: "L310",
        Abitanti: "8300",
      },
      {
        Istat: "57072",
        Comune: "Vacone",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "746",
        CAP: "02040",
        CodFisco: "L525",
        Abitanti: "265",
      },
      {
        Istat: "56053",
        Comune: "Valentano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01018",
        CodFisco: "L569",
        Abitanti: "2975",
      },
      {
        Istat: "60082",
        Comune: "Vallecorsa",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "L598",
        Abitanti: "2844",
      },
      {
        Istat: "60083",
        Comune: "Vallemaio",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "L605",
        Abitanti: "984",
      },
      {
        Istat: "58108",
        Comune: "Vallepietra",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "L611",
        Abitanti: "308",
      },
      {
        Istat: "56054",
        Comune: "Vallerano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "L612",
        Abitanti: "2667",
      },
      {
        Istat: "60084",
        Comune: "Vallerotonda",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "L614",
        Abitanti: "1736",
      },
      {
        Istat: "58109",
        Comune: "Vallinfreda",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "L625",
        Abitanti: "316",
      },
      {
        Istat: "58110",
        Comune: "Valmontone",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00038",
        CodFisco: "L639",
        Abitanti: "15469",
      },
      {
        Istat: "57073",
        Comune: "Varco Sabino",
        Provincia: "RI",
        Regione: "LAZ",
        Prefisso: "765",
        CAP: "02020",
        CodFisco: "L676",
        Abitanti: "221",
      },
      {
        Istat: "56055",
        Comune: "Vasanello",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "A701",
        Abitanti: "4249",
      },
      {
        Istat: "56056",
        Comune: "Vejano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "L713",
        Abitanti: "2337",
      },
      {
        Istat: "58111",
        Comune: "Velletri",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00049",
        CodFisco: "L719",
        Abitanti: "53544",
      },
      {
        Istat: "59033",
        Comune: "Ventotene",
        Provincia: "LT",
        Regione: "LAZ",
        Prefisso: "771",
        CAP: "04020",
        CodFisco: "L742",
        Abitanti: "745",
      },
      {
        Istat: "60085",
        Comune: "Veroli",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03029",
        CodFisco: "L780",
        Abitanti: "20798",
      },
      {
        Istat: "56057",
        Comune: "Vetralla",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01019",
        CodFisco: "L814",
        Abitanti: "13508",
      },
      {
        Istat: "60086",
        Comune: "Vicalvi",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "L836",
        Abitanti: "840",
      },
      {
        Istat: "60087",
        Comune: "Vico nel Lazio",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03010",
        CodFisco: "L843",
        Abitanti: "2314",
      },
      {
        Istat: "58112",
        Comune: "Vicovaro",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00029",
        CodFisco: "L851",
        Abitanti: "4108",
      },
      {
        Istat: "56058",
        Comune: "Vignanello",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01039",
        CodFisco: "L882",
        Abitanti: "4823",
      },
      {
        Istat: "60088",
        Comune: "Villa Latina",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "A081",
        Abitanti: "1240",
      },
      {
        Istat: "56046",
        Comune: "Villa San Giovanni in Tuscia",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01010",
        CodFisco: "H913",
        Abitanti: "1364",
      },
      {
        Istat: "60089",
        Comune: "Villa Santa Lucia",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03030",
        CodFisco: "L905",
        Abitanti: "2696",
      },
      {
        Istat: "60090",
        Comune: "Villa Santo Stefano",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "775",
        CAP: "03020",
        CodFisco: "I364",
        Abitanti: "1750",
      },
      {
        Istat: "56059",
        Comune: "Viterbo",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01100",
        CodFisco: "M082",
        Abitanti: "63597",
      },
      {
        Istat: "60091",
        Comune: "Viticuso",
        Provincia: "FR",
        Regione: "LAZ",
        Prefisso: "776",
        CAP: "03040",
        CodFisco: "M083",
        Abitanti: "388",
      },
      {
        Istat: "56060",
        Comune: "Vitorchiano",
        Provincia: "VT",
        Regione: "LAZ",
        Prefisso: "761",
        CAP: "01030",
        CodFisco: "M086",
        Abitanti: "4844",
      },
      {
        Istat: "58113",
        Comune: "Vivaro Romano",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "774",
        CAP: "00020",
        CodFisco: "M095",
        Abitanti: "192",
      },
      {
        Istat: "58114",
        Comune: "Zagarolo",
        Provincia: "RM",
        Regione: "LAZ",
        Prefisso: "6",
        CAP: "00039",
        CodFisco: "M141",
        Abitanti: "17628",
      },
      {
        Istat: "8001",
        Comune: "Airole",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18030",
        CodFisco: "A111",
        Abitanti: "497",
      },
      {
        Istat: "9001",
        Comune: "Alassio",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17021",
        CodFisco: "A122",
        Abitanti: "11312",
      },
      {
        Istat: "9002",
        Comune: "Albenga",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17031",
        CodFisco: "A145",
        Abitanti: "24378",
      },
      {
        Istat: "9004",
        Comune: "Albisola Superiore",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17011",
        CodFisco: "A166",
        Abitanti: "10581",
      },
      {
        Istat: "9003",
        Comune: "Albissola Marina",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17012",
        CodFisco: "A165",
        Abitanti: "5617",
      },
      {
        Istat: "9005",
        Comune: "Altare",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17041",
        CodFisco: "A226",
        Abitanti: "2156",
      },
      {
        Istat: "11001",
        Comune: "Ameglia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19031",
        CodFisco: "A261",
        Abitanti: "4541",
      },
      {
        Istat: "9006",
        Comune: "Andora",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17051",
        CodFisco: "A278",
        Abitanti: "7657",
      },
      {
        Istat: "8002",
        Comune: "Apricale",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18035",
        CodFisco: "A338",
        Abitanti: "582",
      },
      {
        Istat: "8003",
        Comune: "Aquila d'Arroscia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "A344",
        Abitanti: "177",
      },
      {
        Istat: "11002",
        Comune: "Arcola",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19021",
        CodFisco: "A373",
        Abitanti: "10507",
      },
      {
        Istat: "10001",
        Comune: "Arenzano",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16011",
        CodFisco: "A388",
        Abitanti: "11724",
      },
      {
        Istat: "8004",
        Comune: "Armo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18026",
        CodFisco: "A418",
        Abitanti: "121",
      },
      {
        Istat: "9007",
        Comune: "Arnasco",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17032",
        CodFisco: "A422",
        Abitanti: "621",
      },
      {
        Istat: "8005",
        Comune: "Aurigo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "A499",
        Abitanti: "357",
      },
      {
        Istat: "10002",
        Comune: "Avegno",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "A506",
        Abitanti: "2527",
      },
      {
        Istat: "8006",
        Comune: "Badalucco",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18010",
        CodFisco: "A536",
        Abitanti: "1221",
      },
      {
        Istat: "8007",
        Comune: "Bajardo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18031",
        CodFisco: "A581",
        Abitanti: "342",
      },
      {
        Istat: "9008",
        Comune: "Balestrino",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17020",
        CodFisco: "A593",
        Abitanti: "597",
      },
      {
        Istat: "9009",
        Comune: "Bardineto",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17057",
        CodFisco: "A647",
        Abitanti: "692",
      },
      {
        Istat: "10003",
        Comune: "Bargagli",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16021",
        CodFisco: "A658",
        Abitanti: "2829",
      },
      {
        Istat: "9010",
        Comune: "Bergeggi",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17028",
        CodFisco: "A796",
        Abitanti: "1163",
      },
      {
        Istat: "11003",
        Comune: "Beverino",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "A836",
        Abitanti: "2418",
      },
      {
        Istat: "10004",
        Comune: "Bogliasco",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16031",
        CodFisco: "A922",
        Abitanti: "4529",
      },
      {
        Istat: "9011",
        Comune: "Boissano",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17054",
        CodFisco: "A931",
        Abitanti: "2432",
      },
      {
        Istat: "11004",
        Comune: "Bolano",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "A932",
        Abitanti: "7910",
      },
      {
        Istat: "11005",
        Comune: "Bonassola",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19011",
        CodFisco: "A961",
        Abitanti: "962",
      },
      {
        Istat: "8008",
        Comune: "Bordighera",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18012",
        CodFisco: "A984",
        Abitanti: "10746",
      },
      {
        Istat: "8009",
        Comune: "Borghetto d'Arroscia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "A993",
        Abitanti: "476",
      },
      {
        Istat: "11006",
        Comune: "Borghetto di Vara",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "A992",
        Abitanti: "991",
      },
      {
        Istat: "9012",
        Comune: "Borghetto Santo Spirito",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17052",
        CodFisco: "A999",
        Abitanti: "5261",
      },
      {
        Istat: "9013",
        Comune: "Borgio Verezzi",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17022",
        CodFisco: "B005",
        Abitanti: "2402",
      },
      {
        Istat: "8010",
        Comune: "Borgomaro",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18021",
        CodFisco: "B020",
        Abitanti: "884",
      },
      {
        Istat: "9014",
        Comune: "Bormida",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17045",
        CodFisco: "B048",
        Abitanti: "426",
      },
      {
        Istat: "10005",
        Comune: "Borzonasca",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16041",
        CodFisco: "B067",
        Abitanti: "2206",
      },
      {
        Istat: "11007",
        Comune: "Brugnato",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "B214",
        Abitanti: "1276",
      },
      {
        Istat: "10006",
        Comune: "Busalla",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16012",
        CodFisco: "B282",
        Abitanti: "5848",
      },
      {
        Istat: "9015",
        Comune: "Cairo Montenotte",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17014",
        CodFisco: "B369",
        Abitanti: "13695",
      },
      {
        Istat: "11008",
        Comune: "Calice al Cornoviglio",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "B410",
        Abitanti: "1177",
      },
      {
        Istat: "9016",
        Comune: "Calice Ligure",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17020",
        CodFisco: "B409",
        Abitanti: "1691",
      },
      {
        Istat: "9017",
        Comune: "Calizzano",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17057",
        CodFisco: "B416",
        Abitanti: "1586",
      },
      {
        Istat: "10007",
        Comune: "Camogli",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16032",
        CodFisco: "B490",
        Abitanti: "5582",
      },
      {
        Istat: "10008",
        Comune: "Campo Ligure",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16013",
        CodFisco: "B538",
        Abitanti: "3051",
      },
      {
        Istat: "10009",
        Comune: "Campomorone",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16014",
        CodFisco: "B551",
        Abitanti: "7458",
      },
      {
        Istat: "8011",
        Comune: "Camporosso",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18033",
        CodFisco: "B559",
        Abitanti: "5741",
      },
      {
        Istat: "10010",
        Comune: "Carasco",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16042",
        CodFisco: "B726",
        Abitanti: "3648",
      },
      {
        Istat: "8012",
        Comune: "Caravonica",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "B734",
        Abitanti: "312",
      },
      {
        Istat: "9018",
        Comune: "Carcare",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17043",
        CodFisco: "B748",
        Abitanti: "5682",
      },
      {
        Istat: "8013",
        Comune: "Carpasio",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18010",
        CodFisco: "B814",
        Abitanti: "159",
      },
      {
        Istat: "11009",
        Comune: "Carro",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19012",
        CodFisco: "B838",
        Abitanti: "593",
      },
      {
        Istat: "11010",
        Comune: "Carrodano",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "B839",
        Abitanti: "535",
      },
      {
        Istat: "9019",
        Comune: "Casanova Lerrone",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17033",
        CodFisco: "B927",
        Abitanti: "781",
      },
      {
        Istat: "10011",
        Comune: "Casarza Ligure",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "B939",
        Abitanti: "6694",
      },
      {
        Istat: "10012",
        Comune: "Casella",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16015",
        CodFisco: "B956",
        Abitanti: "3278",
      },
      {
        Istat: "8015",
        Comune: "Castel Vittorio",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18030",
        CodFisco: "C110",
        Abitanti: "350",
      },
      {
        Istat: "9020",
        Comune: "Castelbianco",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17030",
        CodFisco: "C063",
        Abitanti: "324",
      },
      {
        Istat: "8014",
        Comune: "Castellaro",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18011",
        CodFisco: "C143",
        Abitanti: "1267",
      },
      {
        Istat: "11011",
        Comune: "Castelnuovo Magra",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19033",
        CodFisco: "C240",
        Abitanti: "8256",
      },
      {
        Istat: "9021",
        Comune: "Castelvecchio di Rocca Barbena",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17034",
        CodFisco: "C276",
        Abitanti: "180",
      },
      {
        Istat: "10013",
        Comune: "Castiglione Chiavarese",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "C302",
        Abitanti: "1658",
      },
      {
        Istat: "9022",
        Comune: "Celle Ligure",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17015",
        CodFisco: "C443",
        Abitanti: "5431",
      },
      {
        Istat: "9023",
        Comune: "Cengio",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17056",
        CodFisco: "C463",
        Abitanti: "3749",
      },
      {
        Istat: "10014",
        Comune: "Ceranesi",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16014",
        CodFisco: "C481",
        Abitanti: "4035",
      },
      {
        Istat: "9024",
        Comune: "Ceriale",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17023",
        CodFisco: "C510",
        Abitanti: "6049",
      },
      {
        Istat: "8016",
        Comune: "Ceriana",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18034",
        CodFisco: "C511",
        Abitanti: "1297",
      },
      {
        Istat: "8017",
        Comune: "Cervo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18010",
        CodFisco: "C559",
        Abitanti: "1173",
      },
      {
        Istat: "8018",
        Comune: "Cesio",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18022",
        CodFisco: "C578",
        Abitanti: "295",
      },
      {
        Istat: "10015",
        Comune: "Chiavari",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16043",
        CodFisco: "C621",
        Abitanti: "27815",
      },
      {
        Istat: "8019",
        Comune: "Chiusanico",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18027",
        CodFisco: "C657",
        Abitanti: "611",
      },
      {
        Istat: "8020",
        Comune: "Chiusavecchia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18027",
        CodFisco: "C660",
        Abitanti: "584",
      },
      {
        Istat: "10016",
        Comune: "Cicagna",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16044",
        CodFisco: "C673",
        Abitanti: "2633",
      },
      {
        Istat: "8021",
        Comune: "Cipressa",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18017",
        CodFisco: "C718",
        Abitanti: "1370",
      },
      {
        Istat: "9025",
        Comune: "Cisano sul Neva",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17035",
        CodFisco: "C729",
        Abitanti: "1947",
      },
      {
        Istat: "8022",
        Comune: "Civezza",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18017",
        CodFisco: "C755",
        Abitanti: "643",
      },
      {
        Istat: "10017",
        Comune: "Cogoleto",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16016",
        CodFisco: "C823",
        Abitanti: "9209",
      },
      {
        Istat: "10018",
        Comune: "Cogorno",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "C826",
        Abitanti: "5672",
      },
      {
        Istat: "10019",
        Comune: "Coreglia Ligure",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16040",
        CodFisco: "C995",
        Abitanti: "273",
      },
      {
        Istat: "8023",
        Comune: "Cosio d'Arroscia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18023",
        CodFisco: "D087",
        Abitanti: "252",
      },
      {
        Istat: "9026",
        Comune: "Cosseria",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17017",
        CodFisco: "D095",
        Abitanti: "1084",
      },
      {
        Istat: "8024",
        Comune: "Costarainera",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18017",
        CodFisco: "D114",
        Abitanti: "836",
      },
      {
        Istat: "10020",
        Comune: "Crocefieschi",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "D175",
        Abitanti: "576",
      },
      {
        Istat: "10021",
        Comune: "Davagna",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16022",
        CodFisco: "D255",
        Abitanti: "2018",
      },
      {
        Istat: "9027",
        Comune: "Dego",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17058",
        CodFisco: "D264",
        Abitanti: "2006",
      },
      {
        Istat: "11012",
        Comune: "Deiva Marina",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19013",
        CodFisco: "D265",
        Abitanti: "1475",
      },
      {
        Istat: "8025",
        Comune: "Diano Arentino",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18013",
        CodFisco: "D293",
        Abitanti: "742",
      },
      {
        Istat: "8026",
        Comune: "Diano Castello",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18013",
        CodFisco: "D296",
        Abitanti: "2349",
      },
      {
        Istat: "8027",
        Comune: "Diano Marina",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18013",
        CodFisco: "D297",
        Abitanti: "6285",
      },
      {
        Istat: "8028",
        Comune: "Diano San Pietro",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18013",
        CodFisco: "D298",
        Abitanti: "1075",
      },
      {
        Istat: "8029",
        Comune: "Dolceacqua",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18035",
        CodFisco: "D318",
        Abitanti: "2060",
      },
      {
        Istat: "8030",
        Comune: "Dolcedo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "D319",
        Abitanti: "1472",
      },
      {
        Istat: "9028",
        Comune: "Erli",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17030",
        CodFisco: "D424",
        Abitanti: "267",
      },
      {
        Istat: "10022",
        Comune: "Fascia",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16020",
        CodFisco: "D509",
        Abitanti: "105",
      },
      {
        Istat: "10023",
        Comune: "Favale di Malvaro",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16040",
        CodFisco: "D512",
        Abitanti: "512",
      },
      {
        Istat: "9029",
        Comune: "Finale Ligure",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17024",
        CodFisco: "D600",
        Abitanti: "11638",
      },
      {
        Istat: "11013",
        Comune: "Follo",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "D655",
        Abitanti: "6436",
      },
      {
        Istat: "10024",
        Comune: "Fontanigorda",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16023",
        CodFisco: "D677",
        Abitanti: "282",
      },
      {
        Istat: "11014",
        Comune: "Framura",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19014",
        CodFisco: "D758",
        Abitanti: "716",
      },
      {
        Istat: "9030",
        Comune: "Garlenda",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17033",
        CodFisco: "D927",
        Abitanti: "1291",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16121",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16122",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16123",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16124",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16125",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16126",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16127",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16128",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16129",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16130",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16131",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16132",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16133",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16134",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16135",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16136",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16137",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16138",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16139",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16140",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16141",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16142",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16143",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16144",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16145",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16146",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16147",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16148",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16149",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16150",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16151",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16152",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16153",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16154",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16155",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16156",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16157",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16158",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16159",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16160",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16161",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16162",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16163",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16164",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16165",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16166",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "10025",
        Comune: "Genova",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16167",
        CodFisco: "D969",
        Abitanti: "607906",
      },
      {
        Istat: "9031",
        Comune: "Giustenice",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17027",
        CodFisco: "E064",
        Abitanti: "959",
      },
      {
        Istat: "9032",
        Comune: "Giusvalla",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17010",
        CodFisco: "E066",
        Abitanti: "469",
      },
      {
        Istat: "10026",
        Comune: "Gorreto",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16020",
        CodFisco: "E109",
        Abitanti: "113",
      },
      {
        Istat: "8031",
        Comune: "Imperia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18100",
        CodFisco: "E290",
        Abitanti: "42667",
      },
      {
        Istat: "10027",
        Comune: "Isola del Cantone",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16017",
        CodFisco: "E341",
        Abitanti: "1551",
      },
      {
        Istat: "8032",
        Comune: "Isolabona",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18035",
        CodFisco: "E346",
        Abitanti: "720",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19121",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19122",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19123",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19124",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19125",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19126",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19127",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19128",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19129",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19130",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19131",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19132",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19133",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19134",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19135",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19136",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "11015",
        Comune: "La Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19137",
        CodFisco: "E463",
        Abitanti: "95378",
      },
      {
        Istat: "9033",
        Comune: "Laigueglia",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17053",
        CodFisco: "E414",
        Abitanti: "1895",
      },
      {
        Istat: "10028",
        Comune: "Lavagna",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16033",
        CodFisco: "E488",
        Abitanti: "13013",
      },
      {
        Istat: "10029",
        Comune: "Leivi",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16040",
        CodFisco: "E519",
        Abitanti: "2353",
      },
      {
        Istat: "11016",
        Comune: "Lerici",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19032",
        CodFisco: "E542",
        Abitanti: "10284",
      },
      {
        Istat: "11017",
        Comune: "Levanto",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19015",
        CodFisco: "E560",
        Abitanti: "5592",
      },
      {
        Istat: "9034",
        Comune: "Loano",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17025",
        CodFisco: "E632",
        Abitanti: "12034",
      },
      {
        Istat: "10030",
        Comune: "Lorsica",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16045",
        CodFisco: "E695",
        Abitanti: "529",
      },
      {
        Istat: "8033",
        Comune: "Lucinasco",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "E719",
        Abitanti: "287",
      },
      {
        Istat: "10031",
        Comune: "Lumarzo",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16024",
        CodFisco: "E737",
        Abitanti: "1618",
      },
      {
        Istat: "9035",
        Comune: "Magliolo",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17020",
        CodFisco: "E816",
        Abitanti: "882",
      },
      {
        Istat: "11018",
        Comune: "Maissana",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19010",
        CodFisco: "E842",
        Abitanti: "665",
      },
      {
        Istat: "9036",
        Comune: "Mallare",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17045",
        CodFisco: "E860",
        Abitanti: "1214",
      },
      {
        Istat: "10032",
        Comune: "Masone",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "F020",
        Abitanti: "3795",
      },
      {
        Istat: "9037",
        Comune: "Massimino",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "12071",
        CodFisco: "F046",
        Abitanti: "127",
      },
      {
        Istat: "10033",
        Comune: "Mele",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "F098",
        Abitanti: "2721",
      },
      {
        Istat: "8034",
        Comune: "Mendatica",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18025",
        CodFisco: "F123",
        Abitanti: "226",
      },
      {
        Istat: "10034",
        Comune: "Mezzanego",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16046",
        CodFisco: "F173",
        Abitanti: "1648",
      },
      {
        Istat: "10035",
        Comune: "Mignanego",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16018",
        CodFisco: "F202",
        Abitanti: "3764",
      },
      {
        Istat: "9038",
        Comune: "Millesimo",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17017",
        CodFisco: "F213",
        Abitanti: "3516",
      },
      {
        Istat: "9039",
        Comune: "Mioglia",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17040",
        CodFisco: "F226",
        Abitanti: "546",
      },
      {
        Istat: "10036",
        Comune: "Moconesi",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16047",
        CodFisco: "F256",
        Abitanti: "2738",
      },
      {
        Istat: "8035",
        Comune: "Molini di Triora",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18010",
        CodFisco: "F290",
        Abitanti: "699",
      },
      {
        Istat: "10037",
        Comune: "Moneglia",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "F354",
        Abitanti: "2898",
      },
      {
        Istat: "8036",
        Comune: "Montalto Ligure",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18010",
        CodFisco: "F406",
        Abitanti: "372",
      },
      {
        Istat: "10038",
        Comune: "Montebruno",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16025",
        CodFisco: "F445",
        Abitanti: "217",
      },
      {
        Istat: "8037",
        Comune: "Montegrosso Pian Latte",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18025",
        CodFisco: "F528",
        Abitanti: "124",
      },
      {
        Istat: "11019",
        Comune: "Monterosso al Mare",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19016",
        CodFisco: "F609",
        Abitanti: "1521",
      },
      {
        Istat: "10039",
        Comune: "Montoggio",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16026",
        CodFisco: "F682",
        Abitanti: "2111",
      },
      {
        Istat: "9040",
        Comune: "Murialdo",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17013",
        CodFisco: "F813",
        Abitanti: "864",
      },
      {
        Istat: "9041",
        Comune: "Nasino",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17030",
        CodFisco: "F847",
        Abitanti: "227",
      },
      {
        Istat: "10040",
        Comune: "Ne",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16040",
        CodFisco: "F858",
        Abitanti: "2432",
      },
      {
        Istat: "10041",
        Comune: "Neirone",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16040",
        CodFisco: "F862",
        Abitanti: "970",
      },
      {
        Istat: "9042",
        Comune: "Noli",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17026",
        CodFisco: "F926",
        Abitanti: "2861",
      },
      {
        Istat: "8038",
        Comune: "Olivetta San Michele",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18030",
        CodFisco: "G041",
        Abitanti: "240",
      },
      {
        Istat: "9043",
        Comune: "Onzo",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17037",
        CodFisco: "G076",
        Abitanti: "233",
      },
      {
        Istat: "9044",
        Comune: "Orco Feglino",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17024",
        CodFisco: "D522",
        Abitanti: "917",
      },
      {
        Istat: "10042",
        Comune: "Orero",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16040",
        CodFisco: "G093",
        Abitanti: "606",
      },
      {
        Istat: "11020",
        Comune: "Ortonovo",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19034",
        CodFisco: "G143",
        Abitanti: "8483",
      },
      {
        Istat: "9045",
        Comune: "Ortovero",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17037",
        CodFisco: "G144",
        Abitanti: "1564",
      },
      {
        Istat: "9046",
        Comune: "Osiglia",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17010",
        CodFisco: "G155",
        Abitanti: "480",
      },
      {
        Istat: "8039",
        Comune: "Ospedaletti",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18014",
        CodFisco: "G164",
        Abitanti: "3647",
      },
      {
        Istat: "9047",
        Comune: "Pallare",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17043",
        CodFisco: "G281",
        Abitanti: "956",
      },
      {
        Istat: "8040",
        Comune: "Perinaldo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18032",
        CodFisco: "G454",
        Abitanti: "886",
      },
      {
        Istat: "9048",
        Comune: "Piana Crixia",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17058",
        CodFisco: "G542",
        Abitanti: "896",
      },
      {
        Istat: "9049",
        Comune: "Pietra Ligure",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17027",
        CodFisco: "G605",
        Abitanti: "9401",
      },
      {
        Istat: "8041",
        Comune: "Pietrabruna",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18010",
        CodFisco: "G607",
        Abitanti: "558",
      },
      {
        Istat: "8042",
        Comune: "Pieve di Teco",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18026",
        CodFisco: "G632",
        Abitanti: "1421",
      },
      {
        Istat: "10043",
        Comune: "Pieve Ligure",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16030",
        CodFisco: "G646",
        Abitanti: "2545",
      },
      {
        Istat: "8043",
        Comune: "Pigna",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18037",
        CodFisco: "G660",
        Abitanti: "919",
      },
      {
        Istat: "11021",
        Comune: "Pignone",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "G664",
        Abitanti: "611",
      },
      {
        Istat: "9050",
        Comune: "Plodio",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17043",
        CodFisco: "G741",
        Abitanti: "650",
      },
      {
        Istat: "8044",
        Comune: "Pompeiana",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18015",
        CodFisco: "G814",
        Abitanti: "844",
      },
      {
        Istat: "8045",
        Comune: "Pontedassio",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18027",
        CodFisco: "G840",
        Abitanti: "2367",
      },
      {
        Istat: "9051",
        Comune: "Pontinvrea",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17042",
        CodFisco: "G866",
        Abitanti: "857",
      },
      {
        Istat: "8046",
        Comune: "Pornassio",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18024",
        CodFisco: "G890",
        Abitanti: "617",
      },
      {
        Istat: "10044",
        Comune: "Portofino",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16034",
        CodFisco: "G913",
        Abitanti: "479",
      },
      {
        Istat: "11022",
        Comune: "Portovenere",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19025",
        CodFisco: "G925",
        Abitanti: "3906",
      },
      {
        Istat: "8047",
        Comune: "Prelà",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "H027",
        Abitanti: "514",
      },
      {
        Istat: "10045",
        Comune: "Propata",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16027",
        CodFisco: "H073",
        Abitanti: "165",
      },
      {
        Istat: "9052",
        Comune: "Quiliano",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17047",
        CodFisco: "H126",
        Abitanti: "7424",
      },
      {
        Istat: "8048",
        Comune: "Ranzo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "H180",
        Abitanti: "576",
      },
      {
        Istat: "10046",
        Comune: "Rapallo",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16035",
        CodFisco: "H183",
        Abitanti: "30785",
      },
      {
        Istat: "10047",
        Comune: "Recco",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16036",
        CodFisco: "H212",
        Abitanti: "10178",
      },
      {
        Istat: "8049",
        Comune: "Rezzo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18026",
        CodFisco: "H257",
        Abitanti: "383",
      },
      {
        Istat: "10048",
        Comune: "Rezzoaglio",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16048",
        CodFisco: "H258",
        Abitanti: "1074",
      },
      {
        Istat: "9053",
        Comune: "Rialto",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17020",
        CodFisco: "H266",
        Abitanti: "583",
      },
      {
        Istat: "11023",
        Comune: "Riccò del Golfo di Spezia",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "H275",
        Abitanti: "3552",
      },
      {
        Istat: "11024",
        Comune: "Riomaggiore",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19017",
        CodFisco: "H304",
        Abitanti: "1693",
      },
      {
        Istat: "8050",
        Comune: "Riva Ligure",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18015",
        CodFisco: "H328",
        Abitanti: "2941",
      },
      {
        Istat: "9054",
        Comune: "Roccavignale",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17017",
        CodFisco: "H452",
        Abitanti: "750",
      },
      {
        Istat: "11025",
        Comune: "Rocchetta di Vara",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "H461",
        Abitanti: "819",
      },
      {
        Istat: "8051",
        Comune: "Rocchetta Nervina",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18030",
        CodFisco: "H460",
        Abitanti: "275",
      },
      {
        Istat: "10049",
        Comune: "Ronco Scrivia",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16019",
        CodFisco: "H536",
        Abitanti: "4555",
      },
      {
        Istat: "10050",
        Comune: "Rondanina",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16025",
        CodFisco: "H546",
        Abitanti: "78",
      },
      {
        Istat: "10051",
        Comune: "Rossiglione",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "H581",
        Abitanti: "2959",
      },
      {
        Istat: "10052",
        Comune: "Rovegno",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16028",
        CodFisco: "H599",
        Abitanti: "578",
      },
      {
        Istat: "8052",
        Comune: "San Bartolomeo al Mare",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18016",
        CodFisco: "H763",
        Abitanti: "3150",
      },
      {
        Istat: "8053",
        Comune: "San Biagio della Cima",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18036",
        CodFisco: "H780",
        Abitanti: "1339",
      },
      {
        Istat: "10053",
        Comune: "San Colombano Certenoli",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16040",
        CodFisco: "H802",
        Abitanti: "2667",
      },
      {
        Istat: "8054",
        Comune: "San Lorenzo al Mare",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18017",
        CodFisco: "H957",
        Abitanti: "1384",
      },
      {
        Istat: "8055",
        Comune: "Sanremo",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18038",
        CodFisco: "I138",
        Abitanti: "56962",
      },
      {
        Istat: "10054",
        Comune: "Santa Margherita Ligure",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16038",
        CodFisco: "I225",
        Abitanti: "9915",
      },
      {
        Istat: "8056",
        Comune: "Santo Stefano al Mare",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18010",
        CodFisco: "I365",
        Abitanti: "2322",
      },
      {
        Istat: "10056",
        Comune: "Santo Stefano d'Aveto",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16049",
        CodFisco: "I368",
        Abitanti: "1251",
      },
      {
        Istat: "11026",
        Comune: "Santo Stefano di Magra",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19037",
        CodFisco: "I363",
        Abitanti: "8751",
      },
      {
        Istat: "10055",
        Comune: "Sant'Olcese",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "I346",
        Abitanti: "5999",
      },
      {
        Istat: "11027",
        Comune: "Sarzana",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19038",
        CodFisco: "I449",
        Abitanti: "21979",
      },
      {
        Istat: "9055",
        Comune: "Sassello",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17046",
        CodFisco: "I453",
        Abitanti: "1861",
      },
      {
        Istat: "10057",
        Comune: "Savignone",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "I475",
        Abitanti: "3249",
      },
      {
        Istat: "9056",
        Comune: "Savona",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17100",
        CodFisco: "I480",
        Abitanti: "62553",
      },
      {
        Istat: "8057",
        Comune: "Seborga",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18012",
        CodFisco: "I556",
        Abitanti: "319",
      },
      {
        Istat: "10058",
        Comune: "Serra Riccò",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "I640",
        Abitanti: "7994",
      },
      {
        Istat: "11028",
        Comune: "Sesta Godano",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "E070",
        Abitanti: "1475",
      },
      {
        Istat: "10059",
        Comune: "Sestri Levante",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16039",
        CodFisco: "I693",
        Abitanti: "18794",
      },
      {
        Istat: "8058",
        Comune: "Soldano",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18036",
        CodFisco: "I796",
        Abitanti: "982",
      },
      {
        Istat: "10060",
        Comune: "Sori",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "I852",
        Abitanti: "4343",
      },
      {
        Istat: "9057",
        Comune: "Spotorno",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17028",
        CodFisco: "I926",
        Abitanti: "4005",
      },
      {
        Istat: "9058",
        Comune: "Stella",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17044",
        CodFisco: "I946",
        Abitanti: "3082",
      },
      {
        Istat: "9059",
        Comune: "Stellanello",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17020",
        CodFisco: "I947",
        Abitanti: "877",
      },
      {
        Istat: "8059",
        Comune: "Taggia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18018",
        CodFisco: "L024",
        Abitanti: "14502",
      },
      {
        Istat: "8060",
        Comune: "Terzorio",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18010",
        CodFisco: "L146",
        Abitanti: "239",
      },
      {
        Istat: "9060",
        Comune: "Testico",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17020",
        CodFisco: "L152",
        Abitanti: "219",
      },
      {
        Istat: "10061",
        Comune: "Tiglieto",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "L167",
        Abitanti: "594",
      },
      {
        Istat: "9061",
        Comune: "Toirano",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17055",
        CodFisco: "L190",
        Abitanti: "2690",
      },
      {
        Istat: "10062",
        Comune: "Torriglia",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16029",
        CodFisco: "L298",
        Abitanti: "2468",
      },
      {
        Istat: "9062",
        Comune: "Tovo San Giacomo",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17020",
        CodFisco: "L315",
        Abitanti: "2508",
      },
      {
        Istat: "10063",
        Comune: "Tribogna",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "L416",
        Abitanti: "628",
      },
      {
        Istat: "8061",
        Comune: "Triora",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18010",
        CodFisco: "L430",
        Abitanti: "398",
      },
      {
        Istat: "9063",
        Comune: "Urbe",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17048",
        CodFisco: "L499",
        Abitanti: "793",
      },
      {
        Istat: "10064",
        Comune: "Uscio",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "L507",
        Abitanti: "2402",
      },
      {
        Istat: "9064",
        Comune: "Vado Ligure",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17047",
        CodFisco: "L528",
        Abitanti: "8523",
      },
      {
        Istat: "10065",
        Comune: "Valbrevenna",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "L546",
        Abitanti: "811",
      },
      {
        Istat: "8062",
        Comune: "Vallebona",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18012",
        CodFisco: "L596",
        Abitanti: "1327",
      },
      {
        Istat: "8063",
        Comune: "Vallecrosia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18019",
        CodFisco: "L599",
        Abitanti: "7235",
      },
      {
        Istat: "9065",
        Comune: "Varazze",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17019",
        CodFisco: "L675",
        Abitanti: "13708",
      },
      {
        Istat: "11029",
        Comune: "Varese Ligure",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19028",
        CodFisco: "L681",
        Abitanti: "2151",
      },
      {
        Istat: "8064",
        Comune: "Vasia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18020",
        CodFisco: "L693",
        Abitanti: "440",
      },
      {
        Istat: "9066",
        Comune: "Vendone",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17032",
        CodFisco: "L730",
        Abitanti: "416",
      },
      {
        Istat: "8065",
        Comune: "Ventimiglia",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "184",
        CAP: "18039",
        CodFisco: "L741",
        Abitanti: "25675",
      },
      {
        Istat: "11030",
        Comune: "Vernazza",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19018",
        CodFisco: "L774",
        Abitanti: "975",
      },
      {
        Istat: "8066",
        Comune: "Vessalico",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18026",
        CodFisco: "L809",
        Abitanti: "315",
      },
      {
        Istat: "11031",
        Comune: "Vezzano Ligure",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "L819",
        Abitanti: "7344",
      },
      {
        Istat: "9067",
        Comune: "Vezzi Portio",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "19",
        CAP: "17028",
        CodFisco: "L823",
        Abitanti: "825",
      },
      {
        Istat: "8067",
        Comune: "Villa Faraldi",
        Provincia: "IM",
        Regione: "LIG",
        Prefisso: "183",
        CAP: "18010",
        CodFisco: "L943",
        Abitanti: "500",
      },
      {
        Istat: "9068",
        Comune: "Villanova d'Albenga",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17038",
        CodFisco: "L975",
        Abitanti: "2506",
      },
      {
        Istat: "10066",
        Comune: "Vobbia",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "10",
        CAP: "16010",
        CodFisco: "M105",
        Abitanti: "470",
      },
      {
        Istat: "11032",
        Comune: "Zignago",
        Provincia: "SP",
        Regione: "LIG",
        Prefisso: "187",
        CAP: "19020",
        CodFisco: "M177",
        Abitanti: "544",
      },
      {
        Istat: "10067",
        Comune: "Zoagli",
        Provincia: "GE",
        Regione: "LIG",
        Prefisso: "185",
        CAP: "16030",
        CodFisco: "M182",
        Abitanti: "2592",
      },
      {
        Istat: "9069",
        Comune: "Zuccarello",
        Provincia: "SV",
        Regione: "LIG",
        Prefisso: "182",
        CAP: "17039",
        CodFisco: "M197",
        Abitanti: "339",
      },
      {
        Istat: "98001",
        Comune: "Abbadia Cerreto",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26834",
        CodFisco: "A004",
        Abitanti: "293",
      },
      {
        Istat: "97001",
        Comune: "Abbadia Lariana",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23821",
        CodFisco: "A005",
        Abitanti: "3305",
      },
      {
        Istat: "15002",
        Comune: "Abbiategrasso",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20081",
        CodFisco: "A010",
        Abitanti: "32035",
      },
      {
        Istat: "17001",
        Comune: "Acquafredda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "A034",
        Abitanti: "1615",
      },
      {
        Istat: "19001",
        Comune: "Acquanegra Cremonese",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26020",
        CodFisco: "A039",
        Abitanti: "1327",
      },
      {
        Istat: "20001",
        Comune: "Acquanegra sul Chiese",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46011",
        CodFisco: "A038",
        Abitanti: "3061",
      },
      {
        Istat: "16001",
        Comune: "Adrara San Martino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "A057",
        Abitanti: "2171",
      },
      {
        Istat: "16002",
        Comune: "Adrara San Rocco",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "A058",
        Abitanti: "857",
      },
      {
        Istat: "17002",
        Comune: "Adro",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "A060",
        Abitanti: "7180",
      },
      {
        Istat: "19002",
        Comune: "Agnadello",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26020",
        CodFisco: "A076",
        Abitanti: "3776",
      },
      {
        Istat: "17003",
        Comune: "Agnosine",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25071",
        CodFisco: "A082",
        Abitanti: "1839",
      },
      {
        Istat: "12001",
        Comune: "Agra",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "A085",
        Abitanti: "408",
      },
      {
        Istat: "108001",
        Comune: "Agrate Brianza",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20864",
        CodFisco: "A087",
        Abitanti: "15065",
      },
      {
        Istat: "108002",
        Comune: "Aicurzio",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20886",
        CodFisco: "A096",
        Abitanti: "2069",
      },
      {
        Istat: "97002",
        Comune: "Airuno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23881",
        CodFisco: "A112",
        Abitanti: "2999",
      },
      {
        Istat: "18001",
        Comune: "Alagna",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "A118",
        Abitanti: "906",
      },
      {
        Istat: "15005",
        Comune: "Albairate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "A127",
        Abitanti: "4680",
      },
      {
        Istat: "16003",
        Comune: "Albano Sant'Alessandro",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24061",
        CodFisco: "A129",
        Abitanti: "8075",
      },
      {
        Istat: "18002",
        Comune: "Albaredo Arnaboldi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "A134",
        Abitanti: "223",
      },
      {
        Istat: "14001",
        Comune: "Albaredo per San Marco",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "A135",
        Abitanti: "350",
      },
      {
        Istat: "13003",
        Comune: "Albavilla",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22031",
        CodFisco: "A143",
        Abitanti: "6272",
      },
      {
        Istat: "13004",
        Comune: "Albese con Cassano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22032",
        CodFisco: "A153",
        Abitanti: "4139",
      },
      {
        Istat: "108003",
        Comune: "Albiate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20847",
        CodFisco: "A159",
        Abitanti: "6201",
      },
      {
        Istat: "16004",
        Comune: "Albino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24021",
        CodFisco: "A163",
        Abitanti: "18186",
      },
      {
        Istat: "13005",
        Comune: "Albiolo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "A164",
        Abitanti: "2687",
      },
      {
        Istat: "12002",
        Comune: "Albizzate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21041",
        CodFisco: "A167",
        Abitanti: "5275",
      },
      {
        Istat: "18003",
        Comune: "Albonese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "A171",
        Abitanti: "567",
      },
      {
        Istat: "14002",
        Comune: "Albosaggia",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "A172",
        Abitanti: "3170",
      },
      {
        Istat: "18004",
        Comune: "Albuzzano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "A175",
        Abitanti: "3368",
      },
      {
        Istat: "17004",
        Comune: "Alfianello",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "A188",
        Abitanti: "2476",
      },
      {
        Istat: "16248",
        Comune: "Algua",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "A193",
        Abitanti: "726",
      },
      {
        Istat: "16005",
        Comune: "Almè",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24011",
        CodFisco: "A214",
        Abitanti: "5736",
      },
      {
        Istat: "16006",
        Comune: "Almenno San Bartolomeo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "A216",
        Abitanti: "6018",
      },
      {
        Istat: "16007",
        Comune: "Almenno San Salvatore",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24031",
        CodFisco: "A217",
        Abitanti: "5825",
      },
      {
        Istat: "13006",
        Comune: "Alserio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22040",
        CodFisco: "A224",
        Abitanti: "1195",
      },
      {
        Istat: "16008",
        Comune: "Alzano Lombardo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24022",
        CodFisco: "A246",
        Abitanti: "13757",
      },
      {
        Istat: "13007",
        Comune: "Alzate Brianza",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22040",
        CodFisco: "A249",
        Abitanti: "5103",
      },
      {
        Istat: "16009",
        Comune: "Ambivere",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "A259",
        Abitanti: "2343",
      },
      {
        Istat: "14003",
        Comune: "Andalo Valtellino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23014",
        CodFisco: "A273",
        Abitanti: "550",
      },
      {
        Istat: "17005",
        Comune: "Anfo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "A288",
        Abitanti: "487",
      },
      {
        Istat: "12003",
        Comune: "Angera",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21021",
        CodFisco: "A290",
        Abitanti: "5694",
      },
      {
        Istat: "17006",
        Comune: "Angolo Terme",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "A293",
        Abitanti: "2563",
      },
      {
        Istat: "19003",
        Comune: "Annicco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26021",
        CodFisco: "A299",
        Abitanti: "2097",
      },
      {
        Istat: "97003",
        Comune: "Annone di Brianza",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23841",
        CodFisco: "A301",
        Abitanti: "2304",
      },
      {
        Istat: "16010",
        Comune: "Antegnate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24051",
        CodFisco: "A304",
        Abitanti: "3194",
      },
      {
        Istat: "13009",
        Comune: "Anzano del Parco",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22040",
        CodFisco: "A319",
        Abitanti: "1759",
      },
      {
        Istat: "13010",
        Comune: "Appiano Gentile",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "A333",
        Abitanti: "7726",
      },
      {
        Istat: "14004",
        Comune: "Aprica",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23031",
        CodFisco: "A337",
        Abitanti: "1612",
      },
      {
        Istat: "16011",
        Comune: "Arcene",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "A365",
        Abitanti: "4774",
      },
      {
        Istat: "12004",
        Comune: "Arcisate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21051",
        CodFisco: "A371",
        Abitanti: "9991",
      },
      {
        Istat: "15007",
        Comune: "Arconate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "A375",
        Abitanti: "6499",
      },
      {
        Istat: "108004",
        Comune: "Arcore",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20862",
        CodFisco: "A376",
        Abitanti: "17750",
      },
      {
        Istat: "14005",
        Comune: "Ardenno",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23011",
        CodFisco: "A382",
        Abitanti: "3302",
      },
      {
        Istat: "16012",
        Comune: "Ardesio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "A383",
        Abitanti: "3651",
      },
      {
        Istat: "18005",
        Comune: "Arena Po",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "A387",
        Abitanti: "1660",
      },
      {
        Istat: "15009",
        Comune: "Arese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20020",
        CodFisco: "A389",
        Abitanti: "19506",
      },
      {
        Istat: "13011",
        Comune: "Argegno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22010",
        CodFisco: "A391",
        Abitanti: "693",
      },
      {
        Istat: "15010",
        Comune: "Arluno",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "A413",
        Abitanti: "11601",
      },
      {
        Istat: "13012",
        Comune: "Arosio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22060",
        CodFisco: "A430",
        Abitanti: "4964",
      },
      {
        Istat: "12005",
        Comune: "Arsago Seprio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21010",
        CodFisco: "A441",
        Abitanti: "4886",
      },
      {
        Istat: "17007",
        Comune: "Artogne",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "A451",
        Abitanti: "3545",
      },
      {
        Istat: "16013",
        Comune: "Arzago d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "A440",
        Abitanti: "2811",
      },
      {
        Istat: "20002",
        Comune: "Asola",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46041",
        CodFisco: "A470",
        Abitanti: "10127",
      },
      {
        Istat: "15011",
        Comune: "Assago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "A473",
        Abitanti: "8168",
      },
      {
        Istat: "13013",
        Comune: "Asso",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22033",
        CodFisco: "A476",
        Abitanti: "3665",
      },
      {
        Istat: "16014",
        Comune: "Averara",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "A511",
        Abitanti: "185",
      },
      {
        Istat: "16015",
        Comune: "Aviatico",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "A517",
        Abitanti: "525",
      },
      {
        Istat: "19004",
        Comune: "Azzanello",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26010",
        CodFisco: "A526",
        Abitanti: "744",
      },
      {
        Istat: "17008",
        Comune: "Azzano Mella",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "A529",
        Abitanti: "2900",
      },
      {
        Istat: "16016",
        Comune: "Azzano San Paolo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24052",
        CodFisco: "A528",
        Abitanti: "7685",
      },
      {
        Istat: "12006",
        Comune: "Azzate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21022",
        CodFisco: "A531",
        Abitanti: "4520",
      },
      {
        Istat: "12007",
        Comune: "Azzio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "A532",
        Abitanti: "808",
      },
      {
        Istat: "16017",
        Comune: "Azzone",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "A533",
        Abitanti: "437",
      },
      {
        Istat: "18006",
        Comune: "Badia Pavese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "A538",
        Abitanti: "407",
      },
      {
        Istat: "18007",
        Comune: "Bagnaria",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "A550",
        Abitanti: "684",
      },
      {
        Istat: "16018",
        Comune: "Bagnatica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "A557",
        Abitanti: "4190",
      },
      {
        Istat: "19005",
        Comune: "Bagnolo Cremasco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "A570",
        Abitanti: "4841",
      },
      {
        Istat: "17009",
        Comune: "Bagnolo Mella",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25021",
        CodFisco: "A569",
        Abitanti: "12969",
      },
      {
        Istat: "20003",
        Comune: "Bagnolo San Vito",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46031",
        CodFisco: "A575",
        Abitanti: "5926",
      },
      {
        Istat: "17010",
        Comune: "Bagolino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25072",
        CodFisco: "A578",
        Abitanti: "3968",
      },
      {
        Istat: "97004",
        Comune: "Ballabio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23811",
        CodFisco: "A594",
        Abitanti: "3967",
      },
      {
        Istat: "15250",
        Comune: "Baranzate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20021",
        CodFisco: "A618",
        Abitanti: "11448",
      },
      {
        Istat: "12008",
        Comune: "Barasso",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "A619",
        Abitanti: "1751",
      },
      {
        Istat: "17011",
        Comune: "Barbariga",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "A630",
        Abitanti: "2410",
      },
      {
        Istat: "16019",
        Comune: "Barbata",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "A631",
        Abitanti: "743",
      },
      {
        Istat: "18008",
        Comune: "Barbianello",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27041",
        CodFisco: "A634",
        Abitanti: "888",
      },
      {
        Istat: "12009",
        Comune: "Bardello",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "A645",
        Abitanti: "1559",
      },
      {
        Istat: "15012",
        Comune: "Bareggio",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "A652",
        Abitanti: "17254",
      },
      {
        Istat: "17012",
        Comune: "Barghe",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "A661",
        Abitanti: "1195",
      },
      {
        Istat: "16020",
        Comune: "Bariano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "A664",
        Abitanti: "4431",
      },
      {
        Istat: "108005",
        Comune: "Barlassina",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20825",
        CodFisco: "A668",
        Abitanti: "6887",
      },
      {
        Istat: "13015",
        Comune: "Barni",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "A670",
        Abitanti: "628",
      },
      {
        Istat: "97005",
        Comune: "Barzago",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23890",
        CodFisco: "A683",
        Abitanti: "2566",
      },
      {
        Istat: "16021",
        Comune: "Barzana",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "A684",
        Abitanti: "1769",
      },
      {
        Istat: "97006",
        Comune: "Barzanò",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23891",
        CodFisco: "A686",
        Abitanti: "5203",
      },
      {
        Istat: "97007",
        Comune: "Barzio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23816",
        CodFisco: "A687",
        Abitanti: "1321",
      },
      {
        Istat: "18009",
        Comune: "Bascapè",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "A690",
        Abitanti: "1777",
      },
      {
        Istat: "15014",
        Comune: "Basiano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "A697",
        Abitanti: "3686",
      },
      {
        Istat: "15015",
        Comune: "Basiglio",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "A699",
        Abitanti: "8003",
      },
      {
        Istat: "17013",
        Comune: "Bassano Bresciano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "A702",
        Abitanti: "2219",
      },
      {
        Istat: "18010",
        Comune: "Bastida de' Dossi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "A711",
        Abitanti: "173",
      },
      {
        Istat: "18011",
        Comune: "Bastida Pancarana",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "A712",
        Abitanti: "1047",
      },
      {
        Istat: "18012",
        Comune: "Battuda",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "A718",
        Abitanti: "599",
      },
      {
        Istat: "12010",
        Comune: "Bedero Valcuvia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21039",
        CodFisco: "A728",
        Abitanti: "643",
      },
      {
        Istat: "17014",
        Comune: "Bedizzole",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25081",
        CodFisco: "A729",
        Abitanti: "11937",
      },
      {
        Istat: "16022",
        Comune: "Bedulita",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "A732",
        Abitanti: "734",
      },
      {
        Istat: "18013",
        Comune: "Belgioioso",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27011",
        CodFisco: "A741",
        Abitanti: "6427",
      },
      {
        Istat: "13019",
        Comune: "Bellagio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22021",
        CodFisco: "A744",
        Abitanti: "3078",
      },
      {
        Istat: "97008",
        Comune: "Bellano",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23822",
        CodFisco: "A745",
        Abitanti: "3305",
      },
      {
        Istat: "15016",
        Comune: "Bellinzago Lombardo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "A751",
        Abitanti: "3830",
      },
      {
        Istat: "108006",
        Comune: "Bellusco",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20882",
        CodFisco: "A759",
        Abitanti: "7217",
      },
      {
        Istat: "14006",
        Comune: "Bema",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "A777",
        Abitanti: "132",
      },
      {
        Istat: "13021",
        Comune: "Bene Lario",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "A778",
        Abitanti: "365",
      },
      {
        Istat: "16023",
        Comune: "Berbenno",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "A786",
        Abitanti: "2492",
      },
      {
        Istat: "14007",
        Comune: "Berbenno di Valtellina",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "A787",
        Abitanti: "4367",
      },
      {
        Istat: "13022",
        Comune: "Beregazzo con Figliaro",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "A791",
        Abitanti: "2590",
      },
      {
        Istat: "18014",
        Comune: "Bereguardo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27021",
        CodFisco: "A792",
        Abitanti: "2843",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24121",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24122",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24123",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24124",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24125",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24126",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24127",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24128",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "16024",
        Comune: "Bergamo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24129",
        CodFisco: "A794",
        Abitanti: "119551",
      },
      {
        Istat: "17015",
        Comune: "Berlingo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "A799",
        Abitanti: "2600",
      },
      {
        Istat: "108007",
        Comune: "Bernareggio",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20881",
        CodFisco: "A802",
        Abitanti: "10501",
      },
      {
        Istat: "15019",
        Comune: "Bernate Ticino",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "A804",
        Abitanti: "3117",
      },
      {
        Istat: "98002",
        Comune: "Bertonico",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26821",
        CodFisco: "A811",
        Abitanti: "1190",
      },
      {
        Istat: "17016",
        Comune: "Berzo Demo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "A816",
        Abitanti: "1751",
      },
      {
        Istat: "17017",
        Comune: "Berzo Inferiore",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "A817",
        Abitanti: "2439",
      },
      {
        Istat: "16025",
        Comune: "Berzo San Fermo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "A815",
        Abitanti: "1309",
      },
      {
        Istat: "108008",
        Comune: "Besana in Brianza",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20842",
        CodFisco: "A818",
        Abitanti: "15582",
      },
      {
        Istat: "12011",
        Comune: "Besano",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "A819",
        Abitanti: "2593",
      },
      {
        Istat: "15022",
        Comune: "Besate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "A820",
        Abitanti: "2032",
      },
      {
        Istat: "12012",
        Comune: "Besnate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21010",
        CodFisco: "A825",
        Abitanti: "5437",
      },
      {
        Istat: "12013",
        Comune: "Besozzo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21023",
        CodFisco: "A826",
        Abitanti: "9192",
      },
      {
        Istat: "12014",
        Comune: "Biandronno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21024",
        CodFisco: "A845",
        Abitanti: "3275",
      },
      {
        Istat: "16026",
        Comune: "Bianzano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "A846",
        Abitanti: "604",
      },
      {
        Istat: "14008",
        Comune: "Bianzone",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "A848",
        Abitanti: "1278",
      },
      {
        Istat: "108009",
        Comune: "Biassono",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20853",
        CodFisco: "A849",
        Abitanti: "11754",
      },
      {
        Istat: "17018",
        Comune: "Bienno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "A861",
        Abitanti: "3615",
      },
      {
        Istat: "20004",
        Comune: "Bigarello",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46030",
        CodFisco: "A866",
        Abitanti: "2171",
      },
      {
        Istat: "13023",
        Comune: "Binago",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "A870",
        Abitanti: "4862",
      },
      {
        Istat: "15024",
        Comune: "Binasco",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20082",
        CodFisco: "A872",
        Abitanti: "7268",
      },
      {
        Istat: "17019",
        Comune: "Bione",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "A878",
        Abitanti: "1471",
      },
      {
        Istat: "12015",
        Comune: "Bisuschio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "A891",
        Abitanti: "4293",
      },
      {
        Istat: "13024",
        Comune: "Bizzarone",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "A898",
        Abitanti: "1541",
      },
      {
        Istat: "16027",
        Comune: "Blello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "A903",
        Abitanti: "79",
      },
      {
        Istat: "13025",
        Comune: "Blessagno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22028",
        CodFisco: "A904",
        Abitanti: "284",
      },
      {
        Istat: "13026",
        Comune: "Blevio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "A905",
        Abitanti: "1268",
      },
      {
        Istat: "12016",
        Comune: "Bodio Lomnago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "A918",
        Abitanti: "2117",
      },
      {
        Istat: "98003",
        Comune: "Boffalora d'Adda",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26811",
        CodFisco: "A919",
        Abitanti: "1694",
      },
      {
        Istat: "15026",
        Comune: "Boffalora Sopra Ticino",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "A920",
        Abitanti: "4290",
      },
      {
        Istat: "16028",
        Comune: "Bolgare",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "A937",
        Abitanti: "5698",
      },
      {
        Istat: "15027",
        Comune: "Bollate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20021",
        CodFisco: "A940",
        Abitanti: "36467",
      },
      {
        Istat: "16029",
        Comune: "Boltiere",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "A950",
        Abitanti: "5757",
      },
      {
        Istat: "16030",
        Comune: "Bonate Sopra",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "A963",
        Abitanti: "8964",
      },
      {
        Istat: "16031",
        Comune: "Bonate Sotto",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "A962",
        Abitanti: "6606",
      },
      {
        Istat: "19006",
        Comune: "Bonemerse",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26040",
        CodFisco: "A972",
        Abitanti: "1484",
      },
      {
        Istat: "19007",
        Comune: "Bordolano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26020",
        CodFisco: "A986",
        Abitanti: "610",
      },
      {
        Istat: "18015",
        Comune: "Borgarello",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "A989",
        Abitanti: "2689",
      },
      {
        Istat: "98004",
        Comune: "Borghetto Lodigiano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26812",
        CodFisco: "A995",
        Abitanti: "4417",
      },
      {
        Istat: "16032",
        Comune: "Borgo di Terzo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "B010",
        Abitanti: "1134",
      },
      {
        Istat: "18016",
        Comune: "Borgo Priolo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "B028",
        Abitanti: "1428",
      },
      {
        Istat: "17020",
        Comune: "Borgo San Giacomo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25022",
        CodFisco: "B035",
        Abitanti: "5536",
      },
      {
        Istat: "98005",
        Comune: "Borgo San Giovanni",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26851",
        CodFisco: "B017",
        Abitanti: "2180",
      },
      {
        Istat: "18018",
        Comune: "Borgo San Siro",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "B038",
        Abitanti: "1078",
      },
      {
        Istat: "20005",
        Comune: "Borgoforte",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46030",
        CodFisco: "B011",
        Abitanti: "3562",
      },
      {
        Istat: "20006",
        Comune: "Borgofranco sul Po",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "B013",
        Abitanti: "803",
      },
      {
        Istat: "18017",
        Comune: "Borgoratto Mormorolo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "B030",
        Abitanti: "442",
      },
      {
        Istat: "17021",
        Comune: "Borgosatollo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "B040",
        Abitanti: "9232",
      },
      {
        Istat: "14009",
        Comune: "Bormio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23032",
        CodFisco: "B049",
        Abitanti: "4084",
      },
      {
        Istat: "18019",
        Comune: "Bornasco",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "B051",
        Abitanti: "2607",
      },
      {
        Istat: "17022",
        Comune: "Borno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25042",
        CodFisco: "B054",
        Abitanti: "2672",
      },
      {
        Istat: "97009",
        Comune: "Bosisio Parini",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23842",
        CodFisco: "B081",
        Abitanti: "3519",
      },
      {
        Istat: "18020",
        Comune: "Bosnasco",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "B082",
        Abitanti: "655",
      },
      {
        Istat: "16033",
        Comune: "Bossico",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "B083",
        Abitanti: "977",
      },
      {
        Istat: "16034",
        Comune: "Bottanuco",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "B088",
        Abitanti: "5254",
      },
      {
        Istat: "17023",
        Comune: "Botticino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25082",
        CodFisco: "B091",
        Abitanti: "10792",
      },
      {
        Istat: "17024",
        Comune: "Bovegno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25061",
        CodFisco: "B100",
        Abitanti: "2280",
      },
      {
        Istat: "17025",
        Comune: "Bovezzo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25073",
        CodFisco: "B102",
        Abitanti: "7512",
      },
      {
        Istat: "108010",
        Comune: "Bovisio-Masciago",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20813",
        CodFisco: "B105",
        Abitanti: "16903",
      },
      {
        Istat: "20007",
        Comune: "Bozzolo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46012",
        CodFisco: "B110",
        Abitanti: "4185",
      },
      {
        Istat: "16035",
        Comune: "Bracca",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "B112",
        Abitanti: "767",
      },
      {
        Istat: "18021",
        Comune: "Brallo di Pregola",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "B117",
        Abitanti: "725",
      },
      {
        Istat: "17026",
        Comune: "Brandico",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "B120",
        Abitanti: "1653",
      },
      {
        Istat: "16036",
        Comune: "Branzi",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "B123",
        Abitanti: "727",
      },
      {
        Istat: "17027",
        Comune: "Braone",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "B124",
        Abitanti: "673",
      },
      {
        Istat: "12017",
        Comune: "Brebbia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "B126",
        Abitanti: "3360",
      },
      {
        Istat: "12018",
        Comune: "Bregano",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "B131",
        Abitanti: "842",
      },
      {
        Istat: "13028",
        Comune: "Bregnano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "B134",
        Abitanti: "6152",
      },
      {
        Istat: "16037",
        Comune: "Brembate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24041",
        CodFisco: "B137",
        Abitanti: "8234",
      },
      {
        Istat: "16038",
        Comune: "Brembate di Sopra",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "B138",
        Abitanti: "7832",
      },
      {
        Istat: "16039",
        Comune: "Brembilla",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24012",
        CodFisco: "B140",
        Abitanti: "4179",
      },
      {
        Istat: "98006",
        Comune: "Brembio",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26822",
        CodFisco: "B141",
        Abitanti: "2707",
      },
      {
        Istat: "18022",
        Comune: "Breme",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "B142",
        Abitanti: "872",
      },
      {
        Istat: "13029",
        Comune: "Brenna",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22040",
        CodFisco: "B144",
        Abitanti: "1987",
      },
      {
        Istat: "17028",
        Comune: "Breno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25043",
        CodFisco: "B149",
        Abitanti: "4965",
      },
      {
        Istat: "12019",
        Comune: "Brenta",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "B150",
        Abitanti: "1811",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25121",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25122",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25123",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25124",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25125",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25126",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25127",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25128",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25129",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25130",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25131",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25132",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25133",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25134",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25135",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "17029",
        Comune: "Brescia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25136",
        CodFisco: "B157",
        Abitanti: "193879",
      },
      {
        Istat: "18023",
        Comune: "Bressana Bottarone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27042",
        CodFisco: "B159",
        Abitanti: "3560",
      },
      {
        Istat: "15032",
        Comune: "Bresso",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20091",
        CodFisco: "B162",
        Abitanti: "26399",
      },
      {
        Istat: "12020",
        Comune: "Brezzo di Bedero",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "B166",
        Abitanti: "1185",
      },
      {
        Istat: "13030",
        Comune: "Brienno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22010",
        CodFisco: "B172",
        Abitanti: "420",
      },
      {
        Istat: "16040",
        Comune: "Brignano Gera d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24053",
        CodFisco: "B178",
        Abitanti: "5901",
      },
      {
        Istat: "12021",
        Comune: "Brinzio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "B182",
        Abitanti: "880",
      },
      {
        Istat: "17030",
        Comune: "Brione",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "B184",
        Abitanti: "703",
      },
      {
        Istat: "108011",
        Comune: "Briosco",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20836",
        CodFisco: "B187",
        Abitanti: "5955",
      },
      {
        Istat: "12022",
        Comune: "Brissago-Valtravaglia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "B191",
        Abitanti: "1253",
      },
      {
        Istat: "97010",
        Comune: "Brivio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23883",
        CodFisco: "B194",
        Abitanti: "4770",
      },
      {
        Istat: "18024",
        Comune: "Broni",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27043",
        CodFisco: "B201",
        Abitanti: "9528",
      },
      {
        Istat: "108012",
        Comune: "Brugherio",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20861",
        CodFisco: "B212",
        Abitanti: "33484",
      },
      {
        Istat: "16041",
        Comune: "Brumano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24037",
        CodFisco: "B217",
        Abitanti: "95",
      },
      {
        Istat: "13032",
        Comune: "Brunate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22034",
        CodFisco: "B218",
        Abitanti: "1805",
      },
      {
        Istat: "12023",
        Comune: "Brunello",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "B219",
        Abitanti: "1041",
      },
      {
        Istat: "16042",
        Comune: "Brusaporto",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "B223",
        Abitanti: "5354",
      },
      {
        Istat: "12024",
        Comune: "Brusimpiano",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "B228",
        Abitanti: "1173",
      },
      {
        Istat: "15035",
        Comune: "Bubbiano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "B235",
        Abitanti: "2193",
      },
      {
        Istat: "15036",
        Comune: "Buccinasco",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "B240",
        Abitanti: "26937",
      },
      {
        Istat: "14010",
        Comune: "Buglio in Monte",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "B255",
        Abitanti: "2104",
      },
      {
        Istat: "12025",
        Comune: "Buguggiate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "B258",
        Abitanti: "3122",
      },
      {
        Istat: "97011",
        Comune: "Bulciago",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23892",
        CodFisco: "B261",
        Abitanti: "3034",
      },
      {
        Istat: "13034",
        Comune: "Bulgarograsso",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "B262",
        Abitanti: "3921",
      },
      {
        Istat: "108013",
        Comune: "Burago di Molgora",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20875",
        CodFisco: "B272",
        Abitanti: "4250",
      },
      {
        Istat: "15038",
        Comune: "Buscate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20010",
        CodFisco: "B286",
        Abitanti: "4793",
      },
      {
        Istat: "108051",
        Comune: "Busnago",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20874",
        CodFisco: "B289",
        Abitanti: "6376",
      },
      {
        Istat: "15040",
        Comune: "Bussero",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "B292",
        Abitanti: "8645",
      },
      {
        Istat: "12026",
        Comune: "Busto Arsizio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21052",
        CodFisco: "B300",
        Abitanti: "81760",
      },
      {
        Istat: "15041",
        Comune: "Busto Garolfo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "B301",
        Abitanti: "13513",
      },
      {
        Istat: "19008",
        Comune: "Ca' d'Andrea",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26030",
        CodFisco: "B320",
        Abitanti: "482",
      },
      {
        Istat: "13035",
        Comune: "Cabiate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22060",
        CodFisco: "B313",
        Abitanti: "7394",
      },
      {
        Istat: "12027",
        Comune: "Cadegliano-Viconago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21031",
        CodFisco: "B326",
        Abitanti: "1870",
      },
      {
        Istat: "13036",
        Comune: "Cadorago",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22071",
        CodFisco: "B346",
        Abitanti: "7646",
      },
      {
        Istat: "12028",
        Comune: "Cadrezzate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21020",
        CodFisco: "B347",
        Abitanti: "1779",
      },
      {
        Istat: "13037",
        Comune: "Caglio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "B355",
        Abitanti: "448",
      },
      {
        Istat: "13038",
        Comune: "Cagno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "B359",
        Abitanti: "2054",
      },
      {
        Istat: "17031",
        Comune: "Caino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25070",
        CodFisco: "B365",
        Abitanti: "2082",
      },
      {
        Istat: "14011",
        Comune: "Caiolo",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "B366",
        Abitanti: "1017",
      },
      {
        Istat: "12029",
        Comune: "Cairate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21050",
        CodFisco: "B368",
        Abitanti: "7887",
      },
      {
        Istat: "16043",
        Comune: "Calcinate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "B393",
        Abitanti: "5878",
      },
      {
        Istat: "17032",
        Comune: "Calcinato",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25011",
        CodFisco: "B394",
        Abitanti: "12725",
      },
      {
        Istat: "16044",
        Comune: "Calcio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24054",
        CodFisco: "B395",
        Abitanti: "5410",
      },
      {
        Istat: "97012",
        Comune: "Calco",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23885",
        CodFisco: "B396",
        Abitanti: "5132",
      },
      {
        Istat: "97013",
        Comune: "Calolziocorte",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23801",
        CodFisco: "B423",
        Abitanti: "14315",
      },
      {
        Istat: "16046",
        Comune: "Calusco d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24033",
        CodFisco: "B434",
        Abitanti: "8342",
      },
      {
        Istat: "17033",
        Comune: "Calvagese della Riviera",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "B436",
        Abitanti: "3501",
      },
      {
        Istat: "19009",
        Comune: "Calvatone",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26030",
        CodFisco: "B439",
        Abitanti: "1296",
      },
      {
        Istat: "16047",
        Comune: "Calvenzano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "B442",
        Abitanti: "4030",
      },
      {
        Istat: "18025",
        Comune: "Calvignano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "B447",
        Abitanti: "127",
      },
      {
        Istat: "15042",
        Comune: "Calvignasco",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "B448",
        Abitanti: "1196",
      },
      {
        Istat: "17034",
        Comune: "Calvisano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25012",
        CodFisco: "B450",
        Abitanti: "8726",
      },
      {
        Istat: "98007",
        Comune: "Camairago",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26823",
        CodFisco: "B456",
        Abitanti: "686",
      },
      {
        Istat: "15044",
        Comune: "Cambiago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20040",
        CodFisco: "B461",
        Abitanti: "6498",
      },
      {
        Istat: "16048",
        Comune: "Camerata Cornello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "B471",
        Abitanti: "619",
      },
      {
        Istat: "19010",
        Comune: "Camisano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "B484",
        Abitanti: "1311",
      },
      {
        Istat: "19011",
        Comune: "Campagnola Cremasca",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "B498",
        Abitanti: "694",
      },
      {
        Istat: "108014",
        Comune: "Camparada",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20857",
        CodFisco: "B501",
        Abitanti: "2106",
      },
      {
        Istat: "13040",
        Comune: "Campione d'Italia",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "4191",
        CAP: "22060",
        CodFisco: "B513",
        Abitanti: "2121",
      },
      {
        Istat: "14012",
        Comune: "Campodolcino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23021",
        CodFisco: "B530",
        Abitanti: "1045",
      },
      {
        Istat: "18026",
        Comune: "Campospinoso",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "B567",
        Abitanti: "982",
      },
      {
        Istat: "18027",
        Comune: "Candia Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27031",
        CodFisco: "B587",
        Abitanti: "1688",
      },
      {
        Istat: "15046",
        Comune: "Canegrate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20010",
        CodFisco: "B593",
        Abitanti: "12431",
      },
      {
        Istat: "18028",
        Comune: "Canevino",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "B599",
        Abitanti: "119",
      },
      {
        Istat: "18029",
        Comune: "Canneto Pavese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27044",
        CodFisco: "B613",
        Abitanti: "1459",
      },
      {
        Istat: "20008",
        Comune: "Canneto sull'Oglio",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46013",
        CodFisco: "B612",
        Abitanti: "4555",
      },
      {
        Istat: "16049",
        Comune: "Canonica d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "24040",
        CodFisco: "B618",
        Abitanti: "4455",
      },
      {
        Istat: "12030",
        Comune: "Cantello",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "B634",
        Abitanti: "4598",
      },
      {
        Istat: "13041",
        Comune: "Cantù",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22063",
        CodFisco: "B639",
        Abitanti: "39540",
      },
      {
        Istat: "13042",
        Comune: "Canzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22035",
        CodFisco: "B641",
        Abitanti: "5144",
      },
      {
        Istat: "19012",
        Comune: "Capergnanica",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "B650",
        Abitanti: "2094",
      },
      {
        Istat: "13043",
        Comune: "Capiago Intimiano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "B653",
        Abitanti: "5530",
      },
      {
        Istat: "16050",
        Comune: "Capizzone",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "B661",
        Abitanti: "1347",
      },
      {
        Istat: "17035",
        Comune: "Capo di Ponte",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25044",
        CodFisco: "B664",
        Abitanti: "2519",
      },
      {
        Istat: "108052",
        Comune: "Caponago",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20867",
        CodFisco: "B671",
        Abitanti: "5199",
      },
      {
        Istat: "17036",
        Comune: "Capovalle",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "B676",
        Abitanti: "401",
      },
      {
        Istat: "19013",
        Comune: "Cappella Cantone",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "B679",
        Abitanti: "590",
      },
      {
        Istat: "19014",
        Comune: "Cappella de' Picenardi",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26030",
        CodFisco: "B680",
        Abitanti: "438",
      },
      {
        Istat: "19015",
        Comune: "Capralba",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "B686",
        Abitanti: "2479",
      },
      {
        Istat: "17037",
        Comune: "Capriano del Colle",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "B698",
        Abitanti: "4499",
      },
      {
        Istat: "16051",
        Comune: "Capriate San Gervasio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "24042",
        CodFisco: "B703",
        Abitanti: "7819",
      },
      {
        Istat: "16052",
        Comune: "Caprino Bergamasco",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "B710",
        Abitanti: "3120",
      },
      {
        Istat: "17038",
        Comune: "Capriolo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25031",
        CodFisco: "B711",
        Abitanti: "9322",
      },
      {
        Istat: "108015",
        Comune: "Carate Brianza",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20841",
        CodFisco: "B729",
        Abitanti: "17997",
      },
      {
        Istat: "13044",
        Comune: "Carate Urio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22010",
        CodFisco: "B730",
        Abitanti: "1216",
      },
      {
        Istat: "16053",
        Comune: "Caravaggio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24043",
        CodFisco: "B731",
        Abitanti: "16228",
      },
      {
        Istat: "12031",
        Comune: "Caravate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21032",
        CodFisco: "B732",
        Abitanti: "2632",
      },
      {
        Istat: "18030",
        Comune: "Carbonara al Ticino",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "B741",
        Abitanti: "1550",
      },
      {
        Istat: "20009",
        Comune: "Carbonara di Po",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "B739",
        Abitanti: "1344",
      },
      {
        Istat: "13045",
        Comune: "Carbonate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "22070",
        CodFisco: "B742",
        Abitanti: "2920",
      },
      {
        Istat: "12032",
        Comune: "Cardano al Campo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21010",
        CodFisco: "B754",
        Abitanti: "14542",
      },
      {
        Istat: "97014",
        Comune: "Carenno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23802",
        CodFisco: "B763",
        Abitanti: "1533",
      },
      {
        Istat: "13046",
        Comune: "Carimate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22060",
        CodFisco: "B778",
        Abitanti: "4320",
      },
      {
        Istat: "13047",
        Comune: "Carlazzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "B785",
        Abitanti: "2966",
      },
      {
        Istat: "12033",
        Comune: "Carnago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "B796",
        Abitanti: "6466",
      },
      {
        Istat: "108016",
        Comune: "Carnate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20866",
        CodFisco: "B798",
        Abitanti: "7270",
      },
      {
        Istat: "16055",
        Comune: "Carobbio degli Angeli",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "B801",
        Abitanti: "4619",
      },
      {
        Istat: "16056",
        Comune: "Carona",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "B803",
        Abitanti: "355",
      },
      {
        Istat: "12034",
        Comune: "Caronno Pertusella",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "21042",
        CodFisco: "B805",
        Abitanti: "16263",
      },
      {
        Istat: "12035",
        Comune: "Caronno Varesino",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "B807",
        Abitanti: "4944",
      },
      {
        Istat: "17039",
        Comune: "Carpenedolo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25013",
        CodFisco: "B817",
        Abitanti: "12698",
      },
      {
        Istat: "15050",
        Comune: "Carpiano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "B820",
        Abitanti: "3916",
      },
      {
        Istat: "15051",
        Comune: "Carugate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20061",
        CodFisco: "B850",
        Abitanti: "14540",
      },
      {
        Istat: "13048",
        Comune: "Carugo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22060",
        CodFisco: "B851",
        Abitanti: "6262",
      },
      {
        Istat: "16057",
        Comune: "Carvico",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "B854",
        Abitanti: "4651",
      },
      {
        Istat: "19016",
        Comune: "Casalbuttano ed Uniti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26011",
        CodFisco: "B869",
        Abitanti: "4112",
      },
      {
        Istat: "19017",
        Comune: "Casale Cremasco-Vidolasco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "B881",
        Abitanti: "1855",
      },
      {
        Istat: "12036",
        Comune: "Casale Litta",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "B875",
        Abitanti: "2674",
      },
      {
        Istat: "19018",
        Comune: "Casaletto Ceredano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "B889",
        Abitanti: "1188",
      },
      {
        Istat: "19019",
        Comune: "Casaletto di Sopra",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26014",
        CodFisco: "B890",
        Abitanti: "548",
      },
      {
        Istat: "98008",
        Comune: "Casaletto Lodigiano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26852",
        CodFisco: "B887",
        Abitanti: "2719",
      },
      {
        Istat: "19020",
        Comune: "Casaletto Vaprio",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "B891",
        Abitanti: "1784",
      },
      {
        Istat: "19021",
        Comune: "Casalmaggiore",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26041",
        CodFisco: "B898",
        Abitanti: "15073",
      },
      {
        Istat: "98009",
        Comune: "Casalmaiocco",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "26831",
        CodFisco: "B899",
        Abitanti: "3108",
      },
      {
        Istat: "19022",
        Comune: "Casalmorano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "B900",
        Abitanti: "1681",
      },
      {
        Istat: "20010",
        Comune: "Casalmoro",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "B901",
        Abitanti: "2243",
      },
      {
        Istat: "20011",
        Comune: "Casaloldo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "B907",
        Abitanti: "2624",
      },
      {
        Istat: "98010",
        Comune: "Casalpusterlengo",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26841",
        CodFisco: "B910",
        Abitanti: "15302",
      },
      {
        Istat: "20012",
        Comune: "Casalromano",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "B911",
        Abitanti: "1578",
      },
      {
        Istat: "12037",
        Comune: "Casalzuigno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "B921",
        Abitanti: "1360",
      },
      {
        Istat: "18031",
        Comune: "Casanova Lonati",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27041",
        CodFisco: "B929",
        Abitanti: "479",
      },
      {
        Istat: "97015",
        Comune: "Casargo",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23831",
        CodFisco: "B937",
        Abitanti: "874",
      },
      {
        Istat: "15055",
        Comune: "Casarile",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "B938",
        Abitanti: "3915",
      },
      {
        Istat: "13050",
        Comune: "Casasco d'Intelvi",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22022",
        CodFisco: "B942",
        Abitanti: "433",
      },
      {
        Istat: "97016",
        Comune: "Casatenovo",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23880",
        CodFisco: "B943",
        Abitanti: "12691",
      },
      {
        Istat: "18032",
        Comune: "Casatisma",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "B945",
        Abitanti: "889",
      },
      {
        Istat: "16058",
        Comune: "Casazza",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "B947",
        Abitanti: "4053",
      },
      {
        Istat: "12038",
        Comune: "Casciago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "B949",
        Abitanti: "3916",
      },
      {
        Istat: "18033",
        Comune: "Casei Gerola",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "B954",
        Abitanti: "2547",
      },
      {
        Istat: "98011",
        Comune: "Caselle Landi",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26842",
        CodFisco: "B961",
        Abitanti: "1687",
      },
      {
        Istat: "98012",
        Comune: "Caselle Lurani",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26853",
        CodFisco: "B958",
        Abitanti: "3155",
      },
      {
        Istat: "16059",
        Comune: "Casirate d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "B971",
        Abitanti: "3956",
      },
      {
        Istat: "13052",
        Comune: "Caslino d'Erba",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "B974",
        Abitanti: "1722",
      },
      {
        Istat: "13053",
        Comune: "Casnate con Bernate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "B977",
        Abitanti: "4936",
      },
      {
        Istat: "16060",
        Comune: "Casnigo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "B978",
        Abitanti: "3343",
      },
      {
        Istat: "18034",
        Comune: "Casorate Primo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "27022",
        CodFisco: "B988",
        Abitanti: "8425",
      },
      {
        Istat: "12039",
        Comune: "Casorate Sempione",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21011",
        CodFisco: "B987",
        Abitanti: "5747",
      },
      {
        Istat: "15058",
        Comune: "Casorezzo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "B989",
        Abitanti: "5384",
      },
      {
        Istat: "14013",
        Comune: "Caspoggio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "B993",
        Abitanti: "1513",
      },
      {
        Istat: "97017",
        Comune: "Cassago Brianza",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23893",
        CodFisco: "B996",
        Abitanti: "4393",
      },
      {
        Istat: "15059",
        Comune: "Cassano d'Adda",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "20062",
        CodFisco: "C003",
        Abitanti: "18767",
      },
      {
        Istat: "12040",
        Comune: "Cassano Magnago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21012",
        CodFisco: "C004",
        Abitanti: "21595",
      },
      {
        Istat: "12041",
        Comune: "Cassano Valcuvia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "B999",
        Abitanti: "663",
      },
      {
        Istat: "16061",
        Comune: "Cassiglio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "C007",
        Abitanti: "120",
      },
      {
        Istat: "15060",
        Comune: "Cassina de' Pecchi",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "C014",
        Abitanti: "13130",
      },
      {
        Istat: "13055",
        Comune: "Cassina Rizzardi",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "C020",
        Abitanti: "3224",
      },
      {
        Istat: "97018",
        Comune: "Cassina Valsassina",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23817",
        CodFisco: "C024",
        Abitanti: "481",
      },
      {
        Istat: "15061",
        Comune: "Cassinetta di Lugagnano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20081",
        CodFisco: "C033",
        Abitanti: "1879",
      },
      {
        Istat: "18035",
        Comune: "Cassolnovo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "381",
        CAP: "27023",
        CodFisco: "C038",
        Abitanti: "7116",
      },
      {
        Istat: "18036",
        Comune: "Castana",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "C050",
        Abitanti: "743",
      },
      {
        Istat: "15062",
        Comune: "Castano Primo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20022",
        CodFisco: "C052",
        Abitanti: "11026",
      },
      {
        Istat: "18037",
        Comune: "Casteggio",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27045",
        CodFisco: "C053",
        Abitanti: "6530",
      },
      {
        Istat: "17040",
        Comune: "Castegnato",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25045",
        CodFisco: "C055",
        Abitanti: "7987",
      },
      {
        Istat: "20014",
        Comune: "Castel d'Ario",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46033",
        CodFisco: "C076",
        Abitanti: "4882",
      },
      {
        Istat: "19024",
        Comune: "Castel Gabbiano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "C115",
        Abitanti: "474",
      },
      {
        Istat: "20015",
        Comune: "Castel Goffredo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46042",
        CodFisco: "C118",
        Abitanti: "12094",
      },
      {
        Istat: "17042",
        Comune: "Castel Mella",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "C208",
        Abitanti: "10803",
      },
      {
        Istat: "16063",
        Comune: "Castel Rozzone",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "C255",
        Abitanti: "2918",
      },
      {
        Istat: "20013",
        Comune: "Castelbelforte",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46032",
        CodFisco: "C059",
        Abitanti: "3029",
      },
      {
        Istat: "17041",
        Comune: "Castelcovati",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "C072",
        Abitanti: "6564",
      },
      {
        Istat: "19023",
        Comune: "Casteldidone",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26030",
        CodFisco: "C089",
        Abitanti: "592",
      },
      {
        Istat: "12042",
        Comune: "Castellanza",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21053",
        CodFisco: "C139",
        Abitanti: "14400",
      },
      {
        Istat: "19025",
        Comune: "Castelleone",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26012",
        CodFisco: "C153",
        Abitanti: "9603",
      },
      {
        Istat: "18038",
        Comune: "Castelletto di Branduzzo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "C157",
        Abitanti: "1065",
      },
      {
        Istat: "16062",
        Comune: "Castelli Calepio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "C079",
        Abitanti: "10016",
      },
      {
        Istat: "12043",
        Comune: "Castello Cabiaglio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "B312",
        Abitanti: "548",
      },
      {
        Istat: "18039",
        Comune: "Castello d'Agogna",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "C184",
        Abitanti: "1073",
      },
      {
        Istat: "14014",
        Comune: "Castello dell'Acqua",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "C186",
        Abitanti: "677",
      },
      {
        Istat: "97019",
        Comune: "Castello di Brianza",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23884",
        CodFisco: "C187",
        Abitanti: "2469",
      },
      {
        Istat: "20016",
        Comune: "Castellucchio",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46014",
        CodFisco: "C195",
        Abitanti: "5190",
      },
      {
        Istat: "13058",
        Comune: "Castelmarte",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "C206",
        Abitanti: "1303",
      },
      {
        Istat: "18040",
        Comune: "Castelnovetto",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "C213",
        Abitanti: "642",
      },
      {
        Istat: "98013",
        Comune: "Castelnuovo Bocca d'Adda",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26843",
        CodFisco: "C228",
        Abitanti: "1698",
      },
      {
        Istat: "13059",
        Comune: "Castelnuovo Bozzente",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "C220",
        Abitanti: "864",
      },
      {
        Istat: "12044",
        Comune: "Castelseprio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21050",
        CodFisco: "C273",
        Abitanti: "1296",
      },
      {
        Istat: "12045",
        Comune: "Castelveccana",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "C181",
        Abitanti: "2032",
      },
      {
        Istat: "19026",
        Comune: "Castelverde",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26022",
        CodFisco: "B129",
        Abitanti: "5664",
      },
      {
        Istat: "19027",
        Comune: "Castelvisconti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26010",
        CodFisco: "C290",
        Abitanti: "333",
      },
      {
        Istat: "17043",
        Comune: "Castenedolo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25014",
        CodFisco: "C293",
        Abitanti: "11215",
      },
      {
        Istat: "98014",
        Comune: "Castiglione d'Adda",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26823",
        CodFisco: "C304",
        Abitanti: "4939",
      },
      {
        Istat: "20017",
        Comune: "Castiglione delle Stiviere",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46043",
        CodFisco: "C312",
        Abitanti: "22700",
      },
      {
        Istat: "13060",
        Comune: "Castiglione d'Intelvi",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22023",
        CodFisco: "C299",
        Abitanti: "1031",
      },
      {
        Istat: "12046",
        Comune: "Castiglione Olona",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21043",
        CodFisco: "C300",
        Abitanti: "7906",
      },
      {
        Istat: "14015",
        Comune: "Castione Andevenno",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23012",
        CodFisco: "C325",
        Abitanti: "1554",
      },
      {
        Istat: "16064",
        Comune: "Castione della Presolana",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "C324",
        Abitanti: "3476",
      },
      {
        Istat: "98015",
        Comune: "Castiraga Vidardo",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26866",
        CodFisco: "C329",
        Abitanti: "2623",
      },
      {
        Istat: "17044",
        Comune: "Casto",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "C330",
        Abitanti: "1900",
      },
      {
        Istat: "17045",
        Comune: "Castrezzato",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "C332",
        Abitanti: "7018",
      },
      {
        Istat: "16065",
        Comune: "Castro",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24063",
        CodFisco: "C337",
        Abitanti: "1438",
      },
      {
        Istat: "12047",
        Comune: "Castronno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21040",
        CodFisco: "C343",
        Abitanti: "5361",
      },
      {
        Istat: "18041",
        Comune: "Cava Manara",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27051",
        CodFisco: "C360",
        Abitanti: "6650",
      },
      {
        Istat: "98016",
        Comune: "Cavacurta",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26844",
        CodFisco: "C362",
        Abitanti: "876",
      },
      {
        Istat: "13061",
        Comune: "Cavallasca",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "C374",
        Abitanti: "2971",
      },
      {
        Istat: "13062",
        Comune: "Cavargna",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "C381",
        Abitanti: "248",
      },
      {
        Istat: "12048",
        Comune: "Cavaria con Premezzo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21044",
        CodFisco: "C382",
        Abitanti: "5718",
      },
      {
        Istat: "98017",
        Comune: "Cavenago d'Adda",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26824",
        CodFisco: "C394",
        Abitanti: "2294",
      },
      {
        Istat: "108017",
        Comune: "Cavenago di Brianza",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20873",
        CodFisco: "C395",
        Abitanti: "6807",
      },
      {
        Istat: "16066",
        Comune: "Cavernago",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "C396",
        Abitanti: "2450",
      },
      {
        Istat: "20018",
        Comune: "Cavriana",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "C406",
        Abitanti: "3913",
      },
      {
        Istat: "12049",
        Comune: "Cazzago Brabbia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "C409",
        Abitanti: "820",
      },
      {
        Istat: "17046",
        Comune: "Cazzago San Martino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25046",
        CodFisco: "C408",
        Abitanti: "11060",
      },
      {
        Istat: "16067",
        Comune: "Cazzano Sant'Andrea",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24026",
        CodFisco: "C410",
        Abitanti: "1604",
      },
      {
        Istat: "18042",
        Comune: "Cecima",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "C414",
        Abitanti: "237",
      },
      {
        Istat: "17047",
        Comune: "Cedegolo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25051",
        CodFisco: "C417",
        Abitanti: "1256",
      },
      {
        Istat: "14016",
        Comune: "Cedrasco",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "C418",
        Abitanti: "471",
      },
      {
        Istat: "19028",
        Comune: "Cella Dati",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26040",
        CodFisco: "C435",
        Abitanti: "558",
      },
      {
        Istat: "17048",
        Comune: "Cellatica",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "C439",
        Abitanti: "4943",
      },
      {
        Istat: "16068",
        Comune: "Cenate Sopra",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "C456",
        Abitanti: "2524",
      },
      {
        Istat: "16069",
        Comune: "Cenate Sotto",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24069",
        CodFisco: "C457",
        Abitanti: "3463",
      },
      {
        Istat: "16070",
        Comune: "Cene",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "C459",
        Abitanti: "4225",
      },
      {
        Istat: "13063",
        Comune: "Cerano d'Intelvi",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "C482",
        Abitanti: "550",
      },
      {
        Istat: "18043",
        Comune: "Ceranova",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "C484",
        Abitanti: "1834",
      },
      {
        Istat: "14017",
        Comune: "Cercino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23016",
        CodFisco: "C493",
        Abitanti: "757",
      },
      {
        Istat: "20019",
        Comune: "Ceresara",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "C502",
        Abitanti: "2708",
      },
      {
        Istat: "16071",
        Comune: "Cerete",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "C506",
        Abitanti: "1669",
      },
      {
        Istat: "18044",
        Comune: "Ceretto Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "C508",
        Abitanti: "208",
      },
      {
        Istat: "18045",
        Comune: "Cergnago",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "C509",
        Abitanti: "769",
      },
      {
        Istat: "108018",
        Comune: "Ceriano Laghetto",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20816",
        CodFisco: "C512",
        Abitanti: "6392",
      },
      {
        Istat: "13064",
        Comune: "Cermenate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22072",
        CodFisco: "C516",
        Abitanti: "9097",
      },
      {
        Istat: "13065",
        Comune: "Cernobbio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22012",
        CodFisco: "C520",
        Abitanti: "7059",
      },
      {
        Istat: "97020",
        Comune: "Cernusco Lombardone",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23870",
        CodFisco: "C521",
        Abitanti: "3870",
      },
      {
        Istat: "15070",
        Comune: "Cernusco sul Naviglio",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20063",
        CodFisco: "C523",
        Abitanti: "31058",
      },
      {
        Istat: "15071",
        Comune: "Cerro al Lambro",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20070",
        CodFisco: "C536",
        Abitanti: "4925",
      },
      {
        Istat: "15072",
        Comune: "Cerro Maggiore",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20023",
        CodFisco: "C537",
        Abitanti: "14794",
      },
      {
        Istat: "18046",
        Comune: "Certosa di Pavia",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27012",
        CodFisco: "C541",
        Abitanti: "4967",
      },
      {
        Istat: "17049",
        Comune: "Cerveno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "C549",
        Abitanti: "674",
      },
      {
        Istat: "18047",
        Comune: "Cervesina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "C551",
        Abitanti: "1216",
      },
      {
        Istat: "98018",
        Comune: "Cervignano d'Adda",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "26832",
        CodFisco: "C555",
        Abitanti: "2084",
      },
      {
        Istat: "97021",
        Comune: "Cesana Brianza",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23861",
        CodFisco: "C563",
        Abitanti: "2343",
      },
      {
        Istat: "15074",
        Comune: "Cesano Boscone",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "C565",
        Abitanti: "23935",
      },
      {
        Istat: "108019",
        Comune: "Cesano Maderno",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20811",
        CodFisco: "C566",
        Abitanti: "37291",
      },
      {
        Istat: "15076",
        Comune: "Cesate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20020",
        CodFisco: "C569",
        Abitanti: "13977",
      },
      {
        Istat: "17050",
        Comune: "Ceto",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "C585",
        Abitanti: "1963",
      },
      {
        Istat: "17051",
        Comune: "Cevo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "C591",
        Abitanti: "949",
      },
      {
        Istat: "17052",
        Comune: "Chiari",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25032",
        CodFisco: "C618",
        Abitanti: "18887",
      },
      {
        Istat: "14018",
        Comune: "Chiavenna",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23022",
        CodFisco: "C623",
        Abitanti: "7358",
      },
      {
        Istat: "14019",
        Comune: "Chiesa in Valmalenco",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23023",
        CodFisco: "C628",
        Abitanti: "2626",
      },
      {
        Istat: "19029",
        Comune: "Chieve",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "C634",
        Abitanti: "2255",
      },
      {
        Istat: "16072",
        Comune: "Chignolo d'Isola",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "C635",
        Abitanti: "3239",
      },
      {
        Istat: "18048",
        Comune: "Chignolo Po",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27013",
        CodFisco: "C637",
        Abitanti: "4109",
      },
      {
        Istat: "16073",
        Comune: "Chiuduno",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "C649",
        Abitanti: "5861",
      },
      {
        Istat: "14020",
        Comune: "Chiuro",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "C651",
        Abitanti: "2553",
      },
      {
        Istat: "19030",
        Comune: "Cicognolo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26030",
        CodFisco: "C678",
        Abitanti: "932",
      },
      {
        Istat: "18049",
        Comune: "Cigognola",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "C684",
        Abitanti: "1363",
      },
      {
        Istat: "17053",
        Comune: "Cigole",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "C685",
        Abitanti: "1669",
      },
      {
        Istat: "18050",
        Comune: "Cilavegna",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "381",
        CAP: "27024",
        CodFisco: "C686",
        Abitanti: "5653",
      },
      {
        Istat: "17054",
        Comune: "Cimbergo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "C691",
        Abitanti: "579",
      },
      {
        Istat: "19031",
        Comune: "Cingia de' Botti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26042",
        CodFisco: "C703",
        Abitanti: "1325",
      },
      {
        Istat: "15077",
        Comune: "Cinisello Balsamo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20092",
        CodFisco: "C707",
        Abitanti: "74150",
      },
      {
        Istat: "14021",
        Comune: "Cino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "C709",
        Abitanti: "379",
      },
      {
        Istat: "13068",
        Comune: "Cirimido",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "C724",
        Abitanti: "2164",
      },
      {
        Istat: "16074",
        Comune: "Cisano Bergamasco",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24034",
        CodFisco: "C728",
        Abitanti: "6316",
      },
      {
        Istat: "16075",
        Comune: "Ciserano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "C730",
        Abitanti: "5769",
      },
      {
        Istat: "12050",
        Comune: "Cislago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "21040",
        CodFisco: "C732",
        Abitanti: "10063",
      },
      {
        Istat: "15078",
        Comune: "Cisliano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "C733",
        Abitanti: "4057",
      },
      {
        Istat: "12051",
        Comune: "Cittiglio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21033",
        CodFisco: "C751",
        Abitanti: "4012",
      },
      {
        Istat: "97022",
        Comune: "Civate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23862",
        CodFisco: "C752",
        Abitanti: "4062",
      },
      {
        Istat: "13070",
        Comune: "Civenna",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "C754",
        Abitanti: "720",
      },
      {
        Istat: "16076",
        Comune: "Cividate al Piano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "C759",
        Abitanti: "5194",
      },
      {
        Istat: "17055",
        Comune: "Cividate Camuno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "C760",
        Abitanti: "2776",
      },
      {
        Istat: "14022",
        Comune: "Civo",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "C785",
        Abitanti: "1093",
      },
      {
        Istat: "13071",
        Comune: "Claino con Osteno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "C787",
        Abitanti: "554",
      },
      {
        Istat: "12052",
        Comune: "Clivio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "C796",
        Abitanti: "1944",
      },
      {
        Istat: "16077",
        Comune: "Clusone",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24023",
        CodFisco: "C800",
        Abitanti: "8793",
      },
      {
        Istat: "17056",
        Comune: "Coccaglio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "C806",
        Abitanti: "8575",
      },
      {
        Istat: "12053",
        Comune: "Cocquio-Trevisago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21034",
        CodFisco: "C810",
        Abitanti: "4813",
      },
      {
        Istat: "18051",
        Comune: "Codevilla",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "C813",
        Abitanti: "1010",
      },
      {
        Istat: "98019",
        Comune: "Codogno",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26845",
        CodFisco: "C816",
        Abitanti: "15765",
      },
      {
        Istat: "108020",
        Comune: "Cogliate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20815",
        CodFisco: "C820",
        Abitanti: "8409",
      },
      {
        Istat: "16078",
        Comune: "Colere",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "C835",
        Abitanti: "1141",
      },
      {
        Istat: "97023",
        Comune: "Colico",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23823",
        CodFisco: "C839",
        Abitanti: "7561",
      },
      {
        Istat: "97024",
        Comune: "Colle Brianza",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23886",
        CodFisco: "C851",
        Abitanti: "1762",
      },
      {
        Istat: "17057",
        Comune: "Collebeato",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "C850",
        Abitanti: "4771",
      },
      {
        Istat: "17058",
        Comune: "Collio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "C883",
        Abitanti: "2292",
      },
      {
        Istat: "17059",
        Comune: "Cologne",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25033",
        CodFisco: "C893",
        Abitanti: "7600",
      },
      {
        Istat: "16079",
        Comune: "Cologno al Serio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24055",
        CodFisco: "C894",
        Abitanti: "10759",
      },
      {
        Istat: "15081",
        Comune: "Cologno Monzese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20093",
        CodFisco: "C895",
        Abitanti: "47794",
      },
      {
        Istat: "13074",
        Comune: "Colonno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22010",
        CodFisco: "C902",
        Abitanti: "539",
      },
      {
        Istat: "14023",
        Comune: "Colorina",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "C903",
        Abitanti: "1484",
      },
      {
        Istat: "15082",
        Comune: "Colturano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "C908",
        Abitanti: "1975",
      },
      {
        Istat: "16080",
        Comune: "Colzate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "C910",
        Abitanti: "1674",
      },
      {
        Istat: "12054",
        Comune: "Comabbio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21020",
        CodFisco: "C911",
        Abitanti: "1181",
      },
      {
        Istat: "98020",
        Comune: "Comazzo",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "26833",
        CodFisco: "C917",
        Abitanti: "2155",
      },
      {
        Istat: "12055",
        Comune: "Comerio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21025",
        CodFisco: "C922",
        Abitanti: "2668",
      },
      {
        Istat: "17060",
        Comune: "Comezzano-Cizzago",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "C925",
        Abitanti: "3702",
      },
      {
        Istat: "20020",
        Comune: "Commessaggio",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46010",
        CodFisco: "C930",
        Abitanti: "1180",
      },
      {
        Istat: "13075",
        Comune: "Como",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22100",
        CodFisco: "C933",
        Abitanti: "85263",
      },
      {
        Istat: "16081",
        Comune: "Comun Nuovo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "C937",
        Abitanti: "4144",
      },
      {
        Istat: "17061",
        Comune: "Concesio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25062",
        CodFisco: "C948",
        Abitanti: "15005",
      },
      {
        Istat: "108021",
        Comune: "Concorezzo",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20863",
        CodFisco: "C952",
        Abitanti: "15371",
      },
      {
        Istat: "18052",
        Comune: "Confienza",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "C958",
        Abitanti: "1663",
      },
      {
        Istat: "18053",
        Comune: "Copiano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "C979",
        Abitanti: "1801",
      },
      {
        Istat: "18054",
        Comune: "Corana",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "C982",
        Abitanti: "781",
      },
      {
        Istat: "15085",
        Comune: "Corbetta",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20011",
        CodFisco: "C986",
        Abitanti: "17365",
      },
      {
        Istat: "15086",
        Comune: "Cormano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20032",
        CodFisco: "D013",
        Abitanti: "20270",
      },
      {
        Istat: "16082",
        Comune: "Corna Imagna",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "D015",
        Abitanti: "969",
      },
      {
        Istat: "16249",
        Comune: "Cornalba",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24017",
        CodFisco: "D016",
        Abitanti: "310",
      },
      {
        Istat: "18055",
        Comune: "Cornale",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "D017",
        Abitanti: "747",
      },
      {
        Istat: "15087",
        Comune: "Cornaredo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "D018",
        Abitanti: "20546",
      },
      {
        Istat: "108053",
        Comune: "Cornate d'Adda",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20872",
        CodFisco: "D019",
        Abitanti: "10464",
      },
      {
        Istat: "98021",
        Comune: "Cornegliano Laudense",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26854",
        CodFisco: "D021",
        Abitanti: "2881",
      },
      {
        Istat: "98022",
        Comune: "Corno Giovine",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26846",
        CodFisco: "D028",
        Abitanti: "1196",
      },
      {
        Istat: "98023",
        Comune: "Cornovecchio",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26842",
        CodFisco: "D029",
        Abitanti: "239",
      },
      {
        Istat: "108022",
        Comune: "Correzzana",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20856",
        CodFisco: "D038",
        Abitanti: "2648",
      },
      {
        Istat: "13077",
        Comune: "Corrido",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "D041",
        Abitanti: "823",
      },
      {
        Istat: "15093",
        Comune: "Corsico",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20094",
        CodFisco: "D045",
        Abitanti: "34507",
      },
      {
        Istat: "19032",
        Comune: "Corte de' Cortesi con Cignone",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26020",
        CodFisco: "D056",
        Abitanti: "1137",
      },
      {
        Istat: "19033",
        Comune: "Corte de' Frati",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26010",
        CodFisco: "D057",
        Abitanti: "1453",
      },
      {
        Istat: "17062",
        Comune: "Corte Franca",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25040",
        CodFisco: "D058",
        Abitanti: "7189",
      },
      {
        Istat: "98024",
        Comune: "Corte Palasio",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26834",
        CodFisco: "D068",
        Abitanti: "1597",
      },
      {
        Istat: "17063",
        Comune: "Corteno Golgi",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "D064",
        Abitanti: "2028",
      },
      {
        Istat: "97025",
        Comune: "Cortenova",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23813",
        CodFisco: "D065",
        Abitanti: "1295",
      },
      {
        Istat: "16083",
        Comune: "Cortenuova",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "D066",
        Abitanti: "1959",
      },
      {
        Istat: "18056",
        Comune: "Corteolona",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27014",
        CodFisco: "D067",
        Abitanti: "2201",
      },
      {
        Istat: "18057",
        Comune: "Corvino San Quirico",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "D081",
        Abitanti: "1053",
      },
      {
        Istat: "17064",
        Comune: "Corzano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "D082",
        Abitanti: "1358",
      },
      {
        Istat: "14024",
        Comune: "Cosio Valtellino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23013",
        CodFisco: "D088",
        Abitanti: "5429",
      },
      {
        Istat: "18058",
        Comune: "Costa de' Nobili",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "D109",
        Abitanti: "360",
      },
      {
        Istat: "16084",
        Comune: "Costa di Mezzate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D110",
        Abitanti: "3369",
      },
      {
        Istat: "97026",
        Comune: "Costa Masnaga",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23845",
        CodFisco: "D112",
        Abitanti: "4806",
      },
      {
        Istat: "16247",
        Comune: "Costa Serina",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "D111",
        Abitanti: "980",
      },
      {
        Istat: "16085",
        Comune: "Costa Valle Imagna",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "D103",
        Abitanti: "624",
      },
      {
        Istat: "16086",
        Comune: "Costa Volpino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24062",
        CodFisco: "D117",
        Abitanti: "9333",
      },
      {
        Istat: "16087",
        Comune: "Covo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "D126",
        Abitanti: "4140",
      },
      {
        Istat: "18059",
        Comune: "Cozzo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "D127",
        Abitanti: "372",
      },
      {
        Istat: "97027",
        Comune: "Crandola Valsassina",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23832",
        CodFisco: "D131",
        Abitanti: "267",
      },
      {
        Istat: "16088",
        Comune: "Credaro",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D139",
        Abitanti: "3400",
      },
      {
        Istat: "19034",
        Comune: "Credera Rubbiano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "D141",
        Abitanti: "1654",
      },
      {
        Istat: "19035",
        Comune: "Crema",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26013",
        CodFisco: "D142",
        Abitanti: "34144",
      },
      {
        Istat: "97028",
        Comune: "Cremella",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23894",
        CodFisco: "D143",
        Abitanti: "1794",
      },
      {
        Istat: "12056",
        Comune: "Cremenaga",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "D144",
        Abitanti: "801",
      },
      {
        Istat: "97029",
        Comune: "Cremeno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23814",
        CodFisco: "D145",
        Abitanti: "1428",
      },
      {
        Istat: "13083",
        Comune: "Cremia",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "D147",
        Abitanti: "718",
      },
      {
        Istat: "19036",
        Comune: "Cremona",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26100",
        CodFisco: "D150",
        Abitanti: "72147",
      },
      {
        Istat: "19037",
        Comune: "Cremosano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "D151",
        Abitanti: "1555",
      },
      {
        Istat: "98025",
        Comune: "Crespiatica",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26835",
        CodFisco: "D159",
        Abitanti: "2186",
      },
      {
        Istat: "12057",
        Comune: "Crosio della Valle",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "D185",
        Abitanti: "618",
      },
      {
        Istat: "19038",
        Comune: "Crotta d'Adda",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26020",
        CodFisco: "D186",
        Abitanti: "678",
      },
      {
        Istat: "12058",
        Comune: "Cuasso al Monte",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "D192",
        Abitanti: "3592",
      },
      {
        Istat: "13084",
        Comune: "Cucciago",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22060",
        CodFisco: "D196",
        Abitanti: "3472",
      },
      {
        Istat: "15096",
        Comune: "Cuggiono",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20012",
        CodFisco: "D198",
        Abitanti: "8227",
      },
      {
        Istat: "12059",
        Comune: "Cugliate-Fabiasco",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "D199",
        Abitanti: "3109",
      },
      {
        Istat: "19039",
        Comune: "Cumignano sul Naviglio",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "D203",
        Abitanti: "442",
      },
      {
        Istat: "12060",
        Comune: "Cunardo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21035",
        CodFisco: "D204",
        Abitanti: "2939",
      },
      {
        Istat: "18060",
        Comune: "Cura Carpignano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "B824",
        Abitanti: "4215",
      },
      {
        Istat: "12061",
        Comune: "Curiglia con Monteviasco",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "D217",
        Abitanti: "189",
      },
      {
        Istat: "16089",
        Comune: "Curno",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24035",
        CodFisco: "D221",
        Abitanti: "7752",
      },
      {
        Istat: "20021",
        Comune: "Curtatone",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46010",
        CodFisco: "D227",
        Abitanti: "14482",
      },
      {
        Istat: "15097",
        Comune: "Cusago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "D229",
        Abitanti: "3547",
      },
      {
        Istat: "15098",
        Comune: "Cusano Milanino",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20095",
        CodFisco: "D231",
        Abitanti: "19547",
      },
      {
        Istat: "13085",
        Comune: "Cusino",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "D232",
        Abitanti: "245",
      },
      {
        Istat: "16090",
        Comune: "Cusio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "D233",
        Abitanti: "265",
      },
      {
        Istat: "12062",
        Comune: "Cuveglio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "D238",
        Abitanti: "3443",
      },
      {
        Istat: "12063",
        Comune: "Cuvio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "D239",
        Abitanti: "1688",
      },
      {
        Istat: "15099",
        Comune: "Dairago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "D244",
        Abitanti: "5780",
      },
      {
        Istat: "16091",
        Comune: "Dalmine",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24044",
        CodFisco: "D245",
        Abitanti: "23266",
      },
      {
        Istat: "17065",
        Comune: "Darfo Boario Terme",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25047",
        CodFisco: "D251",
        Abitanti: "15751",
      },
      {
        Istat: "12064",
        Comune: "Daverio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "D256",
        Abitanti: "3073",
      },
      {
        Istat: "14025",
        Comune: "Dazio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "D258",
        Abitanti: "438",
      },
      {
        Istat: "14026",
        Comune: "Delebio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23014",
        CodFisco: "D266",
        Abitanti: "3215",
      },
      {
        Istat: "17066",
        Comune: "Dello",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "D270",
        Abitanti: "5575",
      },
      {
        Istat: "19040",
        Comune: "Derovere",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26040",
        CodFisco: "D278",
        Abitanti: "316",
      },
      {
        Istat: "97030",
        Comune: "Dervio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23824",
        CodFisco: "D280",
        Abitanti: "2774",
      },
      {
        Istat: "17067",
        Comune: "Desenzano del Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25015",
        CodFisco: "D284",
        Abitanti: "27229",
      },
      {
        Istat: "108023",
        Comune: "Desio",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20832",
        CodFisco: "D286",
        Abitanti: "40661",
      },
      {
        Istat: "13087",
        Comune: "Dizzasco",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "D310",
        Abitanti: "578",
      },
      {
        Istat: "97031",
        Comune: "Dolzago",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23843",
        CodFisco: "D327",
        Abitanti: "2304",
      },
      {
        Istat: "13089",
        Comune: "Domaso",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22013",
        CodFisco: "D329",
        Abitanti: "1462",
      },
      {
        Istat: "13090",
        Comune: "Dongo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22014",
        CodFisco: "D341",
        Abitanti: "3491",
      },
      {
        Istat: "97032",
        Comune: "Dorio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23824",
        CodFisco: "D346",
        Abitanti: "353",
      },
      {
        Istat: "18061",
        Comune: "Dorno",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "D348",
        Abitanti: "4654",
      },
      {
        Istat: "20022",
        Comune: "Dosolo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "46030",
        CodFisco: "D351",
        Abitanti: "3449",
      },
      {
        Istat: "16092",
        Comune: "Dossena",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "D352",
        Abitanti: "966",
      },
      {
        Istat: "13092",
        Comune: "Dosso del Liro",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "D355",
        Abitanti: "275",
      },
      {
        Istat: "19041",
        Comune: "Dovera",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "D358",
        Abitanti: "3957",
      },
      {
        Istat: "15101",
        Comune: "Dresano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20070",
        CodFisco: "D367",
        Abitanti: "2992",
      },
      {
        Istat: "13093",
        Comune: "Drezzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "D369",
        Abitanti: "1218",
      },
      {
        Istat: "19042",
        Comune: "Drizzona",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26034",
        CodFisco: "D370",
        Abitanti: "565",
      },
      {
        Istat: "14027",
        Comune: "Dubino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23015",
        CodFisco: "D377",
        Abitanti: "3549",
      },
      {
        Istat: "12065",
        Comune: "Dumenza",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "D384",
        Abitanti: "1463",
      },
      {
        Istat: "12066",
        Comune: "Duno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "D385",
        Abitanti: "162",
      },
      {
        Istat: "17068",
        Comune: "Edolo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25048",
        CodFisco: "D391",
        Abitanti: "4558",
      },
      {
        Istat: "97033",
        Comune: "Ello",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23848",
        CodFisco: "D398",
        Abitanti: "1281",
      },
      {
        Istat: "16093",
        Comune: "Endine Gaiano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D406",
        Abitanti: "3553",
      },
      {
        Istat: "16094",
        Comune: "Entratico",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D411",
        Abitanti: "1887",
      },
      {
        Istat: "13095",
        Comune: "Erba",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22036",
        CodFisco: "D416",
        Abitanti: "16949",
      },
      {
        Istat: "17069",
        Comune: "Erbusco",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "D421",
        Abitanti: "8656",
      },
      {
        Istat: "97034",
        Comune: "Erve",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23805",
        CodFisco: "D428",
        Abitanti: "779",
      },
      {
        Istat: "17070",
        Comune: "Esine",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "D434",
        Abitanti: "5384",
      },
      {
        Istat: "97035",
        Comune: "Esino Lario",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23825",
        CodFisco: "D436",
        Abitanti: "766",
      },
      {
        Istat: "13097",
        Comune: "Eupilio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "D445",
        Abitanti: "2778",
      },
      {
        Istat: "14028",
        Comune: "Faedo Valtellino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "D456",
        Abitanti: "558",
      },
      {
        Istat: "13098",
        Comune: "Faggeto Lario",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "D462",
        Abitanti: "1253",
      },
      {
        Istat: "12067",
        Comune: "Fagnano Olona",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21054",
        CodFisco: "D467",
        Abitanti: "12146",
      },
      {
        Istat: "13099",
        Comune: "Faloppio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "D482",
        Abitanti: "4192",
      },
      {
        Istat: "16096",
        Comune: "Fara Gera d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24045",
        CodFisco: "D490",
        Abitanti: "7970",
      },
      {
        Istat: "16097",
        Comune: "Fara Olivana con Sola",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24058",
        CodFisco: "D491",
        Abitanti: "1329",
      },
      {
        Istat: "20023",
        Comune: "Felonica",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46022",
        CodFisco: "D529",
        Abitanti: "1480",
      },
      {
        Istat: "13100",
        Comune: "Fenegrò",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "D531",
        Abitanti: "3138",
      },
      {
        Istat: "12068",
        Comune: "Ferno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21010",
        CodFisco: "D543",
        Abitanti: "6876",
      },
      {
        Istat: "12069",
        Comune: "Ferrera di Varese",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "D551",
        Abitanti: "696",
      },
      {
        Istat: "18062",
        Comune: "Ferrera Erbognone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27032",
        CodFisco: "D552",
        Abitanti: "1137",
      },
      {
        Istat: "19043",
        Comune: "Fiesco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26010",
        CodFisco: "D574",
        Abitanti: "1179",
      },
      {
        Istat: "17071",
        Comune: "Fiesse",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "D576",
        Abitanti: "2198",
      },
      {
        Istat: "13101",
        Comune: "Figino Serenza",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22060",
        CodFisco: "D579",
        Abitanti: "5243",
      },
      {
        Istat: "16098",
        Comune: "Filago",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "D588",
        Abitanti: "3224",
      },
      {
        Istat: "18063",
        Comune: "Filighera",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "D594",
        Abitanti: "846",
      },
      {
        Istat: "16099",
        Comune: "Fino del Monte",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "D604",
        Abitanti: "1153",
      },
      {
        Istat: "13102",
        Comune: "Fino Mornasco",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22073",
        CodFisco: "D605",
        Abitanti: "9614",
      },
      {
        Istat: "16100",
        Comune: "Fiorano al Serio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "D606",
        Abitanti: "3097",
      },
      {
        Istat: "17072",
        Comune: "Flero",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "D634",
        Abitanti: "8525",
      },
      {
        Istat: "98026",
        Comune: "Fombio",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26861",
        CodFisco: "D660",
        Abitanti: "2278",
      },
      {
        Istat: "16101",
        Comune: "Fontanella",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24056",
        CodFisco: "D672",
        Abitanti: "4339",
      },
      {
        Istat: "16102",
        Comune: "Fonteno",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D684",
        Abitanti: "691",
      },
      {
        Istat: "16103",
        Comune: "Foppolo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "D688",
        Abitanti: "206",
      },
      {
        Istat: "14029",
        Comune: "Forcola",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "D694",
        Abitanti: "842",
      },
      {
        Istat: "16104",
        Comune: "Foresto Sparso",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D697",
        Abitanti: "3151",
      },
      {
        Istat: "19044",
        Comune: "Formigara",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "D710",
        Abitanti: "1146",
      },
      {
        Istat: "16105",
        Comune: "Fornovo San Giovanni",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "D727",
        Abitanti: "3305",
      },
      {
        Istat: "18064",
        Comune: "Fortunago",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "D732",
        Abitanti: "391",
      },
      {
        Istat: "18065",
        Comune: "Frascarolo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "D771",
        Abitanti: "1241",
      },
      {
        Istat: "16106",
        Comune: "Fuipiano Valle Imagna",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "D817",
        Abitanti: "226",
      },
      {
        Istat: "14030",
        Comune: "Fusine",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "D830",
        Abitanti: "633",
      },
      {
        Istat: "19045",
        Comune: "Gabbioneta-Binanuova",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26030",
        CodFisco: "D834",
        Abitanti: "955",
      },
      {
        Istat: "19046",
        Comune: "Gadesco-Pieve Delmona",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26030",
        CodFisco: "D841",
        Abitanti: "2028",
      },
      {
        Istat: "15103",
        Comune: "Gaggiano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20083",
        CodFisco: "D845",
        Abitanti: "8975",
      },
      {
        Istat: "97036",
        Comune: "Galbiate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23851",
        CodFisco: "D865",
        Abitanti: "8663",
      },
      {
        Istat: "98027",
        Comune: "Galgagnano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26832",
        CodFisco: "D868",
        Abitanti: "1214",
      },
      {
        Istat: "12070",
        Comune: "Gallarate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21013",
        CodFisco: "D869",
        Abitanti: "51751",
      },
      {
        Istat: "12071",
        Comune: "Galliate Lombardo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "D871",
        Abitanti: "993",
      },
      {
        Istat: "18066",
        Comune: "Galliavola",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27034",
        CodFisco: "D873",
        Abitanti: "227",
      },
      {
        Istat: "17073",
        Comune: "Gambara",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "D891",
        Abitanti: "4809",
      },
      {
        Istat: "18067",
        Comune: "Gambarana",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "D892",
        Abitanti: "254",
      },
      {
        Istat: "18068",
        Comune: "Gambolò",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "381",
        CAP: "27025",
        CodFisco: "D901",
        Abitanti: "10231",
      },
      {
        Istat: "16107",
        Comune: "Gandellino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "D903",
        Abitanti: "1065",
      },
      {
        Istat: "16108",
        Comune: "Gandino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24024",
        CodFisco: "D905",
        Abitanti: "5697",
      },
      {
        Istat: "16109",
        Comune: "Gandosso",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D906",
        Abitanti: "1527",
      },
      {
        Istat: "15105",
        Comune: "Garbagnate Milanese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20024",
        CodFisco: "D912",
        Abitanti: "27193",
      },
      {
        Istat: "97037",
        Comune: "Garbagnate Monastero",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23846",
        CodFisco: "D913",
        Abitanti: "2431",
      },
      {
        Istat: "17074",
        Comune: "Gardone Riviera",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25083",
        CodFisco: "D917",
        Abitanti: "2757",
      },
      {
        Istat: "17075",
        Comune: "Gardone Val Trompia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25063",
        CodFisco: "D918",
        Abitanti: "11938",
      },
      {
        Istat: "17076",
        Comune: "Gargnano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25084",
        CodFisco: "D924",
        Abitanti: "3050",
      },
      {
        Istat: "18069",
        Comune: "Garlasco",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27026",
        CodFisco: "D925",
        Abitanti: "9888",
      },
      {
        Istat: "97038",
        Comune: "Garlate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23852",
        CodFisco: "D926",
        Abitanti: "2675",
      },
      {
        Istat: "13106",
        Comune: "Garzeno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "D930",
        Abitanti: "862",
      },
      {
        Istat: "17077",
        Comune: "Gavardo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25085",
        CodFisco: "D940",
        Abitanti: "11686",
      },
      {
        Istat: "16110",
        Comune: "Gaverina Terme",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "D943",
        Abitanti: "931",
      },
      {
        Istat: "12072",
        Comune: "Gavirate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21026",
        CodFisco: "D946",
        Abitanti: "9338",
      },
      {
        Istat: "20024",
        Comune: "Gazoldo degli Ippoliti",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "D949",
        Abitanti: "3033",
      },
      {
        Istat: "12073",
        Comune: "Gazzada Schianno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21045",
        CodFisco: "D951",
        Abitanti: "4681",
      },
      {
        Istat: "16111",
        Comune: "Gazzaniga",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24025",
        CodFisco: "D952",
        Abitanti: "5161",
      },
      {
        Istat: "20025",
        Comune: "Gazzuolo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46010",
        CodFisco: "D959",
        Abitanti: "2427",
      },
      {
        Istat: "12074",
        Comune: "Gemonio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21036",
        CodFisco: "D963",
        Abitanti: "2907",
      },
      {
        Istat: "19047",
        Comune: "Genivolta",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "D966",
        Abitanti: "1188",
      },
      {
        Istat: "18070",
        Comune: "Genzone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27014",
        CodFisco: "D973",
        Abitanti: "374",
      },
      {
        Istat: "13107",
        Comune: "Gera Lario",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "D974",
        Abitanti: "969",
      },
      {
        Istat: "18071",
        Comune: "Gerenzago",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "D980",
        Abitanti: "1379",
      },
      {
        Istat: "12075",
        Comune: "Gerenzano",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "21040",
        CodFisco: "D981",
        Abitanti: "10401",
      },
      {
        Istat: "12076",
        Comune: "Germignaga",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "D987",
        Abitanti: "3809",
      },
      {
        Istat: "14031",
        Comune: "Gerola Alta",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "D990",
        Abitanti: "197",
      },
      {
        Istat: "16112",
        Comune: "Gerosa",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "D991",
        Abitanti: "360",
      },
      {
        Istat: "19048",
        Comune: "Gerre de' Caprioli",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26040",
        CodFisco: "D993",
        Abitanti: "1322",
      },
      {
        Istat: "15106",
        Comune: "Gessate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "D995",
        Abitanti: "8690",
      },
      {
        Istat: "17078",
        Comune: "Ghedi",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25016",
        CodFisco: "D999",
        Abitanti: "18694",
      },
      {
        Istat: "16113",
        Comune: "Ghisalba",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "E006",
        Abitanti: "5945",
      },
      {
        Istat: "17079",
        Comune: "Gianico",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "E010",
        Abitanti: "2219",
      },
      {
        Istat: "13109",
        Comune: "Gironico",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "E051",
        Abitanti: "2276",
      },
      {
        Istat: "18072",
        Comune: "Giussago",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "E062",
        Abitanti: "5038",
      },
      {
        Istat: "108024",
        Comune: "Giussano",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20833",
        CodFisco: "E063",
        Abitanti: "24672",
      },
      {
        Istat: "18073",
        Comune: "Godiasco",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27052",
        CodFisco: "E072",
        Abitanti: "3218",
      },
      {
        Istat: "20026",
        Comune: "Goito",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46044",
        CodFisco: "E078",
        Abitanti: "10355",
      },
      {
        Istat: "12077",
        Comune: "Golasecca",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21010",
        CodFisco: "E079",
        Abitanti: "2694",
      },
      {
        Istat: "18074",
        Comune: "Golferenzo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27047",
        CodFisco: "E081",
        Abitanti: "216",
      },
      {
        Istat: "19049",
        Comune: "Gombito",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "E082",
        Abitanti: "653",
      },
      {
        Istat: "20027",
        Comune: "Gonzaga",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46023",
        CodFisco: "E089",
        Abitanti: "9301",
      },
      {
        Istat: "14032",
        Comune: "Gordona",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23020",
        CodFisco: "E090",
        Abitanti: "1841",
      },
      {
        Istat: "15108",
        Comune: "Gorgonzola",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20064",
        CodFisco: "E094",
        Abitanti: "19649",
      },
      {
        Istat: "12078",
        Comune: "Gorla Maggiore",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21050",
        CodFisco: "E101",
        Abitanti: "5097",
      },
      {
        Istat: "12079",
        Comune: "Gorla Minore",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21055",
        CodFisco: "E102",
        Abitanti: "8512",
      },
      {
        Istat: "16114",
        Comune: "Gorlago",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "E100",
        Abitanti: "5062",
      },
      {
        Istat: "16115",
        Comune: "Gorle",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "E103",
        Abitanti: "6404",
      },
      {
        Istat: "12080",
        Comune: "Gornate-Olona",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "E104",
        Abitanti: "2232",
      },
      {
        Istat: "16116",
        Comune: "Gorno",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "E106",
        Abitanti: "1684",
      },
      {
        Istat: "17080",
        Comune: "Gottolengo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25023",
        CodFisco: "E116",
        Abitanti: "5364",
      },
      {
        Istat: "98028",
        Comune: "Graffignana",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26813",
        CodFisco: "E127",
        Abitanti: "2680",
      },
      {
        Istat: "13110",
        Comune: "Grandate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "E139",
        Abitanti: "2921",
      },
      {
        Istat: "13111",
        Comune: "Grandola ed Uniti",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "E141",
        Abitanti: "1358",
      },
      {
        Istat: "12081",
        Comune: "Grantola",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "E144",
        Abitanti: "1289",
      },
      {
        Istat: "16117",
        Comune: "Grassobbio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "E148",
        Abitanti: "6322",
      },
      {
        Istat: "13249",
        Comune: "Gravedona ed Uniti",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22015",
        CodFisco: "M315",
        Abitanti: "4226",
      },
      {
        Istat: "18075",
        Comune: "Gravellona Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "381",
        CAP: "27020",
        CodFisco: "E152",
        Abitanti: "2651",
      },
      {
        Istat: "15110",
        Comune: "Grezzago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20056",
        CodFisco: "E170",
        Abitanti: "2804",
      },
      {
        Istat: "13113",
        Comune: "Griante",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22011",
        CodFisco: "E172",
        Abitanti: "636",
      },
      {
        Istat: "16118",
        Comune: "Gromo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "E189",
        Abitanti: "1252",
      },
      {
        Istat: "16119",
        Comune: "Grone",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "E192",
        Abitanti: "934",
      },
      {
        Istat: "19050",
        Comune: "Grontardo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26044",
        CodFisco: "E193",
        Abitanti: "1465",
      },
      {
        Istat: "18076",
        Comune: "Gropello Cairoli",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27027",
        CodFisco: "E195",
        Abitanti: "4602",
      },
      {
        Istat: "14033",
        Comune: "Grosio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23033",
        CodFisco: "E200",
        Abitanti: "4634",
      },
      {
        Istat: "14034",
        Comune: "Grosotto",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23034",
        CodFisco: "E201",
        Abitanti: "1633",
      },
      {
        Istat: "19051",
        Comune: "Grumello Cremonese ed Uniti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26023",
        CodFisco: "E217",
        Abitanti: "1908",
      },
      {
        Istat: "16120",
        Comune: "Grumello del Monte",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24064",
        CodFisco: "E219",
        Abitanti: "7360",
      },
      {
        Istat: "13114",
        Comune: "Guanzate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "E235",
        Abitanti: "5719",
      },
      {
        Istat: "98029",
        Comune: "Guardamiglio",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26862",
        CodFisco: "E238",
        Abitanti: "2722",
      },
      {
        Istat: "15112",
        Comune: "Gudo Visconti",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20088",
        CodFisco: "E258",
        Abitanti: "1720",
      },
      {
        Istat: "20028",
        Comune: "Guidizzolo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "E261",
        Abitanti: "6185",
      },
      {
        Istat: "17081",
        Comune: "Gussago",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25064",
        CodFisco: "E271",
        Abitanti: "16759",
      },
      {
        Istat: "19052",
        Comune: "Gussola",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26040",
        CodFisco: "E272",
        Abitanti: "2968",
      },
      {
        Istat: "17082",
        Comune: "Idro",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25074",
        CodFisco: "E280",
        Abitanti: "1893",
      },
      {
        Istat: "97039",
        Comune: "Imbersago",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23898",
        CodFisco: "E287",
        Abitanti: "2431",
      },
      {
        Istat: "12082",
        Comune: "Inarzo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "E292",
        Abitanti: "1089",
      },
      {
        Istat: "17083",
        Comune: "Incudine",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "E297",
        Abitanti: "400",
      },
      {
        Istat: "12083",
        Comune: "Induno Olona",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21056",
        CodFisco: "E299",
        Abitanti: "10476",
      },
      {
        Istat: "97040",
        Comune: "Introbio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23815",
        CodFisco: "E305",
        Abitanti: "1983",
      },
      {
        Istat: "97041",
        Comune: "Introzzo",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23835",
        CodFisco: "E308",
        Abitanti: "122",
      },
      {
        Istat: "13118",
        Comune: "Inverigo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22044",
        CodFisco: "E309",
        Abitanti: "8981",
      },
      {
        Istat: "18077",
        Comune: "Inverno e Monteleone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "E310",
        Abitanti: "1317",
      },
      {
        Istat: "15113",
        Comune: "Inveruno",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "E313",
        Abitanti: "8686",
      },
      {
        Istat: "15114",
        Comune: "Inzago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20065",
        CodFisco: "E317",
        Abitanti: "10541",
      },
      {
        Istat: "17084",
        Comune: "Irma",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25061",
        CodFisco: "E325",
        Abitanti: "152",
      },
      {
        Istat: "17085",
        Comune: "Iseo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25049",
        CodFisco: "E333",
        Abitanti: "9205",
      },
      {
        Istat: "16121",
        Comune: "Isola di Fondra",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "E353",
        Abitanti: "189",
      },
      {
        Istat: "19053",
        Comune: "Isola Dovarese",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26031",
        CodFisco: "E356",
        Abitanti: "1223",
      },
      {
        Istat: "17086",
        Comune: "Isorella",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "E364",
        Abitanti: "4218",
      },
      {
        Istat: "12084",
        Comune: "Ispra",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21027",
        CodFisco: "E367",
        Abitanti: "5238",
      },
      {
        Istat: "16122",
        Comune: "Isso",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "E370",
        Abitanti: "664",
      },
      {
        Istat: "19054",
        Comune: "Izano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "E380",
        Abitanti: "2072",
      },
      {
        Istat: "12085",
        Comune: "Jerago con Orago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "E386",
        Abitanti: "5124",
      },
      {
        Istat: "15115",
        Comune: "Lacchiarella",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20084",
        CodFisco: "E395",
        Abitanti: "8480",
      },
      {
        Istat: "13119",
        Comune: "Laglio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22010",
        CodFisco: "E405",
        Abitanti: "957",
      },
      {
        Istat: "15116",
        Comune: "Lainate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20020",
        CodFisco: "E415",
        Abitanti: "25343",
      },
      {
        Istat: "13120",
        Comune: "Laino",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "E416",
        Abitanti: "524",
      },
      {
        Istat: "16123",
        Comune: "Lallio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "E422",
        Abitanti: "4138",
      },
      {
        Istat: "13121",
        Comune: "Lambrugo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22045",
        CodFisco: "E428",
        Abitanti: "2445",
      },
      {
        Istat: "18078",
        Comune: "Landriano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27015",
        CodFisco: "E437",
        Abitanti: "6121",
      },
      {
        Istat: "18079",
        Comune: "Langosco",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "E439",
        Abitanti: "441",
      },
      {
        Istat: "14036",
        Comune: "Lanzada",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "E443",
        Abitanti: "1399",
      },
      {
        Istat: "13122",
        Comune: "Lanzo d'Intelvi",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22024",
        CodFisco: "E444",
        Abitanti: "1452",
      },
      {
        Istat: "18080",
        Comune: "Lardirago",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27016",
        CodFisco: "E454",
        Abitanti: "1227",
      },
      {
        Istat: "13123",
        Comune: "Lasnigo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "E462",
        Abitanti: "458",
      },
      {
        Istat: "12086",
        Comune: "Lavena Ponte Tresa",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21037",
        CodFisco: "E494",
        Abitanti: "5622",
      },
      {
        Istat: "12087",
        Comune: "Laveno-Mombello",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21014",
        CodFisco: "E496",
        Abitanti: "9053",
      },
      {
        Istat: "17087",
        Comune: "Lavenone",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25074",
        CodFisco: "E497",
        Abitanti: "624",
      },
      {
        Istat: "108025",
        Comune: "Lazzate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20824",
        CodFisco: "E504",
        Abitanti: "7605",
      },
      {
        Istat: "97042",
        Comune: "Lecco",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23900",
        CodFisco: "E507",
        Abitanti: "48114",
      },
      {
        Istat: "16124",
        Comune: "Leffe",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24026",
        CodFisco: "E509",
        Abitanti: "4730",
      },
      {
        Istat: "12088",
        Comune: "Leggiuno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21038",
        CodFisco: "E510",
        Abitanti: "3582",
      },
      {
        Istat: "15118",
        Comune: "Legnano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20025",
        CodFisco: "E514",
        Abitanti: "59147",
      },
      {
        Istat: "16125",
        Comune: "Lenna",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "E524",
        Abitanti: "640",
      },
      {
        Istat: "13125",
        Comune: "Lenno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22016",
        CodFisco: "E525",
        Abitanti: "1859",
      },
      {
        Istat: "17088",
        Comune: "Leno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25024",
        CodFisco: "E526",
        Abitanti: "14561",
      },
      {
        Istat: "108054",
        Comune: "Lentate sul Seveso",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20823",
        CodFisco: "E530",
        Abitanti: "15572",
      },
      {
        Istat: "108026",
        Comune: "Lesmo",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20855",
        CodFisco: "E550",
        Abitanti: "8065",
      },
      {
        Istat: "16126",
        Comune: "Levate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "E562",
        Abitanti: "3813",
      },
      {
        Istat: "13126",
        Comune: "Lezzeno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22025",
        CodFisco: "E569",
        Abitanti: "2054",
      },
      {
        Istat: "97043",
        Comune: "Lierna",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23827",
        CodFisco: "E581",
        Abitanti: "2242",
      },
      {
        Istat: "108027",
        Comune: "Limbiate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20812",
        CodFisco: "E591",
        Abitanti: "35168",
      },
      {
        Istat: "13128",
        Comune: "Limido Comasco",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "E593",
        Abitanti: "3732",
      },
      {
        Istat: "17089",
        Comune: "Limone sul Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25010",
        CodFisco: "E596",
        Abitanti: "1164",
      },
      {
        Istat: "18081",
        Comune: "Linarolo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "E600",
        Abitanti: "2716",
      },
      {
        Istat: "13129",
        Comune: "Lipomo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "E607",
        Abitanti: "5860",
      },
      {
        Istat: "18082",
        Comune: "Lirio",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "E608",
        Abitanti: "146",
      },
      {
        Istat: "15122",
        Comune: "Liscate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "E610",
        Abitanti: "4060",
      },
      {
        Istat: "108028",
        Comune: "Lissone",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20851",
        CodFisco: "E617",
        Abitanti: "42474",
      },
      {
        Istat: "14037",
        Comune: "Livigno",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "E621",
        Abitanti: "5991",
      },
      {
        Istat: "13130",
        Comune: "Livo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "E623",
        Abitanti: "198",
      },
      {
        Istat: "98030",
        Comune: "Livraga",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26814",
        CodFisco: "E627",
        Abitanti: "2611",
      },
      {
        Istat: "15125",
        Comune: "Locate di Triulzi",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20085",
        CodFisco: "E639",
        Abitanti: "9707",
      },
      {
        Istat: "13131",
        Comune: "Locate Varesino",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "22070",
        CodFisco: "E638",
        Abitanti: "4249",
      },
      {
        Istat: "16127",
        Comune: "Locatello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "E640",
        Abitanti: "853",
      },
      {
        Istat: "98031",
        Comune: "Lodi",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26900",
        CodFisco: "E648",
        Abitanti: "44401",
      },
      {
        Istat: "98032",
        Comune: "Lodi Vecchio",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26855",
        CodFisco: "E651",
        Abitanti: "7401",
      },
      {
        Istat: "17090",
        Comune: "Lodrino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "E652",
        Abitanti: "1795",
      },
      {
        Istat: "17091",
        Comune: "Lograto",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "E654",
        Abitanti: "3888",
      },
      {
        Istat: "97044",
        Comune: "Lomagna",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23871",
        CodFisco: "E656",
        Abitanti: "4849",
      },
      {
        Istat: "13133",
        Comune: "Lomazzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "22074",
        CodFisco: "E659",
        Abitanti: "9564",
      },
      {
        Istat: "18083",
        Comune: "Lomello",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27034",
        CodFisco: "E662",
        Abitanti: "2322",
      },
      {
        Istat: "12089",
        Comune: "Lonate Ceppino",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21050",
        CodFisco: "E665",
        Abitanti: "4909",
      },
      {
        Istat: "12090",
        Comune: "Lonate Pozzolo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21015",
        CodFisco: "E666",
        Abitanti: "12059",
      },
      {
        Istat: "17092",
        Comune: "Lonato del Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25017",
        CodFisco: "M312",
        Abitanti: "15744",
      },
      {
        Istat: "17093",
        Comune: "Longhena",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "E673",
        Abitanti: "620",
      },
      {
        Istat: "13134",
        Comune: "Longone al Segrino",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "E679",
        Abitanti: "1789",
      },
      {
        Istat: "17094",
        Comune: "Losine",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "E698",
        Abitanti: "596",
      },
      {
        Istat: "16128",
        Comune: "Lovere",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24065",
        CodFisco: "E704",
        Abitanti: "5428",
      },
      {
        Istat: "14038",
        Comune: "Lovero",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "E705",
        Abitanti: "670",
      },
      {
        Istat: "17095",
        Comune: "Lozio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "E706",
        Abitanti: "416",
      },
      {
        Istat: "12091",
        Comune: "Lozza",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21040",
        CodFisco: "E707",
        Abitanti: "1238",
      },
      {
        Istat: "12092",
        Comune: "Luino",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21016",
        CodFisco: "E734",
        Abitanti: "14471",
      },
      {
        Istat: "13135",
        Comune: "Luisago",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "E735",
        Abitanti: "2702",
      },
      {
        Istat: "17096",
        Comune: "Lumezzane",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25065",
        CodFisco: "E738",
        Abitanti: "23828",
      },
      {
        Istat: "18084",
        Comune: "Lungavilla",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27053",
        CodFisco: "B387",
        Abitanti: "2417",
      },
      {
        Istat: "13136",
        Comune: "Lurago d'Erba",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22040",
        CodFisco: "E749",
        Abitanti: "5350",
      },
      {
        Istat: "13137",
        Comune: "Lurago Marinone",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "E750",
        Abitanti: "2463",
      },
      {
        Istat: "16129",
        Comune: "Lurano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "E751",
        Abitanti: "2566",
      },
      {
        Istat: "13138",
        Comune: "Lurate Caccivio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22075",
        CodFisco: "E753",
        Abitanti: "10083",
      },
      {
        Istat: "12093",
        Comune: "Luvinate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "E769",
        Abitanti: "1334",
      },
      {
        Istat: "16130",
        Comune: "Luzzana",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24069",
        CodFisco: "E770",
        Abitanti: "868",
      },
      {
        Istat: "12094",
        Comune: "Maccagno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "E775",
        Abitanti: "2041",
      },
      {
        Istat: "98033",
        Comune: "Maccastorna",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26843",
        CodFisco: "E777",
        Abitanti: "68",
      },
      {
        Istat: "108029",
        Comune: "Macherio",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20846",
        CodFisco: "E786",
        Abitanti: "7222",
      },
      {
        Istat: "17097",
        Comune: "Maclodio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "E787",
        Abitanti: "1514",
      },
      {
        Istat: "14035",
        Comune: "Madesimo",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23024",
        CodFisco: "E342",
        Abitanti: "566",
      },
      {
        Istat: "19055",
        Comune: "Madignano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26020",
        CodFisco: "E793",
        Abitanti: "2992",
      },
      {
        Istat: "16131",
        Comune: "Madone",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "E794",
        Abitanti: "4031",
      },
      {
        Istat: "17098",
        Comune: "Magasa",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "E800",
        Abitanti: "152",
      },
      {
        Istat: "15130",
        Comune: "Magenta",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20013",
        CodFisco: "E801",
        Abitanti: "23513",
      },
      {
        Istat: "18085",
        Comune: "Magherno",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "E804",
        Abitanti: "1619",
      },
      {
        Istat: "20029",
        Comune: "Magnacavallo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "E818",
        Abitanti: "1696",
      },
      {
        Istat: "15131",
        Comune: "Magnago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "E819",
        Abitanti: "9090",
      },
      {
        Istat: "13139",
        Comune: "Magreglio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "E830",
        Abitanti: "666",
      },
      {
        Istat: "98034",
        Comune: "Mairago",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26825",
        CodFisco: "E840",
        Abitanti: "1429",
      },
      {
        Istat: "17099",
        Comune: "Mairano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "E841",
        Abitanti: "3327",
      },
      {
        Istat: "19056",
        Comune: "Malagnino",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26030",
        CodFisco: "E843",
        Abitanti: "1457",
      },
      {
        Istat: "17100",
        Comune: "Malegno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25053",
        CodFisco: "E851",
        Abitanti: "2115",
      },
      {
        Istat: "98035",
        Comune: "Maleo",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26847",
        CodFisco: "E852",
        Abitanti: "3280",
      },
      {
        Istat: "12095",
        Comune: "Malgesso",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "E856",
        Abitanti: "1320",
      },
      {
        Istat: "97045",
        Comune: "Malgrate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23864",
        CodFisco: "E858",
        Abitanti: "4327",
      },
      {
        Istat: "12096",
        Comune: "Malnate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21046",
        CodFisco: "E863",
        Abitanti: "16641",
      },
      {
        Istat: "17101",
        Comune: "Malonno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "E865",
        Abitanti: "3357",
      },
      {
        Istat: "97046",
        Comune: "Mandello del Lario",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23826",
        CodFisco: "E879",
        Abitanti: "10628",
      },
      {
        Istat: "17102",
        Comune: "Manerba del Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "E883",
        Abitanti: "4985",
      },
      {
        Istat: "17103",
        Comune: "Manerbio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25025",
        CodFisco: "E884",
        Abitanti: "13273",
      },
      {
        Istat: "14039",
        Comune: "Mantello",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23016",
        CodFisco: "E896",
        Abitanti: "755",
      },
      {
        Istat: "20030",
        Comune: "Mantova",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46100",
        CodFisco: "E897",
        Abitanti: "48612",
      },
      {
        Istat: "16132",
        Comune: "Mapello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "E901",
        Abitanti: "6446",
      },
      {
        Istat: "15134",
        Comune: "Marcallo con Casone",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "E921",
        Abitanti: "6048",
      },
      {
        Istat: "20031",
        Comune: "Marcaria",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46010",
        CodFisco: "E922",
        Abitanti: "7027",
      },
      {
        Istat: "17104",
        Comune: "Marcheno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "E928",
        Abitanti: "4492",
      },
      {
        Istat: "12097",
        Comune: "Marchirolo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "E929",
        Abitanti: "3423",
      },
      {
        Istat: "18086",
        Comune: "Marcignago",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "E934",
        Abitanti: "2488",
      },
      {
        Istat: "97047",
        Comune: "Margno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23832",
        CodFisco: "E947",
        Abitanti: "366",
      },
      {
        Istat: "20032",
        Comune: "Mariana Mantovana",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46010",
        CodFisco: "E949",
        Abitanti: "729",
      },
      {
        Istat: "13143",
        Comune: "Mariano Comense",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22066",
        CodFisco: "E951",
        Abitanti: "23890",
      },
      {
        Istat: "17105",
        Comune: "Marmentino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "E961",
        Abitanti: "691",
      },
      {
        Istat: "20033",
        Comune: "Marmirolo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46045",
        CodFisco: "E962",
        Abitanti: "7768",
      },
      {
        Istat: "12098",
        Comune: "Marnate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21050",
        CodFisco: "E965",
        Abitanti: "7211",
      },
      {
        Istat: "17106",
        Comune: "Marone",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25054",
        CodFisco: "E967",
        Abitanti: "3328",
      },
      {
        Istat: "19057",
        Comune: "Martignana di Po",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26040",
        CodFisco: "E983",
        Abitanti: "1851",
      },
      {
        Istat: "16133",
        Comune: "Martinengo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24057",
        CodFisco: "E987",
        Abitanti: "10121",
      },
      {
        Istat: "98036",
        Comune: "Marudo",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26866",
        CodFisco: "E994",
        Abitanti: "1556",
      },
      {
        Istat: "18087",
        Comune: "Marzano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "E999",
        Abitanti: "1608",
      },
      {
        Istat: "12099",
        Comune: "Marzio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "F002",
        Abitanti: "308",
      },
      {
        Istat: "15136",
        Comune: "Masate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "F003",
        Abitanti: "3363",
      },
      {
        Istat: "12100",
        Comune: "Masciago Primo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "F007",
        Abitanti: "289",
      },
      {
        Istat: "13144",
        Comune: "Maslianico",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22026",
        CodFisco: "F017",
        Abitanti: "3387",
      },
      {
        Istat: "98037",
        Comune: "Massalengo",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26815",
        CodFisco: "F028",
        Abitanti: "4257",
      },
      {
        Istat: "17107",
        Comune: "Mazzano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "F063",
        Abitanti: "11713",
      },
      {
        Istat: "14040",
        Comune: "Mazzo di Valtellina",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "F070",
        Abitanti: "1061",
      },
      {
        Istat: "108030",
        Comune: "Meda",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20821",
        CodFisco: "F078",
        Abitanti: "23221",
      },
      {
        Istat: "18088",
        Comune: "Mede",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27035",
        CodFisco: "F080",
        Abitanti: "7085",
      },
      {
        Istat: "15139",
        Comune: "Mediglia",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "F084",
        Abitanti: "12187",
      },
      {
        Istat: "16250",
        Comune: "Medolago",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "F085",
        Abitanti: "2388",
      },
      {
        Istat: "20034",
        Comune: "Medole",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46046",
        CodFisco: "F086",
        Abitanti: "4026",
      },
      {
        Istat: "15140",
        Comune: "Melegnano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20077",
        CodFisco: "F100",
        Abitanti: "17260",
      },
      {
        Istat: "98038",
        Comune: "Meleti",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26843",
        CodFisco: "F102",
        Abitanti: "475",
      },
      {
        Istat: "14041",
        Comune: "Mello",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "F115",
        Abitanti: "996",
      },
      {
        Istat: "15142",
        Comune: "Melzo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20066",
        CodFisco: "F119",
        Abitanti: "18513",
      },
      {
        Istat: "13145",
        Comune: "Menaggio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22017",
        CodFisco: "F120",
        Abitanti: "3273",
      },
      {
        Istat: "14042",
        Comune: "Menarola",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23020",
        CodFisco: "F121",
        Abitanti: "46",
      },
      {
        Istat: "18089",
        Comune: "Menconico",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "F122",
        Abitanti: "400",
      },
      {
        Istat: "97048",
        Comune: "Merate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23807",
        CodFisco: "F133",
        Abitanti: "14943",
      },
      {
        Istat: "12101",
        Comune: "Mercallo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21020",
        CodFisco: "F134",
        Abitanti: "1816",
      },
      {
        Istat: "98039",
        Comune: "Merlino",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "26833",
        CodFisco: "F149",
        Abitanti: "1778",
      },
      {
        Istat: "13147",
        Comune: "Merone",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22046",
        CodFisco: "F151",
        Abitanti: "4124",
      },
      {
        Istat: "14043",
        Comune: "Mese",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23020",
        CodFisco: "F153",
        Abitanti: "1740",
      },
      {
        Istat: "12102",
        Comune: "Mesenzana",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "F154",
        Abitanti: "1473",
      },
      {
        Istat: "15144",
        Comune: "Mesero",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "F155",
        Abitanti: "3915",
      },
      {
        Istat: "108031",
        Comune: "Mezzago",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20883",
        CodFisco: "F165",
        Abitanti: "4163",
      },
      {
        Istat: "18090",
        Comune: "Mezzana Bigli",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "F170",
        Abitanti: "1144",
      },
      {
        Istat: "18091",
        Comune: "Mezzana Rabattone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27030",
        CodFisco: "F171",
        Abitanti: "514",
      },
      {
        Istat: "18092",
        Comune: "Mezzanino",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "F175",
        Abitanti: "1509",
      },
      {
        Istat: "13148",
        Comune: "Mezzegra",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "F181",
        Abitanti: "1043",
      },
      {
        Istat: "16134",
        Comune: "Mezzoldo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "F186",
        Abitanti: "196",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20121",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20122",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20123",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20124",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20125",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20126",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20127",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20128",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20129",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20130",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20131",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20132",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20133",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20134",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20135",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20136",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20137",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20138",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20139",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20140",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20141",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20142",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20143",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20144",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20145",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20146",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20147",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20148",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20149",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20150",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20151",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20152",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20153",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20154",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20155",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20156",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20157",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20158",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20159",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20160",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20161",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "15146",
        Comune: "Milano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20162",
        CodFisco: "F205",
        Abitanti: "1324110",
      },
      {
        Istat: "17108",
        Comune: "Milzano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "F216",
        Abitanti: "1815",
      },
      {
        Istat: "18093",
        Comune: "Miradolo Terme",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "F238",
        Abitanti: "3817",
      },
      {
        Istat: "16135",
        Comune: "Misano di Gera d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "F243",
        Abitanti: "2986",
      },
      {
        Istat: "108032",
        Comune: "Misinto",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20826",
        CodFisco: "F247",
        Abitanti: "5078",
      },
      {
        Istat: "97049",
        Comune: "Missaglia",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23873",
        CodFisco: "F248",
        Abitanti: "8555",
      },
      {
        Istat: "97050",
        Comune: "Moggio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23817",
        CodFisco: "F265",
        Abitanti: "521",
      },
      {
        Istat: "20035",
        Comune: "Moglia",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46024",
        CodFisco: "F267",
        Abitanti: "6064",
      },
      {
        Istat: "16136",
        Comune: "Moio de' Calvi",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "F276",
        Abitanti: "208",
      },
      {
        Istat: "97051",
        Comune: "Molteno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23847",
        CodFisco: "F304",
        Abitanti: "3545",
      },
      {
        Istat: "13152",
        Comune: "Moltrasio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22010",
        CodFisco: "F305",
        Abitanti: "1710",
      },
      {
        Istat: "16137",
        Comune: "Monasterolo del Castello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "F328",
        Abitanti: "1119",
      },
      {
        Istat: "13153",
        Comune: "Monguzzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22040",
        CodFisco: "F372",
        Abitanti: "2207",
      },
      {
        Istat: "17109",
        Comune: "Moniga del Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "F373",
        Abitanti: "2457",
      },
      {
        Istat: "17110",
        Comune: "Monno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "F375",
        Abitanti: "568",
      },
      {
        Istat: "14044",
        Comune: "Montagna in Valtellina",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "F393",
        Abitanti: "3059",
      },
      {
        Istat: "18094",
        Comune: "Montalto Pavese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "F417",
        Abitanti: "931",
      },
      {
        Istat: "98040",
        Comune: "Montanaso Lombardo",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26836",
        CodFisco: "F423",
        Abitanti: "2203",
      },
      {
        Istat: "13154",
        Comune: "Montano Lucino",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "F427",
        Abitanti: "4755",
      },
      {
        Istat: "19058",
        Comune: "Monte Cremasco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "F434",
        Abitanti: "2351",
      },
      {
        Istat: "17111",
        Comune: "Monte Isola",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25050",
        CodFisco: "F532",
        Abitanti: "1807",
      },
      {
        Istat: "97052",
        Comune: "Monte Marenzo",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23804",
        CodFisco: "F561",
        Abitanti: "1994",
      },
      {
        Istat: "18095",
        Comune: "Montebello della Battaglia",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27054",
        CodFisco: "F440",
        Abitanti: "1712",
      },
      {
        Istat: "18096",
        Comune: "Montecalvo Versiggia",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27047",
        CodFisco: "F449",
        Abitanti: "588",
      },
      {
        Istat: "12103",
        Comune: "Montegrino Valtravaglia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "F526",
        Abitanti: "1416",
      },
      {
        Istat: "16139",
        Comune: "Montello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "F547",
        Abitanti: "3253",
      },
      {
        Istat: "13155",
        Comune: "Montemezzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "F564",
        Abitanti: "261",
      },
      {
        Istat: "18097",
        Comune: "Montescano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "F638",
        Abitanti: "395",
      },
      {
        Istat: "18098",
        Comune: "Montesegale",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27052",
        CodFisco: "F644",
        Abitanti: "321",
      },
      {
        Istat: "97053",
        Comune: "Montevecchia",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23874",
        CodFisco: "F657",
        Abitanti: "2494",
      },
      {
        Istat: "17112",
        Comune: "Monticelli Brusati",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25040",
        CodFisco: "F672",
        Abitanti: "4388",
      },
      {
        Istat: "18099",
        Comune: "Monticelli Pavese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "F670",
        Abitanti: "737",
      },
      {
        Istat: "97054",
        Comune: "Monticello Brianza",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23876",
        CodFisco: "F674",
        Abitanti: "4218",
      },
      {
        Istat: "17113",
        Comune: "Montichiari",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25018",
        CodFisco: "F471",
        Abitanti: "23339",
      },
      {
        Istat: "17114",
        Comune: "Montirone",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "F680",
        Abitanti: "5094",
      },
      {
        Istat: "19059",
        Comune: "Montodine",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "F681",
        Abitanti: "2609",
      },
      {
        Istat: "13157",
        Comune: "Montorfano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "F688",
        Abitanti: "2696",
      },
      {
        Istat: "18100",
        Comune: "Montù Beccaria",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "F701",
        Abitanti: "1772",
      },
      {
        Istat: "12104",
        Comune: "Monvalle",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "F703",
        Abitanti: "1991",
      },
      {
        Istat: "108033",
        Comune: "Monza",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20900",
        CodFisco: "F704",
        Abitanti: "122712",
      },
      {
        Istat: "20036",
        Comune: "Monzambano",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "F705",
        Abitanti: "4859",
      },
      {
        Istat: "12105",
        Comune: "Morazzone",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21040",
        CodFisco: "F711",
        Abitanti: "4375",
      },
      {
        Istat: "14045",
        Comune: "Morbegno",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23017",
        CodFisco: "F712",
        Abitanti: "12071",
      },
      {
        Istat: "16140",
        Comune: "Morengo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "F720",
        Abitanti: "2600",
      },
      {
        Istat: "15150",
        Comune: "Morimondo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20081",
        CodFisco: "D033",
        Abitanti: "1211",
      },
      {
        Istat: "12106",
        Comune: "Mornago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21020",
        CodFisco: "F736",
        Abitanti: "4873",
      },
      {
        Istat: "16141",
        Comune: "Mornico al Serio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "F738",
        Abitanti: "2897",
      },
      {
        Istat: "18101",
        Comune: "Mornico Losana",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "F739",
        Abitanti: "732",
      },
      {
        Istat: "18102",
        Comune: "Mortara",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27036",
        CodFisco: "F754",
        Abitanti: "15673",
      },
      {
        Istat: "97055",
        Comune: "Morterone",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23811",
        CodFisco: "F758",
        Abitanti: "37",
      },
      {
        Istat: "19060",
        Comune: "Moscazzano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "F761",
        Abitanti: "841",
      },
      {
        Istat: "19061",
        Comune: "Motta Baluffi",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26045",
        CodFisco: "F771",
        Abitanti: "1000",
      },
      {
        Istat: "15151",
        Comune: "Motta Visconti",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20086",
        CodFisco: "F783",
        Abitanti: "7665",
      },
      {
        Istat: "20037",
        Comune: "Motteggiana",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46020",
        CodFisco: "B012",
        Abitanti: "2576",
      },
      {
        Istat: "16142",
        Comune: "Mozzanica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "F786",
        Abitanti: "4619",
      },
      {
        Istat: "13159",
        Comune: "Mozzate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "22076",
        CodFisco: "F788",
        Abitanti: "8367",
      },
      {
        Istat: "16143",
        Comune: "Mozzo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "F791",
        Abitanti: "7488",
      },
      {
        Istat: "108034",
        Comune: "Muggiò",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20835",
        CodFisco: "F797",
        Abitanti: "23433",
      },
      {
        Istat: "98041",
        Comune: "Mulazzano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "26837",
        CodFisco: "F801",
        Abitanti: "5768",
      },
      {
        Istat: "17115",
        Comune: "Mura",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "F806",
        Abitanti: "791",
      },
      {
        Istat: "17116",
        Comune: "Muscoline",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "F820",
        Abitanti: "2573",
      },
      {
        Istat: "13160",
        Comune: "Musso",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "F828",
        Abitanti: "1017",
      },
      {
        Istat: "17117",
        Comune: "Nave",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25075",
        CodFisco: "F851",
        Abitanti: "11142",
      },
      {
        Istat: "16144",
        Comune: "Nembro",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24027",
        CodFisco: "F864",
        Abitanti: "11636",
      },
      {
        Istat: "15154",
        Comune: "Nerviano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20014",
        CodFisco: "F874",
        Abitanti: "17499",
      },
      {
        Istat: "13161",
        Comune: "Nesso",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "F877",
        Abitanti: "1273",
      },
      {
        Istat: "17118",
        Comune: "Niardo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "F884",
        Abitanti: "1944",
      },
      {
        Istat: "97056",
        Comune: "Nibionno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23895",
        CodFisco: "F887",
        Abitanti: "3628",
      },
      {
        Istat: "18103",
        Comune: "Nicorvo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "F891",
        Abitanti: "382",
      },
      {
        Istat: "15155",
        Comune: "Nosate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "F939",
        Abitanti: "689",
      },
      {
        Istat: "108035",
        Comune: "Nova Milanese",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20834",
        CodFisco: "F944",
        Abitanti: "23152",
      },
      {
        Istat: "14046",
        Comune: "Novate Mezzola",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23025",
        CodFisco: "F956",
        Abitanti: "1863",
      },
      {
        Istat: "15157",
        Comune: "Novate Milanese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20026",
        CodFisco: "F955",
        Abitanti: "20201",
      },
      {
        Istat: "13163",
        Comune: "Novedrate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22060",
        CodFisco: "F958",
        Abitanti: "2932",
      },
      {
        Istat: "15158",
        Comune: "Noviglio",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20082",
        CodFisco: "F968",
        Abitanti: "4459",
      },
      {
        Istat: "17119",
        Comune: "Nuvolento",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "F989",
        Abitanti: "4073",
      },
      {
        Istat: "17120",
        Comune: "Nuvolera",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "F990",
        Abitanti: "4651",
      },
      {
        Istat: "17121",
        Comune: "Odolo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25076",
        CodFisco: "G001",
        Abitanti: "2104",
      },
      {
        Istat: "19062",
        Comune: "Offanengo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "G004",
        Abitanti: "5893",
      },
      {
        Istat: "17122",
        Comune: "Offlaga",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "G006",
        Abitanti: "4297",
      },
      {
        Istat: "12107",
        Comune: "Oggiona con Santo Stefano",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "G008",
        Abitanti: "4329",
      },
      {
        Istat: "97057",
        Comune: "Oggiono",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23848",
        CodFisco: "G009",
        Abitanti: "8887",
      },
      {
        Istat: "18104",
        Comune: "Olevano di Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "G021",
        Abitanti: "806",
      },
      {
        Istat: "13165",
        Comune: "Olgiate Comasco",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22077",
        CodFisco: "G025",
        Abitanti: "11364",
      },
      {
        Istat: "97058",
        Comune: "Olgiate Molgora",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23887",
        CodFisco: "G026",
        Abitanti: "6311",
      },
      {
        Istat: "12108",
        Comune: "Olgiate Olona",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21057",
        CodFisco: "G028",
        Abitanti: "12243",
      },
      {
        Istat: "97059",
        Comune: "Olginate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23854",
        CodFisco: "G030",
        Abitanti: "7194",
      },
      {
        Istat: "18105",
        Comune: "Oliva Gessi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "G032",
        Abitanti: "179",
      },
      {
        Istat: "97060",
        Comune: "Oliveto Lario",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23865",
        CodFisco: "G040",
        Abitanti: "1212",
      },
      {
        Istat: "19063",
        Comune: "Olmeneta",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26010",
        CodFisco: "G047",
        Abitanti: "957",
      },
      {
        Istat: "16145",
        Comune: "Olmo al Brembo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "G049",
        Abitanti: "525",
      },
      {
        Istat: "16146",
        Comune: "Oltre il Colle",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24013",
        CodFisco: "G050",
        Abitanti: "1062",
      },
      {
        Istat: "16147",
        Comune: "Oltressenda Alta",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "G054",
        Abitanti: "186",
      },
      {
        Istat: "13169",
        Comune: "Oltrona di San Mamette",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "G056",
        Abitanti: "2293",
      },
      {
        Istat: "17123",
        Comune: "Ome",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25050",
        CodFisco: "G061",
        Abitanti: "3273",
      },
      {
        Istat: "16148",
        Comune: "Oneta",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "G068",
        Abitanti: "661",
      },
      {
        Istat: "17124",
        Comune: "Ono San Pietro",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "G074",
        Abitanti: "991",
      },
      {
        Istat: "16149",
        Comune: "Onore",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "G075",
        Abitanti: "835",
      },
      {
        Istat: "15159",
        Comune: "Opera",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "G078",
        Abitanti: "13840",
      },
      {
        Istat: "12109",
        Comune: "Origgio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "21040",
        CodFisco: "G103",
        Abitanti: "7400",
      },
      {
        Istat: "12110",
        Comune: "Orino",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "G105",
        Abitanti: "866",
      },
      {
        Istat: "16150",
        Comune: "Orio al Serio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "G108",
        Abitanti: "1752",
      },
      {
        Istat: "98042",
        Comune: "Orio Litta",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26863",
        CodFisco: "G107",
        Abitanti: "2036",
      },
      {
        Istat: "108036",
        Comune: "Ornago",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20876",
        CodFisco: "G116",
        Abitanti: "4670",
      },
      {
        Istat: "16151",
        Comune: "Ornica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "G118",
        Abitanti: "180",
      },
      {
        Istat: "13170",
        Comune: "Orsenigo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "G126",
        Abitanti: "2758",
      },
      {
        Istat: "17125",
        Comune: "Orzinuovi",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25034",
        CodFisco: "G149",
        Abitanti: "12549",
      },
      {
        Istat: "17126",
        Comune: "Orzivecchi",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "G150",
        Abitanti: "2506",
      },
      {
        Istat: "16152",
        Comune: "Osio Sopra",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "G159",
        Abitanti: "5115",
      },
      {
        Istat: "16153",
        Comune: "Osio Sotto",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24046",
        CodFisco: "G160",
        Abitanti: "11890",
      },
      {
        Istat: "12111",
        Comune: "Osmate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21018",
        CodFisco: "E529",
        Abitanti: "764",
      },
      {
        Istat: "97061",
        Comune: "Osnago",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23875",
        CodFisco: "G161",
        Abitanti: "4843",
      },
      {
        Istat: "98043",
        Comune: "Ospedaletto Lodigiano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26864",
        CodFisco: "G166",
        Abitanti: "1947",
      },
      {
        Istat: "17127",
        Comune: "Ospitaletto",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25035",
        CodFisco: "G170",
        Abitanti: "13974",
      },
      {
        Istat: "98044",
        Comune: "Ossago Lodigiano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26816",
        CodFisco: "G171",
        Abitanti: "1442",
      },
      {
        Istat: "17128",
        Comune: "Ossimo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "G179",
        Abitanti: "1462",
      },
      {
        Istat: "15164",
        Comune: "Ossona",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "G181",
        Abitanti: "4150",
      },
      {
        Istat: "13172",
        Comune: "Ossuccio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "G182",
        Abitanti: "1008",
      },
      {
        Istat: "19064",
        Comune: "Ostiano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26032",
        CodFisco: "G185",
        Abitanti: "3067",
      },
      {
        Istat: "20038",
        Comune: "Ostiglia",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46035",
        CodFisco: "G186",
        Abitanti: "7221",
      },
      {
        Istat: "18106",
        Comune: "Ottobiano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "G194",
        Abitanti: "1187",
      },
      {
        Istat: "15165",
        Comune: "Ozzero",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "G206",
        Abitanti: "1480",
      },
      {
        Istat: "17129",
        Comune: "Padenghe sul Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "G213",
        Abitanti: "4350",
      },
      {
        Istat: "97062",
        Comune: "Paderno d'Adda",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23877",
        CodFisco: "G218",
        Abitanti: "3936",
      },
      {
        Istat: "15166",
        Comune: "Paderno Dugnano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20037",
        CodFisco: "G220",
        Abitanti: "47695",
      },
      {
        Istat: "17130",
        Comune: "Paderno Franciacorta",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25050",
        CodFisco: "G217",
        Abitanti: "3765",
      },
      {
        Istat: "19065",
        Comune: "Paderno Ponchielli",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26024",
        CodFisco: "G222",
        Abitanti: "1499",
      },
      {
        Istat: "16154",
        Comune: "Pagazzano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "G233",
        Abitanti: "2103",
      },
      {
        Istat: "97063",
        Comune: "Pagnona",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23833",
        CodFisco: "G241",
        Abitanti: "407",
      },
      {
        Istat: "17131",
        Comune: "Paisco Loveno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "G247",
        Abitanti: "200",
      },
      {
        Istat: "17132",
        Comune: "Paitone",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "G248",
        Abitanti: "2043",
      },
      {
        Istat: "16155",
        Comune: "Paladina",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "G249",
        Abitanti: "4002",
      },
      {
        Istat: "16156",
        Comune: "Palazzago",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "G259",
        Abitanti: "4215",
      },
      {
        Istat: "19066",
        Comune: "Palazzo Pignano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26020",
        CodFisco: "G260",
        Abitanti: "3916",
      },
      {
        Istat: "17133",
        Comune: "Palazzolo sull'Oglio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25036",
        CodFisco: "G264",
        Abitanti: "19862",
      },
      {
        Istat: "18107",
        Comune: "Palestro",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "G275",
        Abitanti: "2001",
      },
      {
        Istat: "16157",
        Comune: "Palosco",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "G295",
        Abitanti: "5801",
      },
      {
        Istat: "18108",
        Comune: "Pancarana",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "G304",
        Abitanti: "336",
      },
      {
        Istat: "19067",
        Comune: "Pandino",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26025",
        CodFisco: "G306",
        Abitanti: "9147",
      },
      {
        Istat: "15167",
        Comune: "Pantigliate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "G316",
        Abitanti: "5983",
      },
      {
        Istat: "15168",
        Comune: "Parabiago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20015",
        CodFisco: "G324",
        Abitanti: "26952",
      },
      {
        Istat: "17134",
        Comune: "Paratico",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "25030",
        CodFisco: "G327",
        Abitanti: "4444",
      },
      {
        Istat: "13175",
        Comune: "Parè",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "G329",
        Abitanti: "1763",
      },
      {
        Istat: "97064",
        Comune: "Parlasco",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23837",
        CodFisco: "G336",
        Abitanti: "141",
      },
      {
        Istat: "18109",
        Comune: "Parona",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "G342",
        Abitanti: "2052",
      },
      {
        Istat: "16158",
        Comune: "Parre",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "G346",
        Abitanti: "2815",
      },
      {
        Istat: "16159",
        Comune: "Parzanica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "G350",
        Abitanti: "375",
      },
      {
        Istat: "17135",
        Comune: "Paspardo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "G354",
        Abitanti: "654",
      },
      {
        Istat: "17136",
        Comune: "Passirano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25050",
        CodFisco: "G361",
        Abitanti: "7191",
      },
      {
        Istat: "97065",
        Comune: "Pasturo",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23818",
        CodFisco: "G368",
        Abitanti: "1957",
      },
      {
        Istat: "15169",
        Comune: "Paullo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20067",
        CodFisco: "G385",
        Abitanti: "11132",
      },
      {
        Istat: "18110",
        Comune: "Pavia",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27100",
        CodFisco: "G388",
        Abitanti: "71142",
      },
      {
        Istat: "17137",
        Comune: "Pavone del Mella",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "G391",
        Abitanti: "2831",
      },
      {
        Istat: "14047",
        Comune: "Pedesina",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "G410",
        Abitanti: "34",
      },
      {
        Istat: "16160",
        Comune: "Pedrengo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24066",
        CodFisco: "G412",
        Abitanti: "5777",
      },
      {
        Istat: "13178",
        Comune: "Peglio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "G415",
        Abitanti: "199",
      },
      {
        Istat: "20039",
        Comune: "Pegognaga",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46020",
        CodFisco: "G417",
        Abitanti: "7299",
      },
      {
        Istat: "16161",
        Comune: "Peia",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "G418",
        Abitanti: "1857",
      },
      {
        Istat: "13179",
        Comune: "Pellio Intelvi",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "G427",
        Abitanti: "1053",
      },
      {
        Istat: "97066",
        Comune: "Perego",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23888",
        CodFisco: "G448",
        Abitanti: "1757",
      },
      {
        Istat: "97067",
        Comune: "Perledo",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23828",
        CodFisco: "G456",
        Abitanti: "945",
      },
      {
        Istat: "15170",
        Comune: "Pero",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20016",
        CodFisco: "C013",
        Abitanti: "10749",
      },
      {
        Istat: "19068",
        Comune: "Persico Dosimo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26043",
        CodFisco: "G469",
        Abitanti: "3370",
      },
      {
        Istat: "17139",
        Comune: "Pertica Alta",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "G474",
        Abitanti: "612",
      },
      {
        Istat: "17140",
        Comune: "Pertica Bassa",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25078",
        CodFisco: "G475",
        Abitanti: "698",
      },
      {
        Istat: "19069",
        Comune: "Pescarolo ed Uniti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26033",
        CodFisco: "G483",
        Abitanti: "1608",
      },
      {
        Istat: "97068",
        Comune: "Pescate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23855",
        CodFisco: "G485",
        Abitanti: "2188",
      },
      {
        Istat: "15171",
        Comune: "Peschiera Borromeo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20068",
        CodFisco: "G488",
        Abitanti: "22774",
      },
      {
        Istat: "15172",
        Comune: "Pessano con Bornago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "G502",
        Abitanti: "9121",
      },
      {
        Istat: "19070",
        Comune: "Pessina Cremonese",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26030",
        CodFisco: "G504",
        Abitanti: "693",
      },
      {
        Istat: "17141",
        Comune: "Pezzaze",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "G529",
        Abitanti: "1616",
      },
      {
        Istat: "19071",
        Comune: "Piadena",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26034",
        CodFisco: "G536",
        Abitanti: "3645",
      },
      {
        Istat: "17142",
        Comune: "Pian Camuno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "G546",
        Abitanti: "4369",
      },
      {
        Istat: "17206",
        Comune: "Piancogno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25052",
        CodFisco: "G549",
        Abitanti: "4707",
      },
      {
        Istat: "13183",
        Comune: "Pianello del Lario",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "G556",
        Abitanti: "1050",
      },
      {
        Istat: "19072",
        Comune: "Pianengo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "G558",
        Abitanti: "2617",
      },
      {
        Istat: "16162",
        Comune: "Pianico",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "G564",
        Abitanti: "1544",
      },
      {
        Istat: "14048",
        Comune: "Piantedo",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "G572",
        Abitanti: "1302",
      },
      {
        Istat: "16163",
        Comune: "Piario",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "G574",
        Abitanti: "1106",
      },
      {
        Istat: "14049",
        Comune: "Piateda",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "G576",
        Abitanti: "2340",
      },
      {
        Istat: "16164",
        Comune: "Piazza Brembana",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24014",
        CodFisco: "G579",
        Abitanti: "1256",
      },
      {
        Istat: "16165",
        Comune: "Piazzatorre",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "G583",
        Abitanti: "444",
      },
      {
        Istat: "16166",
        Comune: "Piazzolo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "G588",
        Abitanti: "86",
      },
      {
        Istat: "19073",
        Comune: "Pieranica",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26017",
        CodFisco: "G603",
        Abitanti: "1181",
      },
      {
        Istat: "18111",
        Comune: "Pietra de' Giorgi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "G612",
        Abitanti: "939",
      },
      {
        Istat: "18112",
        Comune: "Pieve Albignola",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27030",
        CodFisco: "G635",
        Abitanti: "924",
      },
      {
        Istat: "18113",
        Comune: "Pieve del Cairo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27037",
        CodFisco: "G639",
        Abitanti: "2168",
      },
      {
        Istat: "20040",
        Comune: "Pieve di Coriano",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "G633",
        Abitanti: "1069",
      },
      {
        Istat: "19074",
        Comune: "Pieve d'Olmi",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26040",
        CodFisco: "G647",
        Abitanti: "1297",
      },
      {
        Istat: "15173",
        Comune: "Pieve Emanuele",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "G634",
        Abitanti: "14887",
      },
      {
        Istat: "98045",
        Comune: "Pieve Fissiraga",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26854",
        CodFisco: "G096",
        Abitanti: "1680",
      },
      {
        Istat: "18114",
        Comune: "Pieve Porto Morone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27017",
        CodFisco: "G650",
        Abitanti: "2801",
      },
      {
        Istat: "19075",
        Comune: "Pieve San Giacomo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26035",
        CodFisco: "G651",
        Abitanti: "1625",
      },
      {
        Istat: "13184",
        Comune: "Pigra",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "G665",
        Abitanti: "270",
      },
      {
        Istat: "18115",
        Comune: "Pinarolo Po",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27040",
        CodFisco: "G671",
        Abitanti: "1730",
      },
      {
        Istat: "12112",
        Comune: "Pino sulla Sponda del Lago Maggiore",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "G677",
        Abitanti: "224",
      },
      {
        Istat: "15175",
        Comune: "Pioltello",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20096",
        CodFisco: "G686",
        Abitanti: "36369",
      },
      {
        Istat: "17143",
        Comune: "Pisogne",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25055",
        CodFisco: "G710",
        Abitanti: "8115",
      },
      {
        Istat: "20041",
        Comune: "Piubega",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "G717",
        Abitanti: "1775",
      },
      {
        Istat: "14050",
        Comune: "Piuro",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23020",
        CodFisco: "G718",
        Abitanti: "1975",
      },
      {
        Istat: "18116",
        Comune: "Pizzale",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "G720",
        Abitanti: "733",
      },
      {
        Istat: "19076",
        Comune: "Pizzighettone",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26026",
        CodFisco: "G721",
        Abitanti: "6730",
      },
      {
        Istat: "13185",
        Comune: "Plesio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "G737",
        Abitanti: "853",
      },
      {
        Istat: "20042",
        Comune: "Poggio Rusco",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46025",
        CodFisco: "G753",
        Abitanti: "6649",
      },
      {
        Istat: "14051",
        Comune: "Poggiridenti",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "G431",
        Abitanti: "1910",
      },
      {
        Istat: "15176",
        Comune: "Pogliano Milanese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "G772",
        Abitanti: "8257",
      },
      {
        Istat: "13186",
        Comune: "Pognana Lario",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "G773",
        Abitanti: "793",
      },
      {
        Istat: "16167",
        Comune: "Pognano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "G774",
        Abitanti: "1571",
      },
      {
        Istat: "17144",
        Comune: "Polaveno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "G779",
        Abitanti: "2715",
      },
      {
        Istat: "17145",
        Comune: "Polpenazze del Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "G801",
        Abitanti: "2572",
      },
      {
        Istat: "17146",
        Comune: "Pompiano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "G815",
        Abitanti: "3911",
      },
      {
        Istat: "20043",
        Comune: "Pomponesco",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "46030",
        CodFisco: "G816",
        Abitanti: "1775",
      },
      {
        Istat: "17147",
        Comune: "Poncarale",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "G818",
        Abitanti: "5269",
      },
      {
        Istat: "13187",
        Comune: "Ponna",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "G821",
        Abitanti: "266",
      },
      {
        Istat: "17148",
        Comune: "Ponte di Legno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25056",
        CodFisco: "G844",
        Abitanti: "1771",
      },
      {
        Istat: "14052",
        Comune: "Ponte in Valtellina",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23026",
        CodFisco: "G829",
        Abitanti: "2326",
      },
      {
        Istat: "13188",
        Comune: "Ponte Lambro",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22037",
        CodFisco: "G847",
        Abitanti: "4483",
      },
      {
        Istat: "18117",
        Comune: "Ponte Nizza",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "G851",
        Abitanti: "822",
      },
      {
        Istat: "16168",
        Comune: "Ponte Nossa",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24028",
        CodFisco: "F941",
        Abitanti: "1923",
      },
      {
        Istat: "16170",
        Comune: "Ponte San Pietro",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24036",
        CodFisco: "G856",
        Abitanti: "11543",
      },
      {
        Istat: "16169",
        Comune: "Ponteranica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24010",
        CodFisco: "G853",
        Abitanti: "6825",
      },
      {
        Istat: "17149",
        Comune: "Pontevico",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25026",
        CodFisco: "G859",
        Abitanti: "7221",
      },
      {
        Istat: "20044",
        Comune: "Ponti sul Mincio",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "G862",
        Abitanti: "2322",
      },
      {
        Istat: "16171",
        Comune: "Pontida",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "G864",
        Abitanti: "3229",
      },
      {
        Istat: "16172",
        Comune: "Pontirolo Nuovo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24040",
        CodFisco: "G867",
        Abitanti: "5102",
      },
      {
        Istat: "17150",
        Comune: "Pontoglio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25037",
        CodFisco: "G869",
        Abitanti: "7009",
      },
      {
        Istat: "13189",
        Comune: "Porlezza",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22018",
        CodFisco: "G889",
        Abitanti: "4648",
      },
      {
        Istat: "18118",
        Comune: "Portalbera",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "G895",
        Abitanti: "1577",
      },
      {
        Istat: "12113",
        Comune: "Porto Ceresio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "G906",
        Abitanti: "3075",
      },
      {
        Istat: "20045",
        Comune: "Porto Mantovano",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46047",
        CodFisco: "G917",
        Abitanti: "16123",
      },
      {
        Istat: "12114",
        Comune: "Porto Valtravaglia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "G907",
        Abitanti: "2440",
      },
      {
        Istat: "14053",
        Comune: "Postalesio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "G937",
        Abitanti: "662",
      },
      {
        Istat: "19077",
        Comune: "Pozzaglio ed Uniti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26010",
        CodFisco: "B914",
        Abitanti: "1451",
      },
      {
        Istat: "15177",
        Comune: "Pozzo d'Adda",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "G955",
        Abitanti: "5523",
      },
      {
        Istat: "17151",
        Comune: "Pozzolengo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "G959",
        Abitanti: "3436",
      },
      {
        Istat: "15178",
        Comune: "Pozzuolo Martesana",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "G965",
        Abitanti: "7991",
      },
      {
        Istat: "16173",
        Comune: "Pradalunga",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "G968",
        Abitanti: "4593",
      },
      {
        Istat: "17152",
        Comune: "Pralboino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "G977",
        Abitanti: "2981",
      },
      {
        Istat: "14054",
        Comune: "Prata Camportaccio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23020",
        CodFisco: "G993",
        Abitanti: "2903",
      },
      {
        Istat: "16174",
        Comune: "Predore",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "H020",
        Abitanti: "1895",
      },
      {
        Istat: "15179",
        Comune: "Pregnana Milanese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "H026",
        Abitanti: "6919",
      },
      {
        Istat: "97069",
        Comune: "Premana",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23834",
        CodFisco: "H028",
        Abitanti: "2312",
      },
      {
        Istat: "16175",
        Comune: "Premolo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "H036",
        Abitanti: "1166",
      },
      {
        Istat: "17153",
        Comune: "Preseglie",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "H043",
        Abitanti: "1595",
      },
      {
        Istat: "16176",
        Comune: "Presezzo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "H046",
        Abitanti: "4896",
      },
      {
        Istat: "17154",
        Comune: "Prestine",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "H050",
        Abitanti: "402",
      },
      {
        Istat: "17155",
        Comune: "Prevalle",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "H055",
        Abitanti: "6995",
      },
      {
        Istat: "97070",
        Comune: "Primaluna",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23819",
        CodFisco: "H063",
        Abitanti: "2170",
      },
      {
        Istat: "13192",
        Comune: "Proserpio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "H074",
        Abitanti: "912",
      },
      {
        Istat: "17156",
        Comune: "Provaglio d'Iseo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25050",
        CodFisco: "H078",
        Abitanti: "7137",
      },
      {
        Istat: "17157",
        Comune: "Provaglio Val Sabbia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "H077",
        Abitanti: "969",
      },
      {
        Istat: "17158",
        Comune: "Puegnago sul Garda",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "H086",
        Abitanti: "3258",
      },
      {
        Istat: "16177",
        Comune: "Pumenengo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "H091",
        Abitanti: "1694",
      },
      {
        Istat: "13193",
        Comune: "Pusiano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "H094",
        Abitanti: "1327",
      },
      {
        Istat: "20046",
        Comune: "Quingentole",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "H129",
        Abitanti: "1220",
      },
      {
        Istat: "19078",
        Comune: "Quintano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26017",
        CodFisco: "H130",
        Abitanti: "927",
      },
      {
        Istat: "17159",
        Comune: "Quinzano d'Oglio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25027",
        CodFisco: "H140",
        Abitanti: "6483",
      },
      {
        Istat: "20047",
        Comune: "Quistello",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46026",
        CodFisco: "H143",
        Abitanti: "5856",
      },
      {
        Istat: "13194",
        Comune: "Ramponio Verna",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "H171",
        Abitanti: "410",
      },
      {
        Istat: "12115",
        Comune: "Rancio Valcuvia",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21030",
        CodFisco: "H173",
        Abitanti: "940",
      },
      {
        Istat: "12116",
        Comune: "Ranco",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21020",
        CodFisco: "H174",
        Abitanti: "1371",
      },
      {
        Istat: "16178",
        Comune: "Ranica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "H176",
        Abitanti: "6018",
      },
      {
        Istat: "16179",
        Comune: "Ranzanico",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "H177",
        Abitanti: "1310",
      },
      {
        Istat: "14055",
        Comune: "Rasura",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "H192",
        Abitanti: "297",
      },
      {
        Istat: "18119",
        Comune: "Rea",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "H204",
        Abitanti: "429",
      },
      {
        Istat: "18120",
        Comune: "Redavalle",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27050",
        CodFisco: "H216",
        Abitanti: "1078",
      },
      {
        Istat: "20048",
        Comune: "Redondesco",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46010",
        CodFisco: "H218",
        Abitanti: "1329",
      },
      {
        Istat: "17160",
        Comune: "Remedello",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "H230",
        Abitanti: "3404",
      },
      {
        Istat: "108037",
        Comune: "Renate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20838",
        CodFisco: "H233",
        Abitanti: "4214",
      },
      {
        Istat: "15181",
        Comune: "Rescaldina",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20027",
        CodFisco: "H240",
        Abitanti: "14103",
      },
      {
        Istat: "18121",
        Comune: "Retorbido",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "H246",
        Abitanti: "1474",
      },
      {
        Istat: "20049",
        Comune: "Revere",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46036",
        CodFisco: "H248",
        Abitanti: "2594",
      },
      {
        Istat: "13195",
        Comune: "Rezzago",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "H255",
        Abitanti: "317",
      },
      {
        Istat: "17161",
        Comune: "Rezzato",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25086",
        CodFisco: "H256",
        Abitanti: "13429",
      },
      {
        Istat: "15182",
        Comune: "Rho",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20017",
        CodFisco: "H264",
        Abitanti: "50686",
      },
      {
        Istat: "19079",
        Comune: "Ricengo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "H276",
        Abitanti: "1795",
      },
      {
        Istat: "19080",
        Comune: "Ripalta Arpina",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "H314",
        Abitanti: "1054",
      },
      {
        Istat: "19081",
        Comune: "Ripalta Cremasca",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "H315",
        Abitanti: "3409",
      },
      {
        Istat: "19082",
        Comune: "Ripalta Guerina",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "H316",
        Abitanti: "529",
      },
      {
        Istat: "16180",
        Comune: "Riva di Solto",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "H331",
        Abitanti: "867",
      },
      {
        Istat: "18122",
        Comune: "Rivanazzano Terme",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27055",
        CodFisco: "H336",
        Abitanti: "5144",
      },
      {
        Istat: "19083",
        Comune: "Rivarolo del Re ed Uniti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26036",
        CodFisco: "H341",
        Abitanti: "2091",
      },
      {
        Istat: "20050",
        Comune: "Rivarolo Mantovano",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46017",
        CodFisco: "H342",
        Abitanti: "2661",
      },
      {
        Istat: "19084",
        Comune: "Rivolta d'Adda",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "26027",
        CodFisco: "H357",
        Abitanti: "8056",
      },
      {
        Istat: "97071",
        Comune: "Robbiate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23899",
        CodFisco: "G223",
        Abitanti: "6106",
      },
      {
        Istat: "18123",
        Comune: "Robbio",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27038",
        CodFisco: "H369",
        Abitanti: "6156",
      },
      {
        Istat: "15183",
        Comune: "Robecchetto con Induno",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "H371",
        Abitanti: "4896",
      },
      {
        Istat: "19085",
        Comune: "Robecco d'Oglio",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26010",
        CodFisco: "H372",
        Abitanti: "2393",
      },
      {
        Istat: "18124",
        Comune: "Robecco Pavese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27042",
        CodFisco: "H375",
        Abitanti: "571",
      },
      {
        Istat: "15184",
        Comune: "Robecco sul Naviglio",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20087",
        CodFisco: "H373",
        Abitanti: "6929",
      },
      {
        Istat: "18125",
        Comune: "Rocca de' Giorgi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "H396",
        Abitanti: "74",
      },
      {
        Istat: "18126",
        Comune: "Rocca Susella",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27052",
        CodFisco: "H450",
        Abitanti: "237",
      },
      {
        Istat: "17162",
        Comune: "Roccafranca",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "H410",
        Abitanti: "4819",
      },
      {
        Istat: "15185",
        Comune: "Rodano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "H470",
        Abitanti: "4457",
      },
      {
        Istat: "17163",
        Comune: "Rodengo Saiano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25050",
        CodFisco: "H477",
        Abitanti: "8858",
      },
      {
        Istat: "13197",
        Comune: "Rodero",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "H478",
        Abitanti: "1202",
      },
      {
        Istat: "20051",
        Comune: "Rodigo",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "H481",
        Abitanti: "5399",
      },
      {
        Istat: "17164",
        Comune: "Roè Volciano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25077",
        CodFisco: "H484",
        Abitanti: "4496",
      },
      {
        Istat: "97072",
        Comune: "Rogeno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23849",
        CodFisco: "H486",
        Abitanti: "3239",
      },
      {
        Istat: "18127",
        Comune: "Rognano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "H491",
        Abitanti: "618",
      },
      {
        Istat: "16182",
        Comune: "Rogno",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "H492",
        Abitanti: "3966",
      },
      {
        Istat: "14056",
        Comune: "Rogolo",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "H493",
        Abitanti: "566",
      },
      {
        Istat: "18128",
        Comune: "Romagnese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "H505",
        Abitanti: "752",
      },
      {
        Istat: "19086",
        Comune: "Romanengo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26014",
        CodFisco: "H508",
        Abitanti: "3078",
      },
      {
        Istat: "16183",
        Comune: "Romano di Lombardia",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24058",
        CodFisco: "H509",
        Abitanti: "19049",
      },
      {
        Istat: "13199",
        Comune: "Ronago",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22027",
        CodFisco: "H521",
        Abitanti: "1769",
      },
      {
        Istat: "17165",
        Comune: "Roncadelle",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "H525",
        Abitanti: "9369",
      },
      {
        Istat: "18129",
        Comune: "Roncaro",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "H527",
        Abitanti: "1327",
      },
      {
        Istat: "108055",
        Comune: "Roncello",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20877",
        CodFisco: "H529",
        Abitanti: "3908",
      },
      {
        Istat: "108038",
        Comune: "Ronco Briantino",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20885",
        CodFisco: "H537",
        Abitanti: "3452",
      },
      {
        Istat: "16184",
        Comune: "Roncobello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "H535",
        Abitanti: "436",
      },
      {
        Istat: "20052",
        Comune: "Roncoferraro",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46037",
        CodFisco: "H541",
        Abitanti: "7309",
      },
      {
        Istat: "16185",
        Comune: "Roncola",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "H544",
        Abitanti: "754",
      },
      {
        Istat: "18130",
        Comune: "Rosasco",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "H559",
        Abitanti: "647",
      },
      {
        Istat: "15188",
        Comune: "Rosate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20088",
        CodFisco: "H560",
        Abitanti: "5442",
      },
      {
        Istat: "16186",
        Comune: "Rota d'Imagna",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24037",
        CodFisco: "H584",
        Abitanti: "924",
      },
      {
        Istat: "97073",
        Comune: "Rovagnate",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23888",
        CodFisco: "H596",
        Abitanti: "2953",
      },
      {
        Istat: "17166",
        Comune: "Rovato",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25038",
        CodFisco: "H598",
        Abitanti: "18352",
      },
      {
        Istat: "13201",
        Comune: "Rovellasca",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "22069",
        CodFisco: "H601",
        Abitanti: "7608",
      },
      {
        Istat: "13202",
        Comune: "Rovello Porro",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "22070",
        CodFisco: "H602",
        Abitanti: "6105",
      },
      {
        Istat: "20053",
        Comune: "Roverbella",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46048",
        CodFisco: "H604",
        Abitanti: "8649",
      },
      {
        Istat: "18131",
        Comune: "Rovescala",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "H614",
        Abitanti: "951",
      },
      {
        Istat: "16187",
        Comune: "Rovetta",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "H615",
        Abitanti: "3966",
      },
      {
        Istat: "15189",
        Comune: "Rozzano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20089",
        CodFisco: "H623",
        Abitanti: "41363",
      },
      {
        Istat: "17167",
        Comune: "Rudiano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "H630",
        Abitanti: "5861",
      },
      {
        Istat: "18132",
        Comune: "Ruino",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "H637",
        Abitanti: "755",
      },
      {
        Istat: "17168",
        Comune: "Sabbio Chiese",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "H650",
        Abitanti: "3807",
      },
      {
        Istat: "20054",
        Comune: "Sabbioneta",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "46018",
        CodFisco: "H652",
        Abitanti: "4357",
      },
      {
        Istat: "13203",
        Comune: "Sala Comacina",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "H679",
        Abitanti: "598",
      },
      {
        Istat: "17169",
        Comune: "Sale Marasino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25057",
        CodFisco: "H699",
        Abitanti: "3387",
      },
      {
        Istat: "98046",
        Comune: "Salerano sul Lambro",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26857",
        CodFisco: "H701",
        Abitanti: "2683",
      },
      {
        Istat: "17170",
        Comune: "Salò",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25087",
        CodFisco: "H717",
        Abitanti: "10740",
      },
      {
        Istat: "12117",
        Comune: "Saltrio",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21050",
        CodFisco: "H723",
        Abitanti: "3062",
      },
      {
        Istat: "19087",
        Comune: "Salvirola",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "H731",
        Abitanti: "1176",
      },
      {
        Istat: "12118",
        Comune: "Samarate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21017",
        CodFisco: "H736",
        Abitanti: "16362",
      },
      {
        Istat: "14057",
        Comune: "Samolaco",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23027",
        CodFisco: "H752",
        Abitanti: "2914",
      },
      {
        Istat: "13204",
        Comune: "San Bartolomeo Val Cavargna",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "H760",
        Abitanti: "1049",
      },
      {
        Istat: "19088",
        Comune: "San Bassano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "H767",
        Abitanti: "2227",
      },
      {
        Istat: "20055",
        Comune: "San Benedetto Po",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46027",
        CodFisco: "H771",
        Abitanti: "7791",
      },
      {
        Istat: "18133",
        Comune: "San Cipriano Po",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27043",
        CodFisco: "H799",
        Abitanti: "503",
      },
      {
        Istat: "15191",
        Comune: "San Colombano al Lambro",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "20078",
        CodFisco: "H803",
        Abitanti: "7468",
      },
      {
        Istat: "18134",
        Comune: "San Damiano al Colle",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "H814",
        Abitanti: "740",
      },
      {
        Istat: "19089",
        Comune: "San Daniele Po",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26046",
        CodFisco: "H815",
        Abitanti: "1472",
      },
      {
        Istat: "15192",
        Comune: "San Donato Milanese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20097",
        CodFisco: "H827",
        Abitanti: "32702",
      },
      {
        Istat: "13205",
        Comune: "San Fedele Intelvi",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22028",
        CodFisco: "H830",
        Abitanti: "1778",
      },
      {
        Istat: "17171",
        Comune: "San Felice del Benaco",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25010",
        CodFisco: "H838",
        Abitanti: "3424",
      },
      {
        Istat: "13206",
        Comune: "San Fermo della Battaglia",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "H840",
        Abitanti: "4489",
      },
      {
        Istat: "98047",
        Comune: "San Fiorano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26848",
        CodFisco: "H844",
        Abitanti: "1811",
      },
      {
        Istat: "18135",
        Comune: "San Genesio ed Uniti",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "H859",
        Abitanti: "3791",
      },
      {
        Istat: "17172",
        Comune: "San Gervasio Bresciano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "H865",
        Abitanti: "2470",
      },
      {
        Istat: "20056",
        Comune: "San Giacomo delle Segnate",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46020",
        CodFisco: "H870",
        Abitanti: "1774",
      },
      {
        Istat: "14058",
        Comune: "San Giacomo Filippo",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23020",
        CodFisco: "H868",
        Abitanti: "415",
      },
      {
        Istat: "18136",
        Comune: "San Giorgio di Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "H885",
        Abitanti: "1189",
      },
      {
        Istat: "20057",
        Comune: "San Giorgio di Mantova",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46030",
        CodFisco: "H883",
        Abitanti: "9482",
      },
      {
        Istat: "15194",
        Comune: "San Giorgio su Legnano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20010",
        CodFisco: "H884",
        Abitanti: "6776",
      },
      {
        Istat: "16188",
        Comune: "San Giovanni Bianco",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24015",
        CodFisco: "H910",
        Abitanti: "5094",
      },
      {
        Istat: "20058",
        Comune: "San Giovanni del Dosso",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "H912",
        Abitanti: "1389",
      },
      {
        Istat: "19090",
        Comune: "San Giovanni in Croce",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26037",
        CodFisco: "H918",
        Abitanti: "1880",
      },
      {
        Istat: "15195",
        Comune: "San Giuliano Milanese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20098",
        CodFisco: "H930",
        Abitanti: "36871",
      },
      {
        Istat: "20059",
        Comune: "San Martino dall'Argine",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46010",
        CodFisco: "I005",
        Abitanti: "1829",
      },
      {
        Istat: "19091",
        Comune: "San Martino del Lago",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26040",
        CodFisco: "I007",
        Abitanti: "489",
      },
      {
        Istat: "98048",
        Comune: "San Martino in Strada",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26817",
        CodFisco: "I012",
        Abitanti: "3624",
      },
      {
        Istat: "18137",
        Comune: "San Martino Siccomario",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27028",
        CodFisco: "I014",
        Abitanti: "5766",
      },
      {
        Istat: "13207",
        Comune: "San Nazzaro Val Cavargna",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "I051",
        Abitanti: "348",
      },
      {
        Istat: "17138",
        Comune: "San Paolo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "G407",
        Abitanti: "4553",
      },
      {
        Istat: "16189",
        Comune: "San Paolo d'Argon",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "B310",
        Abitanti: "5435",
      },
      {
        Istat: "16190",
        Comune: "San Pellegrino Terme",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24016",
        CodFisco: "I079",
        Abitanti: "4974",
      },
      {
        Istat: "98049",
        Comune: "San Rocco al Porto",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26865",
        CodFisco: "I140",
        Abitanti: "3578",
      },
      {
        Istat: "13248",
        Comune: "San Siro",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "I162",
        Abitanti: "1804",
      },
      {
        Istat: "15201",
        Comune: "San Vittore Olona",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20028",
        CodFisco: "I409",
        Abitanti: "8285",
      },
      {
        Istat: "17173",
        Comune: "San Zeno Naviglio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "I412",
        Abitanti: "4671",
      },
      {
        Istat: "15202",
        Comune: "San Zenone al Lambro",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20070",
        CodFisco: "I415",
        Abitanti: "4193",
      },
      {
        Istat: "18145",
        Comune: "San Zenone al Po",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "I416",
        Abitanti: "625",
      },
      {
        Istat: "12141",
        Comune: "Sangiano",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21038",
        CodFisco: "H872",
        Abitanti: "1507",
      },
      {
        Istat: "18138",
        Comune: "Sannazzaro de' Burgondi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27039",
        CodFisco: "I048",
        Abitanti: "5869",
      },
      {
        Istat: "16191",
        Comune: "Santa Brigida",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "I168",
        Abitanti: "586",
      },
      {
        Istat: "18139",
        Comune: "Santa Cristina e Bissone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "I175",
        Abitanti: "2026",
      },
      {
        Istat: "18140",
        Comune: "Santa Giuletta",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27046",
        CodFisco: "I203",
        Abitanti: "1679",
      },
      {
        Istat: "18142",
        Comune: "Santa Margherita di Staffora",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "I230",
        Abitanti: "530",
      },
      {
        Istat: "18143",
        Comune: "Santa Maria della Versa",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27047",
        CodFisco: "I237",
        Abitanti: "2519",
      },
      {
        Istat: "97074",
        Comune: "Santa Maria Hoè",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23889",
        CodFisco: "I243",
        Abitanti: "2256",
      },
      {
        Istat: "18141",
        Comune: "Sant'Alessio con Vialone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27016",
        CodFisco: "I213",
        Abitanti: "845",
      },
      {
        Istat: "98050",
        Comune: "Sant'Angelo Lodigiano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26866",
        CodFisco: "I274",
        Abitanti: "13279",
      },
      {
        Istat: "18144",
        Comune: "Sant'Angelo Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "I276",
        Abitanti: "902",
      },
      {
        Istat: "98051",
        Comune: "Santo Stefano Lodigiano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26849",
        CodFisco: "I362",
        Abitanti: "1929",
      },
      {
        Istat: "15200",
        Comune: "Santo Stefano Ticino",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "I361",
        Abitanti: "4731",
      },
      {
        Istat: "16192",
        Comune: "Sant'Omobono Terme",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24038",
        CodFisco: "I349",
        Abitanti: "3571",
      },
      {
        Istat: "17174",
        Comune: "Sarezzo",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25068",
        CodFisco: "I433",
        Abitanti: "13430",
      },
      {
        Istat: "16193",
        Comune: "Sarnico",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24067",
        CodFisco: "I437",
        Abitanti: "6652",
      },
      {
        Istat: "12119",
        Comune: "Saronno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "21047",
        CodFisco: "I441",
        Abitanti: "39161",
      },
      {
        Istat: "18146",
        Comune: "Sartirana Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "I447",
        Abitanti: "1787",
      },
      {
        Istat: "17175",
        Comune: "Saviore dell'Adamello",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25040",
        CodFisco: "I476",
        Abitanti: "1021",
      },
      {
        Istat: "18147",
        Comune: "Scaldasole",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "I487",
        Abitanti: "998",
      },
      {
        Istat: "19092",
        Comune: "Scandolara Ravara",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26040",
        CodFisco: "I497",
        Abitanti: "1520",
      },
      {
        Istat: "19093",
        Comune: "Scandolara Ripa d'Oglio",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26047",
        CodFisco: "I498",
        Abitanti: "626",
      },
      {
        Istat: "16194",
        Comune: "Scanzorosciate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "I506",
        Abitanti: "9843",
      },
      {
        Istat: "13211",
        Comune: "Schignano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "I529",
        Abitanti: "914",
      },
      {
        Istat: "16195",
        Comune: "Schilpario",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "I530",
        Abitanti: "1265",
      },
      {
        Istat: "20060",
        Comune: "Schivenoglia",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "I532",
        Abitanti: "1281",
      },
      {
        Istat: "98052",
        Comune: "Secugnago",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26826",
        CodFisco: "I561",
        Abitanti: "2023",
      },
      {
        Istat: "15204",
        Comune: "Sedriano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20018",
        CodFisco: "I566",
        Abitanti: "11277",
      },
      {
        Istat: "16196",
        Comune: "Sedrina",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "I567",
        Abitanti: "2559",
      },
      {
        Istat: "15205",
        Comune: "Segrate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "I577",
        Abitanti: "34352",
      },
      {
        Istat: "17176",
        Comune: "Sellero",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "I588",
        Abitanti: "1497",
      },
      {
        Istat: "16197",
        Comune: "Selvino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "I597",
        Abitanti: "2015",
      },
      {
        Istat: "18148",
        Comune: "Semiana",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "I599",
        Abitanti: "251",
      },
      {
        Istat: "15206",
        Comune: "Senago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20030",
        CodFisco: "I602",
        Abitanti: "21273",
      },
      {
        Istat: "17177",
        Comune: "Seniga",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25020",
        CodFisco: "I607",
        Abitanti: "1615",
      },
      {
        Istat: "13212",
        Comune: "Senna Comasco",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "I611",
        Abitanti: "3211",
      },
      {
        Istat: "98053",
        Comune: "Senna Lodigiana",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26856",
        CodFisco: "I612",
        Abitanti: "2051",
      },
      {
        Istat: "108039",
        Comune: "Seregno",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20831",
        CodFisco: "I625",
        Abitanti: "43163",
      },
      {
        Istat: "19094",
        Comune: "Sergnano",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "I627",
        Abitanti: "3675",
      },
      {
        Istat: "16198",
        Comune: "Seriate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24068",
        CodFisco: "I628",
        Abitanti: "24297",
      },
      {
        Istat: "16199",
        Comune: "Serina",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24017",
        CodFisco: "I629",
        Abitanti: "2187",
      },
      {
        Istat: "17178",
        Comune: "Serle",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25080",
        CodFisco: "I631",
        Abitanti: "3119",
      },
      {
        Istat: "20061",
        Comune: "Sermide",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46028",
        CodFisco: "I632",
        Abitanti: "6428",
      },
      {
        Istat: "14059",
        Comune: "Sernio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "I636",
        Abitanti: "503",
      },
      {
        Istat: "20062",
        Comune: "Serravalle a Po",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46030",
        CodFisco: "I662",
        Abitanti: "1650",
      },
      {
        Istat: "12120",
        Comune: "Sesto Calende",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21018",
        CodFisco: "I688",
        Abitanti: "10830",
      },
      {
        Istat: "19095",
        Comune: "Sesto ed Uniti",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26028",
        CodFisco: "I683",
        Abitanti: "3000",
      },
      {
        Istat: "15209",
        Comune: "Sesto San Giovanni",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20099",
        CodFisco: "I690",
        Abitanti: "81130",
      },
      {
        Istat: "15210",
        Comune: "Settala",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "I696",
        Abitanti: "7465",
      },
      {
        Istat: "15211",
        Comune: "Settimo Milanese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20019",
        CodFisco: "I700",
        Abitanti: "19464",
      },
      {
        Istat: "108040",
        Comune: "Seveso",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20822",
        CodFisco: "I709",
        Abitanti: "22877",
      },
      {
        Istat: "18149",
        Comune: "Silvano Pietra",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "I739",
        Abitanti: "693",
      },
      {
        Istat: "17179",
        Comune: "Sirmione",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25019",
        CodFisco: "I633",
        Abitanti: "8150",
      },
      {
        Istat: "97075",
        Comune: "Sirone",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23844",
        CodFisco: "I759",
        Abitanti: "2372",
      },
      {
        Istat: "97076",
        Comune: "Sirtori",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23896",
        CodFisco: "I761",
        Abitanti: "2950",
      },
      {
        Istat: "18150",
        Comune: "Siziano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "E265",
        Abitanti: "5908",
      },
      {
        Istat: "17180",
        Comune: "Soiano del Lago",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "I782",
        Abitanti: "1863",
      },
      {
        Istat: "15213",
        Comune: "Solaro",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20020",
        CodFisco: "I786",
        Abitanti: "14203",
      },
      {
        Istat: "19096",
        Comune: "Solarolo Rainerio",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26030",
        CodFisco: "I790",
        Abitanti: "1017",
      },
      {
        Istat: "13215",
        Comune: "Solbiate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "I792",
        Abitanti: "2561",
      },
      {
        Istat: "12121",
        Comune: "Solbiate Arno",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21048",
        CodFisco: "I793",
        Abitanti: "4356",
      },
      {
        Istat: "12122",
        Comune: "Solbiate Olona",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21058",
        CodFisco: "I794",
        Abitanti: "5627",
      },
      {
        Istat: "20063",
        Comune: "Solferino",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46040",
        CodFisco: "I801",
        Abitanti: "2628",
      },
      {
        Istat: "16200",
        Comune: "Solto Collina",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "I812",
        Abitanti: "1709",
      },
      {
        Istat: "16251",
        Comune: "Solza",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "I813",
        Abitanti: "1948",
      },
      {
        Istat: "98054",
        Comune: "Somaglia",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26867",
        CodFisco: "I815",
        Abitanti: "3708",
      },
      {
        Istat: "12123",
        Comune: "Somma Lombardo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21019",
        CodFisco: "I819",
        Abitanti: "17437",
      },
      {
        Istat: "18151",
        Comune: "Sommo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27048",
        CodFisco: "I825",
        Abitanti: "1132",
      },
      {
        Istat: "19097",
        Comune: "Soncino",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26029",
        CodFisco: "I827",
        Abitanti: "7767",
      },
      {
        Istat: "14060",
        Comune: "Sondalo",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23035",
        CodFisco: "I828",
        Abitanti: "4281",
      },
      {
        Istat: "14061",
        Comune: "Sondrio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23100",
        CodFisco: "I829",
        Abitanti: "22365",
      },
      {
        Istat: "16201",
        Comune: "Songavazzo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "I830",
        Abitanti: "701",
      },
      {
        Istat: "17181",
        Comune: "Sonico",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25048",
        CodFisco: "I831",
        Abitanti: "1269",
      },
      {
        Istat: "98055",
        Comune: "Sordio",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "26858",
        CodFisco: "I848",
        Abitanti: "3120",
      },
      {
        Istat: "19098",
        Comune: "Soresina",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26015",
        CodFisco: "I849",
        Abitanti: "9345",
      },
      {
        Istat: "13216",
        Comune: "Sorico",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "I856",
        Abitanti: "1255",
      },
      {
        Istat: "16202",
        Comune: "Sorisole",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24010",
        CodFisco: "I858",
        Abitanti: "9120",
      },
      {
        Istat: "13217",
        Comune: "Sormano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22030",
        CodFisco: "I860",
        Abitanti: "682",
      },
      {
        Istat: "19099",
        Comune: "Sospiro",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26048",
        CodFisco: "I865",
        Abitanti: "3086",
      },
      {
        Istat: "16203",
        Comune: "Sotto il Monte Giovanni XXIII",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24039",
        CodFisco: "I869",
        Abitanti: "4239",
      },
      {
        Istat: "16204",
        Comune: "Sovere",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "I873",
        Abitanti: "5486",
      },
      {
        Istat: "108041",
        Comune: "Sovico",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20845",
        CodFisco: "I878",
        Abitanti: "8121",
      },
      {
        Istat: "18152",
        Comune: "Spessa",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "I894",
        Abitanti: "597",
      },
      {
        Istat: "19100",
        Comune: "Spinadesco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26020",
        CodFisco: "I906",
        Abitanti: "1582",
      },
      {
        Istat: "19101",
        Comune: "Spineda",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "26030",
        CodFisco: "I909",
        Abitanti: "641",
      },
      {
        Istat: "19102",
        Comune: "Spino d'Adda",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26016",
        CodFisco: "I914",
        Abitanti: "7007",
      },
      {
        Istat: "16205",
        Comune: "Spinone al Lago",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "I916",
        Abitanti: "1037",
      },
      {
        Istat: "16206",
        Comune: "Spirano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "I919",
        Abitanti: "5652",
      },
      {
        Istat: "14062",
        Comune: "Spriana",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "I928",
        Abitanti: "105",
      },
      {
        Istat: "19103",
        Comune: "Stagno Lombardo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26049",
        CodFisco: "I935",
        Abitanti: "1544",
      },
      {
        Istat: "13218",
        Comune: "Stazzona",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "I943",
        Abitanti: "647",
      },
      {
        Istat: "16207",
        Comune: "Stezzano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "I951",
        Abitanti: "12867",
      },
      {
        Istat: "18153",
        Comune: "Stradella",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27049",
        CodFisco: "I968",
        Abitanti: "11674",
      },
      {
        Istat: "16208",
        Comune: "Strozza",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "I986",
        Abitanti: "1075",
      },
      {
        Istat: "18154",
        Comune: "Suardi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "B014",
        Abitanti: "673",
      },
      {
        Istat: "97077",
        Comune: "Sueglio",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23835",
        CodFisco: "I994",
        Abitanti: "151",
      },
      {
        Istat: "97078",
        Comune: "Suello",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "23867",
        CodFisco: "I996",
        Abitanti: "1689",
      },
      {
        Istat: "16209",
        Comune: "Suisio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "I997",
        Abitanti: "3888",
      },
      {
        Istat: "108042",
        Comune: "Sulbiate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20884",
        CodFisco: "I998",
        Abitanti: "3995",
      },
      {
        Istat: "17182",
        Comune: "Sulzano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25058",
        CodFisco: "L002",
        Abitanti: "1956",
      },
      {
        Istat: "12124",
        Comune: "Sumirago",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "L003",
        Abitanti: "6274",
      },
      {
        Istat: "20064",
        Comune: "Sustinente",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46030",
        CodFisco: "L015",
        Abitanti: "2238",
      },
      {
        Istat: "20065",
        Comune: "Suzzara",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46029",
        CodFisco: "L020",
        Abitanti: "20648",
      },
      {
        Istat: "97079",
        Comune: "Taceno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23837",
        CodFisco: "L022",
        Abitanti: "540",
      },
      {
        Istat: "12125",
        Comune: "Taino",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21020",
        CodFisco: "L032",
        Abitanti: "3798",
      },
      {
        Istat: "14063",
        Comune: "Talamona",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23018",
        CodFisco: "L035",
        Abitanti: "4767",
      },
      {
        Istat: "16210",
        Comune: "Taleggio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "L037",
        Abitanti: "625",
      },
      {
        Istat: "14064",
        Comune: "Tartano",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "L056",
        Abitanti: "194",
      },
      {
        Istat: "98056",
        Comune: "Tavazzano con Villavesco",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26838",
        CodFisco: "F260",
        Abitanti: "6125",
      },
      {
        Istat: "13222",
        Comune: "Tavernerio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22038",
        CodFisco: "L071",
        Abitanti: "5823",
      },
      {
        Istat: "16211",
        Comune: "Tavernola Bergamasca",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "L073",
        Abitanti: "2146",
      },
      {
        Istat: "17183",
        Comune: "Tavernole sul Mella",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25060",
        CodFisco: "C698",
        Abitanti: "1411",
      },
      {
        Istat: "14065",
        Comune: "Teglio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23036",
        CodFisco: "L084",
        Abitanti: "4769",
      },
      {
        Istat: "16212",
        Comune: "Telgate",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "L087",
        Abitanti: "4847",
      },
      {
        Istat: "17184",
        Comune: "Temù",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "L094",
        Abitanti: "1085",
      },
      {
        Istat: "12126",
        Comune: "Ternate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "L115",
        Abitanti: "2467",
      },
      {
        Istat: "16213",
        Comune: "Terno d'Isola",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "L118",
        Abitanti: "7665",
      },
      {
        Istat: "98057",
        Comune: "Terranova dei Passerini",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26827",
        CodFisco: "L125",
        Abitanti: "914",
      },
      {
        Istat: "19104",
        Comune: "Ticengo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26020",
        CodFisco: "L164",
        Abitanti: "444",
      },
      {
        Istat: "17185",
        Comune: "Tignale",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "L169",
        Abitanti: "1312",
      },
      {
        Istat: "14066",
        Comune: "Tirano",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23037",
        CodFisco: "L175",
        Abitanti: "9238",
      },
      {
        Istat: "17186",
        Comune: "Torbole Casaglia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "L210",
        Abitanti: "6332",
      },
      {
        Istat: "19105",
        Comune: "Torlino Vimercati",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26017",
        CodFisco: "L221",
        Abitanti: "445",
      },
      {
        Istat: "19106",
        Comune: "Tornata",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26030",
        CodFisco: "L225",
        Abitanti: "500",
      },
      {
        Istat: "13223",
        Comune: "Torno",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "L228",
        Abitanti: "1214",
      },
      {
        Istat: "18155",
        Comune: "Torrazza Coste",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "L237",
        Abitanti: "1681",
      },
      {
        Istat: "18156",
        Comune: "Torre Beretti e Castellaro",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "L250",
        Abitanti: "606",
      },
      {
        Istat: "16214",
        Comune: "Torre Boldone",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "L251",
        Abitanti: "8334",
      },
      {
        Istat: "18157",
        Comune: "Torre d'Arese",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "L256",
        Abitanti: "980",
      },
      {
        Istat: "97080",
        Comune: "Torre de' Busi",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "23806",
        CodFisco: "L257",
        Abitanti: "1970",
      },
      {
        Istat: "18158",
        Comune: "Torre de' Negri",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27011",
        CodFisco: "L262",
        Abitanti: "339",
      },
      {
        Istat: "19107",
        Comune: "Torre de' Picenardi",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26038",
        CodFisco: "L258",
        Abitanti: "1801",
      },
      {
        Istat: "16216",
        Comune: "Torre de' Roveri",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "L265",
        Abitanti: "2326",
      },
      {
        Istat: "14067",
        Comune: "Torre di Santa Maria",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "L244",
        Abitanti: "839",
      },
      {
        Istat: "18159",
        Comune: "Torre d'Isola",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "L269",
        Abitanti: "2359",
      },
      {
        Istat: "16217",
        Comune: "Torre Pallavicina",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24050",
        CodFisco: "L276",
        Abitanti: "1138",
      },
      {
        Istat: "18160",
        Comune: "Torrevecchia Pia",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "L285",
        Abitanti: "3409",
      },
      {
        Istat: "19108",
        Comune: "Torricella del Pizzo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26040",
        CodFisco: "L296",
        Abitanti: "693",
      },
      {
        Istat: "18161",
        Comune: "Torricella Verzate",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "L292",
        Abitanti: "849",
      },
      {
        Istat: "17187",
        Comune: "Toscolano-Maderno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25088",
        CodFisco: "L312",
        Abitanti: "8111",
      },
      {
        Istat: "14068",
        Comune: "Tovo di Sant'Agata",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "L316",
        Abitanti: "630",
      },
      {
        Istat: "12127",
        Comune: "Tradate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21049",
        CodFisco: "L319",
        Abitanti: "17901",
      },
      {
        Istat: "14069",
        Comune: "Traona",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23019",
        CodFisco: "L330",
        Abitanti: "2534",
      },
      {
        Istat: "18162",
        Comune: "Travacò Siccomario",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "I236",
        Abitanti: "4280",
      },
      {
        Istat: "17188",
        Comune: "Travagliato",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25039",
        CodFisco: "L339",
        Abitanti: "13415",
      },
      {
        Istat: "12128",
        Comune: "Travedona-Monate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21028",
        CodFisco: "L342",
        Abitanti: "4029",
      },
      {
        Istat: "97081",
        Comune: "Tremenico",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23836",
        CodFisco: "L368",
        Abitanti: "191",
      },
      {
        Istat: "13225",
        Comune: "Tremezzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22019",
        CodFisco: "L371",
        Abitanti: "1260",
      },
      {
        Istat: "17189",
        Comune: "Tremosine",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25010",
        CodFisco: "L372",
        Abitanti: "2154",
      },
      {
        Istat: "17190",
        Comune: "Trenzano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "L380",
        Abitanti: "5551",
      },
      {
        Istat: "16218",
        Comune: "Trescore Balneario",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24069",
        CodFisco: "L388",
        Abitanti: "9685",
      },
      {
        Istat: "19109",
        Comune: "Trescore Cremasco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26017",
        CodFisco: "L389",
        Abitanti: "2931",
      },
      {
        Istat: "14070",
        Comune: "Tresivio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23020",
        CodFisco: "L392",
        Abitanti: "2024",
      },
      {
        Istat: "16219",
        Comune: "Treviglio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "24047",
        CodFisco: "L400",
        Abitanti: "29034",
      },
      {
        Istat: "16220",
        Comune: "Treviolo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24048",
        CodFisco: "L404",
        Abitanti: "10297",
      },
      {
        Istat: "17191",
        Comune: "Treviso Bresciano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25070",
        CodFisco: "L406",
        Abitanti: "568",
      },
      {
        Istat: "15219",
        Comune: "Trezzano Rosa",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "L408",
        Abitanti: "4804",
      },
      {
        Istat: "15220",
        Comune: "Trezzano sul Naviglio",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "L409",
        Abitanti: "19350",
      },
      {
        Istat: "15221",
        Comune: "Trezzo sull'Adda",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20056",
        CodFisco: "L411",
        Abitanti: "12249",
      },
      {
        Istat: "13226",
        Comune: "Trezzone",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "L413",
        Abitanti: "239",
      },
      {
        Istat: "15222",
        Comune: "Tribiano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20067",
        CodFisco: "L415",
        Abitanti: "3330",
      },
      {
        Istat: "19110",
        Comune: "Trigolo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "374",
        CAP: "26018",
        CodFisco: "L426",
        Abitanti: "1772",
      },
      {
        Istat: "108043",
        Comune: "Triuggio",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20844",
        CodFisco: "L434",
        Abitanti: "8396",
      },
      {
        Istat: "18163",
        Comune: "Trivolzio",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "L440",
        Abitanti: "1986",
      },
      {
        Istat: "18164",
        Comune: "Tromello",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "L449",
        Abitanti: "3873",
      },
      {
        Istat: "12129",
        Comune: "Tronzano Lago Maggiore",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "A705",
        Abitanti: "262",
      },
      {
        Istat: "18165",
        Comune: "Trovo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "L453",
        Abitanti: "1013",
      },
      {
        Istat: "15224",
        Comune: "Truccazzano",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "L454",
        Abitanti: "5982",
      },
      {
        Istat: "98058",
        Comune: "Turano Lodigiano",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "377",
        CAP: "26828",
        CodFisco: "L469",
        Abitanti: "1576",
      },
      {
        Istat: "13227",
        Comune: "Turate",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "22078",
        CodFisco: "L470",
        Abitanti: "9016",
      },
      {
        Istat: "15226",
        Comune: "Turbigo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20029",
        CodFisco: "L471",
        Abitanti: "7485",
      },
      {
        Istat: "16221",
        Comune: "Ubiale Clanezzo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "C789",
        Abitanti: "1395",
      },
      {
        Istat: "12130",
        Comune: "Uboldo",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "21040",
        CodFisco: "L480",
        Abitanti: "10489",
      },
      {
        Istat: "13228",
        Comune: "Uggiate-Trevano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22029",
        CodFisco: "L487",
        Abitanti: "4431",
      },
      {
        Istat: "17192",
        Comune: "Urago d'Oglio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "L494",
        Abitanti: "4052",
      },
      {
        Istat: "16222",
        Comune: "Urgnano",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24059",
        CodFisco: "L502",
        Abitanti: "9592",
      },
      {
        Istat: "108044",
        Comune: "Usmate Velate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20865",
        CodFisco: "L511",
        Abitanti: "10019",
      },
      {
        Istat: "19111",
        Comune: "Vaiano Cremasco",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "373",
        CAP: "26010",
        CodFisco: "L535",
        Abitanti: "3919",
      },
      {
        Istat: "19112",
        Comune: "Vailate",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "363",
        CAP: "26019",
        CodFisco: "L539",
        Abitanti: "4499",
      },
      {
        Istat: "18166",
        Comune: "Val di Nizza",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "L562",
        Abitanti: "682",
      },
      {
        Istat: "14074",
        Comune: "Val Masino",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23010",
        CodFisco: "L638",
        Abitanti: "946",
      },
      {
        Istat: "13233",
        Comune: "Val Rezzo",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "H259",
        Abitanti: "182",
      },
      {
        Istat: "16223",
        Comune: "Valbondione",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "L544",
        Abitanti: "1097",
      },
      {
        Istat: "16224",
        Comune: "Valbrembo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "L545",
        Abitanti: "3753",
      },
      {
        Istat: "13229",
        Comune: "Valbrona",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22039",
        CodFisco: "L547",
        Abitanti: "2737",
      },
      {
        Istat: "14071",
        Comune: "Valdidentro",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23038",
        CodFisco: "L557",
        Abitanti: "4068",
      },
      {
        Istat: "14072",
        Comune: "Valdisotto",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "L563",
        Abitanti: "3508",
      },
      {
        Istat: "18167",
        Comune: "Valeggio",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "L568",
        Abitanti: "233",
      },
      {
        Istat: "98059",
        Comune: "Valera Fratta",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26859",
        CodFisco: "L572",
        Abitanti: "1653",
      },
      {
        Istat: "14073",
        Comune: "Valfurva",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "L576",
        Abitanti: "2732",
      },
      {
        Istat: "12131",
        Comune: "Valganna",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21039",
        CodFisco: "L577",
        Abitanti: "1622",
      },
      {
        Istat: "16225",
        Comune: "Valgoglio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "L579",
        Abitanti: "616",
      },
      {
        Istat: "97082",
        Comune: "Valgreghentino",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23857",
        CodFisco: "L581",
        Abitanti: "3389",
      },
      {
        Istat: "18168",
        Comune: "Valle Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "L593",
        Abitanti: "2241",
      },
      {
        Istat: "18169",
        Comune: "Valle Salimbene",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "L617",
        Abitanti: "1494",
      },
      {
        Istat: "16226",
        Comune: "Valleve",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "L623",
        Abitanti: "137",
      },
      {
        Istat: "17193",
        Comune: "Vallio Terme",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "L626",
        Abitanti: "1337",
      },
      {
        Istat: "97083",
        Comune: "Valmadrera",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23868",
        CodFisco: "L634",
        Abitanti: "11668",
      },
      {
        Istat: "13232",
        Comune: "Valmorea",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "L640",
        Abitanti: "2687",
      },
      {
        Istat: "16227",
        Comune: "Valnegra",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "L642",
        Abitanti: "209",
      },
      {
        Istat: "16228",
        Comune: "Valsecca",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "L649",
        Abitanti: "425",
      },
      {
        Istat: "13234",
        Comune: "Valsolda",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22010",
        CodFisco: "C936",
        Abitanti: "1639",
      },
      {
        Istat: "16229",
        Comune: "Valtorta",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "L655",
        Abitanti: "295",
      },
      {
        Istat: "18170",
        Comune: "Valverde",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27050",
        CodFisco: "L659",
        Abitanti: "314",
      },
      {
        Istat: "17194",
        Comune: "Valvestino",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25080",
        CodFisco: "L468",
        Abitanti: "214",
      },
      {
        Istat: "15249",
        Comune: "Vanzaghello",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "L664",
        Abitanti: "5307",
      },
      {
        Istat: "15229",
        Comune: "Vanzago",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "L665",
        Abitanti: "8894",
      },
      {
        Istat: "15230",
        Comune: "Vaprio d'Adda",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20069",
        CodFisco: "L667",
        Abitanti: "8045",
      },
      {
        Istat: "12132",
        Comune: "Varano Borghi",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21020",
        CodFisco: "L671",
        Abitanti: "2383",
      },
      {
        Istat: "108045",
        Comune: "Varedo",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20814",
        CodFisco: "L677",
        Abitanti: "12899",
      },
      {
        Istat: "97084",
        Comune: "Varenna",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23829",
        CodFisco: "L680",
        Abitanti: "812",
      },
      {
        Istat: "12133",
        Comune: "Varese",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21100",
        CodFisco: "L682",
        Abitanti: "81579",
      },
      {
        Istat: "18171",
        Comune: "Varzi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27057",
        CodFisco: "L690",
        Abitanti: "3420",
      },
      {
        Istat: "108046",
        Comune: "Vedano al Lambro",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20854",
        CodFisco: "L704",
        Abitanti: "7669",
      },
      {
        Istat: "12134",
        Comune: "Vedano Olona",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21040",
        CodFisco: "L703",
        Abitanti: "7370",
      },
      {
        Istat: "12135",
        Comune: "Veddasca",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21010",
        CodFisco: "L705",
        Abitanti: "277",
      },
      {
        Istat: "16230",
        Comune: "Vedeseta",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24010",
        CodFisco: "L707",
        Abitanti: "217",
      },
      {
        Istat: "108047",
        Comune: "Veduggio con Colzano",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20837",
        CodFisco: "L709",
        Abitanti: "4417",
      },
      {
        Istat: "13236",
        Comune: "Veleso",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "L715",
        Abitanti: "278",
      },
      {
        Istat: "18172",
        Comune: "Velezzo Lomellina",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27020",
        CodFisco: "L716",
        Abitanti: "103",
      },
      {
        Istat: "18173",
        Comune: "Vellezzo Bellini",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "L720",
        Abitanti: "3044",
      },
      {
        Istat: "97085",
        Comune: "Vendrogno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23838",
        CodFisco: "L731",
        Abitanti: "317",
      },
      {
        Istat: "12136",
        Comune: "Venegono Inferiore",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "L733",
        Abitanti: "6370",
      },
      {
        Istat: "12137",
        Comune: "Venegono Superiore",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21040",
        CodFisco: "L734",
        Abitanti: "7185",
      },
      {
        Istat: "13238",
        Comune: "Veniano",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "L737",
        Abitanti: "2859",
      },
      {
        Istat: "108048",
        Comune: "Verano Brianza",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "362",
        CAP: "20843",
        CodFisco: "L744",
        Abitanti: "9342",
      },
      {
        Istat: "13239",
        Comune: "Vercana",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "344",
        CAP: "22013",
        CodFisco: "L748",
        Abitanti: "754",
      },
      {
        Istat: "14075",
        Comune: "Verceia",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23020",
        CodFisco: "L749",
        Abitanti: "1106",
      },
      {
        Istat: "97086",
        Comune: "Vercurago",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23808",
        CodFisco: "L751",
        Abitanti: "2896",
      },
      {
        Istat: "16232",
        Comune: "Verdellino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24040",
        CodFisco: "L752",
        Abitanti: "7790",
      },
      {
        Istat: "16233",
        Comune: "Verdello",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24049",
        CodFisco: "L753",
        Abitanti: "7829",
      },
      {
        Istat: "97087",
        Comune: "Verderio Inferiore",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23879",
        CodFisco: "L755",
        Abitanti: "2967",
      },
      {
        Istat: "97088",
        Comune: "Verderio Superiore",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23878",
        CodFisco: "L756",
        Abitanti: "2719",
      },
      {
        Istat: "12138",
        Comune: "Vergiate",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21029",
        CodFisco: "L765",
        Abitanti: "9014",
      },
      {
        Istat: "15235",
        Comune: "Vermezzo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "L768",
        Abitanti: "3897",
      },
      {
        Istat: "15236",
        Comune: "Vernate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "L773",
        Abitanti: "3189",
      },
      {
        Istat: "17195",
        Comune: "Verolanuova",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25028",
        CodFisco: "L777",
        Abitanti: "8183",
      },
      {
        Istat: "17196",
        Comune: "Verolavecchia",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25029",
        CodFisco: "L778",
        Abitanti: "3903",
      },
      {
        Istat: "18174",
        Comune: "Verretto",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27053",
        CodFisco: "L784",
        Abitanti: "377",
      },
      {
        Istat: "18175",
        Comune: "Verrua Po",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27040",
        CodFisco: "L788",
        Abitanti: "1288",
      },
      {
        Istat: "13242",
        Comune: "Vertemate con Minoprio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22070",
        CodFisco: "L792",
        Abitanti: "4025",
      },
      {
        Istat: "16234",
        Comune: "Vertova",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24029",
        CodFisco: "L795",
        Abitanti: "4871",
      },
      {
        Istat: "14076",
        Comune: "Vervio",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "L799",
        Abitanti: "216",
      },
      {
        Istat: "19113",
        Comune: "Vescovato",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26039",
        CodFisco: "L806",
        Abitanti: "4040",
      },
      {
        Istat: "17197",
        Comune: "Vestone",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25078",
        CodFisco: "L812",
        Abitanti: "4509",
      },
      {
        Istat: "97089",
        Comune: "Vestreno",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "341",
        CAP: "23822",
        CodFisco: "L813",
        Abitanti: "315",
      },
      {
        Istat: "17198",
        Comune: "Vezza d'Oglio",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25059",
        CodFisco: "L816",
        Abitanti: "1475",
      },
      {
        Istat: "20066",
        Comune: "Viadana",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "46019",
        CodFisco: "L826",
        Abitanti: "19785",
      },
      {
        Istat: "16235",
        Comune: "Viadanica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "L827",
        Abitanti: "1142",
      },
      {
        Istat: "18176",
        Comune: "Vidigulfo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27018",
        CodFisco: "L854",
        Abitanti: "6016",
      },
      {
        Istat: "97090",
        Comune: "Viganò",
        Provincia: "LC",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "23897",
        CodFisco: "L866",
        Abitanti: "2015",
      },
      {
        Istat: "16236",
        Comune: "Vigano San Martino",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "L865",
        Abitanti: "1260",
      },
      {
        Istat: "18177",
        Comune: "Vigevano",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "381",
        CAP: "27029",
        CodFisco: "L872",
        Abitanti: "63700",
      },
      {
        Istat: "12139",
        Comune: "Viggiù",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "332",
        CAP: "21059",
        CodFisco: "L876",
        Abitanti: "5247",
      },
      {
        Istat: "15237",
        Comune: "Vignate",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20060",
        CodFisco: "L883",
        Abitanti: "9064",
      },
      {
        Istat: "16237",
        Comune: "Vigolo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "L894",
        Abitanti: "603",
      },
      {
        Istat: "18178",
        Comune: "Villa Biscossi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27035",
        CodFisco: "L917",
        Abitanti: "76",
      },
      {
        Istat: "17199",
        Comune: "Villa Carcina",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25069",
        CodFisco: "L919",
        Abitanti: "10997",
      },
      {
        Istat: "15248",
        Comune: "Villa Cortese",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "20020",
        CodFisco: "L928",
        Abitanti: "6216",
      },
      {
        Istat: "16238",
        Comune: "Villa d'Adda",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24030",
        CodFisco: "L929",
        Abitanti: "4729",
      },
      {
        Istat: "16239",
        Comune: "Villa d'Almè",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24018",
        CodFisco: "A215",
        Abitanti: "6844",
      },
      {
        Istat: "14077",
        Comune: "Villa di Chiavenna",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "343",
        CAP: "23029",
        CodFisco: "L907",
        Abitanti: "1054",
      },
      {
        Istat: "16240",
        Comune: "Villa di Serio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24020",
        CodFisco: "L936",
        Abitanti: "6608",
      },
      {
        Istat: "14078",
        Comune: "Villa di Tirano",
        Provincia: "SO",
        Regione: "LOM",
        Prefisso: "342",
        CAP: "23030",
        CodFisco: "L908",
        Abitanti: "2984",
      },
      {
        Istat: "16241",
        Comune: "Villa d'Ogna",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "L938",
        Abitanti: "1982",
      },
      {
        Istat: "13245",
        Comune: "Villa Guardia",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22079",
        CodFisco: "L956",
        Abitanti: "7759",
      },
      {
        Istat: "20067",
        Comune: "Villa Poma",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "386",
        CAP: "46020",
        CodFisco: "F804",
        Abitanti: "2037",
      },
      {
        Istat: "17200",
        Comune: "Villachiara",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25030",
        CodFisco: "L923",
        Abitanti: "1456",
      },
      {
        Istat: "18179",
        Comune: "Villanova d'Ardenghi",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27030",
        CodFisco: "L983",
        Abitanti: "786",
      },
      {
        Istat: "98060",
        Comune: "Villanova del Sillaro",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "371",
        CAP: "26818",
        CodFisco: "L977",
        Abitanti: "1824",
      },
      {
        Istat: "18180",
        Comune: "Villanterio",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27019",
        CodFisco: "L994",
        Abitanti: "3226",
      },
      {
        Istat: "17201",
        Comune: "Villanuova sul Clisi",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25089",
        CodFisco: "L995",
        Abitanti: "5855",
      },
      {
        Istat: "108049",
        Comune: "Villasanta",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20852",
        CodFisco: "M017",
        Abitanti: "13590",
      },
      {
        Istat: "20068",
        Comune: "Villimpenta",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46039",
        CodFisco: "M044",
        Abitanti: "2261",
      },
      {
        Istat: "16242",
        Comune: "Villongo",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "M045",
        Abitanti: "7770",
      },
      {
        Istat: "16243",
        Comune: "Vilminore di Scalve",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "346",
        CAP: "24020",
        CodFisco: "M050",
        Abitanti: "1532",
      },
      {
        Istat: "108050",
        Comune: "Vimercate",
        Provincia: "MB",
        Regione: "LOM",
        Prefisso: "39",
        CAP: "20871",
        CodFisco: "M052",
        Abitanti: "25758",
      },
      {
        Istat: "15242",
        Comune: "Vimodrone",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20090",
        CodFisco: "M053",
        Abitanti: "16612",
      },
      {
        Istat: "17202",
        Comune: "Vione",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "364",
        CAP: "25050",
        CodFisco: "M065",
        Abitanti: "729",
      },
      {
        Istat: "20069",
        Comune: "Virgilio",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46030",
        CodFisco: "H123",
        Abitanti: "11293",
      },
      {
        Istat: "17203",
        Comune: "Visano",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25010",
        CodFisco: "M070",
        Abitanti: "1953",
      },
      {
        Istat: "18181",
        Comune: "Vistarino",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "M079",
        Abitanti: "1569",
      },
      {
        Istat: "15243",
        Comune: "Vittuone",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20010",
        CodFisco: "M091",
        Abitanti: "9082",
      },
      {
        Istat: "12140",
        Comune: "Vizzola Ticino",
        Provincia: "VA",
        Regione: "LOM",
        Prefisso: "331",
        CAP: "21010",
        CodFisco: "M101",
        Abitanti: "586",
      },
      {
        Istat: "15244",
        Comune: "Vizzolo Predabissi",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20070",
        CodFisco: "M102",
        Abitanti: "3979",
      },
      {
        Istat: "17204",
        Comune: "Vobarno",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "365",
        CAP: "25079",
        CodFisco: "M104",
        Abitanti: "8259",
      },
      {
        Istat: "18182",
        Comune: "Voghera",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27058",
        CodFisco: "M109",
        Abitanti: "39937",
      },
      {
        Istat: "19114",
        Comune: "Volongo",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "372",
        CAP: "26030",
        CodFisco: "M116",
        Abitanti: "571",
      },
      {
        Istat: "18183",
        Comune: "Volpara",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27047",
        CodFisco: "M119",
        Abitanti: "129",
      },
      {
        Istat: "20070",
        Comune: "Volta Mantovana",
        Provincia: "MN",
        Regione: "LOM",
        Prefisso: "376",
        CAP: "46049",
        CodFisco: "M125",
        Abitanti: "7377",
      },
      {
        Istat: "19115",
        Comune: "Voltido",
        Provincia: "CR",
        Regione: "LOM",
        Prefisso: "375",
        CAP: "26030",
        CodFisco: "M127",
        Abitanti: "413",
      },
      {
        Istat: "16244",
        Comune: "Zandobbio",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24060",
        CodFisco: "M144",
        Abitanti: "2746",
      },
      {
        Istat: "16245",
        Comune: "Zanica",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "35",
        CAP: "24050",
        CodFisco: "M147",
        Abitanti: "8190",
      },
      {
        Istat: "18184",
        Comune: "Zavattarello",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "383",
        CAP: "27059",
        CodFisco: "M150",
        Abitanti: "1052",
      },
      {
        Istat: "18185",
        Comune: "Zeccone",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27010",
        CodFisco: "M152",
        Abitanti: "1694",
      },
      {
        Istat: "13246",
        Comune: "Zelbio",
        Provincia: "CO",
        Regione: "LOM",
        Prefisso: "31",
        CAP: "22020",
        CodFisco: "M156",
        Abitanti: "218",
      },
      {
        Istat: "98061",
        Comune: "Zelo Buon Persico",
        Provincia: "LO",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "26839",
        CodFisco: "M158",
        Abitanti: "6860",
      },
      {
        Istat: "15246",
        Comune: "Zelo Surrigone",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "M160",
        Abitanti: "1377",
      },
      {
        Istat: "18186",
        Comune: "Zeme",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "384",
        CAP: "27030",
        CodFisco: "M161",
        Abitanti: "1134",
      },
      {
        Istat: "18187",
        Comune: "Zenevredo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "385",
        CAP: "27049",
        CodFisco: "M162",
        Abitanti: "483",
      },
      {
        Istat: "18188",
        Comune: "Zerbo",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27017",
        CodFisco: "M166",
        Abitanti: "444",
      },
      {
        Istat: "18189",
        Comune: "Zerbolò",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27020",
        CodFisco: "M167",
        Abitanti: "1642",
      },
      {
        Istat: "15247",
        Comune: "Zibido San Giacomo",
        Provincia: "MI",
        Regione: "LOM",
        Prefisso: "2",
        CAP: "20080",
        CodFisco: "M176",
        Abitanti: "6734",
      },
      {
        Istat: "18190",
        Comune: "Zinasco",
        Provincia: "PV",
        Regione: "LOM",
        Prefisso: "382",
        CAP: "27030",
        CodFisco: "M180",
        Abitanti: "3264",
      },
      {
        Istat: "16246",
        Comune: "Zogno",
        Provincia: "BG",
        Regione: "LOM",
        Prefisso: "345",
        CAP: "24019",
        CodFisco: "M184",
        Abitanti: "9097",
      },
      {
        Istat: "17205",
        Comune: "Zone",
        Provincia: "BS",
        Regione: "LOM",
        Prefisso: "30",
        CAP: "25050",
        CodFisco: "M188",
        Abitanti: "1110",
      },
      { Comune: "Barbara", Provincia: "AN", Regione: "MAR", CAP: "60010" },
      {
        Comune: "Castel Colonna",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60010",
      },
      {
        Comune: "Castelleone di Suasa",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60010",
      },
      {
        Comune: "Monterado",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60010",
      },
      { Comune: "Ostra", Provincia: "AN", Regione: "MAR", CAP: "60010" },
      {
        Comune: "Ostra Vetere",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60010",
      },
      { Comune: "Ripe", Provincia: "AN", Regione: "MAR", CAP: "60010" },
      { Comune: "Arcevia", Provincia: "AN", Regione: "MAR", CAP: "60011" },
      {
        Comune: "Trecastelli ",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60012",
      },
      {
        Comune: "Corinaldo",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60013",
      },
      {
        Comune: "Castelferretti",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60015",
      },
      {
        Comune: "Falconara Marittima",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60015",
      },
      {
        Comune: "Marina di Montemarciano",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60018",
      },
      {
        Comune: "Montemarciano",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60018",
      },
      {
        Comune: "Senigallia",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60019",
      },
      {
        Comune: "Agugliano",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60020",
      },
      {
        Comune: "Camerata Picena",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60020",
      },
      { Comune: "Offagna", Provincia: "AN", Regione: "MAR", CAP: "60020" },
      {
        Comune: "Polverigi",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60020",
      },
      { Comune: "Sirolo", Provincia: "AN", Regione: "MAR", CAP: "60020" },
      { Comune: "Camerano", Provincia: "AN", Regione: "MAR", CAP: "60021" },
      {
        Comune: "Castelfidardo",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60022",
      },
      {
        Comune: "Filottrano",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60024",
      },
      { Comune: "Loreto", Provincia: "AN", Regione: "MAR", CAP: "60025" },
      {
        Comune: "Loreto Stazione",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60025",
      },
      { Comune: "Numana", Provincia: "AN", Regione: "MAR", CAP: "60026" },
      { Comune: "Osimo", Provincia: "AN", Regione: "MAR", CAP: "60027" },
      {
        Comune: "Osimo Stazione",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60027",
      },
      {
        Comune: "Angeli di Rosara",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Belvedere Ostrense",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Castelbellino",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Maiolati Spontini",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      { Comune: "Mergo", Provincia: "AN", Regione: "MAR", CAP: "60030" },
      { Comune: "Monsano", Provincia: "AN", Regione: "MAR", CAP: "60030" },
      {
        Comune: "Monte Roberto",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Morro d'Alba",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Pianello Vallesina",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Poggio San Marcello",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      { Comune: "Rosara", Provincia: "AN", Regione: "MAR", CAP: "60030" },
      { Comune: "Rosora", Provincia: "AN", Regione: "MAR", CAP: "60030" },
      {
        Comune: "San Marcello",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Santa Maria Nuova",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Serra de' Conti",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60030",
      },
      {
        Comune: "Castelplanio",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60031",
      },
      {
        Comune: "Chiaravalle",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60033",
      },
      {
        Comune: "Cupramontana",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60034",
      },
      { Comune: "Jesi", Provincia: "AN", Regione: "MAR", CAP: "60035" },
      {
        Comune: "Montecarotto",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60036",
      },
      {
        Comune: "Monte San Vito",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60037",
      },
      {
        Comune: "San Paolo di Jesi",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60038",
      },
      { Comune: "Staffolo", Provincia: "AN", Regione: "MAR", CAP: "60039" },
      { Comune: "Genga", Provincia: "AN", Regione: "MAR", CAP: "60040" },
      {
        Comune: "Sassoferrato",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60041",
      },
      {
        Comune: "Cerreto d'Esi",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60043",
      },
      { Comune: "Fabriano", Provincia: "AN", Regione: "MAR", CAP: "60044" },
      {
        Comune: "Serra San Quirico",
        Provincia: "AN",
        Regione: "MAR",
        CAP: "60048",
      },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60100" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60121" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60122" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60123" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60124" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60125" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60126" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60127" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60128" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60129" },
      { Comune: "Varano", Provincia: "AN", Regione: "MAR", CAP: "60129" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60130" },
      { Comune: "ANCONA", Provincia: "AN", Regione: "MAR", CAP: "60131" },
      {
        Comune: "Collo Del Tronto",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63030",
      },
      {
        Comune: "Montegallo",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63040",
      },
      { Comune: "Palmiano", Provincia: "AP", Regione: "MAR", CAP: "63049" },
      {
        Comune: "Massignano",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63061",
      },
      {
        Comune: "Montefiore dell'Aso",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63062",
      },
      { Comune: "Carassai", Provincia: "AP", Regione: "MAR", CAP: "63063" },
      {
        Comune: "Cupra Marittima",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63064",
      },
      {
        Comune: "Ripatransone",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63065",
      },
      {
        Comune: "Grottammare",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63066",
      },
      {
        Comune: "Cossignano",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63067",
      },
      {
        Comune: "Montalto delle Marche",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63068",
      },
      {
        Comune: "Montedinove",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63069",
      },
      { Comune: "Rotella", Provincia: "AP", Regione: "MAR", CAP: "63071" },
      {
        Comune: "Castignano",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63072",
      },
      {
        Comune: "Ripa Berarda",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63072",
      },
      { Comune: "Offida", Provincia: "AP", Regione: "MAR", CAP: "63073" },
      {
        Comune: "Porto d'Ascoli",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63074",
      },
      {
        Comune: "San Benedetto del Tronto",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63074",
      },
      {
        Comune: "Acquaviva Picena",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63075",
      },
      {
        Comune: "Centobuchi",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63076",
      },
      {
        Comune: "Monteprandone",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63076",
      },
      {
        Comune: "MONTERRANDONE",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63076",
      },
      {
        Comune: "Monsampolo del Tronto",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63077",
      },
      {
        Comune: "Stella di Monsampolo",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63077",
      },
      { Comune: "Pagliare", Provincia: "AP", Regione: "MAR", CAP: "63078" },
      {
        Comune: "Spinetoli",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63078",
      },
      {
        Comune: "Colli del Tronto",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63079",
      },
      {
        Comune: "Castorano",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63081",
      },
      {
        Comune: "Castel di Lama",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63082",
      },
      {
        Comune: "Appignano del Tronto",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63083",
      },
      {
        Comune: "Folignano",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63084",
      },
      {
        Comune: "Maltignano",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63085",
      },
      { Comune: "Force", Provincia: "AP", Regione: "MAR", CAP: "63086" },
      {
        Comune: "Comunanza",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63087",
      },
      {
        Comune: "Montemonaco",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63088",
      },
      {
        Comune: "Venarotta",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63091",
      },
      {
        Comune: "Roccafluvione",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63093",
      },
      {
        Comune: "Acquasanta Terme",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63095",
      },
      {
        Comune: "Arquata del Tronto",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63096",
      },
      {
        Comune: "ASCOLI PICENO",
        Provincia: "AP",
        Regione: "MAR",
        CAP: "63100",
      },
      {
        Comune: "Massa Fermana",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63020",
      },
      {
        Comune: "Montappone",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63020",
      },
      {
        Comune: "Ponzano di Fermo",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63020",
      },
      {
        Comune: "LIDO TRE ARCHI",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63023",
      },
      {
        Comune: "Rapagnano",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63025",
      },
      { Comune: "Moresco", Provincia: "FM", Regione: "MAR", CAP: "63026" },
      {
        Comune: "Monte Vidon Combatte",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63027",
      },
      {
        Comune: "Montefalcone Appennino",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63028",
      },
      {
        Comune: "Monteleone di Fermo",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63029",
      },
      {
        Comune: "Montefortino",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63044",
      },
      {
        Comune: "Sant'Elpidio a Mare",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63811",
      },
      {
        Comune: "Montegranaro",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63812",
      },
      {
        Comune: "Monte Urano",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63813",
      },
      {
        Comune: "Torre San Patrizio",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63814",
      },
      {
        Comune: "Monte San Pietrangeli",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63815",
      },
      {
        Comune: "Francavilla d'Ete",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63816",
      },
      {
        Comune: "Porto Sant'Elpidio",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63821",
      },
      {
        Comune: "Porto San Giorgio",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63822",
      },
      { Comune: "Lapedona", Provincia: "FM", Regione: "MAR", CAP: "63823" },
      { Comune: "Altidona", Provincia: "FM", Regione: "MAR", CAP: "63824" },
      {
        Comune: "Monterubbiano",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63825",
      },
      { Comune: "Pedaso", Provincia: "FM", Regione: "MAR", CAP: "63827" },
      {
        Comune: "Campofilone",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63828",
      },
      {
        Comune: "Magliano di Tenna",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63832",
      },
      {
        Comune: "Montegiorgio",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63833",
      },
      {
        Comune: "Monte Vidon Corrado",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63836",
      },
      { Comune: "Falerone", Provincia: "FM", Regione: "MAR", CAP: "63837" },
      {
        Comune: "Belmonte Piceno",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63838",
      },
      {
        Comune: "Servigliano",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63839",
      },
      {
        Comune: "Monsampietro Morico",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63842",
      },
      {
        Comune: "Montottone",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63843",
      },
      {
        Comune: "Grottazzolina",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63844",
      },
      {
        Comune: "Monte Giberto",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63846",
      },
      {
        Comune: "Petritoli",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63848",
      },
      {
        Comune: "Ortezzano",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63851",
      },
      {
        Comune: "Monte Rinaldo",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63852",
      },
      {
        Comune: "Montelparo",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63853",
      },
      {
        Comune: "Santa Vittoria in Matenano",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63854",
      },
      { Comune: "Smerillo", Provincia: "FM", Regione: "MAR", CAP: "63856" },
      { Comune: "Amandola", Provincia: "FM", Regione: "MAR", CAP: "63857" },
      {
        Comune: "SEMPREMONTEFORTINO",
        Provincia: "FM",
        Regione: "MAR",
        CAP: "63858",
      },
      { Comune: "FERMO", Provincia: "FM", Regione: "MAR", CAP: "63900" },
      {
        Comune: "Appignano",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      {
        Comune: "Chiesanuova",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      { Comune: "Mogliano", Provincia: "MC", Regione: "MAR", CAP: "62010" },
      {
        Comune: "Montecassiano",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      {
        Comune: "Montecosaro",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      {
        Comune: "Montefano",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      {
        Comune: "Montelupone",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      {
        Comune: "Morrovalle",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      {
        Comune: "Passo di Treia",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      { Comune: "Pollenza", Provincia: "MC", Regione: "MAR", CAP: "62010" },
      { Comune: "Treia", Provincia: "MC", Regione: "MAR", CAP: "62010" },
      {
        Comune: "Urbisaglia",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62010",
      },
      { Comune: "Cingoli", Provincia: "MC", Regione: "MAR", CAP: "62011" },
      {
        Comune: "Civitanova Marche",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62012",
      },
      {
        Comune: "Corridonia",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62014",
      },
      { Comune: "Petriolo", Provincia: "MC", Regione: "MAR", CAP: "62014" },
      {
        Comune: "Monte San Giusto",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62015",
      },
      {
        Comune: "Porto Recanati",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62017",
      },
      {
        Comune: "Porto Potenza Picena",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62018",
      },
      {
        Comune: "Potenza Picena",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62018",
      },
      { Comune: "Recanati", Provincia: "MC", Regione: "MAR", CAP: "62019" },
      {
        Comune: "Belforte del Chienti",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Caldarola",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Camporotondo di Fiastrone",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Cessapalombo",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Colmurano",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      { Comune: "Gualdo", Provincia: "MC", Regione: "MAR", CAP: "62020" },
      {
        Comune: "Loro Piceno",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Monte San Martino",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Penna San Giovanni",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Ripe San Ginesio",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Sant'Angelo in Pontano",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      {
        Comune: "Serrapetrona",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62020",
      },
      { Comune: "Apiro", Provincia: "MC", Regione: "MAR", CAP: "62021" },
      {
        Comune: "Poggio San Vicino",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62021",
      },
      {
        Comune: "Castelraimondo",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62022",
      },
      { Comune: "Gagliole", Provincia: "MC", Regione: "MAR", CAP: "62022" },
      {
        Comune: "Esanatoglia",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62024",
      },
      { Comune: "Matelica", Provincia: "MC", Regione: "MAR", CAP: "62024" },
      {
        Comune: "Fiuminata",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62025",
      },
      { Comune: "Pioraco", Provincia: "MC", Regione: "MAR", CAP: "62025" },
      { Comune: "Sefro", Provincia: "MC", Regione: "MAR", CAP: "62025" },
      {
        Comune: "San Ginesio",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62026",
      },
      {
        Comune: "San Severino Marche",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62027",
      },
      { Comune: "Sarnano", Provincia: "MC", Regione: "MAR", CAP: "62028" },
      {
        Comune: "Tolentino",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62029",
      },
      {
        Comune: "Valfornace",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62031",
      },
      { Comune: "Camerino", Provincia: "MC", Regione: "MAR", CAP: "62032" },
      { Comune: "Muccia", Provincia: "MC", Regione: "MAR", CAP: "62034" },
      {
        Comune: "Acquacanina",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62035",
      },
      {
        Comune: "Bolognola",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62035",
      },
      { Comune: "Fiastra", Provincia: "MC", Regione: "MAR", CAP: "62035" },
      {
        Comune: "Fiordimonte",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62035",
      },
      {
        Comune: "Pievebovigliana",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62035",
      },
      {
        Comune: "Monte Cavallo",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62036",
      },
      {
        Comune: "Pieve Torina",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62036",
      },
      {
        Comune: "Serravalle di Chienti",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62038",
      },
      {
        Comune: "Castelsantangelo sul Nera",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62039",
      },
      { Comune: "Ussita", Provincia: "MC", Regione: "MAR", CAP: "62039" },
      { Comune: "Visso", Provincia: "MC", Regione: "MAR", CAP: "62039" },
      { Comune: "MACERATA", Provincia: "MC", Regione: "MAR", CAP: "62100" },
      {
        Comune: "Piediripa",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62100",
      },
      {
        Comune: "Villa Potenza",
        Provincia: "MC",
        Regione: "MAR",
        CAP: "62100",
      },
      { Comune: "Pistrino", Provincia: "PU", Regione: "MAR", CAP: "06018" },
      {
        Comune: "Monte Cerignone",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61010",
      },
      {
        Comune: "Monte Grimano Terme",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61010",
      },
      { Comune: "Tavullia", Provincia: "PU", Regione: "MAR", CAP: "61010" },
      {
        Comune: "GABBICE MARE",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61011",
      },
      {
        Comune: "Gabicce Mare",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61011",
      },
      { Comune: "Gradara", Provincia: "PU", Regione: "MAR", CAP: "61012" },
      {
        Comune: "Mercatino Conca",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61013",
      },
      {
        Comune: "Sassofeltrio",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61013",
      },
      {
        Comune: "Montecopiolo",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61014",
      },
      { Comune: "Auditore", Provincia: "PU", Regione: "MAR", CAP: "61020" },
      {
        Comune: "Montecalvo in Foglia",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61020",
      },
      { Comune: "Petriano", Provincia: "PU", Regione: "MAR", CAP: "61020" },
      {
        Comune: "Sant'Angelo in Lizzola",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61020",
      },
      { Comune: "Tavoleto", Provincia: "PU", Regione: "MAR", CAP: "61020" },
      { Comune: "Carpegna", Provincia: "PU", Regione: "MAR", CAP: "61021" },
      { Comune: "Frontino", Provincia: "PU", Regione: "MAR", CAP: "61021" },
      {
        Comune: "Colbordolo",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61022",
      },
      {
        Comune: "Vallefoglia",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61022",
      },
      {
        Comune: "Macerata Feltria",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61023",
      },
      {
        Comune: "Pietrarubbia",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61023",
      },
      {
        Comune: "Mombaroccio",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61024",
      },
      {
        Comune: "Monteciccardo",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61024",
      },
      {
        Comune: "Montelabbate",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61025",
      },
      {
        Comune: "Belforte all'Isauro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61026",
      },
      { Comune: "Lunano", Provincia: "PU", Regione: "MAR", CAP: "61026" },
      {
        Comune: "Piandimeleto",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61026",
      },
      {
        Comune: "Sassocorvaro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61028",
      },
      { Comune: "URBINO", Provincia: "PU", Regione: "MAR", CAP: "61029" },
      {
        Comune: "Calcinelli",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Cartoceto",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Centinarola ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "COLLI DEL METAURO",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Isola del Piano",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      { Comune: "Lucrezia", Provincia: "PU", Regione: "MAR", CAP: "61030" },
      {
        Comune: "LUCREZIA DI CARTOCETO",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Montefelcino",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Montemaggiore al Metauro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      { Comune: "Piagge", Provincia: "PU", Regione: "MAR", CAP: "61030" },
      {
        Comune: "Ponte Metauro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      { Comune: "Saltara", Provincia: "PU", Regione: "MAR", CAP: "61030" },
      {
        Comune: "San Giorgio di Pesaro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Serrungarina",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Tavernelle",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61030",
      },
      {
        Comune: "Bellocchi",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      { Comune: "Bevano ", Provincia: "PU", Regione: "MAR", CAP: "61032" },
      {
        Comune: "Camminate ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      {
        Comune: "Cannelle ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      {
        Comune: "Carignano ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      { Comune: "Carrara ", Provincia: "PU", Regione: "MAR", CAP: "61032" },
      {
        Comune: "Cuccurano",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      { Comune: "Fano", Provincia: "PU", Regione: "MAR", CAP: "61032" },
      { Comune: "Fenile", Provincia: "PU", Regione: "MAR", CAP: "61032" },
      {
        Comune: "Ferretto ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      { Comune: "Gimarra ", Provincia: "PU", Regione: "MAR", CAP: "61032" },
      {
        Comune: "Magliano ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      { Comune: "Prelato ", Provincia: "PU", Regione: "MAR", CAP: "61032" },
      {
        Comune: "Roncosambaccio ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      {
        Comune: "Rosciano ",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      {
        Comune: "Sant'andrea",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      {
        Comune: "Tre Ponti",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61032",
      },
      {
        Comune: "Fermignano",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61033",
      },
      {
        Comune: "Fossombrone",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61034",
      },
      {
        Comune: "Colli al Metauro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61036",
      },
      { Comune: "Marotta ", Provincia: "PU", Regione: "MAR", CAP: "61037" },
      { Comune: "Mondolfo", Provincia: "PU", Regione: "MAR", CAP: "61037" },
      {
        Comune: "Orciano di Pesaro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61038",
      },
      {
        Comune: "Terre Roveresche",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61038",
      },
      {
        Comune: "San Costanzo",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61039",
      },
      { Comune: "Barchi", Provincia: "PU", Regione: "MAR", CAP: "61040" },
      {
        Comune: "Borgo Pace",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      {
        Comune: "Fratte Rosa",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      { Comune: "Frontone", Provincia: "PU", Regione: "MAR", CAP: "61040" },
      {
        Comune: "Mercatello sul Metauro",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      { Comune: "Mondavio", Provincia: "PU", Regione: "MAR", CAP: "61040" },
      {
        Comune: "Monte Porzio",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      {
        Comune: "San Michele al Fiume",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      {
        Comune: "Sant'Andrea di Suasa",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      {
        Comune: "Sant'Ippolito",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      {
        Comune: "Serra Sant'Abbondio",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61040",
      },
      {
        Comune: "Acqualagna",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61041",
      },
      { Comune: "Apecchio", Provincia: "PU", Regione: "MAR", CAP: "61042" },
      { Comune: "Cagli", Provincia: "PU", Regione: "MAR", CAP: "61043" },
      { Comune: "Cantiano", Provincia: "PU", Regione: "MAR", CAP: "61044" },
      {
        Comune: "Pontericcioli",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61044",
      },
      { Comune: "Pergola", Provincia: "PU", Regione: "MAR", CAP: "61045" },
      { Comune: "Piobbico", Provincia: "PU", Regione: "MAR", CAP: "61046" },
      {
        Comune: "San Lorenzo in Campo",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61047",
      },
      {
        Comune: "Sant'Angelo in Vado",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61048",
      },
      { Comune: "Peglio", Provincia: "PU", Regione: "MAR", CAP: "61049" },
      {
        Comune: "PIEVE DI CAGNA",
        Provincia: "PU",
        Regione: "MAR",
        CAP: "61049",
      },
      { Comune: "Urbania", Provincia: "PU", Regione: "MAR", CAP: "61049" },
      { Comune: "PESARO", Provincia: "PU", Regione: "MAR", CAP: "61100" },
      { Comune: "PESARO", Provincia: "PU", Regione: "MAR", CAP: "61121" },
      { Comune: "PESARO", Provincia: "PU", Regione: "MAR", CAP: "61122" },
      {
        Istat: "70001",
        Comune: "Acquaviva Collecroce",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86030",
        CodFisco: "A050",
        Abitanti: "697",
      },
      {
        Istat: "94001",
        Comune: "Acquaviva d'Isernia",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "A051",
        Abitanti: "464",
      },
      {
        Istat: "94002",
        Comune: "Agnone",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86081",
        CodFisco: "A080",
        Abitanti: "5323",
      },
      {
        Istat: "94003",
        Comune: "Bagnoli del Trigno",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86091",
        CodFisco: "A567",
        Abitanti: "784",
      },
      {
        Istat: "70002",
        Comune: "Baranello",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86011",
        CodFisco: "A616",
        Abitanti: "2806",
      },
      {
        Istat: "94004",
        Comune: "Belmonte del Sannio",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "A761",
        Abitanti: "843",
      },
      {
        Istat: "70003",
        Comune: "Bojano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86021",
        CodFisco: "A930",
        Abitanti: "8175",
      },
      {
        Istat: "70004",
        Comune: "Bonefro",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86041",
        CodFisco: "A971",
        Abitanti: "1544",
      },
      {
        Istat: "70005",
        Comune: "Busso",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "B295",
        Abitanti: "1405",
      },
      {
        Istat: "70006",
        Comune: "Campobasso",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86100",
        CodFisco: "B519",
        Abitanti: "50916",
      },
      {
        Istat: "70007",
        Comune: "Campochiaro",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "B522",
        Abitanti: "647",
      },
      {
        Istat: "70008",
        Comune: "Campodipietra",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "B528",
        Abitanti: "2569",
      },
      {
        Istat: "70009",
        Comune: "Campolieto",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "B544",
        Abitanti: "950",
      },
      {
        Istat: "70010",
        Comune: "Campomarino",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86042",
        CodFisco: "B550",
        Abitanti: "7208",
      },
      {
        Istat: "94005",
        Comune: "Cantalupo nel Sannio",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86092",
        CodFisco: "B630",
        Abitanti: "756",
      },
      {
        Istat: "94006",
        Comune: "Capracotta",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86082",
        CodFisco: "B682",
        Abitanti: "959",
      },
      {
        Istat: "94007",
        Comune: "Carovilli",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86083",
        CodFisco: "B810",
        Abitanti: "1430",
      },
      {
        Istat: "94008",
        Comune: "Carpinone",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86093",
        CodFisco: "B830",
        Abitanti: "1225",
      },
      {
        Istat: "70011",
        Comune: "Casacalenda",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86043",
        CodFisco: "B858",
        Abitanti: "2236",
      },
      {
        Istat: "70012",
        Comune: "Casalciprano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "B871",
        Abitanti: "579",
      },
      {
        Istat: "94009",
        Comune: "Castel del Giudice",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "C082",
        Abitanti: "355",
      },
      {
        Istat: "94012",
        Comune: "Castel San Vincenzo",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86071",
        CodFisco: "C270",
        Abitanti: "561",
      },
      {
        Istat: "70013",
        Comune: "Castelbottaccio",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "C066",
        Abitanti: "363",
      },
      {
        Istat: "70014",
        Comune: "Castellino del Biferno",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "C175",
        Abitanti: "613",
      },
      {
        Istat: "70015",
        Comune: "Castelmauro",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86031",
        CodFisco: "C197",
        Abitanti: "1661",
      },
      {
        Istat: "94010",
        Comune: "Castelpetroso",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86090",
        CodFisco: "C246",
        Abitanti: "1649",
      },
      {
        Istat: "94011",
        Comune: "Castelpizzuto",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86090",
        CodFisco: "C247",
        Abitanti: "161",
      },
      {
        Istat: "94013",
        Comune: "Castelverrino",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "C200",
        Abitanti: "138",
      },
      {
        Istat: "70016",
        Comune: "Castropignano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "C346",
        Abitanti: "1044",
      },
      {
        Istat: "70017",
        Comune: "Cercemaggiore",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86012",
        CodFisco: "C486",
        Abitanti: "4043",
      },
      {
        Istat: "70018",
        Comune: "Cercepiccola",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "C488",
        Abitanti: "697",
      },
      {
        Istat: "94014",
        Comune: "Cerro al Volturno",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86072",
        CodFisco: "C534",
        Abitanti: "1363",
      },
      {
        Istat: "94015",
        Comune: "Chiauci",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86097",
        CodFisco: "C620",
        Abitanti: "273",
      },
      {
        Istat: "70019",
        Comune: "Civitacampomarano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "C764",
        Abitanti: "545",
      },
      {
        Istat: "94016",
        Comune: "Civitanova del Sannio",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86094",
        CodFisco: "C769",
        Abitanti: "952",
      },
      {
        Istat: "70020",
        Comune: "Colle d'Anchise",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "C854",
        Abitanti: "813",
      },
      {
        Istat: "70021",
        Comune: "Colletorto",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86044",
        CodFisco: "C875",
        Abitanti: "2145",
      },
      {
        Istat: "94017",
        Comune: "Colli a Volturno",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86073",
        CodFisco: "C878",
        Abitanti: "1409",
      },
      {
        Istat: "94018",
        Comune: "Conca Casale",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86070",
        CodFisco: "C941",
        Abitanti: "218",
      },
      {
        Istat: "70022",
        Comune: "Duronia",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "C772",
        Abitanti: "442",
      },
      {
        Istat: "70023",
        Comune: "Ferrazzano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "D550",
        Abitanti: "3345",
      },
      {
        Istat: "94019",
        Comune: "Filignano",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86074",
        CodFisco: "D595",
        Abitanti: "728",
      },
      {
        Istat: "94020",
        Comune: "Forlì del Sannio",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86084",
        CodFisco: "D703",
        Abitanti: "740",
      },
      {
        Istat: "94021",
        Comune: "Fornelli",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86070",
        CodFisco: "D715",
        Abitanti: "2005",
      },
      {
        Istat: "70024",
        Comune: "Fossalto",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "D737",
        Abitanti: "1526",
      },
      {
        Istat: "94022",
        Comune: "Frosolone",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86095",
        CodFisco: "D811",
        Abitanti: "3248",
      },
      {
        Istat: "70025",
        Comune: "Gambatesa",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86013",
        CodFisco: "D896",
        Abitanti: "1502",
      },
      {
        Istat: "70026",
        Comune: "Gildone",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "E030",
        Abitanti: "884",
      },
      {
        Istat: "70027",
        Comune: "Guardialfiera",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "E244",
        Abitanti: "1167",
      },
      {
        Istat: "70028",
        Comune: "Guardiaregia",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86014",
        CodFisco: "E248",
        Abitanti: "765",
      },
      {
        Istat: "70029",
        Comune: "Guglionesi",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86034",
        CodFisco: "E259",
        Abitanti: "5411",
      },
      {
        Istat: "94023",
        Comune: "Isernia",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86170",
        CodFisco: "E335",
        Abitanti: "22150",
      },
      {
        Istat: "70030",
        Comune: "Jelsi",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86015",
        CodFisco: "E381",
        Abitanti: "1842",
      },
      {
        Istat: "70031",
        Comune: "Larino",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86035",
        CodFisco: "E456",
        Abitanti: "7095",
      },
      {
        Istat: "70032",
        Comune: "Limosano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86022",
        CodFisco: "E599",
        Abitanti: "843",
      },
      {
        Istat: "94024",
        Comune: "Longano",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86090",
        CodFisco: "E669",
        Abitanti: "739",
      },
      {
        Istat: "70033",
        Comune: "Lucito",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "E722",
        Abitanti: "763",
      },
      {
        Istat: "70034",
        Comune: "Lupara",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "E748",
        Abitanti: "553",
      },
      {
        Istat: "94025",
        Comune: "Macchia d'Isernia",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86070",
        CodFisco: "E778",
        Abitanti: "982",
      },
      {
        Istat: "70035",
        Comune: "Macchia Valfortore",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "E780",
        Abitanti: "638",
      },
      {
        Istat: "94026",
        Comune: "Macchiagodena",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86096",
        CodFisco: "E779",
        Abitanti: "1877",
      },
      {
        Istat: "70036",
        Comune: "Mafalda",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86030",
        CodFisco: "E799",
        Abitanti: "1308",
      },
      {
        Istat: "70037",
        Comune: "Matrice",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "F055",
        Abitanti: "1114",
      },
      {
        Istat: "70038",
        Comune: "Mirabello Sannitico",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "F233",
        Abitanti: "2165",
      },
      {
        Istat: "94027",
        Comune: "Miranda",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "F239",
        Abitanti: "1070",
      },
      {
        Istat: "70039",
        Comune: "Molise",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "F294",
        Abitanti: "164",
      },
      {
        Istat: "70040",
        Comune: "Monacilioni",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "F322",
        Abitanti: "600",
      },
      {
        Istat: "70041",
        Comune: "Montagano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86023",
        CodFisco: "F391",
        Abitanti: "1155",
      },
      {
        Istat: "94028",
        Comune: "Montaquila",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86070",
        CodFisco: "F429",
        Abitanti: "2501",
      },
      {
        Istat: "70042",
        Comune: "Montecilfone",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86032",
        CodFisco: "F475",
        Abitanti: "1460",
      },
      {
        Istat: "70043",
        Comune: "Montefalcone nel Sannio",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86033",
        CodFisco: "F495",
        Abitanti: "1678",
      },
      {
        Istat: "70044",
        Comune: "Montelongo",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "F548",
        Abitanti: "398",
      },
      {
        Istat: "70045",
        Comune: "Montemitro",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "F569",
        Abitanti: "460",
      },
      {
        Istat: "70046",
        Comune: "Montenero di Bisaccia",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86036",
        CodFisco: "F576",
        Abitanti: "6781",
      },
      {
        Istat: "94029",
        Comune: "Montenero Val Cocchiara",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "F580",
        Abitanti: "575",
      },
      {
        Istat: "94030",
        Comune: "Monteroduni",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86075",
        CodFisco: "F601",
        Abitanti: "2319",
      },
      {
        Istat: "70047",
        Comune: "Montorio nei Frentani",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "F689",
        Abitanti: "474",
      },
      {
        Istat: "70048",
        Comune: "Morrone del Sannio",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "F748",
        Abitanti: "666",
      },
      {
        Istat: "70049",
        Comune: "Oratino",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "G086",
        Abitanti: "1529",
      },
      {
        Istat: "70050",
        Comune: "Palata",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86037",
        CodFisco: "G257",
        Abitanti: "1788",
      },
      {
        Istat: "94031",
        Comune: "Pesche",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86090",
        CodFisco: "G486",
        Abitanti: "1595",
      },
      {
        Istat: "94032",
        Comune: "Pescolanciano",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86097",
        CodFisco: "G495",
        Abitanti: "890",
      },
      {
        Istat: "94033",
        Comune: "Pescopennataro",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "G497",
        Abitanti: "310",
      },
      {
        Istat: "70051",
        Comune: "Petacciato",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86038",
        CodFisco: "G506",
        Abitanti: "3656",
      },
      {
        Istat: "70052",
        Comune: "Petrella Tifernina",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86024",
        CodFisco: "G512",
        Abitanti: "1245",
      },
      {
        Istat: "94034",
        Comune: "Pettoranello del Molise",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86090",
        CodFisco: "G523",
        Abitanti: "481",
      },
      {
        Istat: "94035",
        Comune: "Pietrabbondante",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86085",
        CodFisco: "G606",
        Abitanti: "822",
      },
      {
        Istat: "70053",
        Comune: "Pietracatella",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "G609",
        Abitanti: "1479",
      },
      {
        Istat: "70054",
        Comune: "Pietracupa",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "G610",
        Abitanti: "234",
      },
      {
        Istat: "94036",
        Comune: "Pizzone",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86071",
        CodFisco: "G727",
        Abitanti: "329",
      },
      {
        Istat: "94037",
        Comune: "Poggio Sannita",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86086",
        CodFisco: "B317",
        Abitanti: "795",
      },
      {
        Istat: "70055",
        Comune: "Portocannone",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86045",
        CodFisco: "G910",
        Abitanti: "2574",
      },
      {
        Istat: "94038",
        Comune: "Pozzilli",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86077",
        CodFisco: "G954",
        Abitanti: "2350",
      },
      {
        Istat: "70056",
        Comune: "Provvidenti",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "H083",
        Abitanti: "128",
      },
      {
        Istat: "70057",
        Comune: "Riccia",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86016",
        CodFisco: "H273",
        Abitanti: "5503",
      },
      {
        Istat: "94039",
        Comune: "Rionero Sannitico",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86087",
        CodFisco: "H308",
        Abitanti: "1174",
      },
      {
        Istat: "70058",
        Comune: "Ripabottoni",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "H311",
        Abitanti: "553",
      },
      {
        Istat: "70059",
        Comune: "Ripalimosani",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86025",
        CodFisco: "H313",
        Abitanti: "2980",
      },
      {
        Istat: "94040",
        Comune: "Roccamandolfi",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86092",
        CodFisco: "H420",
        Abitanti: "1003",
      },
      {
        Istat: "94041",
        Comune: "Roccasicura",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "H445",
        Abitanti: "587",
      },
      {
        Istat: "70060",
        Comune: "Roccavivara",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "H454",
        Abitanti: "875",
      },
      {
        Istat: "94042",
        Comune: "Rocchetta a Volturno",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86070",
        CodFisco: "H458",
        Abitanti: "1080",
      },
      {
        Istat: "70061",
        Comune: "Rotello",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "H589",
        Abitanti: "1257",
      },
      {
        Istat: "70062",
        Comune: "Salcito",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86026",
        CodFisco: "H693",
        Abitanti: "695",
      },
      {
        Istat: "70063",
        Comune: "San Biase",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "H782",
        Abitanti: "212",
      },
      {
        Istat: "70064",
        Comune: "San Felice del Molise",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86030",
        CodFisco: "H833",
        Abitanti: "708",
      },
      {
        Istat: "70065",
        Comune: "San Giacomo degli Schiavoni",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86030",
        CodFisco: "H867",
        Abitanti: "1428",
      },
      {
        Istat: "70066",
        Comune: "San Giovanni in Galdo",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "H920",
        Abitanti: "639",
      },
      {
        Istat: "70067",
        Comune: "San Giuliano del Sannio",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "H928",
        Abitanti: "1065",
      },
      {
        Istat: "70068",
        Comune: "San Giuliano di Puglia",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86040",
        CodFisco: "H929",
        Abitanti: "1094",
      },
      {
        Istat: "70069",
        Comune: "San Martino in Pensilis",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86046",
        CodFisco: "H990",
        Abitanti: "4877",
      },
      {
        Istat: "70070",
        Comune: "San Massimo",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86027",
        CodFisco: "I023",
        Abitanti: "818",
      },
      {
        Istat: "94043",
        Comune: "San Pietro Avellana",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86088",
        CodFisco: "I096",
        Abitanti: "539",
      },
      {
        Istat: "70071",
        Comune: "San Polo Matese",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "I122",
        Abitanti: "478",
      },
      {
        Istat: "70072",
        Comune: "Santa Croce di Magliano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86047",
        CodFisco: "I181",
        Abitanti: "4727",
      },
      {
        Istat: "94045",
        Comune: "Santa Maria del Molise",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86096",
        CodFisco: "I238",
        Abitanti: "660",
      },
      {
        Istat: "94044",
        Comune: "Sant'Agapito",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86070",
        CodFisco: "I189",
        Abitanti: "1397",
      },
      {
        Istat: "94046",
        Comune: "Sant'Angelo del Pesco",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86080",
        CodFisco: "I282",
        Abitanti: "370",
      },
      {
        Istat: "70073",
        Comune: "Sant'Angelo Limosano",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "I289",
        Abitanti: "349",
      },
      {
        Istat: "94047",
        Comune: "Sant'Elena Sannita",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86095",
        CodFisco: "B466",
        Abitanti: "260",
      },
      {
        Istat: "70074",
        Comune: "Sant'Elia a Pianisi",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86048",
        CodFisco: "I320",
        Abitanti: "1970",
      },
      {
        Istat: "94048",
        Comune: "Scapoli",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86070",
        CodFisco: "I507",
        Abitanti: "776",
      },
      {
        Istat: "70075",
        Comune: "Sepino",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86017",
        CodFisco: "I618",
        Abitanti: "2041",
      },
      {
        Istat: "94049",
        Comune: "Sessano del Molise",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86097",
        CodFisco: "I679",
        Abitanti: "763",
      },
      {
        Istat: "94050",
        Comune: "Sesto Campano",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86078",
        CodFisco: "I682",
        Abitanti: "2419",
      },
      {
        Istat: "70076",
        Comune: "Spinete",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86020",
        CodFisco: "I910",
        Abitanti: "1384",
      },
      {
        Istat: "70077",
        Comune: "Tavenna",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86030",
        CodFisco: "L069",
        Abitanti: "827",
      },
      {
        Istat: "70078",
        Comune: "Termoli",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "875",
        CAP: "86039",
        CodFisco: "L113",
        Abitanti: "32873",
      },
      {
        Istat: "70079",
        Comune: "Torella del Sannio",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86028",
        CodFisco: "L215",
        Abitanti: "801",
      },
      {
        Istat: "70080",
        Comune: "Toro",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86018",
        CodFisco: "L230",
        Abitanti: "1513",
      },
      {
        Istat: "70081",
        Comune: "Trivento",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86029",
        CodFisco: "L435",
        Abitanti: "4907",
      },
      {
        Istat: "70082",
        Comune: "Tufara",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86010",
        CodFisco: "L458",
        Abitanti: "1000",
      },
      {
        Istat: "70083",
        Comune: "Ururi",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86049",
        CodFisco: "L505",
        Abitanti: "2839",
      },
      {
        Istat: "94051",
        Comune: "Vastogirardi",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86089",
        CodFisco: "L696",
        Abitanti: "762",
      },
      {
        Istat: "94052",
        Comune: "Venafro",
        Provincia: "IS",
        Regione: "MOL",
        Prefisso: "865",
        CAP: "86079",
        CodFisco: "L725",
        Abitanti: "11535",
      },
      {
        Istat: "70084",
        Comune: "Vinchiaturo",
        Provincia: "CB",
        Regione: "MOL",
        Prefisso: "874",
        CAP: "86019",
        CodFisco: "M057",
        Abitanti: "3185",
      },
      {
        Istat: "4001",
        Comune: "Acceglio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12021",
        CodFisco: "A016",
        Abitanti: "177",
      },
      {
        Istat: "6001",
        Comune: "Acqui Terme",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15011",
        CodFisco: "A052",
        Abitanti: "20552",
      },
      {
        Istat: "5001",
        Comune: "Agliano Terme",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14041",
        CodFisco: "A072",
        Abitanti: "1685",
      },
      {
        Istat: "1001",
        Comune: "Agliè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10011",
        CodFisco: "A074",
        Abitanti: "2591",
      },
      {
        Istat: "3001",
        Comune: "Agrate Conturbia",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "A088",
        Abitanti: "1560",
      },
      {
        Istat: "96001",
        Comune: "Ailoche",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13861",
        CodFisco: "A107",
        Abitanti: "333",
      },
      {
        Istat: "1002",
        Comune: "Airasca",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "A109",
        Abitanti: "3808",
      },
      {
        Istat: "4002",
        Comune: "Aisone",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "A113",
        Abitanti: "256",
      },
      {
        Istat: "1003",
        Comune: "Ala di Stura",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "A117",
        Abitanti: "465",
      },
      {
        Istat: "2002",
        Comune: "Alagna Valsesia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13021",
        CodFisco: "A119",
        Abitanti: "434",
      },
      {
        Istat: "4003",
        Comune: "Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12051",
        CodFisco: "A124",
        Abitanti: "31341",
      },
      {
        Istat: "2003",
        Comune: "Albano Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "A130",
        Abitanti: "342",
      },
      {
        Istat: "4004",
        Comune: "Albaretto della Torre",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "A139",
        Abitanti: "260",
      },
      {
        Istat: "6002",
        Comune: "Albera Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "A146",
        Abitanti: "323",
      },
      {
        Istat: "1004",
        Comune: "Albiano d'Ivrea",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "A157",
        Abitanti: "1778",
      },
      {
        Istat: "5002",
        Comune: "Albugnano",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "14022",
        CodFisco: "A173",
        Abitanti: "553",
      },
      {
        Istat: "6003",
        Comune: "Alessandria",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15121",
        CodFisco: "A182",
        Abitanti: "94974",
      },
      {
        Istat: "6003",
        Comune: "Alessandria",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15122",
        CodFisco: "A182",
        Abitanti: "94974",
      },
      {
        Istat: "6004",
        Comune: "Alfiano Natta",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "15021",
        CodFisco: "A189",
        Abitanti: "772",
      },
      {
        Istat: "6005",
        Comune: "Alice Bel Colle",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "A197",
        Abitanti: "781",
      },
      {
        Istat: "2004",
        Comune: "Alice Castello",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "A198",
        Abitanti: "2716",
      },
      {
        Istat: "1005",
        Comune: "Alice Superiore",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "A199",
        Abitanti: "713",
      },
      {
        Istat: "6006",
        Comune: "Alluvioni Cambiò",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "A211",
        Abitanti: "986",
      },
      {
        Istat: "1006",
        Comune: "Almese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "A218",
        Abitanti: "6378",
      },
      {
        Istat: "1007",
        Comune: "Alpette",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "A221",
        Abitanti: "271",
      },
      {
        Istat: "1008",
        Comune: "Alpignano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10091",
        CodFisco: "A222",
        Abitanti: "17097",
      },
      {
        Istat: "6007",
        Comune: "Altavilla Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15041",
        CodFisco: "A227",
        Abitanti: "506",
      },
      {
        Istat: "4005",
        Comune: "Alto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "A238",
        Abitanti: "127",
      },
      {
        Istat: "6008",
        Comune: "Alzano Scrivia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "A245",
        Abitanti: "380",
      },
      {
        Istat: "3002",
        Comune: "Ameno",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "A264",
        Abitanti: "893",
      },
      {
        Istat: "1009",
        Comune: "Andezeno",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "A275",
        Abitanti: "2010",
      },
      {
        Istat: "96002",
        Comune: "Andorno Micca",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13811",
        CodFisco: "A280",
        Abitanti: "3481",
      },
      {
        Istat: "1010",
        Comune: "Andrate",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "A282",
        Abitanti: "521",
      },
      {
        Istat: "1011",
        Comune: "Angrogna",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "A295",
        Abitanti: "882",
      },
      {
        Istat: "5003",
        Comune: "Antignano",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "A312",
        Abitanti: "1002",
      },
      {
        Istat: "103001",
        Comune: "Antrona Schieranco",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28841",
        CodFisco: "A317",
        Abitanti: "482",
      },
      {
        Istat: "103002",
        Comune: "Anzola d'Ossola",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28877",
        CodFisco: "A325",
        Abitanti: "458",
      },
      {
        Istat: "5004",
        Comune: "Aramengo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "A352",
        Abitanti: "642",
      },
      {
        Istat: "2006",
        Comune: "Arborio",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13031",
        CodFisco: "A358",
        Abitanti: "939",
      },
      {
        Istat: "4006",
        Comune: "Argentera",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "A394",
        Abitanti: "78",
      },
      {
        Istat: "4007",
        Comune: "Arguello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "A396",
        Abitanti: "200",
      },
      {
        Istat: "1012",
        Comune: "Arignano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "A405",
        Abitanti: "1057",
      },
      {
        Istat: "103003",
        Comune: "Arizzano",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28811",
        CodFisco: "A409",
        Abitanti: "2080",
      },
      {
        Istat: "3006",
        Comune: "Armeno",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28011",
        CodFisco: "A414",
        Abitanti: "2261",
      },
      {
        Istat: "103004",
        Comune: "Arola",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28899",
        CodFisco: "A427",
        Abitanti: "269",
      },
      {
        Istat: "3008",
        Comune: "Arona",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28041",
        CodFisco: "A429",
        Abitanti: "14547",
      },
      {
        Istat: "6009",
        Comune: "Arquata Scrivia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15061",
        CodFisco: "A436",
        Abitanti: "6260",
      },
      {
        Istat: "2007",
        Comune: "Asigliano Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13032",
        CodFisco: "A466",
        Abitanti: "1390",
      },
      {
        Istat: "5005",
        Comune: "Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14100",
        CodFisco: "A479",
        Abitanti: "76534",
      },
      {
        Istat: "103005",
        Comune: "Aurano",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28812",
        CodFisco: "A497",
        Abitanti: "112",
      },
      {
        Istat: "1013",
        Comune: "Avigliana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10051",
        CodFisco: "A518",
        Abitanti: "12367",
      },
      {
        Istat: "6010",
        Comune: "Avolasca",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "A523",
        Abitanti: "298",
      },
      {
        Istat: "1014",
        Comune: "Azeglio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "A525",
        Abitanti: "1375",
      },
      {
        Istat: "5006",
        Comune: "Azzano d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "A527",
        Abitanti: "418",
      },
      {
        Istat: "103006",
        Comune: "Baceno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28861",
        CodFisco: "A534",
        Abitanti: "928",
      },
      {
        Istat: "4008",
        Comune: "Bagnasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12071",
        CodFisco: "A555",
        Abitanti: "1054",
      },
      {
        Istat: "4009",
        Comune: "Bagnolo Piemonte",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12031",
        CodFisco: "A571",
        Abitanti: "6066",
      },
      {
        Istat: "1015",
        Comune: "Bairo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10010",
        CodFisco: "A584",
        Abitanti: "819",
      },
      {
        Istat: "1016",
        Comune: "Balangero",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "A587",
        Abitanti: "3178",
      },
      {
        Istat: "5007",
        Comune: "Baldichieri d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14011",
        CodFisco: "A588",
        Abitanti: "1099",
      },
      {
        Istat: "1017",
        Comune: "Baldissero Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "A590",
        Abitanti: "551",
      },
      {
        Istat: "4010",
        Comune: "Baldissero d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "A589",
        Abitanti: "1053",
      },
      {
        Istat: "1018",
        Comune: "Baldissero Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "A591",
        Abitanti: "3825",
      },
      {
        Istat: "1019",
        Comune: "Balme",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "A599",
        Abitanti: "97",
      },
      {
        Istat: "2008",
        Comune: "Balmuccia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "A600",
        Abitanti: "92",
      },
      {
        Istat: "2009",
        Comune: "Balocco",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "A601",
        Abitanti: "250",
      },
      {
        Istat: "6011",
        Comune: "Balzola",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15031",
        CodFisco: "A605",
        Abitanti: "1424",
      },
      {
        Istat: "1020",
        Comune: "Banchette",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "A607",
        Abitanti: "3355",
      },
      {
        Istat: "103007",
        Comune: "Bannio Anzino",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28871",
        CodFisco: "A610",
        Abitanti: "524",
      },
      {
        Istat: "1021",
        Comune: "Barbania",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "A625",
        Abitanti: "1632",
      },
      {
        Istat: "4011",
        Comune: "Barbaresco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "A629",
        Abitanti: "674",
      },
      {
        Istat: "1022",
        Comune: "Bardonecchia",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10052",
        CodFisco: "A651",
        Abitanti: "3273",
      },
      {
        Istat: "3012",
        Comune: "Barengo",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28010",
        CodFisco: "A653",
        Abitanti: "891",
      },
      {
        Istat: "4012",
        Comune: "Barge",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12032",
        CodFisco: "A660",
        Abitanti: "7891",
      },
      {
        Istat: "4013",
        Comune: "Barolo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "A671",
        Abitanti: "728",
      },
      {
        Istat: "1023",
        Comune: "Barone Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10010",
        CodFisco: "A673",
        Abitanti: "597",
      },
      {
        Istat: "6012",
        Comune: "Basaluzzo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "A689",
        Abitanti: "2113",
      },
      {
        Istat: "6013",
        Comune: "Bassignana",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15042",
        CodFisco: "A708",
        Abitanti: "1772",
      },
      {
        Istat: "4014",
        Comune: "Bastia Mondovì",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "A709",
        Abitanti: "658",
      },
      {
        Istat: "4015",
        Comune: "Battifollo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "A716",
        Abitanti: "237",
      },
      {
        Istat: "103008",
        Comune: "Baveno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28831",
        CodFisco: "A725",
        Abitanti: "4966",
      },
      {
        Istat: "103009",
        Comune: "Bee",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28813",
        CodFisco: "A733",
        Abitanti: "722",
      },
      {
        Istat: "1024",
        Comune: "Beinasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10092",
        CodFisco: "A734",
        Abitanti: "18185",
      },
      {
        Istat: "4016",
        Comune: "Beinette",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12081",
        CodFisco: "A735",
        Abitanti: "3130",
      },
      {
        Istat: "6014",
        Comune: "Belforte Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15070",
        CodFisco: "A738",
        Abitanti: "501",
      },
      {
        Istat: "103010",
        Comune: "Belgirate",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28832",
        CodFisco: "A742",
        Abitanti: "554",
      },
      {
        Istat: "4017",
        Comune: "Bellino",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "A750",
        Abitanti: "144",
      },
      {
        Istat: "3016",
        Comune: "Bellinzago Novarese",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28043",
        CodFisco: "A752",
        Abitanti: "9258",
      },
      {
        Istat: "4018",
        Comune: "Belvedere Langhe",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "A774",
        Abitanti: "388",
      },
      {
        Istat: "5008",
        Comune: "Belveglio",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "A770",
        Abitanti: "327",
      },
      {
        Istat: "4019",
        Comune: "Bene Vagienna",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12041",
        CodFisco: "A779",
        Abitanti: "3675",
      },
      {
        Istat: "4020",
        Comune: "Benevello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "A782",
        Abitanti: "461",
      },
      {
        Istat: "96003",
        Comune: "Benna",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13871",
        CodFisco: "A784",
        Abitanti: "1183",
      },
      {
        Istat: "6015",
        Comune: "Bergamasco",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15022",
        CodFisco: "A793",
        Abitanti: "780",
      },
      {
        Istat: "4021",
        Comune: "Bergolo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12074",
        CodFisco: "A798",
        Abitanti: "67",
      },
      {
        Istat: "4022",
        Comune: "Bernezzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "A805",
        Abitanti: "3785",
      },
      {
        Istat: "5009",
        Comune: "Berzano di San Pietro",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "14020",
        CodFisco: "A812",
        Abitanti: "454",
      },
      {
        Istat: "6016",
        Comune: "Berzano di Tortona",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "A813",
        Abitanti: "170",
      },
      {
        Istat: "103011",
        Comune: "Beura-Cardezza",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28851",
        CodFisco: "A834",
        Abitanti: "1425",
      },
      {
        Istat: "3018",
        Comune: "Biandrate",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28061",
        CodFisco: "A844",
        Abitanti: "1190",
      },
      {
        Istat: "2011",
        Comune: "Bianzè",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13041",
        CodFisco: "A847",
        Abitanti: "2060",
      },
      {
        Istat: "1025",
        Comune: "Bibiana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "A853",
        Abitanti: "3399",
      },
      {
        Istat: "96004",
        Comune: "Biella",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13900",
        CodFisco: "A859",
        Abitanti: "45589",
      },
      {
        Istat: "96005",
        Comune: "Bioglio",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13841",
        CodFisco: "A876",
        Abitanti: "1013",
      },
      {
        Istat: "6017",
        Comune: "Bistagno",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15012",
        CodFisco: "A889",
        Abitanti: "1944",
      },
      {
        Istat: "1026",
        Comune: "Bobbio Pellice",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "A910",
        Abitanti: "566",
      },
      {
        Istat: "3019",
        Comune: "Boca",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "A911",
        Abitanti: "1225",
      },
      {
        Istat: "2014",
        Comune: "Boccioleto",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13022",
        CodFisco: "A914",
        Abitanti: "221",
      },
      {
        Istat: "103012",
        Comune: "Bognanco",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28842",
        CodFisco: "A925",
        Abitanti: "245",
      },
      {
        Istat: "3021",
        Comune: "Bogogno",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "A929",
        Abitanti: "1316",
      },
      {
        Istat: "1027",
        Comune: "Bollengo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10012",
        CodFisco: "A941",
        Abitanti: "2088",
      },
      {
        Istat: "3022",
        Comune: "Bolzano Novarese",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "A953",
        Abitanti: "1180",
      },
      {
        Istat: "4023",
        Comune: "Bonvicino",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "A979",
        Abitanti: "115",
      },
      {
        Istat: "1028",
        Comune: "Borgaro Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10071",
        CodFisco: "A990",
        Abitanti: "13502",
      },
      {
        Istat: "6018",
        Comune: "Borghetto di Borbera",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "A998",
        Abitanti: "2009",
      },
      {
        Istat: "1029",
        Comune: "Borgiallo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "B003",
        Abitanti: "556",
      },
      {
        Istat: "2015",
        Comune: "Borgo d'Ale",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "B009",
        Abitanti: "2644",
      },
      {
        Istat: "4025",
        Comune: "Borgo San Dalmazzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12011",
        CodFisco: "B033",
        Abitanti: "12521",
      },
      {
        Istat: "6020",
        Comune: "Borgo San Martino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15032",
        CodFisco: "B037",
        Abitanti: "1465",
      },
      {
        Istat: "3025",
        Comune: "Borgo Ticino",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28040",
        CodFisco: "B043",
        Abitanti: "4872",
      },
      {
        Istat: "2017",
        Comune: "Borgo Vercelli",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13012",
        CodFisco: "B046",
        Abitanti: "2372",
      },
      {
        Istat: "1030",
        Comune: "Borgofranco d'Ivrea",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10013",
        CodFisco: "B015",
        Abitanti: "3780",
      },
      {
        Istat: "3023",
        Comune: "Borgolavezzaro",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28071",
        CodFisco: "B016",
        Abitanti: "2085",
      },
      {
        Istat: "4024",
        Comune: "Borgomale",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "B018",
        Abitanti: "393",
      },
      {
        Istat: "3024",
        Comune: "Borgomanero",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28021",
        CodFisco: "B019",
        Abitanti: "21518",
      },
      {
        Istat: "1031",
        Comune: "Borgomasino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10031",
        CodFisco: "B021",
        Abitanti: "845",
      },
      {
        Istat: "1032",
        Comune: "Borgone Susa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "B024",
        Abitanti: "2372",
      },
      {
        Istat: "6019",
        Comune: "Borgoratto Alessandrino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15013",
        CodFisco: "B029",
        Abitanti: "618",
      },
      {
        Istat: "2016",
        Comune: "Borgosesia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13011",
        CodFisco: "B041",
        Abitanti: "13336",
      },
      {
        Istat: "96006",
        Comune: "Borriana",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13872",
        CodFisco: "B058",
        Abitanti: "894",
      },
      {
        Istat: "6021",
        Comune: "Bosco Marengo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15062",
        CodFisco: "B071",
        Abitanti: "2551",
      },
      {
        Istat: "1033",
        Comune: "Bosconero",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10080",
        CodFisco: "B075",
        Abitanti: "3101",
      },
      {
        Istat: "4026",
        Comune: "Bosia",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "B079",
        Abitanti: "186",
      },
      {
        Istat: "6022",
        Comune: "Bosio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "B080",
        Abitanti: "1239",
      },
      {
        Istat: "4027",
        Comune: "Bossolasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "B084",
        Abitanti: "695",
      },
      {
        Istat: "4028",
        Comune: "Boves",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12012",
        CodFisco: "B101",
        Abitanti: "9867",
      },
      {
        Istat: "6023",
        Comune: "Bozzole",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15040",
        CodFisco: "B109",
        Abitanti: "315",
      },
      {
        Istat: "4029",
        Comune: "Bra",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12042",
        CodFisco: "B111",
        Abitanti: "29871",
      },
      {
        Istat: "1034",
        Comune: "Brandizzo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10032",
        CodFisco: "B121",
        Abitanti: "8297",
      },
      {
        Istat: "2019",
        Comune: "Breia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "B136",
        Abitanti: "188",
      },
      {
        Istat: "4030",
        Comune: "Briaglia",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "B167",
        Abitanti: "319",
      },
      {
        Istat: "1035",
        Comune: "Bricherasio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "B171",
        Abitanti: "4454",
      },
      {
        Istat: "4031",
        Comune: "Briga Alta",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "18025",
        CodFisco: "B175",
        Abitanti: "48",
      },
      {
        Istat: "3026",
        Comune: "Briga Novarese",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "B176",
        Abitanti: "3038",
      },
      {
        Istat: "6024",
        Comune: "Brignano-Frascata",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "B179",
        Abitanti: "456",
      },
      {
        Istat: "3027",
        Comune: "Briona",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28072",
        CodFisco: "B183",
        Abitanti: "1244",
      },
      {
        Istat: "4032",
        Comune: "Brondello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "B200",
        Abitanti: "319",
      },
      {
        Istat: "4033",
        Comune: "Brossasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "B204",
        Abitanti: "1099",
      },
      {
        Istat: "1036",
        Comune: "Brosso",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "B205",
        Abitanti: "472",
      },
      {
        Istat: "103013",
        Comune: "Brovello-Carpugnino",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28833",
        CodFisco: "B207",
        Abitanti: "689",
      },
      {
        Istat: "1037",
        Comune: "Brozolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "B209",
        Abitanti: "481",
      },
      {
        Istat: "1038",
        Comune: "Bruino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "B216",
        Abitanti: "8520",
      },
      {
        Istat: "5010",
        Comune: "Bruno",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14046",
        CodFisco: "B221",
        Abitanti: "360",
      },
      {
        Istat: "1039",
        Comune: "Brusasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "B225",
        Abitanti: "1760",
      },
      {
        Istat: "96007",
        Comune: "Brusnengo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13862",
        CodFisco: "B229",
        Abitanti: "2206",
      },
      {
        Istat: "1040",
        Comune: "Bruzolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "B232",
        Abitanti: "1540",
      },
      {
        Istat: "5011",
        Comune: "Bubbio",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14051",
        CodFisco: "B236",
        Abitanti: "919",
      },
      {
        Istat: "1041",
        Comune: "Buriasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "B278",
        Abitanti: "1411",
      },
      {
        Istat: "1042",
        Comune: "Burolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "B279",
        Abitanti: "1267",
      },
      {
        Istat: "2021",
        Comune: "Buronzo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "B280",
        Abitanti: "922",
      },
      {
        Istat: "1043",
        Comune: "Busano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "B284",
        Abitanti: "1571",
      },
      {
        Istat: "4034",
        Comune: "Busca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12022",
        CodFisco: "B285",
        Abitanti: "10084",
      },
      {
        Istat: "1044",
        Comune: "Bussoleno",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10053",
        CodFisco: "B297",
        Abitanti: "6521",
      },
      {
        Istat: "1045",
        Comune: "Buttigliera Alta",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "B305",
        Abitanti: "6458",
      },
      {
        Istat: "5012",
        Comune: "Buttigliera d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "14021",
        CodFisco: "B306",
        Abitanti: "2512",
      },
      {
        Istat: "6025",
        Comune: "Cabella Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "B311",
        Abitanti: "576",
      },
      {
        Istat: "1046",
        Comune: "Cafasse",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "B350",
        Abitanti: "3585",
      },
      {
        Istat: "5013",
        Comune: "Calamandrana",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14042",
        CodFisco: "B376",
        Abitanti: "1807",
      },
      {
        Istat: "103014",
        Comune: "Calasca-Castiglione",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28873",
        CodFisco: "B380",
        Abitanti: "699",
      },
      {
        Istat: "96008",
        Comune: "Callabiana",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13821",
        CodFisco: "B417",
        Abitanti: "154",
      },
      {
        Istat: "5014",
        Comune: "Calliano",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14031",
        CodFisco: "B418",
        Abitanti: "1395",
      },
      {
        Istat: "5015",
        Comune: "Calosso",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14052",
        CodFisco: "B425",
        Abitanti: "1351",
      },
      {
        Istat: "3030",
        Comune: "Caltignaga",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28010",
        CodFisco: "B431",
        Abitanti: "2617",
      },
      {
        Istat: "1047",
        Comune: "Caluso",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10014",
        CodFisco: "B435",
        Abitanti: "7679",
      },
      {
        Istat: "6026",
        Comune: "Camagna Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "B453",
        Abitanti: "533",
      },
      {
        Istat: "96009",
        Comune: "Camandona",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13821",
        CodFisco: "B457",
        Abitanti: "366",
      },
      {
        Istat: "1048",
        Comune: "Cambiano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "B462",
        Abitanti: "6304",
      },
      {
        Istat: "103015",
        Comune: "Cambiasca",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28814",
        CodFisco: "B463",
        Abitanti: "1636",
      },
      {
        Istat: "96010",
        Comune: "Camburzano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13891",
        CodFisco: "B465",
        Abitanti: "1237",
      },
      {
        Istat: "4035",
        Comune: "Camerana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12072",
        CodFisco: "B467",
        Abitanti: "671",
      },
      {
        Istat: "5016",
        Comune: "Camerano Casasco",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "B469",
        Abitanti: "502",
      },
      {
        Istat: "3032",
        Comune: "Cameri",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28062",
        CodFisco: "B473",
        Abitanti: "10878",
      },
      {
        Istat: "6027",
        Comune: "Camino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "B482",
        Abitanti: "810",
      },
      {
        Istat: "4036",
        Comune: "Camo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "12050",
        CodFisco: "B489",
        Abitanti: "203",
      },
      {
        Istat: "2025",
        Comune: "Campertogno",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13023",
        CodFisco: "B505",
        Abitanti: "246",
      },
      {
        Istat: "96011",
        Comune: "Campiglia Cervo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13812",
        CodFisco: "B508",
        Abitanti: "165",
      },
      {
        Istat: "1049",
        Comune: "Campiglione-Fenile",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "B512",
        Abitanti: "1385",
      },
      {
        Istat: "4037",
        Comune: "Canale",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12043",
        CodFisco: "B573",
        Abitanti: "5791",
      },
      {
        Istat: "96012",
        Comune: "Candelo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13878",
        CodFisco: "B586",
        Abitanti: "8058",
      },
      {
        Istat: "1050",
        Comune: "Candia Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10010",
        CodFisco: "B588",
        Abitanti: "1317",
      },
      {
        Istat: "1051",
        Comune: "Candiolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "B592",
        Abitanti: "5591",
      },
      {
        Istat: "5017",
        Comune: "Canelli",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14053",
        CodFisco: "B594",
        Abitanti: "10722",
      },
      {
        Istat: "1052",
        Comune: "Canischio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "B605",
        Abitanti: "301",
      },
      {
        Istat: "103016",
        Comune: "Cannero Riviera",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28821",
        CodFisco: "B610",
        Abitanti: "1017",
      },
      {
        Istat: "103017",
        Comune: "Cannobio",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28822",
        CodFisco: "B615",
        Abitanti: "5181",
      },
      {
        Istat: "4038",
        Comune: "Canosio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "B621",
        Abitanti: "87",
      },
      {
        Istat: "1053",
        Comune: "Cantalupa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "B628",
        Abitanti: "2588",
      },
      {
        Istat: "6028",
        Comune: "Cantalupo Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "B629",
        Abitanti: "559",
      },
      {
        Istat: "5018",
        Comune: "Cantarana",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "B633",
        Abitanti: "1015",
      },
      {
        Istat: "1054",
        Comune: "Cantoira",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "B637",
        Abitanti: "554",
      },
      {
        Istat: "4039",
        Comune: "Caprauna",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "B692",
        Abitanti: "121",
      },
      {
        Istat: "103018",
        Comune: "Caprezzo",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28815",
        CodFisco: "B694",
        Abitanti: "171",
      },
      {
        Istat: "6029",
        Comune: "Capriata d'Orba",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "B701",
        Abitanti: "1950",
      },
      {
        Istat: "1055",
        Comune: "Caprie",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "B705",
        Abitanti: "2136",
      },
      {
        Istat: "5019",
        Comune: "Capriglio",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14014",
        CodFisco: "B707",
        Abitanti: "298",
      },
      {
        Istat: "96013",
        Comune: "Caprile",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13864",
        CodFisco: "B708",
        Abitanti: "223",
      },
      {
        Istat: "4040",
        Comune: "Caraglio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12023",
        CodFisco: "B719",
        Abitanti: "6774",
      },
      {
        Istat: "4041",
        Comune: "Caramagna Piemonte",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "B720",
        Abitanti: "3023",
      },
      {
        Istat: "1056",
        Comune: "Caravino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "B733",
        Abitanti: "1008",
      },
      {
        Istat: "6030",
        Comune: "Carbonara Scrivia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "B736",
        Abitanti: "1074",
      },
      {
        Istat: "2029",
        Comune: "Carcoforo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13026",
        CodFisco: "B752",
        Abitanti: "79",
      },
      {
        Istat: "4042",
        Comune: "Cardè",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "B755",
        Abitanti: "1124",
      },
      {
        Istat: "1057",
        Comune: "Carema",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "B762",
        Abitanti: "771",
      },
      {
        Istat: "6031",
        Comune: "Carentino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15026",
        CodFisco: "B765",
        Abitanti: "341",
      },
      {
        Istat: "2030",
        Comune: "Caresana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13010",
        CodFisco: "B767",
        Abitanti: "1051",
      },
      {
        Istat: "2031",
        Comune: "Caresanablot",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "B768",
        Abitanti: "1145",
      },
      {
        Istat: "6032",
        Comune: "Carezzano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15051",
        CodFisco: "B769",
        Abitanti: "448",
      },
      {
        Istat: "1058",
        Comune: "Carignano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10041",
        CodFisco: "B777",
        Abitanti: "9234",
      },
      {
        Istat: "2032",
        Comune: "Carisio",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "B782",
        Abitanti: "919",
      },
      {
        Istat: "1059",
        Comune: "Carmagnola",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10022",
        CodFisco: "B791",
        Abitanti: "28653",
      },
      {
        Istat: "6033",
        Comune: "Carpeneto",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15071",
        CodFisco: "B818",
        Abitanti: "966",
      },
      {
        Istat: "3036",
        Comune: "Carpignano Sesia",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28064",
        CodFisco: "B823",
        Abitanti: "2568",
      },
      {
        Istat: "6034",
        Comune: "Carrega Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "B836",
        Abitanti: "85",
      },
      {
        Istat: "6035",
        Comune: "Carrosio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "B840",
        Abitanti: "482",
      },
      {
        Istat: "4043",
        Comune: "Carrù",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12061",
        CodFisco: "B841",
        Abitanti: "4376",
      },
      {
        Istat: "4044",
        Comune: "Cartignano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "B845",
        Abitanti: "180",
      },
      {
        Istat: "6036",
        Comune: "Cartosio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15015",
        CodFisco: "B847",
        Abitanti: "817",
      },
      {
        Istat: "6037",
        Comune: "Casal Cermelli",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15072",
        CodFisco: "B870",
        Abitanti: "1263",
      },
      {
        Istat: "3037",
        Comune: "Casalbeltrame",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "B864",
        Abitanti: "1033",
      },
      {
        Istat: "1060",
        Comune: "Casalborgone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "B867",
        Abitanti: "1851",
      },
      {
        Istat: "103019",
        Comune: "Casale Corte Cerro",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28881",
        CodFisco: "B876",
        Abitanti: "3473",
      },
      {
        Istat: "6039",
        Comune: "Casale Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15033",
        CodFisco: "B885",
        Abitanti: "36069",
      },
      {
        Istat: "6038",
        Comune: "Casaleggio Boiro",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15070",
        CodFisco: "B882",
        Abitanti: "420",
      },
      {
        Istat: "3039",
        Comune: "Casaleggio Novara",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "B883",
        Abitanti: "920",
      },
      {
        Istat: "4045",
        Comune: "Casalgrasso",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "12030",
        CodFisco: "B894",
        Abitanti: "1449",
      },
      {
        Istat: "3040",
        Comune: "Casalino",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "B897",
        Abitanti: "1589",
      },
      {
        Istat: "6040",
        Comune: "Casalnoceto",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15052",
        CodFisco: "B902",
        Abitanti: "1007",
      },
      {
        Istat: "3041",
        Comune: "Casalvolone",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "28060",
        CodFisco: "B920",
        Abitanti: "891",
      },
      {
        Istat: "2033",
        Comune: "Casanova Elvo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "B928",
        Abitanti: "275",
      },
      {
        Istat: "96014",
        Comune: "Casapinta",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13866",
        CodFisco: "B933",
        Abitanti: "464",
      },
      {
        Istat: "6041",
        Comune: "Casasco",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "B941",
        Abitanti: "125",
      },
      {
        Istat: "1061",
        Comune: "Cascinette d'Ivrea",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "B953",
        Abitanti: "1506",
      },
      {
        Istat: "1062",
        Comune: "Caselette",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "B955",
        Abitanti: "2874",
      },
      {
        Istat: "1063",
        Comune: "Caselle Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10072",
        CodFisco: "B960",
        Abitanti: "18577",
      },
      {
        Istat: "5020",
        Comune: "Casorzo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14032",
        CodFisco: "B991",
        Abitanti: "680",
      },
      {
        Istat: "6042",
        Comune: "Cassano Spinola",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15063",
        CodFisco: "C005",
        Abitanti: "1852",
      },
      {
        Istat: "5021",
        Comune: "Cassinasco",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14050",
        CodFisco: "C022",
        Abitanti: "629",
      },
      {
        Istat: "6043",
        Comune: "Cassine",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15016",
        CodFisco: "C027",
        Abitanti: "3085",
      },
      {
        Istat: "6044",
        Comune: "Cassinelle",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15070",
        CodFisco: "C030",
        Abitanti: "924",
      },
      {
        Istat: "1064",
        Comune: "Castagneto Po",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "C045",
        Abitanti: "1820",
      },
      {
        Istat: "4046",
        Comune: "Castagnito",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "C046",
        Abitanti: "2157",
      },
      {
        Istat: "5022",
        Comune: "Castagnole delle Lanze",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14054",
        CodFisco: "C049",
        Abitanti: "3833",
      },
      {
        Istat: "5023",
        Comune: "Castagnole Monferrato",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "C047",
        Abitanti: "1270",
      },
      {
        Istat: "1065",
        Comune: "Castagnole Piemonte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "C048",
        Abitanti: "2186",
      },
      {
        Istat: "5024",
        Comune: "Castel Boglione",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "C064",
        Abitanti: "632",
      },
      {
        Istat: "5032",
        Comune: "Castel Rocchero",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14044",
        CodFisco: "C253",
        Abitanti: "414",
      },
      {
        Istat: "4047",
        Comune: "Casteldelfino",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "C081",
        Abitanti: "180",
      },
      {
        Istat: "5025",
        Comune: "Castell'Alfero",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14033",
        CodFisco: "C127",
        Abitanti: "2801",
      },
      {
        Istat: "1066",
        Comune: "Castellamonte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10081",
        CodFisco: "C133",
        Abitanti: "10008",
      },
      {
        Istat: "6045",
        Comune: "Castellania",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15051",
        CodFisco: "C137",
        Abitanti: "86",
      },
      {
        Istat: "4048",
        Comune: "Castellar",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "C140",
        Abitanti: "272",
      },
      {
        Istat: "6046",
        Comune: "Castellar Guidobono",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "C142",
        Abitanti: "422",
      },
      {
        Istat: "6047",
        Comune: "Castellazzo Bormida",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15073",
        CodFisco: "C148",
        Abitanti: "4696",
      },
      {
        Istat: "3042",
        Comune: "Castellazzo Novarese",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "C149",
        Abitanti: "339",
      },
      {
        Istat: "5026",
        Comune: "Castellero",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14013",
        CodFisco: "C154",
        Abitanti: "307",
      },
      {
        Istat: "96015",
        Comune: "Castelletto Cervo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13851",
        CodFisco: "C155",
        Abitanti: "905",
      },
      {
        Istat: "6048",
        Comune: "Castelletto d'Erro",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "C156",
        Abitanti: "149",
      },
      {
        Istat: "6049",
        Comune: "Castelletto d'Orba",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "C158",
        Abitanti: "2092",
      },
      {
        Istat: "6050",
        Comune: "Castelletto Merli",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "15020",
        CodFisco: "C160",
        Abitanti: "493",
      },
      {
        Istat: "5027",
        Comune: "Castelletto Molina",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "C161",
        Abitanti: "180",
      },
      {
        Istat: "6051",
        Comune: "Castelletto Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "C162",
        Abitanti: "1562",
      },
      {
        Istat: "3043",
        Comune: "Castelletto Sopra Ticino",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "331",
        CAP: "28053",
        CodFisco: "C166",
        Abitanti: "10259",
      },
      {
        Istat: "4049",
        Comune: "Castelletto Stura",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12040",
        CodFisco: "C165",
        Abitanti: "1341",
      },
      {
        Istat: "4050",
        Comune: "Castelletto Uzzone",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12070",
        CodFisco: "C167",
        Abitanti: "370",
      },
      {
        Istat: "4051",
        Comune: "Castellinaldo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "C173",
        Abitanti: "906",
      },
      {
        Istat: "4052",
        Comune: "Castellino Tanaro",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "C176",
        Abitanti: "339",
      },
      {
        Istat: "5028",
        Comune: "Castello di Annone",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14034",
        CodFisco: "A300",
        Abitanti: "1938",
      },
      {
        Istat: "4053",
        Comune: "Castelmagno",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "C205",
        Abitanti: "85",
      },
      {
        Istat: "5029",
        Comune: "Castelnuovo Belbo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14043",
        CodFisco: "C226",
        Abitanti: "913",
      },
      {
        Istat: "6052",
        Comune: "Castelnuovo Bormida",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15017",
        CodFisco: "C229",
        Abitanti: "690",
      },
      {
        Istat: "5030",
        Comune: "Castelnuovo Calcea",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "C230",
        Abitanti: "783",
      },
      {
        Istat: "4054",
        Comune: "Castelnuovo di Ceva",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "C214",
        Abitanti: "138",
      },
      {
        Istat: "5031",
        Comune: "Castelnuovo Don Bosco",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "14022",
        CodFisco: "C232",
        Abitanti: "3238",
      },
      {
        Istat: "1067",
        Comune: "Castelnuovo Nigra",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "C241",
        Abitanti: "415",
      },
      {
        Istat: "6053",
        Comune: "Castelnuovo Scrivia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15053",
        CodFisco: "C243",
        Abitanti: "5473",
      },
      {
        Istat: "6054",
        Comune: "Castelspina",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15070",
        CodFisco: "C274",
        Abitanti: "425",
      },
      {
        Istat: "4055",
        Comune: "Castiglione Falletto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "C314",
        Abitanti: "719",
      },
      {
        Istat: "4056",
        Comune: "Castiglione Tinella",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "12053",
        CodFisco: "C317",
        Abitanti: "869",
      },
      {
        Istat: "1068",
        Comune: "Castiglione Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "C307",
        Abitanti: "6336",
      },
      {
        Istat: "4057",
        Comune: "Castino",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "C323",
        Abitanti: "517",
      },
      {
        Istat: "96016",
        Comune: "Cavaglià",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13881",
        CodFisco: "C363",
        Abitanti: "3623",
      },
      {
        Istat: "3044",
        Comune: "Cavaglietto",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "C364",
        Abitanti: "415",
      },
      {
        Istat: "3045",
        Comune: "Cavaglio d'Agogna",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "C365",
        Abitanti: "1282",
      },
      {
        Istat: "103020",
        Comune: "Cavaglio-Spoccia",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28825",
        CodFisco: "C367",
        Abitanti: "271",
      },
      {
        Istat: "1069",
        Comune: "Cavagnolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "C369",
        Abitanti: "2383",
      },
      {
        Istat: "4058",
        Comune: "Cavallerleone",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "C375",
        Abitanti: "646",
      },
      {
        Istat: "4059",
        Comune: "Cavallermaggiore",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "C376",
        Abitanti: "5501",
      },
      {
        Istat: "3047",
        Comune: "Cavallirio",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "28010",
        CodFisco: "C378",
        Abitanti: "1271",
      },
      {
        Istat: "6055",
        Comune: "Cavatore",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "C387",
        Abitanti: "315",
      },
      {
        Istat: "1070",
        Comune: "Cavour",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10061",
        CodFisco: "C404",
        Abitanti: "5642",
      },
      {
        Istat: "6056",
        Comune: "Cella Monte",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15034",
        CodFisco: "C432",
        Abitanti: "527",
      },
      {
        Istat: "5033",
        Comune: "Cellarengo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "C438",
        Abitanti: "721",
      },
      {
        Istat: "4060",
        Comune: "Celle di Macra",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "C441",
        Abitanti: "102",
      },
      {
        Istat: "5034",
        Comune: "Celle Enomondo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "C440",
        Abitanti: "481",
      },
      {
        Istat: "2038",
        Comune: "Cellio",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13024",
        CodFisco: "C450",
        Abitanti: "863",
      },
      {
        Istat: "4061",
        Comune: "Centallo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12044",
        CodFisco: "C466",
        Abitanti: "6733",
      },
      {
        Istat: "103021",
        Comune: "Ceppo Morelli",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28875",
        CodFisco: "C478",
        Abitanti: "347",
      },
      {
        Istat: "3049",
        Comune: "Cerano",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28065",
        CodFisco: "C483",
        Abitanti: "6980",
      },
      {
        Istat: "1071",
        Comune: "Cercenasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "C487",
        Abitanti: "1864",
      },
      {
        Istat: "1072",
        Comune: "Ceres",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "C497",
        Abitanti: "1080",
      },
      {
        Istat: "6057",
        Comune: "Cereseto",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "C503",
        Abitanti: "468",
      },
      {
        Istat: "4062",
        Comune: "Ceresole Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "C504",
        Abitanti: "2149",
      },
      {
        Istat: "1073",
        Comune: "Ceresole Reale",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "C505",
        Abitanti: "164",
      },
      {
        Istat: "96017",
        Comune: "Cerreto Castello",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13852",
        CodFisco: "C526",
        Abitanti: "640",
      },
      {
        Istat: "5035",
        Comune: "Cerreto d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "C528",
        Abitanti: "216",
      },
      {
        Istat: "6058",
        Comune: "Cerreto Grue",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "C507",
        Abitanti: "322",
      },
      {
        Istat: "4063",
        Comune: "Cerretto Langhe",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "C530",
        Abitanti: "466",
      },
      {
        Istat: "6059",
        Comune: "Cerrina Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "C531",
        Abitanti: "1536",
      },
      {
        Istat: "96018",
        Comune: "Cerrione",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13882",
        CodFisco: "C532",
        Abitanti: "2926",
      },
      {
        Istat: "5036",
        Comune: "Cerro Tanaro",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "C533",
        Abitanti: "665",
      },
      {
        Istat: "4064",
        Comune: "Cervasca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "C547",
        Abitanti: "4783",
      },
      {
        Istat: "2041",
        Comune: "Cervatto",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13025",
        CodFisco: "C548",
        Abitanti: "49",
      },
      {
        Istat: "4065",
        Comune: "Cervere",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "C550",
        Abitanti: "2179",
      },
      {
        Istat: "1074",
        Comune: "Cesana Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10054",
        CodFisco: "C564",
        Abitanti: "1040",
      },
      {
        Istat: "103022",
        Comune: "Cesara",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28891",
        CodFisco: "C567",
        Abitanti: "595",
      },
      {
        Istat: "5037",
        Comune: "Cessole",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14050",
        CodFisco: "C583",
        Abitanti: "430",
      },
      {
        Istat: "4066",
        Comune: "Ceva",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12073",
        CodFisco: "C589",
        Abitanti: "5862",
      },
      {
        Istat: "4067",
        Comune: "Cherasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12062",
        CodFisco: "C599",
        Abitanti: "8623",
      },
      {
        Istat: "1075",
        Comune: "Chialamberto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "C604",
        Abitanti: "360",
      },
      {
        Istat: "1076",
        Comune: "Chianocco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "C610",
        Abitanti: "1695",
      },
      {
        Istat: "1077",
        Comune: "Chiaverano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "C624",
        Abitanti: "2150",
      },
      {
        Istat: "1078",
        Comune: "Chieri",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10023",
        CodFisco: "C627",
        Abitanti: "36168",
      },
      {
        Istat: "1079",
        Comune: "Chiesanuova",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "C629",
        Abitanti: "216",
      },
      {
        Istat: "1080",
        Comune: "Chiomonte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "C639",
        Abitanti: "942",
      },
      {
        Istat: "4068",
        Comune: "Chiusa di Pesio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12013",
        CodFisco: "C653",
        Abitanti: "3783",
      },
      {
        Istat: "1081",
        Comune: "Chiusa di San Michele",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "C655",
        Abitanti: "1687",
      },
      {
        Istat: "5038",
        Comune: "Chiusano d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14025",
        CodFisco: "C658",
        Abitanti: "232",
      },
      {
        Istat: "1082",
        Comune: "Chivasso",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10034",
        CodFisco: "C665",
        Abitanti: "26368",
      },
      {
        Istat: "1083",
        Comune: "Ciconio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "C679",
        Abitanti: "365",
      },
      {
        Istat: "2042",
        Comune: "Cigliano",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13043",
        CodFisco: "C680",
        Abitanti: "4586",
      },
      {
        Istat: "4069",
        Comune: "Cigliè",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "C681",
        Abitanti: "199",
      },
      {
        Istat: "5039",
        Comune: "Cinaglio",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "C701",
        Abitanti: "452",
      },
      {
        Istat: "1084",
        Comune: "Cintano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "C711",
        Abitanti: "264",
      },
      {
        Istat: "1085",
        Comune: "Cinzano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "C715",
        Abitanti: "389",
      },
      {
        Istat: "1086",
        Comune: "Ciriè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10073",
        CodFisco: "C722",
        Abitanti: "18973",
      },
      {
        Istat: "4070",
        Comune: "Cissone",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "C738",
        Abitanti: "82",
      },
      {
        Istat: "5040",
        Comune: "Cisterna d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "C739",
        Abitanti: "1317",
      },
      {
        Istat: "2043",
        Comune: "Civiasco",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13010",
        CodFisco: "C757",
        Abitanti: "262",
      },
      {
        Istat: "4071",
        Comune: "Clavesana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "C792",
        Abitanti: "913",
      },
      {
        Istat: "1087",
        Comune: "Claviere",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "C793",
        Abitanti: "212",
      },
      {
        Istat: "1088",
        Comune: "Coassolo Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "C801",
        Abitanti: "1571",
      },
      {
        Istat: "1089",
        Comune: "Coazze",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "C803",
        Abitanti: "3339",
      },
      {
        Istat: "5041",
        Comune: "Coazzolo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14054",
        CodFisco: "C804",
        Abitanti: "323",
      },
      {
        Istat: "5042",
        Comune: "Cocconato",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14023",
        CodFisco: "C807",
        Abitanti: "1609",
      },
      {
        Istat: "96019",
        Comune: "Coggiola",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13863",
        CodFisco: "C819",
        Abitanti: "2025",
      },
      {
        Istat: "3051",
        Comune: "Colazza",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "C829",
        Abitanti: "486",
      },
      {
        Istat: "1090",
        Comune: "Collegno",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10093",
        CodFisco: "C860",
        Abitanti: "50137",
      },
      {
        Istat: "1091",
        Comune: "Colleretto Castelnuovo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "C867",
        Abitanti: "358",
      },
      {
        Istat: "1092",
        Comune: "Colleretto Giacosa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "C868",
        Abitanti: "610",
      },
      {
        Istat: "2045",
        Comune: "Collobiano",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "C884",
        Abitanti: "108",
      },
      {
        Istat: "3052",
        Comune: "Comignago",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28060",
        CodFisco: "C926",
        Abitanti: "1228",
      },
      {
        Istat: "1093",
        Comune: "Condove",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10055",
        CodFisco: "C955",
        Abitanti: "4704",
      },
      {
        Istat: "6060",
        Comune: "Coniolo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "C962",
        Abitanti: "467",
      },
      {
        Istat: "6061",
        Comune: "Conzano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "C977",
        Abitanti: "1009",
      },
      {
        Istat: "1094",
        Comune: "Corio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "D008",
        Abitanti: "3410",
      },
      {
        Istat: "4072",
        Comune: "Corneliano d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12040",
        CodFisco: "D022",
        Abitanti: "2091",
      },
      {
        Istat: "5044",
        Comune: "Corsione",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "D046",
        Abitanti: "209",
      },
      {
        Istat: "5045",
        Comune: "Cortandone",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14013",
        CodFisco: "D050",
        Abitanti: "316",
      },
      {
        Istat: "5046",
        Comune: "Cortanze",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "D051",
        Abitanti: "282",
      },
      {
        Istat: "5047",
        Comune: "Cortazzone",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "D052",
        Abitanti: "682",
      },
      {
        Istat: "4073",
        Comune: "Cortemilia",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12074",
        CodFisco: "D062",
        Abitanti: "2454",
      },
      {
        Istat: "5048",
        Comune: "Cortiglione",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "D072",
        Abitanti: "613",
      },
      {
        Istat: "4074",
        Comune: "Cossano Belbo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "12054",
        CodFisco: "D093",
        Abitanti: "1042",
      },
      {
        Istat: "1095",
        Comune: "Cossano Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "D092",
        Abitanti: "528",
      },
      {
        Istat: "96020",
        Comune: "Cossato",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13836",
        CodFisco: "D094",
        Abitanti: "15010",
      },
      {
        Istat: "103023",
        Comune: "Cossogno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28801",
        CodFisco: "D099",
        Abitanti: "595",
      },
      {
        Istat: "5049",
        Comune: "Cossombrato",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "D101",
        Abitanti: "521",
      },
      {
        Istat: "6062",
        Comune: "Costa Vescovato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "D102",
        Abitanti: "369",
      },
      {
        Istat: "2047",
        Comune: "Costanzana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13033",
        CodFisco: "D113",
        Abitanti: "831",
      },
      {
        Istat: "5050",
        Comune: "Costigliole d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14055",
        CodFisco: "D119",
        Abitanti: "6111",
      },
      {
        Istat: "4075",
        Comune: "Costigliole Saluzzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12024",
        CodFisco: "D120",
        Abitanti: "3349",
      },
      {
        Istat: "2048",
        Comune: "Cravagliana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "D132",
        Abitanti: "272",
      },
      {
        Istat: "4076",
        Comune: "Cravanzana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "D133",
        Abitanti: "399",
      },
      {
        Istat: "103024",
        Comune: "Craveggia",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28852",
        CodFisco: "D134",
        Abitanti: "754",
      },
      {
        Istat: "6063",
        Comune: "Cremolino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15010",
        CodFisco: "D149",
        Abitanti: "1099",
      },
      {
        Istat: "2049",
        Comune: "Crescentino",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13044",
        CodFisco: "D154",
        Abitanti: "8086",
      },
      {
        Istat: "3055",
        Comune: "Cressa",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28012",
        CodFisco: "D162",
        Abitanti: "1543",
      },
      {
        Istat: "96021",
        Comune: "Crevacuore",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13864",
        CodFisco: "D165",
        Abitanti: "1638",
      },
      {
        Istat: "103025",
        Comune: "Crevoladossola",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28865",
        CodFisco: "D168",
        Abitanti: "4765",
      },
      {
        Istat: "4077",
        Comune: "Crissolo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "D172",
        Abitanti: "174",
      },
      {
        Istat: "103026",
        Comune: "Crodo",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28862",
        CodFisco: "D177",
        Abitanti: "1482",
      },
      {
        Istat: "96022",
        Comune: "Crosa",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13853",
        CodFisco: "D182",
        Abitanti: "352",
      },
      {
        Istat: "2052",
        Comune: "Crova",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "D187",
        Abitanti: "423",
      },
      {
        Istat: "6064",
        Comune: "Cuccaro Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "D194",
        Abitanti: "346",
      },
      {
        Istat: "1096",
        Comune: "Cuceglio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10090",
        CodFisco: "D197",
        Abitanti: "1000",
      },
      {
        Istat: "1097",
        Comune: "Cumiana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "D202",
        Abitanti: "7882",
      },
      {
        Istat: "4078",
        Comune: "Cuneo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12100",
        CodFisco: "D205",
        Abitanti: "55714",
      },
      {
        Istat: "5051",
        Comune: "Cunico",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14026",
        CodFisco: "D207",
        Abitanti: "545",
      },
      {
        Istat: "1098",
        Comune: "Cuorgnè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10082",
        CodFisco: "D208",
        Abitanti: "10141",
      },
      {
        Istat: "3058",
        Comune: "Cureggio",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28060",
        CodFisco: "D216",
        Abitanti: "2603",
      },
      {
        Istat: "96023",
        Comune: "Curino",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13865",
        CodFisco: "D219",
        Abitanti: "460",
      },
      {
        Istat: "103027",
        Comune: "Cursolo-Orasso",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28827",
        CodFisco: "D225",
        Abitanti: "107",
      },
      {
        Istat: "4079",
        Comune: "Demonte",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12014",
        CodFisco: "D271",
        Abitanti: "2057",
      },
      {
        Istat: "6065",
        Comune: "Denice",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "D272",
        Abitanti: "199",
      },
      {
        Istat: "6066",
        Comune: "Dernice",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15056",
        CodFisco: "D277",
        Abitanti: "237",
      },
      {
        Istat: "2054",
        Comune: "Desana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13034",
        CodFisco: "D281",
        Abitanti: "1083",
      },
      {
        Istat: "4080",
        Comune: "Diano d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12055",
        CodFisco: "D291",
        Abitanti: "3460",
      },
      {
        Istat: "3060",
        Comune: "Divignano",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28010",
        CodFisco: "D309",
        Abitanti: "1472",
      },
      {
        Istat: "4081",
        Comune: "Dogliani",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12063",
        CodFisco: "D314",
        Abitanti: "4868",
      },
      {
        Istat: "103028",
        Comune: "Domodossola",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28845",
        CodFisco: "D332",
        Abitanti: "18475",
      },
      {
        Istat: "96024",
        Comune: "Donato",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13893",
        CodFisco: "D339",
        Abitanti: "719",
      },
      {
        Istat: "3062",
        Comune: "Dormelletto",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28040",
        CodFisco: "D347",
        Abitanti: "2699",
      },
      {
        Istat: "96025",
        Comune: "Dorzano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13881",
        CodFisco: "D350",
        Abitanti: "500",
      },
      {
        Istat: "4082",
        Comune: "Dronero",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12025",
        CodFisco: "D372",
        Abitanti: "7317",
      },
      {
        Istat: "1099",
        Comune: "Druento",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "D373",
        Abitanti: "8513",
      },
      {
        Istat: "103029",
        Comune: "Druogno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28853",
        CodFisco: "D374",
        Abitanti: "976",
      },
      {
        Istat: "5052",
        Comune: "Dusino San Michele",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "D388",
        Abitanti: "1045",
      },
      {
        Istat: "4083",
        Comune: "Elva",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "D401",
        Abitanti: "102",
      },
      {
        Istat: "4084",
        Comune: "Entracque",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "D410",
        Abitanti: "845",
      },
      {
        Istat: "4085",
        Comune: "Envie",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "D412",
        Abitanti: "2074",
      },
      {
        Istat: "1100",
        Comune: "Exilles",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "D433",
        Abitanti: "270",
      },
      {
        Istat: "6067",
        Comune: "Fabbrica Curone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15054",
        CodFisco: "D447",
        Abitanti: "732",
      },
      {
        Istat: "103030",
        Comune: "Falmenta",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28827",
        CodFisco: "D481",
        Abitanti: "162",
      },
      {
        Istat: "3065",
        Comune: "Fara Novarese",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28073",
        CodFisco: "D492",
        Abitanti: "2093",
      },
      {
        Istat: "4086",
        Comune: "Farigliano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "D499",
        Abitanti: "1770",
      },
      {
        Istat: "4087",
        Comune: "Faule",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "12030",
        CodFisco: "D511",
        Abitanti: "484",
      },
      {
        Istat: "1101",
        Comune: "Favria",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10083",
        CodFisco: "D520",
        Abitanti: "5320",
      },
      {
        Istat: "4088",
        Comune: "Feisoglio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "D523",
        Abitanti: "355",
      },
      {
        Istat: "1102",
        Comune: "Feletto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "D524",
        Abitanti: "2410",
      },
      {
        Istat: "6068",
        Comune: "Felizzano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15023",
        CodFisco: "D528",
        Abitanti: "2475",
      },
      {
        Istat: "1103",
        Comune: "Fenestrelle",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "D532",
        Abitanti: "571",
      },
      {
        Istat: "5053",
        Comune: "Ferrere",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14012",
        CodFisco: "D554",
        Abitanti: "1631",
      },
      {
        Istat: "1104",
        Comune: "Fiano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "D562",
        Abitanti: "2744",
      },
      {
        Istat: "1105",
        Comune: "Fiorano Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "D608",
        Abitanti: "859",
      },
      {
        Istat: "2057",
        Comune: "Fobello",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13025",
        CodFisco: "D641",
        Abitanti: "220",
      },
      {
        Istat: "1106",
        Comune: "Foglizzo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "D646",
        Abitanti: "2372",
      },
      {
        Istat: "3066",
        Comune: "Fontaneto d'Agogna",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "D675",
        Abitanti: "2741",
      },
      {
        Istat: "2058",
        Comune: "Fontanetto Po",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "D676",
        Abitanti: "1242",
      },
      {
        Istat: "5054",
        Comune: "Fontanile",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14044",
        CodFisco: "D678",
        Abitanti: "576",
      },
      {
        Istat: "103031",
        Comune: "Formazza",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28863",
        CodFisco: "D706",
        Abitanti: "443",
      },
      {
        Istat: "2059",
        Comune: "Formigliana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "D712",
        Abitanti: "569",
      },
      {
        Istat: "1107",
        Comune: "Forno Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10084",
        CodFisco: "D725",
        Abitanti: "3691",
      },
      {
        Istat: "4089",
        Comune: "Fossano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12045",
        CodFisco: "D742",
        Abitanti: "24854",
      },
      {
        Istat: "4090",
        Comune: "Frabosa Soprana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12082",
        CodFisco: "D751",
        Abitanti: "823",
      },
      {
        Istat: "4091",
        Comune: "Frabosa Sottana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12083",
        CodFisco: "D752",
        Abitanti: "1605",
      },
      {
        Istat: "6069",
        Comune: "Fraconalto",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "10",
        CAP: "15060",
        CodFisco: "D559",
        Abitanti: "364",
      },
      {
        Istat: "6070",
        Comune: "Francavilla Bisio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "D759",
        Abitanti: "512",
      },
      {
        Istat: "6071",
        Comune: "Frascaro",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15010",
        CodFisco: "D770",
        Abitanti: "458",
      },
      {
        Istat: "6072",
        Comune: "Frassinello Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15035",
        CodFisco: "D777",
        Abitanti: "549",
      },
      {
        Istat: "6073",
        Comune: "Frassineto Po",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15040",
        CodFisco: "D780",
        Abitanti: "1463",
      },
      {
        Istat: "1108",
        Comune: "Frassinetto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "D781",
        Abitanti: "277",
      },
      {
        Istat: "4092",
        Comune: "Frassino",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "D782",
        Abitanti: "291",
      },
      {
        Istat: "6074",
        Comune: "Fresonara",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15064",
        CodFisco: "D797",
        Abitanti: "730",
      },
      {
        Istat: "5055",
        Comune: "Frinco",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "D802",
        Abitanti: "751",
      },
      {
        Istat: "1109",
        Comune: "Front",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "D805",
        Abitanti: "1753",
      },
      {
        Istat: "1110",
        Comune: "Frossasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "D812",
        Abitanti: "2870",
      },
      {
        Istat: "6075",
        Comune: "Frugarolo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15065",
        CodFisco: "D813",
        Abitanti: "1997",
      },
      {
        Istat: "6076",
        Comune: "Fubine",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15043",
        CodFisco: "D814",
        Abitanti: "1679",
      },
      {
        Istat: "6077",
        Comune: "Gabiano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "D835",
        Abitanti: "1250",
      },
      {
        Istat: "96026",
        Comune: "Gaglianico",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13894",
        CodFisco: "D848",
        Abitanti: "3931",
      },
      {
        Istat: "4093",
        Comune: "Gaiola",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "D856",
        Abitanti: "592",
      },
      {
        Istat: "3068",
        Comune: "Galliate",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28066",
        CodFisco: "D872",
        Abitanti: "15412",
      },
      {
        Istat: "6078",
        Comune: "Gamalero",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15010",
        CodFisco: "D890",
        Abitanti: "852",
      },
      {
        Istat: "4094",
        Comune: "Gambasca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "D894",
        Abitanti: "403",
      },
      {
        Istat: "6079",
        Comune: "Garbagna",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "D910",
        Abitanti: "721",
      },
      {
        Istat: "3069",
        Comune: "Garbagna Novarese",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28070",
        CodFisco: "D911",
        Abitanti: "1342",
      },
      {
        Istat: "4095",
        Comune: "Garessio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12075",
        CodFisco: "D920",
        Abitanti: "3432",
      },
      {
        Istat: "3070",
        Comune: "Gargallo",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "D921",
        Abitanti: "1835",
      },
      {
        Istat: "1111",
        Comune: "Garzigliana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "D931",
        Abitanti: "556",
      },
      {
        Istat: "1112",
        Comune: "Gassino Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "D933",
        Abitanti: "9553",
      },
      {
        Istat: "3071",
        Comune: "Gattico",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28013",
        CodFisco: "D937",
        Abitanti: "3402",
      },
      {
        Istat: "2061",
        Comune: "Gattinara",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13045",
        CodFisco: "D938",
        Abitanti: "8340",
      },
      {
        Istat: "6080",
        Comune: "Gavazzana",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15063",
        CodFisco: "D941",
        Abitanti: "171",
      },
      {
        Istat: "6081",
        Comune: "Gavi",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15066",
        CodFisco: "D944",
        Abitanti: "4744",
      },
      {
        Istat: "4096",
        Comune: "Genola",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "D967",
        Abitanti: "2567",
      },
      {
        Istat: "1113",
        Comune: "Germagnano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "D983",
        Abitanti: "1293",
      },
      {
        Istat: "103032",
        Comune: "Germagno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28887",
        CodFisco: "D984",
        Abitanti: "187",
      },
      {
        Istat: "3073",
        Comune: "Ghemme",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "28074",
        CodFisco: "E001",
        Abitanti: "3752",
      },
      {
        Istat: "103033",
        Comune: "Ghiffa",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28823",
        CodFisco: "E003",
        Abitanti: "2412",
      },
      {
        Istat: "2062",
        Comune: "Ghislarengo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "E007",
        Abitanti: "908",
      },
      {
        Istat: "1114",
        Comune: "Giaglione",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "E009",
        Abitanti: "661",
      },
      {
        Istat: "6082",
        Comune: "Giarole",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15036",
        CodFisco: "E015",
        Abitanti: "743",
      },
      {
        Istat: "1115",
        Comune: "Giaveno",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10094",
        CodFisco: "E020",
        Abitanti: "16730",
      },
      {
        Istat: "96027",
        Comune: "Gifflenga",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13874",
        CodFisco: "E024",
        Abitanti: "135",
      },
      {
        Istat: "103034",
        Comune: "Gignese",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28836",
        CodFisco: "E028",
        Abitanti: "973",
      },
      {
        Istat: "1116",
        Comune: "Givoletto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "E067",
        Abitanti: "3509",
      },
      {
        Istat: "4097",
        Comune: "Gorzegno",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12070",
        CodFisco: "E111",
        Abitanti: "332",
      },
      {
        Istat: "4098",
        Comune: "Gottasecca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "E115",
        Abitanti: "183",
      },
      {
        Istat: "4099",
        Comune: "Govone",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12040",
        CodFisco: "E118",
        Abitanti: "2132",
      },
      {
        Istat: "3076",
        Comune: "Gozzano",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28024",
        CodFisco: "E120",
        Abitanti: "5671",
      },
      {
        Istat: "96028",
        Comune: "Graglia",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13895",
        CodFisco: "E130",
        Abitanti: "1606",
      },
      {
        Istat: "5056",
        Comune: "Grana",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14031",
        CodFisco: "E134",
        Abitanti: "638",
      },
      {
        Istat: "3077",
        Comune: "Granozzo con Monticello",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "E143",
        Abitanti: "1432",
      },
      {
        Istat: "103035",
        Comune: "Gravellona Toce",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28883",
        CodFisco: "E153",
        Abitanti: "7868",
      },
      {
        Istat: "1117",
        Comune: "Gravere",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "E154",
        Abitanti: "745",
      },
      {
        Istat: "5057",
        Comune: "Grazzano Badoglio",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14035",
        CodFisco: "E159",
        Abitanti: "615",
      },
      {
        Istat: "2065",
        Comune: "Greggio",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "E163",
        Abitanti: "390",
      },
      {
        Istat: "6083",
        Comune: "Gremiasco",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15056",
        CodFisco: "E164",
        Abitanti: "362",
      },
      {
        Istat: "3079",
        Comune: "Grignasco",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "28075",
        CodFisco: "E177",
        Abitanti: "4779",
      },
      {
        Istat: "4100",
        Comune: "Grinzane Cavour",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "E182",
        Abitanti: "1928",
      },
      {
        Istat: "6084",
        Comune: "Grognardo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "E188",
        Abitanti: "302",
      },
      {
        Istat: "6085",
        Comune: "Grondona",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "E191",
        Abitanti: "549",
      },
      {
        Istat: "1118",
        Comune: "Groscavallo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "E199",
        Abitanti: "205",
      },
      {
        Istat: "1119",
        Comune: "Grosso",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "E203",
        Abitanti: "1055",
      },
      {
        Istat: "1120",
        Comune: "Grugliasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10095",
        CodFisco: "E216",
        Abitanti: "37870",
      },
      {
        Istat: "2066",
        Comune: "Guardabosone",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13010",
        CodFisco: "E237",
        Abitanti: "356",
      },
      {
        Istat: "4101",
        Comune: "Guarene",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "E251",
        Abitanti: "3476",
      },
      {
        Istat: "6086",
        Comune: "Guazzora",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "E255",
        Abitanti: "322",
      },
      {
        Istat: "103036",
        Comune: "Gurro",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28828",
        CodFisco: "E269",
        Abitanti: "252",
      },
      {
        Istat: "4102",
        Comune: "Igliano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "E282",
        Abitanti: "85",
      },
      {
        Istat: "5058",
        Comune: "Incisa Scapaccino",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14045",
        CodFisco: "E295",
        Abitanti: "2290",
      },
      {
        Istat: "1121",
        Comune: "Ingria",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "E301",
        Abitanti: "47",
      },
      {
        Istat: "103037",
        Comune: "Intragna",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28816",
        CodFisco: "E304",
        Abitanti: "114",
      },
      {
        Istat: "1122",
        Comune: "Inverso Pinasca",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "E311",
        Abitanti: "743",
      },
      {
        Istat: "3082",
        Comune: "Invorio",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28045",
        CodFisco: "E314",
        Abitanti: "4472",
      },
      {
        Istat: "4103",
        Comune: "Isasca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "E327",
        Abitanti: "78",
      },
      {
        Istat: "5059",
        Comune: "Isola d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14057",
        CodFisco: "E338",
        Abitanti: "2154",
      },
      {
        Istat: "6087",
        Comune: "Isola Sant'Antonio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "E360",
        Abitanti: "744",
      },
      {
        Istat: "1123",
        Comune: "Isolabella",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10046",
        CodFisco: "E345",
        Abitanti: "394",
      },
      {
        Istat: "1124",
        Comune: "Issiglio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "E368",
        Abitanti: "422",
      },
      {
        Istat: "1125",
        Comune: "Ivrea",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10015",
        CodFisco: "E379",
        Abitanti: "24196",
      },
      {
        Istat: "1126",
        Comune: "La Cassa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "E394",
        Abitanti: "1821",
      },
      {
        Istat: "1127",
        Comune: "La Loggia",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "E423",
        Abitanti: "8457",
      },
      {
        Istat: "4105",
        Comune: "La Morra",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12064",
        CodFisco: "E430",
        Abitanti: "2758",
      },
      {
        Istat: "4104",
        Comune: "Lagnasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "E406",
        Abitanti: "1388",
      },
      {
        Istat: "2067",
        Comune: "Lamporo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13046",
        CodFisco: "E433",
        Abitanti: "547",
      },
      {
        Istat: "3083",
        Comune: "Landiona",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28064",
        CodFisco: "E436",
        Abitanti: "614",
      },
      {
        Istat: "1128",
        Comune: "Lanzo Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10074",
        CodFisco: "E445",
        Abitanti: "5303",
      },
      {
        Istat: "1129",
        Comune: "Lauriano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "E484",
        Abitanti: "1546",
      },
      {
        Istat: "1130",
        Comune: "Leini",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "E518",
        Abitanti: "15451",
      },
      {
        Istat: "1131",
        Comune: "Lemie",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "E520",
        Abitanti: "190",
      },
      {
        Istat: "2068",
        Comune: "Lenta",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13035",
        CodFisco: "E528",
        Abitanti: "905",
      },
      {
        Istat: "4106",
        Comune: "Lequio Berria",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "E540",
        Abitanti: "499",
      },
      {
        Istat: "4107",
        Comune: "Lequio Tanaro",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12060",
        CodFisco: "E539",
        Abitanti: "806",
      },
      {
        Istat: "6088",
        Comune: "Lerma",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15070",
        CodFisco: "E543",
        Abitanti: "879",
      },
      {
        Istat: "3084",
        Comune: "Lesa",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28040",
        CodFisco: "E544",
        Abitanti: "2351",
      },
      {
        Istat: "4108",
        Comune: "Lesegno",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12076",
        CodFisco: "E546",
        Abitanti: "871",
      },
      {
        Istat: "1132",
        Comune: "Lessolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "E551",
        Abitanti: "1994",
      },
      {
        Istat: "96029",
        Comune: "Lessona",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13853",
        CodFisco: "E552",
        Abitanti: "2492",
      },
      {
        Istat: "4109",
        Comune: "Levice",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12070",
        CodFisco: "E564",
        Abitanti: "244",
      },
      {
        Istat: "1133",
        Comune: "Levone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10070",
        CodFisco: "E566",
        Abitanti: "459",
      },
      {
        Istat: "2070",
        Comune: "Lignana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13034",
        CodFisco: "E583",
        Abitanti: "584",
      },
      {
        Istat: "4110",
        Comune: "Limone Piemonte",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12015",
        CodFisco: "E597",
        Abitanti: "1527",
      },
      {
        Istat: "4111",
        Comune: "Lisio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "E615",
        Abitanti: "226",
      },
      {
        Istat: "2071",
        Comune: "Livorno Ferraris",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13046",
        CodFisco: "E626",
        Abitanti: "4529",
      },
      {
        Istat: "5060",
        Comune: "Loazzolo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14051",
        CodFisco: "E633",
        Abitanti: "342",
      },
      {
        Istat: "1134",
        Comune: "Locana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "E635",
        Abitanti: "1642",
      },
      {
        Istat: "1135",
        Comune: "Lombardore",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "E660",
        Abitanti: "1712",
      },
      {
        Istat: "1136",
        Comune: "Lombriasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "E661",
        Abitanti: "1075",
      },
      {
        Istat: "1137",
        Comune: "Loranzè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "E683",
        Abitanti: "1106",
      },
      {
        Istat: "103038",
        Comune: "Loreglia",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28893",
        CodFisco: "E685",
        Abitanti: "270",
      },
      {
        Istat: "2072",
        Comune: "Lozzolo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13045",
        CodFisco: "E711",
        Abitanti: "822",
      },
      {
        Istat: "6089",
        Comune: "Lu",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "E712",
        Abitanti: "1170",
      },
      {
        Istat: "1138",
        Comune: "Lugnacco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "E727",
        Abitanti: "380",
      },
      {
        Istat: "1139",
        Comune: "Luserna San Giovanni",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10062",
        CodFisco: "E758",
        Abitanti: "7676",
      },
      {
        Istat: "1140",
        Comune: "Lusernetta",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "E759",
        Abitanti: "531",
      },
      {
        Istat: "1141",
        Comune: "Lusigliè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "E763",
        Abitanti: "561",
      },
      {
        Istat: "1142",
        Comune: "Macello",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "E782",
        Abitanti: "1247",
      },
      {
        Istat: "4112",
        Comune: "Macra",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "E789",
        Abitanti: "55",
      },
      {
        Istat: "103039",
        Comune: "Macugnaga",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28876",
        CodFisco: "E790",
        Abitanti: "613",
      },
      {
        Istat: "103040",
        Comune: "Madonna del Sasso",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28894",
        CodFisco: "E795",
        Abitanti: "412",
      },
      {
        Istat: "3088",
        Comune: "Maggiora",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28014",
        CodFisco: "E803",
        Abitanti: "1794",
      },
      {
        Istat: "4113",
        Comune: "Magliano Alfieri",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "E809",
        Abitanti: "1984",
      },
      {
        Istat: "4114",
        Comune: "Magliano Alpi",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "E808",
        Abitanti: "2217",
      },
      {
        Istat: "1143",
        Comune: "Maglione",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "10030",
        CodFisco: "E817",
        Abitanti: "467",
      },
      {
        Istat: "96030",
        Comune: "Magnano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13887",
        CodFisco: "E821",
        Abitanti: "387",
      },
      {
        Istat: "103041",
        Comune: "Malesco",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28854",
        CodFisco: "E853",
        Abitanti: "1478",
      },
      {
        Istat: "6090",
        Comune: "Malvicino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15015",
        CodFisco: "E870",
        Abitanti: "96",
      },
      {
        Istat: "3090",
        Comune: "Mandello Vitta",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "E880",
        Abitanti: "248",
      },
      {
        Istat: "4115",
        Comune: "Mango",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "12056",
        CodFisco: "E887",
        Abitanti: "1344",
      },
      {
        Istat: "4116",
        Comune: "Manta",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "E894",
        Abitanti: "3716",
      },
      {
        Istat: "3091",
        Comune: "Marano Ticino",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28040",
        CodFisco: "E907",
        Abitanti: "1572",
      },
      {
        Istat: "5061",
        Comune: "Maranzana",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "E917",
        Abitanti: "325",
      },
      {
        Istat: "4117",
        Comune: "Marene",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "E939",
        Abitanti: "3047",
      },
      {
        Istat: "1144",
        Comune: "Marentino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "E941",
        Abitanti: "1409",
      },
      {
        Istat: "5062",
        Comune: "Maretto",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14018",
        CodFisco: "E944",
        Abitanti: "403",
      },
      {
        Istat: "4118",
        Comune: "Margarita",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12040",
        CodFisco: "E945",
        Abitanti: "1450",
      },
      {
        Istat: "4119",
        Comune: "Marmora",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "E963",
        Abitanti: "77",
      },
      {
        Istat: "4120",
        Comune: "Marsaglia",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "E973",
        Abitanti: "277",
      },
      {
        Istat: "4121",
        Comune: "Martiniana Po",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "E988",
        Abitanti: "767",
      },
      {
        Istat: "103042",
        Comune: "Masera",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28855",
        CodFisco: "F010",
        Abitanti: "1521",
      },
      {
        Istat: "6091",
        Comune: "Masio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15024",
        CodFisco: "F015",
        Abitanti: "1475",
      },
      {
        Istat: "96031",
        Comune: "Massazza",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13873",
        CodFisco: "F037",
        Abitanti: "553",
      },
      {
        Istat: "1145",
        Comune: "Massello",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "F041",
        Abitanti: "61",
      },
      {
        Istat: "96032",
        Comune: "Masserano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13866",
        CodFisco: "F042",
        Abitanti: "2264",
      },
      {
        Istat: "3093",
        Comune: "Massino Visconti",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28040",
        CodFisco: "F047",
        Abitanti: "1126",
      },
      {
        Istat: "103043",
        Comune: "Massiola",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28895",
        CodFisco: "F048",
        Abitanti: "143",
      },
      {
        Istat: "1146",
        Comune: "Mathi",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10075",
        CodFisco: "F053",
        Abitanti: "4074",
      },
      {
        Istat: "1147",
        Comune: "Mattie",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "F058",
        Abitanti: "715",
      },
      {
        Istat: "1148",
        Comune: "Mazzè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10035",
        CodFisco: "F067",
        Abitanti: "4177",
      },
      {
        Istat: "1149",
        Comune: "Meana di Susa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "F074",
        Abitanti: "903",
      },
      {
        Istat: "3095",
        Comune: "Meina",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28046",
        CodFisco: "F093",
        Abitanti: "2584",
      },
      {
        Istat: "6092",
        Comune: "Melazzo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "F096",
        Abitanti: "1327",
      },
      {
        Istat: "4122",
        Comune: "Melle",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "F114",
        Abitanti: "328",
      },
      {
        Istat: "6093",
        Comune: "Merana",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "F131",
        Abitanti: "190",
      },
      {
        Istat: "1150",
        Comune: "Mercenasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "F140",
        Abitanti: "1279",
      },
      {
        Istat: "103044",
        Comune: "Mergozzo",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28802",
        CodFisco: "F146",
        Abitanti: "2167",
      },
      {
        Istat: "1151",
        Comune: "Meugliano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "F164",
        Abitanti: "96",
      },
      {
        Istat: "96033",
        Comune: "Mezzana Mortigliengo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13831",
        CodFisco: "F167",
        Abitanti: "559",
      },
      {
        Istat: "1152",
        Comune: "Mezzenile",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "F182",
        Abitanti: "844",
      },
      {
        Istat: "3097",
        Comune: "Mezzomerico",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28040",
        CodFisco: "F188",
        Abitanti: "1175",
      },
      {
        Istat: "96034",
        Comune: "Miagliano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13816",
        CodFisco: "F189",
        Abitanti: "665",
      },
      {
        Istat: "3098",
        Comune: "Miasino",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "F191",
        Abitanti: "915",
      },
      {
        Istat: "103045",
        Comune: "Miazzina",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28817",
        CodFisco: "F192",
        Abitanti: "418",
      },
      {
        Istat: "6094",
        Comune: "Mirabello Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15040",
        CodFisco: "F232",
        Abitanti: "1399",
      },
      {
        Istat: "5063",
        Comune: "Moasca",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14050",
        CodFisco: "F254",
        Abitanti: "462",
      },
      {
        Istat: "4123",
        Comune: "Moiola",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "F279",
        Abitanti: "272",
      },
      {
        Istat: "6095",
        Comune: "Molare",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15074",
        CodFisco: "F281",
        Abitanti: "2255",
      },
      {
        Istat: "6096",
        Comune: "Molino dei Torti",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "F293",
        Abitanti: "687",
      },
      {
        Istat: "2078",
        Comune: "Mollia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "F297",
        Abitanti: "106",
      },
      {
        Istat: "5064",
        Comune: "Mombaldone",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14050",
        CodFisco: "F308",
        Abitanti: "225",
      },
      {
        Istat: "4124",
        Comune: "Mombarcaro",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "F309",
        Abitanti: "281",
      },
      {
        Istat: "5065",
        Comune: "Mombaruzzo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14046",
        CodFisco: "F311",
        Abitanti: "1146",
      },
      {
        Istat: "4125",
        Comune: "Mombasiglio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "F312",
        Abitanti: "616",
      },
      {
        Istat: "1153",
        Comune: "Mombello di Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "F315",
        Abitanti: "411",
      },
      {
        Istat: "6097",
        Comune: "Mombello Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "F313",
        Abitanti: "1099",
      },
      {
        Istat: "5066",
        Comune: "Mombercelli",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14047",
        CodFisco: "F316",
        Abitanti: "2394",
      },
      {
        Istat: "3100",
        Comune: "Momo",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28015",
        CodFisco: "F317",
        Abitanti: "2731",
      },
      {
        Istat: "1154",
        Comune: "Mompantero",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10059",
        CodFisco: "F318",
        Abitanti: "669",
      },
      {
        Istat: "6098",
        Comune: "Momperone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "F320",
        Abitanti: "218",
      },
      {
        Istat: "5067",
        Comune: "Monale",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14013",
        CodFisco: "F323",
        Abitanti: "1050",
      },
      {
        Istat: "5068",
        Comune: "Monastero Bormida",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14058",
        CodFisco: "F325",
        Abitanti: "1002",
      },
      {
        Istat: "1155",
        Comune: "Monastero di Lanzo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "F327",
        Abitanti: "396",
      },
      {
        Istat: "4126",
        Comune: "Monastero di Vasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "F326",
        Abitanti: "1307",
      },
      {
        Istat: "4127",
        Comune: "Monasterolo Casotto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "F329",
        Abitanti: "107",
      },
      {
        Istat: "4128",
        Comune: "Monasterolo di Savigliano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "F330",
        Abitanti: "1312",
      },
      {
        Istat: "1156",
        Comune: "Moncalieri",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10024",
        CodFisco: "F335",
        Abitanti: "58320",
      },
      {
        Istat: "5069",
        Comune: "Moncalvo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14036",
        CodFisco: "F336",
        Abitanti: "3275",
      },
      {
        Istat: "1157",
        Comune: "Moncenisio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "D553",
        Abitanti: "42",
      },
      {
        Istat: "6099",
        Comune: "Moncestino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "F337",
        Abitanti: "242",
      },
      {
        Istat: "4129",
        Comune: "Monchiero",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "F338",
        Abitanti: "574",
      },
      {
        Istat: "2079",
        Comune: "Moncrivello",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "F342",
        Abitanti: "1444",
      },
      {
        Istat: "5070",
        Comune: "Moncucco Torinese",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "14024",
        CodFisco: "F343",
        Abitanti: "891",
      },
      {
        Istat: "4130",
        Comune: "Mondovì",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12084",
        CodFisco: "F351",
        Abitanti: "22605",
      },
      {
        Istat: "4131",
        Comune: "Monesiglio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12077",
        CodFisco: "F355",
        Abitanti: "733",
      },
      {
        Istat: "4132",
        Comune: "Monforte d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12065",
        CodFisco: "F358",
        Abitanti: "2079",
      },
      {
        Istat: "5071",
        Comune: "Mongardino",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "F361",
        Abitanti: "950",
      },
      {
        Istat: "6100",
        Comune: "Mongiardino Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "F365",
        Abitanti: "182",
      },
      {
        Istat: "96035",
        Comune: "Mongrando",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13888",
        CodFisco: "F369",
        Abitanti: "4009",
      },
      {
        Istat: "6101",
        Comune: "Monleale",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15059",
        CodFisco: "F374",
        Abitanti: "599",
      },
      {
        Istat: "4133",
        Comune: "Montà",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12046",
        CodFisco: "F385",
        Abitanti: "4712",
      },
      {
        Istat: "5072",
        Comune: "Montabone",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "F386",
        Abitanti: "364",
      },
      {
        Istat: "6102",
        Comune: "Montacuto",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "F387",
        Abitanti: "308",
      },
      {
        Istat: "5073",
        Comune: "Montafia",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14014",
        CodFisco: "F390",
        Abitanti: "956",
      },
      {
        Istat: "6103",
        Comune: "Montaldeo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "F403",
        Abitanti: "292",
      },
      {
        Istat: "6104",
        Comune: "Montaldo Bormida",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15010",
        CodFisco: "F404",
        Abitanti: "709",
      },
      {
        Istat: "4134",
        Comune: "Montaldo di Mondovì",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "F405",
        Abitanti: "582",
      },
      {
        Istat: "4135",
        Comune: "Montaldo Roero",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "F408",
        Abitanti: "872",
      },
      {
        Istat: "5074",
        Comune: "Montaldo Scarampi",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14048",
        CodFisco: "F409",
        Abitanti: "791",
      },
      {
        Istat: "1158",
        Comune: "Montaldo Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "F407",
        Abitanti: "714",
      },
      {
        Istat: "1159",
        Comune: "Montalenghe",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "F411",
        Abitanti: "990",
      },
      {
        Istat: "1160",
        Comune: "Montalto Dora",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10016",
        CodFisco: "F420",
        Abitanti: "3493",
      },
      {
        Istat: "1161",
        Comune: "Montanaro",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10017",
        CodFisco: "F422",
        Abitanti: "5405",
      },
      {
        Istat: "4136",
        Comune: "Montanera",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12040",
        CodFisco: "F424",
        Abitanti: "741",
      },
      {
        Istat: "6105",
        Comune: "Montecastello",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "F455",
        Abitanti: "321",
      },
      {
        Istat: "6106",
        Comune: "Montechiaro d'Acqui",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "F469",
        Abitanti: "580",
      },
      {
        Istat: "5075",
        Comune: "Montechiaro d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14025",
        CodFisco: "F468",
        Abitanti: "1432",
      },
      {
        Istat: "103046",
        Comune: "Montecrestese",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28864",
        CodFisco: "F483",
        Abitanti: "1241",
      },
      {
        Istat: "6107",
        Comune: "Montegioco",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "F518",
        Abitanti: "340",
      },
      {
        Istat: "5076",
        Comune: "Montegrosso d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14048",
        CodFisco: "F527",
        Abitanti: "2293",
      },
      {
        Istat: "4137",
        Comune: "Montelupo Albese",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "F550",
        Abitanti: "536",
      },
      {
        Istat: "5077",
        Comune: "Montemagno",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "F556",
        Abitanti: "1203",
      },
      {
        Istat: "4138",
        Comune: "Montemale di Cuneo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12025",
        CodFisco: "F558",
        Abitanti: "220",
      },
      {
        Istat: "6108",
        Comune: "Montemarzino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "F562",
        Abitanti: "349",
      },
      {
        Istat: "4139",
        Comune: "Monterosso Grana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "F608",
        Abitanti: "546",
      },
      {
        Istat: "103047",
        Comune: "Montescheno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28843",
        CodFisco: "F639",
        Abitanti: "437",
      },
      {
        Istat: "1162",
        Comune: "Monteu da Po",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "F651",
        Abitanti: "897",
      },
      {
        Istat: "4140",
        Comune: "Monteu Roero",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12040",
        CodFisco: "F654",
        Abitanti: "1672",
      },
      {
        Istat: "4141",
        Comune: "Montezemolo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "F666",
        Abitanti: "283",
      },
      {
        Istat: "4142",
        Comune: "Monticello d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12066",
        CodFisco: "F669",
        Abitanti: "2220",
      },
      {
        Istat: "5121",
        Comune: "Montiglio Monferrato",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14026",
        CodFisco: "M302",
        Abitanti: "1689",
      },
      {
        Istat: "6109",
        Comune: "Morano sul Po",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15025",
        CodFisco: "F707",
        Abitanti: "1526",
      },
      {
        Istat: "5079",
        Comune: "Moransengo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14023",
        CodFisco: "F709",
        Abitanti: "206",
      },
      {
        Istat: "6110",
        Comune: "Morbello",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "F713",
        Abitanti: "434",
      },
      {
        Istat: "4143",
        Comune: "Moretta",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12033",
        CodFisco: "F723",
        Abitanti: "4274",
      },
      {
        Istat: "1163",
        Comune: "Moriondo Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "F733",
        Abitanti: "810",
      },
      {
        Istat: "6111",
        Comune: "Mornese",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15075",
        CodFisco: "F737",
        Abitanti: "738",
      },
      {
        Istat: "4144",
        Comune: "Morozzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12040",
        CodFisco: "F743",
        Abitanti: "2120",
      },
      {
        Istat: "6112",
        Comune: "Morsasco",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "F751",
        Abitanti: "704",
      },
      {
        Istat: "96084",
        Comune: "Mosso",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13822",
        CodFisco: "M304",
        Abitanti: "1659",
      },
      {
        Istat: "2082",
        Comune: "Motta de' Conti",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13010",
        CodFisco: "F774",
        Abitanti: "812",
      },
      {
        Istat: "96037",
        Comune: "Mottalciata",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13874",
        CodFisco: "F776",
        Abitanti: "1469",
      },
      {
        Istat: "4145",
        Comune: "Murazzano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "F809",
        Abitanti: "870",
      },
      {
        Istat: "4146",
        Comune: "Murello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "F811",
        Abitanti: "962",
      },
      {
        Istat: "6113",
        Comune: "Murisengo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "15020",
        CodFisco: "F814",
        Abitanti: "1484",
      },
      {
        Istat: "96038",
        Comune: "Muzzano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13895",
        CodFisco: "F833",
        Abitanti: "613",
      },
      {
        Istat: "4147",
        Comune: "Narzole",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12068",
        CodFisco: "F846",
        Abitanti: "3572",
      },
      {
        Istat: "3103",
        Comune: "Nebbiuno",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "F859",
        Abitanti: "1854",
      },
      {
        Istat: "4148",
        Comune: "Neive",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12052",
        CodFisco: "F863",
        Abitanti: "3374",
      },
      {
        Istat: "96039",
        Comune: "Netro",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13896",
        CodFisco: "F878",
        Abitanti: "995",
      },
      {
        Istat: "4149",
        Comune: "Neviglie",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "F883",
        Abitanti: "428",
      },
      {
        Istat: "3104",
        Comune: "Nibbiola",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28070",
        CodFisco: "F886",
        Abitanti: "781",
      },
      {
        Istat: "1164",
        Comune: "Nichelino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10042",
        CodFisco: "F889",
        Abitanti: "48946",
      },
      {
        Istat: "4150",
        Comune: "Niella Belbo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "F894",
        Abitanti: "409",
      },
      {
        Istat: "4151",
        Comune: "Niella Tanaro",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "F895",
        Abitanti: "1060",
      },
      {
        Istat: "5080",
        Comune: "Nizza Monferrato",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14049",
        CodFisco: "F902",
        Abitanti: "10391",
      },
      {
        Istat: "1165",
        Comune: "Noasca",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "F906",
        Abitanti: "185",
      },
      {
        Istat: "1166",
        Comune: "Nole",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10076",
        CodFisco: "F925",
        Abitanti: "6907",
      },
      {
        Istat: "1167",
        Comune: "Nomaglio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "F927",
        Abitanti: "320",
      },
      {
        Istat: "1168",
        Comune: "None",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "F931",
        Abitanti: "8034",
      },
      {
        Istat: "103048",
        Comune: "Nonio",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28891",
        CodFisco: "F932",
        Abitanti: "898",
      },
      {
        Istat: "1169",
        Comune: "Novalesa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "F948",
        Abitanti: "576",
      },
      {
        Istat: "3106",
        Comune: "Novara",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28100",
        CodFisco: "F952",
        Abitanti: "105024",
      },
      {
        Istat: "4152",
        Comune: "Novello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "F961",
        Abitanti: "1034",
      },
      {
        Istat: "6114",
        Comune: "Novi Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15067",
        CodFisco: "F965",
        Abitanti: "28744",
      },
      {
        Istat: "4153",
        Comune: "Nucetto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "F972",
        Abitanti: "436",
      },
      {
        Istat: "96040",
        Comune: "Occhieppo Inferiore",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13897",
        CodFisco: "F992",
        Abitanti: "4009",
      },
      {
        Istat: "96041",
        Comune: "Occhieppo Superiore",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13898",
        CodFisco: "F993",
        Abitanti: "2844",
      },
      {
        Istat: "6115",
        Comune: "Occimiano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15040",
        CodFisco: "F995",
        Abitanti: "1380",
      },
      {
        Istat: "6116",
        Comune: "Odalengo Grande",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "F997",
        Abitanti: "520",
      },
      {
        Istat: "6117",
        Comune: "Odalengo Piccolo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "15020",
        CodFisco: "F998",
        Abitanti: "267",
      },
      {
        Istat: "103049",
        Comune: "Oggebbio",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28824",
        CodFisco: "G007",
        Abitanti: "889",
      },
      {
        Istat: "1170",
        Comune: "Oglianico",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "G010",
        Abitanti: "1448",
      },
      {
        Istat: "2088",
        Comune: "Olcenengo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13047",
        CodFisco: "G016",
        Abitanti: "745",
      },
      {
        Istat: "2089",
        Comune: "Oldenico",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "G018",
        Abitanti: "258",
      },
      {
        Istat: "3108",
        Comune: "Oleggio",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28047",
        CodFisco: "G019",
        Abitanti: "13616",
      },
      {
        Istat: "3109",
        Comune: "Oleggio Castello",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28040",
        CodFisco: "G020",
        Abitanti: "1960",
      },
      {
        Istat: "6118",
        Comune: "Olivola",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "G042",
        Abitanti: "124",
      },
      {
        Istat: "5081",
        Comune: "Olmo Gentile",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14050",
        CodFisco: "G048",
        Abitanti: "89",
      },
      {
        Istat: "103050",
        Comune: "Omegna",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28887",
        CodFisco: "G062",
        Abitanti: "15991",
      },
      {
        Istat: "4154",
        Comune: "Oncino",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "G066",
        Abitanti: "81",
      },
      {
        Istat: "1171",
        Comune: "Orbassano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10043",
        CodFisco: "G087",
        Abitanti: "22345",
      },
      {
        Istat: "1172",
        Comune: "Orio Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10010",
        CodFisco: "G109",
        Abitanti: "820",
      },
      {
        Istat: "4155",
        Comune: "Ormea",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12078",
        CodFisco: "G114",
        Abitanti: "1783",
      },
      {
        Istat: "103051",
        Comune: "Ornavasso",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28877",
        CodFisco: "G117",
        Abitanti: "3419",
      },
      {
        Istat: "6119",
        Comune: "Orsara Bormida",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "G124",
        Abitanti: "405",
      },
      {
        Istat: "3112",
        Comune: "Orta San Giulio",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28016",
        CodFisco: "G134",
        Abitanti: "1167",
      },
      {
        Istat: "1173",
        Comune: "Osasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "G151",
        Abitanti: "1138",
      },
      {
        Istat: "1174",
        Comune: "Osasio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "G152",
        Abitanti: "897",
      },
      {
        Istat: "4156",
        Comune: "Ostana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "G183",
        Abitanti: "73",
      },
      {
        Istat: "6120",
        Comune: "Ottiglio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15038",
        CodFisco: "G193",
        Abitanti: "672",
      },
      {
        Istat: "1175",
        Comune: "Oulx",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10056",
        CodFisco: "G196",
        Abitanti: "3209",
      },
      {
        Istat: "6121",
        Comune: "Ovada",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15076",
        CodFisco: "G197",
        Abitanti: "11965",
      },
      {
        Istat: "6122",
        Comune: "Oviglio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15026",
        CodFisco: "G199",
        Abitanti: "1322",
      },
      {
        Istat: "1176",
        Comune: "Ozegna",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "G202",
        Abitanti: "1258",
      },
      {
        Istat: "6123",
        Comune: "Ozzano Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15039",
        CodFisco: "G204",
        Abitanti: "1501",
      },
      {
        Istat: "6124",
        Comune: "Paderna",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "G215",
        Abitanti: "233",
      },
      {
        Istat: "4157",
        Comune: "Paesana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12034",
        CodFisco: "G228",
        Abitanti: "2937",
      },
      {
        Istat: "4158",
        Comune: "Pagno",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "G240",
        Abitanti: "584",
      },
      {
        Istat: "1177",
        Comune: "Palazzo Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "G262",
        Abitanti: "850",
      },
      {
        Istat: "2090",
        Comune: "Palazzolo Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "G266",
        Abitanti: "1290",
      },
      {
        Istat: "103052",
        Comune: "Pallanzeno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28884",
        CodFisco: "G280",
        Abitanti: "1177",
      },
      {
        Istat: "4159",
        Comune: "Pamparato",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12087",
        CodFisco: "G302",
        Abitanti: "336",
      },
      {
        Istat: "1178",
        Comune: "Pancalieri",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "G303",
        Abitanti: "2019",
      },
      {
        Istat: "1179",
        Comune: "Parella",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "G330",
        Abitanti: "479",
      },
      {
        Istat: "6125",
        Comune: "Pareto",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "19",
        CAP: "15010",
        CodFisco: "G334",
        Abitanti: "634",
      },
      {
        Istat: "6126",
        Comune: "Parodi Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "G338",
        Abitanti: "747",
      },
      {
        Istat: "4160",
        Comune: "Paroldo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "G339",
        Abitanti: "239",
      },
      {
        Istat: "3114",
        Comune: "Paruzzaro",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28040",
        CodFisco: "G349",
        Abitanti: "2064",
      },
      {
        Istat: "5082",
        Comune: "Passerano Marmorito",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "G358",
        Abitanti: "451",
      },
      {
        Istat: "6127",
        Comune: "Pasturana",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "G367",
        Abitanti: "1217",
      },
      {
        Istat: "1180",
        Comune: "Pavarolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "G387",
        Abitanti: "1107",
      },
      {
        Istat: "1181",
        Comune: "Pavone Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10018",
        CodFisco: "G392",
        Abitanti: "3899",
      },
      {
        Istat: "1182",
        Comune: "Pecco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "G396",
        Abitanti: "218",
      },
      {
        Istat: "6128",
        Comune: "Pecetto di Valenza",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "G397",
        Abitanti: "1260",
      },
      {
        Istat: "1183",
        Comune: "Pecetto Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "G398",
        Abitanti: "3954",
      },
      {
        Istat: "3115",
        Comune: "Pella",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "G421",
        Abitanti: "1082",
      },
      {
        Istat: "5083",
        Comune: "Penango",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "G430",
        Abitanti: "525",
      },
      {
        Istat: "4161",
        Comune: "Perletto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12070",
        CodFisco: "G457",
        Abitanti: "306",
      },
      {
        Istat: "4162",
        Comune: "Perlo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "G458",
        Abitanti: "127",
      },
      {
        Istat: "1184",
        Comune: "Perosa Argentina",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10063",
        CodFisco: "G463",
        Abitanti: "3438",
      },
      {
        Istat: "1185",
        Comune: "Perosa Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "G462",
        Abitanti: "578",
      },
      {
        Istat: "1186",
        Comune: "Perrero",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "G465",
        Abitanti: "737",
      },
      {
        Istat: "2091",
        Comune: "Pertengo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "G471",
        Abitanti: "332",
      },
      {
        Istat: "1187",
        Comune: "Pertusio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "G477",
        Abitanti: "772",
      },
      {
        Istat: "1188",
        Comune: "Pessinetto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "G505",
        Abitanti: "614",
      },
      {
        Istat: "3116",
        Comune: "Pettenasco",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28028",
        CodFisco: "G520",
        Abitanti: "1392",
      },
      {
        Istat: "96042",
        Comune: "Pettinengo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13843",
        CodFisco: "G521",
        Abitanti: "1564",
      },
      {
        Istat: "4163",
        Comune: "Peveragno",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12016",
        CodFisco: "G526",
        Abitanti: "5496",
      },
      {
        Istat: "2093",
        Comune: "Pezzana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13010",
        CodFisco: "G528",
        Abitanti: "1338",
      },
      {
        Istat: "4164",
        Comune: "Pezzolo Valle Uzzone",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12070",
        CodFisco: "G532",
        Abitanti: "353",
      },
      {
        Istat: "1189",
        Comune: "Pianezza",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10044",
        CodFisco: "G559",
        Abitanti: "14088",
      },
      {
        Istat: "4165",
        Comune: "Pianfei",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "G561",
        Abitanti: "2200",
      },
      {
        Istat: "4166",
        Comune: "Piasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12026",
        CodFisco: "G575",
        Abitanti: "2855",
      },
      {
        Istat: "96043",
        Comune: "Piatto",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13844",
        CodFisco: "G577",
        Abitanti: "559",
      },
      {
        Istat: "5084",
        Comune: "Piea",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "G593",
        Abitanti: "636",
      },
      {
        Istat: "96044",
        Comune: "Piedicavallo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13812",
        CodFisco: "G594",
        Abitanti: "210",
      },
      {
        Istat: "103053",
        Comune: "Piedimulera",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28885",
        CodFisco: "G600",
        Abitanti: "1606",
      },
      {
        Istat: "6129",
        Comune: "Pietra Marazzi",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "G619",
        Abitanti: "914",
      },
      {
        Istat: "4167",
        Comune: "Pietraporzio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "G625",
        Abitanti: "94",
      },
      {
        Istat: "103054",
        Comune: "Pieve Vergonte",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28886",
        CodFisco: "G658",
        Abitanti: "2682",
      },
      {
        Istat: "2096",
        Comune: "Pila",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "G666",
        Abitanti: "140",
      },
      {
        Istat: "1190",
        Comune: "Pinasca",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "G672",
        Abitanti: "3073",
      },
      {
        Istat: "1191",
        Comune: "Pinerolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10064",
        CodFisco: "G674",
        Abitanti: "36158",
      },
      {
        Istat: "5085",
        Comune: "Pino d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "14020",
        CodFisco: "G676",
        Abitanti: "242",
      },
      {
        Istat: "1192",
        Comune: "Pino Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10025",
        CodFisco: "G678",
        Abitanti: "8648",
      },
      {
        Istat: "4168",
        Comune: "Piobesi d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12040",
        CodFisco: "G683",
        Abitanti: "1279",
      },
      {
        Istat: "1193",
        Comune: "Piobesi Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "G684",
        Abitanti: "3723",
      },
      {
        Istat: "2097",
        Comune: "Piode",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "G685",
        Abitanti: "195",
      },
      {
        Istat: "1194",
        Comune: "Piossasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10045",
        CodFisco: "G691",
        Abitanti: "18279",
      },
      {
        Istat: "5086",
        Comune: "Piovà Massaia",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14026",
        CodFisco: "G692",
        Abitanti: "684",
      },
      {
        Istat: "6130",
        Comune: "Piovera",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "G695",
        Abitanti: "818",
      },
      {
        Istat: "4169",
        Comune: "Piozzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "G697",
        Abitanti: "1003",
      },
      {
        Istat: "3119",
        Comune: "Pisano",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "G703",
        Abitanti: "807",
      },
      {
        Istat: "1195",
        Comune: "Piscina",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "G705",
        Abitanti: "3435",
      },
      {
        Istat: "1196",
        Comune: "Piverone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "G719",
        Abitanti: "1381",
      },
      {
        Istat: "4170",
        Comune: "Pocapaglia",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12060",
        CodFisco: "G742",
        Abitanti: "3281",
      },
      {
        Istat: "3120",
        Comune: "Pogno",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28076",
        CodFisco: "G775",
        Abitanti: "1568",
      },
      {
        Istat: "1197",
        Comune: "Poirino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10046",
        CodFisco: "G777",
        Abitanti: "10245",
      },
      {
        Istat: "96046",
        Comune: "Pollone",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13814",
        CodFisco: "G798",
        Abitanti: "2192",
      },
      {
        Istat: "4171",
        Comune: "Polonghera",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "12030",
        CodFisco: "G800",
        Abitanti: "1196",
      },
      {
        Istat: "1198",
        Comune: "Pomaretto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10063",
        CodFisco: "G805",
        Abitanti: "1087",
      },
      {
        Istat: "6131",
        Comune: "Pomaro Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15040",
        CodFisco: "G807",
        Abitanti: "386",
      },
      {
        Istat: "3121",
        Comune: "Pombia",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28050",
        CodFisco: "G809",
        Abitanti: "2174",
      },
      {
        Istat: "96047",
        Comune: "Ponderano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13875",
        CodFisco: "G820",
        Abitanti: "4008",
      },
      {
        Istat: "1199",
        Comune: "Pont-Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10085",
        CodFisco: "G826",
        Abitanti: "3756",
      },
      {
        Istat: "4172",
        Comune: "Pontechianale",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "G837",
        Abitanti: "187",
      },
      {
        Istat: "6132",
        Comune: "Pontecurone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15055",
        CodFisco: "G839",
        Abitanti: "3867",
      },
      {
        Istat: "6133",
        Comune: "Pontestura",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15027",
        CodFisco: "G858",
        Abitanti: "1485",
      },
      {
        Istat: "6134",
        Comune: "Ponti",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "G861",
        Abitanti: "639",
      },
      {
        Istat: "6135",
        Comune: "Ponzano Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "15020",
        CodFisco: "G872",
        Abitanti: "383",
      },
      {
        Istat: "6136",
        Comune: "Ponzone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "G877",
        Abitanti: "1114",
      },
      {
        Istat: "5087",
        Comune: "Portacomaro",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14037",
        CodFisco: "G894",
        Abitanti: "2003",
      },
      {
        Istat: "1200",
        Comune: "Porte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "G900",
        Abitanti: "1124",
      },
      {
        Istat: "96048",
        Comune: "Portula",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13833",
        CodFisco: "G927",
        Abitanti: "1398",
      },
      {
        Istat: "2102",
        Comune: "Postua",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13010",
        CodFisco: "G940",
        Abitanti: "590",
      },
      {
        Istat: "6137",
        Comune: "Pozzol Groppo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "G960",
        Abitanti: "378",
      },
      {
        Istat: "6138",
        Comune: "Pozzolo Formigaro",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15068",
        CodFisco: "G961",
        Abitanti: "4886",
      },
      {
        Istat: "4173",
        Comune: "Pradleves",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12027",
        CodFisco: "G970",
        Abitanti: "276",
      },
      {
        Istat: "1201",
        Comune: "Pragelato",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10060",
        CodFisco: "G973",
        Abitanti: "794",
      },
      {
        Istat: "1202",
        Comune: "Prali",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "G978",
        Abitanti: "266",
      },
      {
        Istat: "1203",
        Comune: "Pralormo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "G979",
        Abitanti: "1939",
      },
      {
        Istat: "96049",
        Comune: "Pralungo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13899",
        CodFisco: "G980",
        Abitanti: "2684",
      },
      {
        Istat: "1204",
        Comune: "Pramollo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10065",
        CodFisco: "G982",
        Abitanti: "248",
      },
      {
        Istat: "2104",
        Comune: "Prarolo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13012",
        CodFisco: "G985",
        Abitanti: "666",
      },
      {
        Istat: "1205",
        Comune: "Prarostino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "G986",
        Abitanti: "1302",
      },
      {
        Istat: "6139",
        Comune: "Prasco",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "G987",
        Abitanti: "565",
      },
      {
        Istat: "1206",
        Comune: "Prascorsano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "G988",
        Abitanti: "798",
      },
      {
        Istat: "1207",
        Comune: "Pratiglione",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "G997",
        Abitanti: "564",
      },
      {
        Istat: "3122",
        Comune: "Prato Sesia",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "28077",
        CodFisco: "H001",
        Abitanti: "2023",
      },
      {
        Istat: "96050",
        Comune: "Pray",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13867",
        CodFisco: "G974",
        Abitanti: "2360",
      },
      {
        Istat: "4174",
        Comune: "Prazzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12028",
        CodFisco: "H011",
        Abitanti: "185",
      },
      {
        Istat: "6140",
        Comune: "Predosa",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15077",
        CodFisco: "H021",
        Abitanti: "2128",
      },
      {
        Istat: "103055",
        Comune: "Premeno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28818",
        CodFisco: "H030",
        Abitanti: "773",
      },
      {
        Istat: "103056",
        Comune: "Premia",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28866",
        CodFisco: "H033",
        Abitanti: "582",
      },
      {
        Istat: "103057",
        Comune: "Premosello-Chiovenda",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28803",
        CodFisco: "H037",
        Abitanti: "2052",
      },
      {
        Istat: "4175",
        Comune: "Priero",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "H059",
        Abitanti: "511",
      },
      {
        Istat: "4176",
        Comune: "Priocca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12040",
        CodFisco: "H068",
        Abitanti: "1984",
      },
      {
        Istat: "4177",
        Comune: "Priola",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "H069",
        Abitanti: "741",
      },
      {
        Istat: "4178",
        Comune: "Prunetto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12077",
        CodFisco: "H085",
        Abitanti: "478",
      },
      {
        Istat: "1208",
        Comune: "Quagliuzzo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "H100",
        Abitanti: "339",
      },
      {
        Istat: "5088",
        Comune: "Quaranti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "H102",
        Abitanti: "200",
      },
      {
        Istat: "96051",
        Comune: "Quaregna",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13854",
        CodFisco: "H103",
        Abitanti: "1399",
      },
      {
        Istat: "6141",
        Comune: "Quargnento",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15044",
        CodFisco: "H104",
        Abitanti: "1420",
      },
      {
        Istat: "103058",
        Comune: "Quarna Sopra",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28898",
        CodFisco: "H106",
        Abitanti: "283",
      },
      {
        Istat: "103059",
        Comune: "Quarna Sotto",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28896",
        CodFisco: "H107",
        Abitanti: "430",
      },
      {
        Istat: "2107",
        Comune: "Quarona",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13017",
        CodFisco: "H108",
        Abitanti: "4292",
      },
      {
        Istat: "1209",
        Comune: "Quassolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "H120",
        Abitanti: "370",
      },
      {
        Istat: "6142",
        Comune: "Quattordio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15028",
        CodFisco: "H121",
        Abitanti: "1682",
      },
      {
        Istat: "1210",
        Comune: "Quincinetto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "H127",
        Abitanti: "1065",
      },
      {
        Istat: "2108",
        Comune: "Quinto Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "H132",
        Abitanti: "417",
      },
      {
        Istat: "96052",
        Comune: "Quittengo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13812",
        CodFisco: "H145",
        Abitanti: "220",
      },
      {
        Istat: "4179",
        Comune: "Racconigi",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12035",
        CodFisco: "H150",
        Abitanti: "10161",
      },
      {
        Istat: "2110",
        Comune: "Rassa",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "H188",
        Abitanti: "73",
      },
      {
        Istat: "103060",
        Comune: "Re",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28856",
        CodFisco: "H203",
        Abitanti: "765",
      },
      {
        Istat: "1211",
        Comune: "Reano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "H207",
        Abitanti: "1688",
      },
      {
        Istat: "3129",
        Comune: "Recetto",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "H213",
        Abitanti: "948",
      },
      {
        Istat: "5089",
        Comune: "Refrancore",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "H219",
        Abitanti: "1678",
      },
      {
        Istat: "4180",
        Comune: "Revello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12036",
        CodFisco: "H247",
        Abitanti: "4226",
      },
      {
        Istat: "5090",
        Comune: "Revigliasco d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "H250",
        Abitanti: "840",
      },
      {
        Istat: "1212",
        Comune: "Ribordone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "H270",
        Abitanti: "69",
      },
      {
        Istat: "6143",
        Comune: "Ricaldone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "H272",
        Abitanti: "685",
      },
      {
        Istat: "4181",
        Comune: "Rifreddo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "H285",
        Abitanti: "1077",
      },
      {
        Istat: "2111",
        Comune: "Rima San Giuseppe",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13026",
        CodFisco: "H291",
        Abitanti: "69",
      },
      {
        Istat: "2112",
        Comune: "Rimasco",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13026",
        CodFisco: "H292",
        Abitanti: "119",
      },
      {
        Istat: "2113",
        Comune: "Rimella",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "H293",
        Abitanti: "135",
      },
      {
        Istat: "4182",
        Comune: "Rittana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "H326",
        Abitanti: "140",
      },
      {
        Istat: "1215",
        Comune: "Riva Presso Chieri",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "H337",
        Abitanti: "4199",
      },
      {
        Istat: "2114",
        Comune: "Riva Valdobbia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "H329",
        Abitanti: "250",
      },
      {
        Istat: "1213",
        Comune: "Rivalba",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "H333",
        Abitanti: "1162",
      },
      {
        Istat: "6144",
        Comune: "Rivalta Bormida",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "H334",
        Abitanti: "1447",
      },
      {
        Istat: "1214",
        Comune: "Rivalta di Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "H335",
        Abitanti: "19422",
      },
      {
        Istat: "1216",
        Comune: "Rivara",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "H338",
        Abitanti: "2721",
      },
      {
        Istat: "1217",
        Comune: "Rivarolo Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10086",
        CodFisco: "H340",
        Abitanti: "12370",
      },
      {
        Istat: "6145",
        Comune: "Rivarone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15040",
        CodFisco: "H343",
        Abitanti: "371",
      },
      {
        Istat: "1218",
        Comune: "Rivarossa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "H344",
        Abitanti: "1658",
      },
      {
        Istat: "2115",
        Comune: "Rive",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "H346",
        Abitanti: "473",
      },
      {
        Istat: "1219",
        Comune: "Rivoli",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10098",
        CodFisco: "H355",
        Abitanti: "49591",
      },
      {
        Istat: "4183",
        Comune: "Roaschia",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "H362",
        Abitanti: "151",
      },
      {
        Istat: "4184",
        Comune: "Roascio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12073",
        CodFisco: "H363",
        Abitanti: "82",
      },
      {
        Istat: "2116",
        Comune: "Roasio",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13060",
        CodFisco: "H365",
        Abitanti: "2477",
      },
      {
        Istat: "5091",
        Comune: "Roatto",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14018",
        CodFisco: "H366",
        Abitanti: "396",
      },
      {
        Istat: "1220",
        Comune: "Robassomero",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "H367",
        Abitanti: "3023",
      },
      {
        Istat: "5092",
        Comune: "Robella",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "H376",
        Abitanti: "503",
      },
      {
        Istat: "4185",
        Comune: "Robilante",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12017",
        CodFisco: "H377",
        Abitanti: "2447",
      },
      {
        Istat: "4186",
        Comune: "Roburent",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "H378",
        Abitanti: "541",
      },
      {
        Istat: "1221",
        Comune: "Rocca Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "H386",
        Abitanti: "1781",
      },
      {
        Istat: "4188",
        Comune: "Rocca Cigliè",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12060",
        CodFisco: "H391",
        Abitanti: "159",
      },
      {
        Istat: "5093",
        Comune: "Rocca d'Arazzo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "H392",
        Abitanti: "954",
      },
      {
        Istat: "4189",
        Comune: "Rocca de' Baldi",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12047",
        CodFisco: "H395",
        Abitanti: "1685",
      },
      {
        Istat: "6147",
        Comune: "Rocca Grimalda",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15078",
        CodFisco: "H414",
        Abitanti: "1548",
      },
      {
        Istat: "4187",
        Comune: "Roccabruna",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "H385",
        Abitanti: "1603",
      },
      {
        Istat: "6146",
        Comune: "Roccaforte Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "H406",
        Abitanti: "158",
      },
      {
        Istat: "4190",
        Comune: "Roccaforte Mondovì",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12088",
        CodFisco: "H407",
        Abitanti: "2127",
      },
      {
        Istat: "4191",
        Comune: "Roccasparvera",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "H447",
        Abitanti: "737",
      },
      {
        Istat: "5094",
        Comune: "Roccaverano",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14050",
        CodFisco: "H451",
        Abitanti: "443",
      },
      {
        Istat: "4192",
        Comune: "Roccavione",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12018",
        CodFisco: "H453",
        Abitanti: "2889",
      },
      {
        Istat: "4193",
        Comune: "Rocchetta Belbo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "12050",
        CodFisco: "H462",
        Abitanti: "178",
      },
      {
        Istat: "6148",
        Comune: "Rocchetta Ligure",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "H465",
        Abitanti: "216",
      },
      {
        Istat: "5095",
        Comune: "Rocchetta Palafea",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14042",
        CodFisco: "H466",
        Abitanti: "359",
      },
      {
        Istat: "5096",
        Comune: "Rocchetta Tanaro",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "H468",
        Abitanti: "1475",
      },
      {
        Istat: "4194",
        Comune: "Roddi",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "H472",
        Abitanti: "1578",
      },
      {
        Istat: "4195",
        Comune: "Roddino",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "H473",
        Abitanti: "377",
      },
      {
        Istat: "4196",
        Comune: "Rodello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "H474",
        Abitanti: "986",
      },
      {
        Istat: "1222",
        Comune: "Roletto",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "H498",
        Abitanti: "2028",
      },
      {
        Istat: "3130",
        Comune: "Romagnano Sesia",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "28078",
        CodFisco: "H502",
        Abitanti: "4103",
      },
      {
        Istat: "1223",
        Comune: "Romano Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10090",
        CodFisco: "H511",
        Abitanti: "2957",
      },
      {
        Istat: "3131",
        Comune: "Romentino",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28068",
        CodFisco: "H518",
        Abitanti: "5386",
      },
      {
        Istat: "96053",
        Comune: "Ronco Biellese",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13845",
        CodFisco: "H538",
        Abitanti: "1509",
      },
      {
        Istat: "1224",
        Comune: "Ronco Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "H539",
        Abitanti: "319",
      },
      {
        Istat: "1225",
        Comune: "Rondissone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10030",
        CodFisco: "H547",
        Abitanti: "1843",
      },
      {
        Istat: "2118",
        Comune: "Ronsecco",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13036",
        CodFisco: "H549",
        Abitanti: "603",
      },
      {
        Istat: "96054",
        Comune: "Roppolo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13883",
        CodFisco: "H553",
        Abitanti: "934",
      },
      {
        Istat: "1226",
        Comune: "Rorà",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "H554",
        Abitanti: "251",
      },
      {
        Istat: "96055",
        Comune: "Rosazza",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13815",
        CodFisco: "H561",
        Abitanti: "95",
      },
      {
        Istat: "6149",
        Comune: "Rosignano Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "H569",
        Abitanti: "1670",
      },
      {
        Istat: "2121",
        Comune: "Rossa",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "H577",
        Abitanti: "194",
      },
      {
        Istat: "4197",
        Comune: "Rossana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "H578",
        Abitanti: "929",
      },
      {
        Istat: "1228",
        Comune: "Rosta",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "H583",
        Abitanti: "4623",
      },
      {
        Istat: "1227",
        Comune: "Roure",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "H555",
        Abitanti: "897",
      },
      {
        Istat: "2122",
        Comune: "Rovasenda",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "H364",
        Abitanti: "998",
      },
      {
        Istat: "1229",
        Comune: "Rubiana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "H627",
        Abitanti: "2399",
      },
      {
        Istat: "1230",
        Comune: "Rueglio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "H631",
        Abitanti: "765",
      },
      {
        Istat: "4198",
        Comune: "Ruffia",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "H633",
        Abitanti: "365",
      },
      {
        Istat: "2123",
        Comune: "Sabbia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "H648",
        Abitanti: "69",
      },
      {
        Istat: "96056",
        Comune: "Sagliano Micca",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13816",
        CodFisco: "H662",
        Abitanti: "1679",
      },
      {
        Istat: "96057",
        Comune: "Sala Biellese",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13884",
        CodFisco: "H681",
        Abitanti: "627",
      },
      {
        Istat: "6150",
        Comune: "Sala Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "H677",
        Abitanti: "386",
      },
      {
        Istat: "2126",
        Comune: "Salasco",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "H690",
        Abitanti: "229",
      },
      {
        Istat: "1231",
        Comune: "Salassa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "H691",
        Abitanti: "1771",
      },
      {
        Istat: "1232",
        Comune: "Salbertrand",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "H684",
        Abitanti: "572",
      },
      {
        Istat: "6151",
        Comune: "Sale",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15045",
        CodFisco: "H694",
        Abitanti: "4297",
      },
      {
        Istat: "4199",
        Comune: "Sale delle Langhe",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "H695",
        Abitanti: "524",
      },
      {
        Istat: "4200",
        Comune: "Sale San Giovanni",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "H704",
        Abitanti: "180",
      },
      {
        Istat: "1233",
        Comune: "Salerano Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "H702",
        Abitanti: "543",
      },
      {
        Istat: "2127",
        Comune: "Sali Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "H707",
        Abitanti: "120",
      },
      {
        Istat: "4201",
        Comune: "Saliceto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12079",
        CodFisco: "H710",
        Abitanti: "1403",
      },
      {
        Istat: "4202",
        Comune: "Salmour",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "H716",
        Abitanti: "725",
      },
      {
        Istat: "2128",
        Comune: "Saluggia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13040",
        CodFisco: "H725",
        Abitanti: "4201",
      },
      {
        Istat: "96058",
        Comune: "Salussola",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13885",
        CodFisco: "H726",
        Abitanti: "2085",
      },
      {
        Istat: "4203",
        Comune: "Saluzzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12037",
        CodFisco: "H727",
        Abitanti: "17067",
      },
      {
        Istat: "1234",
        Comune: "Salza di Pinerolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "H734",
        Abitanti: "79",
      },
      {
        Istat: "4204",
        Comune: "Sambuco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "H746",
        Abitanti: "99",
      },
      {
        Istat: "1235",
        Comune: "Samone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "H753",
        Abitanti: "1661",
      },
      {
        Istat: "4205",
        Comune: "Sampeyre",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "H755",
        Abitanti: "1083",
      },
      {
        Istat: "4206",
        Comune: "San Benedetto Belbo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "H770",
        Abitanti: "196",
      },
      {
        Istat: "1236",
        Comune: "San Benigno Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10080",
        CodFisco: "H775",
        Abitanti: "5675",
      },
      {
        Istat: "103061",
        Comune: "San Bernardino Verbano",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28804",
        CodFisco: "H777",
        Abitanti: "1380",
      },
      {
        Istat: "1237",
        Comune: "San Carlo Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "H789",
        Abitanti: "3943",
      },
      {
        Istat: "1238",
        Comune: "San Colombano Belmonte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "H804",
        Abitanti: "375",
      },
      {
        Istat: "6152",
        Comune: "San Cristoforo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "H810",
        Abitanti: "604",
      },
      {
        Istat: "5097",
        Comune: "San Damiano d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14015",
        CodFisco: "H811",
        Abitanti: "8401",
      },
      {
        Istat: "4207",
        Comune: "San Damiano Macra",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12029",
        CodFisco: "H812",
        Abitanti: "451",
      },
      {
        Istat: "1239",
        Comune: "San Didero",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "H820",
        Abitanti: "574",
      },
      {
        Istat: "1240",
        Comune: "San Francesco al Campo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "H847",
        Abitanti: "4877",
      },
      {
        Istat: "1242",
        Comune: "San Germano Chisone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10065",
        CodFisco: "H862",
        Abitanti: "1878",
      },
      {
        Istat: "2131",
        Comune: "San Germano Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13047",
        CodFisco: "H861",
        Abitanti: "1784",
      },
      {
        Istat: "2035",
        Comune: "San Giacomo Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "B952",
        Abitanti: "330",
      },
      {
        Istat: "1243",
        Comune: "San Gillio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "H873",
        Abitanti: "3035",
      },
      {
        Istat: "1244",
        Comune: "San Giorgio Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10090",
        CodFisco: "H890",
        Abitanti: "2721",
      },
      {
        Istat: "6153",
        Comune: "San Giorgio Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "H878",
        Abitanti: "1290",
      },
      {
        Istat: "5098",
        Comune: "San Giorgio Scarampi",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14059",
        CodFisco: "H899",
        Abitanti: "123",
      },
      {
        Istat: "1245",
        Comune: "San Giorio di Susa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "H900",
        Abitanti: "1041",
      },
      {
        Istat: "1246",
        Comune: "San Giusto Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10090",
        CodFisco: "H936",
        Abitanti: "3383",
      },
      {
        Istat: "5099",
        Comune: "San Martino Alfieri",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "H987",
        Abitanti: "712",
      },
      {
        Istat: "1247",
        Comune: "San Martino Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "H997",
        Abitanti: "865",
      },
      {
        Istat: "5100",
        Comune: "San Marzano Oliveto",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14050",
        CodFisco: "I017",
        Abitanti: "1075",
      },
      {
        Istat: "1248",
        Comune: "San Maurizio Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10077",
        CodFisco: "I024",
        Abitanti: "9763",
      },
      {
        Istat: "3133",
        Comune: "San Maurizio d'Opaglio",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28017",
        CodFisco: "I025",
        Abitanti: "3199",
      },
      {
        Istat: "1249",
        Comune: "San Mauro Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10099",
        CodFisco: "I030",
        Abitanti: "19311",
      },
      {
        Istat: "4210",
        Comune: "San Michele Mondovì",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "I037",
        Abitanti: "2072",
      },
      {
        Istat: "3134",
        Comune: "San Nazzaro Sesia",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "I052",
        Abitanti: "702",
      },
      {
        Istat: "96060",
        Comune: "San Paolo Cervo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13812",
        CodFisco: "I074",
        Abitanti: "147",
      },
      {
        Istat: "5101",
        Comune: "San Paolo Solbrito",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "I076",
        Abitanti: "1231",
      },
      {
        Istat: "3135",
        Comune: "San Pietro Mosezzo",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "I116",
        Abitanti: "1992",
      },
      {
        Istat: "1250",
        Comune: "San Pietro Val Lemina",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "I090",
        Abitanti: "1471",
      },
      {
        Istat: "1251",
        Comune: "San Ponso",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "I126",
        Abitanti: "285",
      },
      {
        Istat: "1252",
        Comune: "San Raffaele Cimena",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "I137",
        Abitanti: "3122",
      },
      {
        Istat: "6154",
        Comune: "San Salvatore Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15046",
        CodFisco: "I144",
        Abitanti: "4478",
      },
      {
        Istat: "6155",
        Comune: "San Sebastiano Curone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15056",
        CodFisco: "I150",
        Abitanti: "632",
      },
      {
        Istat: "1253",
        Comune: "San Sebastiano da Po",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10020",
        CodFisco: "I152",
        Abitanti: "1920",
      },
      {
        Istat: "1254",
        Comune: "San Secondo di Pinerolo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "I154",
        Abitanti: "3615",
      },
      {
        Istat: "96059",
        Comune: "Sandigliano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13876",
        CodFisco: "H821",
        Abitanti: "2731",
      },
      {
        Istat: "4208",
        Comune: "Sanfrè",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "H851",
        Abitanti: "2910",
      },
      {
        Istat: "4209",
        Comune: "Sanfront",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "H852",
        Abitanti: "2598",
      },
      {
        Istat: "1241",
        Comune: "Sangano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "H855",
        Abitanti: "3777",
      },
      {
        Istat: "103062",
        Comune: "Santa Maria Maggiore",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28857",
        CodFisco: "I249",
        Abitanti: "1271",
      },
      {
        Istat: "4212",
        Comune: "Santa Vittoria d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12069",
        CodFisco: "I316",
        Abitanti: "2754",
      },
      {
        Istat: "6156",
        Comune: "Sant'Agata Fossili",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "I190",
        Abitanti: "449",
      },
      {
        Istat: "4211",
        Comune: "Sant'Albano Stura",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "I210",
        Abitanti: "2394",
      },
      {
        Istat: "1255",
        Comune: "Sant'Ambrogio di Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10057",
        CodFisco: "I258",
        Abitanti: "4843",
      },
      {
        Istat: "1256",
        Comune: "Sant'Antonino di Susa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "I296",
        Abitanti: "4407",
      },
      {
        Istat: "1257",
        Comune: "Santena",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10026",
        CodFisco: "I327",
        Abitanti: "10740",
      },
      {
        Istat: "2133",
        Comune: "Santhià",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13048",
        CodFisco: "I337",
        Abitanti: "8994",
      },
      {
        Istat: "4213",
        Comune: "Santo Stefano Belbo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "12058",
        CodFisco: "I367",
        Abitanti: "4109",
      },
      {
        Istat: "4214",
        Comune: "Santo Stefano Roero",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12040",
        CodFisco: "I372",
        Abitanti: "1432",
      },
      {
        Istat: "6157",
        Comune: "Sardigliano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "I429",
        Abitanti: "452",
      },
      {
        Istat: "6158",
        Comune: "Sarezzano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "I432",
        Abitanti: "1205",
      },
      {
        Istat: "1258",
        Comune: "Sauze di Cesana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10054",
        CodFisco: "I465",
        Abitanti: "252",
      },
      {
        Istat: "1259",
        Comune: "Sauze d'Oulx",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "I466",
        Abitanti: "1171",
      },
      {
        Istat: "4215",
        Comune: "Savigliano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12038",
        CodFisco: "I470",
        Abitanti: "21065",
      },
      {
        Istat: "4216",
        Comune: "Scagnello",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "I484",
        Abitanti: "210",
      },
      {
        Istat: "1260",
        Comune: "Scalenghe",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "I490",
        Abitanti: "3311",
      },
      {
        Istat: "1261",
        Comune: "Scarmagno",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "I511",
        Abitanti: "811",
      },
      {
        Istat: "4217",
        Comune: "Scarnafigi",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12030",
        CodFisco: "I512",
        Abitanti: "2092",
      },
      {
        Istat: "1262",
        Comune: "Sciolze",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "I539",
        Abitanti: "1515",
      },
      {
        Istat: "2134",
        Comune: "Scopa",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13027",
        CodFisco: "I544",
        Abitanti: "406",
      },
      {
        Istat: "2135",
        Comune: "Scopello",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13028",
        CodFisco: "I545",
        Abitanti: "418",
      },
      {
        Istat: "5103",
        Comune: "Scurzolengo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "I555",
        Abitanti: "610",
      },
      {
        Istat: "96061",
        Comune: "Selve Marcone",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13841",
        CodFisco: "I596",
        Abitanti: "100",
      },
      {
        Istat: "103063",
        Comune: "Seppiana",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28843",
        CodFisco: "I619",
        Abitanti: "159",
      },
      {
        Istat: "5104",
        Comune: "Serole",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14050",
        CodFisco: "I637",
        Abitanti: "141",
      },
      {
        Istat: "4218",
        Comune: "Serralunga d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "I646",
        Abitanti: "535",
      },
      {
        Istat: "6159",
        Comune: "Serralunga di Crea",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "I645",
        Abitanti: "578",
      },
      {
        Istat: "4219",
        Comune: "Serravalle Langhe",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "I659",
        Abitanti: "326",
      },
      {
        Istat: "6160",
        Comune: "Serravalle Scrivia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15069",
        CodFisco: "I657",
        Abitanti: "6445",
      },
      {
        Istat: "2137",
        Comune: "Serravalle Sesia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13037",
        CodFisco: "I663",
        Abitanti: "5162",
      },
      {
        Istat: "5105",
        Comune: "Sessame",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14058",
        CodFisco: "I678",
        Abitanti: "286",
      },
      {
        Istat: "1263",
        Comune: "Sestriere",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10058",
        CodFisco: "I692",
        Abitanti: "889",
      },
      {
        Istat: "5106",
        Comune: "Settime",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "I698",
        Abitanti: "603",
      },
      {
        Istat: "1264",
        Comune: "Settimo Rottaro",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "I701",
        Abitanti: "523",
      },
      {
        Istat: "1265",
        Comune: "Settimo Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10036",
        CodFisco: "I703",
        Abitanti: "47790",
      },
      {
        Istat: "1266",
        Comune: "Settimo Vittone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "I702",
        Abitanti: "1576",
      },
      {
        Istat: "6161",
        Comune: "Sezzadio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15079",
        CodFisco: "I711",
        Abitanti: "1295",
      },
      {
        Istat: "3138",
        Comune: "Sillavengo",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28064",
        CodFisco: "I736",
        Abitanti: "587",
      },
      {
        Istat: "6162",
        Comune: "Silvano d'Orba",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "I738",
        Abitanti: "2042",
      },
      {
        Istat: "4220",
        Comune: "Sinio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "I750",
        Abitanti: "525",
      },
      {
        Istat: "3139",
        Comune: "Sizzano",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28070",
        CodFisco: "I767",
        Abitanti: "1468",
      },
      {
        Istat: "5107",
        Comune: "Soglio",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "I781",
        Abitanti: "155",
      },
      {
        Istat: "6163",
        Comune: "Solero",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15029",
        CodFisco: "I798",
        Abitanti: "1710",
      },
      {
        Istat: "6164",
        Comune: "Solonghello",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "I808",
        Abitanti: "232",
      },
      {
        Istat: "4221",
        Comune: "Somano",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12060",
        CodFisco: "I817",
        Abitanti: "370",
      },
      {
        Istat: "4222",
        Comune: "Sommariva del Bosco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12048",
        CodFisco: "I822",
        Abitanti: "6426",
      },
      {
        Istat: "4223",
        Comune: "Sommariva Perno",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12040",
        CodFisco: "I823",
        Abitanti: "2847",
      },
      {
        Istat: "96062",
        Comune: "Soprana",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13834",
        CodFisco: "I835",
        Abitanti: "773",
      },
      {
        Istat: "96063",
        Comune: "Sordevolo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13817",
        CodFisco: "I847",
        Abitanti: "1334",
      },
      {
        Istat: "3140",
        Comune: "Soriso",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "I857",
        Abitanti: "777",
      },
      {
        Istat: "96064",
        Comune: "Sostegno",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13868",
        CodFisco: "I868",
        Abitanti: "761",
      },
      {
        Istat: "3141",
        Comune: "Sozzago",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "I880",
        Abitanti: "1062",
      },
      {
        Istat: "1267",
        Comune: "Sparone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "I886",
        Abitanti: "1115",
      },
      {
        Istat: "6165",
        Comune: "Spigno Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15018",
        CodFisco: "I901",
        Abitanti: "1148",
      },
      {
        Istat: "6166",
        Comune: "Spineto Scrivia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "I911",
        Abitanti: "379",
      },
      {
        Istat: "6167",
        Comune: "Stazzano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "I941",
        Abitanti: "2424",
      },
      {
        Istat: "1268",
        Comune: "Strambinello",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "I969",
        Abitanti: "273",
      },
      {
        Istat: "1269",
        Comune: "Strambino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10019",
        CodFisco: "I970",
        Abitanti: "6437",
      },
      {
        Istat: "103064",
        Comune: "Stresa",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28838",
        CodFisco: "I976",
        Abitanti: "5226",
      },
      {
        Istat: "6168",
        Comune: "Strevi",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15019",
        CodFisco: "I977",
        Abitanti: "2083",
      },
      {
        Istat: "96065",
        Comune: "Strona",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13823",
        CodFisco: "I980",
        Abitanti: "1166",
      },
      {
        Istat: "2142",
        Comune: "Stroppiana",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13010",
        CodFisco: "I984",
        Abitanti: "1262",
      },
      {
        Istat: "4224",
        Comune: "Stroppo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "I985",
        Abitanti: "114",
      },
      {
        Istat: "3143",
        Comune: "Suno",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28019",
        CodFisco: "L007",
        Abitanti: "2840",
      },
      {
        Istat: "1270",
        Comune: "Susa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10059",
        CodFisco: "L013",
        Abitanti: "6727",
      },
      {
        Istat: "6169",
        Comune: "Tagliolo Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15070",
        CodFisco: "L027",
        Abitanti: "1571",
      },
      {
        Istat: "4225",
        Comune: "Tarantasca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "L048",
        Abitanti: "2042",
      },
      {
        Istat: "6170",
        Comune: "Tassarolo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "L059",
        Abitanti: "647",
      },
      {
        Istat: "1271",
        Comune: "Tavagnasco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10010",
        CodFisco: "L066",
        Abitanti: "807",
      },
      {
        Istat: "96066",
        Comune: "Tavigliano",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13811",
        CodFisco: "L075",
        Abitanti: "973",
      },
      {
        Istat: "3144",
        Comune: "Terdobbiate",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28070",
        CodFisco: "L104",
        Abitanti: "511",
      },
      {
        Istat: "96067",
        Comune: "Ternengo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13844",
        CodFisco: "L116",
        Abitanti: "300",
      },
      {
        Istat: "6171",
        Comune: "Terruggia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "L139",
        Abitanti: "898",
      },
      {
        Istat: "6172",
        Comune: "Terzo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "L143",
        Abitanti: "906",
      },
      {
        Istat: "6173",
        Comune: "Ticineto",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15040",
        CodFisco: "L165",
        Abitanti: "1399",
      },
      {
        Istat: "5108",
        Comune: "Tigliole",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14016",
        CodFisco: "L168",
        Abitanti: "1714",
      },
      {
        Istat: "103065",
        Comune: "Toceno",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28858",
        CodFisco: "L187",
        Abitanti: "779",
      },
      {
        Istat: "96068",
        Comune: "Tollegno",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13818",
        CodFisco: "L193",
        Abitanti: "2667",
      },
      {
        Istat: "5109",
        Comune: "Tonco",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14039",
        CodFisco: "L203",
        Abitanti: "911",
      },
      {
        Istat: "5110",
        Comune: "Tonengo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14023",
        CodFisco: "L204",
        Abitanti: "200",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10121",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10122",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10123",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10124",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10125",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10126",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10127",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10128",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10129",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10130",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10131",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10132",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10133",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10134",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10135",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10136",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10137",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10138",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10139",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10140",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10141",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10142",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10143",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10144",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10145",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10146",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10147",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10148",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10149",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10150",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10151",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10152",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10153",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10154",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10155",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "1272",
        Comune: "Torino",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10156",
        CodFisco: "L219",
        Abitanti: "907563",
      },
      {
        Istat: "3146",
        Comune: "Tornaco",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28070",
        CodFisco: "L223",
        Abitanti: "864",
      },
      {
        Istat: "1273",
        Comune: "Torrazza Piemonte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10037",
        CodFisco: "L238",
        Abitanti: "2748",
      },
      {
        Istat: "96069",
        Comune: "Torrazzo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13884",
        CodFisco: "L239",
        Abitanti: "227",
      },
      {
        Istat: "4226",
        Comune: "Torre Bormida",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "L252",
        Abitanti: "210",
      },
      {
        Istat: "1274",
        Comune: "Torre Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10010",
        CodFisco: "L247",
        Abitanti: "591",
      },
      {
        Istat: "4227",
        Comune: "Torre Mondovì",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "L241",
        Abitanti: "511",
      },
      {
        Istat: "1275",
        Comune: "Torre Pellice",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10066",
        CodFisco: "L277",
        Abitanti: "4735",
      },
      {
        Istat: "4228",
        Comune: "Torre San Giorgio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "L278",
        Abitanti: "721",
      },
      {
        Istat: "4229",
        Comune: "Torresina",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "L281",
        Abitanti: "62",
      },
      {
        Istat: "6174",
        Comune: "Tortona",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15057",
        CodFisco: "L304",
        Abitanti: "27706",
      },
      {
        Istat: "1276",
        Comune: "Trana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "L327",
        Abitanti: "3874",
      },
      {
        Istat: "103066",
        Comune: "Trarego Viggiona",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28826",
        CodFisco: "L333",
        Abitanti: "398",
      },
      {
        Istat: "103067",
        Comune: "Trasquera",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28868",
        CodFisco: "L336",
        Abitanti: "222",
      },
      {
        Istat: "1277",
        Comune: "Trausella",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "L338",
        Abitanti: "135",
      },
      {
        Istat: "1278",
        Comune: "Traversella",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "L345",
        Abitanti: "350",
      },
      {
        Istat: "1279",
        Comune: "Traves",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "L340",
        Abitanti: "564",
      },
      {
        Istat: "3149",
        Comune: "Trecate",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28069",
        CodFisco: "L356",
        Abitanti: "20190",
      },
      {
        Istat: "4230",
        Comune: "Treiso",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "L367",
        Abitanti: "828",
      },
      {
        Istat: "6175",
        Comune: "Treville",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "L403",
        Abitanti: "279",
      },
      {
        Istat: "4231",
        Comune: "Trezzo Tinella",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12050",
        CodFisco: "L410",
        Abitanti: "359",
      },
      {
        Istat: "2147",
        Comune: "Tricerro",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13038",
        CodFisco: "L420",
        Abitanti: "723",
      },
      {
        Istat: "4232",
        Comune: "Trinità",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12049",
        CodFisco: "L427",
        Abitanti: "2181",
      },
      {
        Istat: "2148",
        Comune: "Trino",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13039",
        CodFisco: "L429",
        Abitanti: "7627",
      },
      {
        Istat: "6176",
        Comune: "Trisobbio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15070",
        CodFisco: "L432",
        Abitanti: "664",
      },
      {
        Istat: "96070",
        Comune: "Trivero",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13835",
        CodFisco: "L436",
        Abitanti: "6204",
      },
      {
        Istat: "1280",
        Comune: "Trofarello",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10028",
        CodFisco: "L445",
        Abitanti: "11018",
      },
      {
        Istat: "103068",
        Comune: "Trontano",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28859",
        CodFisco: "L450",
        Abitanti: "1693",
      },
      {
        Istat: "2150",
        Comune: "Tronzano Vercellese",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13049",
        CodFisco: "L451",
        Abitanti: "3598",
      },
      {
        Istat: "1281",
        Comune: "Usseaux",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "L515",
        Abitanti: "191",
      },
      {
        Istat: "1282",
        Comune: "Usseglio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "L516",
        Abitanti: "224",
      },
      {
        Istat: "5111",
        Comune: "Vaglio Serra",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14049",
        CodFisco: "L531",
        Abitanti: "285",
      },
      {
        Istat: "1283",
        Comune: "Vaie",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "L538",
        Abitanti: "1472",
      },
      {
        Istat: "1284",
        Comune: "Val della Torre",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "L555",
        Abitanti: "3850",
      },
      {
        Istat: "96071",
        Comune: "Valdengo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13855",
        CodFisco: "L556",
        Abitanti: "2518",
      },
      {
        Istat: "4233",
        Comune: "Valdieri",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "L558",
        Abitanti: "949",
      },
      {
        Istat: "2152",
        Comune: "Valduggia",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13018",
        CodFisco: "L566",
        Abitanti: "2161",
      },
      {
        Istat: "6177",
        Comune: "Valenza",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15048",
        CodFisco: "L570",
        Abitanti: "20169",
      },
      {
        Istat: "5112",
        Comune: "Valfenera",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14017",
        CodFisco: "L574",
        Abitanti: "2533",
      },
      {
        Istat: "1285",
        Comune: "Valgioie",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10094",
        CodFisco: "L578",
        Abitanti: "952",
      },
      {
        Istat: "4234",
        Comune: "Valgrana",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "L580",
        Abitanti: "805",
      },
      {
        Istat: "96072",
        Comune: "Vallanzengo",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13847",
        CodFisco: "L586",
        Abitanti: "238",
      },
      {
        Istat: "96073",
        Comune: "Valle Mosso",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13825",
        CodFisco: "L606",
        Abitanti: "3640",
      },
      {
        Istat: "96074",
        Comune: "Valle San Nicolao",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13847",
        CodFisco: "L620",
        Abitanti: "1126",
      },
      {
        Istat: "1286",
        Comune: "Vallo Torinese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "L629",
        Abitanti: "804",
      },
      {
        Istat: "4235",
        Comune: "Valloriate",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "L631",
        Abitanti: "126",
      },
      {
        Istat: "6178",
        Comune: "Valmacca",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15040",
        CodFisco: "L633",
        Abitanti: "1060",
      },
      {
        Istat: "4236",
        Comune: "Valmala",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "L636",
        Abitanti: "66",
      },
      {
        Istat: "1287",
        Comune: "Valperga",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10087",
        CodFisco: "L644",
        Abitanti: "3176",
      },
      {
        Istat: "1288",
        Comune: "Valprato Soana",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "124",
        CAP: "10080",
        CodFisco: "B510",
        Abitanti: "113",
      },
      {
        Istat: "103069",
        Comune: "Valstrona",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28897",
        CodFisco: "L651",
        Abitanti: "1254",
      },
      {
        Istat: "103070",
        Comune: "Vanzone con San Carlo",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28879",
        CodFisco: "L666",
        Abitanti: "445",
      },
      {
        Istat: "3153",
        Comune: "Vaprio d'Agogna",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28010",
        CodFisco: "L668",
        Abitanti: "1022",
      },
      {
        Istat: "2156",
        Comune: "Varallo",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13019",
        CodFisco: "L669",
        Abitanti: "7593",
      },
      {
        Istat: "3154",
        Comune: "Varallo Pombia",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28040",
        CodFisco: "L670",
        Abitanti: "4999",
      },
      {
        Istat: "1289",
        Comune: "Varisella",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "L685",
        Abitanti: "829",
      },
      {
        Istat: "103071",
        Comune: "Varzo",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28868",
        CodFisco: "L691",
        Abitanti: "2137",
      },
      {
        Istat: "1290",
        Comune: "Vauda Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "L698",
        Abitanti: "1496",
      },
      {
        Istat: "96075",
        Comune: "Veglio",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13824",
        CodFisco: "L712",
        Abitanti: "591",
      },
      {
        Istat: "1292",
        Comune: "Venaria Reale",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10078",
        CodFisco: "L727",
        Abitanti: "34859",
      },
      {
        Istat: "4237",
        Comune: "Venasca",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12020",
        CodFisco: "L729",
        Abitanti: "1484",
      },
      {
        Istat: "1291",
        Comune: "Venaus",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "122",
        CAP: "10050",
        CodFisco: "L726",
        Abitanti: "959",
      },
      {
        Istat: "103072",
        Comune: "Verbania",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28921",
        CodFisco: "L746",
        Abitanti: "31243",
      },
      {
        Istat: "103072",
        Comune: "Verbania",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28922",
        CodFisco: "L746",
        Abitanti: "31243",
      },
      {
        Istat: "103072",
        Comune: "Verbania",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28923",
        CodFisco: "L746",
        Abitanti: "31243",
      },
      {
        Istat: "103072",
        Comune: "Verbania",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28924",
        CodFisco: "L746",
        Abitanti: "31243",
      },
      {
        Istat: "103072",
        Comune: "Verbania",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28925",
        CodFisco: "L746",
        Abitanti: "31243",
      },
      {
        Istat: "2158",
        Comune: "Vercelli",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13100",
        CodFisco: "L750",
        Abitanti: "46979",
      },
      {
        Istat: "4238",
        Comune: "Verduno",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12060",
        CodFisco: "L758",
        Abitanti: "564",
      },
      {
        Istat: "4239",
        Comune: "Vernante",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12019",
        CodFisco: "L771",
        Abitanti: "1251",
      },
      {
        Istat: "1293",
        Comune: "Verolengo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10038",
        CodFisco: "L779",
        Abitanti: "5037",
      },
      {
        Istat: "96076",
        Comune: "Verrone",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13871",
        CodFisco: "L785",
        Abitanti: "1213",
      },
      {
        Istat: "1294",
        Comune: "Verrua Savoia",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "10020",
        CodFisco: "L787",
        Abitanti: "1472",
      },
      {
        Istat: "3157",
        Comune: "Veruno",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "322",
        CAP: "28010",
        CodFisco: "L798",
        Abitanti: "1874",
      },
      {
        Istat: "4240",
        Comune: "Verzuolo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "175",
        CAP: "12039",
        CodFisco: "L804",
        Abitanti: "6507",
      },
      {
        Istat: "5113",
        Comune: "Vesime",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "14059",
        CodFisco: "L807",
        Abitanti: "661",
      },
      {
        Istat: "3158",
        Comune: "Vespolate",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28079",
        CodFisco: "L808",
        Abitanti: "2085",
      },
      {
        Istat: "1295",
        Comune: "Vestignè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10030",
        CodFisco: "L811",
        Abitanti: "834",
      },
      {
        Istat: "4241",
        Comune: "Vezza d'Alba",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "173",
        CAP: "12040",
        CodFisco: "L817",
        Abitanti: "2230",
      },
      {
        Istat: "5114",
        Comune: "Viale",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14010",
        CodFisco: "L829",
        Abitanti: "264",
      },
      {
        Istat: "1296",
        Comune: "Vialfrè",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10090",
        CodFisco: "L830",
        Abitanti: "247",
      },
      {
        Istat: "5115",
        Comune: "Viarigi",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14030",
        CodFisco: "L834",
        Abitanti: "971",
      },
      {
        Istat: "1297",
        Comune: "Vico Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "L548",
        Abitanti: "885",
      },
      {
        Istat: "4242",
        Comune: "Vicoforte",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12080",
        CodFisco: "L841",
        Abitanti: "3266",
      },
      {
        Istat: "3159",
        Comune: "Vicolungo",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "321",
        CAP: "28060",
        CodFisco: "L847",
        Abitanti: "898",
      },
      {
        Istat: "1298",
        Comune: "Vidracco",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "L857",
        Abitanti: "499",
      },
      {
        Istat: "103073",
        Comune: "Viganella",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28841",
        CodFisco: "L864",
        Abitanti: "204",
      },
      {
        Istat: "96077",
        Comune: "Vigliano Biellese",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13856",
        CodFisco: "L880",
        Abitanti: "8343",
      },
      {
        Istat: "5116",
        Comune: "Vigliano d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "L879",
        Abitanti: "880",
      },
      {
        Istat: "6179",
        Comune: "Vignale Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15049",
        CodFisco: "L881",
        Abitanti: "1084",
      },
      {
        Istat: "6180",
        Comune: "Vignole Borbera",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "143",
        CAP: "15060",
        CodFisco: "L887",
        Abitanti: "2279",
      },
      {
        Istat: "4243",
        Comune: "Vignolo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "L888",
        Abitanti: "2467",
      },
      {
        Istat: "103074",
        Comune: "Vignone",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "323",
        CAP: "28819",
        CodFisco: "L889",
        Abitanti: "1219",
      },
      {
        Istat: "1299",
        Comune: "Vigone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10067",
        CodFisco: "L898",
        Abitanti: "5268",
      },
      {
        Istat: "6181",
        Comune: "Viguzzolo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15058",
        CodFisco: "L904",
        Abitanti: "3261",
      },
      {
        Istat: "96078",
        Comune: "Villa del Bosco",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13868",
        CodFisco: "L933",
        Abitanti: "379",
      },
      {
        Istat: "5119",
        Comune: "Villa San Secondo",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14020",
        CodFisco: "M019",
        Abitanti: "393",
      },
      {
        Istat: "6182",
        Comune: "Villadeati",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "15020",
        CodFisco: "L931",
        Abitanti: "513",
      },
      {
        Istat: "103075",
        Comune: "Villadossola",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28844",
        CodFisco: "L906",
        Abitanti: "6912",
      },
      {
        Istat: "4244",
        Comune: "Villafalletto",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "L942",
        Abitanti: "2925",
      },
      {
        Istat: "5117",
        Comune: "Villafranca d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14018",
        CodFisco: "L945",
        Abitanti: "3198",
      },
      {
        Istat: "1300",
        Comune: "Villafranca Piemonte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10068",
        CodFisco: "L948",
        Abitanti: "4845",
      },
      {
        Istat: "6183",
        Comune: "Villalvernia",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "L963",
        Abitanti: "995",
      },
      {
        Istat: "6184",
        Comune: "Villamiroglio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15020",
        CodFisco: "L970",
        Abitanti: "338",
      },
      {
        Istat: "96079",
        Comune: "Villanova Biellese",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13877",
        CodFisco: "L978",
        Abitanti: "191",
      },
      {
        Istat: "1301",
        Comune: "Villanova Canavese",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10070",
        CodFisco: "L982",
        Abitanti: "1141",
      },
      {
        Istat: "5118",
        Comune: "Villanova d'Asti",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14019",
        CodFisco: "L984",
        Abitanti: "5767",
      },
      {
        Istat: "4245",
        Comune: "Villanova Mondovì",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12089",
        CodFisco: "L974",
        Abitanti: "5838",
      },
      {
        Istat: "6185",
        Comune: "Villanova Monferrato",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "142",
        CAP: "15030",
        CodFisco: "L972",
        Abitanti: "1861",
      },
      {
        Istat: "4246",
        Comune: "Villanova Solaro",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "172",
        CAP: "12030",
        CodFisco: "L990",
        Abitanti: "783",
      },
      {
        Istat: "1303",
        Comune: "Villar Dora",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "L999",
        Abitanti: "3043",
      },
      {
        Istat: "1305",
        Comune: "Villar Focchiardo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10050",
        CodFisco: "M007",
        Abitanti: "2079",
      },
      {
        Istat: "1306",
        Comune: "Villar Pellice",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10060",
        CodFisco: "M013",
        Abitanti: "1124",
      },
      {
        Istat: "1307",
        Comune: "Villar Perosa",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "121",
        CAP: "10069",
        CodFisco: "M014",
        Abitanti: "4184",
      },
      {
        Istat: "4247",
        Comune: "Villar San Costanzo",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "M015",
        Abitanti: "1513",
      },
      {
        Istat: "1302",
        Comune: "Villarbasse",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10090",
        CodFisco: "M002",
        Abitanti: "3334",
      },
      {
        Istat: "2163",
        Comune: "Villarboit",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13030",
        CodFisco: "M003",
        Abitanti: "491",
      },
      {
        Istat: "1304",
        Comune: "Villareggia",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "10030",
        CodFisco: "M004",
        Abitanti: "1015",
      },
      {
        Istat: "6186",
        Comune: "Villaromagnano",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "M009",
        Abitanti: "730",
      },
      {
        Istat: "1308",
        Comune: "Villastellone",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10029",
        CodFisco: "M027",
        Abitanti: "4898",
      },
      {
        Istat: "2164",
        Comune: "Villata",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13010",
        CodFisco: "M028",
        Abitanti: "1631",
      },
      {
        Istat: "103076",
        Comune: "Villette",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28856",
        CodFisco: "M042",
        Abitanti: "264",
      },
      {
        Istat: "4248",
        Comune: "Vinadio",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12010",
        CodFisco: "M055",
        Abitanti: "701",
      },
      {
        Istat: "5120",
        Comune: "Vinchio",
        Provincia: "AT",
        Regione: "PIE",
        Prefisso: "141",
        CAP: "14040",
        CodFisco: "M058",
        Abitanti: "667",
      },
      {
        Istat: "1309",
        Comune: "Vinovo",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10048",
        CodFisco: "M060",
        Abitanti: "14200",
      },
      {
        Istat: "3164",
        Comune: "Vinzaglio",
        Provincia: "NO",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "28060",
        CodFisco: "M062",
        Abitanti: "591",
      },
      {
        Istat: "4249",
        Comune: "Viola",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "174",
        CAP: "12070",
        CodFisco: "M063",
        Abitanti: "429",
      },
      {
        Istat: "1310",
        Comune: "Virle Piemonte",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10060",
        CodFisco: "M069",
        Abitanti: "1212",
      },
      {
        Istat: "1311",
        Comune: "Vische",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10030",
        CodFisco: "M071",
        Abitanti: "1342",
      },
      {
        Istat: "6187",
        Comune: "Visone",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "144",
        CAP: "15010",
        CodFisco: "M077",
        Abitanti: "1285",
      },
      {
        Istat: "1312",
        Comune: "Vistrorio",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "125",
        CAP: "10080",
        CodFisco: "M080",
        Abitanti: "547",
      },
      {
        Istat: "1313",
        Comune: "Viù",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "123",
        CAP: "10070",
        CodFisco: "M094",
        Abitanti: "1141",
      },
      {
        Istat: "96080",
        Comune: "Viverone",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "161",
        CAP: "13886",
        CodFisco: "M098",
        Abitanti: "1435",
      },
      {
        Istat: "2166",
        Comune: "Vocca",
        Provincia: "VC",
        Regione: "PIE",
        Prefisso: "163",
        CAP: "13020",
        CodFisco: "M106",
        Abitanti: "172",
      },
      {
        Istat: "103077",
        Comune: "Vogogna",
        Provincia: "VB",
        Regione: "PIE",
        Prefisso: "324",
        CAP: "28805",
        CodFisco: "M111",
        Abitanti: "1785",
      },
      {
        Istat: "6188",
        Comune: "Volpedo",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15059",
        CodFisco: "M120",
        Abitanti: "1261",
      },
      {
        Istat: "6189",
        Comune: "Volpeglino",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "131",
        CAP: "15050",
        CodFisco: "M121",
        Abitanti: "172",
      },
      {
        Istat: "1314",
        Comune: "Volpiano",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10088",
        CodFisco: "M122",
        Abitanti: "15097",
      },
      {
        Istat: "6190",
        Comune: "Voltaggio",
        Provincia: "AL",
        Regione: "PIE",
        Prefisso: "10",
        CAP: "15060",
        CodFisco: "M123",
        Abitanti: "776",
      },
      {
        Istat: "1315",
        Comune: "Volvera",
        Provincia: "TO",
        Regione: "PIE",
        Prefisso: "11",
        CAP: "10040",
        CodFisco: "M133",
        Abitanti: "8707",
      },
      {
        Istat: "4250",
        Comune: "Vottignasco",
        Provincia: "CN",
        Regione: "PIE",
        Prefisso: "171",
        CAP: "12020",
        CodFisco: "M136",
        Abitanti: "549",
      },
      {
        Istat: "96081",
        Comune: "Zimone",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13887",
        CodFisco: "M179",
        Abitanti: "425",
      },
      {
        Istat: "96082",
        Comune: "Zubiena",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13888",
        CodFisco: "M196",
        Abitanti: "1257",
      },
      {
        Istat: "96083",
        Comune: "Zumaglia",
        Provincia: "BI",
        Regione: "PIE",
        Prefisso: "15",
        CAP: "13848",
        CodFisco: "M201",
        Abitanti: "1152",
      },
      {
        Istat: "71001",
        Comune: "Accadia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71021",
        CodFisco: "A015",
        Abitanti: "2481",
      },
      {
        Istat: "75001",
        Comune: "Acquarica del Capo",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "A042",
        Abitanti: "4951",
      },
      {
        Istat: "72001",
        Comune: "Acquaviva delle Fonti",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70021",
        CodFisco: "A048",
        Abitanti: "21096",
      },
      {
        Istat: "72002",
        Comune: "Adelfia",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "A055",
        Abitanti: "17306",
      },
      {
        Istat: "72003",
        Comune: "Alberobello",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70011",
        CodFisco: "A149",
        Abitanti: "11013",
      },
      {
        Istat: "71002",
        Comune: "Alberona",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71031",
        CodFisco: "A150",
        Abitanti: "1012",
      },
      {
        Istat: "75002",
        Comune: "Alessano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73031",
        CodFisco: "A184",
        Abitanti: "6552",
      },
      {
        Istat: "75003",
        Comune: "Alezio",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73011",
        CodFisco: "A185",
        Abitanti: "5547",
      },
      {
        Istat: "75004",
        Comune: "Alliste",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "A208",
        Abitanti: "6719",
      },
      {
        Istat: "72004",
        Comune: "Altamura",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70022",
        CodFisco: "A225",
        Abitanti: "69665",
      },
      {
        Istat: "75005",
        Comune: "Andrano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73032",
        CodFisco: "A281",
        Abitanti: "5027",
      },
      {
        Istat: "110001",
        Comune: "Andria",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76123",
        CodFisco: "A285",
        Abitanti: "100086",
      },
      {
        Istat: "71003",
        Comune: "Anzano di Puglia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71020",
        CodFisco: "A320",
        Abitanti: "1759",
      },
      {
        Istat: "71004",
        Comune: "Apricena",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71011",
        CodFisco: "A339",
        Abitanti: "13694",
      },
      {
        Istat: "75006",
        Comune: "Aradeo",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73040",
        CodFisco: "A350",
        Abitanti: "9827",
      },
      {
        Istat: "75007",
        Comune: "Arnesano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "A425",
        Abitanti: "3981",
      },
      {
        Istat: "71005",
        Comune: "Ascoli Satriano",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71022",
        CodFisco: "A463",
        Abitanti: "6390",
      },
      {
        Istat: "73001",
        Comune: "Avetrana",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "A514",
        Abitanti: "7079",
      },
      {
        Istat: "75008",
        Comune: "Bagnolo del Salento",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "A572",
        Abitanti: "1880",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70121",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70122",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70123",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70124",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70125",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70126",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70127",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70128",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70129",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70130",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70131",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "72006",
        Comune: "Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70132",
        CodFisco: "A662",
        Abitanti: "320475",
      },
      {
        Istat: "110002",
        Comune: "Barletta",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76121",
        CodFisco: "A669",
        Abitanti: "94459",
      },
      {
        Istat: "71006",
        Comune: "Biccari",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71032",
        CodFisco: "A854",
        Abitanti: "2893",
      },
      {
        Istat: "72008",
        Comune: "Binetto",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70020",
        CodFisco: "A874",
        Abitanti: "2133",
      },
      {
        Istat: "110003",
        Comune: "Bisceglie",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "76011",
        CodFisco: "A883",
        Abitanti: "54847",
      },
      {
        Istat: "72010",
        Comune: "Bitetto",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70020",
        CodFisco: "A892",
        Abitanti: "11717",
      },
      {
        Istat: "72011",
        Comune: "Bitonto",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70032",
        CodFisco: "A893",
        Abitanti: "56462",
      },
      {
        Istat: "72012",
        Comune: "Bitritto",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70020",
        CodFisco: "A894",
        Abitanti: "10881",
      },
      {
        Istat: "75009",
        Comune: "Botrugno",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "B086",
        Abitanti: "2891",
      },
      {
        Istat: "71007",
        Comune: "Bovino",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71023",
        CodFisco: "B104",
        Abitanti: "3574",
      },
      {
        Istat: "74001",
        Comune: "Brindisi",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72100",
        CodFisco: "B180",
        Abitanti: "89780",
      },
      {
        Istat: "71008",
        Comune: "Cagnano Varano",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71010",
        CodFisco: "B357",
        Abitanti: "7663",
      },
      {
        Istat: "75010",
        Comune: "Calimera",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73021",
        CodFisco: "B413",
        Abitanti: "7281",
      },
      {
        Istat: "75011",
        Comune: "Campi Salentina",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73012",
        CodFisco: "B506",
        Abitanti: "10820",
      },
      {
        Istat: "71009",
        Comune: "Candela",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71024",
        CodFisco: "B584",
        Abitanti: "2753",
      },
      {
        Istat: "75012",
        Comune: "Cannole",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "B616",
        Abitanti: "1758",
      },
      {
        Istat: "110004",
        Comune: "Canosa di Puglia",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76012",
        CodFisco: "B619",
        Abitanti: "31115",
      },
      {
        Istat: "75013",
        Comune: "Caprarica di Lecce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "B690",
        Abitanti: "2595",
      },
      {
        Istat: "72014",
        Comune: "Capurso",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "B716",
        Abitanti: "15411",
      },
      {
        Istat: "71010",
        Comune: "Carapelle",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71041",
        CodFisco: "B724",
        Abitanti: "6527",
      },
      {
        Istat: "71011",
        Comune: "Carlantino",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71030",
        CodFisco: "B784",
        Abitanti: "1079",
      },
      {
        Istat: "75014",
        Comune: "Carmiano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73041",
        CodFisco: "B792",
        Abitanti: "12366",
      },
      {
        Istat: "73002",
        Comune: "Carosino",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74021",
        CodFisco: "B808",
        Abitanti: "6776",
      },
      {
        Istat: "74002",
        Comune: "Carovigno",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72012",
        CodFisco: "B809",
        Abitanti: "16307",
      },
      {
        Istat: "75015",
        Comune: "Carpignano Salentino",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "B822",
        Abitanti: "3853",
      },
      {
        Istat: "71012",
        Comune: "Carpino",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71010",
        CodFisco: "B829",
        Abitanti: "4380",
      },
      {
        Istat: "71013",
        Comune: "Casalnuovo Monterotaro",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71033",
        CodFisco: "B904",
        Abitanti: "1697",
      },
      {
        Istat: "71014",
        Comune: "Casalvecchio di Puglia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71030",
        CodFisco: "B917",
        Abitanti: "1978",
      },
      {
        Istat: "72015",
        Comune: "Casamassima",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "B923",
        Abitanti: "19184",
      },
      {
        Istat: "75016",
        Comune: "Casarano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73042",
        CodFisco: "B936",
        Abitanti: "20632",
      },
      {
        Istat: "72016",
        Comune: "Cassano delle Murge",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70020",
        CodFisco: "B998",
        Abitanti: "13701",
      },
      {
        Istat: "72017",
        Comune: "Castellana Grotte",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70013",
        CodFisco: "C134",
        Abitanti: "19435",
      },
      {
        Istat: "73003",
        Comune: "Castellaneta",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74011",
        CodFisco: "C136",
        Abitanti: "17144",
      },
      {
        Istat: "71015",
        Comune: "Castelluccio dei Sauri",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71025",
        CodFisco: "C198",
        Abitanti: "2144",
      },
      {
        Istat: "71016",
        Comune: "Castelluccio Valmaggiore",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71020",
        CodFisco: "C202",
        Abitanti: "1370",
      },
      {
        Istat: "71017",
        Comune: "Castelnuovo della Daunia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71034",
        CodFisco: "C222",
        Abitanti: "1578",
      },
      {
        Istat: "75017",
        Comune: "Castri di Lecce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73020",
        CodFisco: "C334",
        Abitanti: "3030",
      },
      {
        Istat: "75018",
        Comune: "Castrignano de' Greci",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "C335",
        Abitanti: "4144",
      },
      {
        Istat: "75019",
        Comune: "Castrignano del Capo",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "C336",
        Abitanti: "5422",
      },
      {
        Istat: "75096",
        Comune: "Castro",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73030",
        CodFisco: "M261",
        Abitanti: "2495",
      },
      {
        Istat: "75020",
        Comune: "Cavallino",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73020",
        CodFisco: "C377",
        Abitanti: "12428",
      },
      {
        Istat: "74003",
        Comune: "Ceglie Messapica",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72013",
        CodFisco: "C424",
        Abitanti: "20690",
      },
      {
        Istat: "71018",
        Comune: "Celenza Valfortore",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71035",
        CodFisco: "C429",
        Abitanti: "1741",
      },
      {
        Istat: "72018",
        Comune: "Cellamare",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "C436",
        Abitanti: "5812",
      },
      {
        Istat: "71019",
        Comune: "Celle di San Vito",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71020",
        CodFisco: "C442",
        Abitanti: "173",
      },
      {
        Istat: "74004",
        Comune: "Cellino San Marco",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72020",
        CodFisco: "C448",
        Abitanti: "6755",
      },
      {
        Istat: "71020",
        Comune: "Cerignola",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71042",
        CodFisco: "C514",
        Abitanti: "59103",
      },
      {
        Istat: "71021",
        Comune: "Chieuti",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71010",
        CodFisco: "C633",
        Abitanti: "1772",
      },
      {
        Istat: "74005",
        Comune: "Cisternino",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "72014",
        CodFisco: "C741",
        Abitanti: "11884",
      },
      {
        Istat: "75021",
        Comune: "Collepasso",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "C865",
        Abitanti: "6428",
      },
      {
        Istat: "72019",
        Comune: "Conversano",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70014",
        CodFisco: "C975",
        Abitanti: "25760",
      },
      {
        Istat: "75022",
        Comune: "Copertino",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73043",
        CodFisco: "C978",
        Abitanti: "24527",
      },
      {
        Istat: "72020",
        Comune: "Corato",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70033",
        CodFisco: "C983",
        Abitanti: "48101",
      },
      {
        Istat: "75023",
        Comune: "Corigliano d'Otranto",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73022",
        CodFisco: "D006",
        Abitanti: "5889",
      },
      {
        Istat: "75024",
        Comune: "Corsano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73033",
        CodFisco: "D044",
        Abitanti: "5693",
      },
      {
        Istat: "73004",
        Comune: "Crispiano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74012",
        CodFisco: "D171",
        Abitanti: "13668",
      },
      {
        Istat: "75025",
        Comune: "Cursi",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "D223",
        Abitanti: "4280",
      },
      {
        Istat: "75026",
        Comune: "Cutrofiano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "D237",
        Abitanti: "9292",
      },
      {
        Istat: "71022",
        Comune: "Deliceto",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71026",
        CodFisco: "D269",
        Abitanti: "3947",
      },
      {
        Istat: "75027",
        Comune: "Diso",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73030",
        CodFisco: "D305",
        Abitanti: "3137",
      },
      {
        Istat: "74006",
        Comune: "Erchie",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72020",
        CodFisco: "D422",
        Abitanti: "8947",
      },
      {
        Istat: "71023",
        Comune: "Faeto",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71020",
        CodFisco: "D459",
        Abitanti: "643",
      },
      {
        Istat: "73005",
        Comune: "Faggiano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "D463",
        Abitanti: "3561",
      },
      {
        Istat: "74007",
        Comune: "Fasano",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "72015",
        CodFisco: "D508",
        Abitanti: "38657",
      },
      {
        Istat: "71024",
        Comune: "Foggia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71121",
        CodFisco: "D643",
        Abitanti: "152747",
      },
      {
        Istat: "71024",
        Comune: "Foggia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71122",
        CodFisco: "D643",
        Abitanti: "152747",
      },
      {
        Istat: "73006",
        Comune: "Fragagnano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74022",
        CodFisco: "D754",
        Abitanti: "5417",
      },
      {
        Istat: "74008",
        Comune: "Francavilla Fontana",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72021",
        CodFisco: "D761",
        Abitanti: "36593",
      },
      {
        Istat: "75028",
        Comune: "Gagliano del Capo",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73034",
        CodFisco: "D851",
        Abitanti: "5485",
      },
      {
        Istat: "75029",
        Comune: "Galatina",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73013",
        CodFisco: "D862",
        Abitanti: "27299",
      },
      {
        Istat: "75030",
        Comune: "Galatone",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73044",
        CodFisco: "D863",
        Abitanti: "15834",
      },
      {
        Istat: "75031",
        Comune: "Gallipoli",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73014",
        CodFisco: "D883",
        Abitanti: "21139",
      },
      {
        Istat: "73007",
        Comune: "Ginosa",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74013",
        CodFisco: "E036",
        Abitanti: "22802",
      },
      {
        Istat: "72021",
        Comune: "Gioia del Colle",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70023",
        CodFisco: "E038",
        Abitanti: "28100",
      },
      {
        Istat: "72022",
        Comune: "Giovinazzo",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70054",
        CodFisco: "E047",
        Abitanti: "20593",
      },
      {
        Istat: "75032",
        Comune: "Giuggianello",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73030",
        CodFisco: "E053",
        Abitanti: "1256",
      },
      {
        Istat: "75033",
        Comune: "Giurdignano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "E061",
        Abitanti: "1917",
      },
      {
        Istat: "72023",
        Comune: "Gravina in Puglia",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70024",
        CodFisco: "E155",
        Abitanti: "44383",
      },
      {
        Istat: "73008",
        Comune: "Grottaglie",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74023",
        CodFisco: "E205",
        Abitanti: "32791",
      },
      {
        Istat: "72024",
        Comune: "Grumo Appula",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70025",
        CodFisco: "E223",
        Abitanti: "13145",
      },
      {
        Istat: "75034",
        Comune: "Guagnano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "E227",
        Abitanti: "5943",
      },
      {
        Istat: "71025",
        Comune: "Ischitella",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71010",
        CodFisco: "E332",
        Abitanti: "4401",
      },
      {
        Istat: "71026",
        Comune: "Isole Tremiti",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71040",
        CodFisco: "E363",
        Abitanti: "486",
      },
      {
        Istat: "73009",
        Comune: "Laterza",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74014",
        CodFisco: "E469",
        Abitanti: "15282",
      },
      {
        Istat: "74009",
        Comune: "Latiano",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72022",
        CodFisco: "E471",
        Abitanti: "15020",
      },
      {
        Istat: "75035",
        Comune: "Lecce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73100",
        CodFisco: "E506",
        Abitanti: "95520",
      },
      {
        Istat: "73010",
        Comune: "Leporano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "E537",
        Abitanti: "7861",
      },
      {
        Istat: "75036",
        Comune: "Lequile",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "E538",
        Abitanti: "8617",
      },
      {
        Istat: "71027",
        Comune: "Lesina",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71010",
        CodFisco: "E549",
        Abitanti: "6397",
      },
      {
        Istat: "75037",
        Comune: "Leverano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73045",
        CodFisco: "E563",
        Abitanti: "14267",
      },
      {
        Istat: "75038",
        Comune: "Lizzanello",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73023",
        CodFisco: "E629",
        Abitanti: "11788",
      },
      {
        Istat: "73011",
        Comune: "Lizzano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "E630",
        Abitanti: "10282",
      },
      {
        Istat: "72025",
        Comune: "Locorotondo",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "E645",
        Abitanti: "14231",
      },
      {
        Istat: "71028",
        Comune: "Lucera",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71036",
        CodFisco: "E716",
        Abitanti: "34513",
      },
      {
        Istat: "75039",
        Comune: "Maglie",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73024",
        CodFisco: "E815",
        Abitanti: "14981",
      },
      {
        Istat: "73012",
        Comune: "Manduria",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74024",
        CodFisco: "E882",
        Abitanti: "31843",
      },
      {
        Istat: "71029",
        Comune: "Manfredonia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71043",
        CodFisco: "E885",
        Abitanti: "57455",
      },
      {
        Istat: "110005",
        Comune: "Margherita di Savoia",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76016",
        CodFisco: "E946",
        Abitanti: "12465",
      },
      {
        Istat: "75040",
        Comune: "Martano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73025",
        CodFisco: "E979",
        Abitanti: "9485",
      },
      {
        Istat: "75041",
        Comune: "Martignano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73020",
        CodFisco: "E984",
        Abitanti: "1748",
      },
      {
        Istat: "73013",
        Comune: "Martina Franca",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "74015",
        CodFisco: "E986",
        Abitanti: "49780",
      },
      {
        Istat: "73014",
        Comune: "Maruggio",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "E995",
        Abitanti: "5514",
      },
      {
        Istat: "73015",
        Comune: "Massafra",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74016",
        CodFisco: "F027",
        Abitanti: "32448",
      },
      {
        Istat: "75042",
        Comune: "Matino",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73046",
        CodFisco: "F054",
        Abitanti: "11795",
      },
      {
        Istat: "71031",
        Comune: "Mattinata",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71030",
        CodFisco: "F059",
        Abitanti: "6523",
      },
      {
        Istat: "75043",
        Comune: "Melendugno",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73026",
        CodFisco: "F101",
        Abitanti: "9838",
      },
      {
        Istat: "75044",
        Comune: "Melissano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "F109",
        Abitanti: "7357",
      },
      {
        Istat: "75045",
        Comune: "Melpignano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "F117",
        Abitanti: "2217",
      },
      {
        Istat: "74010",
        Comune: "Mesagne",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72023",
        CodFisco: "F152",
        Abitanti: "27860",
      },
      {
        Istat: "75046",
        Comune: "Miggiano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73035",
        CodFisco: "F194",
        Abitanti: "3685",
      },
      {
        Istat: "75047",
        Comune: "Minervino di Lecce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73027",
        CodFisco: "F221",
        Abitanti: "3787",
      },
      {
        Istat: "110006",
        Comune: "Minervino Murge",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76013",
        CodFisco: "F220",
        Abitanti: "9598",
      },
      {
        Istat: "72027",
        Comune: "Modugno",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70026",
        CodFisco: "F262",
        Abitanti: "38826",
      },
      {
        Istat: "72028",
        Comune: "Mola di Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70042",
        CodFisco: "F280",
        Abitanti: "26348",
      },
      {
        Istat: "72029",
        Comune: "Molfetta",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70056",
        CodFisco: "F284",
        Abitanti: "60159",
      },
      {
        Istat: "72030",
        Comune: "Monopoli",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70043",
        CodFisco: "F376",
        Abitanti: "49622",
      },
      {
        Istat: "71033",
        Comune: "Monte Sant'Angelo",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71037",
        CodFisco: "F631",
        Abitanti: "13221",
      },
      {
        Istat: "73016",
        Comune: "Monteiasi",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "F531",
        Abitanti: "5522",
      },
      {
        Istat: "71032",
        Comune: "Monteleone di Puglia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71020",
        CodFisco: "F538",
        Abitanti: "1085",
      },
      {
        Istat: "73017",
        Comune: "Montemesola",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "F563",
        Abitanti: "4156",
      },
      {
        Istat: "73018",
        Comune: "Monteparano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "F587",
        Abitanti: "2414",
      },
      {
        Istat: "75048",
        Comune: "Monteroni di Lecce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73047",
        CodFisco: "F604",
        Abitanti: "13964",
      },
      {
        Istat: "75049",
        Comune: "Montesano Salentino",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73030",
        CodFisco: "F623",
        Abitanti: "2696",
      },
      {
        Istat: "75050",
        Comune: "Morciano di Leuca",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "F716",
        Abitanti: "3460",
      },
      {
        Istat: "71034",
        Comune: "Motta Montecorvino",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71030",
        CodFisco: "F777",
        Abitanti: "798",
      },
      {
        Istat: "73019",
        Comune: "Mottola",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74017",
        CodFisco: "F784",
        Abitanti: "16333",
      },
      {
        Istat: "75051",
        Comune: "Muro Leccese",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73036",
        CodFisco: "F816",
        Abitanti: "5123",
      },
      {
        Istat: "75052",
        Comune: "Nardò",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73048",
        CodFisco: "F842",
        Abitanti: "31952",
      },
      {
        Istat: "75053",
        Comune: "Neviano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73040",
        CodFisco: "F881",
        Abitanti: "5533",
      },
      {
        Istat: "72031",
        Comune: "Noci",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70015",
        CodFisco: "F915",
        Abitanti: "19477",
      },
      {
        Istat: "75054",
        Comune: "Nociglia",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "F916",
        Abitanti: "2482",
      },
      {
        Istat: "72032",
        Comune: "Noicàttaro",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70016",
        CodFisco: "F923",
        Abitanti: "25835",
      },
      {
        Istat: "75055",
        Comune: "Novoli",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73051",
        CodFisco: "F970",
        Abitanti: "8200",
      },
      {
        Istat: "71063",
        Comune: "Ordona",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71040",
        CodFisco: "M266",
        Abitanti: "2720",
      },
      {
        Istat: "74011",
        Comune: "Oria",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72024",
        CodFisco: "G098",
        Abitanti: "15436",
      },
      {
        Istat: "71035",
        Comune: "Orsara di Puglia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71027",
        CodFisco: "G125",
        Abitanti: "2990",
      },
      {
        Istat: "71036",
        Comune: "Orta Nova",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71045",
        CodFisco: "G131",
        Abitanti: "17868",
      },
      {
        Istat: "75056",
        Comune: "Ortelle",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73030",
        CodFisco: "G136",
        Abitanti: "2413",
      },
      {
        Istat: "74012",
        Comune: "Ostuni",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72017",
        CodFisco: "G187",
        Abitanti: "32316",
      },
      {
        Istat: "75057",
        Comune: "Otranto",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73028",
        CodFisco: "G188",
        Abitanti: "5548",
      },
      {
        Istat: "73020",
        Comune: "Palagianello",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74018",
        CodFisco: "G251",
        Abitanti: "7871",
      },
      {
        Istat: "73021",
        Comune: "Palagiano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74019",
        CodFisco: "G252",
        Abitanti: "16064",
      },
      {
        Istat: "75058",
        Comune: "Palmariggi",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "G285",
        Abitanti: "1580",
      },
      {
        Istat: "72033",
        Comune: "Palo del Colle",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70027",
        CodFisco: "G291",
        Abitanti: "21786",
      },
      {
        Istat: "71037",
        Comune: "Panni",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71020",
        CodFisco: "G312",
        Abitanti: "865",
      },
      {
        Istat: "75059",
        Comune: "Parabita",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73052",
        CodFisco: "G325",
        Abitanti: "9374",
      },
      {
        Istat: "75060",
        Comune: "Patù",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73053",
        CodFisco: "G378",
        Abitanti: "1740",
      },
      {
        Istat: "71038",
        Comune: "Peschici",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71010",
        CodFisco: "G487",
        Abitanti: "4411",
      },
      {
        Istat: "71039",
        Comune: "Pietramontecorvino",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71038",
        CodFisco: "G604",
        Abitanti: "2765",
      },
      {
        Istat: "75061",
        Comune: "Poggiardo",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73037",
        CodFisco: "G751",
        Abitanti: "6140",
      },
      {
        Istat: "71040",
        Comune: "Poggio Imperiale",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71010",
        CodFisco: "G761",
        Abitanti: "2835",
      },
      {
        Istat: "72034",
        Comune: "Poggiorsini",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70020",
        CodFisco: "G769",
        Abitanti: "1452",
      },
      {
        Istat: "72035",
        Comune: "Polignano a Mare",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70044",
        CodFisco: "G787",
        Abitanti: "17797",
      },
      {
        Istat: "75097",
        Comune: "Porto Cesareo",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73010",
        CodFisco: "M263",
        Abitanti: "5675",
      },
      {
        Istat: "75062",
        Comune: "Presicce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73054",
        CodFisco: "H047",
        Abitanti: "5621",
      },
      {
        Istat: "73022",
        Comune: "Pulsano",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74026",
        CodFisco: "H090",
        Abitanti: "11002",
      },
      {
        Istat: "72036",
        Comune: "Putignano",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70017",
        CodFisco: "H096",
        Abitanti: "27394",
      },
      {
        Istat: "75063",
        Comune: "Racale",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73055",
        CodFisco: "H147",
        Abitanti: "10892",
      },
      {
        Istat: "71041",
        Comune: "Rignano Garganico",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71010",
        CodFisco: "H287",
        Abitanti: "2216",
      },
      {
        Istat: "73023",
        Comune: "Roccaforzata",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "H409",
        Abitanti: "1846",
      },
      {
        Istat: "71042",
        Comune: "Rocchetta Sant'Antonio",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71020",
        CodFisco: "H467",
        Abitanti: "1982",
      },
      {
        Istat: "71043",
        Comune: "Rodi Garganico",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71012",
        CodFisco: "H480",
        Abitanti: "3673",
      },
      {
        Istat: "71044",
        Comune: "Roseto Valfortore",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71039",
        CodFisco: "H568",
        Abitanti: "1205",
      },
      {
        Istat: "75064",
        Comune: "Ruffano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73049",
        CodFisco: "H632",
        Abitanti: "9724",
      },
      {
        Istat: "72037",
        Comune: "Rutigliano",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70018",
        CodFisco: "H643",
        Abitanti: "18108",
      },
      {
        Istat: "72038",
        Comune: "Ruvo di Puglia",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70037",
        CodFisco: "H645",
        Abitanti: "25786",
      },
      {
        Istat: "75065",
        Comune: "Salice Salentino",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73015",
        CodFisco: "H708",
        Abitanti: "8767",
      },
      {
        Istat: "75066",
        Comune: "Salve",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73050",
        CodFisco: "H729",
        Abitanti: "4708",
      },
      {
        Istat: "72039",
        Comune: "Sammichele di Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "H749",
        Abitanti: "6658",
      },
      {
        Istat: "75095",
        Comune: "San Cassiano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "M264",
        Abitanti: "2126",
      },
      {
        Istat: "75068",
        Comune: "San Cesario di Lecce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73016",
        CodFisco: "H793",
        Abitanti: "8398",
      },
      {
        Istat: "74013",
        Comune: "San Donaci",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72025",
        CodFisco: "H822",
        Abitanti: "7050",
      },
      {
        Istat: "75069",
        Comune: "San Donato di Lecce",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "H826",
        Abitanti: "5871",
      },
      {
        Istat: "110007",
        Comune: "San Ferdinando di Puglia",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76017",
        CodFisco: "H839",
        Abitanti: "14894",
      },
      {
        Istat: "73024",
        Comune: "San Giorgio Ionico",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74027",
        CodFisco: "H882",
        Abitanti: "15992",
      },
      {
        Istat: "71046",
        Comune: "San Giovanni Rotondo",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71013",
        CodFisco: "H926",
        Abitanti: "27327",
      },
      {
        Istat: "71047",
        Comune: "San Marco in Lamis",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71014",
        CodFisco: "H985",
        Abitanti: "14444",
      },
      {
        Istat: "71048",
        Comune: "San Marco la Catola",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71030",
        CodFisco: "H986",
        Abitanti: "1108",
      },
      {
        Istat: "73025",
        Comune: "San Marzano di San Giuseppe",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "I018",
        Abitanti: "9284",
      },
      {
        Istat: "74014",
        Comune: "San Michele Salentino",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72018",
        CodFisco: "I045",
        Abitanti: "6420",
      },
      {
        Istat: "71049",
        Comune: "San Nicandro Garganico",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71015",
        CodFisco: "I054",
        Abitanti: "16054",
      },
      {
        Istat: "74015",
        Comune: "San Pancrazio Salentino",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72026",
        CodFisco: "I066",
        Abitanti: "10342",
      },
      {
        Istat: "71050",
        Comune: "San Paolo di Civitate",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71010",
        CodFisco: "I072",
        Abitanti: "6018",
      },
      {
        Istat: "75071",
        Comune: "San Pietro in Lama",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "I115",
        Abitanti: "3628",
      },
      {
        Istat: "74016",
        Comune: "San Pietro Vernotico",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72027",
        CodFisco: "I119",
        Abitanti: "14380",
      },
      {
        Istat: "71051",
        Comune: "San Severo",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71016",
        CodFisco: "I158",
        Abitanti: "55321",
      },
      {
        Istat: "74017",
        Comune: "San Vito dei Normanni",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72019",
        CodFisco: "I396",
        Abitanti: "19801",
      },
      {
        Istat: "75067",
        Comune: "Sanarica",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73030",
        CodFisco: "H757",
        Abitanti: "1490",
      },
      {
        Istat: "72040",
        Comune: "Sannicandro di Bari",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70028",
        CodFisco: "I053",
        Abitanti: "9794",
      },
      {
        Istat: "75070",
        Comune: "Sannicola",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73017",
        CodFisco: "I059",
        Abitanti: "5965",
      },
      {
        Istat: "75072",
        Comune: "Santa Cesarea Terme",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "I172",
        Abitanti: "3051",
      },
      {
        Istat: "71052",
        Comune: "Sant'Agata di Puglia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71028",
        CodFisco: "I193",
        Abitanti: "2149",
      },
      {
        Istat: "72041",
        Comune: "Santeramo in Colle",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70029",
        CodFisco: "I330",
        Abitanti: "26854",
      },
      {
        Istat: "73026",
        Comune: "Sava",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74028",
        CodFisco: "I467",
        Abitanti: "16776",
      },
      {
        Istat: "75073",
        Comune: "Scorrano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "I549",
        Abitanti: "7027",
      },
      {
        Istat: "75074",
        Comune: "Seclì",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73050",
        CodFisco: "I559",
        Abitanti: "1938",
      },
      {
        Istat: "71053",
        Comune: "Serracapriola",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71010",
        CodFisco: "I641",
        Abitanti: "4106",
      },
      {
        Istat: "75075",
        Comune: "Sogliano Cavour",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73010",
        CodFisco: "I780",
        Abitanti: "4122",
      },
      {
        Istat: "75076",
        Comune: "Soleto",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73010",
        CodFisco: "I800",
        Abitanti: "5572",
      },
      {
        Istat: "75077",
        Comune: "Specchia",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "I887",
        Abitanti: "4912",
      },
      {
        Istat: "110008",
        Comune: "Spinazzola",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76014",
        CodFisco: "I907",
        Abitanti: "6908",
      },
      {
        Istat: "75078",
        Comune: "Spongano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73038",
        CodFisco: "I923",
        Abitanti: "3803",
      },
      {
        Istat: "75079",
        Comune: "Squinzano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73018",
        CodFisco: "I930",
        Abitanti: "14537",
      },
      {
        Istat: "73029",
        Comune: "Statte",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74010",
        CodFisco: "M298",
        Abitanti: "14494",
      },
      {
        Istat: "75080",
        Comune: "Sternatia",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73010",
        CodFisco: "I950",
        Abitanti: "2459",
      },
      {
        Istat: "71054",
        Comune: "Stornara",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71047",
        CodFisco: "I962",
        Abitanti: "5114",
      },
      {
        Istat: "71055",
        Comune: "Stornarella",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "885",
        CAP: "71048",
        CodFisco: "I963",
        Abitanti: "5137",
      },
      {
        Istat: "75081",
        Comune: "Supersano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73040",
        CodFisco: "L008",
        Abitanti: "4510",
      },
      {
        Istat: "75082",
        Comune: "Surano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73030",
        CodFisco: "L010",
        Abitanti: "1724",
      },
      {
        Istat: "75083",
        Comune: "Surbo",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "L011",
        Abitanti: "14876",
      },
      {
        Istat: "73027",
        Comune: "Taranto",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74121",
        CodFisco: "L049",
        Abitanti: "191810",
      },
      {
        Istat: "73027",
        Comune: "Taranto",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74122",
        CodFisco: "L049",
        Abitanti: "191810",
      },
      {
        Istat: "73027",
        Comune: "Taranto",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74123",
        CodFisco: "L049",
        Abitanti: "191810",
      },
      {
        Istat: "75084",
        Comune: "Taurisano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73056",
        CodFisco: "L064",
        Abitanti: "12675",
      },
      {
        Istat: "75085",
        Comune: "Taviano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73057",
        CodFisco: "L074",
        Abitanti: "12632",
      },
      {
        Istat: "72043",
        Comune: "Terlizzi",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70038",
        CodFisco: "L109",
        Abitanti: "27290",
      },
      {
        Istat: "75086",
        Comune: "Tiggiano",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73030",
        CodFisco: "L166",
        Abitanti: "2931",
      },
      {
        Istat: "74018",
        Comune: "Torchiarolo",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72020",
        CodFisco: "L213",
        Abitanti: "5179",
      },
      {
        Istat: "72044",
        Comune: "Toritto",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70020",
        CodFisco: "L220",
        Abitanti: "8645",
      },
      {
        Istat: "74019",
        Comune: "Torre Santa Susanna",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72028",
        CodFisco: "L280",
        Abitanti: "10552",
      },
      {
        Istat: "71056",
        Comune: "Torremaggiore",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "882",
        CAP: "71017",
        CodFisco: "L273",
        Abitanti: "17434",
      },
      {
        Istat: "73028",
        Comune: "Torricella",
        Provincia: "TA",
        Regione: "PUG",
        Prefisso: "99",
        CAP: "74020",
        CodFisco: "L294",
        Abitanti: "4216",
      },
      {
        Istat: "110009",
        Comune: "Trani",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76125",
        CodFisco: "L328",
        Abitanti: "53940",
      },
      {
        Istat: "75087",
        Comune: "Trepuzzi",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73019",
        CodFisco: "L383",
        Abitanti: "14688",
      },
      {
        Istat: "75088",
        Comune: "Tricase",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73039",
        CodFisco: "L419",
        Abitanti: "17792",
      },
      {
        Istat: "72046",
        Comune: "Triggiano",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70019",
        CodFisco: "L425",
        Abitanti: "27553",
      },
      {
        Istat: "110010",
        Comune: "Trinitapoli",
        Provincia: "BT",
        Regione: "PUG",
        Prefisso: "883",
        CAP: "76015",
        CodFisco: "B915",
        Abitanti: "14551",
      },
      {
        Istat: "71058",
        Comune: "Troia",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71029",
        CodFisco: "L447",
        Abitanti: "7411",
      },
      {
        Istat: "75089",
        Comune: "Tuglie",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73058",
        CodFisco: "L462",
        Abitanti: "5320",
      },
      {
        Istat: "72047",
        Comune: "Turi",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "L472",
        Abitanti: "12413",
      },
      {
        Istat: "75090",
        Comune: "Ugento",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "833",
        CAP: "73059",
        CodFisco: "L484",
        Abitanti: "12266",
      },
      {
        Istat: "75091",
        Comune: "Uggiano La Chiesa",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73020",
        CodFisco: "L485",
        Abitanti: "4444",
      },
      {
        Istat: "72048",
        Comune: "Valenzano",
        Provincia: "BA",
        Regione: "PUG",
        Prefisso: "80",
        CAP: "70010",
        CodFisco: "L571",
        Abitanti: "18305",
      },
      {
        Istat: "75092",
        Comune: "Veglie",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73010",
        CodFisco: "L711",
        Abitanti: "14369",
      },
      {
        Istat: "75093",
        Comune: "Vernole",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "832",
        CAP: "73029",
        CodFisco: "L776",
        Abitanti: "7404",
      },
      {
        Istat: "71059",
        Comune: "Vico del Gargano",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71018",
        CodFisco: "L842",
        Abitanti: "7982",
      },
      {
        Istat: "71060",
        Comune: "Vieste",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71019",
        CodFisco: "L858",
        Abitanti: "13963",
      },
      {
        Istat: "74020",
        Comune: "Villa Castelli",
        Provincia: "BR",
        Regione: "PUG",
        Prefisso: "831",
        CAP: "72029",
        CodFisco: "L920",
        Abitanti: "9260",
      },
      {
        Istat: "71061",
        Comune: "Volturara Appula",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71030",
        CodFisco: "M131",
        Abitanti: "496",
      },
      {
        Istat: "71062",
        Comune: "Volturino",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "881",
        CAP: "71030",
        CodFisco: "M132",
        Abitanti: "1800",
      },
      {
        Istat: "71064",
        Comune: "Zapponeta",
        Provincia: "FG",
        Regione: "PUG",
        Prefisso: "884",
        CAP: "71030",
        CodFisco: "M267",
        Abitanti: "3465",
      },
      {
        Istat: "75094",
        Comune: "Zollino",
        Provincia: "LE",
        Regione: "PUG",
        Prefisso: "836",
        CAP: "73010",
        CodFisco: "M187",
        Abitanti: "2072",
      },
      {
        Istat: "95001",
        Comune: "Abbasanta",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09071",
        CodFisco: "A007",
        Abitanti: "2872",
      },
      {
        Istat: "104001",
        Comune: "Aggius",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07020",
        CodFisco: "A069",
        Abitanti: "1631",
      },
      {
        Istat: "104002",
        Comune: "Aglientu",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07020",
        CodFisco: "H848",
        Abitanti: "1213",
      },
      {
        Istat: "95002",
        Comune: "Aidomaggiore",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09070",
        CodFisco: "A097",
        Abitanti: "480",
      },
      {
        Istat: "104003",
        Comune: "Alà dei Sardi",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07020",
        CodFisco: "A115",
        Abitanti: "1960",
      },
      {
        Istat: "95003",
        Comune: "Albagiara",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "A126",
        Abitanti: "283",
      },
      {
        Istat: "95004",
        Comune: "Ales",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09091",
        CodFisco: "A180",
        Abitanti: "1512",
      },
      {
        Istat: "90003",
        Comune: "Alghero",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07041",
        CodFisco: "A192",
        Abitanti: "40965",
      },
      {
        Istat: "95005",
        Comune: "Allai",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "A204",
        Abitanti: "385",
      },
      {
        Istat: "90004",
        Comune: "Anela",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "A287",
        Abitanti: "699",
      },
      {
        Istat: "95006",
        Comune: "Arborea",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09092",
        CodFisco: "A357",
        Abitanti: "4068",
      },
      {
        Istat: "106001",
        Comune: "Arbus",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09031",
        CodFisco: "A359",
        Abitanti: "6615",
      },
      {
        Istat: "90005",
        Comune: "Ardara",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "A379",
        Abitanti: "810",
      },
      {
        Istat: "95007",
        Comune: "Ardauli",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09081",
        CodFisco: "A380",
        Abitanti: "960",
      },
      {
        Istat: "91001",
        Comune: "Aritzo",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08031",
        CodFisco: "A407",
        Abitanti: "1361",
      },
      {
        Istat: "92002",
        Comune: "Armungia",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "A419",
        Abitanti: "498",
      },
      {
        Istat: "104004",
        Comune: "Arzachena",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07021",
        CodFisco: "A453",
        Abitanti: "13317",
      },
      {
        Istat: "105001",
        Comune: "Arzana",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "A454",
        Abitanti: "2526",
      },
      {
        Istat: "92003",
        Comune: "Assemini",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09032",
        CodFisco: "A474",
        Abitanti: "26979",
      },
      {
        Istat: "95008",
        Comune: "Assolo",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "A477",
        Abitanti: "444",
      },
      {
        Istat: "95009",
        Comune: "Asuni",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "A480",
        Abitanti: "365",
      },
      {
        Istat: "91003",
        Comune: "Atzara",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08030",
        CodFisco: "A492",
        Abitanti: "1224",
      },
      {
        Istat: "91004",
        Comune: "Austis",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08030",
        CodFisco: "A503",
        Abitanti: "881",
      },
      {
        Istat: "104005",
        Comune: "Badesi",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "M214",
        Abitanti: "1909",
      },
      {
        Istat: "92004",
        Comune: "Ballao",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "A597",
        Abitanti: "882",
      },
      {
        Istat: "90007",
        Comune: "Banari",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "A606",
        Abitanti: "606",
      },
      {
        Istat: "95010",
        Comune: "Baradili",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "A614",
        Abitanti: "95",
      },
      {
        Istat: "95011",
        Comune: "Baratili San Pietro",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "A621",
        Abitanti: "1335",
      },
      {
        Istat: "95012",
        Comune: "Baressa",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "A655",
        Abitanti: "747",
      },
      {
        Istat: "105002",
        Comune: "Bari Sardo",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08042",
        CodFisco: "A663",
        Abitanti: "3983",
      },
      {
        Istat: "92005",
        Comune: "Barrali",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "A677",
        Abitanti: "1123",
      },
      {
        Istat: "106002",
        Comune: "Barumini",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09021",
        CodFisco: "A681",
        Abitanti: "1339",
      },
      {
        Istat: "95013",
        Comune: "Bauladu",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "A721",
        Abitanti: "711",
      },
      {
        Istat: "105003",
        Comune: "Baunei",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "A722",
        Abitanti: "3736",
      },
      {
        Istat: "91007",
        Comune: "Belvì",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08030",
        CodFisco: "A776",
        Abitanti: "671",
      },
      {
        Istat: "90008",
        Comune: "Benetutti",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "A781",
        Abitanti: "2010",
      },
      {
        Istat: "104006",
        Comune: "Berchidda",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07022",
        CodFisco: "A789",
        Abitanti: "2941",
      },
      {
        Istat: "90010",
        Comune: "Bessude",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "A827",
        Abitanti: "433",
      },
      {
        Istat: "95014",
        Comune: "Bidonì",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "A856",
        Abitanti: "150",
      },
      {
        Istat: "91008",
        Comune: "Birori",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "A880",
        Abitanti: "562",
      },
      {
        Istat: "91009",
        Comune: "Bitti",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08021",
        CodFisco: "A895",
        Abitanti: "3074",
      },
      {
        Istat: "91010",
        Comune: "Bolotana",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08011",
        CodFisco: "A948",
        Abitanti: "2884",
      },
      {
        Istat: "95015",
        Comune: "Bonarcado",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "A960",
        Abitanti: "1650",
      },
      {
        Istat: "90011",
        Comune: "Bonnanaro",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07043",
        CodFisco: "A976",
        Abitanti: "1036",
      },
      {
        Istat: "90012",
        Comune: "Bono",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07011",
        CodFisco: "A977",
        Abitanti: "3679",
      },
      {
        Istat: "90013",
        Comune: "Bonorva",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07012",
        CodFisco: "A978",
        Abitanti: "3728",
      },
      {
        Istat: "95016",
        Comune: "Boroneddu",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09080",
        CodFisco: "B055",
        Abitanti: "162",
      },
      {
        Istat: "91011",
        Comune: "Borore",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08016",
        CodFisco: "B056",
        Abitanti: "2209",
      },
      {
        Istat: "91012",
        Comune: "Bortigali",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08012",
        CodFisco: "B062",
        Abitanti: "1438",
      },
      {
        Istat: "104007",
        Comune: "Bortigiadas",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "B063",
        Abitanti: "806",
      },
      {
        Istat: "90015",
        Comune: "Borutta",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "B064",
        Abitanti: "283",
      },
      {
        Istat: "95079",
        Comune: "Bosa",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08013",
        CodFisco: "B068",
        Abitanti: "8133",
      },
      {
        Istat: "90016",
        Comune: "Bottidda",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "B094",
        Abitanti: "756",
      },
      {
        Istat: "104008",
        Comune: "Buddusò",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07020",
        CodFisco: "B246",
        Abitanti: "4009",
      },
      {
        Istat: "104009",
        Comune: "Budoni",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "B248",
        Abitanti: "4951",
      },
      {
        Istat: "107001",
        Comune: "Buggerru",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "B250",
        Abitanti: "1110",
      },
      {
        Istat: "90018",
        Comune: "Bultei",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "B264",
        Abitanti: "1057",
      },
      {
        Istat: "90019",
        Comune: "Bulzi",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "B265",
        Abitanti: "562",
      },
      {
        Istat: "92008",
        Comune: "Burcei",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "B274",
        Abitanti: "2917",
      },
      {
        Istat: "90020",
        Comune: "Burgos",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "B276",
        Abitanti: "958",
      },
      {
        Istat: "95017",
        Comune: "Busachi",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09082",
        CodFisco: "B281",
        Abitanti: "1415",
      },
      {
        Istat: "95018",
        Comune: "Cabras",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09072",
        CodFisco: "B314",
        Abitanti: "9169",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09121",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09122",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09123",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09124",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09125",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09126",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09127",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09128",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09129",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09130",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09131",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09132",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09133",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "92009",
        Comune: "Cagliari",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09134",
        CodFisco: "B354",
        Abitanti: "156488",
      },
      {
        Istat: "104010",
        Comune: "Calangianus",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07023",
        CodFisco: "B378",
        Abitanti: "4337",
      },
      {
        Istat: "107002",
        Comune: "Calasetta",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09011",
        CodFisco: "B383",
        Abitanti: "2901",
      },
      {
        Istat: "92011",
        Comune: "Capoterra",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09012",
        CodFisco: "B675",
        Abitanti: "24030",
      },
      {
        Istat: "107003",
        Comune: "Carbonia",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09013",
        CodFisco: "B745",
        Abitanti: "29764",
      },
      {
        Istat: "105004",
        Comune: "Cardedu",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "M285",
        Abitanti: "1711",
      },
      {
        Istat: "90022",
        Comune: "Cargeghe",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "B772",
        Abitanti: "629",
      },
      {
        Istat: "107004",
        Comune: "Carloforte",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09014",
        CodFisco: "B789",
        Abitanti: "6420",
      },
      {
        Istat: "90023",
        Comune: "Castelsardo",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07031",
        CodFisco: "C272",
        Abitanti: "5881",
      },
      {
        Istat: "92106",
        Comune: "Castiadas",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "M288",
        Abitanti: "1507",
      },
      {
        Istat: "90024",
        Comune: "Cheremule",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "C600",
        Abitanti: "469",
      },
      {
        Istat: "90025",
        Comune: "Chiaramonti",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "C613",
        Abitanti: "1748",
      },
      {
        Istat: "90026",
        Comune: "Codrongianos",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "C818",
        Abitanti: "1374",
      },
      {
        Istat: "106003",
        Comune: "Collinas",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "C882",
        Abitanti: "897",
      },
      {
        Istat: "90027",
        Comune: "Cossoine",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "D100",
        Abitanti: "914",
      },
      {
        Istat: "95019",
        Comune: "Cuglieri",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09073",
        CodFisco: "D200",
        Abitanti: "2902",
      },
      {
        Istat: "95077",
        Comune: "Curcuris",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "D214",
        Abitanti: "314",
      },
      {
        Istat: "92015",
        Comune: "Decimomannu",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09033",
        CodFisco: "D259",
        Abitanti: "7833",
      },
      {
        Istat: "92016",
        Comune: "Decimoputzu",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09010",
        CodFisco: "D260",
        Abitanti: "4278",
      },
      {
        Istat: "91016",
        Comune: "Desulo",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08032",
        CodFisco: "D287",
        Abitanti: "2500",
      },
      {
        Istat: "92017",
        Comune: "Dolianova",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09041",
        CodFisco: "D323",
        Abitanti: "9336",
      },
      {
        Istat: "92018",
        Comune: "Domus De Maria",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09010",
        CodFisco: "D333",
        Abitanti: "1711",
      },
      {
        Istat: "107005",
        Comune: "Domusnovas",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09015",
        CodFisco: "D334",
        Abitanti: "6430",
      },
      {
        Istat: "92020",
        Comune: "Donori",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "D344",
        Abitanti: "2121",
      },
      {
        Istat: "91017",
        Comune: "Dorgali",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08022",
        CodFisco: "D345",
        Abitanti: "8544",
      },
      {
        Istat: "91018",
        Comune: "Dualchi",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "D376",
        Abitanti: "684",
      },
      {
        Istat: "105005",
        Comune: "Elini",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "D395",
        Abitanti: "555",
      },
      {
        Istat: "92108",
        Comune: "Elmas",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09030",
        CodFisco: "D399",
        Abitanti: "9112",
      },
      {
        Istat: "90088",
        Comune: "Erula",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "M292",
        Abitanti: "769",
      },
      {
        Istat: "92110",
        Comune: "Escalaplano",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "08043",
        CodFisco: "D430",
        Abitanti: "2295",
      },
      {
        Istat: "92111",
        Comune: "Escolca",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "D431",
        Abitanti: "622",
      },
      {
        Istat: "90028",
        Comune: "Esporlatu",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "D441",
        Abitanti: "431",
      },
      {
        Istat: "92112",
        Comune: "Esterzili",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "D443",
        Abitanti: "743",
      },
      {
        Istat: "90029",
        Comune: "Florinas",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "D637",
        Abitanti: "1556",
      },
      {
        Istat: "107006",
        Comune: "Fluminimaggiore",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "D639",
        Abitanti: "2992",
      },
      {
        Istat: "95080",
        Comune: "Flussio",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "D640",
        Abitanti: "468",
      },
      {
        Istat: "91024",
        Comune: "Fonni",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08023",
        CodFisco: "D665",
        Abitanti: "4104",
      },
      {
        Istat: "95020",
        Comune: "Fordongianus",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09083",
        CodFisco: "D695",
        Abitanti: "951",
      },
      {
        Istat: "106004",
        Comune: "Furtei",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "D827",
        Abitanti: "1690",
      },
      {
        Istat: "91025",
        Comune: "Gadoni",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08030",
        CodFisco: "D842",
        Abitanti: "897",
      },
      {
        Istat: "105006",
        Comune: "Gairo",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "D859",
        Abitanti: "1572",
      },
      {
        Istat: "91027",
        Comune: "Galtellì",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "D888",
        Abitanti: "2484",
      },
      {
        Istat: "91028",
        Comune: "Gavoi",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "D947",
        Abitanti: "2819",
      },
      {
        Istat: "95081",
        Comune: "Genoni",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "D968",
        Abitanti: "903",
      },
      {
        Istat: "106005",
        Comune: "Genuri",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "D970",
        Abitanti: "353",
      },
      {
        Istat: "92113",
        Comune: "Gergei",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "D982",
        Abitanti: "1315",
      },
      {
        Istat: "92024",
        Comune: "Gesico",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "D994",
        Abitanti: "894",
      },
      {
        Istat: "106006",
        Comune: "Gesturi",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "D997",
        Abitanti: "1286",
      },
      {
        Istat: "95021",
        Comune: "Ghilarza",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09074",
        CodFisco: "E004",
        Abitanti: "4700",
      },
      {
        Istat: "90030",
        Comune: "Giave",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "E019",
        Abitanti: "603",
      },
      {
        Istat: "107007",
        Comune: "Giba",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "E022",
        Abitanti: "2151",
      },
      {
        Istat: "105007",
        Comune: "Girasole",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "E049",
        Abitanti: "1161",
      },
      {
        Istat: "104011",
        Comune: "Golfo Aranci",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07020",
        CodFisco: "M274",
        Abitanti: "2414",
      },
      {
        Istat: "92027",
        Comune: "Goni",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "E084",
        Abitanti: "524",
      },
      {
        Istat: "107008",
        Comune: "Gonnesa",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "E086",
        Abitanti: "5161",
      },
      {
        Istat: "95022",
        Comune: "Gonnoscodina",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "E087",
        Abitanti: "514",
      },
      {
        Istat: "106007",
        Comune: "Gonnosfanadiga",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09035",
        CodFisco: "E085",
        Abitanti: "6917",
      },
      {
        Istat: "95023",
        Comune: "Gonnosnò",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "D585",
        Abitanti: "808",
      },
      {
        Istat: "95024",
        Comune: "Gonnostramatza",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09093",
        CodFisco: "E088",
        Abitanti: "939",
      },
      {
        Istat: "92030",
        Comune: "Guamaggiore",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "E234",
        Abitanti: "1066",
      },
      {
        Istat: "92031",
        Comune: "Guasila",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "E252",
        Abitanti: "2794",
      },
      {
        Istat: "106008",
        Comune: "Guspini",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09036",
        CodFisco: "E270",
        Abitanti: "12469",
      },
      {
        Istat: "107009",
        Comune: "Iglesias",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09016",
        CodFisco: "E281",
        Abitanti: "27493",
      },
      {
        Istat: "105008",
        Comune: "Ilbono",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "E283",
        Abitanti: "2246",
      },
      {
        Istat: "90031",
        Comune: "Illorai",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "E285",
        Abitanti: "979",
      },
      {
        Istat: "91033",
        Comune: "Irgoli",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "E323",
        Abitanti: "2348",
      },
      {
        Istat: "92114",
        Comune: "Isili",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08033",
        CodFisco: "E336",
        Abitanti: "3030",
      },
      {
        Istat: "90032",
        Comune: "Ittireddu",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "E376",
        Abitanti: "583",
      },
      {
        Istat: "90033",
        Comune: "Ittiri",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07044",
        CodFisco: "E377",
        Abitanti: "8918",
      },
      {
        Istat: "105009",
        Comune: "Jerzu",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08044",
        CodFisco: "E387",
        Abitanti: "3231",
      },
      {
        Istat: "104012",
        Comune: "La Maddalena",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07024",
        CodFisco: "E425",
        Abitanti: "11899",
      },
      {
        Istat: "95082",
        Comune: "Laconi",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08034",
        CodFisco: "E400",
        Abitanti: "2044",
      },
      {
        Istat: "90034",
        Comune: "Laerru",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "E401",
        Abitanti: "966",
      },
      {
        Istat: "105010",
        Comune: "Lanusei",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08045",
        CodFisco: "E441",
        Abitanti: "5655",
      },
      {
        Istat: "106009",
        Comune: "Las Plassas",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "E464",
        Abitanti: "266",
      },
      {
        Istat: "91038",
        Comune: "Lei",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "E517",
        Abitanti: "576",
      },
      {
        Istat: "105011",
        Comune: "Loceri",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "E644",
        Abitanti: "1267",
      },
      {
        Istat: "91040",
        Comune: "Loculi",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "E646",
        Abitanti: "513",
      },
      {
        Istat: "91041",
        Comune: "Lodè",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "E647",
        Abitanti: "1935",
      },
      {
        Istat: "91104",
        Comune: "Lodine",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "E649",
        Abitanti: "380",
      },
      {
        Istat: "104013",
        Comune: "Loiri Porto San Paolo",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07020",
        CodFisco: "M275",
        Abitanti: "3270",
      },
      {
        Istat: "105012",
        Comune: "Lotzorai",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "E700",
        Abitanti: "2164",
      },
      {
        Istat: "91043",
        Comune: "Lula",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "E736",
        Abitanti: "1521",
      },
      {
        Istat: "106010",
        Comune: "Lunamatrona",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09022",
        CodFisco: "E742",
        Abitanti: "1799",
      },
      {
        Istat: "104014",
        Comune: "Luogosanto",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07020",
        CodFisco: "E747",
        Abitanti: "1902",
      },
      {
        Istat: "104015",
        Comune: "Luras",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07025",
        CodFisco: "E752",
        Abitanti: "2715",
      },
      {
        Istat: "91044",
        Comune: "Macomer",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08015",
        CodFisco: "E788",
        Abitanti: "10672",
      },
      {
        Istat: "95083",
        Comune: "Magomadas",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "E825",
        Abitanti: "655",
      },
      {
        Istat: "91046",
        Comune: "Mamoiada",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08024",
        CodFisco: "E874",
        Abitanti: "2592",
      },
      {
        Istat: "92036",
        Comune: "Mandas",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "E877",
        Abitanti: "2268",
      },
      {
        Istat: "90038",
        Comune: "Mara",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "E902",
        Abitanti: "688",
      },
      {
        Istat: "92037",
        Comune: "Maracalagonis",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "E903",
        Abitanti: "7744",
      },
      {
        Istat: "95025",
        Comune: "Marrubiu",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09094",
        CodFisco: "E972",
        Abitanti: "4988",
      },
      {
        Istat: "90039",
        Comune: "Martis",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "E992",
        Abitanti: "560",
      },
      {
        Istat: "107010",
        Comune: "Masainas",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "M270",
        Abitanti: "1353",
      },
      {
        Istat: "95026",
        Comune: "Masullas",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "F050",
        Abitanti: "1152",
      },
      {
        Istat: "91047",
        Comune: "Meana Sardo",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08030",
        CodFisco: "F073",
        Abitanti: "1942",
      },
      {
        Istat: "95027",
        Comune: "Milis",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "F208",
        Abitanti: "1615",
      },
      {
        Istat: "95084",
        Comune: "Modolo",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08019",
        CodFisco: "F261",
        Abitanti: "170",
      },
      {
        Istat: "95028",
        Comune: "Mogorella",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "F270",
        Abitanti: "471",
      },
      {
        Istat: "95029",
        Comune: "Mogoro",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09095",
        CodFisco: "F272",
        Abitanti: "4441",
      },
      {
        Istat: "92038",
        Comune: "Monastir",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09023",
        CodFisco: "F333",
        Abitanti: "4576",
      },
      {
        Istat: "92109",
        Comune: "Monserrato",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09042",
        CodFisco: "F383",
        Abitanti: "20240",
      },
      {
        Istat: "90040",
        Comune: "Monteleone Rocca Doria",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "F542",
        Abitanti: "126",
      },
      {
        Istat: "104016",
        Comune: "Monti",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07020",
        CodFisco: "F667",
        Abitanti: "2483",
      },
      {
        Istat: "95085",
        Comune: "Montresta",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "F698",
        Abitanti: "559",
      },
      {
        Istat: "90042",
        Comune: "Mores",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07013",
        CodFisco: "F721",
        Abitanti: "1982",
      },
      {
        Istat: "95030",
        Comune: "Morgongiori",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "F727",
        Abitanti: "797",
      },
      {
        Istat: "92039",
        Comune: "Muravera",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09043",
        CodFisco: "F808",
        Abitanti: "5283",
      },
      {
        Istat: "90043",
        Comune: "Muros",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "F818",
        Abitanti: "844",
      },
      {
        Istat: "107011",
        Comune: "Musei",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "F822",
        Abitanti: "1522",
      },
      {
        Istat: "95031",
        Comune: "Narbolia",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "F840",
        Abitanti: "1802",
      },
      {
        Istat: "107012",
        Comune: "Narcao",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "F841",
        Abitanti: "3390",
      },
      {
        Istat: "95032",
        Comune: "Neoneli",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "F867",
        Abitanti: "717",
      },
      {
        Istat: "91050",
        Comune: "Noragugume",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "F933",
        Abitanti: "342",
      },
      {
        Istat: "95033",
        Comune: "Norbello",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09070",
        CodFisco: "F934",
        Abitanti: "1179",
      },
      {
        Istat: "90044",
        Comune: "Nughedu San Nicolò",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "F975",
        Abitanti: "888",
      },
      {
        Istat: "95034",
        Comune: "Nughedu Santa Vittoria",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "F974",
        Abitanti: "520",
      },
      {
        Istat: "90045",
        Comune: "Nule",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "F976",
        Abitanti: "1443",
      },
      {
        Istat: "90046",
        Comune: "Nulvi",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07032",
        CodFisco: "F977",
        Abitanti: "2877",
      },
      {
        Istat: "91051",
        Comune: "Nuoro",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08100",
        CodFisco: "F979",
        Abitanti: "36347",
      },
      {
        Istat: "95035",
        Comune: "Nurachi",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "F980",
        Abitanti: "1803",
      },
      {
        Istat: "92115",
        Comune: "Nuragus",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "F981",
        Abitanti: "968",
      },
      {
        Istat: "92116",
        Comune: "Nurallao",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "F982",
        Abitanti: "1356",
      },
      {
        Istat: "92042",
        Comune: "Nuraminis",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09024",
        CodFisco: "F983",
        Abitanti: "2556",
      },
      {
        Istat: "95036",
        Comune: "Nureci",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "F985",
        Abitanti: "354",
      },
      {
        Istat: "92117",
        Comune: "Nurri",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08035",
        CodFisco: "F986",
        Abitanti: "2299",
      },
      {
        Istat: "107013",
        Comune: "Nuxis",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "F991",
        Abitanti: "1657",
      },
      {
        Istat: "104017",
        Comune: "Olbia",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07026",
        CodFisco: "G015",
        Abitanti: "56066",
      },
      {
        Istat: "91055",
        Comune: "Oliena",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08025",
        CodFisco: "G031",
        Abitanti: "7418",
      },
      {
        Istat: "95037",
        Comune: "Ollastra",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09088",
        CodFisco: "G043",
        Abitanti: "1253",
      },
      {
        Istat: "91056",
        Comune: "Ollolai",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G044",
        Abitanti: "1395",
      },
      {
        Istat: "90048",
        Comune: "Olmedo",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "G046",
        Abitanti: "3937",
      },
      {
        Istat: "91057",
        Comune: "Olzai",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G058",
        Abitanti: "929",
      },
      {
        Istat: "91058",
        Comune: "Onanì",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G064",
        Abitanti: "457",
      },
      {
        Istat: "91059",
        Comune: "Onifai",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G070",
        Abitanti: "757",
      },
      {
        Istat: "91060",
        Comune: "Oniferi",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G071",
        Abitanti: "930",
      },
      {
        Istat: "91061",
        Comune: "Orani",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08026",
        CodFisco: "G084",
        Abitanti: "3044",
      },
      {
        Istat: "91062",
        Comune: "Orgosolo",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08027",
        CodFisco: "G097",
        Abitanti: "4418",
      },
      {
        Istat: "95038",
        Comune: "Oristano",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09170",
        CodFisco: "G113",
        Abitanti: "32015",
      },
      {
        Istat: "91063",
        Comune: "Orosei",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08028",
        CodFisco: "G119",
        Abitanti: "6904",
      },
      {
        Istat: "91064",
        Comune: "Orotelli",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G120",
        Abitanti: "2157",
      },
      {
        Istat: "92118",
        Comune: "Orroli",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "G122",
        Abitanti: "2430",
      },
      {
        Istat: "92044",
        Comune: "Ortacesus",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "G133",
        Abitanti: "933",
      },
      {
        Istat: "91066",
        Comune: "Ortueri",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08036",
        CodFisco: "G146",
        Abitanti: "1270",
      },
      {
        Istat: "91067",
        Comune: "Orune",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G147",
        Abitanti: "2596",
      },
      {
        Istat: "104018",
        Comune: "Oschiri",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07027",
        CodFisco: "G153",
        Abitanti: "3472",
      },
      {
        Istat: "91068",
        Comune: "Osidda",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "08020",
        CodFisco: "G154",
        Abitanti: "266",
      },
      {
        Istat: "90050",
        Comune: "Osilo",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07033",
        CodFisco: "G156",
        Abitanti: "3228",
      },
      {
        Istat: "105013",
        Comune: "Osini",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "G158",
        Abitanti: "826",
      },
      {
        Istat: "90051",
        Comune: "Ossi",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07045",
        CodFisco: "G178",
        Abitanti: "5974",
      },
      {
        Istat: "91070",
        Comune: "Ottana",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G191",
        Abitanti: "2422",
      },
      {
        Istat: "91071",
        Comune: "Ovodda",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G201",
        Abitanti: "1651",
      },
      {
        Istat: "90052",
        Comune: "Ozieri",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07014",
        CodFisco: "G203",
        Abitanti: "10991",
      },
      {
        Istat: "106011",
        Comune: "Pabillonis",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09030",
        CodFisco: "G207",
        Abitanti: "2947",
      },
      {
        Istat: "90053",
        Comune: "Padria",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07015",
        CodFisco: "G225",
        Abitanti: "710",
      },
      {
        Istat: "104019",
        Comune: "Padru",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07020",
        CodFisco: "M301",
        Abitanti: "2174",
      },
      {
        Istat: "104020",
        Comune: "Palau",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07020",
        CodFisco: "G258",
        Abitanti: "4440",
      },
      {
        Istat: "95039",
        Comune: "Palmas Arborea",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "G286",
        Abitanti: "1484",
      },
      {
        Istat: "90055",
        Comune: "Pattada",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07016",
        CodFisco: "G376",
        Abitanti: "3283",
      },
      {
        Istat: "95040",
        Comune: "Pau",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "G379",
        Abitanti: "309",
      },
      {
        Istat: "106012",
        Comune: "Pauli Arbarei",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "G382",
        Abitanti: "648",
      },
      {
        Istat: "95041",
        Comune: "Paulilatino",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09070",
        CodFisco: "G384",
        Abitanti: "2377",
      },
      {
        Istat: "105014",
        Comune: "Perdasdefogu",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08046",
        CodFisco: "G445",
        Abitanti: "2122",
      },
      {
        Istat: "107014",
        Comune: "Perdaxius",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "G446",
        Abitanti: "1476",
      },
      {
        Istat: "90056",
        Comune: "Perfugas",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07034",
        CodFisco: "G450",
        Abitanti: "2486",
      },
      {
        Istat: "92048",
        Comune: "Pimentel",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "G669",
        Abitanti: "1201",
      },
      {
        Istat: "107015",
        Comune: "Piscinas",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "M291",
        Abitanti: "862",
      },
      {
        Istat: "90057",
        Comune: "Ploaghe",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07017",
        CodFisco: "G740",
        Abitanti: "4726",
      },
      {
        Istat: "95042",
        Comune: "Pompu",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09093",
        CodFisco: "G817",
        Abitanti: "282",
      },
      {
        Istat: "90058",
        Comune: "Porto Torres",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07046",
        CodFisco: "G924",
        Abitanti: "22567",
      },
      {
        Istat: "107016",
        Comune: "Portoscuso",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "G922",
        Abitanti: "5268",
      },
      {
        Istat: "91073",
        Comune: "Posada",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "G929",
        Abitanti: "2915",
      },
      {
        Istat: "90059",
        Comune: "Pozzomaggiore",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07018",
        CodFisco: "G962",
        Abitanti: "2755",
      },
      {
        Istat: "92050",
        Comune: "Pula",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09010",
        CodFisco: "H088",
        Abitanti: "7405",
      },
      {
        Istat: "90060",
        Comune: "Putifigari",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "H095",
        Abitanti: "753",
      },
      {
        Istat: "92051",
        Comune: "Quartu Sant'Elena",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09045",
        CodFisco: "H118",
        Abitanti: "71779",
      },
      {
        Istat: "92105",
        Comune: "Quartucciu",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09044",
        CodFisco: "H119",
        Abitanti: "12844",
      },
      {
        Istat: "95043",
        Comune: "Riola Sardo",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "H301",
        Abitanti: "2163",
      },
      {
        Istat: "90061",
        Comune: "Romana",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "H507",
        Abitanti: "585",
      },
      {
        Istat: "95044",
        Comune: "Ruinas",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09085",
        CodFisco: "F271",
        Abitanti: "732",
      },
      {
        Istat: "92119",
        Comune: "Sadali",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "H659",
        Abitanti: "928",
      },
      {
        Istat: "95086",
        Comune: "Sagama",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "H661",
        Abitanti: "190",
      },
      {
        Istat: "106013",
        Comune: "Samassi",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09030",
        CodFisco: "H738",
        Abitanti: "5338",
      },
      {
        Istat: "92053",
        Comune: "Samatzai",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "H739",
        Abitanti: "1749",
      },
      {
        Istat: "95045",
        Comune: "Samugheo",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09086",
        CodFisco: "H756",
        Abitanti: "3250",
      },
      {
        Istat: "92054",
        Comune: "San Basilio",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "H766",
        Abitanti: "1284",
      },
      {
        Istat: "106014",
        Comune: "San Gavino Monreale",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09037",
        CodFisco: "H856",
        Abitanti: "8960",
      },
      {
        Istat: "107017",
        Comune: "San Giovanni Suergiu",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "G287",
        Abitanti: "6040",
      },
      {
        Istat: "95046",
        Comune: "San Nicolò d'Arcidano",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09097",
        CodFisco: "A368",
        Abitanti: "2837",
      },
      {
        Istat: "92058",
        Comune: "San Nicolò Gerrei",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "G383",
        Abitanti: "881",
      },
      {
        Istat: "92059",
        Comune: "San Sperate",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09026",
        CodFisco: "I166",
        Abitanti: "7881",
      },
      {
        Istat: "104023",
        Comune: "San Teodoro",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "I329",
        Abitanti: "4342",
      },
      {
        Istat: "95050",
        Comune: "San Vero Milis",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "I384",
        Abitanti: "2547",
      },
      {
        Istat: "92064",
        Comune: "San Vito",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I402",
        Abitanti: "3860",
      },
      {
        Istat: "106015",
        Comune: "Sanluri",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09025",
        CodFisco: "H974",
        Abitanti: "8530",
      },
      {
        Istat: "95047",
        Comune: "Santa Giusta",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09096",
        CodFisco: "I205",
        Abitanti: "4837",
      },
      {
        Istat: "90087",
        Comune: "Santa Maria Coghinas",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "M284",
        Abitanti: "1454",
      },
      {
        Istat: "104022",
        Comune: "Santa Teresa Gallura",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07028",
        CodFisco: "I312",
        Abitanti: "5225",
      },
      {
        Istat: "090070",
        Comune: "Tempio Pausania",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "079",
        CAP: "07029",
        CodFisco: "",
        Abitanti: "13211",
      },
      {
        Istat: "107018",
        Comune: "Santadi",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "I182",
        Abitanti: "3604",
      },
      {
        Istat: "92061",
        Comune: "Sant'Andrea Frius",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I271",
        Abitanti: "1839",
      },
      {
        Istat: "107019",
        Comune: "Sant'Anna Arresi",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "M209",
        Abitanti: "2709",
      },
      {
        Istat: "107020",
        Comune: "Sant'Antioco",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09017",
        CodFisco: "I294",
        Abitanti: "11630",
      },
      {
        Istat: "104021",
        Comune: "Sant'Antonio di Gallura",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "M276",
        Abitanti: "1692",
      },
      {
        Istat: "95049",
        Comune: "Santu Lussurgiu",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09075",
        CodFisco: "I374",
        Abitanti: "2471",
      },
      {
        Istat: "106016",
        Comune: "Sardara",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09030",
        CodFisco: "I428",
        Abitanti: "4217",
      },
      {
        Istat: "92066",
        Comune: "Sarroch",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09018",
        CodFisco: "I443",
        Abitanti: "5273",
      },
      {
        Istat: "91077",
        Comune: "Sarule",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "I448",
        Abitanti: "1802",
      },
      {
        Istat: "90064",
        Comune: "Sassari",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07100",
        CodFisco: "I452",
        Abitanti: "130658",
      },
      {
        Istat: "95051",
        Comune: "Scano di Montiferro",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09078",
        CodFisco: "I503",
        Abitanti: "1592",
      },
      {
        Istat: "95052",
        Comune: "Sedilo",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09076",
        CodFisco: "I564",
        Abitanti: "2256",
      },
      {
        Istat: "90065",
        Comune: "Sedini",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07035",
        CodFisco: "I565",
        Abitanti: "1412",
      },
      {
        Istat: "106017",
        Comune: "Segariu",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I570",
        Abitanti: "1315",
      },
      {
        Istat: "92068",
        Comune: "Selargius",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09047",
        CodFisco: "I580",
        Abitanti: "29169",
      },
      {
        Istat: "92069",
        Comune: "Selegas",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I582",
        Abitanti: "1438",
      },
      {
        Istat: "90066",
        Comune: "Semestene",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "I598",
        Abitanti: "174",
      },
      {
        Istat: "95053",
        Comune: "Seneghe",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "I605",
        Abitanti: "1873",
      },
      {
        Istat: "95054",
        Comune: "Senis",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "I609",
        Abitanti: "484",
      },
      {
        Istat: "95055",
        Comune: "Sennariolo",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09078",
        CodFisco: "I613",
        Abitanti: "188",
      },
      {
        Istat: "90067",
        Comune: "Sennori",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07036",
        CodFisco: "I614",
        Abitanti: "7405",
      },
      {
        Istat: "92070",
        Comune: "Senorbì",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I615",
        Abitanti: "4745",
      },
      {
        Istat: "92071",
        Comune: "Serdiana",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I624",
        Abitanti: "2633",
      },
      {
        Istat: "106018",
        Comune: "Serramanna",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09038",
        CodFisco: "I647",
        Abitanti: "9333",
      },
      {
        Istat: "106019",
        Comune: "Serrenti",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09027",
        CodFisco: "I667",
        Abitanti: "5078",
      },
      {
        Istat: "92120",
        Comune: "Serri",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "I668",
        Abitanti: "686",
      },
      {
        Istat: "92074",
        Comune: "Sestu",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09028",
        CodFisco: "I695",
        Abitanti: "19921",
      },
      {
        Istat: "92075",
        Comune: "Settimo San Pietro",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I699",
        Abitanti: "6576",
      },
      {
        Istat: "106020",
        Comune: "Setzu",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09029",
        CodFisco: "I705",
        Abitanti: "146",
      },
      {
        Istat: "105015",
        Comune: "Seui",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08037",
        CodFisco: "I706",
        Abitanti: "1392",
      },
      {
        Istat: "92121",
        Comune: "Seulo",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "I707",
        Abitanti: "909",
      },
      {
        Istat: "95056",
        Comune: "Siamaggiore",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "I717",
        Abitanti: "983",
      },
      {
        Istat: "95057",
        Comune: "Siamanna",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "I718",
        Abitanti: "837",
      },
      {
        Istat: "95076",
        Comune: "Siapiccia",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "I721",
        Abitanti: "373",
      },
      {
        Istat: "106021",
        Comune: "Siddi",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "I724",
        Abitanti: "708",
      },
      {
        Istat: "91083",
        Comune: "Silanus",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08017",
        CodFisco: "I730",
        Abitanti: "2210",
      },
      {
        Istat: "90068",
        Comune: "Siligo",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "I732",
        Abitanti: "943",
      },
      {
        Istat: "92078",
        Comune: "Siliqua",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "I734",
        Abitanti: "3973",
      },
      {
        Istat: "92079",
        Comune: "Silius",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I735",
        Abitanti: "1280",
      },
      {
        Istat: "95058",
        Comune: "Simala",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "I742",
        Abitanti: "358",
      },
      {
        Istat: "95059",
        Comune: "Simaxis",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09088",
        CodFisco: "I743",
        Abitanti: "2286",
      },
      {
        Istat: "91084",
        Comune: "Sindia",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08018",
        CodFisco: "I748",
        Abitanti: "1845",
      },
      {
        Istat: "95060",
        Comune: "Sini",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "I749",
        Abitanti: "523",
      },
      {
        Istat: "91085",
        Comune: "Siniscola",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08029",
        CodFisco: "I751",
        Abitanti: "11687",
      },
      {
        Istat: "92080",
        Comune: "Sinnai",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09048",
        CodFisco: "I752",
        Abitanti: "16785",
      },
      {
        Istat: "95061",
        Comune: "Siris",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "I757",
        Abitanti: "231",
      },
      {
        Istat: "92081",
        Comune: "Siurgus Donigala",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I765",
        Abitanti: "2110",
      },
      {
        Istat: "95078",
        Comune: "Soddì",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09080",
        CodFisco: "I778",
        Abitanti: "122",
      },
      {
        Istat: "95062",
        Comune: "Solarussa",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09077",
        CodFisco: "I791",
        Abitanti: "2514",
      },
      {
        Istat: "92082",
        Comune: "Soleminis",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I797",
        Abitanti: "1854",
      },
      {
        Istat: "91086",
        Comune: "Sorgono",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08038",
        CodFisco: "I851",
        Abitanti: "1761",
      },
      {
        Istat: "95063",
        Comune: "Sorradile",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "I861",
        Abitanti: "433",
      },
      {
        Istat: "90069",
        Comune: "Sorso",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07037",
        CodFisco: "I863",
        Abitanti: "14841",
      },
      {
        Istat: "90089",
        Comune: "Stintino",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "M290",
        Abitanti: "1335",
      },
      {
        Istat: "92083",
        Comune: "Suelli",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "I995",
        Abitanti: "1149",
      },
      {
        Istat: "95087",
        Comune: "Suni",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "L006",
        Abitanti: "1131",
      },
      {
        Istat: "95064",
        Comune: "Tadasuni",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09080",
        CodFisco: "L023",
        Abitanti: "184",
      },
      {
        Istat: "105016",
        Comune: "Talana",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "L036",
        Abitanti: "1088",
      },
      {
        Istat: "104024",
        Comune: "Telti",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "789",
        CAP: "07020",
        CodFisco: "L088",
        Abitanti: "2217",
      },
      {
        Istat: "104025",
        Comune: "Tempio Pausania",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07029",
        CodFisco: "L093",
        Abitanti: "14290",
      },
      {
        Istat: "90086",
        Comune: "Tergu",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "M282",
        Abitanti: "622",
      },
      {
        Istat: "95065",
        Comune: "Terralba",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09098",
        CodFisco: "L122",
        Abitanti: "10305",
      },
      {
        Istat: "105017",
        Comune: "Tertenia",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08047",
        CodFisco: "L140",
        Abitanti: "3840",
      },
      {
        Istat: "91090",
        Comune: "Teti",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08030",
        CodFisco: "L153",
        Abitanti: "722",
      },
      {
        Istat: "92084",
        Comune: "Teulada",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09019",
        CodFisco: "L154",
        Abitanti: "3793",
      },
      {
        Istat: "90071",
        Comune: "Thiesi",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07047",
        CodFisco: "L158",
        Abitanti: "3010",
      },
      {
        Istat: "91091",
        Comune: "Tiana",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "L160",
        Abitanti: "529",
      },
      {
        Istat: "95088",
        Comune: "Tinnura",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "08010",
        CodFisco: "L172",
        Abitanti: "266",
      },
      {
        Istat: "90072",
        Comune: "Tissi",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "L180",
        Abitanti: "2300",
      },
      {
        Istat: "91093",
        Comune: "Tonara",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08039",
        CodFisco: "L202",
        Abitanti: "2145",
      },
      {
        Istat: "91094",
        Comune: "Torpè",
        Provincia: "NU",
        Regione: "SAR",
        Prefisso: "784",
        CAP: "08020",
        CodFisco: "L231",
        Abitanti: "2943",
      },
      {
        Istat: "90073",
        Comune: "Torralba",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07048",
        CodFisco: "L235",
        Abitanti: "1001",
      },
      {
        Istat: "105018",
        Comune: "Tortolì",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08048",
        CodFisco: "A355",
        Abitanti: "10838",
      },
      {
        Istat: "95066",
        Comune: "Tramatza",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "L321",
        Abitanti: "992",
      },
      {
        Istat: "107021",
        Comune: "Tratalias",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "L337",
        Abitanti: "1102",
      },
      {
        Istat: "95067",
        Comune: "Tresnuraghes",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "785",
        CAP: "09079",
        CodFisco: "L393",
        Abitanti: "1236",
      },
      {
        Istat: "105019",
        Comune: "Triei",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "L423",
        Abitanti: "1130",
      },
      {
        Istat: "104026",
        Comune: "Trinità d'Agultu e Vignola",
        Provincia: "OT",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07038",
        CodFisco: "L428",
        Abitanti: "2184",
      },
      {
        Istat: "106022",
        Comune: "Tuili",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09029",
        CodFisco: "L463",
        Abitanti: "1080",
      },
      {
        Istat: "90075",
        Comune: "Tula",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07010",
        CodFisco: "L464",
        Abitanti: "1611",
      },
      {
        Istat: "106023",
        Comune: "Turri",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09020",
        CodFisco: "L473",
        Abitanti: "447",
      },
      {
        Istat: "95068",
        Comune: "Ulà Tirso",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "L488",
        Abitanti: "589",
      },
      {
        Istat: "105020",
        Comune: "Ulassai",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "L489",
        Abitanti: "1550",
      },
      {
        Istat: "95069",
        Comune: "Uras",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09099",
        CodFisco: "L496",
        Abitanti: "2980",
      },
      {
        Istat: "90076",
        Comune: "Uri",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07040",
        CodFisco: "L503",
        Abitanti: "3053",
      },
      {
        Istat: "105021",
        Comune: "Urzulei",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "L506",
        Abitanti: "1326",
      },
      {
        Istat: "95070",
        Comune: "Usellus",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "L508",
        Abitanti: "868",
      },
      {
        Istat: "90077",
        Comune: "Usini",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07049",
        CodFisco: "L509",
        Abitanti: "4302",
      },
      {
        Istat: "92088",
        Comune: "Ussana",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "L512",
        Abitanti: "4257",
      },
      {
        Istat: "106024",
        Comune: "Ussaramanna",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09020",
        CodFisco: "L513",
        Abitanti: "572",
      },
      {
        Istat: "105022",
        Comune: "Ussassai",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08040",
        CodFisco: "L514",
        Abitanti: "625",
      },
      {
        Istat: "92090",
        Comune: "Uta",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09010",
        CodFisco: "L521",
        Abitanti: "7831",
      },
      {
        Istat: "90079",
        Comune: "Valledoria",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07039",
        CodFisco: "L604",
        Abitanti: "4198",
      },
      {
        Istat: "92091",
        Comune: "Vallermosa",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "L613",
        Abitanti: "1966",
      },
      {
        Istat: "90082",
        Comune: "Viddalba",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07030",
        CodFisco: "M259",
        Abitanti: "1708",
      },
      {
        Istat: "92099",
        Comune: "Villa San Pietro",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09010",
        CodFisco: "I118",
        Abitanti: "2010",
      },
      {
        Istat: "95048",
        Comune: "Villa Sant'Antonio",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "I298",
        Abitanti: "394",
      },
      {
        Istat: "95073",
        Comune: "Villa Verde",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09090",
        CodFisco: "A609",
        Abitanti: "343",
      },
      {
        Istat: "106025",
        Comune: "Villacidro",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09039",
        CodFisco: "L924",
        Abitanti: "14454",
      },
      {
        Istat: "105023",
        Comune: "Villagrande Strisaili",
        Provincia: "OG",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08049",
        CodFisco: "L953",
        Abitanti: "3421",
      },
      {
        Istat: "106026",
        Comune: "Villamar",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "L966",
        Abitanti: "2881",
      },
      {
        Istat: "107022",
        Comune: "Villamassargia",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "L968",
        Abitanti: "3684",
      },
      {
        Istat: "90078",
        Comune: "Villanova Monteleone",
        Provincia: "SS",
        Regione: "SAR",
        Prefisso: "79",
        CAP: "07019",
        CodFisco: "L989",
        Abitanti: "2405",
      },
      {
        Istat: "95071",
        Comune: "Villanova Truschedu",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09084",
        CodFisco: "L991",
        Abitanti: "339",
      },
      {
        Istat: "92122",
        Comune: "Villanova Tulo",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "782",
        CAP: "08030",
        CodFisco: "L992",
        Abitanti: "1164",
      },
      {
        Istat: "106027",
        Comune: "Villanovaforru",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "L986",
        Abitanti: "683",
      },
      {
        Istat: "106028",
        Comune: "Villanovafranca",
        Provincia: "VS",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09020",
        CodFisco: "L987",
        Abitanti: "1441",
      },
      {
        Istat: "107023",
        Comune: "Villaperuccio",
        Provincia: "CI",
        Regione: "SAR",
        Prefisso: "781",
        CAP: "09010",
        CodFisco: "M278",
        Abitanti: "1121",
      },
      {
        Istat: "92097",
        Comune: "Villaputzu",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "L998",
        Abitanti: "5078",
      },
      {
        Istat: "92098",
        Comune: "Villasalto",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09040",
        CodFisco: "M016",
        Abitanti: "1144",
      },
      {
        Istat: "92100",
        Comune: "Villasimius",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09049",
        CodFisco: "B738",
        Abitanti: "3635",
      },
      {
        Istat: "92101",
        Comune: "Villasor",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09034",
        CodFisco: "M025",
        Abitanti: "7008",
      },
      {
        Istat: "92102",
        Comune: "Villaspeciosa",
        Provincia: "CA",
        Regione: "SAR",
        Prefisso: "70",
        CAP: "09010",
        CodFisco: "M026",
        Abitanti: "2391",
      },
      {
        Istat: "95072",
        Comune: "Villaurbana",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09080",
        CodFisco: "M030",
        Abitanti: "1758",
      },
      {
        Istat: "95074",
        Comune: "Zeddiani",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "M153",
        Abitanti: "1176",
      },
      {
        Istat: "95075",
        Comune: "Zerfaliu",
        Provincia: "OR",
        Regione: "SAR",
        Prefisso: "783",
        CAP: "09070",
        CodFisco: "M168",
        Abitanti: "1181",
      },
      {
        Istat: "88001",
        Comune: "Acate",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97011",
        CodFisco: "A014",
        Abitanti: "9793",
      },
      {
        Istat: "87001",
        Comune: "Aci Bonaccorsi",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95020",
        CodFisco: "A025",
        Abitanti: "3223",
      },
      {
        Istat: "87002",
        Comune: "Aci Castello",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95021",
        CodFisco: "A026",
        Abitanti: "18031",
      },
      {
        Istat: "87003",
        Comune: "Aci Catena",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95022",
        CodFisco: "A027",
        Abitanti: "28920",
      },
      {
        Istat: "87005",
        Comune: "Aci Sant'Antonio",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95025",
        CodFisco: "A029",
        Abitanti: "17610",
      },
      {
        Istat: "87004",
        Comune: "Acireale",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95024",
        CodFisco: "A028",
        Abitanti: "53122",
      },
      {
        Istat: "85001",
        Comune: "Acquaviva Platani",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "A049",
        Abitanti: "1028",
      },
      {
        Istat: "83107",
        Comune: "Acquedolci",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "M211",
        Abitanti: "5652",
      },
      {
        Istat: "87006",
        Comune: "Adrano",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95031",
        CodFisco: "A056",
        Abitanti: "36779",
      },
      {
        Istat: "86001",
        Comune: "Agira",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94011",
        CodFisco: "A070",
        Abitanti: "8282",
      },
      {
        Istat: "84001",
        Comune: "Agrigento",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92100",
        CodFisco: "A089",
        Abitanti: "59175",
      },
      {
        Istat: "86002",
        Comune: "Aidone",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "A098",
        Abitanti: "5083",
      },
      {
        Istat: "81001",
        Comune: "Alcamo",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91011",
        CodFisco: "A176",
        Abitanti: "45835",
      },
      {
        Istat: "83001",
        Comune: "Alcara Li Fusi",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "A177",
        Abitanti: "2116",
      },
      {
        Istat: "84002",
        Comune: "Alessandria della Rocca",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92010",
        CodFisco: "A181",
        Abitanti: "3109",
      },
      {
        Istat: "83002",
        Comune: "Alì",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98020",
        CodFisco: "A194",
        Abitanti: "834",
      },
      {
        Istat: "83003",
        Comune: "Alì Terme",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98021",
        CodFisco: "A201",
        Abitanti: "2581",
      },
      {
        Istat: "82001",
        Comune: "Alia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90021",
        CodFisco: "A195",
        Abitanti: "3907",
      },
      {
        Istat: "82002",
        Comune: "Alimena",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90020",
        CodFisco: "A202",
        Abitanti: "2187",
      },
      {
        Istat: "82003",
        Comune: "Aliminusa",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90020",
        CodFisco: "A203",
        Abitanti: "1334",
      },
      {
        Istat: "82004",
        Comune: "Altavilla Milicia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90010",
        CodFisco: "A229",
        Abitanti: "7177",
      },
      {
        Istat: "82005",
        Comune: "Altofonte",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "A239",
        Abitanti: "10316",
      },
      {
        Istat: "83004",
        Comune: "Antillo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "A313",
        Abitanti: "966",
      },
      {
        Istat: "84003",
        Comune: "Aragona",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92021",
        CodFisco: "A351",
        Abitanti: "9626",
      },
      {
        Istat: "86003",
        Comune: "Assoro",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "A478",
        Abitanti: "5389",
      },
      {
        Istat: "89001",
        Comune: "Augusta",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96011",
        CodFisco: "A494",
        Abitanti: "34539",
      },
      {
        Istat: "89002",
        Comune: "Avola",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96012",
        CodFisco: "A522",
        Abitanti: "31827",
      },
      {
        Istat: "82006",
        Comune: "Bagheria",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90011",
        CodFisco: "A546",
        Abitanti: "56336",
      },
      {
        Istat: "82007",
        Comune: "Balestrate",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90041",
        CodFisco: "A592",
        Abitanti: "6598",
      },
      {
        Istat: "83005",
        Comune: "Barcellona Pozzo di Gotto",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98051",
        CodFisco: "A638",
        Abitanti: "41897",
      },
      {
        Istat: "86004",
        Comune: "Barrafranca",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "94012",
        CodFisco: "A676",
        Abitanti: "13053",
      },
      {
        Istat: "83006",
        Comune: "Basicò",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "A698",
        Abitanti: "692",
      },
      {
        Istat: "82008",
        Comune: "Baucina",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90020",
        CodFisco: "A719",
        Abitanti: "2008",
      },
      {
        Istat: "82009",
        Comune: "Belmonte Mezzagno",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90031",
        CodFisco: "A764",
        Abitanti: "11146",
      },
      {
        Istat: "87007",
        Comune: "Belpasso",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95032",
        CodFisco: "A766",
        Abitanti: "25404",
      },
      {
        Istat: "87008",
        Comune: "Biancavilla",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95033",
        CodFisco: "A841",
        Abitanti: "23947",
      },
      {
        Istat: "82010",
        Comune: "Bisacquino",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90032",
        CodFisco: "A882",
        Abitanti: "4882",
      },
      {
        Istat: "84004",
        Comune: "Bivona",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92010",
        CodFisco: "A896",
        Abitanti: "3952",
      },
      {
        Istat: "82082",
        Comune: "Blufi",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90020",
        CodFisco: "M268",
        Abitanti: "1094",
      },
      {
        Istat: "82011",
        Comune: "Bolognetta",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "A946",
        Abitanti: "4096",
      },
      {
        Istat: "85002",
        Comune: "Bompensiere",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "A957",
        Abitanti: "624",
      },
      {
        Istat: "82012",
        Comune: "Bompietro",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90020",
        CodFisco: "A958",
        Abitanti: "1503",
      },
      {
        Istat: "82013",
        Comune: "Borgetto",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90042",
        CodFisco: "A991",
        Abitanti: "7237",
      },
      {
        Istat: "83007",
        Comune: "Brolo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98061",
        CodFisco: "B198",
        Abitanti: "5846",
      },
      {
        Istat: "87009",
        Comune: "Bronte",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95034",
        CodFisco: "B202",
        Abitanti: "19437",
      },
      {
        Istat: "89003",
        Comune: "Buccheri",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "B237",
        Abitanti: "2148",
      },
      {
        Istat: "84005",
        Comune: "Burgio",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92010",
        CodFisco: "B275",
        Abitanti: "2801",
      },
      {
        Istat: "89004",
        Comune: "Buscemi",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "B287",
        Abitanti: "1147",
      },
      {
        Istat: "81002",
        Comune: "Buseto Palizzolo",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91012",
        CodFisco: "B288",
        Abitanti: "3095",
      },
      {
        Istat: "85003",
        Comune: "Butera",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93011",
        CodFisco: "B302",
        Abitanti: "4992",
      },
      {
        Istat: "82014",
        Comune: "Caccamo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90012",
        CodFisco: "B315",
        Abitanti: "8382",
      },
      {
        Istat: "84006",
        Comune: "Calamonaci",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92010",
        CodFisco: "B377",
        Abitanti: "1387",
      },
      {
        Istat: "86005",
        Comune: "Calascibetta",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "B381",
        Abitanti: "4685",
      },
      {
        Istat: "87010",
        Comune: "Calatabiano",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95011",
        CodFisco: "B384",
        Abitanti: "5437",
      },
      {
        Istat: "81003",
        Comune: "Calatafimi Segesta",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91013",
        CodFisco: "B385",
        Abitanti: "7055",
      },
      {
        Istat: "84007",
        Comune: "Caltabellotta",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92010",
        CodFisco: "B427",
        Abitanti: "3974",
      },
      {
        Istat: "87011",
        Comune: "Caltagirone",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95041",
        CodFisco: "B428",
        Abitanti: "39573",
      },
      {
        Istat: "85004",
        Comune: "Caltanissetta",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93100",
        CodFisco: "B429",
        Abitanti: "60267",
      },
      {
        Istat: "82015",
        Comune: "Caltavuturo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90022",
        CodFisco: "B430",
        Abitanti: "4219",
      },
      {
        Istat: "84008",
        Comune: "Camastra",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "B460",
        Abitanti: "2076",
      },
      {
        Istat: "84009",
        Comune: "Cammarata",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92022",
        CodFisco: "B486",
        Abitanti: "6453",
      },
      {
        Istat: "84010",
        Comune: "Campobello di Licata",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92023",
        CodFisco: "B520",
        Abitanti: "10328",
      },
      {
        Istat: "81004",
        Comune: "Campobello di Mazara",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91021",
        CodFisco: "B521",
        Abitanti: "10812",
      },
      {
        Istat: "82016",
        Comune: "Campofelice di Fitalia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "B533",
        Abitanti: "553",
      },
      {
        Istat: "82017",
        Comune: "Campofelice di Roccella",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90010",
        CodFisco: "B532",
        Abitanti: "6939",
      },
      {
        Istat: "82018",
        Comune: "Campofiorito",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "B535",
        Abitanti: "1353",
      },
      {
        Istat: "85005",
        Comune: "Campofranco",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "B537",
        Abitanti: "3259",
      },
      {
        Istat: "82019",
        Comune: "Camporeale",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "90043",
        CodFisco: "B556",
        Abitanti: "3489",
      },
      {
        Istat: "87012",
        Comune: "Camporotondo Etneo",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95040",
        CodFisco: "B561",
        Abitanti: "4464",
      },
      {
        Istat: "84011",
        Comune: "Canicattì",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92024",
        CodFisco: "B602",
        Abitanti: "35097",
      },
      {
        Istat: "89005",
        Comune: "Canicattini Bagni",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "B603",
        Abitanti: "7355",
      },
      {
        Istat: "82020",
        Comune: "Capaci",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "B645",
        Abitanti: "10623",
      },
      {
        Istat: "83008",
        Comune: "Capizzi",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "98031",
        CodFisco: "B660",
        Abitanti: "3389",
      },
      {
        Istat: "83009",
        Comune: "Capo d'Orlando",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98071",
        CodFisco: "B666",
        Abitanti: "13221",
      },
      {
        Istat: "83010",
        Comune: "Capri Leone",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "B695",
        Abitanti: "4566",
      },
      {
        Istat: "82021",
        Comune: "Carini",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90044",
        CodFisco: "B780",
        Abitanti: "36106",
      },
      {
        Istat: "89006",
        Comune: "Carlentini",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "96013",
        CodFisco: "B787",
        Abitanti: "17587",
      },
      {
        Istat: "83011",
        Comune: "Caronia",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98072",
        CodFisco: "B804",
        Abitanti: "3426",
      },
      {
        Istat: "83012",
        Comune: "Casalvecchio Siculo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98032",
        CodFisco: "B918",
        Abitanti: "945",
      },
      {
        Istat: "89007",
        Comune: "Cassaro",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "C006",
        Abitanti: "819",
      },
      {
        Istat: "87013",
        Comune: "Castel di Iudica",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95040",
        CodFisco: "C091",
        Abitanti: "4726",
      },
      {
        Istat: "83013",
        Comune: "Castel di Lucio",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98070",
        CodFisco: "C094",
        Abitanti: "1390",
      },
      {
        Istat: "82022",
        Comune: "Castelbuono",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90013",
        CodFisco: "C067",
        Abitanti: "9301",
      },
      {
        Istat: "82023",
        Comune: "Casteldaccia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90014",
        CodFisco: "C074",
        Abitanti: "11233",
      },
      {
        Istat: "81005",
        Comune: "Castellammare del Golfo",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91014",
        CodFisco: "C130",
        Abitanti: "15293",
      },
      {
        Istat: "82024",
        Comune: "Castellana Sicula",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90020",
        CodFisco: "C135",
        Abitanti: "3612",
      },
      {
        Istat: "83014",
        Comune: "Castell'Umberto",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "C051",
        Abitanti: "3337",
      },
      {
        Istat: "83015",
        Comune: "Castelmola",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "C210",
        Abitanti: "1082",
      },
      {
        Istat: "84012",
        Comune: "Casteltermini",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92025",
        CodFisco: "C275",
        Abitanti: "8487",
      },
      {
        Istat: "81006",
        Comune: "Castelvetrano",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91022",
        CodFisco: "C286",
        Abitanti: "30735",
      },
      {
        Istat: "87014",
        Comune: "Castiglione di Sicilia",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "95012",
        CodFisco: "C297",
        Abitanti: "3366",
      },
      {
        Istat: "84013",
        Comune: "Castrofilippo",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "C341",
        Abitanti: "3034",
      },
      {
        Istat: "82025",
        Comune: "Castronovo di Sicilia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "C344",
        Abitanti: "3213",
      },
      {
        Istat: "83016",
        Comune: "Castroreale",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98053",
        CodFisco: "C347",
        Abitanti: "2654",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95121",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95122",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95123",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95124",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95125",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95126",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95127",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95128",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95129",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95130",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "87015",
        Comune: "Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95131",
        CodFisco: "C351",
        Abitanti: "293458",
      },
      {
        Istat: "86006",
        Comune: "Catenanuova",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "C353",
        Abitanti: "5079",
      },
      {
        Istat: "84014",
        Comune: "Cattolica Eraclea",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92011",
        CodFisco: "C356",
        Abitanti: "3994",
      },
      {
        Istat: "82026",
        Comune: "Cefalà Diana",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "C420",
        Abitanti: "1014",
      },
      {
        Istat: "82027",
        Comune: "Cefalù",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90015",
        CodFisco: "C421",
        Abitanti: "13807",
      },
      {
        Istat: "86007",
        Comune: "Centuripe",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "C471",
        Abitanti: "5645",
      },
      {
        Istat: "86008",
        Comune: "Cerami",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "C480",
        Abitanti: "2196",
      },
      {
        Istat: "82028",
        Comune: "Cerda",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90010",
        CodFisco: "C496",
        Abitanti: "5369",
      },
      {
        Istat: "83017",
        Comune: "Cesarò",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "98033",
        CodFisco: "C568",
        Abitanti: "2585",
      },
      {
        Istat: "88002",
        Comune: "Chiaramonte Gulfi",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97012",
        CodFisco: "C612",
        Abitanti: "8218",
      },
      {
        Istat: "82029",
        Comune: "Chiusa Sclafani",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90033",
        CodFisco: "C654",
        Abitanti: "2994",
      },
      {
        Istat: "84015",
        Comune: "Cianciana",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92012",
        CodFisco: "C668",
        Abitanti: "3539",
      },
      {
        Istat: "82030",
        Comune: "Ciminna",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90023",
        CodFisco: "C696",
        Abitanti: "3877",
      },
      {
        Istat: "82031",
        Comune: "Cinisi",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90045",
        CodFisco: "C708",
        Abitanti: "12047",
      },
      {
        Istat: "82032",
        Comune: "Collesano",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90016",
        CodFisco: "C871",
        Abitanti: "4118",
      },
      {
        Istat: "88003",
        Comune: "Comiso",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97013",
        CodFisco: "C927",
        Abitanti: "30577",
      },
      {
        Istat: "84016",
        Comune: "Comitini",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "C928",
        Abitanti: "946",
      },
      {
        Istat: "83018",
        Comune: "Condrò",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98040",
        CodFisco: "C956",
        Abitanti: "495",
      },
      {
        Istat: "82033",
        Comune: "Contessa Entellina",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "C968",
        Abitanti: "1917",
      },
      {
        Istat: "82034",
        Comune: "Corleone",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90034",
        CodFisco: "D009",
        Abitanti: "11373",
      },
      {
        Istat: "81007",
        Comune: "Custonaci",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91015",
        CodFisco: "D234",
        Abitanti: "5449",
      },
      {
        Istat: "85006",
        Comune: "Delia",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "93010",
        CodFisco: "D267",
        Abitanti: "4558",
      },
      {
        Istat: "86009",
        Comune: "Enna",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94100",
        CodFisco: "C342",
        Abitanti: "27850",
      },
      {
        Istat: "81008",
        Comune: "Erice",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91016",
        CodFisco: "D423",
        Abitanti: "28583",
      },
      {
        Istat: "83019",
        Comune: "Falcone",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "D474",
        Abitanti: "2927",
      },
      {
        Istat: "84017",
        Comune: "Favara",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92026",
        CodFisco: "D514",
        Abitanti: "33857",
      },
      {
        Istat: "81009",
        Comune: "Favignana",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91023",
        CodFisco: "D518",
        Abitanti: "4314",
      },
      {
        Istat: "89008",
        Comune: "Ferla",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "D540",
        Abitanti: "2599",
      },
      {
        Istat: "82035",
        Comune: "Ficarazzi",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90010",
        CodFisco: "D567",
        Abitanti: "11997",
      },
      {
        Istat: "83020",
        Comune: "Ficarra",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98062",
        CodFisco: "D569",
        Abitanti: "1593",
      },
      {
        Istat: "83021",
        Comune: "Fiumedinisi",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98022",
        CodFisco: "D622",
        Abitanti: "1545",
      },
      {
        Istat: "87016",
        Comune: "Fiumefreddo di Sicilia",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95013",
        CodFisco: "D623",
        Abitanti: "9835",
      },
      {
        Istat: "83022",
        Comune: "Floresta",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98030",
        CodFisco: "D635",
        Abitanti: "542",
      },
      {
        Istat: "89009",
        Comune: "Floridia",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96014",
        CodFisco: "D636",
        Abitanti: "23050",
      },
      {
        Istat: "83023",
        Comune: "Fondachelli-Fantina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98050",
        CodFisco: "D661",
        Abitanti: "1113",
      },
      {
        Istat: "83024",
        Comune: "Forza d'Agrò",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "D733",
        Abitanti: "922",
      },
      {
        Istat: "83025",
        Comune: "Francavilla di Sicilia",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98034",
        CodFisco: "D765",
        Abitanti: "4084",
      },
      {
        Istat: "89010",
        Comune: "Francofonte",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "96015",
        CodFisco: "D768",
        Abitanti: "12392",
      },
      {
        Istat: "83026",
        Comune: "Frazzanò",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "D793",
        Abitanti: "804",
      },
      {
        Istat: "83027",
        Comune: "Furci Siculo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98023",
        CodFisco: "D824",
        Abitanti: "3405",
      },
      {
        Istat: "83028",
        Comune: "Furnari",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98054",
        CodFisco: "D825",
        Abitanti: "3671",
      },
      {
        Istat: "83029",
        Comune: "Gaggi",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "D844",
        Abitanti: "3149",
      },
      {
        Istat: "86010",
        Comune: "Gagliano Castelferrato",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "D849",
        Abitanti: "3731",
      },
      {
        Istat: "83030",
        Comune: "Galati Mamertino",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "D861",
        Abitanti: "2843",
      },
      {
        Istat: "83031",
        Comune: "Gallodoro",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "D885",
        Abitanti: "389",
      },
      {
        Istat: "82036",
        Comune: "Gangi",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90024",
        CodFisco: "D907",
        Abitanti: "7102",
      },
      {
        Istat: "85007",
        Comune: "Gela",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "93012",
        CodFisco: "D960",
        Abitanti: "77360",
      },
      {
        Istat: "82037",
        Comune: "Geraci Siculo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90010",
        CodFisco: "D977",
        Abitanti: "1943",
      },
      {
        Istat: "82038",
        Comune: "Giardinello",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "E013",
        Abitanti: "2260",
      },
      {
        Istat: "83032",
        Comune: "Giardini-Naxos",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98035",
        CodFisco: "E014",
        Abitanti: "9647",
      },
      {
        Istat: "88004",
        Comune: "Giarratana",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97010",
        CodFisco: "E016",
        Abitanti: "3172",
      },
      {
        Istat: "87017",
        Comune: "Giarre",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95014",
        CodFisco: "E017",
        Abitanti: "27785",
      },
      {
        Istat: "81010",
        Comune: "Gibellina",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91024",
        CodFisco: "E023",
        Abitanti: "4298",
      },
      {
        Istat: "83033",
        Comune: "Gioiosa Marea",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98063",
        CodFisco: "E043",
        Abitanti: "7209",
      },
      {
        Istat: "82039",
        Comune: "Giuliana",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "E055",
        Abitanti: "2087",
      },
      {
        Istat: "82040",
        Comune: "Godrano",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "E074",
        Abitanti: "1175",
      },
      {
        Istat: "87018",
        Comune: "Grammichele",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95042",
        CodFisco: "E133",
        Abitanti: "13404",
      },
      {
        Istat: "83034",
        Comune: "Graniti",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98036",
        CodFisco: "E142",
        Abitanti: "1547",
      },
      {
        Istat: "82041",
        Comune: "Gratteri",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90010",
        CodFisco: "E149",
        Abitanti: "1016",
      },
      {
        Istat: "87019",
        Comune: "Gravina di Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "E156",
        Abitanti: "27363",
      },
      {
        Istat: "84018",
        Comune: "Grotte",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "E209",
        Abitanti: "5911",
      },
      {
        Istat: "83035",
        Comune: "Gualtieri Sicaminò",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98040",
        CodFisco: "E233",
        Abitanti: "1846",
      },
      {
        Istat: "82042",
        Comune: "Isnello",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90010",
        CodFisco: "E337",
        Abitanti: "1638",
      },
      {
        Istat: "82043",
        Comune: "Isola delle Femmine",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "E350",
        Abitanti: "7336",
      },
      {
        Istat: "88005",
        Comune: "Ispica",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97014",
        CodFisco: "E366",
        Abitanti: "15554",
      },
      {
        Istat: "83036",
        Comune: "Itala",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98025",
        CodFisco: "E374",
        Abitanti: "1675",
      },
      {
        Istat: "84019",
        Comune: "Joppolo Giancaxio",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92010",
        CodFisco: "E390",
        Abitanti: "1243",
      },
      {
        Istat: "84020",
        Comune: "Lampedusa e Linosa",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92010",
        CodFisco: "E431",
        Abitanti: "6299",
      },
      {
        Istat: "82044",
        Comune: "Lascari",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90010",
        CodFisco: "E459",
        Abitanti: "3489",
      },
      {
        Istat: "83037",
        Comune: "Leni",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98050",
        CodFisco: "E523",
        Abitanti: "697",
      },
      {
        Istat: "89011",
        Comune: "Lentini",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "96016",
        CodFisco: "E532",
        Abitanti: "24017",
      },
      {
        Istat: "86011",
        Comune: "Leonforte",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94013",
        CodFisco: "E536",
        Abitanti: "13954",
      },
      {
        Istat: "82045",
        Comune: "Lercara Friddi",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90025",
        CodFisco: "E541",
        Abitanti: "6984",
      },
      {
        Istat: "83038",
        Comune: "Letojanni",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98037",
        CodFisco: "E555",
        Abitanti: "2767",
      },
      {
        Istat: "83039",
        Comune: "Librizzi",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98064",
        CodFisco: "E571",
        Abitanti: "1812",
      },
      {
        Istat: "84021",
        Comune: "Licata",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92027",
        CodFisco: "E573",
        Abitanti: "39082",
      },
      {
        Istat: "87020",
        Comune: "Licodia Eubea",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95040",
        CodFisco: "E578",
        Abitanti: "3058",
      },
      {
        Istat: "83040",
        Comune: "Limina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "E594",
        Abitanti: "912",
      },
      {
        Istat: "87021",
        Comune: "Linguaglossa",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95015",
        CodFisco: "E602",
        Abitanti: "5462",
      },
      {
        Istat: "83041",
        Comune: "Lipari",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98055",
        CodFisco: "E606",
        Abitanti: "11386",
      },
      {
        Istat: "83042",
        Comune: "Longi",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "E674",
        Abitanti: "1583",
      },
      {
        Istat: "84022",
        Comune: "Lucca Sicula",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92010",
        CodFisco: "E714",
        Abitanti: "1905",
      },
      {
        Istat: "87022",
        Comune: "Maletto",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95035",
        CodFisco: "E854",
        Abitanti: "4061",
      },
      {
        Istat: "83043",
        Comune: "Malfa",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98050",
        CodFisco: "E855",
        Abitanti: "943",
      },
      {
        Istat: "83044",
        Comune: "Malvagna",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "E869",
        Abitanti: "821",
      },
      {
        Istat: "83045",
        Comune: "Mandanici",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98020",
        CodFisco: "E876",
        Abitanti: "653",
      },
      {
        Istat: "87057",
        Comune: "Maniace",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "M283",
        Abitanti: "3682",
      },
      {
        Istat: "85008",
        Comune: "Marianopoli",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "E953",
        Abitanti: "2054",
      },
      {
        Istat: "82046",
        Comune: "Marineo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90035",
        CodFisco: "E957",
        Abitanti: "6791",
      },
      {
        Istat: "81011",
        Comune: "Marsala",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91025",
        CodFisco: "E974",
        Abitanti: "82774",
      },
      {
        Istat: "87023",
        Comune: "Mascali",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95016",
        CodFisco: "F004",
        Abitanti: "13864",
      },
      {
        Istat: "87024",
        Comune: "Mascalucia",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "F005",
        Abitanti: "29056",
      },
      {
        Istat: "81012",
        Comune: "Mazara del Vallo",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91026",
        CodFisco: "F061",
        Abitanti: "51492",
      },
      {
        Istat: "85009",
        Comune: "Mazzarino",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93013",
        CodFisco: "F065",
        Abitanti: "11946",
      },
      {
        Istat: "83046",
        Comune: "Mazzarrà Sant'Andrea",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98056",
        CodFisco: "F066",
        Abitanti: "1589",
      },
      {
        Istat: "87056",
        Comune: "Mazzarrone",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95040",
        CodFisco: "M271",
        Abitanti: "4001",
      },
      {
        Istat: "89012",
        Comune: "Melilli",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "F107",
        Abitanti: "13304",
      },
      {
        Istat: "84023",
        Comune: "Menfi",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92013",
        CodFisco: "F126",
        Abitanti: "12812",
      },
      {
        Istat: "83047",
        Comune: "Merì",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98040",
        CodFisco: "F147",
        Abitanti: "2407",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98121",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98122",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98123",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98124",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98125",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98126",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98127",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98128",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98129",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98130",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98131",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98132",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98133",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98134",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98135",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98136",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98137",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98138",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98139",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98140",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98141",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98142",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98143",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98144",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98145",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98146",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98147",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98148",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98149",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98150",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98151",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98152",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98153",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98154",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98155",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98156",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98157",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98158",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98159",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98160",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98161",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98162",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98163",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98164",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98165",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98166",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98167",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "83048",
        Comune: "Messina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98168",
        CodFisco: "F158",
        Abitanti: "242503",
      },
      {
        Istat: "82047",
        Comune: "Mezzojuso",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "F184",
        Abitanti: "2985",
      },
      {
        Istat: "83049",
        Comune: "Milazzo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98057",
        CodFisco: "F206",
        Abitanti: "32601",
      },
      {
        Istat: "85010",
        Comune: "Milena",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "E618",
        Abitanti: "3201",
      },
      {
        Istat: "87025",
        Comune: "Militello in Val di Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95043",
        CodFisco: "F209",
        Abitanti: "7933",
      },
      {
        Istat: "83050",
        Comune: "Militello Rosmarino",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "F210",
        Abitanti: "1337",
      },
      {
        Istat: "87026",
        Comune: "Milo",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95010",
        CodFisco: "F214",
        Abitanti: "1089",
      },
      {
        Istat: "87027",
        Comune: "Mineo",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95044",
        CodFisco: "F217",
        Abitanti: "5349",
      },
      {
        Istat: "87028",
        Comune: "Mirabella Imbaccari",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95040",
        CodFisco: "F231",
        Abitanti: "5435",
      },
      {
        Istat: "83051",
        Comune: "Mirto",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "F242",
        Abitanti: "1016",
      },
      {
        Istat: "82048",
        Comune: "Misilmeri",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90036",
        CodFisco: "F246",
        Abitanti: "28074",
      },
      {
        Istat: "87029",
        Comune: "Misterbianco",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95045",
        CodFisco: "F250",
        Abitanti: "49424",
      },
      {
        Istat: "83052",
        Comune: "Mistretta",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98073",
        CodFisco: "F251",
        Abitanti: "5079",
      },
      {
        Istat: "88006",
        Comune: "Modica",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97015",
        CodFisco: "F258",
        Abitanti: "55196",
      },
      {
        Istat: "83053",
        Comune: "Moio Alcantara",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "F277",
        Abitanti: "753",
      },
      {
        Istat: "83054",
        Comune: "Monforte San Giorgio",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98041",
        CodFisco: "F359",
        Abitanti: "2911",
      },
      {
        Istat: "83055",
        Comune: "Mongiuffi Melia",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "F368",
        Abitanti: "670",
      },
      {
        Istat: "82049",
        Comune: "Monreale",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90046",
        CodFisco: "F377",
        Abitanti: "38204",
      },
      {
        Istat: "83056",
        Comune: "Montagnareale",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "F395",
        Abitanti: "1676",
      },
      {
        Istat: "83057",
        Comune: "Montalbano Elicona",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98065",
        CodFisco: "F400",
        Abitanti: "2488",
      },
      {
        Istat: "84024",
        Comune: "Montallegro",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92010",
        CodFisco: "F414",
        Abitanti: "2557",
      },
      {
        Istat: "85011",
        Comune: "Montedoro",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "F489",
        Abitanti: "1670",
      },
      {
        Istat: "82050",
        Comune: "Montelepre",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "F544",
        Abitanti: "6459",
      },
      {
        Istat: "82051",
        Comune: "Montemaggiore Belsito",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90020",
        CodFisco: "F553",
        Abitanti: "3574",
      },
      {
        Istat: "88007",
        Comune: "Monterosso Almo",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97010",
        CodFisco: "F610",
        Abitanti: "3229",
      },
      {
        Istat: "84025",
        Comune: "Montevago",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92010",
        CodFisco: "F655",
        Abitanti: "3039",
      },
      {
        Istat: "83058",
        Comune: "Motta Camastra",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "F772",
        Abitanti: "894",
      },
      {
        Istat: "83059",
        Comune: "Motta d'Affermo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98070",
        CodFisco: "F773",
        Abitanti: "850",
      },
      {
        Istat: "87030",
        Comune: "Motta Sant'Anastasia",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95040",
        CodFisco: "F781",
        Abitanti: "11924",
      },
      {
        Istat: "85012",
        Comune: "Mussomeli",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93014",
        CodFisco: "F830",
        Abitanti: "11145",
      },
      {
        Istat: "84026",
        Comune: "Naro",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92028",
        CodFisco: "F845",
        Abitanti: "8245",
      },
      {
        Istat: "83060",
        Comune: "Naso",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98074",
        CodFisco: "F848",
        Abitanti: "4107",
      },
      {
        Istat: "87031",
        Comune: "Nicolosi",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "F890",
        Abitanti: "7229",
      },
      {
        Istat: "86012",
        Comune: "Nicosia",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94014",
        CodFisco: "F892",
        Abitanti: "14547",
      },
      {
        Istat: "85013",
        Comune: "Niscemi",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "93015",
        CodFisco: "F899",
        Abitanti: "26496",
      },
      {
        Istat: "86013",
        Comune: "Nissoria",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "F900",
        Abitanti: "3011",
      },
      {
        Istat: "83061",
        Comune: "Nizza di Sicilia",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98026",
        CodFisco: "F901",
        Abitanti: "3782",
      },
      {
        Istat: "89013",
        Comune: "Noto",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96017",
        CodFisco: "F943",
        Abitanti: "24047",
      },
      {
        Istat: "83062",
        Comune: "Novara di Sicilia",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98058",
        CodFisco: "F951",
        Abitanti: "1447",
      },
      {
        Istat: "83063",
        Comune: "Oliveri",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "G036",
        Abitanti: "2168",
      },
      {
        Istat: "83064",
        Comune: "Pace del Mela",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98042",
        CodFisco: "G209",
        Abitanti: "6414",
      },
      {
        Istat: "81013",
        Comune: "Paceco",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91027",
        CodFisco: "G208",
        Abitanti: "11429",
      },
      {
        Istat: "89014",
        Comune: "Pachino",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96018",
        CodFisco: "G211",
        Abitanti: "21990",
      },
      {
        Istat: "83065",
        Comune: "Pagliara",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98020",
        CodFisco: "G234",
        Abitanti: "1251",
      },
      {
        Istat: "87032",
        Comune: "Palagonia",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95046",
        CodFisco: "G253",
        Abitanti: "16547",
      },
      {
        Istat: "82052",
        Comune: "Palazzo Adriano",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "G263",
        Abitanti: "2262",
      },
      {
        Istat: "89015",
        Comune: "Palazzolo Acreide",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "G267",
        Abitanti: "9061",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90121",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90122",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90123",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90124",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90125",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90126",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90127",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90128",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90129",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90130",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90131",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90132",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90133",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90134",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90135",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90136",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90137",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90138",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90139",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90140",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90141",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90142",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90143",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90144",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90145",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90146",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90147",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90148",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90149",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90150",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "82053",
        Comune: "Palermo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90151",
        CodFisco: "G273",
        Abitanti: "655875",
      },
      {
        Istat: "84027",
        Comune: "Palma di Montechiaro",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "G282",
        Abitanti: "24109",
      },
      {
        Istat: "81014",
        Comune: "Pantelleria",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91017",
        CodFisco: "G315",
        Abitanti: "7846",
      },
      {
        Istat: "81015",
        Comune: "Partanna",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91028",
        CodFisco: "G347",
        Abitanti: "11168",
      },
      {
        Istat: "82054",
        Comune: "Partinico",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90047",
        CodFisco: "G348",
        Abitanti: "31885",
      },
      {
        Istat: "87033",
        Comune: "Paternò",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95047",
        CodFisco: "G371",
        Abitanti: "49578",
      },
      {
        Istat: "83066",
        Comune: "Patti",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98066",
        CodFisco: "G377",
        Abitanti: "13611",
      },
      {
        Istat: "87034",
        Comune: "Pedara",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "G402",
        Abitanti: "13087",
      },
      {
        Istat: "82055",
        Comune: "Petralia Soprana",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90026",
        CodFisco: "G510",
        Abitanti: "3469",
      },
      {
        Istat: "82056",
        Comune: "Petralia Sottana",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90027",
        CodFisco: "G511",
        Abitanti: "2980",
      },
      {
        Istat: "81024",
        Comune: "Petrosino",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91020",
        CodFisco: "M281",
        Abitanti: "7674",
      },
      {
        Istat: "83067",
        Comune: "Pettineo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98070",
        CodFisco: "G522",
        Abitanti: "1454",
      },
      {
        Istat: "82057",
        Comune: "Piana degli Albanesi",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90037",
        CodFisco: "G543",
        Abitanti: "6018",
      },
      {
        Istat: "86014",
        Comune: "Piazza Armerina",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94015",
        CodFisco: "G580",
        Abitanti: "20998",
      },
      {
        Istat: "87035",
        Comune: "Piedimonte Etneo",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95017",
        CodFisco: "G597",
        Abitanti: "4106",
      },
      {
        Istat: "86015",
        Comune: "Pietraperzia",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "94016",
        CodFisco: "G624",
        Abitanti: "7277",
      },
      {
        Istat: "83068",
        Comune: "Piraino",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "G699",
        Abitanti: "4044",
      },
      {
        Istat: "81016",
        Comune: "Poggioreale",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91020",
        CodFisco: "G767",
        Abitanti: "1576",
      },
      {
        Istat: "82058",
        Comune: "Polizzi Generosa",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90028",
        CodFisco: "G792",
        Abitanti: "3656",
      },
      {
        Istat: "82059",
        Comune: "Pollina",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90010",
        CodFisco: "G797",
        Abitanti: "3070",
      },
      {
        Istat: "84028",
        Comune: "Porto Empedocle",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92014",
        CodFisco: "F299",
        Abitanti: "17261",
      },
      {
        Istat: "89020",
        Comune: "Portopalo di Capo Passero",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "M257",
        Abitanti: "3818",
      },
      {
        Istat: "88008",
        Comune: "Pozzallo",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97016",
        CodFisco: "G953",
        Abitanti: "19234",
      },
      {
        Istat: "89021",
        Comune: "Priolo Gargallo",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "M279",
        Abitanti: "12148",
      },
      {
        Istat: "82060",
        Comune: "Prizzi",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90038",
        CodFisco: "H070",
        Abitanti: "5152",
      },
      {
        Istat: "84029",
        Comune: "Racalmuto",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "H148",
        Abitanti: "8962",
      },
      {
        Istat: "83069",
        Comune: "Raccuja",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98067",
        CodFisco: "H151",
        Abitanti: "1147",
      },
      {
        Istat: "87036",
        Comune: "Raddusa",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95040",
        CodFisco: "H154",
        Abitanti: "3285",
      },
      {
        Istat: "84030",
        Comune: "Raffadali",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92015",
        CodFisco: "H159",
        Abitanti: "12924",
      },
      {
        Istat: "87058",
        Comune: "Ragalna",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "M287",
        Abitanti: "3649",
      },
      {
        Istat: "88009",
        Comune: "Ragusa",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97100",
        CodFisco: "H163",
        Abitanti: "73743",
      },
      {
        Istat: "87037",
        Comune: "Ramacca",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95040",
        CodFisco: "H168",
        Abitanti: "10859",
      },
      {
        Istat: "87038",
        Comune: "Randazzo",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95036",
        CodFisco: "H175",
        Abitanti: "11186",
      },
      {
        Istat: "84031",
        Comune: "Ravanusa",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92029",
        CodFisco: "H194",
        Abitanti: "12751",
      },
      {
        Istat: "84032",
        Comune: "Realmonte",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92010",
        CodFisco: "H205",
        Abitanti: "4583",
      },
      {
        Istat: "86016",
        Comune: "Regalbuto",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94017",
        CodFisco: "H221",
        Abitanti: "7512",
      },
      {
        Istat: "83070",
        Comune: "Reitano",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98070",
        CodFisco: "H228",
        Abitanti: "878",
      },
      {
        Istat: "85014",
        Comune: "Resuttano",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "H245",
        Abitanti: "2173",
      },
      {
        Istat: "84033",
        Comune: "Ribera",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92016",
        CodFisco: "H269",
        Abitanti: "19589",
      },
      {
        Istat: "85015",
        Comune: "Riesi",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93016",
        CodFisco: "H281",
        Abitanti: "11232",
      },
      {
        Istat: "87039",
        Comune: "Riposto",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95018",
        CodFisco: "H325",
        Abitanti: "14981",
      },
      {
        Istat: "83071",
        Comune: "Roccafiorita",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "H405",
        Abitanti: "232",
      },
      {
        Istat: "83072",
        Comune: "Roccalumera",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98027",
        CodFisco: "H418",
        Abitanti: "4270",
      },
      {
        Istat: "82061",
        Comune: "Roccamena",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "H422",
        Abitanti: "1604",
      },
      {
        Istat: "82062",
        Comune: "Roccapalumba",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90020",
        CodFisco: "H428",
        Abitanti: "2680",
      },
      {
        Istat: "83073",
        Comune: "Roccavaldina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98040",
        CodFisco: "H380",
        Abitanti: "1173",
      },
      {
        Istat: "83074",
        Comune: "Roccella Valdemone",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "H455",
        Abitanti: "714",
      },
      {
        Istat: "83075",
        Comune: "Rodì Milici",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98059",
        CodFisco: "H479",
        Abitanti: "2213",
      },
      {
        Istat: "83076",
        Comune: "Rometta",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98043",
        CodFisco: "H519",
        Abitanti: "6694",
      },
      {
        Istat: "89016",
        Comune: "Rosolini",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96019",
        CodFisco: "H574",
        Abitanti: "21798",
      },
      {
        Istat: "81017",
        Comune: "Salaparuta",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91020",
        CodFisco: "H688",
        Abitanti: "1741",
      },
      {
        Istat: "81018",
        Comune: "Salemi",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91018",
        CodFisco: "H700",
        Abitanti: "10998",
      },
      {
        Istat: "84034",
        Comune: "Sambuca di Sicilia",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92017",
        CodFisco: "H743",
        Abitanti: "6207",
      },
      {
        Istat: "84035",
        Comune: "San Biagio Platani",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "H778",
        Abitanti: "3547",
      },
      {
        Istat: "85016",
        Comune: "San Cataldo",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93017",
        CodFisco: "H792",
        Abitanti: "23318",
      },
      {
        Istat: "82063",
        Comune: "San Cipirello",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "H797",
        Abitanti: "5473",
      },
      {
        Istat: "87040",
        Comune: "San Cono",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95040",
        CodFisco: "H805",
        Abitanti: "2883",
      },
      {
        Istat: "83077",
        Comune: "San Filippo del Mela",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98044",
        CodFisco: "H842",
        Abitanti: "7291",
      },
      {
        Istat: "83078",
        Comune: "San Fratello",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98075",
        CodFisco: "H850",
        Abitanti: "4003",
      },
      {
        Istat: "84036",
        Comune: "San Giovanni Gemini",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "H914",
        Abitanti: "8159",
      },
      {
        Istat: "87041",
        Comune: "San Giovanni la Punta",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95037",
        CodFisco: "H922",
        Abitanti: "22490",
      },
      {
        Istat: "82064",
        Comune: "San Giuseppe Jato",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90048",
        CodFisco: "H933",
        Abitanti: "8799",
      },
      {
        Istat: "87042",
        Comune: "San Gregorio di Catania",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95027",
        CodFisco: "H940",
        Abitanti: "11604",
      },
      {
        Istat: "83079",
        Comune: "San Marco D'Alunzio",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "H982",
        Abitanti: "2082",
      },
      {
        Istat: "82065",
        Comune: "San Mauro Castelverde",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90010",
        CodFisco: "I028",
        Abitanti: "1896",
      },
      {
        Istat: "87043",
        Comune: "San Michele di Ganzaria",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95040",
        CodFisco: "I035",
        Abitanti: "3580",
      },
      {
        Istat: "83080",
        Comune: "San Pier Niceto",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98045",
        CodFisco: "I084",
        Abitanti: "2976",
      },
      {
        Istat: "83081",
        Comune: "San Piero Patti",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98068",
        CodFisco: "I086",
        Abitanti: "3136",
      },
      {
        Istat: "87044",
        Comune: "San Pietro Clarenza",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "I098",
        Abitanti: "7160",
      },
      {
        Istat: "83082",
        Comune: "San Salvatore di Fitalia",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "I147",
        Abitanti: "1424",
      },
      {
        Istat: "83090",
        Comune: "San Teodoro",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "98030",
        CodFisco: "I328",
        Abitanti: "1426",
      },
      {
        Istat: "81020",
        Comune: "San Vito Lo Capo",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91010",
        CodFisco: "I407",
        Abitanti: "4366",
      },
      {
        Istat: "85017",
        Comune: "Santa Caterina Villarmosa",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93018",
        CodFisco: "I169",
        Abitanti: "5751",
      },
      {
        Istat: "82066",
        Comune: "Santa Cristina Gela",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "I174",
        Abitanti: "927",
      },
      {
        Istat: "88010",
        Comune: "Santa Croce Camerina",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97017",
        CodFisco: "I178",
        Abitanti: "9945",
      },
      {
        Istat: "83083",
        Comune: "Santa Domenica Vittoria",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "98030",
        CodFisco: "I184",
        Abitanti: "1079",
      },
      {
        Istat: "84037",
        Comune: "Santa Elisabetta",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "I185",
        Abitanti: "2769",
      },
      {
        Istat: "82067",
        Comune: "Santa Flavia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90017",
        CodFisco: "I188",
        Abitanti: "10957",
      },
      {
        Istat: "83086",
        Comune: "Santa Lucia del Mela",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98046",
        CodFisco: "I220",
        Abitanti: "4794",
      },
      {
        Istat: "84038",
        Comune: "Santa Margherita di Belice",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92018",
        CodFisco: "I224",
        Abitanti: "6647",
      },
      {
        Istat: "87047",
        Comune: "Santa Maria di Licodia",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95038",
        CodFisco: "I240",
        Abitanti: "7108",
      },
      {
        Istat: "83087",
        Comune: "Santa Marina Salina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98050",
        CodFisco: "I254",
        Abitanti: "894",
      },
      {
        Istat: "81019",
        Comune: "Santa Ninfa",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91029",
        CodFisco: "I291",
        Abitanti: "5125",
      },
      {
        Istat: "83089",
        Comune: "Santa Teresa di Riva",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98028",
        CodFisco: "I311",
        Abitanti: "9296",
      },
      {
        Istat: "87048",
        Comune: "Santa Venerina",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95010",
        CodFisco: "I314",
        Abitanti: "8405",
      },
      {
        Istat: "83084",
        Comune: "Sant'Agata di Militello",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98076",
        CodFisco: "I199",
        Abitanti: "13190",
      },
      {
        Istat: "87045",
        Comune: "Sant'Agata Li Battiati",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "I202",
        Abitanti: "9396",
      },
      {
        Istat: "83085",
        Comune: "Sant'Alessio Siculo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98030",
        CodFisco: "I215",
        Abitanti: "1525",
      },
      {
        Istat: "87046",
        Comune: "Sant'Alfio",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95010",
        CodFisco: "I216",
        Abitanti: "1663",
      },
      {
        Istat: "83088",
        Comune: "Sant'Angelo di Brolo",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "I283",
        Abitanti: "3330",
      },
      {
        Istat: "84039",
        Comune: "Sant'Angelo Muxaro",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "I290",
        Abitanti: "1512",
      },
      {
        Istat: "83091",
        Comune: "Santo Stefano di Camastra",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98077",
        CodFisco: "I370",
        Abitanti: "4533",
      },
      {
        Istat: "84040",
        Comune: "Santo Stefano Quisquina",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92020",
        CodFisco: "I356",
        Abitanti: "4965",
      },
      {
        Istat: "83092",
        Comune: "Saponara",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98047",
        CodFisco: "I420",
        Abitanti: "4089",
      },
      {
        Istat: "83093",
        Comune: "Savoca",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98038",
        CodFisco: "I477",
        Abitanti: "1824",
      },
      {
        Istat: "83094",
        Comune: "Scaletta Zanclea",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98029",
        CodFisco: "I492",
        Abitanti: "2345",
      },
      {
        Istat: "84041",
        Comune: "Sciacca",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92019",
        CodFisco: "I533",
        Abitanti: "41066",
      },
      {
        Istat: "82068",
        Comune: "Sciara",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90020",
        CodFisco: "I534",
        Abitanti: "2856",
      },
      {
        Istat: "88011",
        Comune: "Scicli",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97018",
        CodFisco: "I535",
        Abitanti: "26556",
      },
      {
        Istat: "82081",
        Comune: "Scillato",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90020",
        CodFisco: "I538",
        Abitanti: "637",
      },
      {
        Istat: "82069",
        Comune: "Sclafani Bagni",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90020",
        CodFisco: "I541",
        Abitanti: "454",
      },
      {
        Istat: "87049",
        Comune: "Scordia",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95048",
        CodFisco: "I548",
        Abitanti: "17266",
      },
      {
        Istat: "85018",
        Comune: "Serradifalco",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "I644",
        Abitanti: "6371",
      },
      {
        Istat: "84042",
        Comune: "Siculiana",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "92010",
        CodFisco: "I723",
        Abitanti: "4587",
      },
      {
        Istat: "83095",
        Comune: "Sinagra",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98069",
        CodFisco: "I747",
        Abitanti: "2781",
      },
      {
        Istat: "89017",
        Comune: "Siracusa",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96100",
        CodFisco: "I754",
        Abitanti: "123850",
      },
      {
        Istat: "89018",
        Comune: "Solarino",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "I785",
        Abitanti: "7820",
      },
      {
        Istat: "85019",
        Comune: "Sommatino",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "922",
        CAP: "93019",
        CodFisco: "I824",
        Abitanti: "7374",
      },
      {
        Istat: "89019",
        Comune: "Sortino",
        Provincia: "SR",
        Regione: "SIC",
        Prefisso: "931",
        CAP: "96010",
        CodFisco: "I864",
        Abitanti: "8955",
      },
      {
        Istat: "83096",
        Comune: "Spadafora",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98048",
        CodFisco: "I881",
        Abitanti: "5247",
      },
      {
        Istat: "86017",
        Comune: "Sperlinga",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "I891",
        Abitanti: "895",
      },
      {
        Istat: "85020",
        Comune: "Sutera",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "L016",
        Abitanti: "1471",
      },
      {
        Istat: "83097",
        Comune: "Taormina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "942",
        CAP: "98039",
        CodFisco: "L042",
        Abitanti: "11076",
      },
      {
        Istat: "83106",
        Comune: "Terme Vigliatore",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98050",
        CodFisco: "M210",
        Abitanti: "7203",
      },
      {
        Istat: "82070",
        Comune: "Termini Imerese",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90018",
        CodFisco: "L112",
        Abitanti: "27702",
      },
      {
        Istat: "82071",
        Comune: "Terrasini",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90049",
        CodFisco: "L131",
        Abitanti: "11696",
      },
      {
        Istat: "83098",
        Comune: "Torregrotta",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98040",
        CodFisco: "L271",
        Abitanti: "7400",
      },
      {
        Istat: "83108",
        Comune: "Torrenova",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98070",
        CodFisco: "M286",
        Abitanti: "4242",
      },
      {
        Istat: "82072",
        Comune: "Torretta",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "L282",
        Abitanti: "4157",
      },
      {
        Istat: "83099",
        Comune: "Tortorici",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98078",
        CodFisco: "L308",
        Abitanti: "6785",
      },
      {
        Istat: "82073",
        Comune: "Trabia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90019",
        CodFisco: "L317",
        Abitanti: "9682",
      },
      {
        Istat: "81021",
        Comune: "Trapani",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91100",
        CodFisco: "L331",
        Abitanti: "70622",
      },
      {
        Istat: "82074",
        Comune: "Trappeto",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90040",
        CodFisco: "L332",
        Abitanti: "3250",
      },
      {
        Istat: "87050",
        Comune: "Trecastagni",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95039",
        CodFisco: "L355",
        Abitanti: "10475",
      },
      {
        Istat: "87051",
        Comune: "Tremestieri Etneo",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95030",
        CodFisco: "L369",
        Abitanti: "21460",
      },
      {
        Istat: "83100",
        Comune: "Tripi",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "L431",
        Abitanti: "943",
      },
      {
        Istat: "86018",
        Comune: "Troina",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94018",
        CodFisco: "L448",
        Abitanti: "9704",
      },
      {
        Istat: "83101",
        Comune: "Tusa",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "98079",
        CodFisco: "L478",
        Abitanti: "3082",
      },
      {
        Istat: "83102",
        Comune: "Ucria",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "941",
        CAP: "98060",
        CodFisco: "L482",
        Abitanti: "1133",
      },
      {
        Istat: "82075",
        Comune: "Ustica",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90010",
        CodFisco: "L519",
        Abitanti: "1332",
      },
      {
        Istat: "81022",
        Comune: "Valderice",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "923",
        CAP: "91019",
        CodFisco: "G319",
        Abitanti: "12175",
      },
      {
        Istat: "83103",
        Comune: "Valdina",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98040",
        CodFisco: "L561",
        Abitanti: "1290",
      },
      {
        Istat: "86019",
        Comune: "Valguarnera Caropepe",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94019",
        CodFisco: "L583",
        Abitanti: "8281",
      },
      {
        Istat: "82076",
        Comune: "Valledolmo",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "921",
        CAP: "90029",
        CodFisco: "L603",
        Abitanti: "3753",
      },
      {
        Istat: "85021",
        Comune: "Vallelunga Pratameno",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "L609",
        Abitanti: "3687",
      },
      {
        Istat: "87052",
        Comune: "Valverde",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95028",
        CodFisco: "L658",
        Abitanti: "7760",
      },
      {
        Istat: "83104",
        Comune: "Venetico",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98040",
        CodFisco: "L735",
        Abitanti: "3855",
      },
      {
        Istat: "82077",
        Comune: "Ventimiglia di Sicilia",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90020",
        CodFisco: "L740",
        Abitanti: "2108",
      },
      {
        Istat: "87053",
        Comune: "Viagrande",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95029",
        CodFisco: "L828",
        Abitanti: "8090",
      },
      {
        Istat: "82078",
        Comune: "Vicari",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90020",
        CodFisco: "L837",
        Abitanti: "2962",
      },
      {
        Istat: "82079",
        Comune: "Villabate",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90039",
        CodFisco: "L916",
        Abitanti: "20434",
      },
      {
        Istat: "84043",
        Comune: "Villafranca Sicula",
        Provincia: "AG",
        Regione: "SIC",
        Prefisso: "925",
        CAP: "92020",
        CodFisco: "L944",
        Abitanti: "1436",
      },
      {
        Istat: "83105",
        Comune: "Villafranca Tirrena",
        Provincia: "ME",
        Regione: "SIC",
        Prefisso: "90",
        CAP: "98049",
        CodFisco: "L950",
        Abitanti: "8931",
      },
      {
        Istat: "82080",
        Comune: "Villafrati",
        Provincia: "PA",
        Regione: "SIC",
        Prefisso: "91",
        CAP: "90030",
        CodFisco: "L951",
        Abitanti: "3377",
      },
      {
        Istat: "85022",
        Comune: "Villalba",
        Provincia: "CL",
        Regione: "SIC",
        Prefisso: "934",
        CAP: "93010",
        CodFisco: "L959",
        Abitanti: "1752",
      },
      {
        Istat: "86020",
        Comune: "Villarosa",
        Provincia: "EN",
        Regione: "SIC",
        Prefisso: "935",
        CAP: "94010",
        CodFisco: "M011",
        Abitanti: "5313",
      },
      {
        Istat: "81023",
        Comune: "Vita",
        Provincia: "TP",
        Regione: "SIC",
        Prefisso: "924",
        CAP: "91010",
        CodFisco: "M081",
        Abitanti: "2169",
      },
      {
        Istat: "88012",
        Comune: "Vittoria",
        Provincia: "RG",
        Regione: "SIC",
        Prefisso: "932",
        CAP: "97019",
        CodFisco: "M088",
        Abitanti: "63332",
      },
      {
        Istat: "87054",
        Comune: "Vizzini",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "933",
        CAP: "95049",
        CodFisco: "M100",
        Abitanti: "6656",
      },
      {
        Istat: "87055",
        Comune: "Zafferana Etnea",
        Provincia: "CT",
        Regione: "SIC",
        Prefisso: "95",
        CAP: "95019",
        CodFisco: "M139",
        Abitanti: "9376",
      },
      {
        Istat: "22001",
        Comune: "Ala",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38061",
        CodFisco: "A116",
        Abitanti: "9034",
      },
      {
        Istat: "22002",
        Comune: "Albiano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38041",
        CodFisco: "A158",
        Abitanti: "1511",
      },
      {
        Istat: "22003",
        Comune: "Aldeno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38060",
        CodFisco: "A178",
        Abitanti: "3014",
      },
      {
        Istat: "21001",
        Comune: "Aldino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "A179",
        Abitanti: "1665",
      },
      {
        Istat: "22004",
        Comune: "Amblar",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38011",
        CodFisco: "A260",
        Abitanti: "232",
      },
      {
        Istat: "22005",
        Comune: "Andalo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "A274",
        Abitanti: "1037",
      },
      {
        Istat: "21002",
        Comune: "Andriano",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39010",
        CodFisco: "A286",
        Abitanti: "1042",
      },
      {
        Istat: "21003",
        Comune: "Anterivo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "A306",
        Abitanti: "383",
      },
      {
        Istat: "21004",
        Comune: "Appiano sulla Strada del Vino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39057",
        CodFisco: "A332",
        Abitanti: "14235",
      },
      {
        Istat: "22006",
        Comune: "Arco",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38062",
        CodFisco: "A372",
        Abitanti: "16901",
      },
      {
        Istat: "21005",
        Comune: "Avelengo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "A507",
        Abitanti: "752",
      },
      {
        Istat: "22007",
        Comune: "Avio",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38063",
        CodFisco: "A520",
        Abitanti: "4122",
      },
      {
        Istat: "21006",
        Comune: "Badia",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39036",
        CodFisco: "A537",
        Abitanti: "3369",
      },
      {
        Istat: "21007",
        Comune: "Barbiano",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "A635",
        Abitanti: "1602",
      },
      {
        Istat: "22009",
        Comune: "Baselga di Pinè",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38042",
        CodFisco: "A694",
        Abitanti: "4899",
      },
      {
        Istat: "22011",
        Comune: "Bedollo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38043",
        CodFisco: "A730",
        Abitanti: "1478",
      },
      {
        Istat: "22012",
        Comune: "Bersone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38085",
        CodFisco: "A808",
        Abitanti: "282",
      },
      {
        Istat: "22013",
        Comune: "Besenello",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "A821",
        Abitanti: "2500",
      },
      {
        Istat: "22015",
        Comune: "Bieno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "A863",
        Abitanti: "447",
      },
      {
        Istat: "22017",
        Comune: "Bleggio Superiore",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38071",
        CodFisco: "A902",
        Abitanti: "1530",
      },
      {
        Istat: "22018",
        Comune: "Bocenago",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "A916",
        Abitanti: "415",
      },
      {
        Istat: "22019",
        Comune: "Bolbeno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38079",
        CodFisco: "A933",
        Abitanti: "348",
      },
      {
        Istat: "21008",
        Comune: "Bolzano",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39100",
        CodFisco: "A952",
        Abitanti: "104029",
      },
      {
        Istat: "22020",
        Comune: "Bondo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38081",
        CodFisco: "A967",
        Abitanti: "701",
      },
      {
        Istat: "22021",
        Comune: "Bondone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "A968",
        Abitanti: "677",
      },
      {
        Istat: "22022",
        Comune: "Borgo Valsugana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38051",
        CodFisco: "B006",
        Abitanti: "6842",
      },
      {
        Istat: "22023",
        Comune: "Bosentino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38049",
        CodFisco: "B078",
        Abitanti: "814",
      },
      {
        Istat: "21009",
        Comune: "Braies",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "B116",
        Abitanti: "657",
      },
      {
        Istat: "22024",
        Comune: "Breguzzo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38081",
        CodFisco: "B135",
        Abitanti: "586",
      },
      {
        Istat: "21010",
        Comune: "Brennero",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39041",
        CodFisco: "B145",
        Abitanti: "2093",
      },
      {
        Istat: "22025",
        Comune: "Brentonico",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "B153",
        Abitanti: "3920",
      },
      {
        Istat: "22026",
        Comune: "Bresimo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "B158",
        Abitanti: "248",
      },
      {
        Istat: "21011",
        Comune: "Bressanone",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39042",
        CodFisco: "B160",
        Abitanti: "20689",
      },
      {
        Istat: "22027",
        Comune: "Brez",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38021",
        CodFisco: "B165",
        Abitanti: "725",
      },
      {
        Istat: "22028",
        Comune: "Brione",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38083",
        CodFisco: "B185",
        Abitanti: "151",
      },
      {
        Istat: "21012",
        Comune: "Bronzolo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39051",
        CodFisco: "B203",
        Abitanti: "2647",
      },
      {
        Istat: "21013",
        Comune: "Brunico",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39031",
        CodFisco: "B220",
        Abitanti: "15523",
      },
      {
        Istat: "22029",
        Comune: "Caderzone Terme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "B335",
        Abitanti: "679",
      },
      {
        Istat: "22030",
        Comune: "Cagnò",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38028",
        CodFisco: "B360",
        Abitanti: "365",
      },
      {
        Istat: "21014",
        Comune: "Caines",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "B364",
        Abitanti: "400",
      },
      {
        Istat: "22031",
        Comune: "Calavino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38072",
        CodFisco: "B386",
        Abitanti: "1496",
      },
      {
        Istat: "22032",
        Comune: "Calceranica al Lago",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "B389",
        Abitanti: "1278",
      },
      {
        Istat: "21015",
        Comune: "Caldaro sulla Strada del Vino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39052",
        CodFisco: "B397",
        Abitanti: "7609",
      },
      {
        Istat: "22033",
        Comune: "Caldes",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38022",
        CodFisco: "B400",
        Abitanti: "1115",
      },
      {
        Istat: "22034",
        Comune: "Caldonazzo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38052",
        CodFisco: "B404",
        Abitanti: "3344",
      },
      {
        Istat: "22035",
        Comune: "Calliano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "B419",
        Abitanti: "1565",
      },
      {
        Istat: "22036",
        Comune: "Campitello di Fassa",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38031",
        CodFisco: "B514",
        Abitanti: "748",
      },
      {
        Istat: "21016",
        Comune: "Campo di Trens",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "B529",
        Abitanti: "2661",
      },
      {
        Istat: "21017",
        Comune: "Campo Tures",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39032",
        CodFisco: "B570",
        Abitanti: "5267",
      },
      {
        Istat: "22037",
        Comune: "Campodenno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "B525",
        Abitanti: "1526",
      },
      {
        Istat: "22038",
        Comune: "Canal San Bovo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38050",
        CodFisco: "B577",
        Abitanti: "1623",
      },
      {
        Istat: "22039",
        Comune: "Canazei",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38032",
        CodFisco: "B579",
        Abitanti: "1903",
      },
      {
        Istat: "22040",
        Comune: "Capriana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "B697",
        Abitanti: "602",
      },
      {
        Istat: "22041",
        Comune: "Carano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38033",
        CodFisco: "B723",
        Abitanti: "1077",
      },
      {
        Istat: "22042",
        Comune: "Carisolo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "B783",
        Abitanti: "974",
      },
      {
        Istat: "22043",
        Comune: "Carzano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "B856",
        Abitanti: "521",
      },
      {
        Istat: "22045",
        Comune: "Castel Condino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38082",
        CodFisco: "C183",
        Abitanti: "243",
      },
      {
        Istat: "21018",
        Comune: "Castelbello-Ciardes",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "C062",
        Abitanti: "2392",
      },
      {
        Istat: "22046",
        Comune: "Castelfondo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "C103",
        Abitanti: "643",
      },
      {
        Istat: "22048",
        Comune: "Castello Tesino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38053",
        CodFisco: "C194",
        Abitanti: "1345",
      },
      {
        Istat: "22047",
        Comune: "Castello-Molina di Fiemme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "C189",
        Abitanti: "2273",
      },
      {
        Istat: "22049",
        Comune: "Castelnuovo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "C216",
        Abitanti: "1022",
      },
      {
        Istat: "21019",
        Comune: "Castelrotto",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "C254",
        Abitanti: "6464",
      },
      {
        Istat: "22050",
        Comune: "Cavalese",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38033",
        CodFisco: "C372",
        Abitanti: "4014",
      },
      {
        Istat: "22051",
        Comune: "Cavareno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38011",
        CodFisco: "C380",
        Abitanti: "1048",
      },
      {
        Istat: "22052",
        Comune: "Cavedago",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "C392",
        Abitanti: "535",
      },
      {
        Istat: "22053",
        Comune: "Cavedine",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38073",
        CodFisco: "C393",
        Abitanti: "2935",
      },
      {
        Istat: "22054",
        Comune: "Cavizzana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38022",
        CodFisco: "C400",
        Abitanti: "260",
      },
      {
        Istat: "22055",
        Comune: "Cembra",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38034",
        CodFisco: "C452",
        Abitanti: "1856",
      },
      {
        Istat: "22056",
        Comune: "Centa San Nicolò",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38040",
        CodFisco: "C467",
        Abitanti: "626",
      },
      {
        Istat: "21020",
        Comune: "Cermes",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "A022",
        Abitanti: "1404",
      },
      {
        Istat: "21021",
        Comune: "Chienes",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "C625",
        Abitanti: "2727",
      },
      {
        Istat: "21022",
        Comune: "Chiusa",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39043",
        CodFisco: "C652",
        Abitanti: "5132",
      },
      {
        Istat: "22057",
        Comune: "Cimego",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38082",
        CodFisco: "C694",
        Abitanti: "413",
      },
      {
        Istat: "22058",
        Comune: "Cimone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38060",
        CodFisco: "C700",
        Abitanti: "662",
      },
      {
        Istat: "22059",
        Comune: "Cinte Tesino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "C712",
        Abitanti: "389",
      },
      {
        Istat: "22060",
        Comune: "Cis",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "C727",
        Abitanti: "311",
      },
      {
        Istat: "22061",
        Comune: "Civezzano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38045",
        CodFisco: "C756",
        Abitanti: "3909",
      },
      {
        Istat: "22062",
        Comune: "Cles",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38023",
        CodFisco: "C794",
        Abitanti: "6781",
      },
      {
        Istat: "22063",
        Comune: "Cloz",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "C797",
        Abitanti: "734",
      },
      {
        Istat: "22228",
        Comune: "Comano Terme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38077",
        CodFisco: "M314",
        Abitanti: "2930",
      },
      {
        Istat: "22064",
        Comune: "Commezzadura",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "C931",
        Abitanti: "1004",
      },
      {
        Istat: "22066",
        Comune: "Condino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38083",
        CodFisco: "C953",
        Abitanti: "1512",
      },
      {
        Istat: "22067",
        Comune: "Coredo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "C994",
        Abitanti: "1645",
      },
      {
        Istat: "21023",
        Comune: "Cornedo all'Isarco",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39053",
        CodFisco: "B799",
        Abitanti: "3307",
      },
      {
        Istat: "21024",
        Comune: "Cortaccia sulla Strada del Vino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "D048",
        Abitanti: "2254",
      },
      {
        Istat: "21025",
        Comune: "Cortina sulla Strada del Vino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "D075",
        Abitanti: "645",
      },
      {
        Istat: "21026",
        Comune: "Corvara in Badia",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39033",
        CodFisco: "D079",
        Abitanti: "1335",
      },
      {
        Istat: "22068",
        Comune: "Croviana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38027",
        CodFisco: "D188",
        Abitanti: "688",
      },
      {
        Istat: "22069",
        Comune: "Cunevo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "D206",
        Abitanti: "573",
      },
      {
        Istat: "21027",
        Comune: "Curon Venosta",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39027",
        CodFisco: "D222",
        Abitanti: "2447",
      },
      {
        Istat: "22070",
        Comune: "Daiano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "D243",
        Abitanti: "709",
      },
      {
        Istat: "22071",
        Comune: "Dambel",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "D246",
        Abitanti: "434",
      },
      {
        Istat: "22072",
        Comune: "Daone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "D248",
        Abitanti: "603",
      },
      {
        Istat: "22073",
        Comune: "Darè",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "D250",
        Abitanti: "259",
      },
      {
        Istat: "22074",
        Comune: "Denno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "D273",
        Abitanti: "1244",
      },
      {
        Istat: "22075",
        Comune: "Dimaro",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38025",
        CodFisco: "D302",
        Abitanti: "1263",
      },
      {
        Istat: "21028",
        Comune: "Dobbiaco",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39034",
        CodFisco: "D311",
        Abitanti: "3292",
      },
      {
        Istat: "22076",
        Comune: "Don",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38011",
        CodFisco: "D336",
        Abitanti: "248",
      },
      {
        Istat: "22077",
        Comune: "Dorsino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38070",
        CodFisco: "D349",
        Abitanti: "439",
      },
      {
        Istat: "22078",
        Comune: "Drena",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38074",
        CodFisco: "D365",
        Abitanti: "558",
      },
      {
        Istat: "22079",
        Comune: "Dro",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38074",
        CodFisco: "D371",
        Abitanti: "4435",
      },
      {
        Istat: "21029",
        Comune: "Egna",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39044",
        CodFisco: "D392",
        Abitanti: "5009",
      },
      {
        Istat: "22080",
        Comune: "Faedo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "D457",
        Abitanti: "622",
      },
      {
        Istat: "22081",
        Comune: "Fai della Paganella",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "D468",
        Abitanti: "917",
      },
      {
        Istat: "21030",
        Comune: "Falzes",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "D484",
        Abitanti: "2619",
      },
      {
        Istat: "22082",
        Comune: "Faver",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38030",
        CodFisco: "D516",
        Abitanti: "828",
      },
      {
        Istat: "22083",
        Comune: "Fiavè",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38075",
        CodFisco: "D565",
        Abitanti: "1127",
      },
      {
        Istat: "21031",
        Comune: "Fiè allo Sciliar",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39050",
        CodFisco: "D571",
        Abitanti: "3469",
      },
      {
        Istat: "22084",
        Comune: "Fiera di Primiero",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38054",
        CodFisco: "D572",
        Abitanti: "533",
      },
      {
        Istat: "22085",
        Comune: "Fierozzo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "D573",
        Abitanti: "477",
      },
      {
        Istat: "22086",
        Comune: "Flavon",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "D631",
        Abitanti: "545",
      },
      {
        Istat: "22087",
        Comune: "Folgaria",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38064",
        CodFisco: "D651",
        Abitanti: "3118",
      },
      {
        Istat: "22088",
        Comune: "Fondo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38013",
        CodFisco: "D663",
        Abitanti: "1475",
      },
      {
        Istat: "22089",
        Comune: "Fornace",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38040",
        CodFisco: "D714",
        Abitanti: "1323",
      },
      {
        Istat: "21032",
        Comune: "Fortezza",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39045",
        CodFisco: "D731",
        Abitanti: "975",
      },
      {
        Istat: "22090",
        Comune: "Frassilongo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "D775",
        Abitanti: "331",
      },
      {
        Istat: "21033",
        Comune: "Funes",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "D821",
        Abitanti: "2556",
      },
      {
        Istat: "21034",
        Comune: "Gais",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "D860",
        Abitanti: "3160",
      },
      {
        Istat: "21035",
        Comune: "Gargazzone",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "D923",
        Abitanti: "1638",
      },
      {
        Istat: "22091",
        Comune: "Garniga Terme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38060",
        CodFisco: "D928",
        Abitanti: "392",
      },
      {
        Istat: "22092",
        Comune: "Giovo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38030",
        CodFisco: "E048",
        Abitanti: "2490",
      },
      {
        Istat: "22093",
        Comune: "Giustino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38086",
        CodFisco: "E065",
        Abitanti: "750",
      },
      {
        Istat: "21036",
        Comune: "Glorenza",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "E069",
        Abitanti: "880",
      },
      {
        Istat: "22094",
        Comune: "Grauno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38030",
        CodFisco: "E150",
        Abitanti: "149",
      },
      {
        Istat: "22095",
        Comune: "Grigno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38055",
        CodFisco: "E178",
        Abitanti: "2299",
      },
      {
        Istat: "22096",
        Comune: "Grumes",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38030",
        CodFisco: "E222",
        Abitanti: "441",
      },
      {
        Istat: "22097",
        Comune: "Imer",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38050",
        CodFisco: "E288",
        Abitanti: "1206",
      },
      {
        Istat: "22098",
        Comune: "Isera",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "E334",
        Abitanti: "2601",
      },
      {
        Istat: "22099",
        Comune: "Ivano-Fracena",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38059",
        CodFisco: "E378",
        Abitanti: "339",
      },
      {
        Istat: "21117",
        Comune: "La Valle",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39030",
        CodFisco: "E491",
        Abitanti: "1302",
      },
      {
        Istat: "21037",
        Comune: "Laces",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39021",
        CodFisco: "E398",
        Abitanti: "5144",
      },
      {
        Istat: "21038",
        Comune: "Lagundo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39022",
        CodFisco: "E412",
        Abitanti: "4848",
      },
      {
        Istat: "21039",
        Comune: "Laion",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "E420",
        Abitanti: "2616",
      },
      {
        Istat: "21040",
        Comune: "Laives",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39055",
        CodFisco: "E421",
        Abitanti: "17197",
      },
      {
        Istat: "21041",
        Comune: "Lana",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39011",
        CodFisco: "E434",
        Abitanti: "11230",
      },
      {
        Istat: "22100",
        Comune: "Lardaro",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38087",
        CodFisco: "E452",
        Abitanti: "217",
      },
      {
        Istat: "21042",
        Comune: "Lasa",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39023",
        CodFisco: "E457",
        Abitanti: "3937",
      },
      {
        Istat: "22101",
        Comune: "Lasino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38076",
        CodFisco: "E461",
        Abitanti: "1305",
      },
      {
        Istat: "21043",
        Comune: "Lauregno",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "39040",
        CodFisco: "E481",
        Abitanti: "346",
      },
      {
        Istat: "22102",
        Comune: "Lavarone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38046",
        CodFisco: "E492",
        Abitanti: "1107",
      },
      {
        Istat: "22103",
        Comune: "Lavis",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38015",
        CodFisco: "E500",
        Abitanti: "8635",
      },
      {
        Istat: "22229",
        Comune: "Ledro",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38067",
        CodFisco: "M313",
        Abitanti: "5523",
      },
      {
        Istat: "22104",
        Comune: "Levico Terme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38056",
        CodFisco: "E565",
        Abitanti: "7474",
      },
      {
        Istat: "22105",
        Comune: "Lisignago",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38030",
        CodFisco: "E614",
        Abitanti: "504",
      },
      {
        Istat: "22106",
        Comune: "Livo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "E624",
        Abitanti: "905",
      },
      {
        Istat: "22108",
        Comune: "Lona-Lases",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38040",
        CodFisco: "E664",
        Abitanti: "854",
      },
      {
        Istat: "22109",
        Comune: "Luserna",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38040",
        CodFisco: "E757",
        Abitanti: "297",
      },
      {
        Istat: "21044",
        Comune: "Luson",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "E764",
        Abitanti: "1539",
      },
      {
        Istat: "21045",
        Comune: "Magrè sulla Strada del Vino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "E829",
        Abitanti: "1302",
      },
      {
        Istat: "22110",
        Comune: "Malè",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38027",
        CodFisco: "E850",
        Abitanti: "2133",
      },
      {
        Istat: "21046",
        Comune: "Malles Venosta",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39024",
        CodFisco: "E862",
        Abitanti: "5093",
      },
      {
        Istat: "22111",
        Comune: "Malosco",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38013",
        CodFisco: "E866",
        Abitanti: "453",
      },
      {
        Istat: "21047",
        Comune: "Marebbe",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "E938",
        Abitanti: "2912",
      },
      {
        Istat: "21048",
        Comune: "Marlengo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "E959",
        Abitanti: "2524",
      },
      {
        Istat: "21049",
        Comune: "Martello",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "E981",
        Abitanti: "883",
      },
      {
        Istat: "22112",
        Comune: "Massimeno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38086",
        CodFisco: "F045",
        Abitanti: "117",
      },
      {
        Istat: "22113",
        Comune: "Mazzin",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "F068",
        Abitanti: "498",
      },
      {
        Istat: "21050",
        Comune: "Meltina",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39010",
        CodFisco: "F118",
        Abitanti: "1625",
      },
      {
        Istat: "21051",
        Comune: "Merano",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39012",
        CodFisco: "F132",
        Abitanti: "38229",
      },
      {
        Istat: "22114",
        Comune: "Mezzana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "F168",
        Abitanti: "872",
      },
      {
        Istat: "22115",
        Comune: "Mezzano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38050",
        CodFisco: "F176",
        Abitanti: "1635",
      },
      {
        Istat: "22116",
        Comune: "Mezzocorona",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38016",
        CodFisco: "F183",
        Abitanti: "5179",
      },
      {
        Istat: "22117",
        Comune: "Mezzolombardo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38017",
        CodFisco: "F187",
        Abitanti: "6914",
      },
      {
        Istat: "22118",
        Comune: "Moena",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38035",
        CodFisco: "F263",
        Abitanti: "2709",
      },
      {
        Istat: "22120",
        Comune: "Molveno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38018",
        CodFisco: "F307",
        Abitanti: "1130",
      },
      {
        Istat: "22121",
        Comune: "Monclassico",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "F341",
        Abitanti: "872",
      },
      {
        Istat: "21052",
        Comune: "Monguelfo-Tesido",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39035",
        CodFisco: "F371",
        Abitanti: "2804",
      },
      {
        Istat: "21053",
        Comune: "Montagna",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "F392",
        Abitanti: "1633",
      },
      {
        Istat: "22122",
        Comune: "Montagne",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38070",
        CodFisco: "F396",
        Abitanti: "244",
      },
      {
        Istat: "22123",
        Comune: "Mori",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38065",
        CodFisco: "F728",
        Abitanti: "9538",
      },
      {
        Istat: "21054",
        Comune: "Moso in Passiria",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39013",
        CodFisco: "F766",
        Abitanti: "2168",
      },
      {
        Istat: "22124",
        Comune: "Nago-Torbole",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38069",
        CodFisco: "F835",
        Abitanti: "2793",
      },
      {
        Istat: "21055",
        Comune: "Nalles",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39010",
        CodFisco: "F836",
        Abitanti: "1826",
      },
      {
        Istat: "22125",
        Comune: "Nanno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "F837",
        Abitanti: "632",
      },
      {
        Istat: "21056",
        Comune: "Naturno",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39025",
        CodFisco: "F849",
        Abitanti: "5554",
      },
      {
        Istat: "22126",
        Comune: "Nave San Rocco",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "F853",
        Abitanti: "1399",
      },
      {
        Istat: "21057",
        Comune: "Naz-Sciaves",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "F856",
        Abitanti: "2894",
      },
      {
        Istat: "22127",
        Comune: "Nogaredo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "F920",
        Abitanti: "1959",
      },
      {
        Istat: "22128",
        Comune: "Nomi",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "F929",
        Abitanti: "1317",
      },
      {
        Istat: "21058",
        Comune: "Nova Levante",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39056",
        CodFisco: "F949",
        Abitanti: "1910",
      },
      {
        Istat: "21059",
        Comune: "Nova Ponente",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39050",
        CodFisco: "F950",
        Abitanti: "3902",
      },
      {
        Istat: "22129",
        Comune: "Novaledo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "F947",
        Abitanti: "1040",
      },
      {
        Istat: "21060",
        Comune: "Ora",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "G083",
        Abitanti: "3537",
      },
      {
        Istat: "21061",
        Comune: "Ortisei",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39046",
        CodFisco: "G140",
        Abitanti: "4636",
      },
      {
        Istat: "22130",
        Comune: "Ospedaletto",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "G168",
        Abitanti: "809",
      },
      {
        Istat: "22131",
        Comune: "Ossana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38026",
        CodFisco: "G173",
        Abitanti: "839",
      },
      {
        Istat: "22132",
        Comune: "Padergnone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38070",
        CodFisco: "G214",
        Abitanti: "727",
      },
      {
        Istat: "22133",
        Comune: "Palù del Fersina",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "G296",
        Abitanti: "177",
      },
      {
        Istat: "22134",
        Comune: "Panchià",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "G305",
        Abitanti: "764",
      },
      {
        Istat: "21062",
        Comune: "Parcines",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "G328",
        Abitanti: "3533",
      },
      {
        Istat: "22136",
        Comune: "Peio",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38024",
        CodFisco: "G419",
        Abitanti: "1910",
      },
      {
        Istat: "22137",
        Comune: "Pellizzano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "G428",
        Abitanti: "784",
      },
      {
        Istat: "22138",
        Comune: "Pelugo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38079",
        CodFisco: "G429",
        Abitanti: "391",
      },
      {
        Istat: "21063",
        Comune: "Perca",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "G443",
        Abitanti: "1455",
      },
      {
        Istat: "22139",
        Comune: "Pergine Valsugana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38057",
        CodFisco: "G452",
        Abitanti: "20582",
      },
      {
        Istat: "22140",
        Comune: "Pieve di Bono",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38085",
        CodFisco: "G641",
        Abitanti: "1380",
      },
      {
        Istat: "22142",
        Comune: "Pieve Tesino",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "G656",
        Abitanti: "679",
      },
      {
        Istat: "22143",
        Comune: "Pinzolo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38086",
        CodFisco: "G681",
        Abitanti: "3157",
      },
      {
        Istat: "21064",
        Comune: "Plaus",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39025",
        CodFisco: "G299",
        Abitanti: "686",
      },
      {
        Istat: "22144",
        Comune: "Pomarolo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "G808",
        Abitanti: "2384",
      },
      {
        Istat: "21065",
        Comune: "Ponte Gardena",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "G830",
        Abitanti: "198",
      },
      {
        Istat: "21066",
        Comune: "Postal",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39014",
        CodFisco: "G936",
        Abitanti: "1742",
      },
      {
        Istat: "22145",
        Comune: "Pozza di Fassa",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38036",
        CodFisco: "G950",
        Abitanti: "2110",
      },
      {
        Istat: "22146",
        Comune: "Praso",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "G989",
        Abitanti: "342",
      },
      {
        Istat: "21067",
        Comune: "Prato allo Stelvio",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39026",
        CodFisco: "H004",
        Abitanti: "3381",
      },
      {
        Istat: "22147",
        Comune: "Predazzo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38037",
        CodFisco: "H018",
        Abitanti: "4537",
      },
      {
        Istat: "21068",
        Comune: "Predoi",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "H019",
        Abitanti: "597",
      },
      {
        Istat: "22148",
        Comune: "Preore",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38070",
        CodFisco: "H039",
        Abitanti: "400",
      },
      {
        Istat: "22149",
        Comune: "Prezzo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38085",
        CodFisco: "H057",
        Abitanti: "210",
      },
      {
        Istat: "21069",
        Comune: "Proves",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "39040",
        CodFisco: "H081",
        Abitanti: "274",
      },
      {
        Istat: "22150",
        Comune: "Rabbi",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "H146",
        Abitanti: "1409",
      },
      {
        Istat: "21070",
        Comune: "Racines",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "H152",
        Abitanti: "4369",
      },
      {
        Istat: "22151",
        Comune: "Ragoli",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38070",
        CodFisco: "H162",
        Abitanti: "770",
      },
      {
        Istat: "21071",
        Comune: "Rasun Anterselva",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "H189",
        Abitanti: "2878",
      },
      {
        Istat: "21072",
        Comune: "Renon",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39054",
        CodFisco: "H236",
        Abitanti: "7600",
      },
      {
        Istat: "22152",
        Comune: "Revò",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38028",
        CodFisco: "H254",
        Abitanti: "1268",
      },
      {
        Istat: "21073",
        Comune: "Rifiano",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "H284",
        Abitanti: "1317",
      },
      {
        Istat: "21074",
        Comune: "Rio di Pusteria",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39037",
        CodFisco: "H299",
        Abitanti: "2961",
      },
      {
        Istat: "22153",
        Comune: "Riva del Garda",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38066",
        CodFisco: "H330",
        Abitanti: "16170",
      },
      {
        Istat: "21075",
        Comune: "Rodengo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39037",
        CodFisco: "H475",
        Abitanti: "1190",
      },
      {
        Istat: "22154",
        Comune: "Romallo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38028",
        CodFisco: "H506",
        Abitanti: "610",
      },
      {
        Istat: "22155",
        Comune: "Romeno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "H517",
        Abitanti: "1387",
      },
      {
        Istat: "22156",
        Comune: "Roncegno Terme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "H528",
        Abitanti: "2818",
      },
      {
        Istat: "22157",
        Comune: "Ronchi Valsugana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "H532",
        Abitanti: "424",
      },
      {
        Istat: "22158",
        Comune: "Roncone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38087",
        CodFisco: "H545",
        Abitanti: "1475",
      },
      {
        Istat: "22135",
        Comune: "Ronzo-Chienis",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "M303",
        Abitanti: "1022",
      },
      {
        Istat: "22159",
        Comune: "Ronzone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "H552",
        Abitanti: "396",
      },
      {
        Istat: "22160",
        Comune: "Roverè della Luna",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38030",
        CodFisco: "H607",
        Abitanti: "1602",
      },
      {
        Istat: "22161",
        Comune: "Rovereto",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38068",
        CodFisco: "H612",
        Abitanti: "38167",
      },
      {
        Istat: "22162",
        Comune: "Ruffrè-Mendola",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "H634",
        Abitanti: "416",
      },
      {
        Istat: "22163",
        Comune: "Rumo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38020",
        CodFisco: "H639",
        Abitanti: "837",
      },
      {
        Istat: "22164",
        Comune: "Sagron Mis",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38050",
        CodFisco: "H666",
        Abitanti: "203",
      },
      {
        Istat: "21076",
        Comune: "Salorno",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "H719",
        Abitanti: "3536",
      },
      {
        Istat: "22165",
        Comune: "Samone",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38059",
        CodFisco: "H754",
        Abitanti: "547",
      },
      {
        Istat: "21077",
        Comune: "San Candido",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39038",
        CodFisco: "H786",
        Abitanti: "3172",
      },
      {
        Istat: "21079",
        Comune: "San Genesio Atesino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39050",
        CodFisco: "H858",
        Abitanti: "2939",
      },
      {
        Istat: "21080",
        Comune: "San Leonardo in Passiria",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39015",
        CodFisco: "H952",
        Abitanti: "3542",
      },
      {
        Istat: "21081",
        Comune: "San Lorenzo di Sebato",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "H956",
        Abitanti: "3756",
      },
      {
        Istat: "22166",
        Comune: "San Lorenzo in Banale",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38078",
        CodFisco: "H966",
        Abitanti: "1183",
      },
      {
        Istat: "21082",
        Comune: "San Martino in Badia",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "H988",
        Abitanti: "1726",
      },
      {
        Istat: "21083",
        Comune: "San Martino in Passiria",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "H989",
        Abitanti: "3143",
      },
      {
        Istat: "22167",
        Comune: "San Michele all'Adige",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "I042",
        Abitanti: "2875",
      },
      {
        Istat: "21084",
        Comune: "San Pancrazio",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "I065",
        Abitanti: "1588",
      },
      {
        Istat: "21085",
        Comune: "Santa Cristina Valgardena",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39047",
        CodFisco: "I173",
        Abitanti: "1900",
      },
      {
        Istat: "22168",
        Comune: "Sant'Orsola Terme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "I354",
        Abitanti: "1076",
      },
      {
        Istat: "22169",
        Comune: "Sanzeno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "I411",
        Abitanti: "938",
      },
      {
        Istat: "21086",
        Comune: "Sarentino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39058",
        CodFisco: "I431",
        Abitanti: "6903",
      },
      {
        Istat: "22170",
        Comune: "Sarnonico",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38011",
        CodFisco: "I439",
        Abitanti: "749",
      },
      {
        Istat: "21087",
        Comune: "Scena",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39017",
        CodFisco: "I519",
        Abitanti: "2844",
      },
      {
        Istat: "22171",
        Comune: "Scurelle",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "I554",
        Abitanti: "1403",
      },
      {
        Istat: "22172",
        Comune: "Segonzano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38047",
        CodFisco: "I576",
        Abitanti: "1536",
      },
      {
        Istat: "21088",
        Comune: "Selva dei Molini",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "I593",
        Abitanti: "1474",
      },
      {
        Istat: "21089",
        Comune: "Selva di Val Gardena",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39048",
        CodFisco: "I591",
        Abitanti: "2642",
      },
      {
        Istat: "21091",
        Comune: "Senales",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "I604",
        Abitanti: "1337",
      },
      {
        Istat: "21118",
        Comune: "Senale-San Felice",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "39010",
        CodFisco: "I603",
        Abitanti: "773",
      },
      {
        Istat: "21092",
        Comune: "Sesto",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "I687",
        Abitanti: "1952",
      },
      {
        Istat: "22173",
        Comune: "Sfruz",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "I714",
        Abitanti: "331",
      },
      {
        Istat: "21093",
        Comune: "Silandro",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39028",
        CodFisco: "I729",
        Abitanti: "5998",
      },
      {
        Istat: "22174",
        Comune: "Siror",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38054",
        CodFisco: "I760",
        Abitanti: "1285",
      },
      {
        Istat: "21094",
        Comune: "Sluderno",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "I771",
        Abitanti: "1823",
      },
      {
        Istat: "22175",
        Comune: "Smarano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "I772",
        Abitanti: "492",
      },
      {
        Istat: "22176",
        Comune: "Soraga",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "I839",
        Abitanti: "715",
      },
      {
        Istat: "22177",
        Comune: "Sover",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38048",
        CodFisco: "I871",
        Abitanti: "890",
      },
      {
        Istat: "22178",
        Comune: "Spera",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38059",
        CodFisco: "I889",
        Abitanti: "609",
      },
      {
        Istat: "22179",
        Comune: "Spiazzo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38088",
        CodFisco: "I899",
        Abitanti: "1274",
      },
      {
        Istat: "22180",
        Comune: "Spormaggiore",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "I924",
        Abitanti: "1292",
      },
      {
        Istat: "22181",
        Comune: "Sporminore",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "I925",
        Abitanti: "727",
      },
      {
        Istat: "21095",
        Comune: "Stelvio",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39029",
        CodFisco: "I948",
        Abitanti: "1215",
      },
      {
        Istat: "22182",
        Comune: "Stenico",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38070",
        CodFisco: "I949",
        Abitanti: "1160",
      },
      {
        Istat: "22183",
        Comune: "Storo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38089",
        CodFisco: "I964",
        Abitanti: "4704",
      },
      {
        Istat: "22184",
        Comune: "Strembo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "I975",
        Abitanti: "562",
      },
      {
        Istat: "22185",
        Comune: "Strigno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38059",
        CodFisco: "I979",
        Abitanti: "1432",
      },
      {
        Istat: "22186",
        Comune: "Taio",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38012",
        CodFisco: "L033",
        Abitanti: "2924",
      },
      {
        Istat: "22187",
        Comune: "Tassullo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "L060",
        Abitanti: "1950",
      },
      {
        Istat: "22188",
        Comune: "Telve",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "L089",
        Abitanti: "2003",
      },
      {
        Istat: "22189",
        Comune: "Telve di Sopra",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "L090",
        Abitanti: "625",
      },
      {
        Istat: "22190",
        Comune: "Tenna",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "L096",
        Abitanti: "983",
      },
      {
        Istat: "22191",
        Comune: "Tenno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "L097",
        Abitanti: "2029",
      },
      {
        Istat: "21096",
        Comune: "Terento",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39030",
        CodFisco: "L106",
        Abitanti: "1720",
      },
      {
        Istat: "22192",
        Comune: "Terlago",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38070",
        CodFisco: "L107",
        Abitanti: "1882",
      },
      {
        Istat: "21097",
        Comune: "Terlano",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39018",
        CodFisco: "L108",
        Abitanti: "4137",
      },
      {
        Istat: "21098",
        Comune: "Termeno sulla Strada del Vino",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "L111",
        Abitanti: "3301",
      },
      {
        Istat: "22193",
        Comune: "Terragnolo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "L121",
        Abitanti: "763",
      },
      {
        Istat: "22194",
        Comune: "Terres",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "L137",
        Abitanti: "323",
      },
      {
        Istat: "22195",
        Comune: "Terzolas",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38027",
        CodFisco: "L145",
        Abitanti: "606",
      },
      {
        Istat: "22196",
        Comune: "Tesero",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38038",
        CodFisco: "L147",
        Abitanti: "2871",
      },
      {
        Istat: "21099",
        Comune: "Tesimo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "L149",
        Abitanti: "1853",
      },
      {
        Istat: "22199",
        Comune: "Tione di Trento",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38079",
        CodFisco: "L174",
        Abitanti: "3637",
      },
      {
        Istat: "21100",
        Comune: "Tires",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39050",
        CodFisco: "L176",
        Abitanti: "978",
      },
      {
        Istat: "21101",
        Comune: "Tirolo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39019",
        CodFisco: "L178",
        Abitanti: "2465",
      },
      {
        Istat: "22200",
        Comune: "Ton",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "L200",
        Abitanti: "1316",
      },
      {
        Istat: "22201",
        Comune: "Tonadico",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38054",
        CodFisco: "L201",
        Abitanti: "1470",
      },
      {
        Istat: "22202",
        Comune: "Torcegno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38050",
        CodFisco: "L211",
        Abitanti: "709",
      },
      {
        Istat: "22203",
        Comune: "Trambileno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38068",
        CodFisco: "L322",
        Abitanti: "1373",
      },
      {
        Istat: "22204",
        Comune: "Transacqua",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "439",
        CAP: "38054",
        CodFisco: "L329",
        Abitanti: "2128",
      },
      {
        Istat: "22205",
        Comune: "Trento",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38121",
        CodFisco: "L378",
        Abitanti: "116298",
      },
      {
        Istat: "22205",
        Comune: "Trento",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38122",
        CodFisco: "L378",
        Abitanti: "116298",
      },
      {
        Istat: "22205",
        Comune: "Trento",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38123",
        CodFisco: "L378",
        Abitanti: "116298",
      },
      {
        Istat: "22206",
        Comune: "Tres",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "L385",
        Abitanti: "716",
      },
      {
        Istat: "21102",
        Comune: "Trodena nel parco naturale",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39040",
        CodFisco: "L444",
        Abitanti: "1009",
      },
      {
        Istat: "21103",
        Comune: "Tubre",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39020",
        CodFisco: "L455",
        Abitanti: "962",
      },
      {
        Istat: "22207",
        Comune: "Tuenno",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38019",
        CodFisco: "L457",
        Abitanti: "2394",
      },
      {
        Istat: "21104",
        Comune: "Ultimo",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39016",
        CodFisco: "L490",
        Abitanti: "2932",
      },
      {
        Istat: "21105",
        Comune: "Vadena",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "471",
        CAP: "39051",
        CodFisco: "L527",
        Abitanti: "1003",
      },
      {
        Istat: "21107",
        Comune: "Val di Vizze",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39049",
        CodFisco: "L564",
        Abitanti: "2761",
      },
      {
        Istat: "22208",
        Comune: "Valda",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38030",
        CodFisco: "L550",
        Abitanti: "227",
      },
      {
        Istat: "21106",
        Comune: "Valdaora",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "L552",
        Abitanti: "3056",
      },
      {
        Istat: "22209",
        Comune: "Valfloriana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38040",
        CodFisco: "L575",
        Abitanti: "554",
      },
      {
        Istat: "22210",
        Comune: "Vallarsa",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "L588",
        Abitanti: "1355",
      },
      {
        Istat: "21108",
        Comune: "Valle Aurina",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "L595",
        Abitanti: "5884",
      },
      {
        Istat: "21109",
        Comune: "Valle di Casies",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39030",
        CodFisco: "L601",
        Abitanti: "2261",
      },
      {
        Istat: "21110",
        Comune: "Vandoies",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39030",
        CodFisco: "L660",
        Abitanti: "3263",
      },
      {
        Istat: "22211",
        Comune: "Varena",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "L678",
        Abitanti: "831",
      },
      {
        Istat: "21111",
        Comune: "Varna",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "L687",
        Abitanti: "4236",
      },
      {
        Istat: "22212",
        Comune: "Vattaro",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38049",
        CodFisco: "L697",
        Abitanti: "1144",
      },
      {
        Istat: "21116",
        Comune: "Velturno",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "L724",
        Abitanti: "2749",
      },
      {
        Istat: "21112",
        Comune: "Verano",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "473",
        CAP: "39010",
        CodFisco: "L745",
        Abitanti: "926",
      },
      {
        Istat: "22213",
        Comune: "Vermiglio",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38029",
        CodFisco: "L769",
        Abitanti: "1901",
      },
      {
        Istat: "22214",
        Comune: "Vervò",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "463",
        CAP: "38010",
        CodFisco: "L800",
        Abitanti: "729",
      },
      {
        Istat: "22215",
        Comune: "Vezzano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38070",
        CodFisco: "L821",
        Abitanti: "2192",
      },
      {
        Istat: "22216",
        Comune: "Vignola-Falesina",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38057",
        CodFisco: "L886",
        Abitanti: "154",
      },
      {
        Istat: "22217",
        Comune: "Vigo di Fassa",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38039",
        CodFisco: "L893",
        Abitanti: "1177",
      },
      {
        Istat: "22220",
        Comune: "Vigo Rendena",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "L903",
        Abitanti: "517",
      },
      {
        Istat: "22219",
        Comune: "Vigolo Vattaro",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38049",
        CodFisco: "L896",
        Abitanti: "2201",
      },
      {
        Istat: "22221",
        Comune: "Villa Agnedo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38059",
        CodFisco: "L910",
        Abitanti: "1012",
      },
      {
        Istat: "22222",
        Comune: "Villa Lagarina",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "L957",
        Abitanti: "3684",
      },
      {
        Istat: "22223",
        Comune: "Villa Rendena",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38080",
        CodFisco: "M006",
        Abitanti: "992",
      },
      {
        Istat: "21113",
        Comune: "Villabassa",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "474",
        CAP: "39039",
        CodFisco: "L915",
        Abitanti: "1473",
      },
      {
        Istat: "21114",
        Comune: "Villandro",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39040",
        CodFisco: "L971",
        Abitanti: "1906",
      },
      {
        Istat: "21115",
        Comune: "Vipiteno",
        Provincia: "BZ",
        Regione: "TAA",
        Prefisso: "472",
        CAP: "39049",
        CodFisco: "M067",
        Abitanti: "6419",
      },
      {
        Istat: "22224",
        Comune: "Volano",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "464",
        CAP: "38060",
        CodFisco: "M113",
        Abitanti: "3177",
      },
      {
        Istat: "22225",
        Comune: "Zambana",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "461",
        CAP: "38010",
        CodFisco: "M142",
        Abitanti: "1677",
      },
      {
        Istat: "22226",
        Comune: "Ziano di Fiemme",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "462",
        CAP: "38030",
        CodFisco: "M173",
        Abitanti: "1659",
      },
      {
        Istat: "22227",
        Comune: "Zuclo",
        Provincia: "TN",
        Regione: "TAA",
        Prefisso: "465",
        CAP: "38079",
        CodFisco: "M198",
        Abitanti: "347",
      },
      {
        Istat: "52001",
        Comune: "Abbadia San Salvatore",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53021",
        CodFisco: "A006",
        Abitanti: "6722",
      },
      {
        Istat: "47001",
        Comune: "Abetone",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51021",
        CodFisco: "A012",
        Abitanti: "697",
      },
      {
        Istat: "47002",
        Comune: "Agliana",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "574",
        CAP: "51031",
        CodFisco: "A071",
        Abitanti: "17076",
      },
      {
        Istat: "46001",
        Comune: "Altopascio",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55011",
        CodFisco: "A241",
        Abitanti: "15047",
      },
      {
        Istat: "51001",
        Comune: "Anghiari",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52031",
        CodFisco: "A291",
        Abitanti: "5815",
      },
      {
        Istat: "53001",
        Comune: "Arcidosso",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58031",
        CodFisco: "A369",
        Abitanti: "4427",
      },
      {
        Istat: "51002",
        Comune: "Arezzo",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52100",
        CodFisco: "A390",
        Abitanti: "100212",
      },
      {
        Istat: "52002",
        Comune: "Asciano",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53041",
        CodFisco: "A461",
        Abitanti: "7299",
      },
      {
        Istat: "45001",
        Comune: "Aulla",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54011",
        CodFisco: "A496",
        Abitanti: "11312",
      },
      {
        Istat: "51003",
        Comune: "Badia Tedalda",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52032",
        CodFisco: "A541",
        Abitanti: "1118",
      },
      {
        Istat: "46002",
        Comune: "Bagni di Lucca",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55022",
        CodFisco: "A560",
        Abitanti: "6528",
      },
      {
        Istat: "48001",
        Comune: "Bagno a Ripoli",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50012",
        CodFisco: "A564",
        Abitanti: "25787",
      },
      {
        Istat: "45002",
        Comune: "Bagnone",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54021",
        CodFisco: "A576",
        Abitanti: "1930",
      },
      {
        Istat: "48002",
        Comune: "Barberino di Mugello",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50031",
        CodFisco: "A632",
        Abitanti: "10858",
      },
      {
        Istat: "48003",
        Comune: "Barberino Val d'Elsa",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50021",
        CodFisco: "A633",
        Abitanti: "4373",
      },
      {
        Istat: "46003",
        Comune: "Barga",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55051",
        CodFisco: "A657",
        Abitanti: "10327",
      },
      {
        Istat: "51004",
        Comune: "Bibbiena",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52011",
        CodFisco: "A851",
        Abitanti: "12731",
      },
      {
        Istat: "49001",
        Comune: "Bibbona",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57020",
        CodFisco: "A852",
        Abitanti: "3251",
      },
      {
        Istat: "50001",
        Comune: "Bientina",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56031",
        CodFisco: "A864",
        Abitanti: "7709",
      },
      {
        Istat: "46004",
        Comune: "Borgo a Mozzano",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55023",
        CodFisco: "B007",
        Abitanti: "7396",
      },
      {
        Istat: "48004",
        Comune: "Borgo San Lorenzo",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50032",
        CodFisco: "B036",
        Abitanti: "18191",
      },
      {
        Istat: "51005",
        Comune: "Bucine",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52021",
        CodFisco: "B243",
        Abitanti: "10194",
      },
      {
        Istat: "47003",
        Comune: "Buggiano",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51011",
        CodFisco: "B251",
        Abitanti: "8882",
      },
      {
        Istat: "52003",
        Comune: "Buonconvento",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53022",
        CodFisco: "B269",
        Abitanti: "3197",
      },
      {
        Istat: "50002",
        Comune: "Buti",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56032",
        CodFisco: "B303",
        Abitanti: "5856",
      },
      {
        Istat: "50003",
        Comune: "Calci",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56011",
        CodFisco: "B390",
        Abitanti: "6513",
      },
      {
        Istat: "50004",
        Comune: "Calcinaia",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56012",
        CodFisco: "B392",
        Abitanti: "11692",
      },
      {
        Istat: "48005",
        Comune: "Calenzano",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50041",
        CodFisco: "B406",
        Abitanti: "16462",
      },
      {
        Istat: "46005",
        Comune: "Camaiore",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "584",
        CAP: "55041",
        CodFisco: "B455",
        Abitanti: "32600",
      },
      {
        Istat: "53002",
        Comune: "Campagnatico",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58042",
        CodFisco: "B497",
        Abitanti: "2532",
      },
      {
        Istat: "48006",
        Comune: "Campi Bisenzio",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50013",
        CodFisco: "B507",
        Abitanti: "43901",
      },
      {
        Istat: "49002",
        Comune: "Campiglia Marittima",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57021",
        CodFisco: "B509",
        Abitanti: "13339",
      },
      {
        Istat: "49003",
        Comune: "Campo nell'Elba",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57034",
        CodFisco: "B553",
        Abitanti: "4651",
      },
      {
        Istat: "46006",
        Comune: "Camporgiano",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55031",
        CodFisco: "B557",
        Abitanti: "2316",
      },
      {
        Istat: "100001",
        Comune: "Cantagallo",
        Provincia: "PO",
        Regione: "TOS",
        Prefisso: "574",
        CAP: "59025",
        CodFisco: "B626",
        Abitanti: "3095",
      },
      {
        Istat: "53003",
        Comune: "Capalbio",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58011",
        CodFisco: "B646",
        Abitanti: "4287",
      },
      {
        Istat: "50005",
        Comune: "Capannoli",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56033",
        CodFisco: "B647",
        Abitanti: "6145",
      },
      {
        Istat: "46007",
        Comune: "Capannori",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55012",
        CodFisco: "B648",
        Abitanti: "46207",
      },
      {
        Istat: "49004",
        Comune: "Capoliveri",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57031",
        CodFisco: "B669",
        Abitanti: "3887",
      },
      {
        Istat: "51006",
        Comune: "Capolona",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52010",
        CodFisco: "B670",
        Abitanti: "5515",
      },
      {
        Istat: "48008",
        Comune: "Capraia e Limite",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50050",
        CodFisco: "B684",
        Abitanti: "7268",
      },
      {
        Istat: "49005",
        Comune: "Capraia Isola",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57032",
        CodFisco: "B685",
        Abitanti: "410",
      },
      {
        Istat: "51007",
        Comune: "Caprese Michelangelo",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52033",
        CodFisco: "B693",
        Abitanti: "1551",
      },
      {
        Istat: "46008",
        Comune: "Careggine",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55030",
        CodFisco: "B760",
        Abitanti: "607",
      },
      {
        Istat: "100002",
        Comune: "Carmignano",
        Provincia: "PO",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "59015",
        CodFisco: "B794",
        Abitanti: "14187",
      },
      {
        Istat: "45003",
        Comune: "Carrara",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "585",
        CAP: "54033",
        CodFisco: "B832",
        Abitanti: "65573",
      },
      {
        Istat: "50006",
        Comune: "Casale Marittimo",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "56040",
        CodFisco: "B878",
        Abitanti: "1067",
      },
      {
        Istat: "50007",
        Comune: "Casciana Terme",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56034",
        CodFisco: "A559",
        Abitanti: "3676",
      },
      {
        Istat: "50008",
        Comune: "Cascina",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56021",
        CodFisco: "B950",
        Abitanti: "44201",
      },
      {
        Istat: "45004",
        Comune: "Casola in Lunigiana",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "585",
        CAP: "54014",
        CodFisco: "B979",
        Abitanti: "1042",
      },
      {
        Istat: "52004",
        Comune: "Casole d'Elsa",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53031",
        CodFisco: "B984",
        Abitanti: "3880",
      },
      {
        Istat: "49006",
        Comune: "Castagneto Carducci",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57022",
        CodFisco: "C044",
        Abitanti: "8934",
      },
      {
        Istat: "53004",
        Comune: "Castel del Piano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58033",
        CodFisco: "C085",
        Abitanti: "4700",
      },
      {
        Istat: "51008",
        Comune: "Castel Focognano",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52016",
        CodFisco: "C102",
        Abitanti: "3298",
      },
      {
        Istat: "51010",
        Comune: "Castel San Niccolò",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52018",
        CodFisco: "C263",
        Abitanti: "2778",
      },
      {
        Istat: "48010",
        Comune: "Castelfiorentino",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50051",
        CodFisco: "C101",
        Abitanti: "17959",
      },
      {
        Istat: "51009",
        Comune: "Castelfranco di Sopra",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52020",
        CodFisco: "C112",
        Abitanti: "3099",
      },
      {
        Istat: "50009",
        Comune: "Castelfranco di Sotto",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "56022",
        CodFisco: "C113",
        Abitanti: "13219",
      },
      {
        Istat: "53005",
        Comune: "Castell'Azzara",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58034",
        CodFisco: "C147",
        Abitanti: "1625",
      },
      {
        Istat: "52005",
        Comune: "Castellina in Chianti",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53011",
        CodFisco: "C172",
        Abitanti: "2971",
      },
      {
        Istat: "50010",
        Comune: "Castellina Marittima",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56040",
        CodFisco: "C174",
        Abitanti: "2055",
      },
      {
        Istat: "52006",
        Comune: "Castelnuovo Berardenga",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53019",
        CodFisco: "C227",
        Abitanti: "9133",
      },
      {
        Istat: "46009",
        Comune: "Castelnuovo di Garfagnana",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55032",
        CodFisco: "C236",
        Abitanti: "6117",
      },
      {
        Istat: "50011",
        Comune: "Castelnuovo di Val di Cecina",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "588",
        CAP: "56041",
        CodFisco: "C244",
        Abitanti: "2341",
      },
      {
        Istat: "51011",
        Comune: "Castiglion Fibocchi",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52029",
        CodFisco: "C318",
        Abitanti: "2248",
      },
      {
        Istat: "51012",
        Comune: "Castiglion Fiorentino",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52043",
        CodFisco: "C319",
        Abitanti: "13630",
      },
      {
        Istat: "53006",
        Comune: "Castiglione della Pescaia",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58043",
        CodFisco: "C310",
        Abitanti: "7449",
      },
      {
        Istat: "46010",
        Comune: "Castiglione di Garfagnana",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55033",
        CodFisco: "C303",
        Abitanti: "1895",
      },
      {
        Istat: "52007",
        Comune: "Castiglione d'Orcia",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53023",
        CodFisco: "C313",
        Abitanti: "2485",
      },
      {
        Istat: "51013",
        Comune: "Cavriglia",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52022",
        CodFisco: "C407",
        Abitanti: "9522",
      },
      {
        Istat: "49007",
        Comune: "Cecina",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57023",
        CodFisco: "C415",
        Abitanti: "28573",
      },
      {
        Istat: "48011",
        Comune: "Cerreto Guidi",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50050",
        CodFisco: "C529",
        Abitanti: "10721",
      },
      {
        Istat: "48012",
        Comune: "Certaldo",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50052",
        CodFisco: "C540",
        Abitanti: "16343",
      },
      {
        Istat: "52008",
        Comune: "Cetona",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53040",
        CodFisco: "C587",
        Abitanti: "2913",
      },
      {
        Istat: "52009",
        Comune: "Chianciano Terme",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53042",
        CodFisco: "C608",
        Abitanti: "7447",
      },
      {
        Istat: "50012",
        Comune: "Chianni",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56034",
        CodFisco: "C609",
        Abitanti: "1505",
      },
      {
        Istat: "47022",
        Comune: "Chiesina Uzzanese",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51013",
        CodFisco: "C631",
        Abitanti: "4462",
      },
      {
        Istat: "51014",
        Comune: "Chitignano",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52010",
        CodFisco: "C648",
        Abitanti: "951",
      },
      {
        Istat: "52010",
        Comune: "Chiusdino",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53012",
        CodFisco: "C661",
        Abitanti: "2036",
      },
      {
        Istat: "52011",
        Comune: "Chiusi",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53043",
        CodFisco: "C662",
        Abitanti: "8838",
      },
      {
        Istat: "51015",
        Comune: "Chiusi della Verna",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52010",
        CodFisco: "C663",
        Abitanti: "2083",
      },
      {
        Istat: "53007",
        Comune: "Cinigiano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58044",
        CodFisco: "C705",
        Abitanti: "2778",
      },
      {
        Istat: "51016",
        Comune: "Civitella in Val di Chiana",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52041",
        CodFisco: "C774",
        Abitanti: "9183",
      },
      {
        Istat: "53008",
        Comune: "Civitella Paganico",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58045",
        CodFisco: "C782",
        Abitanti: "3249",
      },
      {
        Istat: "52012",
        Comune: "Colle di Val d'Elsa",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53034",
        CodFisco: "C847",
        Abitanti: "21629",
      },
      {
        Istat: "49008",
        Comune: "Collesalvetti",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57014",
        CodFisco: "C869",
        Abitanti: "16919",
      },
      {
        Istat: "45005",
        Comune: "Comano",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54015",
        CodFisco: "C914",
        Abitanti: "774",
      },
      {
        Istat: "46011",
        Comune: "Coreglia Antelminelli",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55025",
        CodFisco: "C996",
        Abitanti: "5327",
      },
      {
        Istat: "51017",
        Comune: "Cortona",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52044",
        CodFisco: "D077",
        Abitanti: "23036",
      },
      {
        Istat: "50013",
        Comune: "Crespina",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56040",
        CodFisco: "D160",
        Abitanti: "4131",
      },
      {
        Istat: "47004",
        Comune: "Cutigliano",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51024",
        CodFisco: "D235",
        Abitanti: "1580",
      },
      {
        Istat: "48013",
        Comune: "Dicomano",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50062",
        CodFisco: "D299",
        Abitanti: "5777",
      },
      {
        Istat: "48014",
        Comune: "Empoli",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50053",
        CodFisco: "D403",
        Abitanti: "47955",
      },
      {
        Istat: "46012",
        Comune: "Fabbriche di Vallico",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55020",
        CodFisco: "D449",
        Abitanti: "504",
      },
      {
        Istat: "50014",
        Comune: "Fauglia",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56043",
        CodFisco: "D510",
        Abitanti: "3601",
      },
      {
        Istat: "48015",
        Comune: "Fiesole",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50014",
        CodFisco: "D575",
        Abitanti: "14341",
      },
      {
        Istat: "48016",
        Comune: "Figline Valdarno",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50063",
        CodFisco: "D583",
        Abitanti: "17050",
      },
      {
        Istat: "45006",
        Comune: "Filattiera",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54023",
        CodFisco: "D590",
        Abitanti: "2406",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50121",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50122",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50123",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50124",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50125",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50126",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50127",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50128",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50129",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50130",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50131",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50132",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50133",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50134",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50135",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50136",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50137",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50138",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50139",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50140",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50141",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50142",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50143",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50144",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48017",
        Comune: "Firenze",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50145",
        CodFisco: "D612",
        Abitanti: "371282",
      },
      {
        Istat: "48018",
        Comune: "Firenzuola",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50033",
        CodFisco: "D613",
        Abitanti: "4950",
      },
      {
        Istat: "45007",
        Comune: "Fivizzano",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "585",
        CAP: "54013",
        CodFisco: "D629",
        Abitanti: "8524",
      },
      {
        Istat: "51018",
        Comune: "Foiano della Chiana",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52045",
        CodFisco: "D649",
        Abitanti: "9622",
      },
      {
        Istat: "53009",
        Comune: "Follonica",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "566",
        CAP: "58022",
        CodFisco: "D656",
        Abitanti: "22113",
      },
      {
        Istat: "46013",
        Comune: "Forte dei Marmi",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "584",
        CAP: "55042",
        CodFisco: "D730",
        Abitanti: "7752",
      },
      {
        Istat: "46014",
        Comune: "Fosciandora",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55020",
        CodFisco: "D734",
        Abitanti: "629",
      },
      {
        Istat: "45008",
        Comune: "Fosdinovo",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54035",
        CodFisco: "D735",
        Abitanti: "5054",
      },
      {
        Istat: "48019",
        Comune: "Fucecchio",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50054",
        CodFisco: "D815",
        Abitanti: "23496",
      },
      {
        Istat: "52013",
        Comune: "Gaiole in Chianti",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53013",
        CodFisco: "D858",
        Abitanti: "2769",
      },
      {
        Istat: "46015",
        Comune: "Gallicano",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55027",
        CodFisco: "D874",
        Abitanti: "3931",
      },
      {
        Istat: "48020",
        Comune: "Gambassi Terme",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50050",
        CodFisco: "D895",
        Abitanti: "4930",
      },
      {
        Istat: "53010",
        Comune: "Gavorrano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "566",
        CAP: "58023",
        CodFisco: "D948",
        Abitanti: "8982",
      },
      {
        Istat: "46016",
        Comune: "Giuncugnano",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55030",
        CodFisco: "E059",
        Abitanti: "483",
      },
      {
        Istat: "48021",
        Comune: "Greve in Chianti",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50022",
        CodFisco: "E169",
        Abitanti: "14351",
      },
      {
        Istat: "53011",
        Comune: "Grosseto",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58100",
        CodFisco: "E202",
        Abitanti: "81928",
      },
      {
        Istat: "50015",
        Comune: "Guardistallo",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "56040",
        CodFisco: "E250",
        Abitanti: "1296",
      },
      {
        Istat: "48022",
        Comune: "Impruneta",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50023",
        CodFisco: "E291",
        Abitanti: "14906",
      },
      {
        Istat: "48023",
        Comune: "Incisa in Val d'Arno",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50064",
        CodFisco: "E296",
        Abitanti: "6339",
      },
      {
        Istat: "53012",
        Comune: "Isola del Giglio",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58012",
        CodFisco: "E348",
        Abitanti: "1466",
      },
      {
        Istat: "50016",
        Comune: "Lajatico",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56030",
        CodFisco: "E413",
        Abitanti: "1376",
      },
      {
        Istat: "47005",
        Comune: "Lamporecchio",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51035",
        CodFisco: "E432",
        Abitanti: "7724",
      },
      {
        Istat: "47006",
        Comune: "Larciano",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51036",
        CodFisco: "E451",
        Abitanti: "6392",
      },
      {
        Istat: "50017",
        Comune: "Lari",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56035",
        CodFisco: "E455",
        Abitanti: "8841",
      },
      {
        Istat: "48024",
        Comune: "Lastra a Signa",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50055",
        CodFisco: "E466",
        Abitanti: "19829",
      },
      {
        Istat: "51019",
        Comune: "Laterina",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52020",
        CodFisco: "E468",
        Abitanti: "3606",
      },
      {
        Istat: "45009",
        Comune: "Licciana Nardi",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54016",
        CodFisco: "E574",
        Abitanti: "4987",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57121",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57122",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57123",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57124",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57125",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57126",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57127",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "49009",
        Comune: "Livorno",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57128",
        CodFisco: "E625",
        Abitanti: "161131",
      },
      {
        Istat: "48025",
        Comune: "Londa",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50060",
        CodFisco: "E668",
        Abitanti: "1874",
      },
      {
        Istat: "50018",
        Comune: "Lorenzana",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56043",
        CodFisco: "E688",
        Abitanti: "1203",
      },
      {
        Istat: "51020",
        Comune: "Loro Ciuffenna",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52024",
        CodFisco: "E693",
        Abitanti: "5925",
      },
      {
        Istat: "46017",
        Comune: "Lucca",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55100",
        CodFisco: "E715",
        Abitanti: "84939",
      },
      {
        Istat: "51021",
        Comune: "Lucignano",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52046",
        CodFisco: "E718",
        Abitanti: "3613",
      },
      {
        Istat: "53013",
        Comune: "Magliano in Toscana",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58051",
        CodFisco: "E810",
        Abitanti: "3746",
      },
      {
        Istat: "53014",
        Comune: "Manciano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58014",
        CodFisco: "E875",
        Abitanti: "7633",
      },
      {
        Istat: "49010",
        Comune: "Marciana",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57030",
        CodFisco: "E930",
        Abitanti: "2217",
      },
      {
        Istat: "49011",
        Comune: "Marciana Marina",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57033",
        CodFisco: "E931",
        Abitanti: "1993",
      },
      {
        Istat: "51022",
        Comune: "Marciano della Chiana",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52047",
        CodFisco: "E933",
        Abitanti: "3378",
      },
      {
        Istat: "47007",
        Comune: "Marliana",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51010",
        CodFisco: "E960",
        Abitanti: "3233",
      },
      {
        Istat: "48026",
        Comune: "Marradi",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50034",
        CodFisco: "E971",
        Abitanti: "3303",
      },
      {
        Istat: "45010",
        Comune: "Massa",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "585",
        CAP: "54100",
        CodFisco: "F023",
        Abitanti: "70973",
      },
      {
        Istat: "47008",
        Comune: "Massa e Cozzile",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51010",
        CodFisco: "F025",
        Abitanti: "7872",
      },
      {
        Istat: "53015",
        Comune: "Massa Marittima",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "566",
        CAP: "58024",
        CodFisco: "F032",
        Abitanti: "8781",
      },
      {
        Istat: "46018",
        Comune: "Massarosa",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "584",
        CAP: "55054",
        CodFisco: "F035",
        Abitanti: "23004",
      },
      {
        Istat: "46019",
        Comune: "Minucciano",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55034",
        CodFisco: "F225",
        Abitanti: "2278",
      },
      {
        Istat: "46020",
        Comune: "Molazzana",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55020",
        CodFisco: "F283",
        Abitanti: "1152",
      },
      {
        Istat: "47009",
        Comune: "Monsummano Terme",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51015",
        CodFisco: "F384",
        Abitanti: "21374",
      },
      {
        Istat: "48027",
        Comune: "Montaione",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50050",
        CodFisco: "F398",
        Abitanti: "3773",
      },
      {
        Istat: "52014",
        Comune: "Montalcino",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53024",
        CodFisco: "F402",
        Abitanti: "5272",
      },
      {
        Istat: "47010",
        Comune: "Montale",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51037",
        CodFisco: "F410",
        Abitanti: "10766",
      },
      {
        Istat: "53016",
        Comune: "Monte Argentario",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58019",
        CodFisco: "F437",
        Abitanti: "12997",
      },
      {
        Istat: "51025",
        Comune: "Monte San Savino",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52048",
        CodFisco: "F628",
        Abitanti: "8754",
      },
      {
        Istat: "46021",
        Comune: "Montecarlo",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55015",
        CodFisco: "F452",
        Abitanti: "4538",
      },
      {
        Istat: "50019",
        Comune: "Montecatini Val di Cecina",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "588",
        CAP: "56040",
        CodFisco: "F458",
        Abitanti: "1883",
      },
      {
        Istat: "47011",
        Comune: "Montecatini-Terme",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51016",
        CodFisco: "A561",
        Abitanti: "21374",
      },
      {
        Istat: "48028",
        Comune: "Montelupo Fiorentino",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50056",
        CodFisco: "F551",
        Abitanti: "13691",
      },
      {
        Istat: "51023",
        Comune: "Montemignaio",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52010",
        CodFisco: "F565",
        Abitanti: "622",
      },
      {
        Istat: "100003",
        Comune: "Montemurlo",
        Provincia: "PO",
        Regione: "TOS",
        Prefisso: "574",
        CAP: "59013",
        CodFisco: "F572",
        Abitanti: "18438",
      },
      {
        Istat: "52015",
        Comune: "Montepulciano",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53045",
        CodFisco: "F592",
        Abitanti: "14558",
      },
      {
        Istat: "51024",
        Comune: "Monterchi",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52035",
        CodFisco: "F594",
        Abitanti: "1847",
      },
      {
        Istat: "52016",
        Comune: "Monteriggioni",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53035",
        CodFisco: "F598",
        Abitanti: "9165",
      },
      {
        Istat: "52017",
        Comune: "Monteroni d'Arbia",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53014",
        CodFisco: "F605",
        Abitanti: "8812",
      },
      {
        Istat: "53027",
        Comune: "Monterotondo Marittimo",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "566",
        CAP: "58025",
        CodFisco: "F612",
        Abitanti: "1392",
      },
      {
        Istat: "50020",
        Comune: "Montescudaio",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "56040",
        CodFisco: "F640",
        Abitanti: "1946",
      },
      {
        Istat: "48030",
        Comune: "Montespertoli",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50025",
        CodFisco: "F648",
        Abitanti: "13452",
      },
      {
        Istat: "51026",
        Comune: "Montevarchi",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52025",
        CodFisco: "F656",
        Abitanti: "24166",
      },
      {
        Istat: "50021",
        Comune: "Monteverdi Marittimo",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "56040",
        CodFisco: "F661",
        Abitanti: "784",
      },
      {
        Istat: "52018",
        Comune: "Monticiano",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53015",
        CodFisco: "F676",
        Abitanti: "1565",
      },
      {
        Istat: "53017",
        Comune: "Montieri",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "566",
        CAP: "58026",
        CodFisco: "F677",
        Abitanti: "1250",
      },
      {
        Istat: "45011",
        Comune: "Montignoso",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "585",
        CAP: "54038",
        CodFisco: "F679",
        Abitanti: "10549",
      },
      {
        Istat: "50022",
        Comune: "Montopoli in Val d'Arno",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "56020",
        CodFisco: "F686",
        Abitanti: "11219",
      },
      {
        Istat: "45012",
        Comune: "Mulazzo",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54026",
        CodFisco: "F802",
        Abitanti: "2642",
      },
      {
        Istat: "52019",
        Comune: "Murlo",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53016",
        CodFisco: "F815",
        Abitanti: "2427",
      },
      {
        Istat: "53018",
        Comune: "Orbetello",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58015",
        CodFisco: "G088",
        Abitanti: "15246",
      },
      {
        Istat: "50023",
        Comune: "Orciano Pisano",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56040",
        CodFisco: "G090",
        Abitanti: "623",
      },
      {
        Istat: "51027",
        Comune: "Ortignano Raggiolo",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52010",
        CodFisco: "G139",
        Abitanti: "880",
      },
      {
        Istat: "50024",
        Comune: "Palaia",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56036",
        CodFisco: "G254",
        Abitanti: "4622",
      },
      {
        Istat: "48031",
        Comune: "Palazzuolo sul Senio",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50035",
        CodFisco: "G270",
        Abitanti: "1198",
      },
      {
        Istat: "50025",
        Comune: "Peccioli",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56037",
        CodFisco: "G395",
        Abitanti: "4966",
      },
      {
        Istat: "48032",
        Comune: "Pelago",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50060",
        CodFisco: "G420",
        Abitanti: "7702",
      },
      {
        Istat: "51028",
        Comune: "Pergine Valdarno",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52020",
        CodFisco: "G451",
        Abitanti: "3270",
      },
      {
        Istat: "46022",
        Comune: "Pescaglia",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55064",
        CodFisco: "G480",
        Abitanti: "3758",
      },
      {
        Istat: "47012",
        Comune: "Pescia",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51017",
        CodFisco: "G491",
        Abitanti: "19851",
      },
      {
        Istat: "51029",
        Comune: "Pian di Sco",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52026",
        CodFisco: "G552",
        Abitanti: "6407",
      },
      {
        Istat: "52020",
        Comune: "Piancastagnaio",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53025",
        CodFisco: "G547",
        Abitanti: "4187",
      },
      {
        Istat: "46023",
        Comune: "Piazza al Serchio",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55035",
        CodFisco: "G582",
        Abitanti: "2501",
      },
      {
        Istat: "52021",
        Comune: "Pienza",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53026",
        CodFisco: "G602",
        Abitanti: "2186",
      },
      {
        Istat: "46024",
        Comune: "Pietrasanta",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "584",
        CAP: "55045",
        CodFisco: "G628",
        Abitanti: "24931",
      },
      {
        Istat: "47013",
        Comune: "Pieve a Nievole",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51018",
        CodFisco: "G636",
        Abitanti: "9632",
      },
      {
        Istat: "46025",
        Comune: "Pieve Fosciana",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55036",
        CodFisco: "G648",
        Abitanti: "2450",
      },
      {
        Istat: "51030",
        Comune: "Pieve Santo Stefano",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52036",
        CodFisco: "G653",
        Abitanti: "3249",
      },
      {
        Istat: "49012",
        Comune: "Piombino",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57025",
        CodFisco: "G687",
        Abitanti: "35075",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56121",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56122",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56123",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56124",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56125",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56126",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56127",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "50026",
        Comune: "Pisa",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56128",
        CodFisco: "G702",
        Abitanti: "88217",
      },
      {
        Istat: "47014",
        Comune: "Pistoia",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51100",
        CodFisco: "G713",
        Abitanti: "90288",
      },
      {
        Istat: "47015",
        Comune: "Piteglio",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51020",
        CodFisco: "G715",
        Abitanti: "1840",
      },
      {
        Istat: "53019",
        Comune: "Pitigliano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58017",
        CodFisco: "G716",
        Abitanti: "3927",
      },
      {
        Istat: "45013",
        Comune: "Podenzana",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54010",
        CodFisco: "G746",
        Abitanti: "2178",
      },
      {
        Istat: "52022",
        Comune: "Poggibonsi",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53036",
        CodFisco: "G752",
        Abitanti: "29634",
      },
      {
        Istat: "100004",
        Comune: "Poggio a Caiano",
        Provincia: "PO",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "59016",
        CodFisco: "G754",
        Abitanti: "9959",
      },
      {
        Istat: "50027",
        Comune: "Pomarance",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "588",
        CAP: "56045",
        CodFisco: "G804",
        Abitanti: "6054",
      },
      {
        Istat: "50028",
        Comune: "Ponsacco",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56038",
        CodFisco: "G822",
        Abitanti: "15511",
      },
      {
        Istat: "48033",
        Comune: "Pontassieve",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50065",
        CodFisco: "G825",
        Abitanti: "20709",
      },
      {
        Istat: "47016",
        Comune: "Ponte Buggianese",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51019",
        CodFisco: "G833",
        Abitanti: "8840",
      },
      {
        Istat: "50029",
        Comune: "Pontedera",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56025",
        CodFisco: "G843",
        Abitanti: "28350",
      },
      {
        Istat: "45014",
        Comune: "Pontremoli",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54027",
        CodFisco: "G870",
        Abitanti: "7770",
      },
      {
        Istat: "51031",
        Comune: "Poppi",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52014",
        CodFisco: "G879",
        Abitanti: "6396",
      },
      {
        Istat: "46026",
        Comune: "Porcari",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55016",
        CodFisco: "G882",
        Abitanti: "8735",
      },
      {
        Istat: "49013",
        Comune: "Porto Azzurro",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57036",
        CodFisco: "E680",
        Abitanti: "3578",
      },
      {
        Istat: "49014",
        Comune: "Portoferraio",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57037",
        CodFisco: "G912",
        Abitanti: "12253",
      },
      {
        Istat: "100005",
        Comune: "Prato",
        Provincia: "PO",
        Regione: "TOS",
        Prefisso: "574",
        CAP: "59100",
        CodFisco: "G999",
        Abitanti: "188011",
      },
      {
        Istat: "51032",
        Comune: "Pratovecchio",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52015",
        CodFisco: "H008",
        Abitanti: "3150",
      },
      {
        Istat: "47017",
        Comune: "Quarrata",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51039",
        CodFisco: "H109",
        Abitanti: "25379",
      },
      {
        Istat: "52023",
        Comune: "Radda in Chianti",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53017",
        CodFisco: "H153",
        Abitanti: "1690",
      },
      {
        Istat: "52024",
        Comune: "Radicofani",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53040",
        CodFisco: "H156",
        Abitanti: "1165",
      },
      {
        Istat: "52025",
        Comune: "Radicondoli",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53030",
        CodFisco: "H157",
        Abitanti: "954",
      },
      {
        Istat: "52026",
        Comune: "Rapolano Terme",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53040",
        CodFisco: "H185",
        Abitanti: "5308",
      },
      {
        Istat: "48035",
        Comune: "Reggello",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50066",
        CodFisco: "H222",
        Abitanti: "16296",
      },
      {
        Istat: "48036",
        Comune: "Rignano sull'Arno",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50067",
        CodFisco: "H286",
        Abitanti: "8758",
      },
      {
        Istat: "49015",
        Comune: "Rio Marina",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57038",
        CodFisco: "H305",
        Abitanti: "2274",
      },
      {
        Istat: "49016",
        Comune: "Rio nell'Elba",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57039",
        CodFisco: "H297",
        Abitanti: "1244",
      },
      {
        Istat: "50030",
        Comune: "Riparbella",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "56046",
        CodFisco: "H319",
        Abitanti: "1646",
      },
      {
        Istat: "53020",
        Comune: "Roccalbegna",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58053",
        CodFisco: "H417",
        Abitanti: "1136",
      },
      {
        Istat: "53021",
        Comune: "Roccastrada",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58036",
        CodFisco: "H449",
        Abitanti: "9540",
      },
      {
        Istat: "49017",
        Comune: "Rosignano Marittimo",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "586",
        CAP: "57016",
        CodFisco: "H570",
        Abitanti: "32488",
      },
      {
        Istat: "48037",
        Comune: "Rufina",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50068",
        CodFisco: "H635",
        Abitanti: "7461",
      },
      {
        Istat: "47018",
        Comune: "Sambuca Pistoiese",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51020",
        CodFisco: "H744",
        Abitanti: "1749",
      },
      {
        Istat: "52027",
        Comune: "San Casciano dei Bagni",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53040",
        CodFisco: "H790",
        Abitanti: "1703",
      },
      {
        Istat: "48038",
        Comune: "San Casciano in Val di Pesa",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50026",
        CodFisco: "H791",
        Abitanti: "17216",
      },
      {
        Istat: "52028",
        Comune: "San Gimignano",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53037",
        CodFisco: "H875",
        Abitanti: "7806",
      },
      {
        Istat: "52029",
        Comune: "San Giovanni d'Asso",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53020",
        CodFisco: "H911",
        Abitanti: "912",
      },
      {
        Istat: "51033",
        Comune: "San Giovanni Valdarno",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52027",
        CodFisco: "H901",
        Abitanti: "17118",
      },
      {
        Istat: "50031",
        Comune: "San Giuliano Terme",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56017",
        CodFisco: "A562",
        Abitanti: "31822",
      },
      {
        Istat: "48039",
        Comune: "San Godenzo",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50060",
        CodFisco: "H937",
        Abitanti: "1258",
      },
      {
        Istat: "47019",
        Comune: "San Marcello Pistoiese",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51028",
        CodFisco: "H980",
        Abitanti: "6818",
      },
      {
        Istat: "50032",
        Comune: "San Miniato",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "56028",
        CodFisco: "I046",
        Abitanti: "28257",
      },
      {
        Istat: "48040",
        Comune: "San Piero a Sieve",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50037",
        CodFisco: "I085",
        Abitanti: "4305",
      },
      {
        Istat: "52030",
        Comune: "San Quirico d'Orcia",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53027",
        CodFisco: "I135",
        Abitanti: "2774",
      },
      {
        Istat: "46027",
        Comune: "San Romano in Garfagnana",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55038",
        CodFisco: "I142",
        Abitanti: "1486",
      },
      {
        Istat: "49018",
        Comune: "San Vincenzo",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57027",
        CodFisco: "I390",
        Abitanti: "7000",
      },
      {
        Istat: "51034",
        Comune: "Sansepolcro",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52037",
        CodFisco: "I155",
        Abitanti: "16380",
      },
      {
        Istat: "50033",
        Comune: "Santa Croce sull'Arno",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "56029",
        CodFisco: "I177",
        Abitanti: "14356",
      },
      {
        Istat: "53022",
        Comune: "Santa Fiora",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58037",
        CodFisco: "I187",
        Abitanti: "2773",
      },
      {
        Istat: "50034",
        Comune: "Santa Luce",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56040",
        CodFisco: "I217",
        Abitanti: "1715",
      },
      {
        Istat: "50035",
        Comune: "Santa Maria a Monte",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56020",
        CodFisco: "I232",
        Abitanti: "12813",
      },
      {
        Istat: "52031",
        Comune: "Sarteano",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "578",
        CAP: "53047",
        CodFisco: "I445",
        Abitanti: "4905",
      },
      {
        Istat: "49019",
        Comune: "Sassetta",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57020",
        CodFisco: "I454",
        Abitanti: "567",
      },
      {
        Istat: "48041",
        Comune: "Scandicci",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50018",
        CodFisco: "B962",
        Abitanti: "50309",
      },
      {
        Istat: "53023",
        Comune: "Scansano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58054",
        CodFisco: "I504",
        Abitanti: "4636",
      },
      {
        Istat: "53024",
        Comune: "Scarlino",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "566",
        CAP: "58020",
        CodFisco: "I510",
        Abitanti: "3718",
      },
      {
        Istat: "48042",
        Comune: "Scarperia",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50038",
        CodFisco: "I514",
        Abitanti: "7809",
      },
      {
        Istat: "53025",
        Comune: "Seggiano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58038",
        CodFisco: "I571",
        Abitanti: "998",
      },
      {
        Istat: "53028",
        Comune: "Semproniano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58055",
        CodFisco: "I601",
        Abitanti: "1192",
      },
      {
        Istat: "46028",
        Comune: "Seravezza",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "584",
        CAP: "55047",
        CodFisco: "I622",
        Abitanti: "13315",
      },
      {
        Istat: "47020",
        Comune: "Serravalle Pistoiese",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "573",
        CAP: "51030",
        CodFisco: "I660",
        Abitanti: "11561",
      },
      {
        Istat: "51035",
        Comune: "Sestino",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52038",
        CodFisco: "I681",
        Abitanti: "1451",
      },
      {
        Istat: "48043",
        Comune: "Sesto Fiorentino",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50019",
        CodFisco: "I684",
        Abitanti: "47623",
      },
      {
        Istat: "52032",
        Comune: "Siena",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53100",
        CodFisco: "I726",
        Abitanti: "54543",
      },
      {
        Istat: "48044",
        Comune: "Signa",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50058",
        CodFisco: "I728",
        Abitanti: "18510",
      },
      {
        Istat: "46029",
        Comune: "Sillano",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55030",
        CodFisco: "I737",
        Abitanti: "706",
      },
      {
        Istat: "52033",
        Comune: "Sinalunga",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53048",
        CodFisco: "A468",
        Abitanti: "12926",
      },
      {
        Istat: "53026",
        Comune: "Sorano",
        Provincia: "GR",
        Regione: "TOS",
        Prefisso: "564",
        CAP: "58010",
        CodFisco: "I841",
        Abitanti: "3656",
      },
      {
        Istat: "52034",
        Comune: "Sovicille",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53018",
        CodFisco: "I877",
        Abitanti: "9925",
      },
      {
        Istat: "46030",
        Comune: "Stazzema",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "584",
        CAP: "55040",
        CodFisco: "I942",
        Abitanti: "3362",
      },
      {
        Istat: "51036",
        Comune: "Stia",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52017",
        CodFisco: "I952",
        Abitanti: "2954",
      },
      {
        Istat: "51037",
        Comune: "Subbiano",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52010",
        CodFisco: "I991",
        Abitanti: "6408",
      },
      {
        Istat: "49020",
        Comune: "Suvereto",
        Provincia: "LI",
        Regione: "TOS",
        Prefisso: "565",
        CAP: "57028",
        CodFisco: "L019",
        Abitanti: "3171",
      },
      {
        Istat: "51038",
        Comune: "Talla",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "575",
        CAP: "52010",
        CodFisco: "L038",
        Abitanti: "1151",
      },
      {
        Istat: "48045",
        Comune: "Tavarnelle Val di Pesa",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50028",
        CodFisco: "L067",
        Abitanti: "7755",
      },
      {
        Istat: "51039",
        Comune: "Terranuova Bracciolini",
        Provincia: "AR",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "52028",
        CodFisco: "L123",
        Abitanti: "12340",
      },
      {
        Istat: "50036",
        Comune: "Terricciola",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "587",
        CAP: "56030",
        CodFisco: "L138",
        Abitanti: "4556",
      },
      {
        Istat: "52035",
        Comune: "Torrita di Siena",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53049",
        CodFisco: "L303",
        Abitanti: "7522",
      },
      {
        Istat: "52036",
        Comune: "Trequanda",
        Provincia: "SI",
        Regione: "TOS",
        Prefisso: "577",
        CAP: "53020",
        CodFisco: "L384",
        Abitanti: "1380",
      },
      {
        Istat: "45015",
        Comune: "Tresana",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54012",
        CodFisco: "L386",
        Abitanti: "2093",
      },
      {
        Istat: "47021",
        Comune: "Uzzano",
        Provincia: "PT",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "51010",
        CodFisco: "L522",
        Abitanti: "5671",
      },
      {
        Istat: "46031",
        Comune: "Vagli Sotto",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55030",
        CodFisco: "L533",
        Abitanti: "995",
      },
      {
        Istat: "48046",
        Comune: "Vaglia",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50036",
        CodFisco: "L529",
        Abitanti: "5183",
      },
      {
        Istat: "100006",
        Comune: "Vaiano",
        Provincia: "PO",
        Regione: "TOS",
        Prefisso: "574",
        CAP: "59021",
        CodFisco: "L537",
        Abitanti: "9990",
      },
      {
        Istat: "50037",
        Comune: "Vecchiano",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56019",
        CodFisco: "L702",
        Abitanti: "12472",
      },
      {
        Istat: "46032",
        Comune: "Vergemoli",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55020",
        CodFisco: "L763",
        Abitanti: "336",
      },
      {
        Istat: "100007",
        Comune: "Vernio",
        Provincia: "PO",
        Regione: "TOS",
        Prefisso: "574",
        CAP: "59024",
        CodFisco: "L775",
        Abitanti: "6095",
      },
      {
        Istat: "46033",
        Comune: "Viareggio",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "584",
        CAP: "55049",
        CodFisco: "L833",
        Abitanti: "64503",
      },
      {
        Istat: "48049",
        Comune: "Vicchio",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "55",
        CAP: "50039",
        CodFisco: "L838",
        Abitanti: "8262",
      },
      {
        Istat: "50038",
        Comune: "Vicopisano",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "50",
        CAP: "56010",
        CodFisco: "L850",
        Abitanti: "8466",
      },
      {
        Istat: "46034",
        Comune: "Villa Basilica",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "572",
        CAP: "55019",
        CodFisco: "L913",
        Abitanti: "1736",
      },
      {
        Istat: "46035",
        Comune: "Villa Collemandina",
        Provincia: "LU",
        Regione: "TOS",
        Prefisso: "583",
        CAP: "55030",
        CodFisco: "L926",
        Abitanti: "1404",
      },
      {
        Istat: "45016",
        Comune: "Villafranca in Lunigiana",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54028",
        CodFisco: "L946",
        Abitanti: "4877",
      },
      {
        Istat: "48050",
        Comune: "Vinci",
        Provincia: "FI",
        Regione: "TOS",
        Prefisso: "571",
        CAP: "50059",
        CodFisco: "M059",
        Abitanti: "14582",
      },
      {
        Istat: "50039",
        Comune: "Volterra",
        Provincia: "PI",
        Regione: "TOS",
        Prefisso: "588",
        CAP: "56048",
        CodFisco: "M126",
        Abitanti: "11077",
      },
      {
        Istat: "45017",
        Comune: "Zeri",
        Provincia: "MS",
        Regione: "TOS",
        Prefisso: "187",
        CAP: "54029",
        CodFisco: "M169",
        Abitanti: "1217",
      },
      {
        Istat: "55001",
        Comune: "Acquasparta",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05021",
        CodFisco: "A045",
        Abitanti: "5173",
      },
      {
        Istat: "55002",
        Comune: "Allerona",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05011",
        CodFisco: "A207",
        Abitanti: "1881",
      },
      {
        Istat: "55003",
        Comune: "Alviano",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05020",
        CodFisco: "A242",
        Abitanti: "1546",
      },
      {
        Istat: "55004",
        Comune: "Amelia",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05022",
        CodFisco: "A262",
        Abitanti: "12091",
      },
      {
        Istat: "55005",
        Comune: "Arrone",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05031",
        CodFisco: "A439",
        Abitanti: "2918",
      },
      {
        Istat: "54001",
        Comune: "Assisi",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06081",
        CodFisco: "A475",
        Abitanti: "28143",
      },
      {
        Istat: "55006",
        Comune: "Attigliano",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05012",
        CodFisco: "A490",
        Abitanti: "1967",
      },
      {
        Istat: "55033",
        Comune: "Avigliano Umbro",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05020",
        CodFisco: "M258",
        Abitanti: "2671",
      },
      {
        Istat: "55007",
        Comune: "Baschi",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05023",
        CodFisco: "A691",
        Abitanti: "2847",
      },
      {
        Istat: "54002",
        Comune: "Bastia Umbra",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06083",
        CodFisco: "A710",
        Abitanti: "21800",
      },
      {
        Istat: "54003",
        Comune: "Bettona",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06084",
        CodFisco: "A832",
        Abitanti: "4428",
      },
      {
        Istat: "54004",
        Comune: "Bevagna",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06031",
        CodFisco: "A835",
        Abitanti: "5156",
      },
      {
        Istat: "55008",
        Comune: "Calvi dell'Umbria",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05032",
        CodFisco: "B446",
        Abitanti: "1942",
      },
      {
        Istat: "54005",
        Comune: "Campello sul Clitunno",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06042",
        CodFisco: "B504",
        Abitanti: "2538",
      },
      {
        Istat: "54006",
        Comune: "Cannara",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06033",
        CodFisco: "B609",
        Abitanti: "4324",
      },
      {
        Istat: "54007",
        Comune: "Cascia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06043",
        CodFisco: "B948",
        Abitanti: "3257",
      },
      {
        Istat: "55009",
        Comune: "Castel Giorgio",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05013",
        CodFisco: "C117",
        Abitanti: "2207",
      },
      {
        Istat: "54008",
        Comune: "Castel Ritaldi",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06044",
        CodFisco: "C252",
        Abitanti: "3354",
      },
      {
        Istat: "55010",
        Comune: "Castel Viscardo",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05014",
        CodFisco: "C289",
        Abitanti: "3079",
      },
      {
        Istat: "54009",
        Comune: "Castiglione del Lago",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06061",
        CodFisco: "C309",
        Abitanti: "15618",
      },
      {
        Istat: "54010",
        Comune: "Cerreto di Spoleto",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06041",
        CodFisco: "C527",
        Abitanti: "1149",
      },
      {
        Istat: "54011",
        Comune: "Citerna",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06010",
        CodFisco: "C742",
        Abitanti: "3517",
      },
      {
        Istat: "54012",
        Comune: "Città della Pieve",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "578",
        CAP: "06062",
        CodFisco: "C744",
        Abitanti: "7836",
      },
      {
        Istat: "54013",
        Comune: "Città di Castello",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06012",
        CodFisco: "C745",
        Abitanti: "40567",
      },
      {
        Istat: "54014",
        Comune: "Collazzone",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06050",
        CodFisco: "C845",
        Abitanti: "3565",
      },
      {
        Istat: "54015",
        Comune: "Corciano",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06073",
        CodFisco: "C990",
        Abitanti: "20972",
      },
      {
        Istat: "54016",
        Comune: "Costacciaro",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06021",
        CodFisco: "D108",
        Abitanti: "1319",
      },
      {
        Istat: "54017",
        Comune: "Deruta",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06053",
        CodFisco: "D279",
        Abitanti: "9622",
      },
      {
        Istat: "55011",
        Comune: "Fabro",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05015",
        CodFisco: "D454",
        Abitanti: "2951",
      },
      {
        Istat: "55012",
        Comune: "Ferentillo",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05034",
        CodFisco: "D538",
        Abitanti: "1981",
      },
      {
        Istat: "55013",
        Comune: "Ficulle",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05016",
        CodFisco: "D570",
        Abitanti: "1735",
      },
      {
        Istat: "54018",
        Comune: "Foligno",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06034",
        CodFisco: "D653",
        Abitanti: "58162",
      },
      {
        Istat: "54019",
        Comune: "Fossato di Vico",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06022",
        CodFisco: "D745",
        Abitanti: "2893",
      },
      {
        Istat: "54020",
        Comune: "Fratta Todina",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06054",
        CodFisco: "D787",
        Abitanti: "1896",
      },
      {
        Istat: "54021",
        Comune: "Giano dell'Umbria",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06030",
        CodFisco: "E012",
        Abitanti: "3826",
      },
      {
        Istat: "55014",
        Comune: "Giove",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05024",
        CodFisco: "E045",
        Abitanti: "1941",
      },
      {
        Istat: "54022",
        Comune: "Gualdo Cattaneo",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06035",
        CodFisco: "E229",
        Abitanti: "6472",
      },
      {
        Istat: "54023",
        Comune: "Gualdo Tadino",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06023",
        CodFisco: "E230",
        Abitanti: "15740",
      },
      {
        Istat: "55015",
        Comune: "Guardea",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05025",
        CodFisco: "E241",
        Abitanti: "1878",
      },
      {
        Istat: "54024",
        Comune: "Gubbio",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06024",
        CodFisco: "E256",
        Abitanti: "32998",
      },
      {
        Istat: "54025",
        Comune: "Lisciano Niccone",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06060",
        CodFisco: "E613",
        Abitanti: "627",
      },
      {
        Istat: "55016",
        Comune: "Lugnano in Teverina",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05020",
        CodFisco: "E729",
        Abitanti: "1569",
      },
      {
        Istat: "54026",
        Comune: "Magione",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06063",
        CodFisco: "E805",
        Abitanti: "14799",
      },
      {
        Istat: "54027",
        Comune: "Marsciano",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06055",
        CodFisco: "E975",
        Abitanti: "18770",
      },
      {
        Istat: "54028",
        Comune: "Massa Martana",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06056",
        CodFisco: "F024",
        Abitanti: "3947",
      },
      {
        Istat: "54029",
        Comune: "Monte Castello di Vibio",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06057",
        CodFisco: "F456",
        Abitanti: "1633",
      },
      {
        Istat: "54032",
        Comune: "Monte Santa Maria Tiberina",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06010",
        CodFisco: "F629",
        Abitanti: "1215",
      },
      {
        Istat: "55017",
        Comune: "Montecastrilli",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05026",
        CodFisco: "F457",
        Abitanti: "5265",
      },
      {
        Istat: "55018",
        Comune: "Montecchio",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05020",
        CodFisco: "F462",
        Abitanti: "1778",
      },
      {
        Istat: "54030",
        Comune: "Montefalco",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06036",
        CodFisco: "F492",
        Abitanti: "5763",
      },
      {
        Istat: "55019",
        Comune: "Montefranco",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05030",
        CodFisco: "F510",
        Abitanti: "1326",
      },
      {
        Istat: "55020",
        Comune: "Montegabbione",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05010",
        CodFisco: "F513",
        Abitanti: "1256",
      },
      {
        Istat: "54031",
        Comune: "Monteleone di Spoleto",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06045",
        CodFisco: "F540",
        Abitanti: "636",
      },
      {
        Istat: "55021",
        Comune: "Monteleone d'Orvieto",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05017",
        CodFisco: "F543",
        Abitanti: "1576",
      },
      {
        Istat: "54033",
        Comune: "Montone",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06014",
        CodFisco: "F685",
        Abitanti: "1675",
      },
      {
        Istat: "55022",
        Comune: "Narni",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05035",
        CodFisco: "F844",
        Abitanti: "20331",
      },
      {
        Istat: "54034",
        Comune: "Nocera Umbra",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06025",
        CodFisco: "F911",
        Abitanti: "6175",
      },
      {
        Istat: "54035",
        Comune: "Norcia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06046",
        CodFisco: "F935",
        Abitanti: "4995",
      },
      {
        Istat: "55023",
        Comune: "Orvieto",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05018",
        CodFisco: "G148",
        Abitanti: "21130",
      },
      {
        Istat: "55024",
        Comune: "Otricoli",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05030",
        CodFisco: "G189",
        Abitanti: "1984",
      },
      {
        Istat: "54036",
        Comune: "Paciano",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06060",
        CodFisco: "G212",
        Abitanti: "974",
      },
      {
        Istat: "54037",
        Comune: "Panicale",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06064",
        CodFisco: "G308",
        Abitanti: "5983",
      },
      {
        Istat: "55025",
        Comune: "Parrano",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05010",
        CodFisco: "G344",
        Abitanti: "600",
      },
      {
        Istat: "54038",
        Comune: "Passignano sul Trasimeno",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06065",
        CodFisco: "G359",
        Abitanti: "5713",
      },
      {
        Istat: "55026",
        Comune: "Penna in Teverina",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05028",
        CodFisco: "G432",
        Abitanti: "1129",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06121",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06122",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06123",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06124",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06125",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06126",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06127",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06128",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06129",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06130",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06131",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06132",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06133",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06134",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54039",
        Comune: "Perugia",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06135",
        CodFisco: "G478",
        Abitanti: "168169",
      },
      {
        Istat: "54040",
        Comune: "Piegaro",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06066",
        CodFisco: "G601",
        Abitanti: "3847",
      },
      {
        Istat: "54041",
        Comune: "Pietralunga",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06026",
        CodFisco: "G618",
        Abitanti: "2270",
      },
      {
        Istat: "54042",
        Comune: "Poggiodomo",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06040",
        CodFisco: "G758",
        Abitanti: "146",
      },
      {
        Istat: "55027",
        Comune: "Polino",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05030",
        CodFisco: "G790",
        Abitanti: "266",
      },
      {
        Istat: "55028",
        Comune: "Porano",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "763",
        CAP: "05010",
        CodFisco: "G881",
        Abitanti: "1971",
      },
      {
        Istat: "54043",
        Comune: "Preci",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06047",
        CodFisco: "H015",
        Abitanti: "778",
      },
      {
        Istat: "55029",
        Comune: "San Gemini",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05029",
        CodFisco: "H857",
        Abitanti: "4947",
      },
      {
        Istat: "54044",
        Comune: "San Giustino",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06016",
        CodFisco: "H935",
        Abitanti: "11428",
      },
      {
        Istat: "55030",
        Comune: "San Venanzo",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "05010",
        CodFisco: "I381",
        Abitanti: "2370",
      },
      {
        Istat: "54045",
        Comune: "Sant'Anatolia di Narco",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06040",
        CodFisco: "I263",
        Abitanti: "592",
      },
      {
        Istat: "54046",
        Comune: "Scheggia e Pascelupo",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06027",
        CodFisco: "I522",
        Abitanti: "1484",
      },
      {
        Istat: "54047",
        Comune: "Scheggino",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06040",
        CodFisco: "I523",
        Abitanti: "484",
      },
      {
        Istat: "54048",
        Comune: "Sellano",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06030",
        CodFisco: "I585",
        Abitanti: "1151",
      },
      {
        Istat: "54049",
        Comune: "Sigillo",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06028",
        CodFisco: "I727",
        Abitanti: "2532",
      },
      {
        Istat: "54050",
        Comune: "Spello",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06038",
        CodFisco: "I888",
        Abitanti: "8712",
      },
      {
        Istat: "54051",
        Comune: "Spoleto",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06049",
        CodFisco: "I921",
        Abitanti: "39574",
      },
      {
        Istat: "55031",
        Comune: "Stroncone",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05039",
        CodFisco: "I981",
        Abitanti: "5035",
      },
      {
        Istat: "55032",
        Comune: "Terni",
        Provincia: "TR",
        Regione: "UMB",
        Prefisso: "744",
        CAP: "05100",
        CodFisco: "L117",
        Abitanti: "113324",
      },
      {
        Istat: "54052",
        Comune: "Todi",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06059",
        CodFisco: "L188",
        Abitanti: "17399",
      },
      {
        Istat: "54053",
        Comune: "Torgiano",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06089",
        CodFisco: "L216",
        Abitanti: "6585",
      },
      {
        Istat: "54054",
        Comune: "Trevi",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06039",
        CodFisco: "L397",
        Abitanti: "8405",
      },
      {
        Istat: "54055",
        Comune: "Tuoro sul Trasimeno",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06069",
        CodFisco: "L466",
        Abitanti: "3886",
      },
      {
        Istat: "54056",
        Comune: "Umbertide",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06019",
        CodFisco: "D786",
        Abitanti: "16890",
      },
      {
        Istat: "54057",
        Comune: "Valfabbrica",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "75",
        CAP: "06029",
        CodFisco: "L573",
        Abitanti: "3560",
      },
      {
        Istat: "54058",
        Comune: "Vallo di Nera",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "743",
        CAP: "06040",
        CodFisco: "L627",
        Abitanti: "407",
      },
      {
        Istat: "54059",
        Comune: "Valtopina",
        Provincia: "PG",
        Regione: "UMB",
        Prefisso: "742",
        CAP: "06030",
        CodFisco: "L653",
        Abitanti: "1465",
      },
      {
        Istat: "7001",
        Comune: "Allein",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "A205",
        Abitanti: "258",
      },
      {
        Istat: "7002",
        Comune: "Antey-Saint-Andrè",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11020",
        CodFisco: "A305",
        Abitanti: "622",
      },
      {
        Istat: "7003",
        Comune: "Aosta",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11100",
        CodFisco: "A326",
        Abitanti: "35049",
      },
      {
        Istat: "7004",
        Comune: "Arnad",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "A424",
        Abitanti: "1323",
      },
      {
        Istat: "7005",
        Comune: "Arvier",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11011",
        CodFisco: "A452",
        Abitanti: "900",
      },
      {
        Istat: "7006",
        Comune: "Avise",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "A521",
        Abitanti: "340",
      },
      {
        Istat: "7007",
        Comune: "Ayas",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "A094",
        Abitanti: "1359",
      },
      {
        Istat: "7008",
        Comune: "Aymavilles",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "A108",
        Abitanti: "2065",
      },
      {
        Istat: "7009",
        Comune: "Bard",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "A643",
        Abitanti: "132",
      },
      {
        Istat: "7010",
        Comune: "Bionaz",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "A877",
        Abitanti: "243",
      },
      {
        Istat: "7011",
        Comune: "Brissogne",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "B192",
        Abitanti: "962",
      },
      {
        Istat: "7012",
        Comune: "Brusson",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11022",
        CodFisco: "B230",
        Abitanti: "859",
      },
      {
        Istat: "7013",
        Comune: "Challand-Saint-Anselme",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "C593",
        Abitanti: "762",
      },
      {
        Istat: "7014",
        Comune: "Challand-Saint-Victor",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "C594",
        Abitanti: "605",
      },
      {
        Istat: "7015",
        Comune: "Chambave",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11023",
        CodFisco: "C595",
        Abitanti: "947",
      },
      {
        Istat: "7016",
        Comune: "Chamois",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11020",
        CodFisco: "B491",
        Abitanti: "100",
      },
      {
        Istat: "7017",
        Comune: "Champdepraz",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "C596",
        Abitanti: "710",
      },
      {
        Istat: "7018",
        Comune: "Champorcher",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "B540",
        Abitanti: "404",
      },
      {
        Istat: "7019",
        Comune: "Charvensod",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "C598",
        Abitanti: "2527",
      },
      {
        Istat: "7020",
        Comune: "Chatillon",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11024",
        CodFisco: "C294",
        Abitanti: "4966",
      },
      {
        Istat: "7021",
        Comune: "Cogne",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11012",
        CodFisco: "C821",
        Abitanti: "1483",
      },
      {
        Istat: "7022",
        Comune: "Courmayeur",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11013",
        CodFisco: "D012",
        Abitanti: "2877",
      },
      {
        Istat: "7023",
        Comune: "Donnas",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "D338",
        Abitanti: "2658",
      },
      {
        Istat: "7024",
        Comune: "Doues",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "D356",
        Abitanti: "474",
      },
      {
        Istat: "7025",
        Comune: "Emarèse",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11020",
        CodFisco: "D402",
        Abitanti: "223",
      },
      {
        Istat: "7026",
        Comune: "Etroubles",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11014",
        CodFisco: "D444",
        Abitanti: "491",
      },
      {
        Istat: "7027",
        Comune: "Fénis",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "D537",
        Abitanti: "1759",
      },
      {
        Istat: "7028",
        Comune: "Fontainemore",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "D666",
        Abitanti: "453",
      },
      {
        Istat: "7029",
        Comune: "Gaby",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "D839",
        Abitanti: "484",
      },
      {
        Istat: "7030",
        Comune: "Gignod",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "E029",
        Abitanti: "1612",
      },
      {
        Istat: "7031",
        Comune: "Gressan",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "E165",
        Abitanti: "3327",
      },
      {
        Istat: "7032",
        Comune: "Gressoney-la-Trinitè",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "E167",
        Abitanti: "309",
      },
      {
        Istat: "7033",
        Comune: "Gressoney-Saint-Jean",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11025",
        CodFisco: "E168",
        Abitanti: "821",
      },
      {
        Istat: "7034",
        Comune: "Hone",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "E273",
        Abitanti: "1183",
      },
      {
        Istat: "7035",
        Comune: "Introd",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "E306",
        Abitanti: "635",
      },
      {
        Istat: "7036",
        Comune: "Issime",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "E369",
        Abitanti: "426",
      },
      {
        Istat: "7037",
        Comune: "Issogne",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "E371",
        Abitanti: "1402",
      },
      {
        Istat: "7038",
        Comune: "Jovencan",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "E391",
        Abitanti: "765",
      },
      {
        Istat: "7039",
        Comune: "La Magdeleine",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11020",
        CodFisco: "A308",
        Abitanti: "116",
      },
      {
        Istat: "7040",
        Comune: "La Salle",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11015",
        CodFisco: "E458",
        Abitanti: "2102",
      },
      {
        Istat: "7041",
        Comune: "La Thuile",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11016",
        CodFisco: "E470",
        Abitanti: "767",
      },
      {
        Istat: "7042",
        Comune: "Lillianes",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "E587",
        Abitanti: "480",
      },
      {
        Istat: "7043",
        Comune: "Montjovet",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11020",
        CodFisco: "F367",
        Abitanti: "1849",
      },
      {
        Istat: "7044",
        Comune: "Morgex",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11017",
        CodFisco: "F726",
        Abitanti: "2069",
      },
      {
        Istat: "7045",
        Comune: "Nus",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "F987",
        Abitanti: "2965",
      },
      {
        Istat: "7046",
        Comune: "Ollomont",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "G045",
        Abitanti: "164",
      },
      {
        Istat: "7047",
        Comune: "Oyace",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "G012",
        Abitanti: "225",
      },
      {
        Istat: "7048",
        Comune: "Perloz",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "G459",
        Abitanti: "467",
      },
      {
        Istat: "7049",
        Comune: "Pollein",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "G794",
        Abitanti: "1528",
      },
      {
        Istat: "7050",
        Comune: "Pontboset",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11020",
        CodFisco: "G545",
        Abitanti: "197",
      },
      {
        Istat: "7051",
        Comune: "Pontey",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11024",
        CodFisco: "G860",
        Abitanti: "835",
      },
      {
        Istat: "7052",
        Comune: "Pont-Saint-Martin",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11026",
        CodFisco: "G854",
        Abitanti: "4029",
      },
      {
        Istat: "7053",
        Comune: "Prè-Saint-Didier",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "H042",
        Abitanti: "992",
      },
      {
        Istat: "7054",
        Comune: "Quart",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "H110",
        Abitanti: "3823",
      },
      {
        Istat: "7055",
        Comune: "Rhemes-Notre-Dame",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "H262",
        Abitanti: "116",
      },
      {
        Istat: "7056",
        Comune: "Rhemes-Saint-Georges",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "H263",
        Abitanti: "209",
      },
      {
        Istat: "7057",
        Comune: "Roisan",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "H497",
        Abitanti: "1037",
      },
      {
        Istat: "7058",
        Comune: "Saint-Christophe",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "H669",
        Abitanti: "3285",
      },
      {
        Istat: "7059",
        Comune: "Saint-Denis",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11023",
        CodFisco: "H670",
        Abitanti: "369",
      },
      {
        Istat: "7060",
        Comune: "Saint-Marcel",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11020",
        CodFisco: "H671",
        Abitanti: "1262",
      },
      {
        Istat: "7061",
        Comune: "Saint-Nicolas",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "H672",
        Abitanti: "325",
      },
      {
        Istat: "7062",
        Comune: "Saint-Oyen",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11014",
        CodFisco: "H673",
        Abitanti: "214",
      },
      {
        Istat: "7063",
        Comune: "Saint-Pierre",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "H674",
        Abitanti: "3162",
      },
      {
        Istat: "7064",
        Comune: "Saint-Rhémy-en-Bosses",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "H675",
        Abitanti: "377",
      },
      {
        Istat: "7065",
        Comune: "Saint-Vincent",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11027",
        CodFisco: "H676",
        Abitanti: "4787",
      },
      {
        Istat: "7066",
        Comune: "Sarre",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "I442",
        Abitanti: "4878",
      },
      {
        Istat: "7067",
        Comune: "Torgnon",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11020",
        CodFisco: "L217",
        Abitanti: "518",
      },
      {
        Istat: "7068",
        Comune: "Valgrisenche",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "L582",
        Abitanti: "195",
      },
      {
        Istat: "7069",
        Comune: "Valpelline",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "L643",
        Abitanti: "659",
      },
      {
        Istat: "7070",
        Comune: "Valsavarenche",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11010",
        CodFisco: "L647",
        Abitanti: "176",
      },
      {
        Istat: "7071",
        Comune: "Valtournenche",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11028",
        CodFisco: "L654",
        Abitanti: "2162",
      },
      {
        Istat: "7072",
        Comune: "Verrayes",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "166",
        CAP: "11020",
        CodFisco: "L783",
        Abitanti: "1351",
      },
      {
        Istat: "7073",
        Comune: "Verrès",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "125",
        CAP: "11029",
        CodFisco: "C282",
        Abitanti: "2758",
      },
      {
        Istat: "7074",
        Comune: "Villeneuve",
        Provincia: "AO",
        Regione: "VDA",
        Prefisso: "165",
        CAP: "11018",
        CodFisco: "L981",
        Abitanti: "1267",
      },
      {
        Istat: "28001",
        Comune: "Abano Terme",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35031",
        CodFisco: "A001",
        Abitanti: "19726",
      },
      {
        Istat: "29001",
        Comune: "Adria",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45011",
        CodFisco: "A059",
        Abitanti: "20441",
      },
      {
        Istat: "23001",
        Comune: "Affi",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "A061",
        Abitanti: "2362",
      },
      {
        Istat: "28002",
        Comune: "Agna",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35021",
        CodFisco: "A075",
        Abitanti: "3412",
      },
      {
        Istat: "25001",
        Comune: "Agordo",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32021",
        CodFisco: "A083",
        Abitanti: "4230",
      },
      {
        Istat: "24001",
        Comune: "Agugliaro",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36020",
        CodFisco: "A093",
        Abitanti: "1416",
      },
      {
        Istat: "25002",
        Comune: "Alano di Piave",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32031",
        CodFisco: "A121",
        Abitanti: "3048",
      },
      {
        Istat: "23002",
        Comune: "Albaredo d'Adige",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37041",
        CodFisco: "A137",
        Abitanti: "5313",
      },
      {
        Istat: "24002",
        Comune: "Albettone",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36020",
        CodFisco: "A154",
        Abitanti: "2104",
      },
      {
        Istat: "28003",
        Comune: "Albignasego",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "A161",
        Abitanti: "23284",
      },
      {
        Istat: "25003",
        Comune: "Alleghe",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32022",
        CodFisco: "A206",
        Abitanti: "1354",
      },
      {
        Istat: "24003",
        Comune: "Alonte",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36045",
        CodFisco: "A220",
        Abitanti: "1636",
      },
      {
        Istat: "24004",
        Comune: "Altavilla Vicentina",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36077",
        CodFisco: "A231",
        Abitanti: "11780",
      },
      {
        Istat: "24005",
        Comune: "Altissimo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36070",
        CodFisco: "A236",
        Abitanti: "2312",
      },
      {
        Istat: "26001",
        Comune: "Altivole",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31030",
        CodFisco: "A237",
        Abitanti: "6837",
      },
      {
        Istat: "23003",
        Comune: "Angiari",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37050",
        CodFisco: "A292",
        Abitanti: "2177",
      },
      {
        Istat: "28004",
        Comune: "Anguillara Veneta",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35022",
        CodFisco: "A296",
        Abitanti: "4624",
      },
      {
        Istat: "27001",
        Comune: "Annone Veneto",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "30020",
        CodFisco: "A302",
        Abitanti: "3995",
      },
      {
        Istat: "26002",
        Comune: "Arcade",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31030",
        CodFisco: "A360",
        Abitanti: "4407",
      },
      {
        Istat: "23004",
        Comune: "Arcole",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37040",
        CodFisco: "A374",
        Abitanti: "6253",
      },
      {
        Istat: "24006",
        Comune: "Arcugnano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36057",
        CodFisco: "A377",
        Abitanti: "7965",
      },
      {
        Istat: "29002",
        Comune: "Ariano nel Polesine",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45012",
        CodFisco: "A400",
        Abitanti: "4656",
      },
      {
        Istat: "28005",
        Comune: "Arquà Petrarca",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35032",
        CodFisco: "A434",
        Abitanti: "1860",
      },
      {
        Istat: "29003",
        Comune: "Arquà Polesine",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45031",
        CodFisco: "A435",
        Abitanti: "2850",
      },
      {
        Istat: "28006",
        Comune: "Arre",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "A438",
        Abitanti: "2179",
      },
      {
        Istat: "25004",
        Comune: "Arsiè",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32030",
        CodFisco: "A443",
        Abitanti: "2535",
      },
      {
        Istat: "24007",
        Comune: "Arsiero",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36011",
        CodFisco: "A444",
        Abitanti: "3323",
      },
      {
        Istat: "28007",
        Comune: "Arzergrande",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "A458",
        Abitanti: "4755",
      },
      {
        Istat: "24008",
        Comune: "Arzignano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36071",
        CodFisco: "A459",
        Abitanti: "26046",
      },
      {
        Istat: "24009",
        Comune: "Asiago",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36012",
        CodFisco: "A465",
        Abitanti: "6485",
      },
      {
        Istat: "24010",
        Comune: "Asigliano Veneto",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36020",
        CodFisco: "A467",
        Abitanti: "891",
      },
      {
        Istat: "26003",
        Comune: "Asolo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31011",
        CodFisco: "A471",
        Abitanti: "9311",
      },
      {
        Istat: "25005",
        Comune: "Auronzo di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32041",
        CodFisco: "A501",
        Abitanti: "3513",
      },
      {
        Istat: "23005",
        Comune: "Badia Calavena",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "A540",
        Abitanti: "2675",
      },
      {
        Istat: "29004",
        Comune: "Badia Polesine",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45021",
        CodFisco: "A539",
        Abitanti: "10865",
      },
      {
        Istat: "28008",
        Comune: "Bagnoli di Sopra",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35023",
        CodFisco: "A568",
        Abitanti: "3762",
      },
      {
        Istat: "29005",
        Comune: "Bagnolo di Po",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45022",
        CodFisco: "A574",
        Abitanti: "1434",
      },
      {
        Istat: "28009",
        Comune: "Baone",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35030",
        CodFisco: "A613",
        Abitanti: "3098",
      },
      {
        Istat: "24011",
        Comune: "Barbarano Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36021",
        CodFisco: "A627",
        Abitanti: "4602",
      },
      {
        Istat: "28010",
        Comune: "Barbona",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "35040",
        CodFisco: "A637",
        Abitanti: "739",
      },
      {
        Istat: "23006",
        Comune: "Bardolino",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37011",
        CodFisco: "A650",
        Abitanti: "6750",
      },
      {
        Istat: "24012",
        Comune: "Bassano del Grappa",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36061",
        CodFisco: "A703",
        Abitanti: "43540",
      },
      {
        Istat: "28011",
        Comune: "Battaglia Terme",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35041",
        CodFisco: "A714",
        Abitanti: "4031",
      },
      {
        Istat: "23007",
        Comune: "Belfiore",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37050",
        CodFisco: "A737",
        Abitanti: "3030",
      },
      {
        Istat: "25006",
        Comune: "Belluno",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32100",
        CodFisco: "A757",
        Abitanti: "36599",
      },
      {
        Istat: "29006",
        Comune: "Bergantino",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45032",
        CodFisco: "A795",
        Abitanti: "2617",
      },
      {
        Istat: "23008",
        Comune: "Bevilacqua",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "A837",
        Abitanti: "1865",
      },
      {
        Istat: "28012",
        Comune: "Boara Pisani",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "35040",
        CodFisco: "A906",
        Abitanti: "2617",
      },
      {
        Istat: "24013",
        Comune: "Bolzano Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "A954",
        Abitanti: "6503",
      },
      {
        Istat: "23009",
        Comune: "Bonavigo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "A964",
        Abitanti: "2010",
      },
      {
        Istat: "25007",
        Comune: "Borca di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "A982",
        Abitanti: "826",
      },
      {
        Istat: "28013",
        Comune: "Borgoricco",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "B031",
        Abitanti: "8478",
      },
      {
        Istat: "26004",
        Comune: "Borso del Grappa",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31030",
        CodFisco: "B061",
        Abitanti: "5863",
      },
      {
        Istat: "29007",
        Comune: "Bosaro",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45033",
        CodFisco: "B069",
        Abitanti: "1569",
      },
      {
        Istat: "23010",
        Comune: "Boschi Sant'Anna",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "B070",
        Abitanti: "1448",
      },
      {
        Istat: "23011",
        Comune: "Bosco Chiesanuova",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37021",
        CodFisco: "B073",
        Abitanti: "3663",
      },
      {
        Istat: "28014",
        Comune: "Bovolenta",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35024",
        CodFisco: "B106",
        Abitanti: "3384",
      },
      {
        Istat: "23012",
        Comune: "Bovolone",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37051",
        CodFisco: "B107",
        Abitanti: "15933",
      },
      {
        Istat: "26005",
        Comune: "Breda di Piave",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31030",
        CodFisco: "B128",
        Abitanti: "7852",
      },
      {
        Istat: "24014",
        Comune: "Breganze",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36042",
        CodFisco: "B132",
        Abitanti: "8747",
      },
      {
        Istat: "24015",
        Comune: "Brendola",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "B143",
        Abitanti: "6721",
      },
      {
        Istat: "23013",
        Comune: "Brentino Belluno",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37020",
        CodFisco: "B152",
        Abitanti: "1416",
      },
      {
        Istat: "23014",
        Comune: "Brenzone",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "B154",
        Abitanti: "2542",
      },
      {
        Istat: "24016",
        Comune: "Bressanvido",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "B161",
        Abitanti: "3133",
      },
      {
        Istat: "24017",
        Comune: "Brogliano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36070",
        CodFisco: "B196",
        Abitanti: "3846",
      },
      {
        Istat: "28015",
        Comune: "Brugine",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "B213",
        Abitanti: "6968",
      },
      {
        Istat: "23015",
        Comune: "Bussolengo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37012",
        CodFisco: "B296",
        Abitanti: "19690",
      },
      {
        Istat: "23016",
        Comune: "Buttapietra",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "B304",
        Abitanti: "6968",
      },
      {
        Istat: "28016",
        Comune: "Cadoneghe",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "B345",
        Abitanti: "16131",
      },
      {
        Istat: "26006",
        Comune: "Caerano di San Marco",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31031",
        CodFisco: "B349",
        Abitanti: "8050",
      },
      {
        Istat: "25008",
        Comune: "Calalzo di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32042",
        CodFisco: "B375",
        Abitanti: "2246",
      },
      {
        Istat: "23017",
        Comune: "Caldiero",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37042",
        CodFisco: "B402",
        Abitanti: "7537",
      },
      {
        Istat: "24018",
        Comune: "Caldogno",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36030",
        CodFisco: "B403",
        Abitanti: "11291",
      },
      {
        Istat: "29008",
        Comune: "Calto",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "B432",
        Abitanti: "827",
      },
      {
        Istat: "24019",
        Comune: "Caltrano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "B433",
        Abitanti: "2636",
      },
      {
        Istat: "24020",
        Comune: "Calvene",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "B441",
        Abitanti: "1335",
      },
      {
        Istat: "24021",
        Comune: "Camisano Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36043",
        CodFisco: "B485",
        Abitanti: "10564",
      },
      {
        Istat: "27002",
        Comune: "Campagna Lupia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30010",
        CodFisco: "B493",
        Abitanti: "6992",
      },
      {
        Istat: "24022",
        Comune: "Campiglia dei Berici",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36020",
        CodFisco: "B511",
        Abitanti: "1782",
      },
      {
        Istat: "28020",
        Comune: "Campo San Martino",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "B564",
        Abitanti: "5784",
      },
      {
        Istat: "28017",
        Comune: "Campodarsego",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35011",
        CodFisco: "B524",
        Abitanti: "14041",
      },
      {
        Istat: "28018",
        Comune: "Campodoro",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "B531",
        Abitanti: "2737",
      },
      {
        Istat: "27003",
        Comune: "Campolongo Maggiore",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "30010",
        CodFisco: "B546",
        Abitanti: "10300",
      },
      {
        Istat: "24023",
        Comune: "Campolongo sul Brenta",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36020",
        CodFisco: "B547",
        Abitanti: "829",
      },
      {
        Istat: "27004",
        Comune: "Camponogara",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30010",
        CodFisco: "B554",
        Abitanti: "12958",
      },
      {
        Istat: "28019",
        Comune: "Camposampiero",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35012",
        CodFisco: "B563",
        Abitanti: "12211",
      },
      {
        Istat: "25023",
        Comune: "Canale d'Agordo",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "B574",
        Abitanti: "1200",
      },
      {
        Istat: "29009",
        Comune: "Canaro",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45034",
        CodFisco: "B578",
        Abitanti: "2907",
      },
      {
        Istat: "29010",
        Comune: "Canda",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45020",
        CodFisco: "B582",
        Abitanti: "967",
      },
      {
        Istat: "28021",
        Comune: "Candiana",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "B589",
        Abitanti: "2505",
      },
      {
        Istat: "27005",
        Comune: "Caorle",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30021",
        CodFisco: "B642",
        Abitanti: "12032",
      },
      {
        Istat: "26007",
        Comune: "Cappella Maggiore",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31012",
        CodFisco: "B678",
        Abitanti: "4666",
      },
      {
        Istat: "23018",
        Comune: "Caprino Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37013",
        CodFisco: "B709",
        Abitanti: "8189",
      },
      {
        Istat: "26008",
        Comune: "Carbonera",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31030",
        CodFisco: "B744",
        Abitanti: "11187",
      },
      {
        Istat: "28022",
        Comune: "Carceri",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "B749",
        Abitanti: "1616",
      },
      {
        Istat: "28023",
        Comune: "Carmignano di Brenta",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "B795",
        Abitanti: "7612",
      },
      {
        Istat: "24024",
        Comune: "Carrè",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36010",
        CodFisco: "B835",
        Abitanti: "3665",
      },
      {
        Istat: "24025",
        Comune: "Cartigliano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36050",
        CodFisco: "B844",
        Abitanti: "3830",
      },
      {
        Istat: "28026",
        Comune: "Cartura",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35025",
        CodFisco: "B848",
        Abitanti: "4653",
      },
      {
        Istat: "28027",
        Comune: "Casale di Scodosia",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "B877",
        Abitanti: "4888",
      },
      {
        Istat: "26009",
        Comune: "Casale sul Sile",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31032",
        CodFisco: "B879",
        Abitanti: "12789",
      },
      {
        Istat: "23019",
        Comune: "Casaleone",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37052",
        CodFisco: "B886",
        Abitanti: "6041",
      },
      {
        Istat: "28028",
        Comune: "Casalserugo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "B912",
        Abitanti: "5514",
      },
      {
        Istat: "26010",
        Comune: "Casier",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31030",
        CodFisco: "B965",
        Abitanti: "11093",
      },
      {
        Istat: "24026",
        Comune: "Cassola",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36022",
        CodFisco: "C037",
        Abitanti: "14301",
      },
      {
        Istat: "23020",
        Comune: "Castagnaro",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37043",
        CodFisco: "C041",
        Abitanti: "4065",
      },
      {
        Istat: "24027",
        Comune: "Castegnero",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36020",
        CodFisco: "C056",
        Abitanti: "2843",
      },
      {
        Istat: "23021",
        Comune: "Castel d'Azzano",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "C078",
        Abitanti: "11795",
      },
      {
        Istat: "28029",
        Comune: "Castelbaldo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "35040",
        CodFisco: "C057",
        Abitanti: "1653",
      },
      {
        Istat: "26011",
        Comune: "Castelcucco",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31030",
        CodFisco: "C073",
        Abitanti: "2220",
      },
      {
        Istat: "26012",
        Comune: "Castelfranco Veneto",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31033",
        CodFisco: "C111",
        Abitanti: "33740",
      },
      {
        Istat: "24028",
        Comune: "Castelgomberto",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36070",
        CodFisco: "C119",
        Abitanti: "6185",
      },
      {
        Istat: "29011",
        Comune: "Castelguglielmo",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45020",
        CodFisco: "C122",
        Abitanti: "1698",
      },
      {
        Istat: "25009",
        Comune: "Castellavazzo",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "C146",
        Abitanti: "1635",
      },
      {
        Istat: "26013",
        Comune: "Castello di Godego",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31030",
        CodFisco: "C190",
        Abitanti: "7102",
      },
      {
        Istat: "29012",
        Comune: "Castelmassa",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45035",
        CodFisco: "C207",
        Abitanti: "4378",
      },
      {
        Istat: "29013",
        Comune: "Castelnovo Bariano",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "C215",
        Abitanti: "2968",
      },
      {
        Istat: "23022",
        Comune: "Castelnuovo del Garda",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37014",
        CodFisco: "C225",
        Abitanti: "12606",
      },
      {
        Istat: "23023",
        Comune: "Cavaion Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "C370",
        Abitanti: "5443",
      },
      {
        Istat: "27044",
        Comune: "Cavallino-Treporti",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30013",
        CodFisco: "M308",
        Abitanti: "13493",
      },
      {
        Istat: "27006",
        Comune: "Cavarzere",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "30014",
        CodFisco: "C383",
        Abitanti: "14974",
      },
      {
        Istat: "26014",
        Comune: "Cavaso del Tomba",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31034",
        CodFisco: "C384",
        Abitanti: "3011",
      },
      {
        Istat: "23024",
        Comune: "Cazzano di Tramigna",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "C412",
        Abitanti: "1549",
      },
      {
        Istat: "27007",
        Comune: "Ceggia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30022",
        CodFisco: "C422",
        Abitanti: "6266",
      },
      {
        Istat: "25010",
        Comune: "Cencenighe Agordino",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "C458",
        Abitanti: "1418",
      },
      {
        Istat: "29014",
        Comune: "Ceneselli",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "C461",
        Abitanti: "1875",
      },
      {
        Istat: "23025",
        Comune: "Cerea",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37053",
        CodFisco: "C498",
        Abitanti: "16360",
      },
      {
        Istat: "29015",
        Comune: "Ceregnano",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45010",
        CodFisco: "C500",
        Abitanti: "3806",
      },
      {
        Istat: "23026",
        Comune: "Cerro Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37020",
        CodFisco: "C538",
        Abitanti: "2478",
      },
      {
        Istat: "28030",
        Comune: "Cervarese Santa Croce",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "C544",
        Abitanti: "5749",
      },
      {
        Istat: "25011",
        Comune: "Cesiomaggiore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32030",
        CodFisco: "C577",
        Abitanti: "4187",
      },
      {
        Istat: "26015",
        Comune: "Cessalto",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "31040",
        CodFisco: "C580",
        Abitanti: "3855",
      },
      {
        Istat: "24029",
        Comune: "Chiampo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36072",
        CodFisco: "C605",
        Abitanti: "12946",
      },
      {
        Istat: "26016",
        Comune: "Chiarano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "C614",
        Abitanti: "3728",
      },
      {
        Istat: "25012",
        Comune: "Chies d'Alpago",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "C630",
        Abitanti: "1440",
      },
      {
        Istat: "27008",
        Comune: "Chioggia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30015",
        CodFisco: "C638",
        Abitanti: "50674",
      },
      {
        Istat: "24030",
        Comune: "Chiuppano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36010",
        CodFisco: "C650",
        Abitanti: "2635",
      },
      {
        Istat: "25013",
        Comune: "Cibiana di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "C672",
        Abitanti: "447",
      },
      {
        Istat: "26017",
        Comune: "Cimadolmo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31010",
        CodFisco: "C689",
        Abitanti: "3488",
      },
      {
        Istat: "27009",
        Comune: "Cinto Caomaggiore",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "C714",
        Abitanti: "3327",
      },
      {
        Istat: "28031",
        Comune: "Cinto Euganeo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35030",
        CodFisco: "C713",
        Abitanti: "2067",
      },
      {
        Istat: "24031",
        Comune: "Cismon del Grappa",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36020",
        CodFisco: "C734",
        Abitanti: "976",
      },
      {
        Istat: "26018",
        Comune: "Cison di Valmarino",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31030",
        CodFisco: "C735",
        Abitanti: "2730",
      },
      {
        Istat: "28032",
        Comune: "Cittadella",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35013",
        CodFisco: "C743",
        Abitanti: "19970",
      },
      {
        Istat: "28033",
        Comune: "Codevigo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "C812",
        Abitanti: "6403",
      },
      {
        Istat: "26019",
        Comune: "Codognè",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31013",
        CodFisco: "C815",
        Abitanti: "5386",
      },
      {
        Istat: "24032",
        Comune: "Cogollo del Cengio",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36010",
        CodFisco: "C824",
        Abitanti: "3434",
      },
      {
        Istat: "25014",
        Comune: "Colle Santa Lucia",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "C872",
        Abitanti: "395",
      },
      {
        Istat: "26020",
        Comune: "Colle Umberto",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31014",
        CodFisco: "C848",
        Abitanti: "5180",
      },
      {
        Istat: "23027",
        Comune: "Cologna Veneta",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37044",
        CodFisco: "C890",
        Abitanti: "8665",
      },
      {
        Istat: "23028",
        Comune: "Colognola ai Colli",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "C897",
        Abitanti: "8403",
      },
      {
        Istat: "25015",
        Comune: "Comelico Superiore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "C920",
        Abitanti: "2366",
      },
      {
        Istat: "27010",
        Comune: "Cona",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "30010",
        CodFisco: "C938",
        Abitanti: "3199",
      },
      {
        Istat: "23029",
        Comune: "Concamarise",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37050",
        CodFisco: "C943",
        Abitanti: "1066",
      },
      {
        Istat: "24033",
        Comune: "Conco",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36062",
        CodFisco: "C949",
        Abitanti: "2200",
      },
      {
        Istat: "27011",
        Comune: "Concordia Sagittaria",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30023",
        CodFisco: "C950",
        Abitanti: "10716",
      },
      {
        Istat: "26021",
        Comune: "Conegliano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31015",
        CodFisco: "C957",
        Abitanti: "35748",
      },
      {
        Istat: "28034",
        Comune: "Conselve",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35026",
        CodFisco: "C964",
        Abitanti: "10486",
      },
      {
        Istat: "29017",
        Comune: "Corbola",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45015",
        CodFisco: "C987",
        Abitanti: "2538",
      },
      {
        Istat: "26022",
        Comune: "Cordignano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31016",
        CodFisco: "C992",
        Abitanti: "7141",
      },
      {
        Istat: "24034",
        Comune: "Cornedo Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36073",
        CodFisco: "D020",
        Abitanti: "12042",
      },
      {
        Istat: "26023",
        Comune: "Cornuda",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31041",
        CodFisco: "D030",
        Abitanti: "6312",
      },
      {
        Istat: "28035",
        Comune: "Correzzola",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "D040",
        Abitanti: "5574",
      },
      {
        Istat: "25016",
        Comune: "Cortina d'Ampezzo",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "436",
        CAP: "32043",
        CodFisco: "A266",
        Abitanti: "6097",
      },
      {
        Istat: "29018",
        Comune: "Costa di Rovigo",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45023",
        CodFisco: "D105",
        Abitanti: "2739",
      },
      {
        Istat: "24035",
        Comune: "Costabissara",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36030",
        CodFisco: "D107",
        Abitanti: "7131",
      },
      {
        Istat: "23030",
        Comune: "Costermano",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "D118",
        Abitanti: "3614",
      },
      {
        Istat: "24036",
        Comune: "Creazzo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36051",
        CodFisco: "D136",
        Abitanti: "11231",
      },
      {
        Istat: "24037",
        Comune: "Crespadoro",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36070",
        CodFisco: "D156",
        Abitanti: "1512",
      },
      {
        Istat: "26024",
        Comune: "Crespano del Grappa",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31017",
        CodFisco: "D157",
        Abitanti: "4729",
      },
      {
        Istat: "29019",
        Comune: "Crespino",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "D161",
        Abitanti: "2030",
      },
      {
        Istat: "26025",
        Comune: "Crocetta del Montello",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31035",
        CodFisco: "C670",
        Abitanti: "6090",
      },
      {
        Istat: "28036",
        Comune: "Curtarolo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "D226",
        Abitanti: "7227",
      },
      {
        Istat: "25017",
        Comune: "Danta di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "D247",
        Abitanti: "514",
      },
      {
        Istat: "23031",
        Comune: "Dolcè",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37020",
        CodFisco: "D317",
        Abitanti: "2621",
      },
      {
        Istat: "27012",
        Comune: "Dolo",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30031",
        CodFisco: "D325",
        Abitanti: "15188",
      },
      {
        Istat: "25018",
        Comune: "Domegge di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "D330",
        Abitanti: "2600",
      },
      {
        Istat: "28106",
        Comune: "Due Carrare",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "M300",
        Abitanti: "8965",
      },
      {
        Istat: "24038",
        Comune: "Dueville",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36031",
        CodFisco: "D379",
        Abitanti: "14065",
      },
      {
        Istat: "24039",
        Comune: "Enego",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36052",
        CodFisco: "D407",
        Abitanti: "1872",
      },
      {
        Istat: "27013",
        Comune: "Eraclea",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "D415",
        Abitanti: "12799",
      },
      {
        Istat: "23032",
        Comune: "Erbè",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "D419",
        Abitanti: "1812",
      },
      {
        Istat: "23033",
        Comune: "Erbezzo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37020",
        CodFisco: "D420",
        Abitanti: "787",
      },
      {
        Istat: "28037",
        Comune: "Este",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35042",
        CodFisco: "D442",
        Abitanti: "16806",
      },
      {
        Istat: "25019",
        Comune: "Falcade",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "D470",
        Abitanti: "2065",
      },
      {
        Istat: "24040",
        Comune: "Fara Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "D496",
        Abitanti: "3998",
      },
      {
        Istat: "25020",
        Comune: "Farra d'Alpago",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32016",
        CodFisco: "D506",
        Abitanti: "2811",
      },
      {
        Istat: "26026",
        Comune: "Farra di Soligo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31010",
        CodFisco: "D505",
        Abitanti: "8955",
      },
      {
        Istat: "25021",
        Comune: "Feltre",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32032",
        CodFisco: "D530",
        Abitanti: "20924",
      },
      {
        Istat: "23034",
        Comune: "Ferrara di Monte Baldo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37020",
        CodFisco: "D549",
        Abitanti: "226",
      },
      {
        Istat: "29021",
        Comune: "Ficarolo",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45036",
        CodFisco: "D568",
        Abitanti: "2627",
      },
      {
        Istat: "27014",
        Comune: "Fiesso d'Artico",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30032",
        CodFisco: "D578",
        Abitanti: "7786",
      },
      {
        Istat: "29022",
        Comune: "Fiesso Umbertiano",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45024",
        CodFisco: "D577",
        Abitanti: "4343",
      },
      {
        Istat: "26027",
        Comune: "Follina",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31051",
        CodFisco: "D654",
        Abitanti: "3997",
      },
      {
        Istat: "26028",
        Comune: "Fontanelle",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31043",
        CodFisco: "D674",
        Abitanti: "5778",
      },
      {
        Istat: "28038",
        Comune: "Fontaniva",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35014",
        CodFisco: "D679",
        Abitanti: "8249",
      },
      {
        Istat: "26029",
        Comune: "Fonte",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31010",
        CodFisco: "D680",
        Abitanti: "6156",
      },
      {
        Istat: "25022",
        Comune: "Fonzaso",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32030",
        CodFisco: "D686",
        Abitanti: "3329",
      },
      {
        Istat: "25024",
        Comune: "Forno di Zoldo",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32012",
        CodFisco: "D726",
        Abitanti: "2560",
      },
      {
        Istat: "27015",
        Comune: "Fossalta di Piave",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "D740",
        Abitanti: "4222",
      },
      {
        Istat: "27016",
        Comune: "Fossalta di Portogruaro",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30025",
        CodFisco: "D741",
        Abitanti: "6080",
      },
      {
        Istat: "27017",
        Comune: "Fossò",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30030",
        CodFisco: "D748",
        Abitanti: "6814",
      },
      {
        Istat: "24041",
        Comune: "Foza",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36010",
        CodFisco: "D750",
        Abitanti: "726",
      },
      {
        Istat: "29023",
        Comune: "Frassinelle Polesine",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "D776",
        Abitanti: "1531",
      },
      {
        Istat: "29024",
        Comune: "Fratta Polesine",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45025",
        CodFisco: "D788",
        Abitanti: "2780",
      },
      {
        Istat: "26030",
        Comune: "Fregona",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31010",
        CodFisco: "D794",
        Abitanti: "3203",
      },
      {
        Istat: "23035",
        Comune: "Fumane",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37022",
        CodFisco: "D818",
        Abitanti: "4148",
      },
      {
        Istat: "26031",
        Comune: "Gaiarine",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "434",
        CAP: "31018",
        CodFisco: "D854",
        Abitanti: "6163",
      },
      {
        Istat: "29025",
        Comune: "Gaiba",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "D855",
        Abitanti: "1123",
      },
      {
        Istat: "28039",
        Comune: "Galliera Veneta",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35015",
        CodFisco: "D879",
        Abitanti: "7141",
      },
      {
        Istat: "24042",
        Comune: "Gallio",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36032",
        CodFisco: "D882",
        Abitanti: "2483",
      },
      {
        Istat: "28040",
        Comune: "Galzignano Terme",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "D889",
        Abitanti: "4436",
      },
      {
        Istat: "24043",
        Comune: "Gambellara",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36053",
        CodFisco: "D897",
        Abitanti: "3400",
      },
      {
        Istat: "24044",
        Comune: "Gambugliano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "D902",
        Abitanti: "842",
      },
      {
        Istat: "23036",
        Comune: "Garda",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37016",
        CodFisco: "D915",
        Abitanti: "4016",
      },
      {
        Istat: "29026",
        Comune: "Gavello",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45010",
        CodFisco: "D942",
        Abitanti: "1683",
      },
      {
        Istat: "28041",
        Comune: "Gazzo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "D956",
        Abitanti: "4282",
      },
      {
        Istat: "23037",
        Comune: "Gazzo Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37060",
        CodFisco: "D957",
        Abitanti: "5558",
      },
      {
        Istat: "29027",
        Comune: "Giacciano con Baruchella",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45020",
        CodFisco: "E008",
        Abitanti: "2246",
      },
      {
        Istat: "26032",
        Comune: "Giavera del Montello",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "E021",
        Abitanti: "5211",
      },
      {
        Istat: "26033",
        Comune: "Godega di Sant'Urbano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31010",
        CodFisco: "E071",
        Abitanti: "6173",
      },
      {
        Istat: "26034",
        Comune: "Gorgo al Monticano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "E092",
        Abitanti: "4203",
      },
      {
        Istat: "25025",
        Comune: "Gosaldo",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "E113",
        Abitanti: "738",
      },
      {
        Istat: "24045",
        Comune: "Grancona",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "E138",
        Abitanti: "1883",
      },
      {
        Istat: "28042",
        Comune: "Grantorto",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "E145",
        Abitanti: "4704",
      },
      {
        Istat: "28043",
        Comune: "Granze",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "E146",
        Abitanti: "2024",
      },
      {
        Istat: "23038",
        Comune: "Grezzana",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37023",
        CodFisco: "E171",
        Abitanti: "10957",
      },
      {
        Istat: "24046",
        Comune: "Grisignano di Zocco",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "E184",
        Abitanti: "4330",
      },
      {
        Istat: "27018",
        Comune: "Gruaro",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "E215",
        Abitanti: "2831",
      },
      {
        Istat: "24047",
        Comune: "Grumolo delle Abbadesse",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "E226",
        Abitanti: "3798",
      },
      {
        Istat: "29028",
        Comune: "Guarda Veneta",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "E240",
        Abitanti: "1213",
      },
      {
        Istat: "23039",
        Comune: "Illasi",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37031",
        CodFisco: "E284",
        Abitanti: "5392",
      },
      {
        Istat: "23040",
        Comune: "Isola della Scala",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37063",
        CodFisco: "E349",
        Abitanti: "11559",
      },
      {
        Istat: "23041",
        Comune: "Isola Rizza",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37050",
        CodFisco: "E358",
        Abitanti: "3281",
      },
      {
        Istat: "24048",
        Comune: "Isola Vicentina",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36033",
        CodFisco: "E354",
        Abitanti: "9538",
      },
      {
        Istat: "26035",
        Comune: "Istrana",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31036",
        CodFisco: "E373",
        Abitanti: "9170",
      },
      {
        Istat: "27019",
        Comune: "Jesolo",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30016",
        CodFisco: "C388",
        Abitanti: "25601",
      },
      {
        Istat: "25027",
        Comune: "La Valle Agordina",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "E490",
        Abitanti: "1162",
      },
      {
        Istat: "24049",
        Comune: "Laghi",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36010",
        CodFisco: "E403",
        Abitanti: "127",
      },
      {
        Istat: "25026",
        Comune: "Lamon",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32033",
        CodFisco: "E429",
        Abitanti: "3124",
      },
      {
        Istat: "24050",
        Comune: "Lastebasse",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36040",
        CodFisco: "E465",
        Abitanti: "231",
      },
      {
        Istat: "23042",
        Comune: "Lavagno",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "E489",
        Abitanti: "8103",
      },
      {
        Istat: "23043",
        Comune: "Lazise",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37017",
        CodFisco: "E502",
        Abitanti: "6968",
      },
      {
        Istat: "23044",
        Comune: "Legnago",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37045",
        CodFisco: "E512",
        Abitanti: "25600",
      },
      {
        Istat: "28044",
        Comune: "Legnaro",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "E515",
        Abitanti: "8594",
      },
      {
        Istat: "29029",
        Comune: "Lendinara",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45026",
        CodFisco: "E522",
        Abitanti: "12181",
      },
      {
        Istat: "25028",
        Comune: "Lentiai",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "C562",
        Abitanti: "2997",
      },
      {
        Istat: "25029",
        Comune: "Limana",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "E588",
        Abitanti: "5027",
      },
      {
        Istat: "28045",
        Comune: "Limena",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "E592",
        Abitanti: "7740",
      },
      {
        Istat: "25030",
        Comune: "Livinallongo del Col di Lana",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "436",
        CAP: "32020",
        CodFisco: "E622",
        Abitanti: "1405",
      },
      {
        Istat: "24051",
        Comune: "Longare",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36023",
        CodFisco: "E671",
        Abitanti: "5646",
      },
      {
        Istat: "25031",
        Comune: "Longarone",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32013",
        CodFisco: "E672",
        Abitanti: "4021",
      },
      {
        Istat: "24052",
        Comune: "Lonigo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36045",
        CodFisco: "E682",
        Abitanti: "16322",
      },
      {
        Istat: "28046",
        Comune: "Loreggia",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "E684",
        Abitanti: "7259",
      },
      {
        Istat: "25032",
        Comune: "Lorenzago di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "E687",
        Abitanti: "579",
      },
      {
        Istat: "29030",
        Comune: "Loreo",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45017",
        CodFisco: "E689",
        Abitanti: "3735",
      },
      {
        Istat: "26036",
        Comune: "Loria",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31037",
        CodFisco: "E692",
        Abitanti: "9116",
      },
      {
        Istat: "28047",
        Comune: "Lozzo Atestino",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35034",
        CodFisco: "E709",
        Abitanti: "3233",
      },
      {
        Istat: "25033",
        Comune: "Lozzo di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "E708",
        Abitanti: "1528",
      },
      {
        Istat: "24053",
        Comune: "Lugo di Vicenza",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "E731",
        Abitanti: "3769",
      },
      {
        Istat: "29031",
        Comune: "Lusia",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45020",
        CodFisco: "E761",
        Abitanti: "3629",
      },
      {
        Istat: "24054",
        Comune: "Lusiana",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36046",
        CodFisco: "E762",
        Abitanti: "2818",
      },
      {
        Istat: "23045",
        Comune: "Malcesine",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37018",
        CodFisco: "E848",
        Abitanti: "3748",
      },
      {
        Istat: "24055",
        Comune: "Malo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36034",
        CodFisco: "E864",
        Abitanti: "14583",
      },
      {
        Istat: "26037",
        Comune: "Mansuè",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "E893",
        Abitanti: "4989",
      },
      {
        Istat: "23046",
        Comune: "Marano di Valpolicella",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37020",
        CodFisco: "E911",
        Abitanti: "3121",
      },
      {
        Istat: "24056",
        Comune: "Marano Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36035",
        CodFisco: "E912",
        Abitanti: "9835",
      },
      {
        Istat: "27020",
        Comune: "Marcon",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30020",
        CodFisco: "E936",
        Abitanti: "15938",
      },
      {
        Istat: "26038",
        Comune: "Mareno di Piave",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31010",
        CodFisco: "E940",
        Abitanti: "9640",
      },
      {
        Istat: "24057",
        Comune: "Marostica",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36063",
        CodFisco: "E970",
        Abitanti: "13824",
      },
      {
        Istat: "27021",
        Comune: "Martellago",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30030",
        CodFisco: "E980",
        Abitanti: "21279",
      },
      {
        Istat: "26039",
        Comune: "Maser",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31010",
        CodFisco: "F009",
        Abitanti: "4980",
      },
      {
        Istat: "28048",
        Comune: "Maserà di Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "F011",
        Abitanti: "9071",
      },
      {
        Istat: "26040",
        Comune: "Maserada sul Piave",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31052",
        CodFisco: "F012",
        Abitanti: "9394",
      },
      {
        Istat: "28049",
        Comune: "Masi",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "35040",
        CodFisco: "F013",
        Abitanti: "1796",
      },
      {
        Istat: "24058",
        Comune: "Mason Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36064",
        CodFisco: "F019",
        Abitanti: "3537",
      },
      {
        Istat: "28050",
        Comune: "Massanzago",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "F033",
        Abitanti: "5885",
      },
      {
        Istat: "26041",
        Comune: "Meduna di Livenza",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "F088",
        Abitanti: "2969",
      },
      {
        Istat: "28051",
        Comune: "Megliadino San Fidenzio",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "F091",
        Abitanti: "1993",
      },
      {
        Istat: "28052",
        Comune: "Megliadino San Vitale",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "F092",
        Abitanti: "2051",
      },
      {
        Istat: "25034",
        Comune: "Mel",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32026",
        CodFisco: "F094",
        Abitanti: "6304",
      },
      {
        Istat: "29032",
        Comune: "Melara",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45037",
        CodFisco: "F095",
        Abitanti: "1914",
      },
      {
        Istat: "27022",
        Comune: "Meolo",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "F130",
        Abitanti: "6566",
      },
      {
        Istat: "28053",
        Comune: "Merlara",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "F148",
        Abitanti: "2856",
      },
      {
        Istat: "28054",
        Comune: "Mestrino",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35035",
        CodFisco: "F161",
        Abitanti: "10986",
      },
      {
        Istat: "23047",
        Comune: "Mezzane di Sotto",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "F172",
        Abitanti: "2419",
      },
      {
        Istat: "26042",
        Comune: "Miane",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31050",
        CodFisco: "F190",
        Abitanti: "3545",
      },
      {
        Istat: "23048",
        Comune: "Minerbe",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37046",
        CodFisco: "F218",
        Abitanti: "4759",
      },
      {
        Istat: "27023",
        Comune: "Mira",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30034",
        CodFisco: "F229",
        Abitanti: "38952",
      },
      {
        Istat: "27024",
        Comune: "Mirano",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30035",
        CodFisco: "F241",
        Abitanti: "27077",
      },
      {
        Istat: "26043",
        Comune: "Mogliano Veneto",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "31021",
        CodFisco: "F269",
        Abitanti: "28115",
      },
      {
        Istat: "24059",
        Comune: "Molvena",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36060",
        CodFisco: "F306",
        Abitanti: "2639",
      },
      {
        Istat: "26044",
        Comune: "Monastier di Treviso",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31050",
        CodFisco: "F332",
        Abitanti: "4105",
      },
      {
        Istat: "26045",
        Comune: "Monfumo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31010",
        CodFisco: "F360",
        Abitanti: "1454",
      },
      {
        Istat: "28055",
        Comune: "Monselice",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35043",
        CodFisco: "F382",
        Abitanti: "17616",
      },
      {
        Istat: "28056",
        Comune: "Montagnana",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35044",
        CodFisco: "F394",
        Abitanti: "9505",
      },
      {
        Istat: "24063",
        Comune: "Monte di Malo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "F486",
        Abitanti: "2912",
      },
      {
        Istat: "24060",
        Comune: "Montebello Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36054",
        CodFisco: "F442",
        Abitanti: "6550",
      },
      {
        Istat: "26046",
        Comune: "Montebelluna",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31044",
        CodFisco: "F443",
        Abitanti: "31181",
      },
      {
        Istat: "23049",
        Comune: "Montecchia di Crosara",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "F461",
        Abitanti: "4585",
      },
      {
        Istat: "24061",
        Comune: "Montecchio Maggiore",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36075",
        CodFisco: "F464",
        Abitanti: "23743",
      },
      {
        Istat: "24062",
        Comune: "Montecchio Precalcino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "F465",
        Abitanti: "4966",
      },
      {
        Istat: "23050",
        Comune: "Monteforte d'Alpone",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37032",
        CodFisco: "F508",
        Abitanti: "8518",
      },
      {
        Istat: "24064",
        Comune: "Montegalda",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36047",
        CodFisco: "F514",
        Abitanti: "3356",
      },
      {
        Istat: "24065",
        Comune: "Montegaldella",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36047",
        CodFisco: "F515",
        Abitanti: "1813",
      },
      {
        Istat: "28057",
        Comune: "Montegrotto Terme",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35036",
        CodFisco: "F529",
        Abitanti: "11181",
      },
      {
        Istat: "24066",
        Comune: "Monteviale",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "F662",
        Abitanti: "2569",
      },
      {
        Istat: "24067",
        Comune: "Monticello Conte Otto",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36010",
        CodFisco: "F675",
        Abitanti: "9280",
      },
      {
        Istat: "24068",
        Comune: "Montorso Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "F696",
        Abitanti: "3186",
      },
      {
        Istat: "26047",
        Comune: "Morgano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31050",
        CodFisco: "F725",
        Abitanti: "4343",
      },
      {
        Istat: "26048",
        Comune: "Moriago della Battaglia",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31010",
        CodFisco: "F729",
        Abitanti: "2782",
      },
      {
        Istat: "24069",
        Comune: "Mossano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36024",
        CodFisco: "F768",
        Abitanti: "1791",
      },
      {
        Istat: "26049",
        Comune: "Motta di Livenza",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31045",
        CodFisco: "F770",
        Abitanti: "10738",
      },
      {
        Istat: "23051",
        Comune: "Mozzecane",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "F789",
        Abitanti: "7019",
      },
      {
        Istat: "27025",
        Comune: "Musile di Piave",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30024",
        CodFisco: "F826",
        Abitanti: "11578",
      },
      {
        Istat: "24070",
        Comune: "Mussolente",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36065",
        CodFisco: "F829",
        Abitanti: "7760",
      },
      {
        Istat: "24071",
        Comune: "Nanto",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36024",
        CodFisco: "F838",
        Abitanti: "3059",
      },
      {
        Istat: "23052",
        Comune: "Negrar",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37024",
        CodFisco: "F861",
        Abitanti: "17232",
      },
      {
        Istat: "26050",
        Comune: "Nervesa della Battaglia",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "F872",
        Abitanti: "6948",
      },
      {
        Istat: "27026",
        Comune: "Noale",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30033",
        CodFisco: "F904",
        Abitanti: "15855",
      },
      {
        Istat: "23053",
        Comune: "Nogara",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37054",
        CodFisco: "F918",
        Abitanti: "8719",
      },
      {
        Istat: "23054",
        Comune: "Nogarole Rocca",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "F921",
        Abitanti: "3448",
      },
      {
        Istat: "24072",
        Comune: "Nogarole Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36070",
        CodFisco: "F922",
        Abitanti: "1142",
      },
      {
        Istat: "24073",
        Comune: "Nove",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36055",
        CodFisco: "F957",
        Abitanti: "5090",
      },
      {
        Istat: "27027",
        Comune: "Noventa di Piave",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "F963",
        Abitanti: "6849",
      },
      {
        Istat: "28058",
        Comune: "Noventa Padovana",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35027",
        CodFisco: "F962",
        Abitanti: "10922",
      },
      {
        Istat: "24074",
        Comune: "Noventa Vicentina",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36025",
        CodFisco: "F964",
        Abitanti: "8923",
      },
      {
        Istat: "29033",
        Comune: "Occhiobello",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "F994",
        Abitanti: "11569",
      },
      {
        Istat: "26051",
        Comune: "Oderzo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31046",
        CodFisco: "F999",
        Abitanti: "20272",
      },
      {
        Istat: "23055",
        Comune: "Oppeano",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37050",
        CodFisco: "G080",
        Abitanti: "9446",
      },
      {
        Istat: "24075",
        Comune: "Orgiano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "G095",
        Abitanti: "3184",
      },
      {
        Istat: "26052",
        Comune: "Ormelle",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31024",
        CodFisco: "G115",
        Abitanti: "4482",
      },
      {
        Istat: "26053",
        Comune: "Orsago",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31010",
        CodFisco: "G123",
        Abitanti: "3876",
      },
      {
        Istat: "28059",
        Comune: "Ospedaletto Euganeo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35045",
        CodFisco: "G167",
        Abitanti: "5943",
      },
      {
        Istat: "25035",
        Comune: "Ospitale di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "G169",
        Abitanti: "336",
      },
      {
        Istat: "26054",
        Comune: "Paderno del Grappa",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31017",
        CodFisco: "G221",
        Abitanti: "2195",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35121",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35122",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35123",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35124",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35125",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35126",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35127",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35128",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35129",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35130",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35131",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35132",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35133",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35134",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35135",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35136",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35137",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35138",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35139",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35140",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35141",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35142",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "28060",
        Comune: "Padova",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35143",
        CodFisco: "G224",
        Abitanti: "214198",
      },
      {
        Istat: "26055",
        Comune: "Paese",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31038",
        CodFisco: "G229",
        Abitanti: "21947",
      },
      {
        Istat: "23056",
        Comune: "Palù",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37050",
        CodFisco: "G297",
        Abitanti: "1285",
      },
      {
        Istat: "29034",
        Comune: "Papozze",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45010",
        CodFisco: "G323",
        Abitanti: "1676",
      },
      {
        Istat: "23057",
        Comune: "Pastrengo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "G365",
        Abitanti: "2883",
      },
      {
        Istat: "25036",
        Comune: "Pedavena",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32034",
        CodFisco: "G404",
        Abitanti: "4435",
      },
      {
        Istat: "24076",
        Comune: "Pedemonte",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36040",
        CodFisco: "G406",
        Abitanti: "769",
      },
      {
        Istat: "26056",
        Comune: "Pederobba",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31040",
        CodFisco: "G408",
        Abitanti: "7602",
      },
      {
        Istat: "25037",
        Comune: "Perarolo di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32010",
        CodFisco: "G442",
        Abitanti: "388",
      },
      {
        Istat: "28061",
        Comune: "Pernumia",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35020",
        CodFisco: "G461",
        Abitanti: "3968",
      },
      {
        Istat: "23058",
        Comune: "Pescantina",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37026",
        CodFisco: "G481",
        Abitanti: "16390",
      },
      {
        Istat: "23059",
        Comune: "Peschiera del Garda",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37019",
        CodFisco: "G489",
        Abitanti: "9984",
      },
      {
        Istat: "29035",
        Comune: "Pettorazza Grimani",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45010",
        CodFisco: "G525",
        Abitanti: "1699",
      },
      {
        Istat: "28062",
        Comune: "Piacenza d'Adige",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "35040",
        CodFisco: "G534",
        Abitanti: "1407",
      },
      {
        Istat: "24077",
        Comune: "Pianezze",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36060",
        CodFisco: "G560",
        Abitanti: "2080",
      },
      {
        Istat: "27028",
        Comune: "Pianiga",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30030",
        CodFisco: "G565",
        Abitanti: "11927",
      },
      {
        Istat: "28063",
        Comune: "Piazzola sul Brenta",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35016",
        CodFisco: "G587",
        Abitanti: "11207",
      },
      {
        Istat: "25038",
        Comune: "Pieve d'Alpago",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "G638",
        Abitanti: "1969",
      },
      {
        Istat: "25039",
        Comune: "Pieve di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32044",
        CodFisco: "G642",
        Abitanti: "4017",
      },
      {
        Istat: "26057",
        Comune: "Pieve di Soligo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31053",
        CodFisco: "G645",
        Abitanti: "12159",
      },
      {
        Istat: "29036",
        Comune: "Pincara",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45020",
        CodFisco: "G673",
        Abitanti: "1281",
      },
      {
        Istat: "28064",
        Comune: "Piombino Dese",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35017",
        CodFisco: "G688",
        Abitanti: "9443",
      },
      {
        Istat: "28065",
        Comune: "Piove di Sacco",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35028",
        CodFisco: "G693",
        Abitanti: "19413",
      },
      {
        Istat: "24078",
        Comune: "Piovene Rocchette",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36013",
        CodFisco: "G694",
        Abitanti: "8361",
      },
      {
        Istat: "24079",
        Comune: "Pojana Maggiore",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36026",
        CodFisco: "G776",
        Abitanti: "4517",
      },
      {
        Istat: "29037",
        Comune: "Polesella",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45038",
        CodFisco: "G782",
        Abitanti: "4201",
      },
      {
        Istat: "28066",
        Comune: "Polverara",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "G802",
        Abitanti: "3048",
      },
      {
        Istat: "28067",
        Comune: "Ponso",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "G823",
        Abitanti: "2445",
      },
      {
        Istat: "26058",
        Comune: "Ponte di Piave",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31047",
        CodFisco: "G846",
        Abitanti: "8309",
      },
      {
        Istat: "25040",
        Comune: "Ponte nelle Alpi",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32014",
        CodFisco: "B662",
        Abitanti: "8533",
      },
      {
        Istat: "28069",
        Comune: "Ponte San Nicolò",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "G855",
        Abitanti: "13325",
      },
      {
        Istat: "29038",
        Comune: "Pontecchio Polesine",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "G836",
        Abitanti: "2047",
      },
      {
        Istat: "28068",
        Comune: "Pontelongo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35029",
        CodFisco: "G850",
        Abitanti: "3938",
      },
      {
        Istat: "26059",
        Comune: "Ponzano Veneto",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31050",
        CodFisco: "G875",
        Abitanti: "12321",
      },
      {
        Istat: "29039",
        Comune: "Porto Tolle",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45018",
        CodFisco: "G923",
        Abitanti: "10131",
      },
      {
        Istat: "29052",
        Comune: "Porto Viro",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45014",
        CodFisco: "G926",
        Abitanti: "14761",
      },
      {
        Istat: "26060",
        Comune: "Portobuffolè",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "G909",
        Abitanti: "804",
      },
      {
        Istat: "27029",
        Comune: "Portogruaro",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30026",
        CodFisco: "G914",
        Abitanti: "25440",
      },
      {
        Istat: "24080",
        Comune: "Posina",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36010",
        CodFisco: "G931",
        Abitanti: "594",
      },
      {
        Istat: "26061",
        Comune: "Possagno",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31054",
        CodFisco: "G933",
        Abitanti: "2234",
      },
      {
        Istat: "24081",
        Comune: "Pove del Grappa",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36020",
        CodFisco: "G943",
        Abitanti: "3144",
      },
      {
        Istat: "26062",
        Comune: "Povegliano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31050",
        CodFisco: "G944",
        Abitanti: "5091",
      },
      {
        Istat: "23060",
        Comune: "Povegliano Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37064",
        CodFisco: "G945",
        Abitanti: "7091",
      },
      {
        Istat: "24082",
        Comune: "Pozzoleone",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "G957",
        Abitanti: "2825",
      },
      {
        Istat: "28070",
        Comune: "Pozzonovo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35020",
        CodFisco: "G963",
        Abitanti: "3694",
      },
      {
        Istat: "27030",
        Comune: "Pramaggiore",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "G981",
        Abitanti: "4714",
      },
      {
        Istat: "26063",
        Comune: "Preganziol",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31022",
        CodFisco: "H022",
        Abitanti: "17025",
      },
      {
        Istat: "23061",
        Comune: "Pressana",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "H048",
        Abitanti: "2576",
      },
      {
        Istat: "25041",
        Comune: "Puos d'Alpago",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32015",
        CodFisco: "H092",
        Abitanti: "2519",
      },
      {
        Istat: "27031",
        Comune: "Quarto d'Altino",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "30020",
        CodFisco: "H117",
        Abitanti: "8212",
      },
      {
        Istat: "25042",
        Comune: "Quero",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32030",
        CodFisco: "H124",
        Abitanti: "2554",
      },
      {
        Istat: "26064",
        Comune: "Quinto di Treviso",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31055",
        CodFisco: "H131",
        Abitanti: "9846",
      },
      {
        Istat: "24083",
        Comune: "Quinto Vicentino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "H134",
        Abitanti: "5763",
      },
      {
        Istat: "24084",
        Comune: "Recoaro Terme",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36076",
        CodFisco: "H214",
        Abitanti: "6835",
      },
      {
        Istat: "26065",
        Comune: "Refrontolo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31020",
        CodFisco: "H220",
        Abitanti: "1863",
      },
      {
        Istat: "26066",
        Comune: "Resana",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31023",
        CodFisco: "H238",
        Abitanti: "9351",
      },
      {
        Istat: "26067",
        Comune: "Revine Lago",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31020",
        CodFisco: "H253",
        Abitanti: "2263",
      },
      {
        Istat: "26068",
        Comune: "Riese Pio X",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31039",
        CodFisco: "H280",
        Abitanti: "10976",
      },
      {
        Istat: "25043",
        Comune: "Rivamonte Agordino",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "H327",
        Abitanti: "678",
      },
      {
        Istat: "23062",
        Comune: "Rivoli Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "H356",
        Abitanti: "2134",
      },
      {
        Istat: "24085",
        Comune: "Roana",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36010",
        CodFisco: "H361",
        Abitanti: "4286",
      },
      {
        Istat: "25044",
        Comune: "Rocca Pietore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "H379",
        Abitanti: "1324",
      },
      {
        Istat: "24086",
        Comune: "Romano d'Ezzelino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36060",
        CodFisco: "H512",
        Abitanti: "14621",
      },
      {
        Istat: "23063",
        Comune: "Roncà",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "H522",
        Abitanti: "3766",
      },
      {
        Istat: "26069",
        Comune: "Roncade",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31056",
        CodFisco: "H523",
        Abitanti: "14148",
      },
      {
        Istat: "23064",
        Comune: "Ronco all'Adige",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37055",
        CodFisco: "H540",
        Abitanti: "6221",
      },
      {
        Istat: "24087",
        Comune: "Rosà",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36027",
        CodFisco: "H556",
        Abitanti: "14138",
      },
      {
        Istat: "29040",
        Comune: "Rosolina",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45010",
        CodFisco: "H573",
        Abitanti: "6511",
      },
      {
        Istat: "24088",
        Comune: "Rossano Veneto",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36028",
        CodFisco: "H580",
        Abitanti: "7959",
      },
      {
        Istat: "24089",
        Comune: "Rotzo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36010",
        CodFisco: "H594",
        Abitanti: "633",
      },
      {
        Istat: "23065",
        Comune: "Roverchiara",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37050",
        CodFisco: "H606",
        Abitanti: "2839",
      },
      {
        Istat: "23067",
        Comune: "Roverè Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37028",
        CodFisco: "H608",
        Abitanti: "2206",
      },
      {
        Istat: "23066",
        Comune: "Roveredo di Guà",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "H610",
        Abitanti: "1546",
      },
      {
        Istat: "29041",
        Comune: "Rovigo",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45100",
        CodFisco: "H620",
        Abitanti: "52793",
      },
      {
        Istat: "28071",
        Comune: "Rovolon",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "H622",
        Abitanti: "4787",
      },
      {
        Istat: "28072",
        Comune: "Rubano",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "H625",
        Abitanti: "15606",
      },
      {
        Istat: "28073",
        Comune: "Saccolongo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "H655",
        Abitanti: "4916",
      },
      {
        Istat: "29042",
        Comune: "Salara",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "H689",
        Abitanti: "1203",
      },
      {
        Istat: "24090",
        Comune: "Salcedo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36040",
        CodFisco: "F810",
        Abitanti: "1021",
      },
      {
        Istat: "28074",
        Comune: "Saletto",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35046",
        CodFisco: "H705",
        Abitanti: "2751",
      },
      {
        Istat: "26070",
        Comune: "Salgareda",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31040",
        CodFisco: "H706",
        Abitanti: "6692",
      },
      {
        Istat: "23068",
        Comune: "Salizzole",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37056",
        CodFisco: "H714",
        Abitanti: "3746",
      },
      {
        Istat: "27032",
        Comune: "Salzano",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30030",
        CodFisco: "H735",
        Abitanti: "12727",
      },
      {
        Istat: "29043",
        Comune: "San Bellino",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45020",
        CodFisco: "H768",
        Abitanti: "1206",
      },
      {
        Istat: "26071",
        Comune: "San Biagio di Callalta",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31048",
        CodFisco: "H781",
        Abitanti: "13279",
      },
      {
        Istat: "23069",
        Comune: "San Bonifacio",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37047",
        CodFisco: "H783",
        Abitanti: "20774",
      },
      {
        Istat: "27033",
        Comune: "San Donà di Piave",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30027",
        CodFisco: "H823",
        Abitanti: "41592",
      },
      {
        Istat: "26072",
        Comune: "San Fior",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31020",
        CodFisco: "H843",
        Abitanti: "6940",
      },
      {
        Istat: "24092",
        Comune: "San Germano dei Berici",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "H863",
        Abitanti: "1166",
      },
      {
        Istat: "28075",
        Comune: "San Giorgio delle Pertiche",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "H893",
        Abitanti: "10029",
      },
      {
        Istat: "28076",
        Comune: "San Giorgio in Bosco",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "H897",
        Abitanti: "6289",
      },
      {
        Istat: "23070",
        Comune: "San Giovanni Ilarione",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37035",
        CodFisco: "H916",
        Abitanti: "5183",
      },
      {
        Istat: "23071",
        Comune: "San Giovanni Lupatoto",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37057",
        CodFisco: "H924",
        Abitanti: "24214",
      },
      {
        Istat: "25045",
        Comune: "San Gregorio nelle Alpi",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32030",
        CodFisco: "H938",
        Abitanti: "1616",
      },
      {
        Istat: "23073",
        Comune: "San Martino Buon Albergo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37036",
        CodFisco: "I003",
        Abitanti: "14295",
      },
      {
        Istat: "28077",
        Comune: "San Martino di Lupari",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35018",
        CodFisco: "I008",
        Abitanti: "13233",
      },
      {
        Istat: "29044",
        Comune: "San Martino di Venezze",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "H996",
        Abitanti: "4046",
      },
      {
        Istat: "23074",
        Comune: "San Mauro di Saline",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "H712",
        Abitanti: "573",
      },
      {
        Istat: "27034",
        Comune: "San Michele al Tagliamento",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "431",
        CAP: "30028",
        CodFisco: "I040",
        Abitanti: "12103",
      },
      {
        Istat: "24093",
        Comune: "San Nazario",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36020",
        CodFisco: "I047",
        Abitanti: "1786",
      },
      {
        Istat: "25046",
        Comune: "San Nicolò di Comelico",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "I063",
        Abitanti: "407",
      },
      {
        Istat: "25047",
        Comune: "San Pietro di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "I088",
        Abitanti: "1719",
      },
      {
        Istat: "26073",
        Comune: "San Pietro di Feletto",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31020",
        CodFisco: "I103",
        Abitanti: "5416",
      },
      {
        Istat: "23075",
        Comune: "San Pietro di Morubio",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37050",
        CodFisco: "I105",
        Abitanti: "3024",
      },
      {
        Istat: "23076",
        Comune: "San Pietro in Cariano",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37029",
        CodFisco: "I109",
        Abitanti: "13110",
      },
      {
        Istat: "28078",
        Comune: "San Pietro in Gu",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "I107",
        Abitanti: "4623",
      },
      {
        Istat: "24094",
        Comune: "San Pietro Mussolino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36070",
        CodFisco: "I117",
        Abitanti: "1619",
      },
      {
        Istat: "28079",
        Comune: "San Pietro Viminario",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35020",
        CodFisco: "I120",
        Abitanti: "3000",
      },
      {
        Istat: "26074",
        Comune: "San Polo di Piave",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31020",
        CodFisco: "I124",
        Abitanti: "5021",
      },
      {
        Istat: "25049",
        Comune: "San Tomaso Agordino",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "I347",
        Abitanti: "696",
      },
      {
        Istat: "26076",
        Comune: "San Vendemiano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31020",
        CodFisco: "I382",
        Abitanti: "10091",
      },
      {
        Istat: "25051",
        Comune: "San Vito di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "436",
        CAP: "32046",
        CodFisco: "I392",
        Abitanti: "1827",
      },
      {
        Istat: "24096",
        Comune: "San Vito di Leguzzano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "I401",
        Abitanti: "3579",
      },
      {
        Istat: "23079",
        Comune: "San Zeno di Montagna",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "I414",
        Abitanti: "1363",
      },
      {
        Istat: "26077",
        Comune: "San Zenone degli Ezzelini",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31020",
        CodFisco: "I417",
        Abitanti: "7459",
      },
      {
        Istat: "24091",
        Comune: "Sandrigo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36066",
        CodFisco: "H829",
        Abitanti: "8665",
      },
      {
        Istat: "23072",
        Comune: "Sanguinetto",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37058",
        CodFisco: "H944",
        Abitanti: "4205",
      },
      {
        Istat: "25048",
        Comune: "Santa Giustina",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32035",
        CodFisco: "I206",
        Abitanti: "6800",
      },
      {
        Istat: "28080",
        Comune: "Santa Giustina in Colle",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "I207",
        Abitanti: "7168",
      },
      {
        Istat: "26075",
        Comune: "Santa Lucia di Piave",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31025",
        CodFisco: "I221",
        Abitanti: "9032",
      },
      {
        Istat: "28081",
        Comune: "Santa Margherita d'Adige",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "I226",
        Abitanti: "2358",
      },
      {
        Istat: "27035",
        Comune: "Santa Maria di Sala",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30036",
        CodFisco: "I242",
        Abitanti: "17278",
      },
      {
        Istat: "23077",
        Comune: "Sant'Ambrogio di Valpolicella",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37015",
        CodFisco: "I259",
        Abitanti: "11635",
      },
      {
        Istat: "28082",
        Comune: "Sant'Angelo di Piove di Sacco",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "I275",
        Abitanti: "7249",
      },
      {
        Istat: "23078",
        Comune: "Sant'Anna d'Alfaedo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37020",
        CodFisco: "I292",
        Abitanti: "2657",
      },
      {
        Istat: "28083",
        Comune: "Sant'Elena",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "I319",
        Abitanti: "2375",
      },
      {
        Istat: "25050",
        Comune: "Santo Stefano di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32045",
        CodFisco: "C919",
        Abitanti: "2680",
      },
      {
        Istat: "27036",
        Comune: "Santo Stino di Livenza",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30029",
        CodFisco: "I373",
        Abitanti: "13088",
      },
      {
        Istat: "24095",
        Comune: "Santorso",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36014",
        CodFisco: "I353",
        Abitanti: "5856",
      },
      {
        Istat: "28084",
        Comune: "Sant'Urbano",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "I375",
        Abitanti: "2178",
      },
      {
        Istat: "28085",
        Comune: "Saonara",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "I418",
        Abitanti: "10073",
      },
      {
        Istat: "25052",
        Comune: "Sappada",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32047",
        CodFisco: "I421",
        Abitanti: "1307",
      },
      {
        Istat: "24097",
        Comune: "Sarcedo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "I425",
        Abitanti: "5316",
      },
      {
        Istat: "24098",
        Comune: "Sarego",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "I430",
        Abitanti: "6587",
      },
      {
        Istat: "26078",
        Comune: "Sarmede",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31026",
        CodFisco: "I435",
        Abitanti: "3202",
      },
      {
        Istat: "24099",
        Comune: "Schiavon",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36060",
        CodFisco: "I527",
        Abitanti: "2598",
      },
      {
        Istat: "24100",
        Comune: "Schio",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36015",
        CodFisco: "I531",
        Abitanti: "39566",
      },
      {
        Istat: "27037",
        Comune: "Scorzè",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30037",
        CodFisco: "I551",
        Abitanti: "19052",
      },
      {
        Istat: "25053",
        Comune: "Sedico",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32036",
        CodFisco: "I563",
        Abitanti: "9928",
      },
      {
        Istat: "26079",
        Comune: "Segusino",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31040",
        CodFisco: "I578",
        Abitanti: "1974",
      },
      {
        Istat: "25054",
        Comune: "Selva di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "I592",
        Abitanti: "520",
      },
      {
        Istat: "23080",
        Comune: "Selva di Progno",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "I594",
        Abitanti: "954",
      },
      {
        Istat: "28086",
        Comune: "Selvazzano Dentro",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "I595",
        Abitanti: "22305",
      },
      {
        Istat: "25055",
        Comune: "Seren del Grappa",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32030",
        CodFisco: "I626",
        Abitanti: "2611",
      },
      {
        Istat: "26080",
        Comune: "Sernaglia della Battaglia",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31020",
        CodFisco: "I635",
        Abitanti: "6358",
      },
      {
        Istat: "26081",
        Comune: "Silea",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31057",
        CodFisco: "F116",
        Abitanti: "10118",
      },
      {
        Istat: "23081",
        Comune: "Soave",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37038",
        CodFisco: "I775",
        Abitanti: "6976",
      },
      {
        Istat: "24101",
        Comune: "Solagna",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36020",
        CodFisco: "I783",
        Abitanti: "1894",
      },
      {
        Istat: "28087",
        Comune: "Solesino",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35047",
        CodFisco: "I799",
        Abitanti: "7239",
      },
      {
        Istat: "23082",
        Comune: "Sommacampagna",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37066",
        CodFisco: "I821",
        Abitanti: "14824",
      },
      {
        Istat: "23083",
        Comune: "Sona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "I826",
        Abitanti: "17156",
      },
      {
        Istat: "23084",
        Comune: "Sorgà",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "I850",
        Abitanti: "3172",
      },
      {
        Istat: "25056",
        Comune: "Sospirolo",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32037",
        CodFisco: "I866",
        Abitanti: "3246",
      },
      {
        Istat: "24102",
        Comune: "Sossano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "I867",
        Abitanti: "4477",
      },
      {
        Istat: "25057",
        Comune: "Soverzene",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "I876",
        Abitanti: "424",
      },
      {
        Istat: "24103",
        Comune: "Sovizzo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "I879",
        Abitanti: "7004",
      },
      {
        Istat: "25058",
        Comune: "Sovramonte",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32030",
        CodFisco: "I673",
        Abitanti: "1541",
      },
      {
        Istat: "27038",
        Comune: "Spinea",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30038",
        CodFisco: "I908",
        Abitanti: "27041",
      },
      {
        Istat: "26082",
        Comune: "Spresiano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31027",
        CodFisco: "I927",
        Abitanti: "11715",
      },
      {
        Istat: "28088",
        Comune: "Stanghella",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "35048",
        CodFisco: "I938",
        Abitanti: "4403",
      },
      {
        Istat: "29045",
        Comune: "Stienta",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45039",
        CodFisco: "I953",
        Abitanti: "3354",
      },
      {
        Istat: "27039",
        Comune: "Stra",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "30039",
        CodFisco: "I965",
        Abitanti: "7574",
      },
      {
        Istat: "26083",
        Comune: "Susegana",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31058",
        CodFisco: "L014",
        Abitanti: "12055",
      },
      {
        Istat: "29046",
        Comune: "Taglio di Po",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "426",
        CAP: "45019",
        CodFisco: "L026",
        Abitanti: "8537",
      },
      {
        Istat: "25059",
        Comune: "Taibon Agordino",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32027",
        CodFisco: "L030",
        Abitanti: "1787",
      },
      {
        Istat: "25060",
        Comune: "Tambre",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "L040",
        Abitanti: "1421",
      },
      {
        Istat: "26084",
        Comune: "Tarzo",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31020",
        CodFisco: "L058",
        Abitanti: "4621",
      },
      {
        Istat: "27040",
        Comune: "Teglio Veneto",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30025",
        CodFisco: "L085",
        Abitanti: "2316",
      },
      {
        Istat: "28089",
        Comune: "Teolo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35037",
        CodFisco: "L100",
        Abitanti: "8949",
      },
      {
        Istat: "28090",
        Comune: "Terrassa Padovana",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "L132",
        Abitanti: "2606",
      },
      {
        Istat: "23085",
        Comune: "Terrazzo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "L136",
        Abitanti: "2335",
      },
      {
        Istat: "24104",
        Comune: "Tezze sul Brenta",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36056",
        CodFisco: "L156",
        Abitanti: "12568",
      },
      {
        Istat: "24105",
        Comune: "Thiene",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36016",
        CodFisco: "L157",
        Abitanti: "23505",
      },
      {
        Istat: "28091",
        Comune: "Tombolo",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35019",
        CodFisco: "L199",
        Abitanti: "8288",
      },
      {
        Istat: "24106",
        Comune: "Tonezza del Cimone",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36040",
        CodFisco: "D717",
        Abitanti: "589",
      },
      {
        Istat: "27041",
        Comune: "Torre di Mosto",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "30020",
        CodFisco: "L267",
        Abitanti: "4766",
      },
      {
        Istat: "24107",
        Comune: "Torrebelvicino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36036",
        CodFisco: "L248",
        Abitanti: "6061",
      },
      {
        Istat: "28092",
        Comune: "Torreglia",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35038",
        CodFisco: "L270",
        Abitanti: "6277",
      },
      {
        Istat: "23086",
        Comune: "Torri del Benaco",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37010",
        CodFisco: "L287",
        Abitanti: "2963",
      },
      {
        Istat: "24108",
        Comune: "Torri di Quartesolo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36040",
        CodFisco: "L297",
        Abitanti: "11939",
      },
      {
        Istat: "28093",
        Comune: "Trebaseleghe",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "L349",
        Abitanti: "12656",
      },
      {
        Istat: "29047",
        Comune: "Trecenta",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45027",
        CodFisco: "L359",
        Abitanti: "3012",
      },
      {
        Istat: "23087",
        Comune: "Tregnago",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37039",
        CodFisco: "L364",
        Abitanti: "4938",
      },
      {
        Istat: "23088",
        Comune: "Trevenzuolo",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37060",
        CodFisco: "L396",
        Abitanti: "2715",
      },
      {
        Istat: "26085",
        Comune: "Trevignano",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31040",
        CodFisco: "L402",
        Abitanti: "10613",
      },
      {
        Istat: "26086",
        Comune: "Treviso",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31100",
        CodFisco: "L407",
        Abitanti: "82807",
      },
      {
        Istat: "28094",
        Comune: "Tribano",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35020",
        CodFisco: "L414",
        Abitanti: "4465",
      },
      {
        Istat: "25061",
        Comune: "Trichiana",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32028",
        CodFisco: "L422",
        Abitanti: "4810",
      },
      {
        Istat: "24110",
        Comune: "Trissino",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36070",
        CodFisco: "L433",
        Abitanti: "8679",
      },
      {
        Istat: "28095",
        Comune: "Urbana",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "L497",
        Abitanti: "2202",
      },
      {
        Istat: "24111",
        Comune: "Valdagno",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36078",
        CodFisco: "L551",
        Abitanti: "26889",
      },
      {
        Istat: "24112",
        Comune: "Valdastico",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36040",
        CodFisco: "L554",
        Abitanti: "1424",
      },
      {
        Istat: "26087",
        Comune: "Valdobbiadene",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31049",
        CodFisco: "L565",
        Abitanti: "10831",
      },
      {
        Istat: "23089",
        Comune: "Valeggio sul Mincio",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37067",
        CodFisco: "L567",
        Abitanti: "14456",
      },
      {
        Istat: "25062",
        Comune: "Vallada Agordina",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "L584",
        Abitanti: "515",
      },
      {
        Istat: "25063",
        Comune: "Valle di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "L590",
        Abitanti: "2080",
      },
      {
        Istat: "24113",
        Comune: "Valli del Pasubio",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "L624",
        Abitanti: "3386",
      },
      {
        Istat: "24114",
        Comune: "Valstagna",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "424",
        CAP: "36020",
        CodFisco: "L650",
        Abitanti: "1900",
      },
      {
        Istat: "25064",
        Comune: "Vas",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "439",
        CAP: "32030",
        CodFisco: "L692",
        Abitanti: "854",
      },
      {
        Istat: "26088",
        Comune: "Vazzola",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31028",
        CodFisco: "L700",
        Abitanti: "7107",
      },
      {
        Istat: "26089",
        Comune: "Vedelago",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31050",
        CodFisco: "L706",
        Abitanti: "16678",
      },
      {
        Istat: "28096",
        Comune: "Veggiano",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "L710",
        Abitanti: "4509",
      },
      {
        Istat: "24115",
        Comune: "Velo d'Astico",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36010",
        CodFisco: "L723",
        Abitanti: "2446",
      },
      {
        Istat: "23090",
        Comune: "Velo Veronese",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "L722",
        Abitanti: "787",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30121",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30122",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30123",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30124",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30125",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30126",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30127",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30128",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30129",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30130",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30131",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30132",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30133",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30134",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30135",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30136",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30137",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30138",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30139",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30140",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30141",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30142",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30143",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30144",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30145",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30146",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30147",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30148",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30149",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30150",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30151",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30152",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30153",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30154",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30155",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30156",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30157",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30158",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30159",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30160",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30161",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30162",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30163",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30164",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30165",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30166",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30167",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30168",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30169",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30170",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30171",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30172",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30173",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30174",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30175",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "27042",
        Comune: "Venezia",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "41",
        CAP: "30176",
        CodFisco: "L736",
        Abitanti: "270884",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37121",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37122",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37123",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37124",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37125",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37126",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37127",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37128",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37129",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37130",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37131",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37132",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37133",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37134",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37135",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37136",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37137",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37138",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37139",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37140",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37141",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23091",
        Comune: "Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37142",
        CodFisco: "L781",
        Abitanti: "263964",
      },
      {
        Istat: "23092",
        Comune: "Veronella",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "D193",
        Abitanti: "4710",
      },
      {
        Istat: "28097",
        Comune: "Vescovana",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "35040",
        CodFisco: "L805",
        Abitanti: "1702",
      },
      {
        Istat: "23093",
        Comune: "Vestenanova",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37030",
        CodFisco: "L810",
        Abitanti: "2677",
      },
      {
        Istat: "24116",
        Comune: "Vicenza",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36100",
        CodFisco: "L840",
        Abitanti: "115927",
      },
      {
        Istat: "26090",
        Comune: "Vidor",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31020",
        CodFisco: "L856",
        Abitanti: "3819",
      },
      {
        Istat: "23094",
        Comune: "Vigasio",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37068",
        CodFisco: "L869",
        Abitanti: "9438",
      },
      {
        Istat: "28098",
        Comune: "Vighizzolo d'Este",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "L878",
        Abitanti: "955",
      },
      {
        Istat: "25065",
        Comune: "Vigo di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "L890",
        Abitanti: "1553",
      },
      {
        Istat: "28099",
        Comune: "Vigodarzere",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "L892",
        Abitanti: "12873",
      },
      {
        Istat: "27043",
        Comune: "Vigonovo",
        Provincia: "VE",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "30030",
        CodFisco: "L899",
        Abitanti: "10078",
      },
      {
        Istat: "28100",
        Comune: "Vigonza",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "L900",
        Abitanti: "22075",
      },
      {
        Istat: "23095",
        Comune: "Villa Bartolomea",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37049",
        CodFisco: "L912",
        Abitanti: "5875",
      },
      {
        Istat: "28101",
        Comune: "Villa del Conte",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "L934",
        Abitanti: "5530",
      },
      {
        Istat: "28102",
        Comune: "Villa Estense",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "429",
        CAP: "35040",
        CodFisco: "L937",
        Abitanti: "2345",
      },
      {
        Istat: "29048",
        Comune: "Villadose",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45010",
        CodFisco: "L939",
        Abitanti: "5223",
      },
      {
        Istat: "23096",
        Comune: "Villafranca di Verona",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37069",
        CodFisco: "L949",
        Abitanti: "33117",
      },
      {
        Istat: "28103",
        Comune: "Villafranca Padovana",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "L947",
        Abitanti: "9841",
      },
      {
        Istat: "24117",
        Comune: "Villaga",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36021",
        CodFisco: "L952",
        Abitanti: "1970",
      },
      {
        Istat: "29049",
        Comune: "Villamarzana",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "L967",
        Abitanti: "1225",
      },
      {
        Istat: "29050",
        Comune: "Villanova del Ghebbo",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45020",
        CodFisco: "L985",
        Abitanti: "2192",
      },
      {
        Istat: "28104",
        Comune: "Villanova di Camposampiero",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35010",
        CodFisco: "L979",
        Abitanti: "5903",
      },
      {
        Istat: "29051",
        Comune: "Villanova Marchesana",
        Provincia: "RO",
        Regione: "VEN",
        Prefisso: "425",
        CAP: "45030",
        CodFisco: "L988",
        Abitanti: "1047",
      },
      {
        Istat: "24118",
        Comune: "Villaverla",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "M032",
        Abitanti: "6244",
      },
      {
        Istat: "26091",
        Comune: "Villorba",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31020",
        CodFisco: "M048",
        Abitanti: "18044",
      },
      {
        Istat: "26092",
        Comune: "Vittorio Veneto",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "438",
        CAP: "31029",
        CodFisco: "M089",
        Abitanti: "28964",
      },
      {
        Istat: "28105",
        Comune: "Vo'",
        Provincia: "PD",
        Regione: "VEN",
        Prefisso: "49",
        CAP: "35030",
        CodFisco: "M103",
        Abitanti: "3396",
      },
      {
        Istat: "25066",
        Comune: "Vodo Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "435",
        CAP: "32040",
        CodFisco: "M108",
        Abitanti: "922",
      },
      {
        Istat: "26093",
        Comune: "Volpago del Montello",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "423",
        CAP: "31040",
        CodFisco: "M118",
        Abitanti: "10024",
      },
      {
        Istat: "25067",
        Comune: "Voltago Agordino",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32020",
        CodFisco: "M124",
        Abitanti: "928",
      },
      {
        Istat: "24119",
        Comune: "Zanè",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36010",
        CodFisco: "M145",
        Abitanti: "6599",
      },
      {
        Istat: "26094",
        Comune: "Zenson di Piave",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "421",
        CAP: "31050",
        CodFisco: "M163",
        Abitanti: "1825",
      },
      {
        Istat: "24120",
        Comune: "Zermeghedo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36050",
        CodFisco: "M170",
        Abitanti: "1380",
      },
      {
        Istat: "26095",
        Comune: "Zero Branco",
        Provincia: "TV",
        Regione: "VEN",
        Prefisso: "422",
        CAP: "31059",
        CodFisco: "M171",
        Abitanti: "10977",
      },
      {
        Istat: "23097",
        Comune: "Zevio",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "45",
        CAP: "37059",
        CodFisco: "M172",
        Abitanti: "14522",
      },
      {
        Istat: "23098",
        Comune: "Zimella",
        Provincia: "VR",
        Regione: "VEN",
        Prefisso: "442",
        CAP: "37040",
        CodFisco: "M178",
        Abitanti: "4931",
      },
      {
        Istat: "25068",
        Comune: "Zoldo Alto",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "I345",
        Abitanti: "1034",
      },
      {
        Istat: "25069",
        Comune: "Zoppè di Cadore",
        Provincia: "BL",
        Regione: "VEN",
        Prefisso: "437",
        CAP: "32010",
        CodFisco: "M189",
        Abitanti: "271",
      },
      {
        Istat: "24121",
        Comune: "Zovencedo",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "444",
        CAP: "36020",
        CodFisco: "M194",
        Abitanti: "814",
      },
      {
        Istat: "24122",
        Comune: "Zugliano",
        Provincia: "VI",
        Regione: "VEN",
        Prefisso: "445",
        CAP: "36030",
        CodFisco: "M199",
        Abitanti: "6753",
      },
    ];
  }

  getComuni(provincia: string) {
    return this.comuni
      .filter((comune) => comune.Provincia === provincia)
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Comune === value.Comune)
      );
  }

  getCap(citta: string) {
    return this.comuni.filter((comune) => comune.Comune === citta);
  }

  getProvince() {
    let province = [];
    this.comuni.forEach(function (obj) {
      // console.log(obj);
      province.push(obj.Provincia);
    });
    return province
      .filter((item, index) => {
        return province.indexOf(item) === index;
      })
      .sort();
  }
}
