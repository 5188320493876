import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { IResponse } from '../interfaces/response';
import { Login } from '../interfaces/login';
import { Router } from '@angular/router';
import { User } from '../class/User';
import { map } from 'rxjs/operators';

declare var __env: any;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  // @Output() userloggedin = new EventEmitter<User>();
  // @Output() userlogout = new EventEmitter();

  private isUserLogged = false;
  private apiAuthUrl = '/login';
  private apiNewPassword = '/cambiaPassword';
  private apiSendPassword = '/sendNewPassword';
  private apiUrlLogout = '/logout';
  private apiUrlRegistrazione = '/saveUser';
  private apiUrlConfermaRegistrazione = '/validaRegistrazione';
  private apiUrlRecuperaPassword = '/recuperaPassword';
  private apiUrlRecuperaUsername = '/recuperaUsername';
  private apiCheckTokenNewPassword = '/checkToken';
  private apiCheckTokenRecuperaUsername = '/checkTokenRecuperaUsername';


  private token!: string;
  public login!: Login;

  constructor(
              private http: HttpClient,
              private router: Router
              ) {}

  isUserLoggedIn() {
      const loginData = JSON.parse(localStorage.loginData  || '{}');
      let token ;
      // console.log(loginData);
      ( loginData !== null && 'items' in loginData) ? token = loginData.items.token : token = null;
      // console.log('token', token);
      if (token !== null && token !== '' && token !== 'null') {
          this.isUserLogged = true;
      } else {
          this.isUserLogged = false;
      }
      // console.log('Checklogin...', this.isUserLogged);
      return this.isUserLogged;
  }

  getTypeUser() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    //   console.log(loginData);
    let typeUser ;
    ( loginData !== null && 'items' in loginData) ? typeUser = loginData.items.tipoUser : typeUser = null;
    // console.log('loginData', loginData);
    // console.log('typeUser', typeUser);
    return typeUser || '';
  }

  getFirstLogin() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let firstLogin ;
    ( loginData !== null && 'items' in loginData) ? firstLogin = loginData.items.firstLogin : firstLogin = null;
    return firstLogin || null;
  }

  getEnabledUpload() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let enabledUpload ;
    ( loginData !== null && 'items' in loginData) ? enabledUpload = loginData.items.enabledUpload : enabledUpload = null;
    return enabledUpload || null;
  }

  getIdUser() {
    // console.log('Recupero l\'idUtente');
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    // console.log(loginData);
    const idUtente = loginData.items.idUtente;
    return idUtente || '';
  }

  getIdGruppo() {
    // console.log('Recupero l\'idUtente');
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    // console.log(loginData);
    const idGruppo = loginData.items.idGruppo;
    return idGruppo || '';
  }
  getEmail() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let email ;
    ('items' in loginData) ?  email = loginData.items.email : email =  null;
    return email;
  }

  getGender() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let gender ;
    ('items' in loginData) ?  gender = loginData.items.gender : gender =  null;
    return gender;
  }

  getDataNascita() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let dataNascita ;
    ('items' in loginData) ?  dataNascita = loginData.items.dataNascita : dataNascita =  null;
    return dataNascita;
  }

  getLinkBanner() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let linkBanner ;
    ('items' in loginData) ?  linkBanner = loginData.items.linkBanner : linkBanner =  '';
    return linkBanner;
  }

  getName() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let nome ;
    ('items' in loginData) ?  nome = loginData.items.nome : nome =  '';
    return nome;
  }

  getUserName() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let username ;
    ('items' in loginData) ?  username = loginData.items.username : username =  null;
    return username;
  }

  getSurname() {
    const loginData = JSON.parse(localStorage.loginData  || '{}');
    let cognome ;
    ('items' in loginData) ?  cognome = loginData.items.cognome : cognome =  '';
    return cognome;
  }

  getNewPassword(token: string, newPassword: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };
    const params = new URLSearchParams();
    params.set('newPassword', newPassword);
    params.set('token', token);
    const body = params.toString();
    return this.http.post(__env.apiUrl + this.apiNewPassword, body, options)
                    .pipe(map(
                          (resp: any) => {
                              return resp;
                          }
                      ));
  }

  checkTokenNewPassword(token: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };
    // tslint:disable-next-line: object-literal-shorthand
    const params = {params: {token: token}};
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.get(__env.apiUrl + this.apiCheckTokenNewPassword, params )
                    .pipe(map(
                          (resp: any) => {
                              return resp;
                          }
                      ));
  }

  checkTokenRecuperaUsername(token: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };
    // tslint:disable-next-line: object-literal-shorthand
    const params = {params: {token: token}};
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.get(__env.apiUrl + this.apiCheckTokenRecuperaUsername, params )
                    .pipe(map(
                          (resp: any) => {
                              return resp;
                          }
                      ));
  }

  sendPassword(email: string, token: string) {
    return this.http.post(__env.apiUrl + this.apiSendPassword,
      {
          // tslint:disable-next-line: object-literal-shorthand
          email: email,
          // tslint:disable-next-line: object-literal-shorthand
          token: token
      }
      ).pipe(map(
      (resp: any) => {
         let r: IResponse;
         r = resp;
         return r;
      }
    ));
  }

  logIn(username: string, password: string, keepConn: string) {

      const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      const options = { headers };
      const params = new URLSearchParams();
      // tslint:disable-next-line: forin
      // for (let key in options) {
      //   params.set(key, options[key]);
      // }
      params.set('username', username);
      params.set('password', password);
      params.set('keepConn', keepConn);

      const body = params.toString();

      return this.http.post(__env.apiUrl + this.apiAuthUrl, body, options
          ).pipe(map(
          (l: any) => {
              let loginData: Login;
              loginData = l;
              if (loginData.status === 'OK') {
                  localStorage.clear();
                  // console.log(JSON.stringify(loginData));
                  localStorage.setItem('loginData', JSON.stringify(loginData));
                  const user = new User();
                  user.email = loginData.items.email;
                  user.username = loginData.items.username;
                  // this.router.navigate(['login']);
              }
              return loginData;
      }
      ));
  }

  registrati( email: string,
              username: string,
              nome: string,
              cognome: string,
              password: string,
              dataNascita: any,
              gender: string,
              consensoPub: string,
              consensoTermini: string) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };
    const params = new URLSearchParams();
    // tslint:disable-next-line: forin
    // for (let key in options) {
    //   params.set(key, options[key]);
    // }
    params.set('email', email);
    params.set('userName', username);
    params.set('password', password);
    params.set('nome', nome);
    params.set('cognome', cognome);
    // tslint:disable-next-line: no-string-literal
    const dateFormatted = dataNascita['year'] + '-' + dataNascita['month'] + '-' + dataNascita['day'];
    // console.log('dateFormatted', dateFormatted);
    params.set('dataNascita', dateFormatted);
    params.set('gender', gender);
    params.set('consensoPub', consensoPub);
    params.set('consensoTermini', consensoTermini);
    params.set('sendEmail', 'true');


    const body = params.toString();

    return this.http.post(__env.apiUrl + this.apiUrlRegistrazione, body, options
        ).pipe(map(
        (l: any) => {
            // tslint:disable-next-line: prefer-const
            let loginData: Login = l;
            if (loginData.status === 'OK') {
                console.log(JSON.stringify(loginData));
                localStorage.setItem('loginData', JSON.stringify(loginData));
            }
            return loginData;
    }
    ));
}

  logout() {
    this.token = this.getToken();
    this.setToken(null);
    // console.log('Token post logOut', this.getToken());
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };

    // localStorage.clear();
    const params = new URLSearchParams();
      // tslint:disable-next-line: forin
      // for (let key in options) {
      //   params.set(key, options[key]);
      // }
    params.set('token', this.token);
    const body = params.toString();

    return this.http.post(__env.apiUrl + this.apiUrlLogout, body, options).pipe(map(
            (l: any) => {
               // tslint:disable-next-line: prefer-const
               let loginData: Login = l;
                // if (loginData.status === 'OK') {
                //   // this.userlogout.emit();
                // }
               this.isUserLogged = false;
               localStorage.clear();
               return loginData;
        }
        ));
  }

  getUser(): User {
      let ret = new User();
      if (localStorage.loginData) {
          const loginData = JSON.parse(localStorage.loginData);
          const userData = loginData.userData;
          if (userData) {
              const user = new User();
              user.nome = loginData.userData.nome;
              user.cognome = loginData.userData.cognome;
              user.email = loginData.userData.email;
              user.idUtente = loginData.userData.idUtente;
              user.typeUser = loginData.userData.typeUser;
              user.cellulare = loginData.userData.cellulare;
              // console.log(user);
              ret = user;
          }
      }
      return ret;
  }

  getToken() {
    // console.log(localStorage.getItem('loginData'));
    let loginData = null;
    if (localStorage.getItem('loginData') !== null) {
       loginData = JSON.parse(localStorage.loginData);
    } else{
      return null;
    }
    let token = null;
    if (loginData !== null) {
      token = loginData.items.token;
    }
    return token;
  }

  setToken(param: null) {
    let loginData = null;
    if (localStorage.getItem('loginData') !== null) {
       loginData = JSON.parse(localStorage.loginData);
    }
    loginData.items.token = param;
    localStorage.setItem('loginData', JSON.stringify(loginData));
  }

  setFirstLogin(param: boolean) {
    let loginData = null;
    if (localStorage.getItem('loginData') !== null) {
       loginData = JSON.parse(localStorage.loginData);
    }
    loginData.items.firstLogin = param;
    localStorage.setItem('loginData', JSON.stringify(loginData));
  }

  disableFirstLogin() {
    const loginData = JSON.parse(localStorage.loginData);
    // console.log(localStorage.userData);
    loginData.userData.isFirstLogin = false;
    // console.log(localStorage);
    localStorage.setItem('loginData', JSON.stringify(loginData));
  }

  confermaRegistrazione(tokenReg: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };
    const params = new URLSearchParams();
    params.set('tokenReg', tokenReg);
    const body = params.toString();
    return this.http.post(__env.apiUrl + this.apiUrlConfermaRegistrazione, body, options)
                    .pipe(map(
                          (r: any) => {
                            // tslint:disable-next-line: prefer-const
                            let resp: IResponse = r;
                            return resp;
                          }
                      ));
  }

  getLikNewPassword(email: string, username: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };
    const params = new URLSearchParams();
    params.set('email', email);
    params.set('username', username);
    const body = params.toString();
    return this.http.post(__env.apiUrl + this.apiUrlRecuperaPassword, body, options)
                    .pipe(map(
                          (r: any) => {
                            // tslint:disable-next-line: prefer-const
                            let resp: IResponse = r;
                            return resp;
                          }
                      ));

  }

  getLinkUsername(email: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const options = { headers };
    const params = new URLSearchParams();
    params.set('email', email);
    const body = params.toString();
    return this.http.post(__env.apiUrl + this.apiUrlRecuperaUsername, body, options)
                    .pipe(map(
                          (r: any) => {
                            // tslint:disable-next-line: prefer-const
                            let resp: IResponse = r;
                            return resp;
                          }
                      ));

  }

  setNome(param: any) {
    let loginData = null;
    if (localStorage.getItem('loginData') !== null) {
       loginData = JSON.parse(localStorage.loginData);
    }
    loginData.items.nome = param;
    localStorage.setItem('loginData', JSON.stringify(loginData));
  }

  setSurname(param: any) {
    let loginData = null;
    if (localStorage.getItem('loginData') !== null) {
       loginData = JSON.parse(localStorage.loginData);
    }
    loginData.items.cognome = param;
    localStorage.setItem('loginData', JSON.stringify(loginData));
  }

  setGender(param: any) {
    let loginData = null;
    if (localStorage.getItem('loginData') !== null) {
       loginData = JSON.parse(localStorage.loginData);
    }
    loginData.items.gender = param;
    localStorage.setItem('loginData', JSON.stringify(loginData));
  }

  setDataNascita(param: any) {
    let loginData = null;
    if (localStorage.getItem('loginData') !== null) {
       loginData = JSON.parse(localStorage.loginData);
    }
    loginData.items.dataNascita = param;
    localStorage.setItem('loginData', JSON.stringify(loginData));
  }

}
