import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('Login service: ' + this.auth.isUserLoggedIn());
    if (this.auth.isUserLoggedIn()) {
        // console.log('ok');
        return true;
    } else {
        this.router.navigate(['/login']);
    }
  }
}
