import { Component, OnInit } from '@angular/core';
import {  faUser,
          faChevronUp,
          faChevronDown,
          faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtentiService } from 'src/app/services/utenti/utenti.service';

@Component({
  selector: 'app-recap-users',
  templateUrl: './recap-users.component.html',
  styleUrls: ['./recap-users.component.css']
})
export class RecapUsersComponent implements OnInit {
  // icone
  faUser        = faUser;
  faChevronUp   = faChevronUp;
  faChevronDown = faChevronDown;
  faUsersCog    = faUsersCog;

  close: boolean;
  nUsers: number;
  nGold: number;
  nCustomer: number;
  nDelegate: number;
  nAdmin: number;
  nAdminCC: number;

  constructor(private utentiService: UtentiService,
              private loader: NgxUiLoaderService) {
                this.close = false;
                this.nUsers = 0;
                this.nGold = 0;
                this.nCustomer = 0;
                this.nDelegate = 0;
                this.nAdmin = 0;
                this.nAdminCC = 0;

                this.loader.startLoader('nUsersLoader');
                this.utentiService.getNUsers()
                .subscribe(resp => {
                  if (resp.status.toUpperCase() === 'OK'){
                    // tslint:disable-next-line: no-string-literal
                    this.nUsers = resp.items['nUsers'];
                    // tslint:disable-next-line: no-string-literal
                    this.nGold = resp.items['nGold'];
                    // tslint:disable-next-line: no-string-literal
                    this.nAdminCC = resp.items['nAdminCC'];
                    // tslint:disable-next-line: no-string-literal
                    this.nAdmin = resp.items['nAdmin'];
                    // tslint:disable-next-line: no-string-literal
                    this.nCustomer = resp.items['nCustomer'];
                    // tslint:disable-next-line: no-string-literal
                    this.nDelegate = resp.items['nDelegate'];
                  }
                  this.loader.stopLoader('nUsersLoader');
                });
            }

  ngOnInit(): void {
  }

  toggleWidget(){
    this.close = !this.close;
    if (this.close){
      $('#recapUsersWrapper').css('min-height', '0px');
    } else {
      $('#recapUsersWrapper').css('min-height', '200px');
    }
  }

}
