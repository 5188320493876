import { Component, OnInit } from '@angular/core';

import { UtilsService } from 'src/app/services/utils.service';
import {
         faTachometerAlt
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home-dash',
  templateUrl: './home-dash.component.html',
  styleUrls: ['./home-dash.component.css']
})
export class HomeDashComponent implements OnInit {

  // icone
  faTachometerAlt = faTachometerAlt;

  // modal
  closeResult: string;

  // baseUrl: string;


    constructor(private utils: UtilsService) {
                  // this.baseUrl = this.utils.getBaseUrl();
    }

  ngOnInit(): void {
  }

}
