import { UserInterface } from '../interfaces/user';

export class User implements UserInterface {
    nome: string;
    cognome: string;
    username: string;
    email: string;
    typeUser: string;
    cellulare: string;
    idUtente: number;
    dataRegistrazione: Date;
    enabled: boolean;
    enabledUpload: boolean;
    firtsLogin: boolean;
    idGruppo?: number;
    lastReset: Date;

    constructor() {
        this.idUtente = 0;
        this.email = null,
        this.username = null;
        this.typeUser = 'customer',
        this.nome = null,
        this.cognome = null,
        this.cellulare = null,
        this.enabled = false;
        this.enabledUpload = false;
        this.firtsLogin = true;
        this.dataRegistrazione = null;
        this.lastReset = null;
    }
}
