<div class="container-fluid bg-dark gradient m-0 p-5 min-vh-100">
  <div class="container text-center ">
    <div class="bg-white p-4 shadow">
      <img src="../../assets/img/logo.png" class="pb-5 img-fluid" width="300" />
      <div *ngIf="showMaintenanceMsg" class="alert alert-warning" role="alert">
        <h1>Attenzione</h1>
        <p>&Egrave; in corso la manutenzione programmata dle sistema, non è possibile utilizzare la piattaforma.</p>
      </div>
      
      <div class="row">
        <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
          <form *ngIf="!recuperaPassword && !recuperaPasswordOk && !recuperaUsername && !recuperaUsernameOk"  [formGroup]="loginForm" class="justify-content-center text-left" (ngSubmit)="signIn()">
            <div class="form-group text-start my-3">
              <label for="username" class="text-left">Username</label>
              <input type="username" class="form-control" required formControlName="username" name="username" #username placeholder="la tua username">
            </div>
            <div class="form-group text-left">
              <label for="password">Password</label>
              <div class="input-group">
                <input  [type]="(showPassword) ? 'text' : 'password'" class="form-control" name="password" autocomplete="on" required formControlName="password" #password placeholder="password">

                <div class="input-group-append">
                  <span class="input-group-text">
                    <fa-icon class="cur" [ngbTooltip]="(!showPassword) ? 'Mostra la password' : 'Nascondi la password'" [icon]="(showPassword) ? faEye : faEyeSlash" (click)="toggleShowPassword()"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-6">
                <div class="form-check text-left">
                  <input class="form-check-input cur border" type="checkbox" value="" #ricordami>
                  <label class="form-check-label" for="ricordami">
                    Ricordami
                  </label>
                </div>
              </div>
              <div class="col-6 text-right">
                <a (click)=toggleRecuperaPassword(); class="cur" placement="bottom" ngbTooltip="Genera una nuova password"> Password dimenticata?</a>
              </div>
              <div class="col-6">
              </div>
              <div class="col-6 pt-2 text-right">
                <a (click)=toggleRecuperaUsername(); class="cur" placement="bottom" ngbTooltip="Recupera la username"> Username dimenticata?</a>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col">
                <div class="alert alert-danger mt-3 mb-2 " *ngIf="showError" role="alert">
                  {{ error }}
                </div>
                <button type="submit" class="btn btn-block btn-blu " [disabled]="!loginForm.valid" >ACCEDI</button>
              </div>
            </div>

          </form>
          <form *ngIf="recuperaPassword" [formGroup]="recuperaPasswordForm" class="justify-content-center" >
            <div class="form-group text-left">
              <label for="email">E-mail</label>
              <input type="email" class="form-control" required formControlName="email" name="email" #email placeholder="la tua email">
            </div>
            <div class="form-group text-left my-3">
              <label for="username">Username</label>
              <input type="text" class="form-control" required formControlName="username" name="username" #username placeholder="ID00000000">
            </div>
            <div class="alert alert-danger mt-3 mb-2 " *ngIf="showError" role="alert">
              {{ error }}
            </div>
            <button type="button" (click)="getLinkNewPassword()" class="btn btn-primary btn-blu btn-block " [disabled]="!recuperaPasswordForm.valid" >NUOVA PASSWORD</button>

            <div class="row mt-3">
              <div class="col">
                <a (click)="goToLogin()"  placement="bottom" ngbTooltip="Vai alla pagina di Login" class="cur"> Torna al Login</a>
              </div>
            </div>
          </form>
          <form *ngIf="recuperaUsername" [formGroup]="recuperaUsernameForm" class="justify-content-center" >
            <div class="form-group text-left my-3">
              <label for="email">E-mail</label>
              <input type="email" class="form-control" required formControlName="email" name="email" #email placeholder="la tua email">
            </div>
            <button type="button" (click)="getLinkUsername()" class="btn btn-primary btn-blu btn-block " [disabled]="!recuperaUsernameForm.valid" >INVIA USERNAME</button>

            <div class="row mt-3">
              <div class="col">
                <a (click)="goToLogin()"  placement="bottom" ngbTooltip="Vai alla pagina di Login" class="cur"> Torna al Login</a>
              </div>
            </div>
          </form>
          <div *ngIf="recuperaPasswordOk || recuperaUsernameOk"  class="alert alert-info" role="alert">
            <h5>Ben fatto!</h5>
            <p>
             Controlla la tua email per continuare la procedura
            </p>
            <div class="row mt-3">
              <div class="col">
                <a (click)=" goToLogin()" placement="bottom" ngbTooltip="Vai alla pagina di Login"  class="cur"> Torna al Login</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
