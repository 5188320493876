<ngx-ui-loader [loaderId]="'nChartLineLoader'"></ngx-ui-loader>
<div class="card shadow card-widget border rounded m-1 " >
  <div class="card-header h5"> 
    <fa-icon class="" [icon]="faChartLine"></fa-icon>
        Report mensile
      <div class="float-end">
          <fa-icon class="cur" (click)="toggleWidget()" [icon]="(!close)?faChevronUp:faChevronDown"></fa-icon>
      </div>
  </div>
  <div *ngIf="!close" class="card-body text-secondary">
    <div class="chart-wrapper">
      <canvas baseChart
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [legend]="lineChartLegend"
          [type]="'line'"
          [plugins]="lineChartPlugins"
          [data]="lineChartData"
          >
      </canvas>
    </div>
  </div>
</div>
<!-- [colors]="lineChartColors" -->