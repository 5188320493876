import { ChartDataset, ChartType } from 'chart.js';
import { Component, OnInit } from '@angular/core';
// import { Label, MultiDataSet } from 'ng2-charts';



@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit {

  doughnutChartLabels: String[] = ['Gucci', 'Balenciaga', 'Bond Factory'];
  // doughnutChartData: MultiDataSet = [
  //   [55, 25, 20]
  // ];

  // doughnutChartData: ChartDataset<'doughnut'> = [
  //   {data: [55, 25, 20]}
  // ];

  doughnutChartType: ChartType = 'doughnut';

  constructor() { }

  ngOnInit(): void {
  }

}
