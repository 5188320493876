import { AfterViewInit, Compiler, Component, NgModule, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { faEdit, faPlus, faTools, faUser, faUserMinus } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'src/app/services/auth.service';
import { DataTableDirective } from 'angular-datatables';
import { GruppiService } from 'src/app/services/gruppi/gruppi.service';
import { Gruppo } from 'src/app/class/Gruppo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/class/User';
import { UtentiService } from 'src/app/services/utenti/utenti.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-users-dash',
  templateUrl: './users-dash.component.html',
  styleUrls: ['./users-dash.component.css']
})
export class UsersDashComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('newUserModal') public newUserModal: TemplateRef<any>;
  @ViewChild('newDelegateModal') public newDelegateModal: TemplateRef<any>;
  @ViewChild('deleteUserModal') public deleteUserModal: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  @ViewChild(DataTableDirective)

  dtElement: DataTableDirective;
  dtOptions: any    = {};
  dtTrigger: Subject<any> = new Subject();
  users: User[]     = [];
  gruppi: Gruppo[]  = []
  row: any;

  gold = false;
  admin = false;
  adminCC = false;
  persons: any[];


  // icone
  faUser = faUser;
  faTools = faTools;
  faEdit = faEdit;
  faUserMinus = faUserMinus;
  faPlus = faPlus;

  showPassword: boolean;

  // form
  userForm: FormGroup;

  // modal
  closeResult: string;

  edit: boolean;
  userToDelete: string;
  msg: string;
  idUserToDelete: number;
  idUserToUpdate: number;
  idUserLoggedin: number;
  // tslint:disable-next-line: ban-types
  privileges: String[] = [];

  // modal
  modalOptions: NgbModalConfig;

  constructor(private loader: NgxUiLoaderService,
              private usersServ: UtentiService,
              private fb: FormBuilder,
              private modalService: NgbModal,
              private message: ToastrService,
              private auth: AuthService,
              private gruppiService: GruppiService,
              public utils: UtilsService) {
                this.modalOptions = new NgbModalConfig(new NgbConfig());
                this.modalOptions.ariaLabelledBy =  'modal-basic-title';
                this.modalOptions.size = 'lg';
                this.edit = false;
                this.msg = '';
                // console.log(this.auth.getTypeUser());
                if (this.auth.getTypeUser() === 'gold'){
                  this.gold = true;
                  this.privileges = ['gold', 'admin', 'admin_cc'];
                }
                else  if (this.auth.getTypeUser() === 'admin'){
                  this.privileges = ['customer'];
                  this.admin = true;
                }
                else  if (this.auth.getTypeUser() === 'admin_cc'){
                    this.privileges = ['delegate'];
                    this.adminCC = true;
                  }
                this.idUserLoggedin = this.auth.getIdUser();
                // console.log('Id utente: ', this.idUserLoggedin);
          }

  ngOnInit(): void {
    this.loader.start();
    this.edit = false;
    this.msg = '';
    // binds component methods to javascript calls
    window.functions = window.functions || {};
    window.functions.editUser = this.editUser.bind(this);
    window.functions.deleteUser = this.deleteUser.bind(this);
    window.functions.resendLink = this.resendLink.bind(this);
    // console.log(window.functions);
    this.initTable();
    this.getGruppi();
  }

  initTable() {
    const that = this;
    this.dtOptions = {
      order: [[ 1, 'desc' ]],
      paging: true,
      pagingType: 'full_numbers',
      pageLength: 25,
      lengthMenu: [[10, 25, 50, 100, 200, -1], [10, 25, 50, 100, 200, 'Tutti']],
      autoWidth: true,
      deferRender: true,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.usersServ.getUsersDT(dataTablesParameters)
        .subscribe( resp => {
          that.users = resp.data;
          // console.log(that.users);
          const table = $('#dtUsers').DataTable();
          const info = table.page.info();
          // console.log(info);
          table.columns.adjust();
          // table.draw();
          that.loader.stop();
          callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.data,
           });
        });
      },
      stateSave: false,
      scrollX: true,
      // search: { regex: true },
      language: {
        url: '//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json',
      },
      // Declare the use of the extension in the dom parameter
      dom: 'lBfrtip',
      // Configure the buttons
      buttons: [
        {
          extend: 'colvis',
          text: 'Nascondi colonne',
          className: 'ms-2 btn'
        },
        {
          extend: 'copy',
          text: 'Copia',
          className: 'btn '
        },
        {
          extend: 'print',
          text: 'Stampa',
          className: 'btn '
        },
      {
        extend: 'csv',
        text: 'CSV',
        className: 'btn '
      },
      {
        extend: 'excel',
        text: 'Excel',
        className: 'btn '
      },
      {
         text: '<i class="fas fa-sync-alt"></i> Aggiorna',
         key: '1',
         className: 'btn',
         // tslint:disable-next-line: space-before-function-paren
         // tslint:disable-next-line: object-literal-shorthand
         // tslint:disable-next-line: only-arrow-functions
         action(e, dt, node, config) {
           // alert('Button activated');
           that.loader.start();
           that.utils.rerenderServerSide(that.dtElement);
           that.loader.stop();
         }
       },
       {
        text: ' Aggiungi',
        key: '2',
        className: 'btn',
        // tslint:disable-next-line: space-before-function-paren
        // tslint:disable-next-line: object-literal-shorthand
        // tslint:disable-next-line: only-arrow-functions
        action(e, dt, node, config) {
          // alert('Button activated');
          that.selectModal();
        }
      },
     ],
     // tslint:disable-next-line: ban-types
     rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // tslint:disable-next-line: deprecation
        $('td', row).unbind('click');
        // tslint:disable-next-line: deprecation
        $('td', row).bind('click', () => {
          self.someClickHandler(data);
        });
        self.row = row;
        return row;
      },
      // Use this attribute to enable colreorder
      colReorder: true,
      columns: [
        {
          data: null,
          // tslint:disable-next-line: max-line-length
          // defaultContent: '<a class="edit-user"><i class="fa fa-edit" aria-hidden="true"></i></a><a></a>',
          // defaultContent: '<app-table-actions></app-table-actions>'
          render: (data, type, full) => {
            // console.log(data);
            // console.log(type);
            // console.log(full);
            // console.log(data.enabled, this.idUserLoggedin, data.idUtente);
            const classTypeResend = (this.gold && this.idUserLoggedin !== parseInt(data.idUtente, 10)) ? 'cur' : 'isDisabled';
            const classType = (data.enabled  && this.idUserLoggedin !== parseInt(data.idUtente, 10)) ? 'cur' : 'isDisabled';
            let user = JSON.stringify(data);
            // tslint:disable-next-line: quotemark
            user = user.replace(/\\n/g, "\\n")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\'/g, "\\'")
                        .replace(/\\"/g, '\\"')
                        // tslint:disable-next-line: quotemark
                        .replace(/\\&/g, "\\&")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\r/g, "\\r")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\t/g, "\\t")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\b/g, "\\b")
                        // tslint:disable-next-line: quotemark
                        .replace(/\\f/g, "\\f");
            // console.log(user);
            return '<a class="me-2 hover-dark ' + classTypeResend + '" title="Reinvia il link di attivazione / reset password" onclick=\'functions.resendLink(' + data.idUtente + ')\' href="javascript:void(0);"><i class="fa fa-rotate-right" aria-hidden="true"></i></a>'
                + ' <a class="me-2 hover-dark ' + classType + '" title="Modifica" onclick=\'functions.editUser(' + user + ')\' href="javascript:void(0);"><i class="fa fa-edit" aria-hidden="true"></i></a>'
                + ' <a class=" hover-dark ' + classType + '" title="Elimina" onclick=\'functions.deleteUser(' + user + ')\' href="javascript:void(0);"><i class="fa fa-minus" aria-hidden="true"></i></a>';
          }
        },
        {
          name: 'Id',
          data: 'idUtente',
          title: 'Id',
        },
        { data: 'username' },
        { data: 'gruppo' },
        { data: 'email' },
        { data: 'nome' },
        { data: 'cognome' },
        {
          data: 'typeUser',
          render: (data, type, full) => {
            return this.getLabelTypeUser(data);
        }
        },
        {
          data: 'cellulare',
          render: (data, type, full) => {
            return (data === null || data === 'null' ? '' : data);
          }
        },
        { data: 'dataRegistrazione' },
        { data: 'ufficio' },
        { data: 'enabled',
          render: (data, type, full) => {
            return (data === true  ? 'si' : 'no');
          }
        },
        // { data: 'enabledUpload'},
        // { data: 'lastReset' },
      ] ,

      columnDefs: [
        {
          // This is for the Action column icons
          targets: 0,
          // data: null,
          sorting: false,
          orderable: false,
          type: 'html',
          // width: '8%',
          className: 'dt-body-center dt-head-center  no-print dt-nowrap ',
          // defaultContent: '<a><i class="fa fa-edit" aria-hidden="true"></i></a><a></a>'
        },
        {
          targets: 1,
          className: 'dt-body-right dt-head-center',
          name: 'Id',
          title: 'Id',
        },
        {
          targets: 2,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 3,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 4,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 5,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 6,
          className: 'dt-body-left dt-head-center'
        },
        {
          targets: 7,
          className: 'dt-body-center dt-head-center'
        },
        {
          targets: 8,
          className: 'dt-body-center dt-head-center',
        },
        {
          targets: 9,
          className: 'dt-body-center dt-head-center'
        },
        {
          targets: 10,
          className: 'dt-body-center dt-head-center'
        },
        {
          targets: 11,
          className: 'dt-body-center dt-head-center'
        }
      ],
    };
    if (this.gold){
      this.dtOptions.columnDefs.push({targets: [11, 12], className: 'dt-body-center dt-head-center', visible: false});
      this.dtOptions.columns.push({data: 'enabledUpload'}, { data: 'lastReset' });
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  someClickHandler(info: any): void {
    // console.log(info);
  }

  resendLink(idUtente: number){
    if (!this.gold){
      return false;
    }
    if (confirm('Sei sicuro di voler reinviare il link di attivazione all\'utente ' + idUtente + ' e resettare la password?')){
      this.usersServ.resendLink(idUtente)
      .subscribe( resp => {
        if (resp.status.toUpperCase() === 'OK'){
          this.message.success('Link inviato!', 'Fatto');
        }
      });
    } else {
      return false;
    }
  }

  deleteUser(user: User) {
    if (!user.enabled || user.idUtente === this.idUserLoggedin){
      return false;
    }
    if (user.nome) {
      user.nome = '';
    }
    if (!user.cognome) {
      user.cognome = '';
    }
    // console.log(idUtente + ' ' + nome + ' ' + cognome );
    // tslint:disable-next-line: max-line-length
    this.userToDelete =  '(' + user.idUtente + ') ' + user.nome?.toUpperCase() + ' ' + user.cognome?.toUpperCase();
    this.idUserToDelete = user.idUtente;
    this.utils.open(this.deleteUserModal, this.modalOptions);
  }

  editUser(user: User) {
    console.log(user);

    if (!user.enabled ){
      return false;
    }
    this.initFormUser();
    this.idUserToUpdate = user.idUtente;
    this.edit = true;
    this.userForm.controls.idUtente.setValue(user.idUtente);
    this.userForm.controls.username.setValue(user.username);
    this.userForm.controls.nome.setValue(user.nome);
    this.userForm.controls.cognome.setValue(user.cognome);
    this.userForm.controls.email.setValue(user.email);
    this.userForm.controls.tipoUtente.setValue(user.typeUser);
    this.userForm.controls.cellulare.setValue((user.cellulare=='null')?null:user.cellulare);
    this.userForm.controls.enabledUpload.setValue((user.enabledUpload.toString()==='si')?true:false);

    this.utils.open(this.newUserModal, this.modalOptions);
    console.log(this.userForm);
  }

  ngAfterViewInit(): void {
    // this.customFilterColumns();
  }

  customFilterColumns(){
      this.dtElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function() {
          // console.log('Filtri  su colonna attivi...');
          const that = this;
          $('input', this.footer()).on('keyup change', function() {
            // console.log('on keyup change');
            // console.log('this', this['value']);
            // tslint:disable-next-line: no-string-literal
            // console.log(that.search() + '!==' +  this['value']);
            // tslint:disable-next-line: no-string-literal
            if (that.search() !== this['value']) {
              that
                // tslint:disable-next-line: no-string-literal
                .search(this['value'])
                .draw();
            }
          });
        });
      });
  }

  initFormUser() {
    this.userForm = this.fb.group({
      idUtente:       new FormControl(null),
      email:          new FormControl(null, [ Validators.required,
                                              Validators.minLength(3),
                                              Validators.maxLength(50),
                                              Validators.email]),
      nome:           new FormControl(null, [ Validators.minLength(3),
                                              Validators.maxLength(50)]),
      username:       new FormControl(null, [ Validators.minLength(8),
                                              Validators.maxLength(50)]),
      cognome:        new FormControl(null, [ Validators.minLength(3),
                                              Validators.maxLength(50)]),
      gruppo:         new FormControl((this.gold) ? this.gruppi[0].idGruppo : this.auth.getIdGruppo(), [Validators.required]),
      tipoUtente:     new FormControl((this.gold) ? 'admin' : 'customer', Validators.required),
      cellulare:      new FormControl(null, [ Validators.minLength(5),
                                              Validators.maxLength(15)]),
      enabledUpload:  new FormControl({ value: (this.gold) ? false : this.auth.getEnabledUpload(), disabled: !this.gold}),
    });

    // se il campo tipoUtente corrisponde ad admin imposto  il validator required sul campo nome a null
    this.userForm.get('tipoUtente').valueChanges.subscribe( (value) => {
      // console.log(value);
      if ((value === 'admin' || value==='admin_cc') && this.gold){
        this.userForm.controls.nome.setValidators([Validators.minLength(3),
                                                   Validators.maxLength(50)]);
      } else if (this.gold || this.admin) {
          this.userForm.controls.nome.setValidators([Validators.required, Validators.minLength(3),
                                                     Validators.maxLength(50)]);
      }
      this.userForm.controls.nome.updateValueAndValidity();
    });
  }

  add(){
    // console.log(this.userForm.value);
    if (!this.gold){
      this.userForm.controls.enabledUpload.enable();
    }

    // tslint:disable-next-line: max-line-length
    this.usersServ.addUser(this.userForm.value)
    // tslint:disable-next-line: deprecation
    .subscribe(res => {
      this.msg = res.msg;
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.userForm.reset();
        this.initFormUser();
        this.modalService.dismissAll();

      } else if (res.status === 'KO' && res.hasOwnProperty('items') && res.items.hasOwnProperty('email')) {
                this.message.warning(res.items.email, 'Attenzione', {
                  timeOut: 3000,
                });
                if (!this.gold){
                  this.userForm.controls.enabledUpload.disable();
                }

              }
              else if (res.status === 'KO'){
                    if (!this.gold){
                      this.userForm.controls.enabledUpload.disable();
                    }
                  }
      this.loader.stop();
    });
  }

  deleteUserConfirm() {
    this.loader.start();
    const selector = '#row-' + this.idUserToDelete;
    // console.log(selector);
    this.usersServ.deleteAccount(this.idUserToDelete)
    // tslint:disable-next-line: deprecation
    .subscribe( resp => {
      this.msg = resp.msg;
      if (resp.status === 'OK') {
        this.message.success(resp.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.modalService.dismissAll();
      } else {
      }
      this.loader.stop();
    });
  }

  confirmEdit() {
    this.loader.start();
    this.usersServ.editUtente(this.userForm.value)
    // tslint:disable-next-line: deprecation
    .subscribe(res => {
      this.msg = res.msg;
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.userForm.reset();
        this.initFormUser();
        this.modalService.dismissAll();

      } else {
      }
      this.modalService.dismissAll();
      this.loader.stop();
    });
  }

  toggleShowPassword(){
    // console.log(this.showPassword );
    this.showPassword = !this.showPassword;
  }

  validPassword(password: string) {
    const regExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20}$)');
    // RegEx	Description
    // ^	The password string will start this way
    // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
    // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
    // (?=.*[0-9])	The string must contain at least 1 numeric character
    // (?=.*[!@#$%^&*])	The string must contain at least one special character,
    // but we are escaping reserved RegEx characters to avoid conflict
    // (?=.{8,20}$)	The string must be eight characters and longer max 20
    // console.log(regExp.test(password));
    return regExp.test(password);
  }

  selectModal(){
    this.loader.start();
    this.edit = false;
    this.initFormUser();
    if (this.auth.getTypeUser() === 'admin' || this.auth.getTypeUser() === 'gold' ){
      this.utils.open(this.newUserModal, this.modalOptions);
    } else if (this.auth.getTypeUser() === 'admin_cc') {
        this.utils.open(this.newDelegateModal, this.modalOptions);
    }
    this.loader.stop();
  }

  addDelegate(){
    this.userForm.controls.email.setValue(this.auth.getEmail());
    this.userForm.controls.tipoUtente.setValue('delegate');
    this.userForm.controls.nome.setValue('sconosciuto');
    this.userForm.controls.cognome.setValue('');
    this.userForm.controls.enabledUpload.enable();
    this.usersServ.addUser(this.userForm.value)
    .subscribe( resp => {
      if (resp.status.toUpperCase() === 'OK') {
        this.message.success(resp.msg, 'Fatto');
        this.modalService.dismissAll();
      }
      this.initFormUser();
      this.loader.stop();
    });
  }

  getLabelTypeUser(tipoUtente: string): string {
    let label;
    switch (tipoUtente){
      case 'gold':
              label = 'pnl';
              break;
      case 'admin':
              label = 'mittente';
              break;
      case 'admin_cc':
              label = 'mittente_cc';
              break;
      case  'customer':
              label = 'delegato';
              break;
      case 'delegate':
              label = 'delegato_cc';
              break;
      default:
              label = 'sconosciuta';
              break;
    }
    return label;
  }

  getGruppi(){
    this.gruppiService.getGruppi()
    .subscribe( resp => {
      console.log(resp);
      if (resp.status.toUpperCase() === 'OK'){
        this.gruppi = resp.items;
      }
    });
  }

}
