import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  NgbConfig,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import {
  faEdit,
  faMinus,
  faPeopleGroup,
  faPlus,
  faTools,
} from "@fortawesome/free-solid-svg-icons";

import { CategorieSpedizioni } from "src/app/class/CategorieSpedizioni";
import { DataTableDirective } from "angular-datatables";
import { GruppiService } from "src/app/services/gruppi/gruppi.service";
import { Gruppo } from "src/app/class/Gruppo";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SpedizioniService } from "src/app/services/spedizioni/spedizioni.service";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "app-gruppi-dash",
  templateUrl: "./gruppi-dash.component.html",
  styleUrls: ["./gruppi-dash.component.css"],
})
export class GruppiDashComponent implements OnDestroy, OnInit {
  @ViewChild("addGruppoModal") public addGruppoModal: TemplateRef<any>;
  @ViewChild("deleteGruppoModal") public deleteGruppoModal: TemplateRef<any>;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  gruppi: Gruppo[] = [];
  row: any;

  // icone
  faPeopleGroup = faPeopleGroup;
  faEdit        = faEdit;
  faMinus       = faMinus;
  faPlus        = faPlus;
  faTools       = faTools;

  // form
  gruppoForm: FormGroup;

  // modal
  closeResult: string;
  modalOptions: NgbModalConfig;

  edit: boolean;
  msg: string;
  idGroupToDelete: number;
  idGroupToUpdate: number;
  groupToDelete: string;


  categorieSpedizioni: CategorieSpedizioni[] = [];

  constructor(
    private loader: NgxUiLoaderService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private message: ToastrService,
    private spedizioniService: SpedizioniService,
    private gruppiService: GruppiService,
    public utils: UtilsService
  ) {
    this.modalOptions = new NgbModalConfig(new NgbConfig());
    this.modalOptions.ariaLabelledBy = "modal-basic-title";
    this.modalOptions.size = "lg";
    this.edit = false;
    this.msg = "";

    this.spedizioniService.getSpedizioni()
    .subscribe(resp => {
      if (resp.status === 'OK') {
        this.categorieSpedizioni = resp.items;
        this.categorieSpedizioni.forEach((ele: any)=>{
          ele.spedizioni.forEach( (sped: any) =>{
            sped.nome = sped.nome.replace(/â‚¬/g, '€');;
          });
        });
      }
    });
  }

  ngOnInit(): void {
    this.loader.start();
    this.edit = false;
    this.msg = "";
    // binds component methods to javascript calls
    window.functions = window.functions || {};
    window.functions.editGroup = this.editGroup.bind(this);
    window.functions.deleteGroup = this.deleteGroup.bind(this);
    this.initTable();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  initTable() {
    const that = this;
    this.dtOptions = {
      order: [[1, "desc"]],
      paging: true,
      pagingType: "full_numbers",
      pageLength: 25,
      lengthMenu: [
        [10, 25, 50, 100, 200, -1],
        [10, 25, 50, 100, 200, "Tutti"],
      ],
      autoWidth: true,
      deferRender: true,
      serverSide: true,
      processing: true,
      scrollX: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.gruppiService
          .getGruppiDT(dataTablesParameters)
          .subscribe((resp) => {
            that.gruppi = resp.data;
            const table = $("#dtGroup").DataTable();
            const info = table.page.info();
            table.columns.adjust();
            that.loader.stop();
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data,
            });
          });
      },
      stateSave: false,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.20/i18n/Italian.json",
      },
      // Declare the use of the extension in the dom parameter
      dom: "lBfrtip",
      // Configure the buttons
      buttons: [
        {
          extend: "colvis",
          text: "Nascondi colonne",
          className: "ms-2 btn",
        },
        {
          extend: "copy",
          text: "Copia",
          className: "btn ",
        },
        {
          extend: "print",
          text: "Stampa",
          className: "btn ",
        },
        {
          extend: "csv",
          text: "CSV",
          className: "btn ",
        },
        {
          extend: "excel",
          text: "Excel",
          className: "btn ",
        },
        {
          text: '<i class="fas fa-sync-alt"></i> Aggiorna',
          key: "1",
          className: "btn",
          // tslint:disable-next-line: space-before-function-paren
          // tslint:disable-next-line: object-literal-shorthand
          // tslint:disable-next-line: only-arrow-functions
          action(e, dt, node, config) {
            // alert('Button activated');
            that.loader.start();
            that.utils.rerenderServerSide(that.dtElement);
            that.loader.stop();
          },
        },
       {
        text: ' Aggiungi',
        key: '2',
        className: 'btn',
        // tslint:disable-next-line: space-before-function-paren
        // tslint:disable-next-line: object-literal-shorthand
        // tslint:disable-next-line: only-arrow-functions
        action(e, dt, node, config) {
          // alert('Button activated');
          that.openModal();
        }
      },
      ],
      // tslint:disable-next-line: ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // tslint:disable-next-line: deprecation
        $("td", row).unbind("click");
        // tslint:disable-next-line: deprecation
        $("td", row).bind("click", () => {
          self.someClickHandler(data);
        });
        self.row = row;
        return row;
      },
      // Use this attribute to enable colreorder
      colReorder: true,
      columns: [
        {
          data: null,
          render: (data, type, full) => {
            // console.log(data);
            // console.log(type);
            // console.log(full);

            if (data.spedizioni && typeof(data.spedizioni) === 'string')
              data.spedizioni = data.spedizioni?.split(',');
            let gruppo = JSON.stringify(data);
            data.nome = decodeURIComponent(data.nome);

            // tslint:disable-next-line: quotemark
            gruppo = gruppo
              .replace(/\\n/g, "\\n")
              // tslint:disable-next-line: quotemark
              .replace(/\\'/g, "\\'")
              .replace(/\\"/g, '\\"')
              // tslint:disable-next-line: quotemark
              .replace(/\\&/g, "\\&")
              // tslint:disable-next-line: quotemark
              .replace(/\\r/g, "\\r")
              // tslint:disable-next-line: quotemark
              .replace(/\\t/g, "\\t")
              // tslint:disable-next-line: quotemark
              .replace(/\\b/g, "\\b")
              // tslint:disable-next-line: quotemark
              .replace(/\\f/g, "\\f");
            return ' <a class="me-2 hover-dark" title="Modifica" onclick=\'functions.editGroup(' + gruppo + ')\' href="javascript:void(0);"><i class="fa fa-edit" aria-hidden="true"></i></a>'
                    + ' <a class=" hover-dark" title="Elimina" onclick=\'functions.deleteGroup(' + gruppo + ')\' href="javascript:void(0);"><i class="fa fa-minus" aria-hidden="true"></i></a>';
          },
        },
        {
          data: "idGruppo",
        },
        {
          data: "nome",
          name: "nome",
          title: "nome",
          target: "nome",
          render: (data, type, full, meta) => {
            return decodeURIComponent(data);
          },
        },
        {
          data: "nUtenti",
        },
        {
          data: "nSpedizioni",
        },
        {
          data: "enabled",
        },
        {
          data: "spedizioni",
        },
      ],
      columnDefs: [
        {
          targets: 0,
          searchable: false,
          orderable: false,
          className: 'dt-body-center dt-head-center dt-nowrap'
        },
        {
          targets: 1,
          className: 'dt-body-right dt-head-center',
          searchable: true,
          orderable: true,
        },
        {
          targets: 2,
          className: 'dt-body-left dt-head-center',
          searchable: true,
          orderable: true,
        },
        {
          targets: 3,
          className: 'dt-body-right dt-head-center',
          searchable: false,
          orderable: true,
        },
        {
          targets: 4,
          className: 'dt-body-right dt-head-center',
          searchable: false,
          orderable: true,
        },
        {
          targets: 5,
          className: 'dt-body-center dt-head-center',
          searchable: true,
          orderable: true,
        }
        ,
        {
          targets: 6,
          className: 'dt-body-center dt-head-center',
          searchable: false,
          orderable: false,
          visible: false
        }
      ],
    };
  }

  initFormGroup() {
    this.gruppoForm = this.fb.group({
      idGruppo:   new FormControl(null),
      nome:       new FormControl(null, [ Validators.required,
                                          Validators.minLength(3),
                                          Validators.maxLength(50)]),
      spedizioni: new FormControl(null, Validators.required),
    });
  }

  someClickHandler(info: any): void {
    // console.log(info);
  }

  deleteGroup(group: Gruppo) {
    this.groupToDelete =  '(' + group.idGruppo + ') ' + decodeURIComponent(group.nome?.toUpperCase());
    this.idGroupToDelete = group.idGruppo;
    this.utils.open(this.deleteGruppoModal, this.modalOptions);
  }

  deleteGroupConfirm() {
    this.loader.start();
    const selector = '#row-' + this.idGroupToDelete;
    // console.log(selector);
    this.gruppiService.deleteGroup(this.idGroupToDelete)
    // tslint:disable-next-line: deprecation
    .subscribe( resp => {
      this.msg = resp.msg;
      if (resp.status === 'OK') {
        this.message.success(resp.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.modalService.dismissAll();
      } else {
      }
      this.loader.stop();
    });
  }

  openModal(){
    this.loader.start();
    this.edit = false;
    this.initFormGroup();
    this.utils.open(this.addGruppoModal, this.modalOptions);
    this.loader.stop();
  }

  add(){
    this.loader.start();
    this.gruppiService.addGroup(this.gruppoForm.value)
    .subscribe(res => {
      this.msg = res.msg;
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.gruppoForm.reset();
        this.initFormGroup();
        this.modalService.dismissAll();
      }
      this.loader.stop();

    });
    
  }

  confirmEdit() {
    this.loader.start();
    this.gruppiService.editGruppo(this.gruppoForm.value)
    // tslint:disable-next-line: deprecation
    .subscribe(res => {
      this.msg = res.msg;
      if (res.status === 'OK') {
        this.message.success(res.msg, 'Fatto', {
          timeOut: 3000,
        });
        this.utils.rerenderServerSide(this.dtElement);
        this.gruppoForm.reset();
        this.initFormGroup();
        this.modalService.dismissAll();

      } else {
      }
      this.modalService.dismissAll();
      this.loader.stop();
    });
  }

  editGroup(group: Gruppo){
    this.initFormGroup();
    this.idGroupToUpdate = group.idGruppo;
    this.edit = true;
    this.gruppoForm.controls.idGruppo.setValue(group.idGruppo);
    this.gruppoForm.controls.spedizioni.setValue(group.spedizioni);
    this.gruppoForm.controls.nome.setValue(group.nome);
    this.utils.open(this.addGruppoModal, this.modalOptions);
  }
}
