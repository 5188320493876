<div class="row">
    <div class="col ">
      <h4 class="mt-3"><fa-icon [icon]="faDolly"></fa-icon> Prodotti di Postalizzazione</h4>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a routerLink="/dashboard/home" class="hover-dark" ngbTooltip="Vai alla Home">Dashboard</a> / <a routerLink="/dashboard/prodotti" class="hover-dark" ngbTooltip="Vai ai prodtti di postalizzazione">Prodotti</a>
    </div>
  </div>
  <hr />
  <table
  datatable
  [dtOptions]="dtOptions"
  #dtProducts
  class="row-border hover w-100"
>
  <thead>
    <tr>
      <th><fa-icon [icon]="faTools"></fa-icon></th>
      <th>Id</th>
      <th>Nome</th>
      <th>Nome Categoria</th>
      <th>Abilitato</th>
    </tr>
  </thead>
</table>

<!-- Elimina prodotto -->
<ng-template #deleteProductModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faDolly"></fa-icon> Elimina prodotto
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close "
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <p>
        Sei sicoro di voler eliminare il prodotto <b>{{ productToDelete }}</b> ?
      </p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-5  ">
          <button
            class="btn btn-secondary"
            (click)="modal.dismiss('Cross click')"
          >
            Annulla
          </button>
        </div>
        <div class="col-7">
          <button class="btn btn-blu" (click)="deleteProductConfirm()">
            Conferma
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Nuova/Modifica Prodotto -->
<ng-template #addProductModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="faDolly"></fa-icon> Product
      </h5>
      <a
        class="close cur"
        (click)="modal.dismiss('Cross click')"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="modal-body">
      <form action="" [formGroup]="productForm" class="justify-content-center">
        <div class="row my-3" >
          <div class="col-12">
            <div class="form-row">
              <div class="form-group w-100" >
                <label for="nome">Nome *</label>
                <input
                  type="text"
                  name="nome"
                  class="form-control"
                  formControlName="nome"
                  #nome
                  placeholder="nome..."
                />
              </div>
              <div *ngIf="utils.showError(productForm.get('nome'))" class="alert alert-danger">
                Nome utente non valido.
              </div>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col-12">
            <div class="form-row"  >
              <div class="form-group w-100">
                <label for="idCategoria">Tipo spedizione *</label>
                <select autofocus   name="idCategoria" class="form-control "formControlName="idCategoria">
                    <ng-container *ngFor="let categoria of categorieSpedizioni">
                      <option value="{{ categoria.idCategoriaSpedizione }}">{{ categoria.nome }}</option>
                    </ng-container>
                </select>
              </div>
            </div>
          </div>
        </div>

        <p class="mt-2">
          <b>* Campi obbligatori</b>
        </p>
        <div class="modal-footer">
          <button
            *ngIf="!edit"
            type="submit"
            [className]="(!productForm.valid)?'btn  btn-blu isDisabled':'btn  btn-blu'"
            [disabled]="!productForm.valid "
            [ngbTooltip]="
            productForm.valid == false
                ? 'Inserisci i campi obbligatori'
                : 'Aggiungi il prodotto'
            "
            (click)="add()"
          >
            Salva
          </button>
          <button
            *ngIf="edit"
            type="submit"
            [className]="(!productForm.valid)?'btn  btn-blu isDisabled':'btn  btn-blu'"
            [disabled]="!productForm.valid"
            [ngbTooltip]="
            productForm.valid == false
                ? 'Inserisci i campi obbligatori'
                : 'Modifica il prodotto'
            "
            (click)="confirmEdit()"
          >
            Modifica
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
