import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.css']
})
export class BubbleChartComponent implements OnInit {
  // public bubbleChartOptions: ChartOptions = {
  //   responsive: true,
  //   scales: {
  //     xAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 50,
  //       }
  //     }],
  //     yAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 50,
  //       }
  //     }]
  //   }
  // };

  // public bubbleChartOptions: ChartOptions = {
  //   responsive: true,
  //   scales: {
  //     xAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 50,
  //       }
  //     }],
  //     yAxes: [{
  //       ticks: {
  //         min: 0,
  //         max: 50,
  //       }
  //     }]
  //   }
  // };
  // public bubbleChartType: ChartType = 'bubble';
  // public bubbleChartLegend = true;

  // public bubbleChartData: ChartDataset[] = [
  //   {
  //     data: [
  //       { x: 15, y: 15, r: 15 },
  //       { x: 25, y: 15, r: 25 },
  //       { x: 36, y: 12, r: 33 },
  //       { x: 10, y: 18, r: 18 },
  //     ],
  //     label: 'Investimenti clienti',
  //   },
  // ];

  
  constructor() { }

  ngOnInit(): void {
  }

}
