import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SpedizioniService extends BaseService {
  private apiUrlGetTipiSpedizione       = '/getTipiSpedizione';
  private apiUrlGetSpedizioniCategoria  = '/getSpedizioniCategoria';
  private apiUrlGetProductDT            = '/getTipiSpedizioneDT';
  private apiUrlDeleteProduct           = '/deleteSpedizione';
  private apiUrlEditProduct             = '/editSpedizione';
  private apiUrlAddProduct              = '/addSpedizione';

  constructor(protected http: HttpClient,
              protected auth: AuthService,
              protected route: Router,
              protected message: ToastrService) {
        super(http, auth, route, message);
  }

  getSpedizioni(param = {}) {
    return this.get(this.apiUrlGetTipiSpedizione, param, true);
  }

  getSpedizioniByCategoria(param = {}) {
    return this.get(this.apiUrlGetSpedizioniCategoria, param, true);
  }

  getProductDT(param = {}) {
    return this.post(this.apiUrlGetProductDT, param, true);
  }

  deleteProduct(idSpedizione) {
    const param = {id : idSpedizione};
    return this.post(this.apiUrlDeleteProduct, param, true);
  }

  editProduct(param) {
    return this.post(this.apiUrlEditProduct, param, true);
  }

  addProduct(param = {}){
    return this.post(this.apiUrlAddProduct, param, true);
  }
}
