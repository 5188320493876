<div class="container text-center p-3">
    <div class="row">
      <div class="col-12 col-sm-6">
      <figure class="px-3">
        <img src="../../assets/img/404.png" class="img-fluid" alt="404 Page Not Found">
      </figure>
      </div>
      <div class="col-12 col-sm-6 m-auto">
        <div class="display-3"><b>Ooops!</b></div>
        <div class="display-4 m-3">404 - La pagina cercata non esiste</div>
        <a routerLink="/" class="m-3"><button class="btn  btn-blu"><i class="fas fa-home"></i> Torna alla home</button></a>
      </div>
    </div>
  </div>
  <app-footer-dash></app-footer-dash>