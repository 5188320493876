import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {
         faBuilding,
         faEye,
         faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'src/app/services/auth.service';
import { ComuniService } from 'src/app/services/comuni/comuni.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtentiService } from 'src/app/services/utenti/utenti.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-completa-registrazione-dash',
  templateUrl: './completa-registrazione-dash.component.html',
  styleUrls: ['./completa-registrazione-dash.component.css']
})
export class CompletaRegistrazioneDashComponent implements OnInit, AfterViewInit {
  @ViewChild('completaRegistrazioneModal') public completaRegistrazioneModal: TemplateRef<any>;

  // icone
  faBuilding  = faBuilding;
  faEye       = faEye;
  faEyeSlash  = faEyeSlash;

  // form
  officeForm: FormGroup;
  delegateFomr: FormGroup;

  // modal
  closeResult: string;

  province: any;
  comuni: any;
  caps: any;

  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;

  tipoUtente: string;

  modalOptions: NgbModalConfig;

  constructor(private loader: NgxUiLoaderService,
              public utils: UtilsService,
              private fb: FormBuilder,
              private message: ToastrService,
              private modalService: NgbModal,
              private router: Router,
              private comuniService: ComuniService,
              private utentiService: UtentiService,
              private auth: AuthService) {
                this.tipoUtente = this.auth.getTypeUser();
                this.province = this.comuniService.getProvince();
                this.showConfirmPassword = false;
                this.showCurrentPassword = false;
                this.showConfirmPassword = false;
                this.modalOptions = new NgbModalConfig(new NgbConfig());
                this.modalOptions.ariaLabelledBy =  'modal-basic-title';
  }

  ngOnInit(): void {
    this.loader.start();
    this.loader.stop();
  }

  ngAfterViewInit(): void {
    this.openCompletaRegistrazione();
  }

  initFormOffice() {
    this.officeForm = this.fb.group({
      nome:             new FormControl(null, [ Validators.minLength(3),
                                                Validators.maxLength(50)]),
      cognome:          new FormControl(null, [ Validators.minLength(3),
                                                Validators.maxLength(50)]),
      cellulare:        new FormControl(null, [ Validators.minLength(5),
                                                Validators.maxLength(15)]),
      citta:            new FormControl({value: -1, disabled: true}, [ Validators.minLength(2),
                                                Validators.maxLength(50)]),
      provincia:        new FormControl(-1, [ Validators.minLength(2),
                                                Validators.maxLength(2)]),
      indirizzo:        new FormControl(null, [ Validators.minLength(3),
                                                Validators.maxLength(80)]),
      cap:              new FormControl({value: -1, disabled: true}, [ Validators.minLength(5),
                                                Validators.maxLength(15)]),
      telefono:         new FormControl(null, [ Validators.minLength(5),
                                                Validators.maxLength(15)]),
      ufficio:          new FormControl(null, [ Validators.minLength(2),
                                                Validators.maxLength(50)]),
      currentPassword:  new FormControl(null, [ Validators.required,
                                                Validators.minLength(8)]),
      newPassword:      new FormControl(null, [ Validators.required,
                                                Validators.minLength(8)]),
      confirmPassword:  new FormControl(null, [ Validators.required,
                                                Validators.minLength(8)])
    });
  }

  initFormDelegate() {
    this.officeForm = this.fb.group({
      currentPassword:  new FormControl(null, [ Validators.required,
                                                Validators.minLength(8)]),
      newPassword:      new FormControl(null, [ Validators.required,
                                                Validators.minLength(8)]),
      confirmPassword:  new FormControl(null, [ Validators.required,
                                                Validators.minLength(8)])
    });
  }

  save(): boolean{
    // console.log(this.officeForm);
    if (!this.validPassword(this.officeForm.get('newPassword').value)) {
      alert('La password inserita non è valida');
      return false;
    }
    else if ( this.officeForm.get('newPassword').value !== this.officeForm.get('confirmPassword').value) {
      alert('Le password non corrispondono');
      return false;
    }
    else {
      this.loader.start();
      this.officeForm.controls.currentPassword.setValue(btoa(this.officeForm.get('currentPassword').value));
      this.officeForm.controls.newPassword.setValue(btoa(this.officeForm.get('newPassword').value));
      const that = this;
      this.utentiService.completaRegistrazione(this.officeForm.value)
      .subscribe( resp => {
          console.log(resp);
          if (resp.status.toUpperCase() === 'OK') {
            this.auth.setFirstLogin(false);
            if (this.tipoUtente !== 'admin_cc' && this.tipoUtente !== 'delegate' ) {
              this.auth.setNome(this.officeForm.get('nome').value);
              this.auth.setSurname(this.officeForm.get('cognome').value);
            } else {
              this.auth.setNome('Sconosciuto');
              this.auth.setSurname('');
            }
            this.message.success(resp.msg, 'Fatto');
            this.modalService.dismissAll();
            this.router.navigate(['/dashboard/postalizzazioni']);
          }
          this.loader.stop();
      });
      return true;
    }
  }

  openCompletaRegistrazione() {
    if (this.tipoUtente === 'admin_cc' || this.tipoUtente === 'admin' ){
      this.initFormOffice();
    } else if (this.tipoUtente === 'delegate'  ){
      this.initFormDelegate();
    }
    this.modalOptions.size = 'lg';
    this.modalOptions.backdrop = 'static';
    this.modalOptions.backdropClass = 'sfocato';
    this.utils.open(this.completaRegistrazioneModal, this.modalOptions);
  }

  validPassword(password: string) {
    const regExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20}$)');
    // RegEx	Description
    // ^	The password string will start this way
    // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
    // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
    // (?=.*[0-9])	The string must contain at least 1 numeric character
    // (?=.*[!@#$%^&*])	The string must contain at least one special character,
    // but we are escaping reserved RegEx characters to avoid conflict
    // (?=.{8,20}$)	The string must be eight characters and longer max 20
    // console.log(regExp.test(password));
    return regExp.test(password);
  }

  checkPwdEquals() {
   return (this.officeForm.get('newPassword').value !== this.officeForm.get('confirmPassword').value) ? false : true;
  }

  getComuni(){
    if ( this.officeForm.get('provincia').value.toString() !== '-1'){
      this.loader.start();

      this.officeForm.controls.citta.enable();
      this.officeForm.controls.citta.setValue('-1');
      this.officeForm.controls.cap.enable();
      this.officeForm.controls.cap.setValue('-1');
      this.officeForm.controls.cap.disable();

      this.comuni = this.comuniService.getComuni(this.officeForm.get('provincia').value);
      // console.log(this.comuni);
      this.loader.stop();
    }else{
      this.officeForm.controls.citta.enable();
      this.officeForm.controls.citta.setValue('-1');
      this.officeForm.controls.citta.disable();
      this.officeForm.controls.cap.enable();
      this.officeForm.controls.cap.setValue('-1');
      this.officeForm.controls.cap.disable();
    }
  }

  getCap(){
    if ( this.officeForm.get('citta').value.toString() !== '-1'){
      this.loader.start();
      this.officeForm.controls.cap.enable();
      this.officeForm.controls.cap.setValue('-1');

      this.caps = this.comuniService.getCap(this.officeForm.get('citta').value);
      // console.log(this.caps);
      this.loader.stop();
    }else{
      this.officeForm.controls.cap.enable();
      this.officeForm.controls.cap.setValue('-1');
      this.officeForm.controls.cap.disable();
    }
  }

  toggleShowCurrentPassword(){
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  toggleShowNewPassword(){
    this.showNewPassword = !this.showNewPassword;
  }

  toggleShowConfirmPassword(){
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
